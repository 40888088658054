import styled from "styled-components";

export const Notification = styled.div`
  padding: 10px;
  width: 100%;
  height: auto;
  background: rgba(37, 52, 106, 0.1);
  border-radius: 4px;
  margin-top: 10px;
`;

export const NotificationText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6b6b6b;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 493px;
  height: 50px;
  background: #25346a;
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  outline: none;
  border: none;
`;

export const DeviceTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 627px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 30px;
  cursor: pointer;
`;

export const Type = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.134139px;
  background: linear-gradient(104.83deg, #161626 -85.42%, #000000 142.89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const AssignSubAdminContainer = styled.div`
  height: 422px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  width: 624px;
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #d2cbcb;
  margin-bottom: 20px;
  width: 100%;
`;
export const SearchInput = styled.input`
  outline: none;
  border: none;
  flex-grow: 1;
  font-size: 14px;
  & :focus {
    outline: none;
    border: none;
  }
`;

export const TotalSubAdmin = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
  opacity: 0.8;
`;
export const SelectedSubAdmin = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
  opacity: 0.8;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  height: 70%;
  overflow-y: auto;
`;
export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    cursor: pointer;
  }
`;

export const LeftOptionContent = styled.div`
  display: flex;
  gap: 5px;
`;
export const Avatar = styled.img`
  border: 5px solid #e8e8e8;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #171725;
`;
export const TotalOwnedDevices = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  background: linear-gradient(180deg, #161626 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #c4c4c4;
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
