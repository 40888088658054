import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getUserFraudReport } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import moment from "moment";

const FraudReport = ({ userId }) => {

    const dispatch = useDispatch();
    const { fraudReport } = useSelector((state) => state.Fraud);
    const { gettingFraudReport } = useSelector((state) => state.Loading);
    let allRows = [];
    fraudReport.map((each) => {
        return allRows.push({
            dateAdded: <div> <p> {moment(each.dateAdded).format("DD-MM-YYYY")}</p></div>,
            suspiciousReason: each.suspiciousReason,
            operations:  <div> {each.operations.map(each => <p className="mb-0"> {each}</p>)}</div>,
            fraudster: <div> <p className="mb-0"> {each.fraudster.email}</p> <p>{each.fraudster.name}</p></div>,
            status: each.status,
        });
    });

    useEffect(() => {
        dispatch(getUserFraudReport(userId));

    }, [userId]);

    const data = {
        columns: [
            {
                label: "Date Added",
                field: "dateAdded",
                sort: "asc",
                width: 170,
            },
            {
                label: "Suspicious Reason",
                field: "suspiciousReason",
                sort: "asc",
                width: 170,
            },
            {
                label: "Operations",
                field: "operations",
                sort: "asc",
                width: 170,
            },
            {
                label: "Fraudster Details",
                field: "fraudster",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
           
           
        ],

        rows: allRows,
    };


    return (

        <div className="row mt-3">
            <div className="col-lg-12 w3-animate-bottom">
                <div className="card">
                    <div
                       className="col-lg-12 mb-4"
                    >
                        <CustomDataTable
                            data={data}
                            title={"Fraud Report"}
                            loading={gettingFraudReport}
                            noData={
                                <EmptyCard
                                  img="/Assets/emptytransactions.svg"
                                  title={"No Fraud Alerts!"}
                                  descr={"This user has not triggered any fraud alerts"}
                                />
                              }
                        />
                    </div>

                </div>

            </div>
        </div>

    );
};

export default FraudReport;
