import React from "react";
import { TableActionButton, TableFlex } from "../tables/tablestyles";

const EmptyCard = (props) => {
  const { img, title, descr, buttontitle, onClick } = props;
  return (
    <div className="col-lg-12 mb-4">
      <div className="">
        <TableFlex className="mt-2 p-20">
          <div></div>
          {buttontitle ? (
            <TableActionButton onClick={onClick}>
              {" "}
              {buttontitle}
            </TableActionButton>
          ) : (
            ""
          )}
        </TableFlex>
        <div className="p-20 t-center ">
          <img height="150px" src={img} alt="" />
          <div className="mt-5 mb-5">
            <p className="text-grey t-16 text-bold mb-3">{title}</p>
            <p className="t-12 text-grey"> {descr}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCard;
