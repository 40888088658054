import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import { handleModal } from "./GeneralAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Get Email Providers
export const getEmailProviders = () => {
    return (dispatch) => {
      dispatch({
        type: _const.GETTING_PROVIDERS,
        payload: true,
      });
      axios
        .get(config.notifApiUrl + "/v1/email-providers")
        .then((res) => {
          dispatch({
            type: _const.GETTING_PROVIDERS,
            payload: false,
          });
          dispatch({
            type: _const.GET_PROVIDERS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.GETTING_PROVIDERS,
            payload: false,
          });
        });
    };
};
  
//Get Email Providers
export const updateProviderStatus = (providerId) => {
    return (dispatch) => {
      dispatch({
        type: _const.UPDATING_PROVIDER_STATUS,
        payload: true,
      });
      axios
        .put(config.notifApiUrl + `/v1/email-providers/status/${providerId}`)
        .then((res) => {
          dispatch({
            type: _const.UPDATING_PROVIDER_STATUS,
            payload: false,
          });
          dispatch({
            type: _const.GET_DEVICES,
            payload: res.data.data,
          });
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
          dispatch(handleModal(0));
          dispatch(getEmailProviders());
        })
        .catch((err) => {
          dispatch({
            type: _const.UPDATING_PROVIDER_STATUS,
            payload: false,
          });
          dispatch({
            type: _const.ERROR_UPDATING_PROVIDER_STATUS,
            payload: err.message ? err.message : "Something went wrong",
          });
        });
    };
};

//Get Node Providers
export const getNodeProviders = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_NODE_PROVIDERS,
      payload: true,
    });
    axios
      .get(config.apiUrl + "/v3/node-provider")
      .then((res) => {
        dispatch({
          type: _const.GETTING_NODE_PROVIDERS,
          payload: false,
        });
        dispatch({
          type: _const.GET_NODE_PROVIDERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_NODE_PROVIDERS,
          payload: false,
        });
      });
  };
};

//Set active node providers
export const updateNodeProviderStatus = (providerId) => {
    return (dispatch) => {
      dispatch({
        type: _const.UPDATING_NODE_PROVIDER_STATUS,
        payload: true,
      });
      axios
        .put(config.apiUrl + `/v3/node-provider/${providerId}/enable`)
        .then((res) => {
          dispatch({
            type: _const.UPDATING_NODE_PROVIDER_STATUS,
            payload: false,
          });
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
          dispatch(handleModal(0));
          dispatch(getNodeProviders());
        })
        .catch((err) => {
          dispatch({
            type: _const.UPDATING_NODE_PROVIDER_STATUS,
            payload: false,
          });
          dispatch({
            type: _const.ERROR_UPDATING_PROVIDER_STATUS,
            payload: err.message ? err.message : "Something went wrong",
          });
        });
    };
  };

//Create node providers
export const createNodeProviderStatus = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.CREATING_NODE_PROVIDER_STATUS,
      payload: true,
    });
    dispatch({
      type: _const.CREATING_NODE_PROVIDER_FAILED,
      payload: "",
    });
    axios
      .post(config.apiUrl + `/v3/node-provider`, data)
      .then((res) => {
        dispatch({
          type: _const.CREATING_NODE_PROVIDER_STATUS,
          payload: false,
        });
        dispatch(handleModal(0));
        dispatch(getNodeProviders());
      })
      .catch((err) => {
        dispatch({
          type: _const.CREATING_NODE_PROVIDER_STATUS,
          payload: false,
        });
        dispatch({
          type: _const.CREATING_NODE_PROVIDER_FAILED,
          payload: err.response
            ? err.response.data.message
            : "Something went wrong", 
        });
      });
  };
};

//Get unremitted tokens
export const getUnremittedTokens = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_UNREMITTED_TOKENS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/remittance/unremitted-tokens?Page=${page}&PageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_UNREMITTED_TOKENS,
          payload: false,
        });
        dispatch({
          type: _const.UNREMITTED_TOKENS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_UNREMITTED_TOKENS,
          payload: false,
        });
      });
  };
};

//Initiate token or gas remittance
export const initiateRemittanceTokens = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.INITIATING_REMITTANCE_COMMAND,
      payload: true,
    });
    dispatch({
      type: _const.INITIATING_REMITTANCE_FAILED,
      payload: "",
    });
    axios
      .post(config.adminApiUrl + `/v1/remittance/initiate-remittance`, data)
      .then((res) => {
        dispatch({
          type: _const.INITIATING_REMITTANCE_COMMAND,
          payload: false,
        });
        Swal.fire({
          title: "Updated!",
          type: "success",
        });
        dispatch(handleModal(0));
        dispatch(getUnremittedTokens(1, 30));
      })
      .catch((err) => {
        dispatch({
          type: _const.INITIATING_REMITTANCE_COMMAND,
          payload: false,
        });
        dispatch({
          type: _const.ERROR_UPDATING_PROVIDER_STATUS,
          payload: err.message ? err.message : "Something went wrong",
        });
        dispatch({
          type: _const.INITIATING_REMITTANCE_FAILED,
          payload: err.response
            ? err.response.data.message
            : "Something went wrong", 
        });
      });
  };
};

//Get Currency
export const getCurrency = (page, pageSize, operation, enabled, search) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CURRENCIES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/currency-control?PageNumber=${page}&PageSize=${pageSize}&operation=${operation}&enabled=${enabled}&SearchParam=${search}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_CURRENCIES,
          payload: false,
        });
        dispatch({
          type: _const.GET_CURRENCIES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CURRENCIES,
          payload: false,
        });
      });
  };
};

//Enable Currency for an operation
export const enableCurrency = (operation) => {
  return (dispatch) => {
    dispatch({
      type: _const.ENABLING_CURRENCY,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/currency-control/get-enable-currencies-for-operation?operation=${operation}`)
      .then((res) => {
        dispatch({
          type: _const.ENABLING_CURRENCY,
          payload: false,
        });
        dispatch({
          type: _const.CURRENCY_ENABLE_STATUS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.ENABLING_CURRENCY,
          payload: false,
        });
      });
  };
};

//Add or update new currency
export const createCurrency = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.CREATING_CURRENCY,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v2/currency-control`, data)
      .then((res) => {
        dispatch({
          type: _const.CREATING_CURRENCY,
          payload: false,
        });
        dispatch({
          type: _const.CURRENCY_CREATION_STATUS,
          payload: res.data.data,
        });
        Swal.fire({
          title: "Updated!",
          type: "success",
        });
        dispatch(handleModal(0));
        dispatch(getCurrency(1, 30, '','', ''))
      })
      .catch((err) => {
        dispatch({
          type: _const.CREATING_CURRENCY,
          payload: false,
        });
        dispatch({
          type: _const.ERROR_UPDATING_PROVIDER_STATUS,
          payload: err.message ? err.message : "Something went wrong",
        });
      });
  };
};

//Get Operations
export const getOperations = (page, pageSize, enabled, search) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_OPERATIONS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/OperationControl?PageNumber=${page}&PageSize=${pageSize}&isEnabled=${enabled}&SearchParam=${search}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_OPERATIONS,
          payload: false,
        });
        dispatch({
          type: _const.GET_OPERATIONS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_OPERATIONS,
          payload: false,
        });
        
      });
  };
};

//Add or update new operations
export const createOperation = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.CREATING_OPERATION,
      payload: true,
    });
    axios
      .post(config.apiUrl + `/v2/OperationControl`, data)
      .then((res) => {
        dispatch({
          type: _const.CREATING_OPERATION,
          payload: false,
        });
        Swal.fire({
          title: "Updated!",
          type: "success",
        });
        dispatch(handleModal(0));
        dispatch(getOperations(1, 30, '', ''))
      })
      .catch((err) => {
        dispatch({
          type: _const.CREATING_OPERATION,
          payload: false,
        });
        dispatch({
          type: _const.ERROR_UPDATING_PROVIDER_STATUS,
          payload: err.message ? err.message : "Something went wrong",
        });
      });
  };
};

//Get APR for reward booster
export const getRewardBooster = (lockPeriod) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BOOSTER,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v4/reward-booster/apr?lockPeriod=${lockPeriod}`)
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_BOOSTER,
            payload: false,
          });
          dispatch({
            type: _const.REWARD_BOOSTER,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BOOSTER,
          payload: false,
        });
        
      });
  };
};

//Update APR settings for reward booster 
export const updateAPR = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.UPDATING_BOOSTER,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v4/reward-booster/apr`, data)
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: _const.UPDATING_BOOSTER,
            payload: false,
          });
          dispatch({
            type: _const.UPDATE_BOOSTER,
            payload: res.data.data,
          });
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
          dispatch(getRewardBooster(""));
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.UPDATING_BOOSTER,
          payload: false,
        });
        dispatch({
          type: _const.APR_ERROR,
          payload: err.message ? err.message : "Something when wrong",
        });
      });
  };
};

//Set penalty setting 
export const penaltySetting = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.UPDATING_PENALTY_SETTING,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v4/reward-booster/penalty-setting`, data)
      .then((res) => {
        dispatch({
          type: _const.UPDATING_PENALTY_SETTING,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.UPDATING_PENALTY_SETTING,
          payload: false,
        });
        
      });
  };
};