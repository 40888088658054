import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import * as options from "./options";
import TableHeader from "../Reusables/tables/TableHeader";
import { whiteListedCols } from "../../utils/TableCols/DeviceCols";
import { useDispatch, useSelector } from "react-redux";
import { getWhiteListedDevices } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { getWhiteListedRow } from "../../utils/TableRows/DevicesRows";

const WhiteListedDevices = ({deviceId}) => {
    const dispatch = useDispatch();
    const { whiteListedDevices } = useSelector((state) => state.Admin)
    const { gettingWhiteListedDevices } = useSelector((state) => state.Loading);

    const allRows = getWhiteListedRow( whiteListedDevices );
    const data = {
        columns: whiteListedCols,
        rows: allRows,
    };

    useEffect(() => {
        dispatch(getWhiteListedDevices(deviceId))
    }, [ deviceId ]);

    return (
        <div className="row">
            <div className="col-lg-12 mb-4">
                <CustomDataTable
                    data={data}
                    loading={gettingWhiteListedDevices}
                    noData={
                        <EmptyCard
                            img="/Assets/emptytransactions.svg"
                            title={"No white listed devices found"}
                        // descr={"No Live Sessions available at the moment"}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default WhiteListedDevices;
