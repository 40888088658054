import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../Reusables/loaders/MoonLoader';

const LoadMore = (props) => {
    const {dataLength, hasMore, items, moreData, height} = props
  
    return (
        <div>
            <InfiniteScroll
                dataLength={dataLength} 
                next={moreData} 
                hasMore={hasMore}
                loader={<div className="row"><div className='m-auto'> <Loader/> </div></div>}
                height={height}
            >
                {items}
            </InfiniteScroll>
        </div>
    )
}

export default LoadMore