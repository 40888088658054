import React from "react";
import { SubmitButton, SubmitButtonContainer } from "../../Devices/modals/styles";
import Loader from "../../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../../Reusables/modals/CustomModal";

function OperationalControlModal({ onSubmit, setActiveError, creatingOperations, enableDisable, operationalName }) {
    const formatEnableDisable = enableDisable.replace(/d$/, '');

  return (
      <div>
          <CustomErrorModal
              id={88}
              heading={`Operational Control - ${enableDisable}`}
              subHeading={`Are you sure you want to ${formatEnableDisable} this control - ${operationalName}`}
              close={true}
              content={
                  <>
                    {setActiveError ? <p className="errorMsgs mt-3 t-center mb-0">{setActiveError}</p> : ""}
                    <SubmitButtonContainer onClick={onSubmit} >
                        <SubmitButton className={enableDisable === "Enabled" ? "bkg-green details" : "bkg-red details"}>{creatingOperations ? <Loader /> : enableDisable === "Enabled" ? "Enable" : "Disable"}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default OperationalControlModal;
