import React, { useEffect } from "react";
import { getUsers } from "../../Redux/actions";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { allUsersHeaderOptionTypes } from "./options";
import { useSelector, useDispatch } from "react-redux";
import { allUsersCols } from "../../utils/TableCols/usersCols";
import { allUsersRows } from "../../utils/TableRows/usersRows";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { useState } from "react";
import Axios from "axios";
import config from "../../Redux/reducers/config";

const AllUsers = () => {
  const { Page, search, usersType, country, page, pageSize } = useSelector(
    (state) => state.Search
  );
  const { activeTab } = useSelector((state) => state.User);
  const { allusers } = useSelector((state) => state.Admin);
  const { gettingUsers } = useSelector((state) => state.Loading);

  const [allCountries, setCountries] = useState([{ id: 1, option: "All" }]);
  const dispatch = useDispatch();

  const allRows = allUsersRows(allusers.records, dispatch, activeTab);

  const data = {
    columns: allUsersCols,
    rows: allRows,
  };

  // get all countries
  const getAllCountries = async () => {
    await Axios.get(config.adminApiUrl + "/v1/countries").then((res) => {
      const results = res.data.data.map((country, index) => {
        return { id: index, option: country };
      });

      setCountries(allCountries.concat(results));
    });
  };

  useEffect(() => {
    dispatch(clearSearch());
    getAllCountries();
  }, []);

  const fetchAllUsers = () => {
    const queries = {
      Search: search,
      Country: country,
      Page: page,
      PageSize: pageSize,
    };
    getUsers(dispatch, queries, usersType);
  }

  useEffect(() => {
    fetchAllUsers();
  }, [Page, usersType, country]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchAllUsers();
    }
  },[search])

  // filter options
  const allUsersHeaderptions = [
    {
      id: 1,
      title: "Country",
      type: "select",
      onSelectProp: "country",
      options: allCountries,
      condition: "retain-space",
    },
    {
      id: 2,
      title: "Search",
      type: "text",
      placeholder: "Enter name or email",
      onChangeProp: "search",
    },
  ];

  return (
    <div className="">
      <CustomDataTable
        data={data}
        pageCount={allusers.totalPages}
        currentPage={allusers.currentPage}
        header={
          <TableHeader
            ontabClick={(val) => {
              dispatch(clearSearch());
              dispatch(
                getSearchDetails({
                  props: ["usersType"],
                  value: val,
                })
              );
            }}
            subHeaderTypes={allUsersHeaderOptionTypes}
            subHeader={allUsersHeaderptions}
            onChange={(e, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            // searchvalue={search}
            onSearchClick={fetchAllUsers}
          />
        }
        loading={gettingUsers}
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no user "}
            // descr={"No User available at the moment"}
          />
        }
      />
    </div>
  );
};

export default AllUsers;
