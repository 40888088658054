import React, { Component } from 'react'
import { connect } from 'react-redux'
import { individualVoucher, getFormDetails } from '../../Redux/actions'
import Loader from '../Reusables/loaders/MoonLoader'
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import { numberWithCommas } from '../../Helper'

class Voucher extends Component {

  render() {
    const { gettingIndividualVoucher, individualVouchers, voucherPhoneNumber, voucherStatus, checkedVoucher } = this.props

    let allRows = []
    individualVouchers.map(voucher => {

      return allRows.push({
        user: voucher.owner.username,
        expiry: voucher.expiryDate == null ? 'N/A' : moment(voucher.expiryDate).format('MMMM Do YYYY'),
        value: numberWithCommas(voucher.voucherValue) + 'WC | ' + numberWithCommas(voucher.voucherValue * 360) + 'NGN',
        balance: numberWithCommas(voucher.balance) + 'WC | ' + numberWithCommas(voucher.balance * 360) + 'NGN',
        code: voucher.code,
        status: <b className={voucher.voucherStatus === 'Exhausted' ?
          'text-red' : voucher.voucherStatus === 'Inactive' ? 'text-orange' : 'text-green'}
        > {voucher.voucherStatus} </b>

      })

    })

    const data = {
      columns: [
        {
          label: 'Username',
          field: 'user',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Expiry Date',
          field: 'expiry',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Voucher Value',
          field: 'value',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Voucher Balance',
          field: 'balance',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Code',
          field: 'code',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Status',
          field: 'status',
          sort: 'asc',
          width: 170
        },


      ],

      rows: allRows
    };

    return (
      <div>
        <div className="col-lg-6 offset-lg-2 col-xs-12">
          <div className="card card-small mb-4">
            <div className="card-header border-bottom d-flex jc">
              {/* <h6 className="m-0">TRACK VOUCHERS</h6> */}
              <div className="row border-bottom py-2 bg-light">
                <div className="col-12 col-sm-6">
                  <div id="blog-overview-date-range" className="input-daterange input-group input-group-sm my-auto ml-auto mr-auto ml-sm-auto mr-sm-0">

                    <select className="input-sm form-control" style={{ width: 'auto' }}
                      onChange={(e) => this.props.getFormDetails({ props: ['voucherStatus'], value: e.target.value })}>
                      <option>Select Voucher Status</option>
                      <option value={'Active'}>Active</option>
                      <option value={'Inactive'}>Inactive</option>
                      <option value={'Exhausted'}>Exhausted</option>
                      <option value={'Expired'}>Expired</option>
                    </select>

                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="col-md-6 col-lg enterCode">
                <form>
                  <label> Enter Phone Number</label>
                  <div className="input-group mb-3">

                    <input
                      type="text"
                      className="form-control loginCred"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={voucherPhoneNumber}
                      onChange={(e) => this.props.getFormDetails({ props: ['voucherPhoneNumber'], value: e.target.value })}
                    />

                  </div>

                  <div className=" mb-4 mt-3 authbtns">
                    <button
                      type="button"
                      className="mb-4 btn wicryptColor customBtn float button"
                      onClick={() => voucherPhoneNumber !== '' ? this.props.individualVoucher(voucherPhoneNumber, voucherStatus) : ''}
                    >
                      {gettingIndividualVoucher === true ? <Loader /> : "Enter"}
                    </button>
                  </div>
                  <div className="clearfix" />
                </form>
              </div>
            </div>
          </div>
        </div>
        {individualVouchers.length > 0 ?
          <div className="col-lg-12 mb-4">
            <div className="card card-small mb-4 dataTable">
              <MDBDataTable
                striped
                hover
                data={data}
                searchLabel='Search'
                responsive
              />
            </div>
          </div>
          : individualVouchers.length === 0 & checkedVoucher ?
            <div className="volucherNote col-md-10">

              <p className="voucherText"> No record found for {' ' + voucherPhoneNumber} </p>
            </div>
            : ''}
      </div>
    )
  }
}

const MapStateToProps = (state) => {

  const { gettingIndividualVoucher } = state.Loading
  const { individualVouchers, checkedVoucher } = state.Voucher
  const { voucherPhoneNumber, voucherStatus } = state.User
  return {

    gettingIndividualVoucher,
    individualVouchers,
    voucherPhoneNumber,
    voucherStatus,
    checkedVoucher
  }
}

export default connect(MapStateToProps, { individualVoucher, getFormDetails })(Voucher)