import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import BusinessSessions from './businessSessions'
import { MainContent } from '../Partials/partials'


class Business extends Component{
    render() {
    
      return (
         <div className="row">
         <Sidenav/>
             <MainContent className="main-content">
                 <div className="main-navbar sticky-top bg-white">
                     <Mainnav/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4">
                 <BusinessSessions businessId={this.props.businessId} userId={this.props.userId}/>
                 </div>
             </MainContent>
        </div>
      );
    }
  }

  const MapstateToProps = (state, props) =>{
    const businessId = props.match.params.businessId;
    const userId = props.match.params.userId;

    return{
        businessId,
        userId
    }
  }

export default connect(MapstateToProps)( Business)