import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRewardApr } from "../../Redux/actions";
import { CustomAPRModal } from "../Reusables/modals/CustomModal";
import { ChangeRoleContainer, SelectTitle } from "../AdminDetails/styles";
import { SubmitButton, SubmitButtonContainer } from "../RemoteCommands/styles";
import Loader from "../Reusables/loaders/MoonLoader";
import { StyledParagraph } from "./styles";

const rewardDuration = [
  { id: 1, value: "ThreeMonths", text: "3 Months" },
  { id: 2, value: "SixMonths", text: "6 Months" },
  { id: 3, value: "TwelveMonths", text: "12 Months" },
];

const UpdateAPR = () => {
  
  const [duration, setDuration] = useState("ThreeMonths");
  const [isError, setError] = useState("");
  const [APR, setAPR] = useState("");
  
  let dispatch = useDispatch();
  const {  updateApr, rewardBoosterapr } = useSelector((state) => state.RewardBooster);
  const { aprError } = useSelector((state) => state.RewardBooster);
  const {updatingApr} = useSelector((state)=> state.Loading);

  // Handle duration click
  const handleDurationClick = (value) => {
    setDuration(value);

    if (rewardBoosterapr) {
      const selectedBooster = rewardBoosterapr.find((booster) => booster.lockPeriod === value);
      if (selectedBooster) {
        setAPR(selectedBooster && selectedBooster.apr ? selectedBooster.apr.toString() : "");
      } else {
        setAPR("");
      }
    }
  };

  //function to check the estimated interest from the reward amount
  const handleUpdateApr = () => {
    if (!APR) {
      setError("Please enter a valid amount");
    } else {
      let data = {
        lockPeriod: duration,
        apr: Number(APR),
        applyToExistingLockPeriods: false
      }
      dispatch(updateRewardApr(data))
    }
  };

  const handleDurationValue = () => {
    if (rewardBoosterapr) {
      const selectedBooster = rewardBoosterapr.find((booster) => booster.lockPeriod === duration);
      setAPR(selectedBooster && selectedBooster.apr ? selectedBooster.apr.toString() : "");
    } else {
      setAPR("");
    }
  }

  useEffect(()=>{
      handleDurationValue();
  }, [rewardBoosterapr])

  useEffect(() => {
    if (updateApr) {
      setAPR("");
      setError("");
    }
  }, [updateApr]);

  return (
    <div>
      <div>
        <CustomAPRModal
          id={59}
          heading="Update APR"
          close={true}
          onClose={() => {handleDurationValue(); setError(""); }}
          content={
            <>
              <ChangeRoleContainer>
                <form className="quick-post-form">
                  <div className="form-group">
                    <SelectTitle>Enter Average Weekly Reward Amount</SelectTitle>
                    <input 
                      disabled={false} 
                      type="number"
                      value={APR}
                      onChange={(e) => setAPR(e.target.value)}
                      className="form-control" 
                    />
                  </div>
                  <div className="form-group" style={{display: "block", marginBottom: "0px"}}>
                    <SelectTitle>Lock Period</SelectTitle>
                    <div>
                      <div className="d-flex flex-wrap" style={{gap: "5px", justifyContent: "flex-start"}}>
                        {rewardDuration.map((each) => {
                          return (
                            <StyledParagraph
                              key={each.value}
                              value={each.value}
                              duration={duration}
                              onClick={() => handleDurationClick(each.value)}
                            >
                              {each.text}
                            </StyledParagraph>
                          );
                        })}
                      </div>
                    </div>
        
                  </div>
        
                </form>
        
                {isError || aprError ? <p className="errorMsgs mt-3 t-center mb-3">{isError || aprError}</p> : ""} 
              </ChangeRoleContainer>
              <SubmitButtonContainer onClick={()=> handleUpdateApr()}>
                <SubmitButton>{updatingApr ? <Loader /> : "Confirm"}</SubmitButton>
              </SubmitButtonContainer>
            </>
          }
        />
      </div>
    </div>
  );
}

export default UpdateAPR;
