import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getTokens,
  takeMeBack,
  deleteUser,
  getAllTokenatedUsers
} from "../../Redux/actions";
import moment from "moment";
import Loader from "../Reusables/loaders/HashLoader";
import MoonLoader from "../Reusables/loaders/MoonLoader";
import Button from "../Reusables/buttons/Button";
import filesize from "filesize";

class Beneficiaries extends Component {
  constructor() {
    super();
    this.state = {
      once: 1
    };
  }
  componentWillMount() {
    this.props.getTokens();
  }

  componentWillReceiveProps(nextProps) {
    const { allTokens, clickedToken } = nextProps;
    let token = allTokens.find(token => {
      return token.id === clickedToken;
    });
    if (nextProps.deleted & (this.state.once === 1)) {
      this.props.getAllTokenatedUsers(token.id);
      this.setState({
        once: 2
      });
    }
  }

  render() {
    const {
      tokenBeneficiaries,
      allTokens,
      clickedToken,
      tokenatedUsers,
      deletingUser,
      userId
    } = this.props;
    let token = allTokens.find(token => {
      return token.id === clickedToken;
    });

    return (
      <div className="row">
        <img
          height={50}
          width={50}
          src="/Assets/images/leftArrow.jpeg"
          alt="Back arrow"
          onClick={() => this.props.takeMeBack(false)}
        />
        <div className="col-lg-12">
          <div className="card card-small mb-4">
            <div
              className="card-header border-bottom"
              style={{
                display: "inline-flex",
                justifyContent: "space-between"
              }}
            >
              <h6 className="m-0">
                {token.code ? token.code : "Unnamed Token"}
              </h6>
              <div>
                Expiry Date:{" "}
                {moment(token.expiresAt).format("MMMM Do YYYY, h:mm:ss a")}
              </div>
            </div>
            <div className="card-body border-bottom">
              {tokenBeneficiaries.length > 0 ? (
                tokenBeneficiaries.map(beneficiaries => {
                  let width =
                    (beneficiaries.totalUsed / token.totalBytes) * 100;

                  return (
                    <div
                      key={beneficiaries.user.id}
                      className="row"
                      style={{ dispaly: "inline-flex" }}
                    >
                      <div className="col-lg-3">{beneficiaries.user.name}</div>
                      <div
                        className="progress progress-sm mb-3 col-lg-3"
                        style={{ padding: "0" }}
                      >
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: `${width}%` }}
                        />
                      </div>

                      <p className="col-lg-3">
                        {" "}
                        {filesize(beneficiaries.totalUsed)}/
                        {filesize(token.totalBytes)}{" "}
                      </p>
                      <div className="col-lg-3">
                        <Button
                          theme="wicryptColor ml-3 mt-2 actionButton"
                          disabled={token.isValid === false ? true : false}
                          buttonAction={() =>
                            this.props.deleteUser(
                              token.id,
                              beneficiaries.user.id
                            )
                          }
                        >
                          {deletingUser ? (
                            beneficiaries.user.id === userId ? (
                              <MoonLoader />
                            ) : (
                              "Remove User"
                            )
                          ) : (
                            "Remove User"
                          )}
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : tokenatedUsers ? (
                <Loader />
              ) : (
                <div style={{ textAlign: "center" }}>
                  This Token has no beneficiaries
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    tokenBeneficiaries,
    clickedToken,
    allTokens,
    userId,
    deleted
  } = state.Manager;
  const { tokenatedUsers, deletingUser } = state.Loading;
  return {
    tokenBeneficiaries,
    clickedToken,
    allTokens,
    tokenatedUsers,
    deletingUser,
    userId,
    deleted
  };
};

export default connect(
  MapStateToProps,
  { getTokens, takeMeBack, deleteUser, getAllTokenatedUsers }
)(Beneficiaries);
