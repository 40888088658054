import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getBoosterCyclesUserId, getSearchDetails } from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { getRewardBoosterRows } from "../../../utils/TableRows/rewardBoosterRow";
import { cyclesUserColumns } from "../../../utils/TableCols/RewardBoosterCols";

const BoosterCyclesUserPage = ({userId}) => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { boosterCyclesUserId } = useSelector((state) => state.RewardBooster);
  const { gettingBoosterCyclesUserId} = useSelector((state) => state.Loading);
  const { page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getRewardBoosterRows( boosterCyclesUserId ? boosterCyclesUserId.records : [], 
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );

  const data = {
    columns: cyclesUserColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(getBoosterCyclesUserId( userId, page, pageSize ));
  }, [userId, page, pageSize]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"Reward Booster"}
              pageCount={boosterCyclesUserId.totalPages}
              currentPage={boosterCyclesUserId.currentPage}
              header={
                <TableHeader
                  dataLength={boosterCyclesUserId.records ? boosterCyclesUserId.records.length : 0}
        
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                />
              }
              loading={gettingBoosterCyclesUserId}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry, you have no booster cycle history"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoosterCyclesUserPage;
