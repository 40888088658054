import _const from "../actions/Types";

const initialState = {
  active: false,
  id: 1,
  navList: [
    {
      id: 1,
      title: "Dashboard",
      link: "manager/dashboard",
      icon: "home",
    },
    {
      id: 2,
      title: "Referrals",
      link: "manager/referals",
      icon: "note_add",
    },
    {
      id: 3,
      title: "My Devices",
      link: "setting",
      icon: "phonelink_setup",
    },
    {
      id: 4,
      title: "Track Data",
      link: "trackdata",
      icon: "phone",
    },
    {
      id: 5,
      title: "Settings",
      link: "setremit",
      icon: "settings",
    },
    {
      id: 6,
      title: "Check Voucher Balance",
      link: "voucher/balance",
      icon: "phonelink_setup",
    },
    // {
    //     id: 4,
    //     title: 'My Tokens',
    //     link: 'token',
    //     icon: 'phonelink_setup'
    // },
    {
      id: 7,
      title: "Generate Voucher",
      link: `voucher?${" "}`,
      icon: "account_box",
    },
    {
      id: 8,
      title: "My Agents",
      link: "agents",
      icon: "account_box",
    },
    {
      id: 9,
      title: "Sub businesses",
      link: "subBusiness",
      icon: "report",
    },
    {
      id: 10,
      title: "Service Charge",
      link: "servicecharge",
      icon: "money",
    },

    {
      id: 11,
      title: "Profile/Wallet",
      link: "manager",
      icon: "account_box",
    },
  ],
};

const BusinessNav = (state = initialState, action) => {
  switch (action.type) {
    case _const.CHANGE_ACTIVE:
      let active = true;
      let id = action.id;
      return {
        ...state,
        active,
        id,
      };
    default:
      return { ...state };
  }
};

export default BusinessNav;
