import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDistributedReward } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { distributedStakingCol } from "../../utils/TableCols/usersCols";
import { getDistributedRows } from "../../utils/TableRows/usersRows";

const DistributedRewwardOverview = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDistributedReward(1, 30));
    }, []);

    const { gettingDistributedReward } = useSelector((state) => state.Loading);

    const { distributedRewardOverview } = useSelector((state) => state.Stake);

    let allRows = getDistributedRows(distributedRewardOverview.records);

    const data = {
        columns: distributedStakingCol,
        rows: allRows,
    };


    return (

        <div className="row">
            <div className="col-lg-12 mt-1">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"staking-report"}
                            title={`Distributed Reward Overview`}
                            pageCount={distributedRewardOverview.totalPages}
                            currentPage={distributedRewardOverview.currentPage}
                            loading={gettingDistributedReward}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={`Sorry,  you have no distributed reward history`}
                                    descr={"No distributed reward history found"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DistributedRewwardOverview;
