import React, { useEffect } from "react";
import {
  getDeviceStatusCount,
  getMiningStatistics,
  getSessionsStatistics,
  getTotalNumberOfDevices,
  getTotalNumberOfDevicesOnce,
  getTotalUsers,
} from "../../Redux/actions/AdminDevicesAction";
import IconCards from "../Reusables/cards/IconCard";
import Loader from "../Reusables/loaders/HashLoader";
import TableHeader from "../Reusables/tables/TableHeader";
import { overviewHeaderOptions } from "./options";
import { useDispatch, useSelector } from "react-redux";
import {
  niceBytes,
  numberWithCommas,
  numberWithCommaswithoutdecimals,
} from "../../Helper";
import { handleSubNav } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";

function Overview() {
  const dispatch = useDispatch();
  const {
    gettingTotalDevices,
    totalOfAllDevices,
    gettingTotalDevicesOnce,
    totalOfAllDevicesOnce,
    gettingSessionsSummary,
    sessionSummary,
    gettingMiningStats,
    miningStatistics,
    gettingDeviceStatusCount,
    deviceStatusCount,
  } = useSelector((state) => state.Devices);
  const { duration } = useSelector((state) => state.Search);
  useEffect(() => {
    getTotalNumberOfDevices(dispatch, duration);
    getSessionsStatistics(dispatch, duration);
    getMiningStatistics(dispatch, duration);
    getTotalUsers(dispatch, duration);
  }, [duration]);

  useEffect(() => {
    getDeviceStatusCount(dispatch, duration);
    getTotalNumberOfDevicesOnce(dispatch, duration);
    dispatch(
      getSearchDetails({
        props: ["duration"],
        value: "",
      })
    );
  }, []);

  return (
    <div className="main-content-container container-fluid mb-5">
      <div className="row">
        <div className="col-lg-12 w3-animate-bottom">
          <TableHeader
            subHeader={overviewHeaderOptions}
            onClick={() => handleSubNav(5)}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            // searchvalue={livesessionDeviceSearch}
          />
          <div
            className="row"
            id={"overview-report"}
          >
            <IconCards
              title={"Total Devices"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingTotalDevices ? (
                  <Loader />
                ) : totalOfAllDevices ? (
                  numberWithCommaswithoutdecimals(totalOfAllDevices.total) || 0
                ) : (
                  "0"
                )
              }
              id={1}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Lynx"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingTotalDevices ? (
                  <Loader />
                ) : totalOfAllDevices ? (
                  numberWithCommaswithoutdecimals(totalOfAllDevices.lynx) || 0
                ) : (
                  "0"
                )
              }
              id={2}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Spider"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingTotalDevices ? (
                  <Loader />
                ) : totalOfAllDevices ? (
                  numberWithCommaswithoutdecimals(totalOfAllDevices.spider) || 0
                ) : (
                  "0"
                )
              }
              id={3}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Basic Lynx"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingTotalDevices ? (
                  <Loader />
                ) : totalOfAllDevices ? (
                  numberWithCommaswithoutdecimals(
                    totalOfAllDevices.basicLynx
                  ) || 0
                ) : (
                  "0"
                )
              }
              id={4}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Basic Spider"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingTotalDevices ? (
                  <Loader />
                ) : totalOfAllDevices ? (
                  numberWithCommaswithoutdecimals(
                    totalOfAllDevices.basicSpider
                  ) || 0
                ) : (
                  "0"
                )
              }
              id={5}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Sessions"}
              image={"/Assets/dashboardicons/Extra/SVG/sessions.svg"}
              value={
                gettingSessionsSummary ? (
                  <Loader />
                ) : sessionSummary ? (
                  numberWithCommaswithoutdecimals(
                    sessionSummary.sessionCount
                  ) || 0
                ) : (
                  0
                )
              }
              id={10}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Data Used"}
              image={"/Assets/dashboardicons/Extra/SVG/dataused.svg"}
              value={
                gettingSessionsSummary ? (
                  <Loader />
                ) : sessionSummary ? (
                  niceBytes(sessionSummary.dataUsed)
                ) : (
                  niceBytes(0)
                )
              }
              id={11}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"WNT Mined"}
              image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
              value={
                gettingMiningStats ? (
                  <Loader />
                ) : miningStatistics ? (
                  (numberWithCommas(miningStatistics.mined) || 0) +
                  " " +
                  (miningStatistics.currency || "N/A")
                ) : (
                  "0"
                )
              }
              id={12}
              link={"#"}
              bordered={true}
            />
             <IconCards
              title={"Online Devices"}
              image={"/Assets/dashboardicons/Extra/SVG/online.svg"}
              value={
                gettingDeviceStatusCount ? (
                  <Loader />
                ) : deviceStatusCount ? (
                  numberWithCommaswithoutdecimals(deviceStatusCount.online) || 0
                ) : (
                  "0"
                )
              }
              id={6}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Offline Devices"}
              image={"/Assets/dashboardicons/Extra/SVG/offline.svg"}
              value={
                gettingDeviceStatusCount ? (
                  <Loader />
                ) : deviceStatusCount ? (
                  numberWithCommaswithoutdecimals(deviceStatusCount.offline) ||
                  0
                ) : (
                  "0"
                )
              }
              id={7}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Blocked Devices"}
              image={"/Assets/dashboardicons/Extra/SVG/blocked.svg"}
              value={
                gettingDeviceStatusCount ? (
                  <Loader />
                ) : deviceStatusCount ? (
                  numberWithCommaswithoutdecimals(deviceStatusCount.blocked) ||
                  0
                ) : (
                  "0"
                )
              }
              id={8}
              link={"#"}
              bordered={true}
            />
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default Overview;
