import styled from "styled-components";
import { theme } from "../../../theme";

export const OverviewDescription = styled.p`
  color: ${theme.grey};
  font-size: ${theme.textSm};
  margin-bottom: 5px;
  margin-top: 20px;
  font-weight: bold;
`;
export const OverviewValue = styled.p`
  color: ${(props) => (props.color ? theme[props.color] : theme.blue)};
  font-size: ${theme.textSmd};
  margin-bottom: 20px;
  font-weight: bold;
`;
export const PieContainer = styled.div`  
   width: 100%
`;
