import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { deleteAgentDevice, toggleModal} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";

class DeleteDevice extends Component {
  render() {
    const {
        deletingAgentDevice,
        businessUserId,
        businessId,
        clickedDeviceId,
    } = this.props;
    
    return (
      <Modal heading={`Delete device`}>
        <div className="t-center p-20 t-12">
            <p> Are you sure you  want to stop this agent from managing {clickedDeviceId.deviceName}</p>
        </div>
        <div className="d-flex">
        <Button
          theme=" ml-3 mt-2"
          buttonAction={() =>
            this.props.toggleModal(false, '', '')
          }
        >
          Close
        </Button>
        <Button 
          theme="wicryptColor ml-3 mt-2"
          buttonAction={() =>
            this.props.deleteAgentDevice(clickedDeviceId.id, businessUserId, businessId)
          }
        >
          {deletingAgentDevice ? <Loader /> : "Continue"}
        </Button>
        </div>
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const {deletingAgentDevice, } = state.Loading;
  const {clickedDeviceId} = state.User;

  return {
    deletingAgentDevice,
    clickedDeviceId,

  };
};

export default connect(
  MapStateToProps,
  {
    deleteAgentDevice,
    toggleModal
  }
)(DeleteDevice);
