import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LinkButton from "../../Components/Reusables/buttons/LinkButton";
// import {
//   ActionOption,
//   Icon,
//   Option,
// } from "../../Components/Devices/devicestyles";
import { numberWithCommas, time_convert } from "../../Helper";
import { handleSubNav } from "../../Redux/actions";
// import { getFormDetails } from "../../Redux/actions";
import filesize from "filesize";
import moment from "moment";

export const getAllDevicesRows = (data, userId, admin, activeTab, toggleSubnav) => {
  const dispatch = useDispatch()
  const allusers = data
    ? data.map((device, index) => {
      return {
        id: index + 1,
        deviceName: (
          <>
            {device.deviceName}
            {device.isNFTDevice && (
              <img src="/Assets/adminicons/info.svg" alt="info" />
            )}
          </>
        ),
        created:
          moment(device.createdAtTimeStamp).format("DD-MM-YYYY") +
          " | " +
          moment(device.createdAtTimeStamp).format("LT"),
        bsid: device.bsid,
        deviceType: device.deviceType || "N/A",
        model: device.model || "N/A",
        deviceMode: device.deviceMode || "N/A",
        owner: device.owner.name || "N/A",
        miningReward: device.miningPercentage + "%" || "N/A",
        status: (
          <div
            className={`${device.status === "Online" || device.status === "ACTIVE"
              ? "online"
              : device.status === "Offline"
                ? "offline"
                : "blocked"
              }`}
          >
            {device.status || "N/A"}
          </div>
        ),
        manufacturer: device.manufacturer && device.manufacturer.name ? device.manufacturer.name : "N/A",
        billingRate: device.billingRate || "N/A",
        view: (
          <Link
            to={
              `/devices/${device.id}`
            }
          >
            <i
              className="material-icons text-orange"
              style={{ fontSize: "16px", cursor: "pointer" }}
              aria-hidden="true"
            onClick={() => {
              dispatch(handleSubNav(1));
              localStorage.setItem("activeTab", activeTab);
              localStorage.setItem("mainTab", toggleSubnav);
            }}
            >
              chevron_right
            </i>
          </Link>
        ),
      };
    })
    : [];
  return allusers;
};

export const getLiveSessionRows = (
  data,
  currencyValue,
  currencyCode,
) => {
  const allRows = data
    ? data.map((session, i) => {
      return {
        key: i + 1,
        device: session.deviceName || "N/A",
        agent: session.deviceManager || "N/A",
        connecteduser: session.connectedClient || "N/A",
        duration: time_convert(session.duration),
        dataused: filesize(session.dataUsed),
        amtGenerated:
          currencyCode +
          " " +
          numberWithCommas(session.amountGenerated * currencyValue),
      };
    })
    : [];

  return allRows;
};

export const getDeviceSessionHistoryRows = (data) => {
  const allRows = data
    ? data.map((session, i) => {
      return {
        client: session.client,
        device: session.deviceName,
        rate: session.rate,
        created:
          moment(session.createdAt).format("DD-MM-YYYY") +
          " | " +
          moment(session.createdAt).format("LT"),
        updated:
          moment(session.updatedAt).format("DD-MM-YYYY") +
          " | " +
          moment(session.updatedAt).format("LT"),
        view: (
          <Link to={`/devices/details`}>
            <i
              className="material-icons text-orange"
              style={{ fontSize: "16px", cursor: "pointer" }}
              aria-hidden="true"
            >
              chevron_right
            </i>
          </Link>
        ),
      };
    })
    : [];
  return allRows;
};

export const getDeviceTrackerRows = (data) => {
  const allRows = data
    ? data.map((trackingInfo) => {
      return {
        subscriptiondate: moment
          .parseZone(trackingInfo.subscriptionDate)
          .format("YYYY-MM-DD"),
        dataAmountInBytes: filesize(trackingInfo.dataValue),
        dataDevice: trackingInfo.deviceName,
        dataUsedInBytes: filesize(trackingInfo.dataConsumed),
        dataRemainingInBytes: filesize(trackingInfo.dataRemaining),
        subscriptiontionValidity: trackingInfo.validityPeriod,
        expiryDate: moment
          .parseZone(trackingInfo.expiryDate)
          .format("YYYY-MM-DD"),
      };
    })
    : [];
  return allRows;
};
export const getLatestDeviceRows = (data, toggleSubnav) => {
  const dispatch = useDispatch();
  const allRows = data
    ? data.map((device) => {
      return {
        deviceId: device.deviceId,
        deviceName: device.deviceName,
        macAddress: device.macAddress,
        bssid: device.bssid,
        status: device.isActive ? (
          <div className="online">Online</div>
        ) : (
          <div className="offline">Offline</div>
        ),
        view: (
          <Link
            to={
              `/devices/${device.deviceId}`
            }
          >
            <i
              className="material-icons text-orange"
              style={{ fontSize: "16px", cursor: "pointer" }}
              aria-hidden="true"
              onClick={() => {
                dispatch(handleSubNav(1));
                localStorage.setItem("mainTab", toggleSubnav);
              }}
            >
              chevron_right
            </i>
          </Link>
        ),
      };
    })
    : [];
  return allRows;
};

export const getDeviceLogs = (
  data,
  deviceId,
  dispatch,
  activeTab
) => {
  const allRows = data
    ? data.map((log, i) => {
      const { requestPayload } = log
      return {
        key: i + 1,
        date: moment(log.createdAt).format("DD-MM-YYYY"),
        deviceActivityType: log.deviceActivityType,
        requestDetails:
          <div> {requestPayload ?
            <div>

              {requestPayload.updateDeviceRateRequest ?
                <div>
                  <p className="mb-0"> DEVICE NAME: {requestPayload.updateDeviceRateRequest.name}</p>
                  <p className="mb-0"> MAC ADDRESS: {requestPayload.updateDeviceRateRequest.macAddress}</p>
                  <p className="mb-0"> CONNECTION ID: {requestPayload.updateDeviceRateRequest.connectionId}</p>
                  <p className="mb-0"> RATE: {requestPayload.updateDeviceRateRequest.rate}</p>
                </div>
                : null}
              {requestPayload.deviceRegistrationRequest ?
                <div>
                  <p className="mb-0"> DEVICE NAME: {requestPayload.deviceRegistrationRequest.name}</p>
                  <p className="mb-0"> DEVICE MODEL: {requestPayload.deviceRegistrationRequest.deviceModel}</p>
                  <p className="mb-0"> EMAIL: {requestPayload.deviceRegistrationRequest.email}</p>
                  <p className="mb-0"> MAC ADDRESS: {requestPayload.deviceRegistrationRequest.macAddress}</p>
                  <p className="mb-0"> BSSID: {requestPayload.deviceRegistrationRequest.bssId}</p>
                  <p className="mb-0"> ARCHITECTURE: {requestPayload.deviceRegistrationRequest.architecture}</p>
                </div>
                : null}
              {requestPayload.deviceArchitectureUpdateRequest ?
                <div>
                  <p className="mb-0"> DEVICE MODEL: {requestPayload.deviceArchitectureUpdateRequest.deviceModel}</p>
                  <p className="mb-0"> MAC ADDRESS: {requestPayload.deviceArchitectureUpdateRequest.macAddress}</p>
                  <p className="mb-0"> BSSID: {requestPayload.deviceArchitectureUpdateRequest.bssId}</p>
                  <p className="mb-0"> ARCHITECTURE: {requestPayload.deviceArchitectureUpdateRequest.architecture}</p>
                </div> : null
              }
            </div>
            : null}

          </div>,
        requestIp: log.requestIP,
        status: <div> <p className={`${log.status + " mb-0"}`}> {log.status}</p> <p>{log.failureReason}</p> </div>,
        payload: <div style={{width:"inherit"}}>{log.requestPayload ? JSON.stringify(log.requestPayload) : "N/A"}</div>,
        viewMore: !deviceId ? <LinkButton link={`/devices/${log.userDeviceId}`}
        text={"View More"}
        size="medium"
        onClick={() => {
          dispatch(handleSubNav(2));
          localStorage.setItem("activeTab", activeTab);
        }}
        /> : null
      };
    })
    : [];

  return allRows;
};

export const getWhiteListedRow = (data) => {
  const allRows = data.records
    ? data.records.map((whiteListed, i) => {
      return {
        key: i + 1,
        date: moment(whiteListed.whiteListedAtTimeStamp).format("DD-MM-YYYY"),
        name: whiteListed.name,
        macAddress: whiteListed.macAddress,
      };
    })
    : [];

  return allRows;
};