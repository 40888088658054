import React from 'react'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Voucher from './Voucher'
import Mainnav from '../Partials/Mainnav'
import { MainContent } from '../Partials/partials'
import SubSidenav from "../Partials/SubSidenav";



const index = () =>{
    return (
        <div className="row">
        <Sidenav/>
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Track Voucher"/>
                </div>  
                <div className="main-content-container container-fluid px-4 mt-5">
                <BreadCrumbs/>
                <SubSidenav />
                <Voucher/>
                </div>
            </MainContent>
            
       </div>
    )
}

export default index