import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword, getFormDetails, handlePinReveal } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { Link } from "react-router-dom";
import BreadCrumbs from "../Partials/Breadcrumbs";

class ResetPassword extends Component {
  render() {
    const { email, token, newPass, resettingPassword, confirmnewPass, pinReveal, resetpasswordfail } = this.props;
    return (
      <div className="row background">
        <main className="main-content col-lg-12 col-md-12 col-sm-12">
          <div className="main-content-container container-fluid px-4">
            <BreadCrumbs />
            <div className="row mt-5 w3-animate-bottom">
              <div className=" col-lg-6 offset-lg-3 fade-in">
                <div className="card card-small card-post card-post--1">
                  <img className='logo' src="/Assets/wicrypt.svg" alt="" />
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        <div className="t-center p-10 mt-5 mb-5">
                          <h5 className="card-title cardHeading">
                            Reset PIN
                        </h5>
                          <p className="wicryptmsg t-14 "> Set a new PIN for your account</p>

                        </div>
                        <label className="mt-3"> Enter new PIN</label>
                        <div className="input-group mb-3">
                          <input
                            type={pinReveal !== 50 ? "password" : "text"}
                            className="form-control loginCred"
                            aria-label="Pin"
                            aria-describedby="basic-addon1"
                            value={newPass}
                            maxLength={4}
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["newPass"],
                                value: e.target.value,
                              })
                            }
                          />
                          {pinReveal !== 50 ?
                            <div onClick={() => this.props.handlePinReveal(50)}>
                              <img className='eye' src="/Assets/eyeClosed.png" alt="" />
                            </div>
                            :
                            <div onClick={() => this.props.handlePinReveal(0)}>
                              <img className='eye' src="/Assets/eyeOpen.png" alt="" />
                            </div>
                          }
                        </div>
                        <label> Confirm new PIN</label>
                        <div className="input-group mb-3">
                          <input
                            type={pinReveal !== 70 ? "password" : "text"}
                            className="form-control loginCred"
                            aria-label="Pin"
                            value={confirmnewPass}
                            aria-describedby="basic-addon1"
                            maxLength={4}
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["confirmnewPass"],
                                value: e.target.value,
                              })
                            }
                          />
                          {pinReveal !== 70 ?
                            <div onClick={() => this.props.handlePinReveal(70)}>
                              <img className='eye' src="/Assets/eyeClosed.png" alt="" />
                            </div>
                            :
                            <div onClick={() => this.props.handlePinReveal(0)}>
                              <img className='eye' src="/Assets/eyeOpen.png" alt="" />
                            </div>
                          }
                        </div>

                        {resetpasswordfail ? (
                          <p className="errorMsgs mt-3 text-center">
                            {" "}
                            <i className="material-icons">info</i> {resetpasswordfail}
                          </p>
                        ) : (
                            ""
                          )}
                        <div className="loginBtn mt-5">
                          <button
                            onClick={() =>
                              this.props.resetPassword(email, token, newPass, confirmnewPass)
                            }
                            className="clickLogin mb-2 mr-1 bold loginbutton"
                          >
                            {resettingPassword ? <Loader /> : "Done"}
                          </button>
                        </div>
                        <div className="t-center mt-3 mb-5">
                          <Link to="/" className="text-blue bold t-16">
                            Back to Log in{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="sideWave" src="/Assets/Intersection 1.png" alt="" />
                  <img className="leftsideWave" src="/Assets/Intersection 2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className='privacy mt-4'>
            <div>
              <p className="policies"> Copyright © 2018-2021. All rights reserved. Wicrypt Limited</p>
            </div>
            <div>
              <a href="https://wicrypt.com/help/" className="tanda policies"> Help Center</a>
              <a href="https://wicrypt.com/privacy-policy.html" className="tanda policies"> Privacy Policy</a>
              <a href="https://wicrypt.com/terms-condition.html" className="tanda policies"> Terms and Condition</a>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  function getUrlParameter(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  let email = getUrlParameter("email");
  let token = getUrlParameter("token");
  const { newPass, confirmnewPass, pinReveal, resetpasswordfail } = state.User;
  const { resettingPassword } = state.Loading;
  return {
    email,
    token,
    newPass,
    resettingPassword,
    confirmnewPass,
    pinReveal,
    resetpasswordfail
  };
};

export default connect(
  MapStateToProps,
  { resetPassword, getFormDetails, handlePinReveal }
)(ResetPassword);
