import _const from '../actions/Types'

const initialState = {
    subscriptionplans: [],
    subscriptionerr: "",
    currentsubscriptionpage: 1,
    allsubscriptionpages: 1,
    subscriptionplansbylocality: [],
    // currentsubscriptionplansbylocalitypage: 1,
    // allsubscriptionplansbylocalitypages: 1,
    currentplan: {},
    planType: "",
    planId: ""
}

const Subscriptionreducer = (state = initialState, action) => {
    switch (action.type) {

        case _const.ALL_SUBSCRIPTION_PLANS:
            return{...state, subscriptionplans: action.payload.data, currentsubscriptionpage: action.payload.meta.page, 
                allsubscriptionpages: action.payload.meta.numberOfPages}

        case _const.ALL_SUBSCRIPTION_BY_LOCALITY:
            return{...state, subscriptionplansbylocality: action.payload.data}

        case _const.CREATING_SUBSCRIPTION_ERROR:
            return{...state, subscriptionerr: action.payload}

        case _const.PLAN_TYPE:
            return{...state, planType: action.payload, planId: action.planId}

        case _const.CURRENT_SUBSCRIPTION_PLAN:
            return{...state, currentplan: action.payload}

        default:
            return state
    }
}

export default Subscriptionreducer