import React, { Component } from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Affiliated from './affiliated'
import {MainContent} from "../Partials/partials";

class AllBusiness extends Component {
    render() {

        return (
            <div className="row">
                <Sidenav />
                <MainContent className="main-content">
                    <div className="main-navbar sticky-top bg-white">
                        <Mainnav />
                    </div>
                    <BreadCrumbs />
                    <div className="main-content-container container-fluid px-4">
                        <Affiliated/>
                    </div>

                </MainContent>
            </div>
        );
    }
}


export default AllBusiness