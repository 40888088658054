import styled from "styled-components";
import { theme } from "../../theme";

// profile styles

export const ProfileDetails = styled.div`
  margin-bottom: 30px;
`;
export const DataKey = styled.p`
  color: ${theme.grey};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  margin-right: 10px;
  text-transform: uppercase;
`;
export const DataValue = styled.p`
  color: ${theme.black};
  font-size: ${theme.textSm};
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
`;

// wallet styles

export const WalletType = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.grey};
  margin-bottom: 20px;
`;
export const WalletData = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.textSm};
  line-height: 24px;
  color: ${theme.grey};
  margin-bottom: 10px;
`;
export const WalletValue = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25346a;
`;

export const SmallerWalletValue = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 36px;
  color: #25346a;
`;

// stake styles

export const StakeStatData = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.grey};
  margin-top: 10px;
`;
export const StakeStatValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29313c;
  margin-bottom: 10px;
`;
export const CommandButtons = styled.div`
  padding: 10px;
  border: none;
  background-color: ${theme.yellow};
  border-radius: 8px;
  color: #fefefe;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
` 

export const LogList = styled.li`
  list-style: disc;
  border-left: 1px solid #5a6169;
  padding-left: 10px;
  margin-bottom: 20px;
`
export const LogDetails = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
`
export const LogStep = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props =>  props.theme.black};
`
export const SpendInput = styled.input`
  text-align: center;
  border: none;
`