import React from "react";
import { StakeStatData, StakeStatValue } from "./styles";
import moment from "moment";
import { numberWithCommas } from "../../Helper";
import CustomModal from "../Reusables/modals/CustomModal";

const UserTransactionReportSingle = ({id, singleTransactionDetails}) => {

  return (
    <CustomModal
    id={id}
    close={true}
    heading={`Transaction Detail`}
    content={
      <div className="col-lg-12 ">
        <div className="card">
          <div className="card-body p-6 ">
            <div className="row mb-2">
              {singleTransactionDetails.name && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Name</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.name}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.deviceName && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Name</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.deviceName}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.email && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Email</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.email}
                  </StakeStatValue>
                </div>
              )}
              {/* {singleTransactionDetails.id && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Transation ID</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.id}
                  </StakeStatValue>
                </div>
              )} */}
              {singleTransactionDetails.walletAddress && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Wallet Address</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.walletAddress}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.referenceCode && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Reference</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.referenceCode}
                  </StakeStatValue>
                </div>
              )}
            </div>
            <div
              className="row mt-2 pt-2"
              style={{ borderTop: "1px solid #E8E8E8" }}
            >
              {singleTransactionDetails.transactionType && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Type</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.transactionType}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.liquidityProvider && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Liquidity Provider</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.liquidityProvider}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.buyAmount && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Buy Amount</StakeStatData>
                  <StakeStatValue>
                    {numberWithCommas(singleTransactionDetails.buyAmount, 4)}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.sellAmount && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Sell Amount</StakeStatData>
                  <StakeStatValue>
                    {numberWithCommas(singleTransactionDetails.sellAmount, 4)}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.currency && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Currency</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.currency}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.bankName && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Bank Name</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.bankName}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.accountNumber && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Account Number</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.accountNumber}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.network && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Network</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.network}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.amount && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Amount</StakeStatData>
                  <StakeStatValue>
                    {numberWithCommas(singleTransactionDetails.amount, 4)}{" "}
                    {singleTransactionDetails.currency}
                  </StakeStatValue>
                </div>
              )}
            </div>
            {singleTransactionDetails.liquidationProvider && (
              <div
                className="row mt-2 pt-2"
                style={{ borderTop: "1px solid #E8E8E8" }}
              >
                {singleTransactionDetails.liquidationProvider && (
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Liquidity Provider</StakeStatData>
                    <StakeStatValue>
                      {singleTransactionDetails.liquidationProvider}
                    </StakeStatValue>
                  </div>
                )}
                {singleTransactionDetails.buyCurrrency && (
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Buy Currency</StakeStatData>
                    <StakeStatValue>
                      {singleTransactionDetails.buyCurrrency}
                    </StakeStatValue>
                  </div>
                )}
                {singleTransactionDetails.sellCurrency && (
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Sell Currency</StakeStatData>
                    <StakeStatValue>
                      {singleTransactionDetails.sellCurrency}
                    </StakeStatValue>
                  </div>
                )}
                {singleTransactionDetails.buyAmount && (
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Buy amount</StakeStatData>
                    <StakeStatValue>
                      {numberWithCommas(singleTransactionDetails.buyAmount, 4)}{" "}
                      {singleTransactionDetails.buyCurrency}
                    </StakeStatValue>
                  </div>
                )}
                {singleTransactionDetails.sellAmount && (
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Sell Amount</StakeStatData>
                    <StakeStatValue>
                      {numberWithCommas(singleTransactionDetails.sellAmount, 4)}{" "}
                      {singleTransactionDetails.sellCurrency}
                    </StakeStatValue>
                  </div>
                )}
              </div>
            )}
            <div
              className="row mt-2 pt-2"
              style={{ borderTop: "1px solid #E8E8E8" }}
            >
              {singleTransactionDetails.destination && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Destination</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.destination}
                  </StakeStatValue>
                </div>
              )}
              {/* {singleTransactionDetails.wntPrice && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>WNT Price</StakeStatData>
                  <StakeStatValue>
                    $ {singleTransactionDetails.wntPrice}
                  </StakeStatValue>
                </div>
              )} */}
              {singleTransactionDetails.wallet && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Wallet</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.wallet}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.createdAt && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Date</StakeStatData>
                  <StakeStatValue>
                    {moment(singleTransactionDetails.createdAt).format(
                      "DD-MM-YYYY"
                    )}{" "}
                    |{" "}
                    {moment(singleTransactionDetails.createdAt).format(
                      "LT"
                    )}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.week && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Week</StakeStatData>
                  <StakeStatValue>
                    {singleTransactionDetails.week}
                  </StakeStatValue>
                </div>
              )}
              {singleTransactionDetails.status && (
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Status</StakeStatData>
                  <StakeStatValue className={singleTransactionDetails.status.toUpperCase()}>
                    {singleTransactionDetails.status}
                  </StakeStatValue>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    }
/>
  );
};

export default UserTransactionReportSingle;
