import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import TransactionOvervieiw from "./TransactionOverview/transactionOverview";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import TransactionsReport from "./TransactionReport/TransactionsReport";
import FraudList from "./FraudList";
import SwitchWalletReport from "./SwitchWallet";
import ReconciliationReport from "./ReconciliationReport";
import P2P from "./P2P";

class index extends Component {
  render() {
    const { toggleSubnav } = this.props;
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white mb-5">
            <Mainnav
              pageTitle="Transactions"
              listItems={[
                { id: 1, item: "Transaction Overview" },
                { id: 2, item: "Transaction Report" },
                // { id: 3, item: "Account Reconciliation" },
                { id: 4, item: "SwitchWallet Report" },
                { id: 6, item: "P2P Webhooks" },
                { id: 5, item: "Fraud List" },
              ]}
            />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            {toggleSubnav === 1 ? (
              <TransactionOvervieiw />
            ) : toggleSubnav === 2 ? (
              <TransactionsReport />
            ) : toggleSubnav === 3 ? (
              <ReconciliationReport />
            ) : toggleSubnav === 4 ? (
              <SwitchWalletReport />
            ) : toggleSubnav === 5 ? (
              <FraudList />
            ) : (
              <P2P />
            )}
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const { toggleSubnav } = state.General;

  return {
    toggleSubnav,
  };
};
export default connect(MapstateToProps)(index);
