// activity logs --------------------------------------------
export const activitylogsOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Action Type",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Transaction",
      },
      {
        id: 3,
        option: "Account",
      },
    ],
  },
  {
    id: 3,
    title: "Search",
    type: "text",
    placeholder: "Enter location, ip or device",
  },
  {
    id: 4,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "report-table",
  },
];

// transaction-------------------------------------------------------
export const transactionsHeaderOptionTypes = [
  {
    id: 1,
    name: "Reward Remit",
  },
  {
    id: 2,
    name: "withdrawal",
  },
];

export const transactionsOptions = [
  {
    id: 2,
    title: "Type",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Xend bridge (Peer to Peer)",
      },
      {
        id: 3,
        option: "Wallet address",
      },
    ],
  },
  {
    id: 3,
    title: "Duration",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 4,
    title: "Status",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Success",
      },
      {
        id: 4,
        option: "Failed",
      },
    ],
  },
  {
    id: 5,
    title: "Transaction ID",
    type: "text",
    placeholder: "Enter ID here",
  },
  {
    id: 6,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "transactions-report",
  },
];

export const remitRewardColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 170,
  },
];
export const withdrawalColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction ID",
    field: "id",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 170,
  },
];

// device-------------------------------------------------------------------------------------
export const deviceOptions = [
  {
    id: 1,
    title: "Search",
    type: "text",
    placeholder: "Enter device name or email",
  },
];

export const deviceHeaderOptionTypes = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Online",
  },
  {
    id: 3,
    name: "Offline",
  },
  {
    id: 4,
    name: "Blocked",
  },
];
