import styled from "styled-components";
import { theme } from "../../theme";


export const ValidityContainer = styled.div`      
    background-color: ${({ theme }) => theme.white};
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 10px;
` 
export const InlineFlex = styled.div`
    display: inline-flex;
`
export const Label = styled.label`      
    color: ${({ theme }) => theme.grey};
    padding-bottom: 10px;
    font-size: 0.6rem;
`
export const ValiditySelect = styled.select`
    background-color: ${({ theme }) => theme.selectgrey} !important;
    border-radius: 4px;
    font-size: 0.6rem !important;
    font-color: ${theme.grey} !important;
`