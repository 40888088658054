
import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'
import { getTrackingInfo, getBusinessDevices, toggleModal, setDeviceTracker, getFormDetails, gettingDevices } from '../../Redux/actions'
import { connect } from 'react-redux'
import Loader from '../Reusables/loaders/MoonLoader'
import DatePicker from "react-datepicker";
import moment from 'moment'
import { precise_round } from '../../Helper'
import Modal from '../Reusables/modals/Modal'
import Button from '../Reusables/buttons/Button'
import { Link } from 'react-router-dom'


class Tracker extends Component {

  componentWillMount() {
    const { id } = this.props
    this.props.getBusinessDevices(id);
    this.props.gettingDevices('');
  }
  componentWillReceiveProps(nextProps) {
    const { deviceToTrack } = nextProps
    if (deviceToTrack !== this.props.deviceToTrack) {
      this.props.getTrackingInfo(this.props.id, deviceToTrack, 1, 10000);
    }
  }

  validityInDays() {
    var arr = [];
    for (let i = 1; i < 32; i++) {
      arr.push(<option value={i}>{i + ' days'}</option>)
    }
    return arr;
  }

  render() {
    const { id, settingTracker, createTrackingDevice, dataAmountInBytes,
      trackerError, validityinDays, subscriptionDate, gettingBusinessDevices, userId,
      gettingTrackingInfo, deviceTrackerInfo, managerDevices } = this.props
    let allRows = []
    deviceTrackerInfo.map(trackingInfo => {
      return allRows.push({
        subscriptiondate: moment.parseZone(trackingInfo.subscriptionDate).format('YYYY-MM-DD'),
        dataAmountInBytes: precise_round(trackingInfo.dataAmountInBytes / 1073741824, 2) + 'GB',
        subscriptiontionValidity: trackingInfo.validityInDays,
        expiryDate: moment.parseZone(trackingInfo.expiryDate).format('YYYY-MM-DD'),
      }
      )
    })

    var date = subscriptionDate;
    var timestamp = date.getTime();


    const data = {
      columns: [
        {
          label: 'Subscription Date',
          field: 'subscriptiondate',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Data Value',
          field: 'dataAmountInBytes',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Subscription Validity',
          field: 'subscriptiontionValidity',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Expiry Date',
          field: 'expiryDate',
          sort: 'asc',
          width: 100
        },

      ],

      rows: allRows
    };

    return (
      <div className="col-lg-12 mb-4">

        <div className="card-header mb-1 d-flex">
          <div>
            <Link to={`/affilaited/${id}/${userId}`}>
              <img height={30} width={30} src='/Assets/images/leftArrow.jpeg' alt='Back arrow' />
            </Link><br />
            <p className='trackers'> Device Tracker </p>
          </div>
          <div className='dateRange' >
            <select className="input-sm form-control m-10"
              style={{ width: 'auto' }} onChange={(e) => this.props.getFormDetails({ props: ['deviceToTrack'], value: e.target.value })}>
              <option>Select Device</option>
              {managerDevices.length > 0 ? managerDevices.map(each => {

                return (
                  userId !== each.user.id ? <option value={each.id}>{each.name}</option> : ''
                )
              }) : ''}

            </select>
            <div className="card-header mb-3 createbtn">
              <i className="fa fa-plus" aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.toggleModal(true, '', 'setRate')}>Create Tracker for a Device</i>
            </div>
          </div>

        </div>
        <Modal heading={'TRACK SUBSCRIPTION DATA'}>

          <div className='col-lg-12'>

            <label> Data amount in GB</label>
            <div className='mb-3'>

              <input type='number' value={dataAmountInBytes} className="form-control mr-2 loginCred"
                onChange={(e) => this.props.getFormDetails({ props: ['dataAmountInBytes'], value: e.target.value })}></input>

            </div>
            <label> Subscription Date</label>


            <div className="form-control loginCred mb-3">
              <DatePicker
                className="dateRange b-0 text-left"
                selected={subscriptionDate}
                onChange={(date) => this.props.getFormDetails({ props: ['subscriptionDate'], value: date })}
              />
            </div>

            <div className="input-group mb-2">

              <select className="form-control loginCred"
                onChange={(e) => this.props.getFormDetails({ props: ['createTrackingDevice'], value: e.target.value })}
              >
                <option>Select Device</option>
                {managerDevices.length > 0 ? managerDevices.map(each => {
                  return (
                    userId !== each.user.id ? <option value={each.id}>{each.name}</option> : ''
                  )
                }) : ''}
              </select>
            </div>

            <label> Select validity period </label>
            <div className="input-group mb-2">

              <select className="form-control loginCred"
                onChange={(e) => this.props.getFormDetails({ props: ['validityinDays'], value: e.target.value })}
              >
                {this.validityInDays()}
              </select>
            </div>
            <p className='text-red t-12 t-center'> {trackerError}</p>
            <Button theme="btn-primary ml-3 mt-2 float wicryptColor"
              buttonAction={() => this.props.setDeviceTracker(createTrackingDevice,
                timestamp, validityinDays, dataAmountInBytes, userId)}
            >
              {settingTracker ? <Loader /> : 'Track Data'}
            </Button>
          </div>


        </Modal>
        {gettingTrackingInfo || gettingBusinessDevices === true ?
          <div className="volucherNote mt-5 t-center " ><Loader size='10' /> </div> :
          deviceTrackerInfo.length > 0 ?
            <div className="card card-small mb-4 dataTable">
              <MDBDataTable
                striped
                hover
                data={data}
                searchLabel='Search'
                responsive
              />
            </div>
            : <p className="voucherText t-center mt-3"> Please select a device to track</p>
        }

      </div>

    );
  }
}
const MapStateToProps = (state, props) => {
  const id = props.match.params.id;
  const { businessDevices, deviceTrackerInfo, managerDevices, trackerError } = state.Manager
  const { gettingTrackingInfo, gettingBusinessDevices, settingTracker, } = state.Loading
  const { deviceToTrack, userId, dataAmountInBytes, validityinDays, subscriptionDate, funct, createTrackingDevice } = state.User
  return {
    id,
    businessDevices,
    gettingTrackingInfo,
    deviceToTrack, createTrackingDevice,
    deviceTrackerInfo, managerDevices,
    gettingBusinessDevices,
    userId, trackerError, funct, settingTracker,
    dataAmountInBytes, validityinDays, subscriptionDate
  }
}
export default connect(MapStateToProps, { getTrackingInfo, setDeviceTracker, toggleModal, getBusinessDevices, gettingDevices, getFormDetails })(Tracker);