import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
// import TableHeader from "../Reusables/tables/TableHeader";
import { LatestDevicesCols } from "../../utils/TableCols/DeviceCols";
import { useEffect } from "react";
import { getLatestDevices } from "../../Redux/actions/AdminAction";
import { getLatestDeviceRows } from "../../utils/TableRows/DevicesRows";

const LatestDevices = () => {
  const { latestDevices, gettingLatestDevices } = useSelector(
    (state) => state.Admin
  );
  const { toggleSubnav } = useSelector((state) => state.General);

  const dispatch = useDispatch();

  useEffect(() => {
    const queries = { PageSize: null };
    getLatestDevices(dispatch, queries);
  }, []);
  const allRows = getLatestDeviceRows(latestDevices, toggleSubnav );
  const data = {
    columns: LatestDevicesCols,
    rows: allRows,
  };

  return (
    <div className="col-lg-12 mb-4">
      <CustomDataTable
        loading={gettingLatestDevices}
        // header={
        //   <TableHeader
        //     onClick={() => this.props.toggleModal(true)}
        //     onChange={(e, props) => {
        //       this.props.getFormDetails({
        //         props: [props],
        //         value: e.target.value,
        //       });
        //     }}
        //     onSelect={(val, props) => {
        //       this.props.getFormDetails({
        //         props: [props],
        //         value: val,
        //       });
        //     }}
        //   />
        // }
        data={data}
      />
    </div>
  );
};

export default LatestDevices;
