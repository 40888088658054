import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import { dailyTransactionReports, getSearchDetails } from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import TableHeader from "../../Reusables/tables/TableHeader";
import { Link } from "react-router-dom";
import moment from "moment"


const ReconciliationReport = () => {

    const dispatch = useDispatch();

    const { dailyReports, dailyReporttotalPages, dailyReportcurrentPage } = useSelector((state) => state.Transactions);
    const { gettingDailyReports } = useSelector((state) => state.Loading)
    const [reconciliationOptions, setreconciliationOptions] = useState(
        [
            {
                id: 1,
                title: "Duration",
                type: "select",
                onSelectProp: "duration",
                options: [
                    {
                        id: 1,
                        option: "All",
                    },
                    {
                        id: 2,
                        option: "Last Week",
                    },
                    {
                        id: 3,
                        option: "Last Month",
                    },
                    {
                        id: 4,
                        option: "Last 5 Months",
                    },
                    {
                        id: 5,
                        option: "Last Year",
                    },
                    {
                        id: 6,
                        option: "Custom",
                    },
                ],
            },
        ]);
    const {
        duration,
        Page,
        PageSize,
        startTime,
        endTime
    } = useSelector((state) => state.Search);



    let allRows = [];
    dailyReports.map((report) => {
        return allRows.push({
            dateCreated: <Link className="text-blue text-bold" to={`/reconcilliation/${report.date}`} >{moment(report.date).format("DD-MM-YYYY")}</Link>,
            transactionDetails: <div>
                {report.transactions && report.transactions.map(each => {
                    return (
                        <p className={each.transactionCount > 0 ? "mb-0 SUCCESS" : "mb-0 FAILED"}> {each.transactionType} : <span>{each.transactionCount}</span> </p>
                    )
                })}
            </div>,
            transactionCount: <div> {report.totalTransactionCount}  </div>,
            viewMore: <Link to={`/reconcilliation/${report.date}`}
            className="t-center"
          >
            View Details <img src="/Assets/arrowRight.svg"
              className=" t-center"
              height="20px"
              alt="view more"

            />
          </Link>

        });
    });
    const data = {
        columns: [
            {
                label: "Date",
                field: "dateCreated",
                sort: "asc",
                width: 170,
            },
            {
                label: "Transaction Details",
                field: "transactionDetails",
                sort: "asc",
                width: 170,
            },
            {
                label: "Transaction Count",
                field: "transactionCount",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "viewMore",
                sort: "asc",
                width: 170,
            },

        ],
        rows: allRows,
    };

    useEffect(() => {
        let start = startTime ? new Date(startTime).getTime() : ""
        let end = endTime ? new Date(endTime).getTime() : ""
        dispatch(dailyTransactionReports(start, end, duration, Page, PageSize))
    }, [duration, Page, PageSize, startTime, endTime]);

    useEffect(() => {
        if (duration === "Custom") {

            setreconciliationOptions([
                {
                    id: 1,
                    title: "Duration",
                    type: "select",
                    onSelectProp: "duration",
                    options: [
                        {
                            id: 1,
                            option: "All",
                        },
                        {
                            id: 2,
                            option: "Last Week",
                        },
                        {
                            id: 3,
                            option: "Last Month",
                        },
                        {
                            id: 4,
                            option: "Last 5 Months",
                        },
                        {
                            id: 5,
                            option: "Last Year",
                        },
                        {
                            id: 6,
                            option: "Custom",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "Start Time",
                    type: "startTime",
                    onChangeProp: "startTime",
                },
                {
                    id: 4,
                    title: "End Time",
                    type: "endTime",
                    onChangeProp: "endTime",
                },
            ]);
        } else {
            setreconciliationOptions([
                {
                    id: 1,
                    title: "Duration",
                    type: "select",
                    onSelectProp: "duration",
                    options: [
                        {
                            id: 1,
                            option: "All",
                        },
                        {
                            id: 2,
                            option: "Last Week",
                        },
                        {
                            id: 3,
                            option: "Last Month",
                        },
                        {
                            id: 4,
                            option: "Last 5 Months",
                        },
                        {
                            id: 5,
                            option: "Last Year",
                        },
                        {
                            id: 6,
                            option: "Custom",
                        },
                    ],
                },

            ]);
        }
    }, [duration]);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"reconciliation-report"}
                            pageCount={dailyReporttotalPages}
                            currentPage={dailyReportcurrentPage}
                            header={
                                <TableHeader
                                    subHeader={reconciliationOptions}
                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );

                                    }}
                                    onChange={(e, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            }
                            loading={gettingDailyReports}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"You have no  account reconcilliation report for the set parameters"}
                                // descr={"No transaction history available at the moment"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReconciliationReport;
