import React, { useEffect } from "react";
import { userDeviceCols } from "../../utils/TableCols/DeviceCols";
import { getAllDevicesRows } from "../../utils/TableRows/DevicesRows";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDevices } from "../../Redux/actions/AdminAction";
import { toggleModal } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import {
  allDevicesHeaderOptions,
  allDevicesHeaderOptionTypes,
} from "../Devices/options";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import { Notification, TableFlex } from "../Reusables/tables/tablestyles";

const UserDevices = ({ userId }) => {
  const { adminDevices, gettingAdminDevices } = useSelector(
    (state) => state.Admin
  );
  const { Page, Name, Status, Type, Model, OrderBy } = useSelector(
    (state) => state.Search
  );
  const { activeTab } = useSelector((state) => state.User);
  const { toggleSubnav } = useSelector((state) => state.General);
  
  const allRows = getAllDevicesRows(
    adminDevices.records,
    userId,
    true,
    activeTab,
    toggleSubnav
  );
  const dispatch = useDispatch();

  const data = {
    columns: userDeviceCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  useEffect(() => {
    const queries = {
      Page,
      Name,
      Status,
      Type,
      Model,
      OrderBy,
      UserId: userId,
    };
    getAdminDevices(dispatch, queries);
  }, [Page, Name, Status, Type, Model, OrderBy]);

  return (
    <div className="">
      <CustomDataTable
        tableId={"all-devices-report"}
        data={data}
        pageCount={adminDevices.totalPages}
        currentPage={adminDevices.currentPage}
        notification={
          <TableFlex>
            <Notification>
              Note: Device names with this icon
              <img src="/Assets/adminicons/info.svg" alt="info" />
              is backed by an NFT
            </Notification>
          </TableFlex>
        }
        header={
          <TableHeader
            subHeaderTypes={allDevicesHeaderOptionTypes}
            subHeader={allDevicesHeaderOptions}
            ontabClick={(val) => {
              dispatch(clearSearch());
              dispatch(
                getSearchDetails({
                  props: ["Status"],
                  value: val,
                })
              );
            }}
            onClick={() => dispatch(toggleModal(true))}
            onChange={(e, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            searchvalue={Name}
          />
        }
        loading={gettingAdminDevices}
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no device"}
            // descr={"No Device available at the moment"}
          />
        }
      />
    </div>
  );
};

export default UserDevices;
