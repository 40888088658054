import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMarketers,
  referalupdate,
  copyToClipboard,
} from "../../Redux/actions";
import CustomLoader from "../Reusables/loaders/CustomLoader"
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard"
import {ActionButton} from "../Reusables/inputs";
import {BigblueText, Title, Instruction, DFlex, Claimed, NotClaimed} from "./referralstyles";
import { numberWithCommas } from "../../Helper";

class Marketer extends Component {
  componentDidMount() {
    this.props.getMarketers(this.props.refCode);
  }

  render() {
    const { refered, referalLoading, refCode, copied, currencyValue } = this.props;
    let allRows = [];
    refered.map((ref, index) => {
      return (
        allRows.push({
          no: index + 1,
          date: ref.createdAt.slice(0, 10),
          name: ref.name,
          email: ref.email,
          earned: (
            <b className="text-bold bold t-12">
              <div> <p className="mb-0">{ "NGN " + numberWithCommas(ref.bonus * currencyValue)}</p>
              <p className="mb-0 t-8 littleamt">~{ref.bonus}WC</p></div>
             
            </b>
          ),
          claimed: ref.isClaimed ? <Claimed> Claimed</Claimed> : <NotClaimed> Not Claimed</NotClaimed>,
        })
      )
    });

    const data = {
      columns: [
        {
          label: "#",
          field: "no",
          sort: "asc",
          width: 170,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 170,
        },
        {
          label: "Referee Name",
          field: "name",
          sort: "asc",
          width: 170,
        },
        {
          label: "Referee Email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Amount Earned",
          field: "earned",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "claimed",
          sort: "asc",
          width: 170,
        },
        
      ],

      rows: allRows,
    };

    return (
      <div>
        <div className="row">
          <div className="col-lg">
            <div className="stats-small stats-small--1 card card-small mb-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                  <div className="p-3">
                    <Title
                    >
                      Your referral code
                    </Title>
                    <DFlex>
                    <BigblueText
                    >
                      {refCode}
                    </BigblueText>
                    <div className="mr-5">
                      <ActionButton title={copied === refCode ? "Copied" : "Copy Code"} img={'/Assets/images/copy.svg'} buttonAction={()=> this.props.copyToClipboard(refCode)}/> 
                    </div>
                   
                    </DFlex>
                    
                    
                  </div>
                  <div className="col-lg-8">
                   <Instruction>
                      {" "}
                      Copy your referral code and share with friends to earn more Wicrypt credit(WC) everytime your friend
                       performs a transaction on their Wicrypt app. 

                    </Instruction>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {refered.length > 0 ? (
          // <div >
          <CustomDataTable
            data={data}
            title="Referals"
          // pagination={this.customPagination()}
          />
          // </div>
        ) : (
            <div>
              {" "}

              {referalLoading ? (
               
                <CustomLoader />

              ) : (
                <div className="row">
                <EmptyCard img="/Assets/emptytransactions.svg" title={'Sorry,  you have no referrals'} descr={'Refer people to earn rewards'}/>
                </div>
                )}
                
            </div>
          )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { refered } = state.Marketer;
  const { refCode, copied, currencyValue} = state.User;
  const { referalLoading } = state.Loading;
  return {
    refCode,
    refered,
    referalLoading,
    copied,
    currencyValue
  };
};

export default connect(MapStateToProps, {
  getMarketers,
  referalupdate,
  copyToClipboard,
})(Marketer);
