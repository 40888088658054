import _const from "../actions/Types";
import axios from "axios";

const initialState = {
  newId: "",
  operation: {},
  newPass: "",
  newAddress: "",
  newDeviceName: "",
  clickedDeviceId: "",
  voucherCode: "",
  funct: "",
  error: "",
  phoneNumberError: "",
  voucherCodeError: "",
  notEmail: "",
  newRate: "",
  recipientEmail: "",
  amountOfToken: "",
  deviceId: "",
  code: "",
  dataVolume: "GB",
  token: "",
  auth: false,
  sessionsCount: "",
  totalBytes: "",
  usersCount: "",
  revenues: "",
  location: 1,
  switchTo: 0,
  role: [],
  name: "",
  refCode: "",
  email: "",
  loginEmail: "",
  password: "",
  balance: "",
  bought: "",
  promoBalance: "",
  sold: "",
  loginErrorMsg: "",
  modal: false,
  maxUsers: "",
  expiresAt: new Date(),
  editExpiry: new Date(),
  currencyValue: 360,
  status: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  merchantName: "",
  merchantAddress: "",
  merchantLogo: "",
  pinReveal: 0,
  merchantEmail: "",
  currencyCode: "NGN",
  logginInUser: {},
  agentEmail: "",
  agentRole: "Agent",
  merchantError: "",
  emailFail: "",
  businessBalance: "",
  userId: "",
  accountNumber: "",
  amtToWithdraw: 0,
  bankId: 0,
  withdrawalOtp: "",
  withdrawalPin: "",
  narration: "",
  otpError: "",
  deviceStart: new Date(),
  deviceEnd: new Date(),
  dateStart: new Date().getTime(),
  dateEnd: new Date().getTime(),
  imagePreview: "",
  voucherAmt: 0,
  voucherValidity: "",
  voucherMsg: "open",
  createSubsidiary: false,
  copied: false,
  checkUser: false,
  newUserName: "",
  googleLogin: 0,
  newUserEmail: "",
  regError: "",
  referralCode: "",
  BVN: "",
  once: 0,
  resolutionErr: "",
  accountname: "",
  bvnErr: "",
  passportImg: "",
  idCard: "",
  passportImg1: "",
  idCard1: "",
  verificationStatus: "",
  voucherPhoneNumber: "",
  voucherStatus: "",
  percentage: 0,
  beneficiaryEmail: "",
  withdrawaldateStart: "",
  withdrawaldateEnd: "",
  sessionDateStart: new Date().getTime(),
  sessionDateEnd: new Date().getTime(),
  allsessionDateStart: "",
  allsessionDateEnd: "",
  transactionDateStart: new Date().getTime(),
  transactionDateEnd: new Date().getTime(),
  withdrawalStatus: "",
  paymentGateway: "",
  withdrawalbusinessId: "",
  isActive: true,
  sessionDeviceId: "",
  verificationRejectionReason: "",
  transactionSubCategory: "",
  transactionCategory: "Funding",
  myBusinessId: "",
  dataAmountInBytes: "",
  validityinDays: "",
  subscriptionDate: new Date(),
  deviceToTrack: "",
  createTrackingDevice: "",
  bulkvoucherStatus: "",
  voucherstartDate: "",
  bulkvoucherstartDate: "",
  bulkvoucherendDate: "",
  voucherendDate: "",
  transactionStartDate: "",
  transactionEndDate: "",
  selectedBusiness: "",
  remitStart: "",
  remitEnd: "",
  selectedAgent: "",
  outstandingremitStart: "",
  outstandingremitEnd: "",
  outstandingselectedAgent: "",
  remitRate: "",
  agentRemit: "",
  remitReceivedDate: new Date().getTime(),
  selectedAffiliatedBusiness: "",
  voucherQty: "",
  bulkvoucherReceipient: "",
  voucherbalancePhoneNumber: "",
  withdrawalrejectreasons: "",
  dataUsagedateStart: "",
  dataUsagedateEnd: "",
  enableVoucherLogin: true,
  requireRouterCodeLogin: true,
  fieldname: "",
  placeholder: "",
  maxLength: 0,
  required: false,
  fieldtype: "TextField",
  allformControl: [],
  userDataAllocation: 0,
  allocationType: 1,
  createnewform: false,
  subsessionbusiness: false,
  days: false,
  options: "",
  formControlOptions: [],
  formtype: "normal",
  tabname: "",
  tabdescription: "",
  alltabs: [],
  currenttab: 0,
  nextform: 0,
  newformErr: "",
  reportformNo: 0,
  formId: "",
  responseformat: 0,
  dateFrom: "",
  dateTo: "",
  fundCategory: "",
  baseconversionrate: "",
  basehourlyrate: "",
  advertImg: "",
  advertstartdate: "",
  billingtype: "",
  subscriptionname: "",
  subscriptionmonthlyfee: "",
  subscriptionyearlyfee: "",
  selectedcountrycode: "",
  externalLink: "",
  CountryCode: { name: "Afghanistan", code: "AF" },
  confirmpassword: "",
  regInputError: "",
  confirmnewPass: "",
  resetpasswordfail: "",
  minimumdevices: 0,
  maximumdevices: 0,
  showinfo: 0,
  bytesAllocated: "",
  secondsAllocated: "",
  quantity: "",
  dataTokenUsageType: "",
  tokenusagetype: "",
  tokenStatus: "",
  cutomertokenemail: "",
  searchcustomeremail: false,
  volume: "GB",
  selectedid: 0,
  selectedwallet: "",
  amtToFund: "",
  totalbalance: "",
  fundAmt: 0,
  transferAmt: 0,
  transferrecipientemail: "",
  walletoptions: [
    { id: 1, option: "Personal" },
    { id: 2, option: "Business" },
  ],
  personaloptions: [{ id: 1, option: "Personal" }],
  selectedwalletoption: "Personal",
  toggleDropdown: false,
  userpin: "",
  transferAmtError: "",
  transferEmailError: "",
  transferPinError: "",
  togglesubnav: false,
  bankdropdown: false,
  selectedbank: "Select a bank account",
  bankAcctError: "",
  withdrawalAmtError: "",
  accountNumberError: "",
  withdrawalpinError: "",
  withdrawalOtpError: "",
  bvnFirstName: "",
  bvnLastName: "",
  bvnMiddleName: "",
  bvnAccountNumber: "",
  formName: "",
  viewbalance: 0,
  opendatefrom: false,
  opendateto: false,
  selectedDevice: "",
  sessionDeviceSearch: "",
  livesessionDeviceSearch: "",
  singlevouchercodeSearch: "",
  bulkvouchercodeSearch: "",
  devicenameSearch: "",
  agentSearch: "",
  supportToggle: false,
  businessNameSearch: "",
  verificationId: "",
  overviewDateFrom: new Date().getTime(),
  overviewDateTo: new Date().getTime(),
  searchCountry: "",
  searchSubscription: "",
  searchBusinessAgent: "",
  searchUser: "",
  verificationSearch: "",
  idType: "",
  idNumber: "",
  activeTab: 1,
  duration: "",
  commandCode: "",
  commandName: "",
  commandId: "",
  issueOptions: 0,
  commandStatus: "",
  commandDevice: "",
  wicryptDeviceName: "",
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case _const.LOGIN_FAIL:
      return {
        ...state,
        loginErrorMsg: action.payload,
      };
    case _const.ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case _const.COUNTRY_EDITED:
      return {
        ...state,
        baseconversionrate: "",
        basehourlyrate: "",
      };
    case _const.EYE_STATUS:
      return { ...state, pinReveal: action.payload };

    case _const.CREATE_IMAGE_PREVIEW:
      return {
        ...state,
        imagePreview: action.payload,
        merchantLogo: action.value,
      };

    case _const.LOGIN_USER:
      saveUser(action.payload);
      return {
        ...state,
        token: action.payload.token,
        auth: true,
        verificationStatus: action.payload.verificationStatus,
        userId: action.payload.id,
        role: action.payload.roles,
        refCode: action.payload.refCode,
        email: action.payload.email,
        name: action.payload.name,
        balance: action.payload.balance,
        // businessBalance: action.payload.businessBalance,
        bought: action.payload.usage.bought,
        promoBalance: action.payload.promoBalance,
        sold: action.payload.usage.sold,
      };

    case _const.INITIALIZE_USER:
      saveUser(action.payload);
      let business = localStorage.getItem("business");
      return {
        ...state,
        token: action.payload.token,
        auth: true,
        verificationStatus: action.payload.verificationStatus,
        userId: action.payload.id,
        role: action.payload.roles,
        refCode: action.payload.refCode,
        email: action.payload.email,
        name: action.payload.name,
        balance: action.payload.balance,
        // businessBalance: action.payload.businessBalance,
        bought: action.payload.usage ? action.payload.usage.bought : 0,
        promoBalance: action.payload.promoBalance,
        sold: action.payload.usage ? action.payload.usage.sold : 0,
        businessProfile: JSON.parse(business),
      };
    case _const.GET_BUSINESS_BALANCE:
      return {
        ...state,
        businessBalance: action.payload.businessBalance,
        balance: action.payload.balance,
        totalbalance: action.payload.totalbalance,
      };

    case _const.GET_ADMIN_INFO:
      return {
        ...state,
        sessionsCount: action.payload.sessionsCount,
        totalBytes: action.payload.totalBytes,
        usersCount: action.payload.usersCount,
        revenues: action.payload.totalBilling,
      };

    case _const.CHANGE_LOCATION:
      return { ...state, location: action.payload };

    case _const.SWITCH_INFO:
      return { ...state, switchTo: action.payload };

    case _const.FILL_OUT_FORM:
      return {
        ...state,
        [action.payload.props]: action.payload.value,
        loginErrorMsg: "",
        error: "",
        notEmail: "",
      };

    case _const.RATE_SET:
      return { ...state, newRate: "" };

    case _const.EMPTY_FORM:
      return { ...state, [action.payload.props]: action.payload.value };

    case _const.MODAL_STATE:
      return {
        ...state,
        modal: action.payload,
        clickedDeviceId: action.id,
        funct: action.funct,
      };

    case _const.CLEAR_FORMS:
      return {
        ...state,
        newId: "",
        newPass: "",
        newAddress: "",
        newDeviceName: "",
        clickedDeviceId: "",
        funct: "",
        error: "",
        notEmail: "",
        newRate: "",
        recipientEmail: "",
        amountOfToken: "",
        deviceId: "",
        code: "",
        dataVolume: "GB",
        token: "",
        sessionsCount: "",
        totalBytes: "",
        usersCount: "",
        revenues: "",
        location: 1,
        switchTo: 0,
        loginEmail: "",
        password: "",
        balance: "",
        bought: "",
        promoBalance: "",
        sold: "",
        loginErrorMsg: "",
        modal: false,
        maxUsers: "",
        expiresAt: new Date(),
        editExpiry: new Date(),
        currencyValue: 360,
        status: "PENDING",
        startDate: new Date().getTime(),
        endDate: new Date().getTime(),
        merchantName: "",
        merchantAddress: "",
        merchantLogo: "",
        merchantEmail: "",
        currencyCode: "NGN",
        agentEmail: "",
        agentRole: "Agent",
        merchantError: "",
        emailFail: "",
        businessBalance: "",
        accountNumber: "",
        amtToWithdraw: "",
        bankId: 0,
        withdrawalOtp: "",
        withdrawalPin: "",
        narration: "",
        otpError: "",
        deviceStart: new Date(),
        deviceEnd: new Date(),
        dateStart: new Date().getTime(),
        dateEnd: new Date().getTime(),
        imagePreview: "",
        voucherAmt: 0,
        voucherValidity: "",
        voucherMsg: "open",
        createSubsidiary: false,
        copied: false,
        checkUser: false,
        newUserName: "",
        googleLogin: 0,
        newUserEmail: "",
        regError: "",
        referralCode: "",
        BVN: "",
        once: 0,
        resolutionErr: "",
        accountname: "",
        bvnErr: "",
        passportImg: "",
        idCard: "",
        passportImg1: "",
        idCard1: "",
        voucherPhoneNumber: "",
        voucherStatus: "",
        beneficiaryEmail: "",
        sessionDateStart: new Date(),
        sessionDateEnd: new Date(),
        allsessionDateStart: "",
        allsessionDateEnd: "",
        withdrawalStatus: "",
        paymentGateway: "",
        withdrawalbusinessId: "",
        isActive: true,
        sessionDeviceId: "",
        verificationRejectionReason: "",
        transactionSubCategory: "",
        transactionCategory: "Funding",
        dataAmountInBytes: "",
        validityinDays: "",
        subscriptionDate: new Date(),
        deviceToTrack: "",
        createTrackingDevice: "",
        voucherstartDate: "",
        voucherendDate: "",
        transactionStartDate: "",
        transactionEndDate: "",
        selectedBusiness: "",
        remitStart: "",
        remitEnd: "",
        selectedAgent: "",
        outstandingremitStart: "",
        outstandingremitEnd: "",
        outstandingselectedAgent: "",
        remitRate: "",
        agentRemit: "",
        remitReceivedDate: new Date().getTime(),
        selectedAffiliatedBusiness: "",
        voucherQty: "",
        bulkvoucherReceipient: "",
        voucherbalancePhoneNumber: "",
        livesessionDateEnd: "",
        livesessionDateStart: "",
      };

    case _const.EDIT_USERINFO:
      return { ...state, accountNumber: "", amtToWithdraw: "" };

    case _const.FOUND_USER_INFO:
      return { ...state, logginInUser: action.payload, checkUser: true };

    case _const.MERCHANT_REGISTERED:
      return { ...state, logginInUser: {}, checkUser: false };

    case _const.MERCHANT_REGISTRATION_SUCCESSFUL:
      return {
        ...state,
        merchantName: "",
        merchantAddress: "",
        merchantLogo: "",
        imagePreview: "",
      };

    case _const.MERCHANT_REG_ERROR:
      return { ...state, merchantError: action.payload };

    case _const.BY_EMAIL_FAIL:
      return { ...state, emailFail: action.payload };

    case _const.DELETING_DEVICE:
      return { ...state, clickedDeviceId: action.id };

    case _const.DELETING_TOKEN:
      return { ...state, clickedDeviceId: action.id };

    case _const.CURRENCY_RATE:
      return {
        ...state,
        currencyValue: action.payload.rate,
        currencyCode: action.payload.code,
      };

    case _const.OTP_ERROR:
      return { ...state, otpError: action.payload };

    case _const.BUSINESS_PROFILE:
      return {
        ...state,
        businessProfile: action.payload,
        createSubsidiary: action.payload.canCreateSubsidiary,
        myBusinessId: action.payload.id,
      };

    case _const.TEXT_COPIED:
      return { ...state, copied: action.payload };

    case _const.FILE_TO_BASE:
      return { ...state, advertImg: action.payload };

    case _const.REG_ERROR:
      return {
        ...state,
        regError: action.payload,
        googleLogin: action.status,
        newUserEmail: "",
        referralCode: "",
        regInputError: "",
        confirmpassword: "",
        password: "",
      };

    case _const.REG_INPUT_ERROR:
      return { ...state, regInputError: action.payload };

    case _const.BVN_RESOLVED:
      return { ...state, once: 1, accountname: action.payload };

    case _const.BVN_RESOLUTION_ERROR:
      return {
        ...state,
        once: 0,
        BVN: "",
        bvnErr: action.payload,
        accountname: "",
        passportImg1: "",
        idCard1: "",
      };

    case _const.UPLOAD_IMAGE_FILE:
      return {
        ...state,
        [action.value.props]: action.value.value,
        [action.value.props + "1"]: action.payload,
      };

    case _const.REMIT_SUCCESS:
      return { ...state, modal: false, remitRate: "", agentRemit: "" };

    case _const.RESET_PASSWORD_FAIL:
      return { ...state, resetpasswordfail: action.payload };

    case _const.LOG_OUT:
      return { ...state, auth: false };
    case _const.GETTING_ALL_USER_TOKEN:
      return { ...state, searchcustomeremail: false };

    case _const.CLEAR_DYNAMIC_FORMS:
      return { ...state, alltabs: [], allformControl: [] };

    case _const.BUSINESS_TOKEN_CREATED:
      return {
        ...state,
        volume: "",
        tokenusagetype: "",
        bytesAllocated: "",
        quantity: "",
        secondsAllocated: "",
      };

    case _const.CREDIT_TRANSFERRED:
      return {
        ...state,
        transferAmt: 0,
        transferrecipientemail: "",
        walletoptions: [
          { id: 1, option: "Personal" },
          { id: 2, option: "Business Wallet" },
        ],
        selectedwalletoption: "Personal",
        toggleDropdown: false,
        userpin: "",
        transferAmtError: "",
        transferEmailError: "",
        transferPinError: "",
      };

    case _const.WITHDRAWAL_SUCCESSFUL:
      return {
        ...state,
        bankdropdown: false,
        selectedbank: "Select a bank account",
        bankAcctError: "",
        amtToWithdraw: "",
        withdrawalAmtError: "",
        accountNumberError: "",
        withdrawalpinError: "",
        withdrawalOtpError: "",
      };

    case _const.UPGRADING_ACCT_SUCCESS:
      return {
        ...state,
        CountryCode: { name: "Afghanistan", code: "AF" },
        bvnFirstName: "",
        bvnLastName: "",
        bvnMiddleName: "",
        bvnAccountNumber: "",
        BVN: "",
        idCard: "",
        passportImg1: "",
        idCard1: "",
      };

    case _const.TOGGLE_MODAL:
      if (action.payload === 0) {
        return {
          ...state,
          amtToWithdraw: 0,
          transferAmt: 0,
          transferrecipientemail: "",
          walletoptions: [
            { id: 1, option: "Personal" },
            { id: 2, option: "Business" },
          ],
          personaloptions: [{ id: 1, option: "Personal" }],
          selectedwalletoption: "Personal",
          toggleDropdown: false,
          userpin: "",
          transferAmtError: "",
          transferEmailError: "",
          transferPinError: "",
          togglesubnav: false,
          bankdropdown: false,
          selectedbank: "Select a bank account",
          bankAcctError: "",
          withdrawalAmtError: "",
          accountNumberError: "",
          withdrawalpinError: "",
          bankId: 0,
          withdrawalOtp: "",
          withdrawalPin: "",
          narration: "",
          otpError: "",
          withdrawalOtpError: "",
        };
      } else {
        return { ...state };
      }

    case _const.ID_VERIFICATION_SUCCESS:
      return { ...state, idType: "", idNumber: "" };

    case _const.ONE_VERIFICATION_REQUEST:
      return { ...state, idType: "", idNumber: "" };

    case _const.CREATING_COMMANDS_SUCCESSFUL:
      return{...state, commandCode: "", commandName: ""}

    default:
      return state;
  }
};

const saveUser = (payload) => {
  localStorage.setItem("user", JSON.stringify(payload));
  axios.defaults.headers.common["Authorization"] = "Bearer " + payload.token;
};

export default User;
