import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { proposedRewards, getSearchDetails, handleSubNav, exportReport } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import TableHeader from "../Reusables/tables/TableHeader";
import {
    numberWithCommaswithoutdecimals
} from "../../Helper";
import { Link } from "react-router-dom";
import Loader from "../Reusables/loaders/MoonLoader";


const UploadedRewards = () => {

    const dispatch = useDispatch();
    const { proposedReward, proposedRewardCurrentPage, proposedRewardTotalPages } = useSelector((state) => state.Miners);
    const { gettingProposedReward, exportingReport} = useSelector((state) => state.Loading);
    const {
        Page,
        PageSize,
    } = useSelector((state) => state.Search);


    const minersOptions = [
        {
            id: 4,
            title: exportingReport ? <Loader color="#fefefe" /> : "Export to email",
            type: "exportToEmail",
            icon: "receipt_long",
            tableId: "report-table",
        },
    ]



    let allRows = [];
    proposedReward.map((reward) => {
        return allRows.push({
            userDetails:
                <Link onClick={() => {dispatch(handleSubNav(1)); localStorage.setItem("backBtn", "/minersReward");}} className="text-blue" to={`/user/${reward.ownerId}`}>
                    <p className="mb-0">{reward.ownerName} </p>
                    <p>{reward.ownerEmail} </p>
                </Link>,
            deviceInfo: <div>
                <p className="mb-0"> Device Name:  
                    <Link onClick={() => dispatch(handleSubNav(1))} className="text-blue" to={`/devices/${reward.deviceId}`}>
                         {" " +reward.deviceName}
                    </Link>
                </p>
                <p className="mb-0"> Tier {reward.deviceTier}</p>
            </div>,
            reward: reward.reward,
            dataUsage: <div>
                <div>
                    <p className="mb-0"> Explorer Details:</p>
                    <p className="mb-0">Data Usage: {numberWithCommaswithoutdecimals(reward.explorerDataUsageInGB) + " GB/" + numberWithCommaswithoutdecimals(reward.explorerDataUsageInMB) + " MB)" }</p>
                    <p className> Uptime: {reward.explorerUptimeInHrs + "Hrs"}</p>
                </div>
                <div>
                    <p className="mb-0"> Wicrypt Core Details:</p>
                    <p className="mb-0"> Data Usage: {numberWithCommaswithoutdecimals(reward.wicryptCoreDataUsageInGB) + " GB/" + numberWithCommaswithoutdecimals(reward.wicryptCoreDataUsageInMB) + " MB"}</p>
                    <p className="mb-0"> Uptime: {reward.wicryptCoreUptimeInHrs + "Hrs"} </p>
                </div>
                
            </div>,
            stakedValue: reward.wntStakedUSDValue,
            metRequirement: reward.meetsMinimumStakingRequirement ? "Yes" : "No"
        });
    });
    const data = {
        columns: [
            {
                label: "User",
                field: "userDetails",
                sort: "asc",
                width: 30,
            },
            {
                label: "Devie Info",
                field: "deviceInfo",
                sort: "asc",
                width: 170,
            },
            {
                label: "Reward",
                field: "reward",
                sort: "asc",
                width: 170,
            },
            {
                label: "Data Usage",
                field: "dataUsage",
                sort: "asc",
                width: 170,
            },
            {
                label: "Staked WNT (USD)",
                field: "stakedValue",
                sort: "asc",
                width: 170,
            },
            {
                label: "Met Staking Requirement?",
                field: "metRequirement",
                sort: "asc",
                width: 170,
            }

        ],
        rows: allRows,
    };

    useEffect(() => {
        dispatch(proposedRewards(Page, PageSize))
    }, [Page, PageSize]);


    return (
        <div className="row mt-5">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"currency-control"}
                            pageCount={proposedRewardTotalPages}
                            currentPage={proposedRewardCurrentPage}
                            header={
                                <TableHeader
                                    subHeader={minersOptions}
                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );

                                    }}
                                    dataLength={proposedReward.length}
                                    onClick={()=> dispatch(exportReport())}
                                    onChange={(e, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            }
                            loading={gettingProposedReward}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"Not found"}
                                    descr={"Reward already approved"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadedRewards;
