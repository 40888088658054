

import styled from "styled-components";
import { theme } from "../../theme";

export const Title = styled.p`
    font-size:  ${theme.textXs};
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: ${theme.grey};
    font-weight: bold;   
`
export const Instruction = styled.p`
    font-size:  ${theme.textXs};
    color: ${theme.grey};   

`
export const BigblueText = styled.p`
    font-size: ${theme.textMd};
    color: #344684;
    font-weight: bold;    
    margin-bottom: 0px;
`
export const DFlex = styled.div`
    display: flex;
    justify-content: space-between;
`
export const Claimed = styled.span`
    color: ${theme.green}
`
export const NotClaimed = styled.span`
    color: ${theme.yellow}
`