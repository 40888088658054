import styled from "styled-components";
import { theme, device } from "../../../theme";

export const ModalTitle = styled.p`
  color: ${theme.black};
  font-weight: ${theme.textBold};
`;

export const ModalSubTitle = styled.p`
  color: ${theme.grey};
  font-weight: ${theme.textBold};
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ModalClose = styled.i`
  color: ${theme.black};
  cursor: pointer;
`;
export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #0000009c;
  display: flex;
  z-index: 100000000;
  justify-content: center;
  align-items: center;
`;

export const ModalCotent = styled.div`
  background-color: ${theme.white};
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  background: #f4f4f4;
  box-shadow: 0px 4px 44px rgb(0 0 0 / 25%);
  border-radius: 20px;
  transition: all 500ms ease-in-out;
  max-height: 600px;
  overflow: auto;
  @media ${device.tablet} {
    width: auto;
    min-width: 50%;
  }
  @media ${device.laptopL} {
    min-width: 40%;
  }
`;
