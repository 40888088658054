import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpendControl, getUsersSpendControl, handleSubNav } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { Link } from "react-router-dom";


const SpendControlData = () => {

    const dispatch = useDispatch();
    const { usersSpendControl } = useSelector((state) => state.SpendControl);
    const { gettingUsersSpendControl } = useSelector((state) => state.Loading);


    let allRows = [];
    usersSpendControl.map((each) => {
        return allRows.push({
            userFullName: <Link onClick={()=> {dispatch(handleSubNav(7)); localStorage.setItem("backBtn", "/spendcontrol");}} className="text-blue" to={`/user/${each.userId}`}> <p className="mb-0"> {each.userFullName}</p><p> {each.userEmail}</p></Link>,
            noofsetSpendControl: each.spendControls.length,
            operations: each.spendControls.map(each => {return(<p className="mb-0">{each.operation} {each.currency}</p>)}),
            viewDetail: <Link className="t-center" onClick={()=>{ dispatch(handleSubNav(7)); localStorage.setItem("backBtn", "/spendcontrol");}} to={`/user/${each.userId}`}> View Detail <img src="/Assets/arrowRight.svg"
              className=" t-center"
              height="20px"
              alt="view more"

            />
          </Link>
        });
    });


    useEffect(() => {
        dispatch(getSpendControl());
        dispatch(getUsersSpendControl());
    }, []);

    const data = {
        columns: [
            {
                label: "User Full Name",
                field: "userFullName",
                sort: "asc",
                width: 170,
            },
            {
                label: "Spend Controls Set",
                field: "noofsetSpendControl",
                sort: "asc",
                width: 170,
            },
            {
                label: "Operations",
                field: "operations",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "viewDetail",
                sort: "asc",
                width: 170,
            },
            
        ],

        rows: allRows,
    };


    return (
        <div className="mb-4 mt-5">

            <div className="row">
                <div className="col-lg-12">
                    <CustomDataTable
                        data={data}
                        title="Custom Spend Control"
                        loading={gettingUsersSpendControl}
                        noData={
                            <EmptyCard
                                img="/Assets/emptytransactions.svg"
                                title={"No custom control has been set"}
                            // descr={"No Request available at the moment"}
                            />
                        }
                    />
                </div>

            </div>

        </div>
    );
};

export default SpendControlData;
