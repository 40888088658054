import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { handleModal, toggleModal } from "./GeneralAction";

export const updateBillingType = async (id, type) => {
  return async (dispatch) => {
    dispatch({
      type: _const.UPDATING_BILLING_TYPE,
      payload: true,
    });
    await axios
      .put(config.apiUrl + `/v2/businesses/${id}/billing-type?type=${type}`)
      .then((res) => {
        dispatch({
          type: _const.UPDATING_BILLING_TYPE,
          payload: false,
        });
        dispatch(toggleModal(false));
        // dispatch(allBusinesses(1, 30));
        window.location.reload();
        Swal.fire({
          title: "Updated!",
          type: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.UPDATING_BILLING_TYPE,
          payload: false,
        });
        dispatch({
          type: _const.ERROR_UPDATING_BILLING_TYPE,
          payload: err.response.data.message,
        });
      });
  };
};

//Get all wicrypt users
export const getUsers = async (dispatch, parameters, type) => {
  dispatch({
    type: _const.GETTING_ALL_USERS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/users/${!type ? "active" : type}`, {
      params,
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ALL_USERS,
        payload: false,
      });
      dispatch({
        type: _const.ALL_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ALL_USERS,
        payload: false,
      });
    });
};

export const getActiveAdmins = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_ACTIVE_ADMINS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/admin-users/active`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ACTIVE_ADMINS,
        payload: false,
      });
      dispatch({
        type: _const.ACTIVE_ADMINS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ACTIVE_ADMINS,
        payload: false,
      });
    });
};

export const getInActiveAdmins = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_INACTIVE_ADMINS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/admin-users/inactive`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_INACTIVE_ADMINS,
        payload: false,
      });
      dispatch({
        type: _const.INACTIVE_ADMINS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_INACTIVE_ADMINS,
        payload: false,
      });
    });
};

export const getAdminDevices = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_ADMIN_DEVICES,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/devices`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ADMIN_DEVICES,
        payload: false,
      });
      dispatch({
        type: _const.ADMIN_DEVICES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ADMIN_DEVICES,
        payload: false,
      });
    });
};

export const getLatestDevices = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_LATEST_DEVICES,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/devices/latest`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_LATEST_DEVICES,
        payload: false,
      });
      dispatch({
        type: _const.LATEST_DEVICES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_LATEST_DEVICES,
        payload: false,
      });
    });
};

export const getAdminLiveSessions = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_ADMIN_LIVE_SESSIONS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + "/v1/devices/live-sessions", {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ADMIN_LIVE_SESSIONS,
        payload: false,
      });
      dispatch({
        type: _const.ADMIN_LIVE_SESSIONS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ADMIN_LIVE_SESSIONS,
        payload: false,
      });
    });
};

//Get admin roles
export const getAdminRoles = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ADMIN_ROLES,
      payload: true,
    });
    axios
      .get(config.adminApiUrl  + `/v1/admin/roles`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_ADMIN_ROLES,
          payload: false,
        });
        dispatch({
          type: _const.ADMIN_ROLES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ADMIN_ROLES,
          payload: false,
        });
      });
  };
};

//invite admin
export const adminInvite = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.INVITING_ADMIN,
      payload: true,
    });
    axios
      .post(config.adminApiUrl  + `/v1/admin-user/invite`, data)
      .then((res) => {
        dispatch({
          type: _const.INVITING_ADMIN,
          payload: false,
        });
        if(!res.data.IsSuccessful){
          dispatch({
            type: _const.INVITE_ADMIN_ERROR,
            payload: res.data.message,
          });
        }else{
          dispatch({
            type: _const.ADMIN_ROLES,
            payload: res.data.data,
          });
          dispatch(handleModal(0))
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.INVITING_ADMIN,
          payload: false,
        });
      });
  };
};

//Change Role 
export const changeAdminRole = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.CHANGING_ADMIN_ROLE,
      payload: true,
    });
    axios
      .put(config.adminApiUrl  + `/v1/admin-users/role`, data)
      .then((res) => {
        dispatch({
          type: _const.CHANGING_ADMIN_ROLE,
          payload: false,
        });
        if(res.data.isSuccessful){
          dispatch({
            type: _const.CHANGING_ADMIN_ROLE_SUCCESSFUL,
            payload: res.data.data,
          });
          dispatch(handleModal(0));
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
        }else{
          dispatch({
            type: _const.INVITE_ADMIN_ERROR,
            payload: res.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.CHANGING_ADMIN_ROLE,
          payload: false,
        });
      });
  };
};

//Select Admin
export const selectAdmin = (payload) => {
  return dispatch => {
    dispatch({
      type: _const.SELECTED_ADMIN,
      payload
    })
  }
}