import React, {Component} from 'react'
import {connect }from 'react-redux'
import {getMarketers, referalupdate} from '../../Redux/actions'
import Loader from '../Reusables/loaders/MoonLoader'

class Marketer extends Component{

    render(){
        const {referalCode, referalLoading, refered,refMsg, failedMsg} = this.props
        const noError = {
            display: "block",
            color: "red",
            fontSize: "12px"
          };
        return(
            <div>
            <div className="col-lg-6 offset-lg-2">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0"> GET REFERRALS</h6>
                </div>
                
                <div className="container">
                  <div className="col-md-6 col-lg enterCode">
                  {refered.length === 0 & refMsg === true ?
                    <p style={ noError}>
                    {" "}
                    {referalCode} has not Refered anyone yet
                    </p>
                     : ''}
                      {failedMsg ?
                    <p style={ noError}>
                    {" "}
                     {failedMsg}
                    </p>
                     : ''}
                    <form>
                    <label> Enter Referral Code</label>
                    <div className="input-group mb-3">

                        <input 
                          type="text"
                          className="form-control loginCred"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={referalCode}
                          onChange = {(e)=> this.props.referalupdate(e.target.value)}
                        />
                        
                    </div>

                      <div className=" mb-4 mt-3 authbtns">
                        <button
                          type="button"
                          className="mb-4 btn wicryptColor customBtn float button"
                          onClick = {()=> referalCode !== '' ? this.props.getMarketers(referalCode): ''}
                        >                  
                          {referalLoading === true ? <Loader /> : "Enter"}         
                        </button>          
                      </div>
                      <div className="clearfix" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {refered.length > 0 ? 
            <div className="row ">
            <div className="col-lg">
              <div className="card card-small mb-4 responsiveTable">
                <div className="card-header border-bottom">
                  <h6 className="m-0"> {refered.length} Referals</h6>  
                </div>
                <div className="card-body p-0 pb-3 text-center ">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">#</th>
                        <th scope="col" className="border-0">Date</th>
                        <th scope="col" className="border-0">Referee Name</th>
                        <th scope="col" className="border-0">Referee Email</th>
                        <th scope="col" className="border-0">Claimed</th>
                      </tr>
                    </thead>
                    <tbody>
                        {refered.map((ref, index) =>{
                        return(
                        <tr key ={index}>
                            <td>{index}</td>
                            <td>{ref.createdAt.slice(0,10)}</td>
                            <td>{ref.name}</td>
                            <td>{ref.email}</td>
                            <td>{ref.isClaimed === true ? 'Yes' : 'No'}</td>
                        </tr>)
                        })}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          : ''}
          </div>
        )
    }
}

const MapStateToProps = (state)=>{
    const {referalCode, refered, refMsg, failedMsg} = state.Marketer
    const {referalLoading} = state.Loading
    return{
        referalCode,
        referalLoading,
        refered,
        refMsg,
        failedMsg
    }
}

export default connect(MapStateToProps, {getMarketers, referalupdate})(Marketer)