import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import Overview from "./Overview";
import AllDevices from "./AllDevices";
import DeviceLiveSession from "./DeviceLiveSession";
import DeviceSessionHistory from "./DeviceSessionHistory";
import { handleSubNav } from "../../Redux/actions";
import SelectDeviceType from "./modals/SelectDeviceType";
import AssignSubAdmin from "./modals/AssignSubAdmin";
import Requests from "./Requests";
import DeviceActivityLogs from "./DeviceActivityLogs";

class index extends Component {
  render() {
    const { toggleSubnav } = this.props;
    let deviceArray = [
      { id: 1, item: "Overview" },
      { id: 2, item: "Latest Devices" },
      { id: 3, item: "All Devices" },
      { id: 4, item: "Live Sessions" },
      { id: 6, item: "Device Activity Log" },
    ];
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white mb-5">
            {toggleSubnav !== 5 ? (
              <Mainnav pageTitle="Devices" listItems={deviceArray} />
            ) : (
              <Mainnav
                pageTitle={
                  <div
                    onClick={() => this.props.handleSubNav(4)}
                    className="pointer mb-0"
                  >
                    <i className="material-icons ">arrow_back</i>
                    Session History
                  </div>
                }
              />
            )}
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            <SelectDeviceType />
            <AssignSubAdmin />
            {toggleSubnav === 2 ? (
              <Requests />
            ) : toggleSubnav === 1 ? (
              <Overview />
            ) : toggleSubnav === 3 ? (
              <AllDevices />)
              : toggleSubnav === 4 ? (
                <DeviceLiveSession />
            ) : toggleSubnav === 5 ? (
              <DeviceSessionHistory />
            ) : (
              <DeviceActivityLogs deviceId= "" />
            )}
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const { toggleSubnav } = state.General;
  const { role, funct } = state.User;
  return {
    toggleSubnav,
    role,
    funct,
  };
};

export default connect(MapstateToProps, {
  handleSubNav,
})(index);
