import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { getFormDetails, allBusinesses } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { numberWithCommas } from "../../Helper";

const BusinessReport = () => {
  const { gettingBusinessReport } = useState((state) => state.Loading);
  const { bizsummaryReport } = useState((state) => state.Report);
  const { allBusiness } = useState((state) => state.Manager);
  const dispatch = useDispatch();

  useEffect(() => {
    if (allBusiness.length === 0) {
      allBusinesses(1, 1000, "");
    }
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card-header mb-1">
          <div className="mt-3 d-flex row">
            <div className="mt-3 d-flex row">
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  dispatch(
                    getFormDetails({
                      props: ["selectedAffiliatedBusiness"],
                      value: e.target.value,
                    })
                  )
                }
              >
                <option>Select Business</option>
                {allBusiness &&
                  allBusiness.map((business) => {
                    return <option value={business.id}>{business.name}</option>;
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          {gettingBusinessReport ? (
            <div className="volucherNote mt-5">
              <Loader size="10" />{" "}
            </div>
          ) : !gettingBusinessReport &
            (bizsummaryReport.businessDetails === undefined) ? (
            <div className="volucherNote">
              <p className="voucherText mt-5 t-center">
                {" "}
                Select a business to view report
              </p>
            </div>
          ) : (
            <div className="mt-5 offset-lg-3 col-lg-5 col-md-6 col-sm-12 mb-4">
              <div className="card card-small h-100">
                <div className="card-header border-bottom">
                  <h6 className="m-0 text-bold">
                    {bizsummaryReport.businessDetails !== undefined
                      ? bizsummaryReport.businessDetails.name
                      : ""}
                  </h6>
                </div>

                <div className="card-body d-flex py-0 jc border-bottom">
                  <div>
                    {" "}
                    <p className="overviewVal">
                      {bizsummaryReport.totalAgents}
                    </p>{" "}
                    <p>Agents</p>{" "}
                  </div>
                  <div>
                    {" "}
                    <p className="overviewVal t-right">
                      {bizsummaryReport.totalSessionsForBusiness}
                    </p>{" "}
                    <p>Sessions</p>{" "}
                  </div>
                </div>
                <div className="card-body d-flex py-0 jc border-bottom">
                  <div>
                    <p className="overviewVal">
                      {" "}
                      {bizsummaryReport.totalNumberOfDevices}
                    </p>{" "}
                    <p> Devices</p>{" "}
                  </div>
                  <div>
                    <p className="overviewVal t-right">
                      {" "}
                      {bizsummaryReport.subBusinesses !== undefined
                        ? bizsummaryReport.subBusinesses.length
                        : ""}
                    </p>{" "}
                    <p> Sub business</p>{" "}
                  </div>
                </div>

                <div className="card-body d-flex py-0 jc border-bottom">
                  <div>
                    <p className="overviewVal">
                      {" "}
                      {bizsummaryReport.currency + " " + +
                        numberWithCommas(bizsummaryReport.businessBalance)}
                    </p>{" "}
                    <p> Business Balance</p>{" "}
                  </div>
                  <div>
                    <p className="overviewVal t-right">
                      {" "}
                      {bizsummaryReport.currency + " " + +
                        numberWithCommas(bizsummaryReport.totalSalesAmount)}
                    </p>{" "}
                    <p> Total Sales Amount</p>{" "}
                  </div>
                </div>

                <div className="card-body d-flex py-0 jc border-bottom">
                  <div>
                    <p className="overviewVal">
                      {" "}
                      {bizsummaryReport.currency + " " + +
                        numberWithCommas(bizsummaryReport.totalServiceCharges)}
                    </p>{" "}
                    <p> Total Service Charge</p>{" "}
                  </div>
                  <div>
                    <p className="overviewVal t-right">
                      {" "}
                      {bizsummaryReport.currency + " " + +
                        numberWithCommas(
                          bizsummaryReport.totalWicryptCommisson
                        )}
                    </p>{" "}
                    <p> Total Wicrypt Commission</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessReport;
