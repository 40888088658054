import React, { Component } from "react";
import { connect } from "react-redux";
import {
  editUserInfo,
  getAccount,
  getWithdrawalToken,
  getBanks,
  copyToClipboard,
  getRates,
  makeWithdrawal,
  getFormDetails,
  getAcctName,
  getBusinessBalance,
} from "../../Redux/actions";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";

class Withdraw extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
    };
  }

  componentWillMount() {
    this.props.getAccount();
    this.props.getBanks();
    this.props.getRates();
    this.props.getBusinessBalance();
  }
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.accountNumber.length === 10) &
      (this.state.count === 0) &
      (nextProps.bankId !== 0)
    ) {
      this.setState({ count: 1 });
      this.props.getAcctName(nextProps.accountNumber, nextProps.bankId);
    }
    if ((nextProps.accountNameError !== "") & (this.state.count === 1)) {
      this.setState({ count: 0 });
    }
  }

  render() {
    const {
      withdrawalError,
      otpError,
      sendingOtp,
      currencyCode,
      makingWithdrawal,
      allBanks,
      currencyRates,
      accountNumber,
      amtToWithdraw,
      bankId,
      gettingAcctName,
      accountNameError,
      otpSent,
      withdrawalOtp,
      withdrawalPin,
      narration,
      accountName,
    } = this.props;

    return (
      <div className="row">
        <div className="col-lg-10 offset-lg-1 fade-in">
          {otpSent ? (
            <span>
              <p className="otpMsg">
                {" "}
                Please enter the OTP that was sent to your email address
              </p>
              <label> Enter OTP </label>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={withdrawalOtp}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["withdrawalOtp"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Enter PIN </label>
              <div className="input-group mb-2">
                <input
                  type="password"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={withdrawalPin}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["withdrawalPin"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label>Narration </label>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={narration}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["narration"],
                      value: e.target.value,
                    })
                  }
                />
              </div>

              <Button
                theme=" ml-3 mt-2 mb-3 float wicryptColor"
                buttonAction={() =>
                  this.props.makeWithdrawal(
                    bankId,
                    accountNumber,
                    amtToWithdraw,
                    narration,
                    currencyCode,
                    withdrawalPin,
                    withdrawalOtp
                  )
                }
              >
                {makingWithdrawal ? <Loader /> : "Submit"}
              </Button>
            </span>
          ) : (
              <span>
                <select
                  className="form-control mr-2 mt-2 mb-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["bankId"],
                      value: e.target.value,
                    })
                  }
                >
                  <option> Select Bank</option>
                  {allBanks.map((bank) => {
                    return (
                      <option key={bank.id} value={bank.id}>
                        {bank.name}
                      </option>
                    );
                  })}
                </select>
                <label>Account Number</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control loginCred mb3"
                    aria-describedby="basic-addon1"
                    value={accountNumber}
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["accountNumber"],
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <label>Account Name</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    disabled
                    className="form-control loginCred mb3"
                    aria-describedby="basic-addon1"
                    value={accountName}
                  />
                  {gettingAcctName ? (
                    <i
                      className="fas fa-spinner fa-spin  mr-2 ml-2 acctNameLoader"
                      aria-hidden="true"
                    />
                  ) : (
                      ""
                    )}
                </div>
                {accountNameError !== "" ? (
                  <p className="errorMsgs">
                    <i className="material-icons">info</i>
                    {accountNameError}
                  </p>
                ) : (
                    ""
                  )}
                <label> Amount</label>
                <div style={{ display: "flex" }}>
                  <input
                    type="number"
                    value={amtToWithdraw}
                    className="form-control mr-2 loginCred"
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["amtToWithdraw"],
                        value: e.target.value,
                      })
                    }
                  />
                  <select
                    className="form-control mr-2 mt-2 loginCred"
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["currencyCode"],
                        value: e.target.value,
                      })
                    }
                  >
                    {currencyRates.map((rates) => {
                      return (
                        <option key={rates.rate} value={rates.code}>
                          {rates.code}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {otpError !== "" ? (
                  <p className="errorMsgs">
                    <i className="material-icons">info</i>
                    {otpError}
                  </p>
                ) : (
                    ""
                  )}
                {withdrawalError !== "" ? (
                  <p className="errorMsgs">
                    <i className="material-icons">info</i>
                    {withdrawalError}
                  </p>
                ) : (
                    ""
                  )}
                <Button
                  theme=" ml-3 mt-2 mb-3 float wicryptColor"
                  buttonAction={() =>
                    this.props.getWithdrawalToken(
                      bankId,
                      accountNumber,
                      amtToWithdraw,
                      narration,
                      currencyCode,
                      accountName
                    )
                  }
                >
                  {sendingOtp ? <Loader /> : "Next"}
                </Button>

              </span>
            )}
        </div>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const {
    editInfo,
    action,
    allBanks,
    currencyRates,
    withdrawalError,
    accountName,
    accountNameError,
    otpSent,
  } = state.Manager;
  const {
    name,
    email,
    refCode,
    role,
    businessBalance,
    accountNumber,
    amtToWithdraw,
    verificationStatus,
    bankId,
    withdrawalOtp,
    withdrawalPin,
    copied,
    narration,
    currencyValue,
    otpError,
    currencyCode,
    businessProfile,
  } = state.User;
  const { merchantAccount } = state.Agent;
  const { makingWithdrawal, sendingOtp, gettingAcctName } = state.Loading;
  return {
    editInfo,
    accountName,
    verificationStatus,
    name,
    email,
    refCode,
    role,
    merchantAccount,
    businessBalance,
    action,
    makingWithdrawal,
    allBanks,
    currencyRates,
    accountNumber,
    amtToWithdraw,
    bankId,
    withdrawalOtp,
    withdrawalPin,
    narration,
    currencyValue,
    otpError,
    currencyCode,
    withdrawalError,
    accountNameError,
    sendingOtp,
    otpSent,
    gettingAcctName,
    businessProfile,
    copied,
  };
};
export default connect(
  MapstateToProps,
  {
    editUserInfo,
    getAccount,
    copyToClipboard,
    getWithdrawalToken,
    getBusinessBalance,
    getAcctName,
    getFormDetails,
    getBanks,
    getRates,
    makeWithdrawal,
  }
)(Withdraw);
