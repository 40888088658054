import _const from '../actions/Types';

const initialState = {
    remoteCommands: [],
    devicedByCommand: [],
    deviceByCommandPage: 1,
    totalDevicePages: 1,
    currentDevicePage: 1,
    createCommandError: ""
}

const CommandReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.ALL_COMMANDS:
            return { ...state, remoteCommands: action.payload }

        case _const.CREATING_COMMANDS_ERROR:
            return{...state, createCommandError: action.payload}

        case _const.ALL_DEVICES_BY_COMMANDS:
            return{...state, devicedByCommand: action.payload.records, totalDevicePages: action.payload.totalPages, currentDevicePage: action.payload.currentPage}

        default:
            return state
    }
}

export default CommandReducer; 