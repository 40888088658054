import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas, shortenAddress } from "../../../Helper";
import { getP2PDetails } from "../../../Redux/actions";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { StakeStatValuePre } from "../style";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import { MdFileCopy } from "react-icons/md";
import EmptyCard from "../../Reusables/cards/EmptyCard";

const NewSingleTransactionReportP2P = ({history}) => {

  const { newP2pDetails } = useSelector((state) => state.Transactions);
  const { gettingP2pDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [transcType, setTranscType] = useState(null);
  const [transcId, setTranscId] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  useEffect(()=>{
    const [newtranscType, newtranscId] = window.location.pathname.split("/").slice(-2);
    setTranscType(newtranscType);
    setTranscId(newtranscId);
  }, [])
  
  useEffect(()=>{
    if(transcType && transcId) {
      dispatch(getP2PDetails(transcType, transcId))
    }
  }, [dispatch, transcType, transcId])

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          <div className="col-lg-12 ">
            { gettingP2pDetails ?
            <CustomLoader size="10" /> :
              <div className="card w3-animate-bottom">
              {Object.keys(newP2pDetails).length !== 0 ? <div className="card-body p-6 ">
                  <div className="row mb-2">
                    
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Reference</StakeStatData>
                        {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].reference !== undefined  ? (
                            <StakeStatValue>{newP2pDetails.walletTransactions[0].reference}
                            </StakeStatValue>
                          ) : "N/A"}
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Transaction Type</StakeStatData>
                        {newP2pDetails.orderDetail && newP2pDetails.orderDetail.orderType ? (
                          <StakeStatValue className={newP2pDetails.orderDetail.orderType === "Buy" ? "text-green" : newP2pDetails.orderDetail.orderType === "Sell" ? "text-red" : "text-blue"}>{newP2pDetails.orderDetail.orderType}</StakeStatValue>
                          ) : "N/A"}
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy Amount</StakeStatData>
                        {newP2pDetails.orderDetail && newP2pDetails.orderDetail.orderType && newP2pDetails.orderDetail.buyAmount ? (
                          <StakeStatValue className={newP2pDetails.orderDetail.orderType === "Buy" ? "text-green" : "text-red"}>
                            {newP2pDetails.orderDetail.orderType === 'Buy' ? "+" + numberWithCommas(newP2pDetails.orderDetail.buyAmount) + " " + newP2pDetails.orderDetail.buyCurrency : "-" + numberWithCommas(newP2pDetails.orderDetail.buyAmount) + " " + newP2pDetails.orderDetail.buyCurrency}
                          </StakeStatValue>
                          ) : "N/A"}
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Amount</StakeStatData>
                        {newP2pDetails.orderDetail && newP2pDetails.orderDetail.orderType && newP2pDetails.orderDetail.sellAmount ? (
                          <StakeStatValue className={newP2pDetails.orderDetail.orderType === "Buy" ? "text-red" : "text-green"}>
                            {newP2pDetails.orderDetail.orderType === 'Buy' ? "-" + numberWithCommas(newP2pDetails.orderDetail.sellAmount) + " " + newP2pDetails.orderDetail.sellCurrency : "+" + numberWithCommas(newP2pDetails.orderDetail.sellAmount) + " " + newP2pDetails.orderDetail.sellCurrency}
                          </StakeStatValue>
                        ) : "N/A"}
                      </div>
                    </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                        <div className="col-md-12">
                          <StakeStatData>Description</StakeStatData>
                          {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].description !== undefined ? (
                              <StakeStatValue>{newP2pDetails.walletTransactions[0].description }</StakeStatValue>
                            ) : "N/A"}
                        </div>
                      
                    </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                      {newP2pDetails.liquidityProvider && (
                      <div className="col-lg-4 col-md-6">
                        <StakeStatData>Liquidity Provider</StakeStatData>
                        <StakeStatValue>Name: {newP2pDetails.liquidityProvider && newP2pDetails.liquidityProvider.name ? newP2pDetails.liquidityProvider.name : "N/A"}</StakeStatValue>
                        <StakeStatValue>Email: {newP2pDetails.liquidityProvider && newP2pDetails.liquidityProvider.email ? newP2pDetails.liquidityProvider.email : "N/A"}</StakeStatValue>
                        <StakeStatValue>Phone Number: {newP2pDetails.liquidityProvider && newP2pDetails.liquidityProvider.phoneNumber ? newP2pDetails.liquidityProvider.phoneNumber : "N/A"}</StakeStatValue>
                      </div>
                      )}
                      {newP2pDetails.customer && (
                        <div className="col-lg-4 col-md-6">
                          <StakeStatData>Customer</StakeStatData>
                          <StakeStatValue>Name: {newP2pDetails.customer && newP2pDetails.customer.name ? newP2pDetails.customer.name : "N/A"}</StakeStatValue>
                          <StakeStatValue>Email: {newP2pDetails.customer && newP2pDetails.customer.email ? newP2pDetails.customer.email : "N/A"}</StakeStatValue>
                          <StakeStatValue>Phone Number: {newP2pDetails.customer && newP2pDetails.customer.phoneNumber ? newP2pDetails.customer.phoneNumber : "N/A"}</StakeStatValue>
                        </div>
                      )}
                        <div className="col-lg-4 col-md-6">
                          <StakeStatData>Customer Bank Details</StakeStatData>
                          <StakeStatValue>Account Name: {newP2pDetails.customerBankDetails && newP2pDetails.customerBankDetails.accountName ? newP2pDetails.customerBankDetails.accountName : "N/A"}</StakeStatValue>
                          <StakeStatValue>Bank: {newP2pDetails.customerBankDetails && newP2pDetails.customerBankDetails.bank ? newP2pDetails.customerBankDetails.bank : "N/A"}</StakeStatValue>
                          <StakeStatValue>Account Number: {newP2pDetails.customerBankDetails && newP2pDetails.customerBankDetails.accountNumber ? newP2pDetails.customerBankDetails.accountNumber : "N/A"}</StakeStatValue>
                        </div>
                      
                    </div>
                  
                    <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8", marginBottom: "1rem"}}
                    >
                          <div className="col-md-12">
                            <StakeStatData>Wallet Transaction</StakeStatData>
                            <div style={{ marginTop: "5px" }} className="table-on-mobile">
                              <Table>
                                <TableHead>
                                  <tr>
                                    <th>Reference</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Fee</th>
                                    <th>Total Amount</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Transaction Type</th>
                                    <th>Transaction Channel</th>
                                  </tr>
                                </TableHead>
                              {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && (
                                <tbody style={{marginTop: "4px"}}>
                                  <tr>
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].reference !== undefined  ? <TableValues data-label="Reference">
                                      <div 
                                        onClick={() => {
                                          navigator.clipboard.writeText(newP2pDetails.walletTransactions[0].reference);
                                          setIsCopied(true);
                                          // Reset the "Copied" state after a short delay
                                          setTimeout(() => {
                                            setIsCopied(false);
                                          }, 1500);
                                        }}
                                        >
                                        <span className="">{shortenAddress(newP2pDetails.walletTransactions[0].reference)}</span>
                                        <span className="pl-2"><MdFileCopy /></span>
                                        <span className="pl-2">{isCopied ? "Copied" : ""}</span>
                                      </div>
                                    </TableValues> : <TableValues>N/A</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].transactionType !== undefined ? 
                                      <TableValues data-label="Type">{newP2pDetails.walletTransactions[0].transactionType}</TableValues> : <TableValues>N/A</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].category !== undefined ? <TableValues data-label="Category" 
                                      className={newP2pDetails.walletTransactions[0].category === "Buy" ? "text-green" : newP2pDetails.walletTransactions[0].category === "Sell" ? "text-red" : "text-blue"}>
                                      {newP2pDetails.walletTransactions[0].category}</TableValues> : <TableValues>N/A</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].fee !== undefined ?
                                      <TableValues data-label="Fee">{newP2pDetails.walletTransactions[0].fee}</TableValues> : <TableValues>0</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].totalAmount !== undefined && newP2pDetails.walletTransactions[0].currency !== undefined ?
                                      <TableValues data-label="Total Amount">{numberWithCommas (newP2pDetails.walletTransactions[0].totalAmount, 2)} {newP2pDetails.walletTransactions[0].currency}</TableValues> : <TableValues>0</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].amount && newP2pDetails.walletTransactions[0].currency !== undefined ? 
                                      <TableValues data-label="Amount">{numberWithCommas(newP2pDetails.walletTransactions[0].amount, 4)} {newP2pDetails.walletTransactions[0].currency}</TableValues> : <TableValues>0</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].status !== undefined ? 
                                      <TableValues data-label="Status" className={newP2pDetails.walletTransactions[0].status.toUpperCase()}>{newP2pDetails.walletTransactions[0].status}</TableValues> : <TableValues>N/A</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].transactionType !== undefined ?
                                      <TableValues data-label="Transaction Type" className={newP2pDetails.walletTransactions[0].transactionType.toLowerCase()}>{newP2pDetails.walletTransactions[0].transactionType}</TableValues> : <TableValues>N/A</TableValues>}
                                    {newP2pDetails.walletTransactions && newP2pDetails.walletTransactions.length > 0 && newP2pDetails.walletTransactions[0].transactionChannel !== undefined ? 
                                    <TableValues data-label="Transaction Channel">{newP2pDetails.walletTransactions[0].transactionChannel}</TableValues> : <TableValues>N/A</TableValues>}
                                  </tr>
                                </tbody>
                              )}
                              </Table>
                            </div>
                          </div>
                    </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                      {newP2pDetails && (
                          <div className="col-lg-4 col-md-6">
                            <StakeStatData>How long to complete order</StakeStatData>
                            <StakeStatValue> {newP2pDetails.orderCompletedTimeStamp
                              ? moment.duration(newP2pDetails.orderCompletedTimeStamp - newP2pDetails.orderCreationTimeStamp).humanize()
                              : "Order not completed yet"}
                              </StakeStatValue>
                          </div>
                        )}
                        {newP2pDetails && (
                          <div className="col-lg-4 col-md-6">
                            <StakeStatData>How long before the order was cancelled</StakeStatData>
                            <StakeStatValue>
                            {newP2pDetails.orderCancelledAtTimeStamp
                              ? moment.duration(newP2pDetails.orderCancelledAtTimeStamp - newP2pDetails.orderCreationTimeStamp).humanize()
                              : "Order was not cancelled"}
                            </StakeStatValue>
                          </div>
                        )}
                        {newP2pDetails && (
                          <div className="col-lg-4 col-md-6">
                            <StakeStatData>Order TimeStamp</StakeStatData>
                            <StakeStatValue>
                              Order Creation TimeStamp: {newP2pDetails.orderCreationTimeStamp
                                ? `${moment(newP2pDetails.orderCreationTimeStamp).format("DD-MM-YYYY")} | ${moment(newP2pDetails.orderCreationTimeStamp).format("LT")}`
                                : "N/A"}
                            </StakeStatValue>
                            <StakeStatValue>
                              Order Completed TimeStamp: {newP2pDetails.orderCompletedTimeStamp
                                ? `${moment(newP2pDetails.orderCompletedTimeStamp).format("DD-MM-YYYY")} | ${moment(newP2pDetails.orderCompletedTimeStamp).format("LT")}`
                                : "N/A"}
                            </StakeStatValue>
                            <StakeStatValue>
                              Order Cancellation TimeStamp: {newP2pDetails.orderCancelledAtTimeStamp
                                ? `${moment(newP2pDetails.orderCancelledAtTimeStamp).format("DD-MM-YYYY")} | ${moment(newP2pDetails.orderCancelledAtTimeStamp).format("LT")}`
                                : "N/A"}
                            </StakeStatValue>
                          </div>
                        )}
                        
                    </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                        <div className="col-md-12">
                          <StakeStatData>Crypto Webhooks</StakeStatData>
                        </div>
                      </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                        {newP2pDetails.cryptoWebhooks && newP2pDetails.cryptoWebhooks[0] && newP2pDetails.cryptoWebhooks[0].webhookType ? (
                          <div className="col-lg-6 col-md-12">
                            <StakeStatData>
                              {newP2pDetails.cryptoWebhooks[0].webhookType === "Deposit" ? "Crypto Deposit Webhook" : "Crypto Withdrawal Webhook"}
                            </StakeStatData>
                            <StakeStatValuePre>{JSON.stringify(newP2pDetails.cryptoWebhooks[0], null, 2)}</StakeStatValuePre>
                          </div>
                        ) : <div className="col-md-6">
                          <StakeStatValue>N/A</StakeStatValue>
                        </div>}
                        {newP2pDetails.cryptoWebhooks && newP2pDetails.cryptoWebhooks[1] && newP2pDetails.cryptoWebhooks[1].webhookType ? (
                          <div className="col-lg-6 col-md-12">
                             <StakeStatData>
                                {newP2pDetails.cryptoWebhooks[1].webhookType === "Deposit" ? "Crypto Deposit Webhook" : "Crypto Withdrawal Webhook"}
                             </StakeStatData>
                            <StakeStatValuePre>{JSON.stringify(newP2pDetails.cryptoWebhooks[1], null, 2)}</StakeStatValuePre>
                          </div>
                        ) : ""}
                        
                    </div>

                    <div
                      className="row mt-2 pt-2"
                      style={{ borderTop: "1px solid #E8E8E8" }}
                      >
                          <div className="col-lg-6 col-md-12">
                            <StakeStatData>Order Cancellation Webhook</StakeStatData>
                            {newP2pDetails.orderCancellationWebhook ? (
                              <StakeStatValuePre>{JSON.stringify(newP2pDetails.orderCancellationWebhook, null, 2)}</StakeStatValuePre>) : "N/A"}
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <StakeStatData>Order Completion Webhook</StakeStatData>
                            {newP2pDetails.orderCompletionWebhook ? (
                              <StakeStatValuePre>{JSON.stringify(newP2pDetails.orderCompletionWebhook, null, 2)}</StakeStatValuePre>) : "N/A"}
                          </div>
                        
                    </div>

                </div> : 
                 <EmptyCard
                 img="/Assets/emptytransactions.svg"
                 title={"Sorry,  you have no transaction history"}
               />}
              </div>
            }
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default NewSingleTransactionReportP2P;
