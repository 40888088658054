import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { getUploads, getSearchDetails, acceptUploads, rejectUploads, uploadReward, approveUpload} from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import TableHeader from "../Reusables/tables/TableHeader";
import Loader from "../Reusables/loaders/MoonLoader";


const UploadedRewards = () => {

    const dispatch = useDispatch();
    const { allUploads, acceptedUpload, allMiningWeeks } = useSelector((state) => state.Miners);
    const [uploadId, setId] = useState('');
    const { gettingUploads, acceptingUploads, rejectingUploads, approvingUploads, uploadingRewards } = useSelector((state) => state.Loading)
    const { selectedMiningWeek } = useSelector((state) => state.Search);

    const minersOptions = [
        {
            id: 1,
            title: "Week",
            type: "select",
            onSelectProp: "selectedMiningWeek",
            options: allMiningWeeks,
        },
    ]

    let responseStatus = ["ABOUT_TO_INITIATE", "PENDING", "CONFIRMED", "FAILED"]

    let allRows = [];
    allUploads.map((reward) => {
        return allRows.push({
            uploadedBy: reward.uploadedBy,
            rewardDetail: <a className="text-blue" href={reward.filePathUrl} target="_blank"> Download Report</a>,
            status: <p className={responseStatus[reward.status - 1]}> {responseStatus[reward.status - 1]}</p>,
            accept:
                acceptedUpload.length > 0 ? null
                : 
                reward.isAccepted ? 
                <p className="SUCCESS"> ACCEPTED </p> 
                :
                reward.isAccepted === null ? 
                <button
                    className="verify btn"
                    onClick={() => { dispatch(acceptUploads(selectedMiningWeek)); setId(reward.id) }}
                >
                    {acceptingUploads && reward.id === uploadId ? <Loader /> : "Accept"}
                </button> : null,
            reject:
                acceptedUpload.length > 0 ? null 
                :
                reward.isAccepted === false? 
                <p className="CANCELLED"> REJECTED </p> 
                :
                reward.isAccepted === null ? 
                <button
                    className="btn reject"
                    onClick={() => { dispatch(rejectUploads(selectedMiningWeek)); setId(reward.id) }}
                >
                    {rejectingUploads && reward.id === uploadId ? <Loader /> : "Reject"}
                </button> : null,
            approve: 
                acceptedUpload.length > 0 ?
                reward.isAccepted === true && !reward.isApproved ? 
                <button
                    className="verify btn"
                    onClick={() => { dispatch(approveUpload(selectedMiningWeek)); setId(reward.id) }}
                >
                    {approvingUploads && reward.id === uploadId ? <Loader /> : "Approve"}
                </button> : reward.isApproved === true ? <p className="CONFIRMED"> APPROVED</p>: null: null,

        });
    });
    const data = {
        columns: [
            {
                label: "Uploaded by",
                field: "uploadedBy",
                sort: "asc",
                width: 30,
            },
            {
                label: "Reward Detail",
                field: "rewardDetail",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
            {
                label: "Accept",
                field: "accept",
                sort: "asc",
                width: 170,
            },
            {
                label: "Reject",
                field: "reject",
                sort: "asc",
                width: 170,
            },
            {
                label: "Approve",
                field: "approve",
                sort: "asc",
                width: 170,
            },

        ],
        rows: allRows,
    };

    useEffect(() => {
        if (selectedMiningWeek) {
            dispatch(getUploads(selectedMiningWeek))
        }
    }, [selectedMiningWeek]);


    return (
        <div className="row mt-5">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"uploaded-reward"}
                            header={
                                <TableHeader
                                    subHeader={minersOptions}
                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );
                                    }}
                                    onChange={(e, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            }
                            loading={gettingUploads}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"Not found"}
                                    descr={`No rewards have been uploaded for week ${selectedMiningWeek} yet`}
                                />
                            }
                            customright={
                                acceptedUpload.length > 0 ? null :
                                <div className="col-lg-12 uploads">
                                <div className="col-lg-9"> </div>
                            <div className="col-lg-3">
                                <input
                                    type="file"
                                    name="file"
                                    id="reward"
                                    accept=".xlsx"
                                    onChange={(e) =>
                                        dispatch(uploadReward(selectedMiningWeek, e.target.files[0]))
                                    }
                                    className="inputfile"
                                />
                                <label for="reward">
                                    <div>
                                        <p className="mb-0"> {uploadingRewards ? <Loader /> : "Upload an updated reward" }</p>
                                    </div>
                                </label>
                            </div>
                        </div>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadedRewards;
