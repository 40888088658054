import React, { Component } from "react";
import {
  getFormDetails,
  allDynamicForm,
  activateDynamicForm,
  deactivateDynamicForm,
  deleteForm
} from "../../Redux/actions";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import CustomCardLoader from "../Reusables/loaders/CustomCardLoader";
import Loader from "../Reusables/loaders/MoonLoader";
import { pagination } from "../../Helper";
import EmptyCard from "../Reusables/cards/EmptyCard";

class AllForms extends Component {
  componentDidMount() {
    this.props.allDynamicForm("", 1, 50);
  }

  customPagination() {
    const { allFormsTotalPages, allFormsCurrentPage } = this.props;
    var pageButtons = pagination(allFormsCurrentPage, allFormsTotalPages, 5);
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.allDynamicForm("", allFormsCurrentPage - 1, 50)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === allFormsCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.allDynamicForm("", pageButtons[i], 50)}
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.allDynamicForm("", allFormsCurrentPage + 1, 50)
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      allForms,
      deactivatingForm,
      gettingDynamicForms,
      oneFormCode,
      activatingForm,
      deletingForm
    } = this.props;
    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3" style={{ width: "100%" }}>
          <div className="d-flex">
            <div
              className="details"
              onClick={e =>
                this.props.getFormDetails({
                  props: ["createnewform"],
                  value: true
                })
              }
            >
              <i
                className="fa fa-plus mr-2"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                {" "}
              </i>
              Create Router Form
            </div>
          </div>
        </div>
        <div className="row">
          {gettingDynamicForms ? (
            <div className="col-lg-12">
              <CustomCardLoader />{" "}
            </div>
          ) : !gettingDynamicForms & (allForms.length === 0) ? (
            <EmptyCard
              img="/Assets/emptytransactions.svg"
              title={"Sorry,  you have no forms"}
              descr={"You can view forms here when you create them"}
            />
          ) : (
            ""
          )}

          {/* <div className="col-lg-12"> */}
          {allForms.map(eachForm => {
            return (
              <div className="col-lg-4">
                <div className="card card-small mb-4">
                  <div>
                    <div className="mt-5">
                      <div className="t-center">
                        <h5 className="bold t-14 mb-0">Form Name: Form Code</h5>
                        <p className="t-12">
                          {" "}
                          {eachForm.formName
                            ? eachForm.formName
                            : "- : " + eachForm.formCode}
                        </p>
                      </div>
                      <div className="t-center">
                        <h5 className="bold t-14 mb-0">Bonus Data Amount</h5>
                        <p className="t-12">
                          {" "}
                          {eachForm.userDataAllocationForLogin}
                        </p>
                      </div>
                      <div className="t-center">
                        <h5 className="bold t-14 mb-0">Bonus Data Frequency</h5>
                        <p className="t-12"> {eachForm.allocationType}</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mb-5 p-3">
                    <div className="mb-2 mt-2">
                      <Button
                        theme="btn-primary deactivate"
                        // disabled = "true"
                        buttonAction={() =>
                          this.props.deleteForm(eachForm.id, eachForm.formCode)
                        }
                      >
                        {deletingForm && eachForm.formCode === oneFormCode ? (
                          <Loader />
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    </div>
                    <div className="mb-2 mt-2">
                      {eachForm.entityStatus === "INACTIVE" ? (
                        <Button
                          theme="btn-primary activate"
                          // disabled = "true"
                          buttonAction={() =>
                            this.props.activateDynamicForm(eachForm.formCode)
                          }
                        >
                          {activatingForm &&
                          eachForm.formCode === oneFormCode ? (
                            <Loader />
                          ) : (
                            "Activate"
                          )}
                        </Button>
                      ) : (
                        <Button
                          theme="btn-primary archive"
                          // disabled = "true"
                          buttonAction={() =>
                            this.props.deactivateDynamicForm(eachForm.formCode)
                          }
                        >
                          {deactivatingForm &&
                          eachForm.formCode === oneFormCode ? (
                            <Loader color={"#fefefe"} />
                          ) : (
                            "Archive"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* </div> */}
          <div className="col-lg-12 mt-5 mb-5">
            <div className="pagination">{this.customPagination()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    requireRouterCodeLogin,
    enableVoucherLogin,
    allformControl
  } = state.User;
  const {
    allForms,
    oneFormCode,
    allFormsCurrentPage,
    allFormsTotalRecords,
    allFormsTotalPages
  } = state.DynamicForm;
  const {
    deactivatingForm,
    gettingDynamicForms,
    activatingForm,
    deletingForm
  } = state.Loading;

  return {
    requireRouterCodeLogin,
    enableVoucherLogin,
    allformControl,
    allForms,
    deactivatingForm,
    gettingDynamicForms,
    oneFormCode,
    activatingForm,
    allFormsCurrentPage,
    allFormsTotalRecords,
    allFormsTotalPages,
    deletingForm
  };
};

export default connect(
  MapStateToProps,
  {
    getFormDetails,
    allDynamicForm,
    activateDynamicForm,
    deactivateDynamicForm,
    deleteForm
  }
)(AllForms);
