import React, { useEffect, useState } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { useSelector, useDispatch } from "react-redux";
import { getAdminTransactions } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { clearSearch } from "../../Redux/actions/GeneralAction";

import EmptyCard from "../Reusables/cards/EmptyCard";
import { newStakingColumns } from "../../utils/TableCols/NewTransactionsCols";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import { newStakingUserOptions } from "./options";

const Stake = ({ userId }) => {
  // const [ stakeDetail, setStakeDetail ] = useState({});
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { stakeHistory } = useSelector((state) => state.Admin);
  const {adminTransactions,gettingAdminTransactions} = useSelector((state) => state.Transactions);
  const { gettingUsers} = useSelector((state) => state.Loading);
  const { activeTab } = useSelector((state) => state.User);

  const { startTime, endTime, Search, Page, PageSize } = useSelector(
    (state) => state.Search
  );
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
  );

  const data = {
    columns: newStakingColumns,
    rows: allRows,
  };

const transactionType = "staking";

const fetchAdminTransactions = () => {
  const queries = {
    StartDate: startTime,
    EndDate: endTime,
    SearchParam: Search,
    userId,
    Page,
    PageSize
  };
  getAdminTransactions(dispatch, transactionType, queries);
}

useEffect(() => {
  fetchAdminTransactions();
}, [ startTime, endTime, userId,  Page, PageSize ]);

// This is to refresh the page when the search input is empty
useEffect(()=>{
  if(Search === ""){
    fetchAdminTransactions();
  }
},[Search])

useEffect(() => {
  dispatch(clearSearch());
}, []);

  return (
    <div className="row">
      {/* <StakeStats /> */}
      <div className="col-lg-12 mt-1">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              title={`Stake History`}
              pageCount={stakeHistory.totalPages}
              currentPage={stakeHistory.currentPage}
              header={
                <TableHeader
                  // subHeaderTypes={StakeHeaderOptionTypes}
                  dataLength={adminTransactions.records ? adminTransactions.records.length : 0}
                  subHeader={newStakingUserOptions}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  searchvalueText={Search}
                  gettingDataListOptions={gettingUsers}
                  onSearchTableClick={fetchAdminTransactions}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={`Sorry,  you have no stake history`}
                  // descr={"No stake history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
      {/* {stakeDetail && stakeDetail.id && <NewStakeLog id={50} stakeLog={stakeDetail} />} */}
    </div>
  );
};

export default Stake;
