import _const from '../actions/Types';

const initialState = {
    allTopics: [],
    dlqTopics: [],
    subscribersPerTopic: [],
    noOfMessagesPerSubscriber: [],
    deadLetterTopic: [],
    replayError: "",
}

const TopicsReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case _const.ALL_TOPICS:
          return {
            ...state,
            allTopics: action.payload.names,
          };

        case _const.DLQ_TOPICS:
          return {
            ...state,
            dlqTopics: action.payload.names,
          };

        case _const.SUBSCRIBERS_PER_TOPIC:
            return {
            ...state,
            subscribersPerTopic: action.payload.subscriptions,
            };

        case _const.NO_OF_MESSAGES_PER_SUBSCRIBER:
            return {
            ...state,
            noOfMessagesPerSubscriber: action.payload,
            };

        case _const.DEAD_LETTER_TOPIC:
            return {
            ...state,
            deadLetterTopic: action.payload,
            };

        case _const.ERROR_UPDATING_DEAD_LETTER_TOPIC:
          return {
          ...state,
          replayError: action.payload,
          };
          
        case _const.CLEAR_REPLAY_ERROR:
          return {
            ...state,
            replayError: "",
          };
    
        default:
          return state;
      }
}

export default TopicsReducer