import React, { Component } from "react";
import { getFormDetails, createDynamicForm } from "../../Redux/actions";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";
import { Link } from "react-router-dom";

class NewForm extends Component {
  render() {
    const {
      fieldname,
      requireRouterCodeLogin,
      enableVoucherLogin,
      maxLength,
      placeholder,
      required,
      fieldtype,
      allformControl,
      userDataAllocation,
      allocationType,
      creatingFormErr,
      creatingForms,
      formControlOptions,
      options,
      formtype,
      tabname,
      tabdescription,
      alltabs,
      currenttab,
      nextform,
      newformErr,
      formCreated,
      showinfo,
      formName
    } = this.props;

    const handleaddtoform = (tabindex, tabarray) => {
      let tabinputs = tabarray;
      for (let i = 0; i < tabarray.length; i++) {
        if (i === tabindex) {
          tabinputs[i].formControls.push({
            fieldType: fieldtype,
            fieldName: fieldname,
            placeholder: placeholder,
            isRequired: required,
            maxLength: Number(maxLength),
            label: fieldname,
            formControlOptions: formControlOptions,
          });
        }
      }
      this.props.getFormDetails({
        props: ["tabarray"],
        value: tabinputs,
      });
    };


    return (
      <div className="col-lg-6 mb-4">
        <div className="card card-small mb-4">
          <div className="card-header t-center">
            <h6 className="m-0 bold">CREATE FORM</h6>
            <p className="t-12">
              {" "}
              Manage what your users see when they want to log in to your
              internet
            </p>
            <p className="text-red t-12">
              {" "}
              NB - Enabling router code login automatically generates email and
              router code input fields and setting bonus data frequency to one-time
              or recurring, automatically generates the phone number field
            </p>
          </div>

          <div className="col-sm-12 col-md-12 mb-3">
            <div className="br-1 pd-2 mb-5">
              {nextform === 0 ? " " : <p> Add to Form</p>}
              <div>
                {nextform === 0 ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <label> Form Type</label>
                      <div className="input-group mb-3">
                        <select
                          className="form-control loginCred"
                          onChange={(e) => {
                            this.props.getFormDetails({
                              props: ["formtype"],
                              value: e.target.value,
                            });
                            this.props.getFormDetails({
                              props: ["alltabs"],
                              value: [],
                            })
                          }

                          }
                        >
                          <option value="normal">Normal Form</option>
                          <option value="tabs">Tabbed Form</option>
                        </select>
                      </div>
                      <label> Form Name</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control loginCred"
                            aria-label="formName"
                            aria-describedby="basic-addon1"
                            value={formName}
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["formName"],
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                    </div>
                    {formtype === "tabs" ? (
                      <div className="col-lg-12">
                        <div className="col-lg-12">
                          <label> Tab Name</label>

                          <div className="input-group mb-3 d-flex">
                            <input
                              type="text"
                              className="form-control loginCred"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={tabname}
                              onChange={(e) =>
                                this.props.getFormDetails({
                                  props: ["tabname"],
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label> Tab Description</label>

                          <div className="input-group mb-3 d-flex">
                            <input
                              type="text"
                              className="form-control loginCred"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={tabdescription}
                              onChange={(e) =>
                                this.props.getFormDetails({
                                  props: ["tabdescription"],
                                  value: e.target.value,
                                })
                              }
                            />

                            <Button
                              disabled={tabname === "" ? true : false}
                              buttonAction={(e) => {alltabs.length > 2
                            ? this.props.getFormDetails({
                              props: ["newformErr"],
                              value:
                                " *You cannot have more than three tabs on your form",
                            }) :
                               this.props.getFormDetails({
                                  props: ["alltabs"],
                                  value: alltabs.concat({
                                    name: tabname,
                                    description: tabdescription,
                                    formControls: [],
                                  }),
                                });
                                this.props.getFormDetails({
                                  props: ["tabname"],
                                  value: "",
                                });
                                this.props.getFormDetails({
                                  props: ["tabdescription"],
                                  value: "",
                                });
                              }}
                              theme="btn-primary mb-0 wicryptColor"
                            >
                              {" "}
                              Add
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                        ""
                      )}
                    {<p className="text-red t-12">{newformErr}</p>}
                    <div className="col-lg-12">
                      {alltabs.map((eachtab, i) => {
                        return (
                          <div
                            onClick={() =>
                              this.props.getFormDetails({
                                props: ["currenttab"],
                                value: i,
                              })
                            }
                          >
                            <p className="mb-0"> {eachtab.name}</p>
                            <p className="t-12">{eachtab.description}</p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-lg-9"> </div>
                    <div className="">
                      <Button
                        buttonAction={(e) =>
                          formtype !== "normal" && alltabs.length < 2
                            ? this.props.getFormDetails({
                              props: ["newformErr"],
                              value:
                                " *Consider using the normal form, if you would have less than two tabs",
                            })
                            : this.props.getFormDetails({
                              props: ["nextform"],
                              value: 1,
                            })
                        }
                        theme="btn-primary mb-0 wicryptColor"
                      >
                        {" "}
                        Next
                      </Button>
                    </div>
                  </div>
                ) : (
                    <div className="row ">
                      <div className="col-lg-12 d-flex mb-5 bb-2">
                        {alltabs.map((eachtab, i) => {
                          return (
                            <div
                              className={
                                currenttab === i
                                  ? "activetab p-2 pointer"
                                  : "pointer p-2"
                              }
                              onClick={() =>
                                this.props.getFormDetails({
                                  props: ["currenttab"],
                                  value: i,
                                })
                              }
                            >
                              <p className="mb-0"> {eachtab.name}</p>
                            </div>
                          );
                        })}
                        {formtype !== "normal" ? (
                          <div className="p-2">
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.props.getFormDetails({
                                  props: ["nextform"],
                                  value: 0,
                                })
                              }
                            >
                              Add More Tabs
                          </i>
                          </div>
                        ) : (
                            ""
                          )}
                      </div>
                      <div className="col-lg-6">
                        <label> Field Name</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control loginCred"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={fieldname}
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["fieldname"],
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label> Placeholder</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control loginCred"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={placeholder}
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["placeholder"],
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label> Required?</label>
                        <div className="input-group mb-3">
                          <select
                            className="form-control loginCred"
                            onChange={(e) =>
                              this.props.getFormDetails({
                                props: ["required"],
                                value: e.target.value,
                              })
                            }
                          >
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label> Expected Input?</label>
                        <div className="input-group mb-3">
                          <select
                            className="form-control loginCred"
                            onChange={(e) =>
                              this.props.mDetails({
                                props: ["fieldtype"],
                                value: e.target.value,
                              })
                            }
                          >
                            <option value="TextField">Text</option>
                            <option value="TextField">Number</option>
                            <option value="TextArea">TextArea</option>
                            <option value="DropDown">DropDown</option>
                            <option value="CheckBox"> CheckBox</option>
                            <option value="RadioButton">Radio Button</option>
                          </select>
                        </div>
                      </div>
                      {fieldtype === "TextField" || fieldtype === "TextArea" ? (
                        <div className="col-lg-6">
                          <label> Maximum Length</label>
                          <div className="input-group mb-3">
                            <input
                              type="number"
                              className="form-control loginCred"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={maxLength}
                              onChange={(e) =>
                                this.props.getFormDetails({
                                  props: ["maxLength"],
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                      {fieldtype === "TextField" || fieldtype === "TextArea" ? (
                        ""
                      ) : (
                          <div className="col-lg-12">
                            <label> {fieldtype} Values</label>

                            <div className="input-group mb-3 d-flex">
                              <input
                                type="text"
                                className="form-control loginCred"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={options}
                                onChange={(e) =>
                                  this.props.getFormDetails({
                                    props: ["options"],
                                    value: e.target.value,
                                  })
                                }
                              />

                              <Button
                                buttonAction={(e) => {
                                  this.props.getFormDetails({
                                    props: ["formControlOptions"],
                                    value: formControlOptions.concat({
                                      text: options,
                                      value: options,
                                    }),
                                  });
                                  this.props.getFormDetails({
                                    props: ["options"],
                                    value: "",
                                  });
                                }}
                                theme="btn-primary mb-0 wicryptColor"
                              >
                                {" "}
                            Add
                          </Button>
                            </div>
                          </div>
                        )}

                      <div className="col-lg-6" />
                      <div className="col-lg-6" />
                      <div className="col-lg-6">
                        {formtype === "normal" ? (
                          <Button
                            buttonAction={(e) => {
                              this.props.getFormDetails({
                                props: ["fieldname"],
                                value: "",
                              });
                              this.props.getFormDetails({
                                props: ["placeholder"],
                                value: "",
                              });
                              this.props.getFormDetails({
                                props: ["maxLength"],
                                value: 0,
                              });
                              this.props.getFormDetails({
                                props: ["formControlOptions"],
                                value: [],
                              });
                              this.props.getFormDetails({
                                props: ["allformControl"],
                                value: allformControl.concat({
                                  fieldType: fieldtype,
                                  fieldName: fieldname,
                                  placeholder: placeholder,
                                  isRequired: required,
                                  maxLength: Number(maxLength),
                                  label: fieldname,
                                  formControlOptions: formControlOptions,
                                }),
                              });
                            }}
                            theme="btn-primary mt-4 w-100 wicryptColor"
                            disabled={
                              (fieldname === "") & (placeholder === "")
                                ? true
                                : (fieldtype !== "TextField") &
                                  (fieldtype !== "TextArea") &
                                  (formControlOptions.length === 0)
                                  ? true
                                  : false
                            }
                          >
                            Add to Form
                          </Button>
                        ) : (
                            <Button
                              buttonAction={(e) => {
                                this.props.getFormDetails({
                                  props: ["fieldname"],
                                  value: "",
                                });
                                this.props.getFormDetails({
                                  props: ["placeholder"],
                                  value: "",
                                });
                                this.props.getFormDetails({
                                  props: ["maxLength"],
                                  value: 0,
                                });
                                this.props.getFormDetails({
                                  props: ["formControlOptions"],
                                  value: [],
                                });
                                handleaddtoform(currenttab, alltabs);
                              }}
                              theme="btn-primary mt-4 w-100 wicryptColor"
                              disabled={
                                (fieldname === "") & (placeholder === "")
                                  ? true
                                  : (fieldtype !== "TextField") &
                                    (fieldtype !== "TextArea") &
                                    (formControlOptions.length === 0)
                                    ? true
                                    : false
                              }
                            >
                              Add to Form
                            </Button>
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label> Bonus Data Amount <i className="fa fa-info-circle pointer" onClick={() => this.props.getFormDetails({
                  props: ["showinfo"],
                  value: showinfo !== 1 ? 1 : 0,
                })} aria-hidden="true"></i></label>
                {showinfo === 1 ? <div className="info-allocation"> <p className="t-12"> This is the amount of bonus data in MB you can give to your users</p></div> : ""}
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control loginCred"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={userDataAllocation}
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["userDataAllocation"],
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label> Bonus Data Frequency
                <i className="fa fa-info-circle pointer ml-1" onClick={() => this.props.getFormDetails({
                  props: ["showinfo"],
                  value: showinfo !== 2 ? 2 : 0,
                })} aria-hidden="true"></i>
                </label>
                {showinfo === 2 ? <div className="info-allocation"> <p className="t-12"> How frequently do you want your users to be able to use their bonus data </p></div> : ""}
                <div className="input-group mb-3">
                  <select
                    className="form-control loginCred"
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["allocationType"],
                        value: Number(e.target.value),
                      })
                    }
                  >
                    <option value={1}>None</option>
                    <option value={2}>Recurring</option>
                    <option value={3}>One Time</option>
                  </select>
                </div>
              </div>

            </div>
            <fieldset>
              <div className="custom-control custom-toggle custom-toggle-sm mb-1">
                <input
                  type="checkbox"
                  id="customToggle1"
                  name="customToggle1"
                  className="custom-control-input"
                  checked={enableVoucherLogin}
                  onChange={() =>
                    this.props.getFormDetails({
                      props: ["enableVoucherLogin"],
                      value: !enableVoucherLogin,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="customToggle1">
                  Enable Voucher Login
                </label>
              </div>
              <div className="custom-control custom-toggle custom-toggle-sm mb-1">
                <input
                  type="checkbox"
                  id="customToggle2"
                  name="customToggle2"
                  className="custom-control-input"
                  checked={requireRouterCodeLogin}
                  onChange={() =>
                    this.props.getFormDetails({
                      props: ["requireRouterCodeLogin"],
                      value: !requireRouterCodeLogin,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="customToggle2">
                  Require Router Code Login
                </label>
              </div>
            </fieldset>
            <div className="t-center mt-5">
              <div className="mb-2 mt-2">
                <Button
                  theme="btn-primary w-50 wicryptColor"
                  buttonAction={() =>
                    this.props.createDynamicForm(
                      allformControl,
                      requireRouterCodeLogin,
                      enableVoucherLogin,
                      userDataAllocation,
                      allocationType,
                      alltabs,
                      formName
                    )
                  }
                >
                  {creatingForms ? <Loader /> : "Create Form"}
                </Button>
              </div>
              <p className="t-12 text-red mb-0"> {creatingFormErr}</p>
              {formCreated ?
                <Link
                  to="/report/dynamicform"
                >
                  {" "}
                View All Form Responses
                <span> <i className="fa fa-arrow-right" aria-hidden="true" /></span>
                </Link>
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    fieldname,
    requireRouterCodeLogin,
    enableVoucherLogin,
    maxLength,
    placeholder,
    required,
    fieldtype,
    allformControl,
    userDataAllocation,
    allocationType,
    formControlOptions,
    options,
    formtype,
    tabname,
    tabdescription,
    alltabs,
    currenttab,
    nextform,
    newformErr,
    formId,
    showinfo,
    formName
  } = state.User;
  const { creatingFormErr, formCreated } = state.DynamicForm;
  const { creatingForms } = state.Loading;

  return {
    fieldname,
    requireRouterCodeLogin,
    enableVoucherLogin,
    maxLength,
    placeholder,
    required,
    fieldtype,
    allformControl,
    userDataAllocation,
    allocationType,
    creatingFormErr,
    creatingForms,
    formControlOptions,
    options,
    formtype,
    tabname,
    tabdescription,
    alltabs,
    currenttab,
    nextform,
    newformErr,
    formId,
    formCreated,
    showinfo,
    formName
  };
};

export default connect(
  MapStateToProps,
  { getFormDetails, createDynamicForm }
)(NewForm);
