import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  changeActive,
  logOut,
  getAgentBusiness,
  getFormDetails,
} from "../../Redux/actions";
import { connect } from "react-redux";
import { ViewAllContainer, SidenavLink, SideIcons, SubTitle } from "./partials";
import { RiLogoutCircleRLine } from "react-icons/ri";

class SubSidenav extends Component {
  componentWillMount() {
    // this.props.getAgentBusiness();
  }

  handleonClick = (id) => {
    this.props.changeActive(id);
  };

  render() {
    const { adminsubnav, togglesubnav } = this.props;

    return (
      <React.Fragment>
        {togglesubnav ? (
          <ViewAllContainer className="w3-animate-top">
            {adminsubnav.map((link) => {
              return (
                <Link to={`/${link.link}`}>
                  <SidenavLink
                    onClick={() =>
                      this.props.getFormDetails({
                        props: ["togglesubnav"],
                        value: !togglesubnav,
                      })
                    }
                    key={link.id}
                    active={window.location.pathname === "/" + link.link}
                  >
                    <SideIcons
                      src={
                        window.location.pathname === "/" + link.link
                          ? link.activeImage
                          : link.inactiveImage
                      }
                    />
                    {link.title}
                    <SubTitle> {link.subtitle}</SubTitle>
                  </SidenavLink>
                </Link>
              );
            })}
            <hr />

            <SidenavLink onClick={() => this.props.logOut()}>
              <RiLogoutCircleRLine />
              Log Out
            </SidenavLink>
          </ViewAllContainer>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const MapStateToProps = (state) => {
  const { togglesubnav } = state.User;
  const { adminsubnav } = state.navLinks;
  return {
    adminsubnav,
    togglesubnav,
  };
};

export default connect(MapStateToProps, {
  changeActive,
  logOut,
  getAgentBusiness,
  getFormDetails,
})(SubSidenav);
