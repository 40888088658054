
import _const from '../actions/Types'

const initialState = {
    stakeLog: {},
    stakingSummary: {},
    unstakeSummary: {},
    stakingStatisticsSummary: {},
    distributedRewardOverview: {},
    distributedRewardDetails: {},
    upcomingUnstakeReport: {},
    upcomingUnstakeOverview: {}
}

const Stake = (state = initialState, action) => {
    switch (action.type) {
        case _const.STAKE_LOG:
            return {
                ...state, stakeLog: action.payload
            }

        case _const.STAKING_SUMMARY:
            return {
                ...state, stakingSummary: action.payload
            }

        case _const.UNSTAKE_SUMMARY:
            return { ...state, unstakeSummary: action.payload }

        case _const.STAKING_STATISTICS_REPORT:
            return { ...state, stakingStatisticsSummary: action.payload }

        case _const.DISTRIBUTED_REWARD_OVERVIEW:
            return{...state, distributedRewardOverview: action.payload}

        case _const.DISTRIBUTED_REWARD_DETAILS:
            return{...state, distributedRewardDetails: action.payload}

        case _const.UPCOMING_UNSTAKE_REPORT:
            return{...state, upcomingUnstakeReport: action.payload}
    
        case _const.UPCOMING_UNSTAKE_OVERVIEW:
            return{...state, upcomingUnstakeOverview: action.payload}

        default: return state
    }
}

export default Stake