import React, { Component } from 'react';
import { connect } from "react-redux";
import { toggleModal, getAdminTokens, getFormDetails, createBusinessToken } from "../../Redux/actions";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import filesize from 'filesize';

class AdminTokenReport extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.tokenusagetype !== this.props.tokenusagetype || nextProps.tokenStatus !== this.props.tokenStatus || (nextProps.cutomertokenemail !== "" & nextProps.searchcustomeremail)) {
      this.props.getAdminTokens(nextProps.tokenStatus, nextProps.tokenusagetype, nextProps.cutomertokenemail)
    }

  }
  render() {
    const { gettingalladmintoken, alladminToken, cutomertokenemail, alladminTokenErr, searchcustomeremail } = this.props;
    let allRows = [];
    alladminToken.map((token) => {
      return allRows.push({
        businessName: token.businessName,
        code: token.code,
        dataallocated: filesize(token.bytesAllocated),
        dataTokenUsageType: token.dataTokenUsageType,
        secondsAllocated: token.secondsAllocated + " secs",
        voucherStatus: token.voucherStatus,
      });
    });

    const data = {
      columns: [
        {
          label: "Business Name",
          field: "businessName",
          sort: "asc",
          width: 170,
        },
        {
          label: "Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Data Allocated",
          field: "dataallocated",
          sort: "asc",
          width: 170,
        },
        {
          label: "Usage Type",
          field: "dataTokenUsageType",
          sort: "asc",
          width: 170,
        },
        {
          label: "Validity Period",
          field: "secondsAllocated",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "voucherStatus",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="">
        <div className="row mb-5">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="d-flex">
              <div className="createbtn">
                <div className="upload-btn-wrapper">
                  <i className="fa fa-plus"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.toggleModal(true)}>
                    {" "}
                        Generate Token
                    </i>
                </div>
              </div>
            </div>

            <div className="mt-3 d-flex">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control loginCred"
                  aria-label="email"
                  aria-describedby="basic-addon1"
                  placeholder="Business email"
                  value={cutomertokenemail}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["cutomertokenemail"],
                      value: e.target.value,
                    })
                  }
                />
                <button onClick={(e) =>
                  this.props.getFormDetails({
                    props: ["searchcustomeremail"],
                    value: !searchcustomeremail,
                  })
                } className="input-group-btn"> Search </button>
              </div>
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["tokenStatus"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Status</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}> Inactive</option>
                <option value={"Exhausted"}> Exhausted</option>
                <option value={"Expired"}> Expired</option>
              </select>
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["tokenusagetype"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>All</option>
                <option value={"Timed"}>Timed</option>
                <option value={"data"}> Data</option>
              </select>
            </div>
          </div>
          {gettingalladmintoken ? (
            <div className="col-lg-12">
              <div className=" t-center mt-4">
                <CustomLoader />
              </div>
            </div>
          ) : alladminToken.length === 0 && !gettingalladmintoken ? (
            <div className="col-lg-12 mb-4">
              <p className="voucherText t-center">
                {" "}
                {alladminTokenErr !== "" ? alladminTokenErr : "No tokens to show."}
              </p>
            </div>
          ) : (
                <div className="col-lg-12">
                  <div className=" t-center mt-4">
                    <CustomDataTable
                      data={data}
                    // pagination={this.customPagination()}
                    />
                  </div>
                </div>
              )}
        </div>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  const { gettingalladmintoken } = state.Loading;
  const { alladminToken, alladminTokenErr } = state.DataToken
  const { cutomertokenemail, tokenusagetype, tokenStatus, searchcustomeremail } = state.User
  return {
    gettingalladmintoken,
    alladminToken,
    tokenusagetype,
    tokenStatus,
    cutomertokenemail,
    alladminTokenErr,
    searchcustomeremail
  };
};

export default connect(MapStateToProps, { toggleModal, getAdminTokens, getFormDetails, createBusinessToken })(AdminTokenReport);