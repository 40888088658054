import React from "react";
import { ContinueBtn } from "../../Reusables/inputs/ContinueButton";
import { ButtonContainer } from "../../Reusables/inputs/inputstyles";
import CustomModal from "../../Reusables/modals/CustomModal";
import { theme } from "../../../theme";
import { Answer, Body } from "./styles";
import { handleModal } from "../../../Redux/actions";
import { getFormDetails } from "../../../Redux/actions";

import { useDispatch } from "react-redux";
import PinBoxes from "../../Reusables/inputs/PinBoxes";

function EnterPin() {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value, name } = e.target;

    getFormDetails({
      props: name,
      value: value,
    });
  };
  return (
    <div>
      <CustomModal
        id={17}
        heading="Enter Pin"
        subHeading="Enter your pin to proceed"
        close={true}
        content={
          <>
            <Body>
              <div className="mb-3">
                <div className="d-flex" style={{ gap: "15px" }}>
                  <PinBoxes name="pin91" keyUp={handleChange} />
                  <PinBoxes name="pin92" keyUp={handleChange} />
                  <PinBoxes name="pin93" keyUp={handleChange} />
                  <PinBoxes name="pin94" keyUp={handleChange} />
                </div>
              </div>
              <Answer onClick={() => dispatch(handleModal(18))}>
                Forgot Pin?
              </Answer>
            </Body>
            {/* {err ? <p className="errorMsgs mt-3 t-center">{err}</p> : ""} */}
            <ButtonContainer>
              <ContinueBtn
                onClick={() => dispatch(handleModal(18))}
                title={false ? "Loading..." : "Submit"}
                darktheme={theme}
              />
            </ButtonContainer>
          </>
        }
      />
    </div>
  );
}

export default EnterPin;
