import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  getSubBusiness,
  createSubBusiness,
  toggleModal,
  getByemail,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";

class CreateSubBusiness extends Component {

  render() {
    const {
      businessAddress,
      imagePreview,
      businessEmail,
      businessName,
      merchantLogo,
      checkUser,
      logginInUser,
      merchantError,
      creatingSubBusiness,
      emailFail,
      businessProfile,
      fetchingname,
    } = this.props;
    let data = {
      id: businessProfile.id,
      userId: logginInUser.id,
      apiKey: businessProfile.apiKey,
      name: businessName,
      logoUrl: null,
      logoFile: merchantLogo,
      officeAddress: businessAddress,
      walletBalance: 0,
      canCreateSubsidiary: true,
    };

    return (

      <Modal heading="CREATE SUBSIDIARY BUSINESS">
        {!checkUser ? (
          <div>
            <label> Email</label>
            <div className="input-group ">
              <input
                type="text"
                className="form-control loginCred mb-3"
                aria-label="businessEmail"
                aria-describedby="basic-addon1"
                value={businessEmail}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["businessEmail"],
                    value: e.target.value,
                  })
                }
              />
            </div>
            {emailFail !== "" ? (
              <p className="errorMsgs">
                <i className="material-icons">info</i>
                {emailFail}
              </p>
            ) : (
                ""
              )}
            <div className="loginBtn">
              <button
                onClick={() => this.props.getByemail(businessEmail)}
                className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin"
              >
                {fetchingname ? <Loader /> : "Create"}
              </button>
            </div>
          </div>
        ) : (
            <div>
              <div className="previewImg">
                <img
                  className="businessImg"
                  id="Img1"
                  src={
                    imagePreview !== ""
                      ? imagePreview
                      : "https://assets.hongkiat.com/uploads/psd-text-svg/logo-example.jpg"
                  }
                  alt=""
                />
              </div>

              <label> Business Name</label>
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control loginCred"
                  aria-label="businessName"
                  aria-describedby="basic-addon1"
                  value={businessName}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["businessName"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Business Address</label>
              <div className="input-group ">
                <input
                  type="email"
                  className="form-control loginCred"
                  aria-label="businessAddress"
                  value={businessAddress}
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["businessAddress"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Upload Logo</label>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control loginCred"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["merchantLogo"],
                      value: e.target.files[0],
                    })
                  }
                />
              </div>
              {merchantError !== "" ? (
                <p className="errorMsgs">
                  <i className="material-icons">info</i>
                  {merchantError}
                </p>
              ) : (
                  ""
                )}

              <div className="loginBtn">
                <button
                  onClick={() => this.props.createSubBusiness(data)}
                  className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin"
                >
                  {creatingSubBusiness ? <Loader /> : "Create Business"}
                </button>
              </div>
            </div>
          )}
      </Modal>

    );
  }
}

const MapStateToProps = (state) => {
  const {
    businessAddress,
    imagePreview,
    businessEmail,
    businessName,
    merchantLogo,
    emailFail,
    businessProfile,
    checkUser,
    logginInUser,
    merchantError,
  } = state.User;
  const {
    creatingSubBusiness,
    fetchingname,
  } = state.Loading;

  return {
    creatingSubBusiness,
    businessAddress,
    merchantError,
    businessProfile,
    fetchingname,
    logginInUser,
    imagePreview,
    businessEmail,
    businessName,
    merchantLogo,
    emailFail,
    checkUser,

  };
};

export default connect(
  MapStateToProps,
  { getFormDetails, getSubBusiness, createSubBusiness, toggleModal, getByemail }
)(CreateSubBusiness);
