import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { getCurrency, getSearchDetails, createCurrency, handleModal } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import Loader from "../Reusables/loaders/MoonLoader";
import TableHeader from "../Reusables/tables/TableHeader";
import CurrencyControlModal from "./modals/CurrencyControlModal";


const CurrencyControl = () => {

    const dispatch = useDispatch();

    const { allCurrencies, currencyTotalPages, currencyCurrentPage, setActiveError } = useSelector((state) => state.Admin);
    const { gettingCurrencies, creatingCurrencies } = useSelector((state) => state.Loading)
    const {
        Page,
        PageSize,
        enabled,
        search,
    } = useSelector((state) => state.Search);
    const [operation, setOperation] = useState("");
    const [currencyId, setCurrencyId] = useState("");
    const [operationCategory, setOperationCategory] = useState("");
    const [isEnabled, setIsEnabled] = useState("");
    const [id, setId] = useState("");
    const [currencyName, setCurrencyName] = useState("");


    const currencyOptions = [
        {
            id: 1,
            title: "Status",
            type: "select",
            onSelectProp: "enabled",
            options: [
                {
                    id: 1,
                    option: "All",
                },
                {
                    id: 2,
                    option: "Enabled",
                },
                {
                    id: 3,
                    option: "Disabled",
                },
            ],
        },
        {
            id: 3,
            title: "Search",
            type: "text",
            placeholder: "Search...",
            onChangeProp: "search",
        },
    ]
    let allRows = [];
    allCurrencies.map((currency) => {
        return allRows.push({
            code: <div style={{ display: "flex" }}>
                <img height="15px" src={currency.iconSvg} alt="icon svg" />
                <p className="pl-2 text-bold"> {currency.code}</p>
            </div>,
            currency: currency.name,
            opearation: currency.operationCategory,
            isEnabled: currency.isEnabled ? "Enabled" : "Disabled",
            setstatus: 
            <div 
            className={currency.isEnabled ? "bkg-red details" : "bkg-green details"}
            onClick={() => {dispatch(handleModal(87)); setCurrencyId(currency.currencyId); setOperationCategory(currency.operationCategory); setIsEnabled(currency.isEnabled); setId(currency.id); setCurrencyName(currency.name)}}>
                    { currency.isEnabled ? "Disable" : "Enable"}
            </div>
        });
    });
    const data = {
        columns: [
            {
                label: "",
                field: "code",
                sort: "asc",
                width: 30,
            },
            {
                label: "Currency",
                field: "currency",
                sort: "asc",
                width: 170,
            },
            {
                label: "Operation",
                field: "opearation",
                sort: "asc",
                width: 170,
            },
            {
                label: "Currency Enabled",
                field: "isEnabled",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "setstatus",
                sort: "asc",
                width: 170,
            }

        ],
        rows: allRows,
    };

    const fetchCurrencies = () => {
        let status = enabled === "Disabled" ? false : enabled === "Enabled" ? true : ""
        dispatch(getCurrency(Page, PageSize, "", status, search))
    }

    useEffect(() => {
        fetchCurrencies();
    }, [Page, PageSize, enabled]);

    // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
        fetchCurrencies();
    }
  },[search])

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <CustomDataTable
                                data={data}
                                tableId={"currency-control"}
                                pageCount={currencyTotalPages}
                                currentPage={currencyCurrentPage}
                                header={
                                    <TableHeader
                                        subHeader={currencyOptions}
                                        onSelect={(val, props) => {
                                            dispatch(
                                                getSearchDetails({
                                                    props: [props],
                                                    value: val,
                                                })
                                            );
                                        }}
                                        onChange={(e, props) => {
                                            dispatch(
                                                getSearchDetails({
                                                    props: [props],
                                                    value: e.target.value,
                                                })
                                            );
                                        }}
                                        onSearchClick={fetchCurrencies}
                                    />
                                }
                                loading={gettingCurrencies}
                                noData={
                                    <EmptyCard
                                        img="/Assets/emptytransactions.svg"
                                        title={"You have no currency options"}
                                    // descr={"No transaction history available at the moment"}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CurrencyControlModal
                onSubmit={() => { 
                    dispatch(createCurrency({
                        "currencyId": currencyId,
                        "operation": operationCategory,
                        "isEnabled": !isEnabled
                    }));
                    setOperation(id)
                 }} 
                setActiveError={setActiveError}
                updatingProviderStatus={creatingCurrencies}
                enableDisable={isEnabled ? "Disabled" : "Enabled"}
                currencyName={currencyName}
            />
        </div>
    );
};

export default CurrencyControl;
