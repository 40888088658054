import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import BusinessReport from "./businessReport";
import { MainContent } from "../Partials/partials";

class Business extends Component {
  render() {
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4">
            <BusinessReport businessId={this.props.businessId} />
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state, props) => {
  const businessId = props.match.params.businessId;

  return {
    businessId,
  };
};

export default connect(MapstateToProps)(Business);
