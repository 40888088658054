import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

export const getFilteredUserFinancialAuditByDate = (
  status,
  startDate,
  endDate
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_REQUESTS,
      payload: true,
    });
    //i need to plug int the current endpoint
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/GetBusinessBalance?entityStatus=${status}&startDateTimeStamp=${startDate}&endDateTimeStamp=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: _const.HAS_BUSINESS_AUDIT_BEEN_FETCHED_BY_DATE,
          payload: true,
        });
        dispatch({
          type: _const.GETTING_REQUESTS,
          payload: false,
        });
        dispatch({
          type: _const.GET_FILTERED_BY_DATE_BUSINESS_AUDIT_LOGS,
          payload: res.data.data.records,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_REQUESTS,
          payload: false,
        });
      });
  };
};

export const getBusinessAgents = (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_BUSINESS_AGENTS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  axios
    .get(config.adminApiUrl + `/v1/businesses/agents`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_BUSINESS_AGENTS,
        payload: false,
      });
      dispatch({
        type: _const.BUSINESS_AGENTS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_BUSINESS_AGENTS,
        payload: false,
      });
    });
};
