import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Create a dynamic form
export const createDynamicForm = (
  formControl,
  requireRouterCodeLogin,
  enableVoucherLogin,
  userDataAllocation,
  allocationType,
  alltabs,
  formName
) => {

  let data = {
    tabs: alltabs,
    formControls: formControl,
    userDataAllocationForLogin: Number(userDataAllocation),
    allocationType: allocationType,
    enableVoucherLogin: enableVoucherLogin,
    requireRouterCodeLogin: requireRouterCodeLogin,
    formName: formName
  };
  return (dispatch) => {
    dispatch({
      type: _const.CREATING_FORM,
      payload: true,
    });
    dispatch({
      type: _const.CREATING_FORM_ERR,
      payload: '',
    });
    axios
      .post(config.apiUrl + "/v2/dynamicForm/splash", data)
      .then((res) => {
        dispatch({
          type: _const.CREATING_FORM,
          payload: false,
        });
        if (res.data.errors.length === 0) {
          Swal.fire({
            text: "Your form has been created successfully!",
            type: "success",
          });
        } else {
          Swal.fire({
            text: res.data.errors[0],
            type: "error",
          });
        }
        dispatch({
          type: _const.CLEAR_DYNAMIC_FORMS,
          payload: []
        })
        dispatch(allDynamicForm("", 1, 50))
      })
      .catch((err) => {
        dispatch({
          type: _const.CREATING_FORM,
          payload: false,
        });
        dispatch({
          type: _const.CREATING_FORM_ERR,
          payload: err.response.data.message,
        });
      });
  };
};

//Reset forms
export const resetForms = () =>{
  return(dispatch)=>{
    dispatch({
      type: _const.CLEAR_DYNAMIC_FORMS,
      payload: []
    })
  }
}

//Activate dynamic form
export const activateDynamicForm = (FormCode) => {
  return (dispatch) => {
    dispatch({
      type: _const.ACTIVATING_FORM,
      payload: true,
    });
    dispatch({
      type: _const.GET_FORM_CODE,
      payload: FormCode,
    });
    axios
      .put(
        config.apiUrl + `/v2/dynamicForm/splash/activate?FormCode=${FormCode}`
      )
      .then((res) => {
        dispatch({
          type: _const.ACTIVATING_FORM,
          payload: false,
        });
        if (res.data.errors.length === 0) {
          Swal.fire({
            text: "Your form has been activated successfully!",
            type: "success",
          });
        } else {
          Swal.fire({
            text: res.data.errors[0],
            type: "error",
          });
        }
        dispatch({
          type: _const.CLEAR_DYNAMIC_FORMS,
          payload: []
        })
        dispatch({
          type: _const.FORM_CREATED,
          payload: true
        })
        dispatch(allDynamicForm("", 1, 50))
      })
      .catch((err) => {
        dispatch({
          type: _const.ACTIVATING_FORM,
          payload: false,
        });
        Swal.fire({
          text: err.response ? err.response.data.error[0] : 'Something went wrong',
          type: 'error'
        })

      });
  };
};

//De-activate dynamic form
export const deactivateDynamicForm = (FormCode) => {
  return (dispatch) => {
    dispatch({
      type: _const.DEACTIVATING_FORM,
      payload: true,
    });
    dispatch({
      type: _const.GET_FORM_CODE,
      payload: FormCode,
    });
    axios
      .put(
        config.apiUrl + `/v2/dynamicForm/splash/deactivate?FormCode=${FormCode}`
      )
      .then((res) => {
        dispatch({
          type: _const.DEACTIVATING_FORM,
          payload: false,
        });
        if (res.data.errors.length === 0) {
          Swal.fire({
            text: "Your form has been deactivated successfully!",
            type: "success",
          });
        } else {
          Swal.fire({
            text: res.data.errors[0],
            type: "error",
          });
        }
        dispatch({
          type: _const.CLEAR_DYNAMIC_FORMS,
          payload: []
        })
        dispatch(allDynamicForm("", 1, 50))
      })
      .catch((err) => {
        dispatch({
          type: _const.DEACTIVATING_FORM,
          payload: false,
        });
        Swal.fire({
          text: err.response ? err.response.data.error[0] : 'Something went wrong',
          type: 'error'
        })

      });
  };
};


// Delete dynamic form
export const deleteForm = (FormId, FormCode) => {
  let data = {
    "businessFormId": FormId
  }
  return (dispatch) => {
    dispatch({
      type: _const.DELETING_FORM,
      payload: true,
    });
    dispatch({
      type: _const.GET_FORM_CODE,
      payload: FormCode,
    });
    axios.delete(config.apiUrl + `/v2/dynamicForm/splash`, {data: data})
      .then((res) => {
        dispatch({
          type: _const.DELETING_FORM,
          payload: false,
        });
        if (res.data.errors.length === 0) {
          Swal.fire({
            text: "Your form has been deleted!",
            type: "success",
          });
        } else {
          Swal.fire({
            text: res.data.errors[0],
            type: "error",
          });
        }
        dispatch({
          type: _const.CLEAR_DYNAMIC_FORMS,
          payload: []
        })
        dispatch(allDynamicForm("", 1, 50))
      })
      .catch((err) => {
        dispatch({
          type: _const.DELETING_FORM,
          payload: false,
        });
        Swal.fire({
          text: err.response ? err.response.data.message : 'Something went wrong',
          type: 'error'
        })

      });
  };
};


//Getting dynamic forms
export const allDynamicForm = (status, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DYNAMIC_FORMS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/dynamicForm/splash?status=${status}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DYNAMIC_FORMS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_FORMS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DYNAMIC_FORMS,
          payload: false,
        });
      });
  };
};


//Getting dynamic form report
export const dynamicFormReport = (id, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DYNAMIC_FORMS_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/dynamicForm/splash/entry?businessFormId=${id}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DYNAMIC_FORMS_REPORT,
          payload: false,
        });
        dispatch({
          type: _const.DYNAMIC_FORM_REPORT,
          payload: res.data.data
        })
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DYNAMIC_FORMS_REPORT,
          payload: false,
        });
      });
  };
};

export const clearResponse = () => {
  return (dispatch) => {
    dispatch({
      type: _const.CLEAR_FORM_RESPONSES,
      payload: true
    })
  }
}