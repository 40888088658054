import React, { Component } from "react";
import {
  EmptyBusinessContainer,
  BagContainer,
  OpenBusiness
} from "./walletstyles";
import { handleModal } from "../../Redux/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class NoBusiness extends Component {
  render() {
    return (
      <div className="card card-small mb-4 w3-animate-right">
        <EmptyBusinessContainer>
          <div>
            <p className="bold"> Open Business Account</p>
          </div>
          <BagContainer>
            <img src="/Assets/cardicons/business.svg" alt="business" />
          </BagContainer>
          <div>
            <p className="t-12">
              Create your own open hotspot network and show ads to users when
              they connect, or bill them at your preferred rate. Manage overall
              data usage and switch between network providers easily when your
              primary provider is unavailable.
              <a
                className="text-orange bold"
                href="https://wicrypt.com/business.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Learn more
              </a>
            </p>
          </div>
          <Link to="/registration">
            <OpenBusiness className="form-control loginButton">
              {" "}
              Open Now
            </OpenBusiness>
          </Link>
        </EmptyBusinessContainer>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const { businessBalance, currencyValue } = state.User;
  return {
    businessBalance,
    currencyValue
  };
};

export default connect(
  MapStateToProps,
  { handleModal }
)(NoBusiness);
