import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  generatebulkvouchers,
  getusergeneratedvouchers,
  toggleModal,
  saveId,
  clearData,
} from "../../Redux/actions";
import Loader from '../Reusables/loaders/MoonLoader';
import Modal from "../Reusables/modals/Modal";
import { voucherHrs, voucherDays } from "../../Helper";
import {BigInput, ContinueBtn,  LayeredInput} from "../Reusables/inputs"
import {Label, ValidityContainer, InlineFlex, ValiditySelect} from '../Voucher/voucherstyles'

class CreateBulkVoucher extends Component {
  render() {
    const {
      voucherQty,
      creatingBulkVouchers,
      bulkVoucherError,
      voucherAmt,
      voucherValidity,
      days
    } = this.props;

    
    return (
        <Modal heading={"Generate Bulk Voucher"}>
        <LayeredInput
            placeholder=""
            title="Voucher Quantity"
            description="How many vouchers do you want to generate"
            value={voucherQty}
            type="number"
            onChange={(e) =>
                this.props.getFormDetails({
                  props: ["voucherQty"],
                  value: e.target.value,
                })
              }
        />
        <BigInput
            title="Click to enter amount"
            type="number"
            value={voucherAmt}
            onChange={(e) =>
                this.props.getFormDetails({
                  props: ["voucherAmt"],
                  value: e.target.value,
                })
            }
        />
        <ValidityContainer className="col-lg-12">
        <Label> Voucher validity</Label>
            <InlineFlex>
            <div
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["days"],
                  value: true,
                })
              }
            >
              <input
                type="radio"
                id="days"
                className="ml-5"
                name="validity"
                checked={days}
              />
              <Label htmlFor="days" className="ml-3">
                Days
              </Label>
            </div>
            
            <div
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["days"],
                  value: false,
                })
              }
            >
              <input
                type="radio"
                id="hours"
                name="validity"
                className="ml-5"
                checked={!days}
              />
              <Label htmlFor="hours" className="ml-3">
                Hours
              </Label>
            </div>  
            </InlineFlex> 
         
            <div className="input-group mb-2">
              <ValiditySelect 
                className="form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherValidity"],
                    value: e.target.value,
                  })
                }
              >
                <option value=""> Select validity period</option>
                {days ? voucherDays() : voucherHrs()}
              </ValiditySelect >
            </div>
        </ValidityContainer>
        {bulkVoucherError !== "" ? (
          <p className="errorMsgs">
            <i className="material-icons mr-1">info</i>
            {bulkVoucherError}
          </p>
        ) : (
            ""
          )}
        <ContinueBtn 
            onClick={() =>
                this.props.generatebulkvouchers(
                    voucherAmt,
                    voucherValidity,
                    voucherQty,
                    days
                  )
            }
            title={creatingBulkVouchers ? <Loader /> : "Set"} 
        />
       
      
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    bulkvoucherReceipient,
    voucherAmt,
    bulkvoucherstartDate,
    bulkvoucherendDate,
    bulkvoucherStatus,
    voucherReceipientEmail,
    voucherValidity,
    voucherMsg,
    businessProfile,
    selectedBusiness,
    voucherQty,
    days,
    bulkvouchercodeSearch
  } = state.User;
  const { creatingBulkVouchers, gettinguserGeneratedVouchers } = state.Loading;
  const { affiliatedBusiness } = state.Manager;
  const {
    userGeneratedVouchers,
    bulkVoucherError,
    savedBusinessId,
    userGeneratedVouchersCurrentPage,
    userGeneratedVouchersTotalPages,
    userGeneratedVouchersTotalRecords,
  } = state.Voucher;
  return {
    userGeneratedVouchers,
    voucherReceipientEmail,
    affiliatedBusiness,
    voucherQty,
    bulkvoucherReceipient,
    voucherAmt,
    bulkvoucherStatus,
    creatingBulkVouchers,
    gettinguserGeneratedVouchers,
    bulkVoucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    userGeneratedVouchersCurrentPage,
    userGeneratedVouchersTotalPages,
    userGeneratedVouchersTotalRecords,
    bulkvoucherstartDate,
    bulkvoucherendDate,
    selectedBusiness,
    days,
    bulkvouchercodeSearch
  };
};

export default connect(
  MapStateToProps,
  {
    getFormDetails,
    generatebulkvouchers,
    getusergeneratedvouchers,
    toggleModal,
    saveId,
    clearData,
  }
)(CreateBulkVoucher);
