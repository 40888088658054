import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import { dailyUserTransactionReports, getSearchDetails} from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import TableHeader from "../../Reusables/tables/TableHeader";
import {numberWithCommas} from "../../../Helper";
import moment from "moment"


const AccountReconciliation = ({userId}) => {

    const dispatch = useDispatch();

    const { dailyReports, dailyReporttotalPages, dailyReportcurrentPage } = useSelector((state) => state.Transactions);
    const { gettingDailyUserReport } = useSelector((state) => state.Loading)
    const [reconciliationOptions, setreconciliationOptions] = useState( 
        [
            {
                id: 1,
                title: "Duration",
                type: "select",
                onSelectProp: "duration",
                options: [
                    {
                        id: 1,
                        option: "All",
                    },
                    {
                        id: 2,
                        option: "Last Week",
                    },
                    {
                        id: 3,
                        option: "Last Month",
                    },
                    {
                        id: 4,
                        option: "Last 5 Months",
                    },
                    {
                        id: 5,
                        option: "Last Year",
                    },
                    {
                        id: 6,
                        option: "Custom",
                    },
                ],
            },
        ]);

    const {
        duration,
        Page,
        PageSize,
        startTime,
        endTime
    } = useSelector((state) => state.Search);

    let allRows = [];
    dailyReports.map((report) => {
        return allRows.push({
            dateCreated: moment(report.dateCreated).format("DD-MM-YYYY"),
            businessWalletAvailableBalance: numberWithCommas(report.businessWalletAvailableBalance, 2),
            businessWalletLockedBalance: numberWithCommas(report.businessWalletLockedBalance, 2),
            personalWalletAvailableBalance: numberWithCommas(report.personalWalletAvailableBalance, 2),
            personalWalletLockedBalance: numberWithCommas(report.personalWalletLockedBalance, 2),
            wntWalletAvailableBalance: numberWithCommas(report.wntWalletAvailableBalance, 2),
            wntWalletLockedBalance: numberWithCommas(report.wntWalletLockedBalance, 2)
        });
    });
    const data = {
        columns: [
            {
                label: "Date",
                field: "dateCreated",
                sort: "asc",
                width: 170,
            },
            {
                label: "Business Available Balance",
                field: "businessWalletAvailableBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "Business Locked Balance",
                field: "businessWalletLockedBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "Personal Available Balance",
                field: "personalWalletAvailableBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "Personal Locked Balance",
                field: "personalWalletLockedBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "WNT Available Balance",
                field: "wntWalletAvailableBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "WNT Locked Balance",
                field: "wntWalletLockedBalance",
                sort: "asc",
                width: 170,
            },

        ],
        rows: allRows,
    };

    useEffect(() => {
        let start = startTime ? new Date(startTime).getTime() : ""
        let end = endTime ? new Date(endTime).getTime() : ""
        dispatch(dailyUserTransactionReports(userId, '', start, end, duration, Page, PageSize))
    }, [duration, Page, PageSize, userId,  startTime, endTime]);

    useEffect(() => {
        if(duration === "Custom"){

            setreconciliationOptions([
                {
                    id: 1,
                    title: "Duration",
                    type: "select",
                    onSelectProp: "duration",
                    options: [
                        {
                            id: 1,
                            option: "All",
                        },
                        {
                            id: 2,
                            option: "Last Week",
                        },
                        {
                            id: 3,
                            option: "Last Month",
                        },
                        {
                            id: 4,
                            option: "Last 5 Months",
                        },
                        {
                            id: 5,
                            option: "Last Year",
                        },
                        {
                            id: 6,
                            option: "Custom",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "Start Time",
                    type: "startTime",
                    onChangeProp: "startTime",
                },
                {
                    id: 4,
                    title: "End Time",
                    type: "endTime",
                    onChangeProp: "endTime",
                },
            ]);
        }else{
            setreconciliationOptions(
                [
                    {
                        id: 1,
                        title: "Duration",
                        type: "select",
                        onSelectProp: "duration",
                        options: [
                            {
                                id: 1,
                                option: "All",
                            },
                            {
                                id: 2,
                                option: "Last Week",
                            },
                            {
                                id: 3,
                                option: "Last Month",
                            },
                            {
                                id: 4,
                                option: "Last 5 Months",
                            },
                            {
                                id: 5,
                                option: "Last Year",
                            },
                            {
                                id: 6,
                                option: "Custom",
                            },
                        ],
                    },
                ]
            );
        }
    }, [duration]);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"reconciliation-report"}
                            pageCount={dailyReporttotalPages}
                            currentPage={dailyReportcurrentPage}
                            header={
                                <TableHeader
                                    subHeader={reconciliationOptions}

                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );
                                    }}
                                    onChange={(e, props) =>{
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            }
                            loading={gettingDailyUserReport}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"You have no report"}
                                // descr={"No transaction history available at the moment"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountReconciliation;
