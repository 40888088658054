import React from "react";
import EmptyCard from "../cards/EmptyCard";
import {
    DeviceList,
    DeviceUl,
    DeviceLi
} from "./selectStyle";

export const SelectDevice = ({ allDevices, handleClick}) => {

    return (
        <DeviceList>
            <DeviceUl>
                {allDevices.length !== 0 ? allDevices.map(each => <DeviceLi key={each.deviceId}
                 onClick={()=>handleClick(each)}> {each.deviceName}</DeviceLi>) :  
                <EmptyCard
                    img="/Assets/emptytransactions.svg"
                    title={"No devices found"}
                // descr={"No Request available at the moment"}
                />}
            </DeviceUl>
        </DeviceList>
    );
};
