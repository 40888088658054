import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Button from "../buttons/Button";
import Loader from "../loaders/MoonLoader";
import { connect } from "react-redux";
import {
  registerUser,
  getFormDetails,
  handlePinReveal
} from "../../../Redux/actions";

class RegCard extends Component {
  render() {
    const {
      newUserEmail,
      newUserName,
      pinReveal,
      password,
      registeringUser,
      regInputError,
      confirmpassword,
      referralCode
    } = this.props;

    let data = {
      name: newUserName,
      referrer: referralCode === "" ? null : referralCode,
      email: newUserEmail,
      password: password,
      macAddress: null,
      confirmpassword: confirmpassword
    };

    return (
      // <div className="row">
      <div className="col-lg-6 offset-lg-3 fade-in">
        <div className="t-center p-10 mt-5 mb-5">
          <h5 className="card-title cardHeading mb-0">Create PIN</h5>
          <p className="wicryptmsg t-14 mb-1">
            {" "}
            Welcome, <span className="text-blue"> {newUserEmail}</span>
          </p>
          <p className="wicryptmsg t-14">
            {" "}
            Create 4 digit PIN to secure your account
          </p>
        </div>
        <div>
          <label> Create new PIN</label>
          <div className="input-group mb-3">
            <input
              type={pinReveal !== 1 ? "password" : "text"}
              className="form-control loginCred"
              aria-label="pin"
              maxLength={4}
              value={password}
              aria-describedby="basic-addon1"
              onChange={e =>
                this.props.getFormDetails({
                  props: ["password"],
                  value: e.target.value
                })
              }
            />
            {pinReveal !== 1 ? (
              <div onClick={() => this.props.handlePinReveal(1)}>
                <img className="eye" src="/Assets/eyeClosed.png" alt="" />
              </div>
            ) : (
              <div onClick={() => this.props.handlePinReveal(0)}>
                <img className="eye" src="/Assets/eyeOpen.png" alt="" />
              </div>
            )}
          </div>
          <label> Confirm new pin</label>
          <div className="input-group mb-3">
            <input
              type={pinReveal !== 10 ? "password" : "text"}
              className="form-control loginCred"
              aria-label="Username"
              value={confirmpassword}
              maxLength={4}
              aria-describedby="basic-addon1"
              onChange={e =>
                this.props.getFormDetails({
                  props: ["confirmpassword"],
                  value: e.target.value
                })
              }
            />
            {pinReveal !== 10 ? (
              <div onClick={() => this.props.handlePinReveal(10)}>
                <img className="eye" src="/Assets/eyeClosed.png" alt="" />
              </div>
            ) : (
              <div onClick={() => this.props.handlePinReveal(0)}>
                <img className="eye" src="/Assets/eyeOpen.png" alt="" />
              </div>
            )}
          </div>
          {regInputError ? (
            <p className="errorMsgs mt-3 text-center">
              {" "}
              <i className="material-icons">info</i> {regInputError}
            </p>
          ) : (
            ""
          )}

          <label> Referral Code? </label>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control loginCred"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={e =>
                this.props.getFormDetails({
                  props: ["referralCode"],
                  value: e.target.value
                })
              }
            />
          </div>

          <div className="loginBtn mb-4">
            <Button
              buttonAction={() => this.props.registerUser(data)}
              theme="clickLogin mb-2 mr-1 bold loginbutton"
            >
              {registeringUser ? <Loader /> : "Done"}
            </Button>
          </div>
        </div>

        <p className="text-center mb-2 t-15 bold t-blacl">
          {" "}
          Already have an account?
        </p>
        <div
          onClick={() =>
            this.props.getFormDetails({ props: ["googleLogin"], value: 0 })
          }
          className="loginBtn mb-3"
        >
          <button
            className="clickLogin mb-2 mr-1 t-14 bold googleBtn"
            ref="googleLoginBtn"
          >
            Log In
          </button>
        </div>
      </div>

      // <div className="col-lg-6 offset-lg-3">
      //   <div className="card card-small card-post card-post--1">
      //     <img className='logo' src="/Assets/wicrypt.svg" />
      //     <div className="card-body pt-0">
      //       <div className="row">
      //         <div className="col-lg-6 offset-lg-3 fade-in">
      //           <div className="t-center p-10 mt-5">
      //             <h5 className="card-title cardHeading mb-0">
      //                 {heading}
      //             </h5>
      //             <p className="wicryptmsg t-14 mb-0">
      //               {" "}
      //               Welcome, <span className="text-blue"> {newUserEmail}</span>
      //             </p>
      //             <p className="wicryptmsg t-14">
      //               {" "}
      //               Create 4 digit PIN to secure your account
      //             </p>
      //           </div>

      //           <div>
      //             {/* <label> Referral Code? </label>
      //             <div className="input-group mb-3">
      //               <input
      //                 type="email"
      //                 className="form-control loginCred"
      //                 aria-label="Username"
      //                 aria-describedby="basic-addon1"
      //                 onChange={(e) =>
      //                   this.props.getFormDetails({
      //                     props: ["referralCode"],
      //                     value: e.target.value,
      //                   })
      //                 }
      //               />
      //             </div> */}
      //             <label> Create new pin</label>
      //             <div className="input-group mb-3">
      //               <input
      //                 type={pinReveal == 0 ? "password" : "text"}
      //                 className="form-control loginCred"
      //                 aria-label="pin"
      //                 maxLength={4}
      //                 value={password}
      //                 aria-describedby="basic-addon1"
      //                 onChange={(e) =>
      //                   this.props.getFormDetails({
      //                     props: ["password"],
      //                     value: e.target.value,
      //                   })
      //                 }
      //               />
      //               {pinReveal !== 1 ? (
      //                 <div onClick={() => this.props.handlePinReveal(1)}>
      //                   <img className="eye" src="./Assets/eyeClosed.png" />
      //                 </div>
      //               ) : (
      //                   <div onClick={() => this.props.handlePinReveal(0)}>
      //                     <img className="eye" src="./Assets/eyeOpen.png" />
      //                   </div>
      //                 )}
      //             </div>
      //             <label> Confirm new pin</label>
      //             <div className="input-group mb-3">
      //               <input
      //                 type={pinReveal == 10 ? "password" : "text"}
      //                 className="form-control loginCred"
      //                 aria-label="Username"
      //                 value={confirmpassword}
      //                 maxLength={4}
      //                 aria-describedby="basic-addon1"
      //                 onChange={(e) =>
      //                   this.props.getFormDetails({
      //                     props: ["confirmpassword"],
      //                     value: e.target.value,
      //                   })
      //                 }
      //               />
      //               {pinReveal !== 10 ? (
      //                 <div onClick={() => this.props.handlePinReveal(10)}>
      //                   <img className="eye" src="./Assets/eyeClosed.png" />
      //                 </div>
      //               ) : (
      //                   <div onClick={() => this.props.handlePinReveal(0)}>
      //                     <img className="eye" src="./Assets/eyeOpen.png" />
      //                   </div>
      //                 )}
      //             </div>

      //             <div className="loginBtn mb-4">
      //               <Button
      //                 buttonAction={() => this.props.registerUser(data)}
      //                 theme="clickLogin mb-2 mr-1 bold loginbutton"
      //               >
      //                 {registeringUser ? <Loader /> : "Done"}
      //               </Button>
      //             </div>
      //           </div>
      //           {regInputError ? (
      //             <p className="errorMsgs mt-3 text-center">
      //               {" "}
      //               <i className="material-icons">info</i> {regInputError}
      //             </p>
      //           ) : (
      //               ""
      //             )}
      //           <p className="text-center mb-2 t-15 bold t-blacl">
      //             {" "}
      //         Already have an account?
      //       </p>
      //           <Link to="/" className="loginBtn mb-3">
      //             <button
      //               className="clickLogin mb-2 mr-1 t-14 bold googleBtn"
      //               ref="googleLoginBtn"
      //             >
      //               Log In
      //             </button>
      //           </Link>

      //         </div>
      //       </div>
      //     </div>
      //     <img className="sideWave" src="./Assets/Intersection 1.png" />
      //     <img className="leftsideWave" src="./Assets/Intersection 2.png" />
      //   </div>
      // </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    newUserEmail,
    googleLogin,
    newUserName,
    pinReveal,
    password,
    regInputError,
    referralCode,
    confirmpassword
  } = state.User;
  const { registeringUser } = state.Loading;
  return {
    newUserEmail,
    googleLogin,
    newUserName,
    pinReveal,
    password,
    registeringUser,
    regInputError,
    referralCode,
    confirmpassword
  };
};
export default connect(
  MapStateToProps,
  { registerUser, getFormDetails, handlePinReveal }
)(RegCard);
