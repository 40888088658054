import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { assignAgents, agentDevice, updateBillingType, getFormDetails} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import { CheckBox } from "../Reusables/inputs/CheckBox"

class UpdateBilling extends Component {
    render() {
        const {
            clickedDeviceId,
            updatingbilling,
            allBusiness,
            billingtype
        } = this.props;

        let business =
            allBusiness.length > 0
                ? allBusiness.find((business) => {
                    return business.id === clickedDeviceId;
                })
                : "";
        let type = business !== undefined ? business.billingType : "Normal";

        return (
            <Modal heading={`UPDATE BILLING`}>
                <CheckBox label="Normal" checked={billingtype === "" & type === "Normal" ? true : billingtype === "Normal" ? true : false} 
                    onClick={() =>  this.props.getFormDetails({
                      props: ["billingtype"],
                      value: "Normal",
                    })} type="radio" 
                />
                <CheckBox label="Timed" checked={billingtype === "" & type === "Timed" ? true : billingtype === "Timed" ? true : false}
                 onClick={() =>  this.props.getFormDetails({
                    props: ["billingtype"],
                    value: "Timed",
                  })} 
                 type="radio" />
                <CheckBox label="Subscription" checked={billingtype === "" & type === "Subscription" ? true : billingtype === "Subscription" ? true : false} 
                onClick={() =>  this.props.getFormDetails({
                    props: ["billingtype"],
                    value: "Subscription",
                  })} t
                type="radio" />
                <Button
                    theme="wicryptColor ml-3 mt-2 float"
                  buttonAction={() =>
                    this.props.updateBillingType(clickedDeviceId, billingtype)
                  }
                >
                    {updatingbilling ? <Loader /> : "Update"}
                </Button>
            </Modal>
        );
    }
}

const MapStateToProps = (state) => {
    const { clickedDeviceId, billingtype } = state.User;
    const { updatingbilling } = state.Loading;
    const { allBusiness } = state.Manager;
    return {
        clickedDeviceId,
        updatingbilling,
        allBusiness,
        billingtype
    };
};

export default connect(
    MapStateToProps,
    {
        assignAgents,
        agentDevice,
        updateBillingType,
        getFormDetails
    }
)(UpdateBilling);
