import React from "react";
import {ActionBtnContainer} from "./inputstyles"

export const ActionButton = (props) => {
  const { title, buttonAction, disabled, id, active, img} = props;
  return (
    <ActionBtnContainer
      id={id}
      disabled={disabled}
      onClick={buttonAction}
      type="button"
      active={active}
    >
      
      <img src={img} alt="transaction icon" />
      <span> {title} </span>
    </ActionBtnContainer>
  );
};

