import React from "react";
import Loader from "../Reusables/loaders/HashLoader";
import IconCard from "../Reusables/cards/IconCard";
import IconCards from "../Reusables/cards/IconCard";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetails } from "../../Redux/actions";
import {
  niceBytes,
  numberWithCommas,
  numberWithCommaswithoutdecimals,
} from "../../Helper";

const SmallStats = () => {
  const {
    gettingTotalDevices,
    totalOfAllDevices,
    gettingSessionsSummary,
    sessionSummary,
    gettingMiningStats,
    miningStatistics,
  } = useSelector((state) => state.Devices);
  const { selectedid } = useSelector((state) => state.User);
  const {
    gettingTotalUsers,
    totalUsers,
    gettingTotalAdmins,
    totalAdmins,
  } = useSelector((state) => state.Admin);
  const dispatch = useDispatch();

  return (
    <div className="row">
      <IconCard
        title={"Devices"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.total) || 0
          ) : (
            "0"
          )
        }
        id={1}
        link={"/devices"}
      />
      <IconCard
        title={"Users"}
        image={"/Assets/dashboardicons/Extra/SVG/users.svg"}
        value={
          gettingTotalUsers ? (
            <Loader />
          ) : (
            numberWithCommaswithoutdecimals(totalUsers.total) || "0"
          )
        }
        id={1}
        link={"/wicryptusers"}
      />
      {/* <IconCard
        title={"Total Revenue  (NGN)"}
        image={"/Assets/dashboardicons/Extra/SVG/revenue.svg"}
        value={false ? <Loader /> : "200"}
        id={1}
        link={"#"}
      /> */}
      <IconCard
        title={"Sessions"}
        image={"/Assets/dashboardicons/Extra/SVG/sessions.svg"}
        value={
          gettingSessionsSummary ? (
            <Loader />
          ) : sessionSummary ? (
            numberWithCommaswithoutdecimals(sessionSummary.sessionCount) || 0
          ) : (
            0
          )
        }
        id={1}
        link={"#"}
      />
      <IconCard
        title={"Data Sold"}
        image={"/Assets/dashboardicons/Extra/SVG/datasold.svg"}
        value={
          gettingSessionsSummary ? (
            <Loader />
          ) : sessionSummary ? (
            niceBytes(sessionSummary.dataSold)
          ) : (
            niceBytes(0)
          )
        }
        id={1}
        link={"#"}
        selectedid={selectedid}
        toggledescription={() =>
          dispatch(
            getFormDetails({
              props: ["selectedid"],
              value: selectedid === 1 ? 0 : 1,
            })
          )
        }
        description={
          "This is the total amount of data sold across your wicrypt platform"
        }
      />
      <IconCard
        selectedid={selectedid}
        title={"Data Used"}
        image={"/Assets/dashboardicons/Extra/SVG/dataused.svg"}
        description={
          "This is the total amount of data used across your wicrypt platform"
        }
        toggledescription={() =>
          dispatch(
            getFormDetails({
              props: ["selectedid"],
              value: selectedid === 2 ? 0 : 2,
            })
          )
        }
        value={
          gettingSessionsSummary ? (
            <Loader />
          ) : sessionSummary ? (
            niceBytes(sessionSummary.dataUsed)
          ) : (
            niceBytes(0)
          )
        }
        id={2}
        link={"#"}
      />
      <IconCard
        title={"Admins"}
        image={"/Assets/dashboardicons/Extra/SVG/admin.svg"}
        value={
          gettingTotalAdmins ? (
            <Loader />
          ) : (
            numberWithCommaswithoutdecimals(totalAdmins.total) || "0"
          )
        }
        id={1}
        link={"/admins"}
      />
      <IconCard
        title={"WNT Mined"}
        image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
        value={
          gettingMiningStats ? (
            <Loader />
          ) : miningStatistics ? (
            (numberWithCommas(miningStatistics.mined) || 0)
          ) : (
            "0"
          )
        }
        id={1}
        link={"#"}
      />
      <IconCard
        title={"Lynx Devices"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.lynx) || 0
          ) : (
            "0"
          )
        }
        id={1}
        link={"#"}
      />
      <IconCard
        title={"Spider Devices"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.spider) || 0
          ) : (
            "0"
          )
        }
        id={1}
        link={"#"}
      />
      <IconCards
        title={"Basic Lynx"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.basicLynx) || 0
          ) : (
            "0"
          )
        }
        id={4}
        link={"#"}
        bordered={true}
      />
      <IconCards
        title={"Basic Spider"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.basicSpider) || 0
          ) : (
            "0"
          )
        }
        id={5}
        link={"#"}
        bordered={true}
      />
      <IconCards
        title={"NFT Devices"}
        image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
        value={
          gettingTotalDevices ? (
            <Loader />
          ) : totalOfAllDevices ? (
            numberWithCommaswithoutdecimals(totalOfAllDevices.nft) || 0
          ) : (
            "0"
          )
        }
        id={1}
        link={"#"}
      />
    </div>
  );
};
export default SmallStats;
