import _const from "../actions/Types";

const initialState = {
    globalSpendControl: [],
    usersSpendControl: [],
    userSpendControl: [],
    globalSpendActivity: [],
    userSpendActivity: [],
    userSpendError: "",
    spendControlErr: ""
};

const SpendControlReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.GLOBAL_SPEND_CONTROL:
            return {
                ...state,
                globalSpendControl: action.payload
            };

        case _const.USERS_SPEND_CONTROL:
            return { ...state, usersSpendControl: action.payload }

        case _const.USER_SPEND_CONTROL:
            console.log("updated here")
            return { ...state, userSpendControl: action.payload }

        case _const.GLOBAL_SPEND_CONTROL_ACTIVITY:
            return { ...state, globalSpendActivity: action.payload }

        case _const.USER_SPEND_CONTROL_ACTIVITY:
            return { ...state, userSpendActivity: action.payload }

        case _const.USER_SPEND_CONTROL_ERROR:
            return{...state, userSpendError: action.payload}

        case _const.GLOBAL_SPEND_CONTROL_ERROR:
            return{...state, spendControlErr: action.payload}

        case _const.CLEAR_MODAL:
            return{...state, userSpendError: "", spendControlErr: "" }

        default:
            return state;
    }
};

export default SpendControlReducer;