import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import { useSelector } from "react-redux";
import StakingSummary from "./stakeSummary";
import UnstakeActivity from "./unstakeActivity";
import DistributedRewwardOverview from "./distributedReward";
import UpcomingUnstake from "./upcomingUnstake";
import Stake from "./stakeOverview";

const StakeDashboard = () => {
  let stakingTabs = [
    { id: 1, item: "Stake Summary" },
    { id: 2, item: "Stake Overview" },
    { id: 3, item: "Stake Activity" },
    { id: 4, item: "Upcoming Unstake" },
    { id: 5, item: "Distributed Reward" },
  ];

  const { toggleSubnav } = useSelector((state) => state.General);
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav pageTitle="Stake" listItems={stakingTabs} />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? (
            <StakingSummary />
          ) : toggleSubnav === 2 ? (
            <Stake userId={""} />
          ) : toggleSubnav === 3 ? (
            <UnstakeActivity />
          ) : toggleSubnav === 4 ? (
            <UpcomingUnstake />
          ) : (
            <DistributedRewwardOverview />
          )}
        </div>
      </MainContent>
    </div>
  );
};

export default StakeDashboard;
