import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getbusinessagents,
  getFormDetails,
  clearData,
  allBusinesses,
} from "../../Redux/actions";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { DataUsageHeaderOptions } from "./options";

class AgentDataUsage extends Component {
  componentWillMount() {
    if (this.props.allBusiness.length === 0) {
      this.props.allBusinesses(1, 1000, "");
    }
  }
  componentWillReceiveProps(nextProps) {
    const { selectedAffiliatedBusiness } = nextProps;
    if (selectedAffiliatedBusiness !== this.props.selectedAffiliatedBusiness) {
      this.props.clearData();
      this.props.getbusinessagents(selectedAffiliatedBusiness, 1, 50);
    }
  }

  customPagination() {
    const {
      selectedAffiliatedBusiness,
      businessAgentsCurrentPage,
      businessAgentsTotalPages,
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== businessAgentsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getbusinessagents(
              selectedAffiliatedBusiness,
              businessAgentsCurrentPage - 1,
              50
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; businessAgentsTotalPages >= i; i++) {
      if (i === businessAgentsCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getbusinessagents(selectedAffiliatedBusiness, i, 50)
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (businessAgentsTotalPages > businessAgentsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getbusinessagents(
              selectedAffiliatedBusiness,
              businessAgentsCurrentPage + 1,
              50
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      gettingBusinessAgents,
      // allBusiness,
      allBusinessAgents,
    } = this.props;

    let allRows = [];
    allBusinessAgents.map((agent, i) => {
      return allRows.push({
        agent: agent.user.name,
        device: agent.managedDevices.length,
        business: agent.user.rate,
        sessions: agent.voucherRemittanceAllowance,
        dataUsed: "",
        Amount: "",
      });
    });

    const data = {
      columns: [
        {
          label: "Business Name",
          field: "business",
          sort: "asc",
          width: 100,
        },
        {
          label: "Agent Name",
          field: "agent",
          sort: "asc",
          width: 100,
        },
        {
          label: "Device Name",
          field: "device",
          sort: "asc",
          width: 100,
        },
        {
          label: "Sessions",
          field: "sessions",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data Used",
          field: "dataUsed",
          sort: "asc",
          width: 100,
        },

        {
          label: "Amount",
          field: "amount",
          sort: "asc",
          width: 100,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="">
        {gettingBusinessAgents && (allBusinessAgents.length === 0) ? (
          <div className="">
            <CustomLoader />{" "}
          </div>
        ) : (
          <div className="mb-4">
            <CustomDataTable
              data={data}
              pagination={this.customPagination()}
              header={
                <TableHeader
                  subHeader={DataUsageHeaderOptions}
                  onClick={() => this.props.toggleModal(true)}
                  onChange={(e, props) => {
                    this.props.getFormDetails({
                      props: [props],
                      value: e.target.value,
                    });
                  }}
                  onSelect={(val, props) => {
                    this.props.getFormDetails({
                      props: [props],
                      value: val,
                    });
                  }}
                  // searchvalue={searchUser}
                />
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { gettingBusinessAgents } = state.Loading;
  const {
    allBusinessAgents,
    conversionsRate,
    businessAgentsTotalRecords,
    businessAgentsCurrentPage,
    businessAgentsTotalPages,
  } = state.Report;
  const { myBusinessId, selectedAffiliatedBusiness } = state.User;
  const {
    managerDevices,
    businessDevices,
    affiliatedBusiness,
    allBusiness,
  } = state.Manager;
  return {
    gettingBusinessAgents,
    allBusiness,
    allBusinessAgents,
    conversionsRate,
    managerDevices,
    myBusinessId,
    businessDevices,
    businessAgentsTotalRecords,
    businessAgentsCurrentPage,
    businessAgentsTotalPages,
    affiliatedBusiness,
    selectedAffiliatedBusiness,
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  getbusinessagents,
  clearData,
  allBusinesses,
})(AgentDataUsage);
