import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  allBusinessesForOperations,
  getAgentDataUssage,
  getFormDetails,
} from "../../Redux/actions";
import DualDatePicker from "../Reusables/cards/DualDatePicker";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { numberWithCommaswithoutdecimals } from "../../Helper";
import { useEffect } from "react";

const BusinessDataUsage = () => {
  const { gettingAgentDataUsage } = useSelector((state) => state.Loading);
  const { businessAgentDataUsage } = useSelector((state) => state.Report);
  const { allBusiness } = useSelector((state) => state.Manager);
  const {
    selectedAffiliatedBusiness,
    dataUsagedateStart,
    dataUsagedateEnd,
    searchBusinessAgent,
  } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  useEffect(() => {
    allBusinessesForOperations(1, 1000, "",dispatch);
    getAgentDataUssage(
      selectedAffiliatedBusiness,
      dataUsagedateStart,
      dataUsagedateEnd,
      searchBusinessAgent,
      dispatch
    );
  }, [
    selectedAffiliatedBusiness,
    dataUsagedateStart,
    dataUsagedateEnd,
    searchBusinessAgent,
  ]);

  let allRows = [];
  businessAgentDataUsage.map((business) => {
    return allRows.push({
      businessName: business.businessName,
      agentName: business.agentName,
      deviceName: business.deviceName,
      session: numberWithCommaswithoutdecimals(business.noOfSessions),
      dataUsageInGB: business.dataUsageInGB + "GB",
      // dataUsageInMB: business.dataUsageInMB + "MB",
      amount: "NGN" + numberWithCommaswithoutdecimals(business.amountInNaira),
    });
  });
  const data = {
    columns: [
      {
        label: "Business Name",
        field: "businessName",
        sort: "asc",
      },
      {
        label: "Agent Name",
        field: "agentName",
        sort: "asc",
      },
      {
        label: "Device Name",
        field: "deviceName",
        sort: "asc",
      },
      {
        label: "Session",
        field: "session",
        sort: "asc",
      },
      {
        label: "Data Usage in GB",
        field: "dataUsageInGB",
        sort: "asc",
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
      },
    ],

    rows: allRows,
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              loading={gettingAgentDataUsage}
              placeholder={"Search agent name"}
              searchfunction={(e) =>
                dispatch(
                  getFormDetails({
                    props: ["searchBusinessAgent"],
                    value: e.target.value,
                  })
                )
              }
              searchvalue={searchBusinessAgent}
              customright={
                <div className="d-flex">
                  <DualDatePicker
                    dateTo={dataUsagedateEnd}
                    dateFrom={dataUsagedateStart}
                    dateFromLabel="dataUsagedateStart"
                    dateToLabel="dataUsagedateEnd"
                  />
                  <select
                    className="selectbox"
                    style={{ width: "auto" }}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["selectedAffiliatedBusiness"],
                          value: e.target.value,
                        })
                      )
                    }
                  >
                    <option>Select Business</option>
                    {allBusiness.length &&
                      allBusiness.map((business) => {
                        return (
                          <option value={business.id}>{business.name}</option>
                        );
                      })}
                  </select>
                </div>
              }
              noData={
                <p className="voucherText t-center">
                  {" "}
                  No data usage report for the selected specs
                </p>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDataUsage;
