import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { deleteAgent, toggleModal} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";

class DeleteAgents extends Component {
  render() {
    const {
        deletingAgent,
        businessUserId,
        businessId,
        deleteAgentFail
    } = this.props;
    
    return (
      <Modal heading={`Delete device`}>
        <div className="t-center p-20 t-12 pb-0">
            <p> Are you sure you  want to delete this agent?</p>
            {deleteAgentFail ? <p className="errorMsgs"> {deleteAgentFail}</p> : ""}
        </div>

        <div className="d-flex">
        <Button
          theme=" ml-3 mt-2"
          buttonAction={() =>
            this.props.toggleModal(false, '', '')
          }
        >
          Close
        </Button>
        <Button 
          theme="wicryptColor ml-3 mt-2"
          buttonAction={() =>
            this.props.deleteAgent( businessUserId, businessId)
          }
        >
          {deletingAgent ? <Loader /> : "Continue"}
        </Button>
        </div>
       
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const {deletingAgent} = state.Loading;
  const {deleteAgentFail} = state.Agent

  return {
    deletingAgent,
    deleteAgentFail

  };
};

export default connect(
  MapStateToProps,
  {
    deleteAgent,
    toggleModal
  }
)(DeleteAgents);
