import React, {useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNodeProviderStatus} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  SubmitButton,
  SubmitButtonContainer,
} from "./style";

const CreateNodeProvider = () => {

  const dispatch = useDispatch();
  const [customData, setCustom] = useState({ 
    "providerName": "",
    "nodeUrl": "", 
    });
    const [errMsg, setError] = useState("");

  const { nodeProviderFail} = useSelector((state) => state.Admin);
  const { creatingNodeProviderStatus } = useSelector((state) => state.Loading);


  const handleSubmit = () =>{
      if(customData.providerName === ""){
        setError("Provider name is required")
      }else if(!customData.nodeUrl){
        setError("Please enter a valid URL")
      }else{
        setError("")
        dispatch(createNodeProviderStatus(customData));
      }
   
  }

  return (
    <div>
      <CustomModal
        id={57}
        heading={`Create Node Provider`}
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
            <div>

              <label> Provider Name</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.providerName}
                  onChange={(e) => setCustom({...customData, "providerName": e.target.value})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            <div>

              <label> Node URL</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.nodeUrl}
                  onChange={(e) => setCustom({...customData, "nodeUrl":  e.target.value})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            {errMsg && <p className="errorMsgs"> {errMsg}</p>}
            {nodeProviderFail && <p className="errorMsgs"> {nodeProviderFail}</p>}

            <SubmitButtonContainer>
              <SubmitButton
                onClick={() => handleSubmit()}
              >
                {creatingNodeProviderStatus ? <Loader /> : "Save"}
              </SubmitButton>
            </SubmitButtonContainer>
          </div>
        }
      />
    </div>
  );
};

export default CreateNodeProvider;
