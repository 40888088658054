import React, { Component } from "react";
import Button from "../buttons/Button";
import Loader from "../loaders/MoonLoader";
import { connect } from "react-redux";
import { getFormDetails, handlePinReveal } from "../../../Redux/actions";
import { withRouter } from "react-router-dom";
import RegCard from "./RegCard";

class LongCards extends Component {
  // componentDidMount() {

  //   this.googleSDK();
  // }

  // googleSDK() {
  //   window["googleSDKLoaded"] = () => {
  //     window["gapi"].load("auth2", () => {
  //       this.auth2 = window["gapi"].auth2.init({
  //         client_id:
  //           "379309600605-obandvmo0lblq2840n4k0e8f55ddo88f.apps.googleusercontent.com",
  //         cookiepolicy: "single_host_origin",
  //         scope: "profile email",
  //       });
  //       this.prepareLoginButton();
  //     });
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "google-jssdk");
  // }

  // prepareLoginButton = () => {
  //   this.auth2.attachClickHandler(
  //     this.refs.googleLoginBtn,
  //     {},
  //     (googleUser) => {
  //       let profile = googleUser.getBasicProfile();
  //       this.props.getFormDetails({
  //         props: ["newUserName"],
  //         value: profile.getName(),
  //       });
  //       this.props.getFormDetails({
  //         props: ["newUserEmail"],
  //         value: profile.getEmail(),
  //       });
  //       this.props.getFormDetails({ props: ["googleLogin"], value: 1 });
  //     },
  //     (error) => {
  //       alert(JSON.stringify(error, undefined, 2));
  //     }
  //   );
  // };

  responseGoogle = (response) => {
    if (response.profileObj !== undefined) {
      let profile = response.profileObj;
      this.props.getFormDetails({
        props: ["newUserName"],
        value: profile.name,
      });
      this.props.getFormDetails({
        props: ["newUserEmail"],
        value: profile.email,
      });
      this.props.getFormDetails({ props: ["googleLogin"], value: 1 });
    }
  };

  render() {
    const {
      heading,
      logIn,
      loggingIn,
      err,
      error,
      notEmail,
      pinReveal,
      googleLogin,
    } = this.props;

    return (
      <div className=" col-lg-6 offset-lg-3">
        <div className="card card-small card-post card-post--1">
          <img className="logo" src="/Assets/wicrypt.svg" alt="logo" />
          <div className="card-body pt-0">
            <div className="row w3-animate-bottom">
              {googleLogin === 1 ? (
                <RegCard />
              ) : (
                <div className="col-lg-6 offset-lg-3 fade-in">
                  <div className="t-center p-10 mt-5">
                    <h5 className="card-title mb-0 cardHeading">{heading}</h5>
                    <p className="mt-5"></p>
                  </div>
                  <label> Email address</label>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      className="form-control loginCred"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["loginEmail"],
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  {notEmail ? (
                    <p className="errorMsgs">
                      <i className="material-icons">info</i>The email address is
                      invalid{" "}
                    </p>
                  ) : (
                    ""
                  )}
                  <label> Enter PIN</label>
                  <div
                    className="input-group"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type={pinReveal === 0 ? "password" : "text"}
                      className="form-control loginCred"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["password"],
                          value: e.target.value,
                        })
                      }
                    />
                    {pinReveal === 0 ? (
                      <span onClick={() => this.props.handlePinReveal(1)}>
                        <img
                          className="eye"
                          src="/Assets/eyeClosed.png"
                          alt=""
                        />
                      </span>
                    ) : (
                      <span onClick={() => this.props.handlePinReveal(0)}>
                        <img className="eye" src="/Assets/eyeOpen.png" alt="" />
                      </span>
                    )}
                  </div>

                  {error ? (
                    <p className="errorMsgs">
                      {" "}
                      <i className="material-icons">info</i> The email address
                      and pin are required
                    </p>
                  ) : err ? (
                    <p className="errorMsgs">
                      {" "}
                      <i className="material-icons">info</i> {err}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <div className="myforgot">
                    <Link to="/forgot" className="forgot">
                      Forgot Your PIN?{" "}
                      <span className="reset bold"> Reset</span>
                    </Link>
                  </div> */}

                  <div className="loginBtn mb-4 mt-4">
                    <Button
                      buttonAction={logIn}
                      theme="clickLogin t-14 mb-2 mr-1 bold loginbutton"
                    >
                      {loggingIn ? <Loader /> : heading}
                    </Button>
                  </div>
                  {/* <p className='text-center mb-2 bold t-blacl'> Don't have an account yet?</p>
                  <div className="loginBtn mb-3">
                    <GoogleLogin
                      clientId="379309600605-obandvmo0lblq2840n4k0e8f55ddo88f.apps.googleusercontent.com"
                      render={renderProps => (
                        <button
                          className="clickLogin mb-2 mr-1 t-14 bold googleBtn"
                          onClick={renderProps.onClick}
                        >
                          <img 
                            className="mr-3 signupwithgoogle"
                            src="./Assets/search.svg"
                            alt=""
                          />{" "}
                        Sign Up with Google
                        </button>
                        // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                   
                  </div> */}
                </div>
              )}
            </div>
          </div>
          <img
            className="sideWave"
            src="./Assets/Intersection1.svg"
            alt="intersection1"
          />
          <img
            className="leftsideWave"
            src="./Assets/Intersection2.svg"
            alt="Intersection2"
          />
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { googleLogin } = state.User;
  return {
    googleLogin,
  };
};
export default withRouter(
  connect(MapStateToProps, { getFormDetails, handlePinReveal })(LongCards)
);
