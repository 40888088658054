import React from "react";
import classnames from "classnames";

const Button = (props) => {
  const { children, theme, buttonAction, disabled, id } = props;
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={buttonAction}
      type="button"
      className={classnames("mb-2 mr-1 customBtn", [theme])}
    >
      {children}
    </button>
  );
};

export default Button;
