import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getLockupRequestsUserId } from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import { getRewardBoosterRows } from "../../../utils/TableRows/rewardBoosterRow";
import { lockupUserColumns } from "../../../utils/TableCols/RewardBoosterCols";

const LockupUserPage = ({userId}) => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { lockupRequestsUserId } = useSelector((state) => state.RewardBooster);
  const { gettingLockupRequestsUserId} = useSelector((state) => state.Loading);
  const { page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getRewardBoosterRows( lockupRequestsUserId && lockupRequestsUserId.records ? lockupRequestsUserId.records.slice().reverse() : [], 
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );

  const data = {
    columns: lockupUserColumns,
    rows: allRows,
  };
  
  useEffect(() => {
    dispatch(getLockupRequestsUserId( userId, page, pageSize ));
  }, [userId, page, pageSize ]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"Reward Booster"}
              pageCount={lockupRequestsUserId.totalPages}
              currentPage={lockupRequestsUserId.currentPage}
              title={"Lockup Requests For Cycle"}
              loading={gettingLockupRequestsUserId}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry, you have no lockup requests history"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockupUserPage;
