import React from 'react'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Remit from './Remit'
import Mainnav from '../Partials/Mainnav'



const index = () =>{
    return (
        <div className="row">
        <Sidenav/>
            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav/>
                </div>  
                <div className="main-content-container container-fluid px-4">
                <BreadCrumbs/>
                <Remit/>
                </div>
            </main>
            
       </div>
    )
}

export default index