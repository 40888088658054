
import React from "react";
import moment from "moment";
import Loader from "../../Components/Reusables/loaders/MoonLoader";
import { handleSubNav } from "../../Redux/actions";
import { Link } from "react-router-dom";

export const getFraudListRow = (
  data,
  removeFromList, 
  removingFromList,  
  fraudListId, 
  setFrausListId,
  dispatch
) => {
  let allRows = data
    ? data.map((list) => {
        return {
          date: moment(list.dateAdded).format("DD-MM-YYYY, h:mm a"),
          prohibitedOperations: <div> {list.prohibitedOperations.map(each => <> {each},</>)}</div>,
          fraudster: <Link onClick={()=> {dispatch(handleSubNav(8)); localStorage.setItem("backBtn", "/transcOverview");}} className="text-blue" to={`/user/${list.userId}`}> <p className="mb-0"> {list.fraudster.name}</p><p> {list.fraudster.email}</p></Link>,
          reason: list.suspiciousReason,
          status: <div>{list.status === "Released"? <p className="CONFIRMED"> {list.status }</p> : 
          list.status === "Suspended"? <p className="FAILED"> {list.status }</p> : <p className="PENDING"> {list.status}</p>} </div>,
          remove:  list.status === "Suspended" ?  <div className="details t-center"
          onClick={() => {
            dispatch(removeFromList(list.id));
            setFrausListId(list.id)
          }}
      >
         {removingFromList && fraudListId === list.id ? <Loader /> : "Remove from list"}
      </div>  : null
        }
      })
    : [];
  return allRows;
};
