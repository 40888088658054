import _const from "../actions/Types";

const initialState = {
  deviceCount: "",
  promoBalance: "",
  balance: "",
  editInfo: false,
  action: 1,
  devices: [],
  activeDevices: "",
  managerDevices: [],
  tokenId: "",
  allTokens: [],
  tokenBeneficiaries: [],
  clickedToken: "",
  tokenPage: false,
  userId: "",
  deleted: false,
  tokenSet: false,
  currencyRates: [],
  tokenError: "",
  allBanks: [],
  withdrawalError: "",
  tokenRate: "",
  accountName: "",
  accountNameError: "",
  otpSent: false,
  allBusiness: [],
  allAgentBusiness: [],
  allRevenue: [],
  allSubBusiness: [],
  affiliatedBusiness: [],
  agentBizSummary: [],
  businessDevices: [],
  trackerError: "",
  deviceTrackerInfo: [],
  allBusinessTotalRecords: 0,
  allBusinessCurrentPage: 1,
  allBusinessTotalPages: 1,
  deviceTrackerTotalRecords: 0,
  deviceTrackerCurrentPage: 1,
  deviceTrackerTotalPages: 1,
  allCryptoWithdrawals: [],
  allCryptoTotalRecords: 0,
  allCryptoCurrentPage: 1,
  allCryptoTotalPages: 1,
  approvedeleteid: "",
  approve: false,
  hasMoreSub: false,
  subPage: 1,
  allSubBusinessTotal: 1,
  agentname: "",
  agentdetail: {},
};

const ManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.FIELD_ACTION:
      return {
        ...state,
        approvedeleteid: action.payload,
        approve: action.approve,
      };

    case _const.GET_MANAGER_INFO:
      return {
        ...state,
        deviceCount: action.payload.deviceCount,
        activeDevices: action.payload.activeDeviceCount,
      };

    case _const.EDIT_USERINFO:
      return {
        ...state,
        editInfo: action.payload,
        action: action.fund,
        otpSent: false,
        accountName: "",
        accountNameError: "",
      };

    case _const.GET_DEVICES:
      return { ...state, devices: action.payload };

    case _const.GET_DEVICE_DETAILS:
      return { ...state, managerDevices: action.payload };

    case _const.GET_TOKENS:
      return { ...state, allTokens: action.payload };

    case _const.TOKENATED_USERS:
      return { ...state, tokenBeneficiaries: action.payload };

    case _const.TOKEN_PAGE:
      return { ...state, tokenPage: action.payload, clickedToken: action.id };

    case _const.CLICKED_TOKEN_ID:
      return { ...state, userId: action.payload };

    case _const.DELETED:
      return { ...state, deleted: action.payload };

    case _const.TOKEN_SET:
      return { ...state, tokenSet: action.payload };

    case _const.GET_CURRENCY_RATES:
      return { ...state, currencyRates: action.payload };

    case _const.SET_TOKEN_ERROR:
      return { ...state, tokenError: action.payload };

    case _const.ALL_BANKS:
      return { ...state, allBanks: action.payload };

    case _const.WITHDRAWAL_ERROR:
      return { ...state, withdrawalError: action.payload };

    case _const.TOKEN_RATE:
      return { ...state, tokenRate: action.payload };

    case _const.ACCOUNT_NAME:
      return { ...state, accountName: action.payload };

    case _const.ACC_NAME_ERR:
      return { ...state, accountNameError: action.payload };

    case _const.OTP_SENT:
      return { ...state, otpSent: action.payload };

    case _const.CLEAR_FORMS:
      return {
        ...state,
        accountNameError: "",
        otpSent: false,
        accountName: "",
      };

    case _const.ALL_BUSINESSES:
      return {
        ...state,
        allBusiness: action.payload,
        allBusinessTotalRecords: action.totalrecords,
        allBusinessCurrentPage: action.currentPage,
        allBusinessTotalPages: action.totalPages,
      };
    case _const.ALL_Agent_BUSINESSES:
      return {
        ...state,
        allAgentBusiness: action.payload,
      };

    case _const.ALL_CRYPTO_WITHDRAWALS:
      return {
        ...state,
        allCryptoWithdrawals: action.payload.data,
        allCryptoTotalRecords: action.payload.meta.total,
        allCryptoCurrentPage: action.payload.meta.page,
        allCryptoTotalPages: action.payload.meta.numberOfPages,
      };

    case _const.APPROVING_DELETE_SUCCESS:
      return { ...state, allCryptoWithdrawals: [] };

    case _const.TOTAL_REVENUE:
      return { ...state, allRevenue: action.payload };

    case _const.ALL_SUB_BUSINESS:
      return {
        ...state,
        allSubBusiness: action.payload.data,
        hasMoreSub: action.payload.meta.hasNext,
        subPage: action.payload.meta.page,
        allSubBusinessTotal: action.payload.meta.numberOfPages,
      };

    case _const.CLEAR_SUB_BUSINESS:
      return { ...state, allSubBusiness: [], hasMoreSub: false, subPage: 1 };

    case _const.AFFILIATED_BUSINESSES:
      return { ...state, affiliatedBusiness: action.payload };

    case _const.AGENT_BIZ_SUMMARY:
      return { ...state, agentBizSummary: action.payload };

    case _const.GETTING_DEVICES_BY_BUSINESS:
      return { ...state, businessDevices: action.payload };

    case _const.ERROR_WHILE_SETTING_UP_TRACKER:
      return { ...state, trackerError: action.payload };

    case _const.DEVICE_TRACKER_INFO:
      return {
        ...state,
        deviceTrackerInfo: action.payload,
        deviceTrackerTotalRecords: action.totalrecords,
        deviceTrackerCurrentPage: action.currentPage,
        deviceTrackerTotalPages: action.totalPages,
      };

    case _const.BUSINESS_AGENT_DETAILS:
      return {
        ...state,
        agentdetail: action.payload,
        agentname: action.payload.name,
      };

    case _const.CLEAR_DATA:
      return { ...state, deviceTrackerInfo: [] };

    default:
      return state;
  }
};

export default ManagerReducer;
