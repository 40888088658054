import React, { useState } from "react";
import CustomModal from "../../Reusables/modals/CustomModal";
import {
  AssignSubAdminContainer,
  Avatar,
  LeftOptionContent,
  Name,
  Option,
  SearchContainer,
  SearchInput,
  SelectContainer,
  SelectedSubAdmin,
  SubmitButton,
  TotalOwnedDevices,
  SubmitButtonContainer,
  TotalSubAdmin,
} from "./styles";

const subAdmins = [
  {
    id: 1,
    name: "Jerome Bell",
    totalDevices: "200",
    deviceType: "jump start devices",
  },
  {
    id: 2,
    name: "Jerome Bell",
    totalDevices: "200",
    deviceType: "jump start devices",
  },
  {
    id: 3,
    name: "Jerome Bell",
    totalDevices: "200",
    deviceType: "jump start devices",
  },
];

function AssignSubAdmin() {
  const [selectedId, setSelectedId] = useState(0);
  return (
    <div>
      <CustomModal
        id={14}
        heading="Assign Sub-Admin"
        close={true}
        content={
          <>
            <AssignSubAdminContainer>
              <SearchContainer>
                <img src="/Assets/dashboardicons/Extra/SVG/search.svg" alt="" />
                <SearchInput type="text" placeholder="Search sub admins" />
              </SearchContainer>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <TotalSubAdmin>300 sub-admins</TotalSubAdmin>
                <SelectedSubAdmin>james selected</SelectedSubAdmin>
              </div>
              <SelectContainer>
                {subAdmins.map((item, index) => (
                  <Option key={item + index}>
                    <LeftOptionContent>
                      <Avatar
                        alt="user avatar"
                        src="/Assets/images/avatars/user_icon.svg"
                      />
                      <div>
                        <Name>Hillary</Name>
                        <TotalOwnedDevices>
                          200 <span>jump start devices</span>
                        </TotalOwnedDevices>
                      </div>
                    </LeftOptionContent>
                    {selectedId === item.id ? (
                      <img
                        src="/Assets/adminicons/Active/activeselect.svg"
                        alt=""
                        onClick={() => setSelectedId(0)}
                      />
                    ) : (
                      <img
                        src="/Assets/adminicons/Inactive/Inactiveselect.svg"
                        alt=""
                        onClick={() => setSelectedId(item.id)}
                      />
                    )}
                  </Option>
                ))}
              </SelectContainer>
            </AssignSubAdminContainer>
            <SubmitButtonContainer>
              <SubmitButton style={{ opacity: selectedId ? "1" : "0.5" }}>Assign</SubmitButton>
            </SubmitButtonContainer>
          </>
        }
      />
    </div>
  );
}

export default AssignSubAdmin;
