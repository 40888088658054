import styled from "styled-components";
import { theme } from "../../theme";

export const TopicContent = styled.p`
  color: ${theme.grey};
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

export const TopicSubContent = styled.p`
  color: ${theme.grey};
  font-weight: ${theme.textXBold};
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;