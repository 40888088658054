import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getBoosterCyclesId, handleSubNav } from "../../../Redux/actions";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "../../Devices/styles";
import { formatString, numberWithCommas } from "../../../Helper";

const CyclesDetailsPage = ({history}) => {

  const { boosterCyclesId } = useSelector((state) => state.RewardBooster);
  const { gettingBoosterCyclesId} = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [cycleId, setCycleId] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  // const cycleId = window.location.pathname.split("/").at(-1);
  useEffect(() => {
    const newCycleId = window.location.pathname.split("/").at(-1);
    setCycleId(newCycleId);
  }, []); 
  
  useEffect(()=>{
    if (cycleId){
      dispatch(getBoosterCyclesId(cycleId))
    }
  }, [cycleId, dispatch])

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Booster Cycle
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingBoosterCyclesId ?
          <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
             {boosterCyclesId && 
             <div className="card-body p-6 ">
                <div className="row mb-2">
                  { boosterCyclesId.userInfo &&
                    <div className="col-lg-6 col-md-6">
                    <PersonalBox>
                      <UserDetailsAvatar
                        className="rounded-circle"
                        src="/Assets/images/avatars/user_icon.svg"
                        alt="User Avatar"
                      />
                      <PersonalInfo>
                        <span>{boosterCyclesId.userInfo && boosterCyclesId.userInfo.name ? boosterCyclesId.userInfo.name : "N/A"}</span>
                        <span>{boosterCyclesId.userInfo && boosterCyclesId.userInfo.email ? boosterCyclesId.userInfo.email : "N/A"}</span>
                      </PersonalInfo>
                      {boosterCyclesId.userInfo && boosterCyclesId.userInfo.id ? 
                        <Link to={`/user/${boosterCyclesId.userInfo.id}`} 
                        onClick={() => {dispatch(handleSubNav(1));}}
                        >
                          <div className="details min-width"> Details </div></Link> : <div className="details min-width"> Not Available </div>}
                    </PersonalBox>
                  </div>
                  }
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Start Date</StakeStatData>
                      {boosterCyclesId.lockUpStartDate ? (
                        <StakeStatValue>
                          {moment(boosterCyclesId.lockUpStartDate).format("DD-MM-YYYY")} |{" "}
                          {moment(boosterCyclesId.lockUpStartDate).format("LT")}
                        </StakeStatValue>
                      ) : <StakeStatValue>N/A</StakeStatValue>}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>End Date</StakeStatData>
                      {boosterCyclesId.lockUpEndDate ? (
                        <StakeStatValue>
                          {moment(boosterCyclesId.lockUpEndDate).format("DD-MM-YYYY")} |{" "}
                          {moment(boosterCyclesId.lockUpEndDate).format("LT")}
                        </StakeStatValue>
                      ) : <StakeStatValue>N/A</StakeStatValue>}
                    </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                   
                    <div className="col-md-3">
                      <StakeStatData>Booster Status</StakeStatData>
                     {boosterCyclesId.boosterStatus ? <StakeStatValue className={` ${boosterCyclesId.boosterStatus === 'LockReward' ? 'text-red' : boosterCyclesId.boosterStatus === 'RemitRewardToWallet' ? 'text-orange' : boosterCyclesId.boosterStatus === 'RewardReleased' ? 'text-green' : ''}`}>
                      {boosterCyclesId.boosterStatus ? formatString(boosterCyclesId.boosterStatus) : "N/A"}</StakeStatValue> : <StakeStatValue>N/A</StakeStatValue>}
                    </div>
                    <div className="col-md-3">
                      <StakeStatData>Lock Period</StakeStatData>
                     <StakeStatValue>{boosterCyclesId.lockPeriod ? formatString(boosterCyclesId.lockPeriod) : "N/A"}</StakeStatValue>
                    </div>
                    <div className="col-md-3">
                      <StakeStatData>Interest Accrued</StakeStatData>
                      {boosterCyclesId.interestAccrued && boosterCyclesId.currency ? <StakeStatValue>{numberWithCommas(boosterCyclesId.interestAccrued, 2)} {boosterCyclesId.currency}</StakeStatValue>
                       : <StakeStatValue>0</StakeStatValue>}
                    </div>
                    <div className="col-md-3">
                      <StakeStatData>Total Amount Locked</StakeStatData>
                      {boosterCyclesId.totalAmountLocked && boosterCyclesId.currency ? <StakeStatValue>{numberWithCommas(boosterCyclesId.totalAmountLocked, 2)} {boosterCyclesId.currency}</StakeStatValue> 
                      : <StakeStatValue>0</StakeStatValue>}
                    </div>
                  
                </div>
               
              </div>}
            </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default CyclesDetailsPage;
