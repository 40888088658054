import React from 'react';
import { Icon, ArrowCard, CardTitle, CardValue, ValueFlex, EyeIcon, IconContainer, DescriptionCard, CardDescription} from "./cards"

const DoubleIconCard = (props) => {
  const { title, value, link, description, toggledescription, id, selectedid, viewbalance, togglebalance, balanceview} = props

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 mb-4 w3-animate-top">
        <ArrowCard>
          <ValueFlex>
            <ValueFlex>
              <IconContainer>
                <EyeIcon>
                    {viewbalance === balanceview ? 
                    <Icon onClick={togglebalance} color={ selectedid === id ? 'yellow' : "grey" }className="material-icons">visibility</Icon> : 
                    <Icon onClick={togglebalance} color={ selectedid === id ? 'yellow' : "grey" }className="material-icons">visibility_off</Icon>}
                </EyeIcon>
              </IconContainer>
              <div>
                <CardTitle> {title}</CardTitle>
                <CardValue> {viewbalance  === balanceview ? value : '****'}</CardValue>   
              </div>
            </ValueFlex>
            {link === '' ? '' : <Icon onClick={toggledescription} color={ selectedid === id ? 'yellow' : "grey" }className="material-icons">help_outline</Icon>}
            {selectedid === id ? 
            <DescriptionCard>
              <CardDescription> {description}</CardDescription>
            </DescriptionCard> : ""}
          </ValueFlex>
        </ArrowCard>
    </div>
  )
}

export default DoubleIconCard