import React, { Component } from "react";
import { connect } from "react-redux";
import {
  goBack,
  approveVerification,
  declineVerification,
  toggleModal,
  countryVerificationTypes,
  getallVerificationRequests,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import {
  DataTitle,
  DataSubTitle,
  DataKey,
  DataValue,
  DataContainer,
} from "../AcctVerification/verificationstyle";
import RejectVerification from "./RejectVerification";
import ApproveVerification from "./ApproveVerification";
import VerifyIdentification from "./VerifyIdentification";
import { withRouter } from "react-router-dom";
import moment from "moment";

class PersonalVerification extends Component {
  componentDidMount() {
    const { oneRequest } = this.props;
    if (oneRequest.id === undefined) {
      this.props.history.push("/wicryptusers");
    } else {
      this.props.countryVerificationTypes(
        oneRequest.countryCode === undefined ? "NG" : oneRequest.countryCode
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { onePage } = this.props;
    if (onePage !== nextProps.onePage) {
      this.props.history.push("/wicryptusers");
    }
  }

  render() {
    const { oneRequest, approvingVerification, funct } = this.props;

    return (
      <div className="col-lg-12">
        {funct === "reject" ? (
          <RejectVerification />
        ) : funct === "approve" ? (
          <ApproveVerification />
        ) : funct === "verify" ? (
          <VerifyIdentification verificationId={oneRequest.id} />
        ) : (
          ""
        )}

        <div className="card card-small mb-4 pl-4">
          <ul className="list-group list-group-flush">
            <li className="list-group-item p-0 px-3 pt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-small card-post mb-4">
                    <div
                      className="card-body"
                      style={{
                        border: "1px solid #C4C4C4",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{ alignItems: "center", marginBottom: "20px" }}
                      >
                        <DataTitle>ID Card</DataTitle>
                        <div className="d-flex">
                          {approvingVerification ? (
                            <button
                              type="button"
                              className=" bg-success btn-block rounded text-white text-center p-3"
                            >
                              <Loader size={15} color={"#fff"} />
                            </button>
                          ) : (
                            // <button
                            //   onClick={() =>
                            //     this.props.toggleModal(true, "", "verify")
                            //   }
                            //   className="btn button verify"
                            // >
                            //   Verify ID Info
                            // </button>
                            ""
                          )}
                        </div>
                      </div>
                      <img
                        style={{ width: "100%", height: "20rem" }}
                        className="img img-responsive"
                        src={oneRequest.identityCardUrl}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-small card-post mb-4">
                    <div
                      className="card-body"
                      style={{
                        border: "1px solid #C4C4C4",
                        borderRadius: "8px",
                      }}
                    >
                      <DataTitle>Passport</DataTitle>
                      <img
                        style={{ width: "100%", height: "20rem" }}
                        className="img img-responsive"
                        src={oneRequest.selfieImageUrl}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="list-group-item p-3">
              <div className="card-post mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <div className="card-body">
                          <DataSubTitle>User Details</DataSubTitle>
                          <DataContainer>
                            <DataKey>Username:</DataKey>
                            <DataValue>{oneRequest.name || "N/A"}</DataValue>
                          </DataContainer>
                          <DataContainer>
                            <DataKey>Email:</DataKey>
                            <DataValue>{oneRequest.email || "N/A"}</DataValue>
                          </DataContainer>
                          <DataContainer>
                            <DataKey>Registration Date:</DataKey>
                            <DataValue>
                              {moment(oneRequest.requestSubmissionDate).format(
                                "DD-MM-YYYY"
                              ) || "N/A"}
                            </DataValue>
                          </DataContainer>
                          <DataContainer>
                            <DataKey>Phone Number:</DataKey>
                            <DataValue>
                              {oneRequest.phoneNumber || "N/A"}
                            </DataValue>
                          </DataContainer>
                          <DataContainer>
                            <DataKey>Country:</DataKey>
                            <DataValue>{oneRequest.country || "N/A"}</DataValue>
                          </DataContainer>
                        </div>
                      </div>
                    </div>
                    {oneRequest.verificationStatus === "Pending" && (
                      <li className="list-group-item p-3 col-md-6">
                        <div
                          className="d-flex row"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <button
                              onClick={() =>
                                this.props.toggleModal(true, "", "approve")
                              }
                              className="btn button accept"
                            >
                              Approve
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn button reject"
                              onClick={() =>
                                this.props.toggleModal(true, "", "reject")
                              }
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </li>
                    )}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div id="myModal" className="modal">
          <span className="close" onClick={() => this.closeImageModal()}>
            &times;
          </span>
          <img
            className="modal-content"
            id="img01"
            style={{ height: "40rem", width: "50rem" }}
            alt=""
          />
          <div id="caption"></div>
        </div>
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const { oneRequest, onePage } = state.Verification;
  const { approvingVerification, decliningVerification } = state.Loading;
  const { verificationRejectionReason, funct } = state.User;
  return {
    oneRequest,
    approvingVerification,
    decliningVerification,
    verificationRejectionReason,
    funct,
    onePage,
  };
};
export default withRouter(
  connect(MapStateToProps, {
    goBack,
    approveVerification,
    declineVerification,
    toggleModal,
    countryVerificationTypes,
    getallVerificationRequests,
  })(PersonalVerification));
