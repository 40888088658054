import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { getUnremittedTokens, handleModal, initiateRemittanceTokens } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import Loader from "../Reusables/loaders/MoonLoader";
import moment from "moment";
import { formathash } from "../../Helper";
import RemitGasModal from "./modals/RemitGasModal";
import RemitTokenModal from "./modals/RemitTokenModal";

const TokenRemittance = () => {
    const dispatch = useDispatch();

    const { unremittedTokens, unremittedTotalPages, unremittedCurrentPages, setActiveError } = useSelector((state) => state.Admin);
    const { gettingUnremittedRemittance, initiatingRemittance } = useSelector((state) => state.Loading);
    const { Page, PageSize } = useSelector((state) => state.Search);
    const [savedId, setId] = useState("");
    const [operation, setOperation] = useState("");
    const [eachWalletAddress, setEachWalletAddress] = useState("");


    let allRows = [];
    unremittedTokens.map((token, id) => {
        return allRows.push({
            dateScanned: moment(token.dateScanned).format("DD-MM-YYYY"),
            walletAddress: formathash(token.walletAddress),
            tokenBalance: token.tokenBalance,
            gasBalance: token.gasBalance,
            tokenRemittedStatus: token.tokenBalance ? token.isTokenRemittanceInitiated? <div className="SUCCESS">  Remitted</div> :
            <div className="details" onClick={() => {dispatch(handleModal(86)); setId(id); setEachWalletAddress(token.walletAddress)}}>
                { id === savedId && operation === "RemitToken" ? "Remit Tokens" : "Remit Tokens"}
            </div> : "Nothing to Remit",
            gasRemittanceStatus: token.gasBalance ? token.isGasRemittanceInitiated ? <div className="SUCCESS">  Remitted</div> :
                <div className="details" onClick={() => {dispatch(handleModal(85)); setId(id); setEachWalletAddress(token.walletAddress)}}>
                    { id === savedId && operation === "RemitGas"  ? "Remit Gas" : "Remit Gas"}
                </div> : "Nothing to Remit",

        });
    });

    const data = {
        columns: [
            {
                label: "Date",
                field: "dateScanned",
                sort: "asc",
                width: 170,
            },
            {
                label: "Wallet Address",
                field: "walletAddress",
                sort: "asc",
                width: 170,
            },
            {
                label: "Token Balance",
                field: "tokenBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "Gas Balance",
                field: "gasBalance",
                sort: "asc",
                width: 170,
            },
            {
                label: "Token Remitted Status",
                field: "tokenRemittedStatus",
                sort: "asc",
                width: 170,
            },
            {
                label: "Gas Remittance Status",
                field: "gasRemittanceStatus",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    useEffect(() => {
        dispatch(getUnremittedTokens(Page, PageSize));
    }, [Page, PageSize]);

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <CustomDataTable
                                data={data}
                                tableId={"token-remittance"}
                                pageCount={unremittedTotalPages}
                                currentPage={unremittedCurrentPages}
                                loading={gettingUnremittedRemittance}
                                noData={
                                    <EmptyCard
                                        img="/Assets/emptytransactions.svg"
                                        title={"You have no pending tokens to remit"}
                                    // descr={"No transaction history available at the moment"}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <RemitGasModal
                onSubmit={() => { 
                    dispatch(initiateRemittanceTokens({
                        "walletAddress": eachWalletAddress,
                        "operationType": "RemitGas"
                    }));
                    setId(savedId);
                    setOperation("RemitGas");
                 }} 
                setActiveError={setActiveError}
                updatingProviderStatus={initiatingRemittance}
                walletAddress={formathash(eachWalletAddress)}
            />
            <RemitTokenModal
                onSubmit={() => { 
                    dispatch(initiateRemittanceTokens({
                        "walletAddress": eachWalletAddress,
                        "operationType": "RemitToken"
                    }));
                    setId(savedId);
                    setOperation("RemitToken");
                 }} 
                setActiveError={setActiveError}
                updatingProviderStatus={initiatingRemittance}
                walletAddress={formathash(eachWalletAddress)}
            />
        </div>
    );
};

export default TokenRemittance;
