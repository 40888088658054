import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleModal, getFormDetails } from "../../Redux/actions";
import Loader from "../Reusables/loaders/HashLoader";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import { CardTitle, SubTitle, Value, AgentCardBtn, BlueValue, SectionWidth, SectionHalfWidth, Icon, SectionValue} from './agentstyles'
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import filesize from 'filesize';
import AssignDevices from "../AgentReg/AssignDevices";
import DeleteDevice from './DeleteDevice';
import DeleteAgent from './DeleteAgent';
import AgentDeleteSuccsess from './AgentDeleteSuccsess';
import { numberWithCommas } from "../../Helper";


class AgentPage extends Component {


  render() {
    const {
      gettingOneBusinessAgent,
      agentdetail,
      funct,
      businessuserId,
      myBusinessId,
      currencyValue,
      deleteAgentSuccess,
    } = this.props;
    let manageddevice = agentdetail.managedDeviceInformation !== undefined ? agentdetail.managedDeviceInformation : [];
    let allRows = [];
    manageddevice.map((device) => {
      return allRows.push({
        name: device.deviceName,
        datasold: filesize(device.dataSoldInBytes),
        billingrate: device.rate,
        amount:  `NGN ${numberWithCommas(device.amountGenerated * currencyValue)}`,
        delete: <Icon className="material-icons t-17" onClick={() => this.props.toggleModal(true, device, "delete")} color='red'>delete</Icon>
      });
    });

    const data = {
      columns: [
        {
          label: "Device name",
          field: "name",
          sort: "asc",
          width: 170,
        },
        {
          label: "Data Sold",
          field: "datasold",
          sort: "asc",
          width: 170,
        },
        {
          label: "Billing Rate",
          field: "billingrate",
          sort: "asc",
          width: 170,
        },
        {
          label: "Amount Generated",
          field: "amount",
          sort: "asc",
          width: 170,
        },
        {
          label: "",
          field: "delete",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-small mb-4 w3-animate-right">
          {deleteAgentSuccess ? <AgentDeleteSuccsess /> : ''}
          {funct === "assign" ? (
            <AssignDevices businessuserId={businessuserId}/>
          ) : funct === "delete" ? 
            <DeleteDevice businessUserId ={businessuserId} businessId={myBusinessId} /> 
            : funct === "deleteAgent" ? 
            <DeleteAgent businessUserId ={businessuserId} businessId={myBusinessId} /> 
            : ''}
            <div className="p-20">
              <div className="d-flex mb-5">
                <CardTitle> Agent Details</CardTitle>
                <AgentCardBtn onClick={() => this.props.toggleModal(true, '', "deleteAgent")}>Delete Agent</AgentCardBtn>
              </div>
              <div className="d-flex">
                <SectionWidth>
                  <SubTitle > Name</SubTitle>
                  <Value> {gettingOneBusinessAgent ? <Loader /> : agentdetail.name}</Value>
                </SectionWidth>
                <SectionWidth>
                  <SubTitle > Email</SubTitle>
                  <Value> {gettingOneBusinessAgent ? <Loader /> :  agentdetail.email}</Value>
                </SectionWidth>
                <SectionHalfWidth >
                </SectionHalfWidth>
              </div>
              <hr />
              <div className="d-flex">
                <SectionWidth>
                  <SubTitle > Total Amount Generated</SubTitle>
                  <BlueValue> NGN {gettingOneBusinessAgent ? <Loader /> :  numberWithCommas(agentdetail.totalAmountGenerated * currencyValue)}</BlueValue>
                  <SectionValue>{ gettingOneBusinessAgent ? '...' : "~ " + numberWithCommas(agentdetail.totalAmountGenerated) + " WC" }</SectionValue>
                </SectionWidth>
                <SectionWidth>
                  <SubTitle > Total Voucher Generated</SubTitle>
                  <BlueValue> {gettingOneBusinessAgent ? <Loader /> :  agentdetail.totalVoucherGenerated}</BlueValue>
                </SectionWidth>
                <SectionHalfWidth >
                </SectionHalfWidth>
              </div>
            </div>
          </div>
          <div className="row">
            {gettingOneBusinessAgent ? (
              <div>
                <CustomLoader />
              </div>
            ) : 
            <div className = "col-lg-12">
              <CustomDataTable
                  data={data}
                  // pagination={this.customPagination()}
                  // placeholder={"Search agent name"}
                  title={<div> <p className='mb-0'> Devices</p><p className="mb-0 text-grey t-8"> Devices managed by {agentdetail.name}</p></div>}
                  onClick={() => this.props.toggleModal(true, agentdetail, "assign")}
                  buttontitle={"Assign Device"}
                />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { gettingOneBusinessAgent} = state.Loading;
  const {agentdetail} = state.Manager;
  const {funct, myBusinessId, currencyValue} = state.User
  const { deleteAgentSuccess,} = state.Agent;
  return {
    gettingOneBusinessAgent,
    agentdetail,
    funct,
    myBusinessId,
    currencyValue,
    deleteAgentSuccess,

  };
};

export default connect(MapStateToProps, {toggleModal, getFormDetails})(AgentPage);
