
import styled from "styled-components";
import { theme } from "../../theme";


export const ServiceHeading = styled.p`  
    color: ${theme.black};
    font-size: ${theme.textSm}; 
    margin-bottom: 5px;
    font-weight: bold
`
export const HeadingDescription = styled.p`  
    color: ${theme.grey};
    font-size: ${theme.textXs}; 
    margin-bottom: 5px;
`
export const BeneficiaryDescription = styled.p`  
    color: ${theme.grey};
    font-size: ${theme.textXs}; 
    margin-bottom: 5px;
    margin-top:20px;
`
export const BeneficiaryValue = styled.p`  
    color: ${theme.blue};
    font-size: ${theme.textXs}; 
    margin-bottom: 20px;
    font-weight: bold;

`
export const ServiceValue = styled.p`  
    color: ${theme.yellow};
    font-size: ${theme.textSm}; 
    margin-bottom: 20px;
    font-weight: bold;

`
export const EmptyChargeTitle = styled.p`  
    color: ${theme.black};
    font-size: ${theme.textSm}; 
    margin-bottom: 2px;
    font-weight: bold;

`
export const EmptyChargeDescription = styled.p`  
    color: ${theme.grey};
    font-size: ${theme.textXs}; 

`