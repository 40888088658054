export const cyclesOptions = [
  {
    id: 1,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Lock Reward",
      },
      {
        id: 3,
        option: "Remit Reward To Wallet",
      },
      {
        id: 4,
        option: "Reward Released",
      },
    ],
  },
  {
    id: 2,
    title: "Duration",
    type: "select",
    onSelectProp: "lockPeriod",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Three Months",
      },
      {
        id: 3,
        option: "Six Months",
      },
      {
        id: 4,
        option: "Twelve Months",
      },
    ],
  },
  {
    id: 3,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "lockStartDate",
  },
  {
    id: 4,
    title: "End Date",
    type: "endTime",
    onChangeProp: "lockEndDate",
  },
];

export const lockupOptions = [
  // {
  //   id: 1,
  //   title: "Week",
  //   type: "number",
  //   placeholder: "Enter week here",
  //   onChangeProp: "week",
  // },
  {
    id: 1,
    title: "Week",
    type: "select",
    onChangeProp: "",
    onSelectProp: "week",
    // options: allMiningWeeks,
},
  {
    id: 2,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "startDate",
  },
  {
    id: 3,
    title: "End Date",
    type: "endTime",
    onChangeProp: "endDate",
  },
];

export const interestDisbursementsOptions = [
  {
    id: 1,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "startDate",
  },
  {
    id: 2,
    title: "End Date",
    type: "endTime",
    onChangeProp: "endDate",
  },
];

export const accruedInterestsOptions = [
  {
    id: 1,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "startDate",
  },
  {
    id: 2,
    title: "End Date",
    type: "endTime",
    onChangeProp: "endDate",
  },
];

export const rewardAprOptions = [
  {
    id: 1,
    title: "Lock Period",
    type: "select",
    onSelectProp: "lockPeriod",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Three Months",
      },
      {
        id: 3,
        option: "Six Months",
      },
      {
        id: 4,
        option: "Twelve Months",
      },
    ],
  },
];