import React, { Component } from "react";
import { connect } from "react-redux";
import {
  gettingDevices,
  getFormDetails,
  setDeviceRate,
  toggleModal,
  setDeviceTracker,
  editDevice,
  changeToFalse,
  getRates,
  deleteDevice,
  createDevice,
} from "../../Redux/actions";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import { formatRate, numberWithCommas } from "../../Helper";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDataTable from "../Reusables/tables/CustomDataTable";

class ManagedDevices extends Component {
  componentWillMount() {
    this.props.gettingDevices();
    this.props.getRates();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.actionDone === true) {
      this.props.toggleModal(false);
      this.props.gettingDevices('');
      this.props.changeToFalse(false);
    }
  }

  validityInDays() {
    var arr = [];
    for (let i = 1; i < 32; i++) {
      arr.push(<option value={i}>{i + " days"}</option>);
    }
    return arr;
  }

  render() {
    const {
      managerDevices,
      editingDevice,
      newRate,
      trackerError,
      settingRate,
      funct,
      clickedDeviceId,
      newId,
      userId,
      newDeviceName,
      gettingAllDevices,
      creatingdevice,
      currencyRates,
      subscriptionDate,
      settingTracker,
      validityinDays,
      dataAmountInBytes,
      currencyValue,
      deviceName,
      deviceConId,
    } = this.props;

    let deviceDetails = managerDevices.find((device) => {
      return device.id === clickedDeviceId;
    });

    var date = subscriptionDate;
    var timestamp = date.getTime();

    let allRows = [];
    managerDevices.map((device) => {
      return userId === device.managerId
        ? allRows.push({
          name: device.name,
          manager: device.manager == null ? "N/A" : device.manager,
          owner: device.user == null ? "N/A" : device.user.name,
          connectionId: device.connectionId + ".wc",
          rate:
            device.rate == null
              ? "N/A"
              : numberWithCommas(device.rate * 1024 * 1024) +
              " WC/GB | " +
              numberWithCommas(device.rate * 1024 * 1024 * 360) +
              " NGN/GB ",
        })
        : "";
    });
    const data = {
      columns: [
        {
          label: "Device Name",
          field: "name",
          sort: "asc",
          width: 170,
        },
        {
          label: "Device Manager",
          field: "manager",
          sort: "asc",
          width: 170,
        },
        {
          label: "Device Owner",
          field: "owner",
          sort: "asc",
          width: 170,
        },
        {
          label: "Connection Id",
          field: "connectionId",
          sort: "asc",
          width: 170,
        },

        {
          label: "Rate",
          field: "rate",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <Modal
          heading={
            funct === "edit"
              ? "EDIT DEVICE"
              : funct === "setRate"
                ? "SET RATE"
                : funct === "trackdata"
                  ? "TRACK SUBSCRIPTION DATA"
                  : "CREATE DEVICE"
          }
        >
          {funct === "edit" ? (
            <div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={deviceDetails ? deviceDetails.name : ""}
                  defaultValue={deviceDetails.name}
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["newDeviceName"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              {/* <div className="input-group mb-3">
                <input type="text"
                  className="form-control"
                  defaultValue={deviceDetails.macAddress}
                  placeholder={deviceDetails.macAddress ? deviceDetails.macAddress : 'macAddress'}
                  aria-describedby="basic-addon1"
                  onChange={(e) => this.props.getFormDetails({ props: ['newAddress'], value: e.target.value })}
                />
              </div> */}

              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={deviceDetails.connectionId + ".wc"}
                  placeholder={
                    deviceDetails
                      ? deviceDetails.connectionId + ".wc"
                      : "connection ID or SSID"
                  }
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["newId"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <Button
                theme="btn-primary float wicryptColor"
                buttonAction={() =>
                  this.props.editDevice(
                    clickedDeviceId,
                    newId,
                    newDeviceName,
                    deviceDetails
                  )
                }
              >
                {editingDevice ? <Loader /> : "Enter"}
              </Button>
            </div>
          ) : funct === "setRate" ? (
            <div className="col-lg-12">
              <p className="mt-2"> {deviceDetails ? deviceDetails.name : ""}</p>
              <label> Set rate per GB</label>
              <div style={{ display: "flex" }}>
                <input
                  type="number"
                  value={newRate}
                  className="form-control mr-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["newRate"],
                      value: e.target.value,
                    })
                  }
                />
                <select
                  className="form-control mr-2 mt-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["currencyValue"],
                      value: e.target.value,
                    })
                  }
                >
                  {currencyRates.map((rates) => {
                    return (
                      <option key={rates.rate} value={rates.rate}>
                        {rates.code}
                      </option>
                    );
                  })}
                  <option value={1}> USD</option>
                </select>
              </div>
              <Button
                theme="btn-primary ml-3 mt-2 float wicryptColor"
                buttonAction={() =>
                  this.props.setDeviceRate(
                    deviceDetails ? deviceDetails.id : "",
                    formatRate(newRate, currencyValue)
                  )
                }
              >
                {settingRate ? <Loader /> : "Set Rate"}
              </Button>
            </div>
          ) : funct === "trackdata" ? (
            <div className="col-lg-12">
              <p className="mt-2"> {deviceDetails ? deviceDetails.name : ""}</p>
              <label> Data amount in GB</label>
              <div className="mb-3">
                <input
                  type="number"
                  value={dataAmountInBytes}
                  className="form-control mr-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["dataAmountInBytes"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Subscription Date</label>

              <div className="form-control loginCred mb-3">
                <DatePicker
                  className="dateRange b-0 text-left"
                  selected={subscriptionDate}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["subscriptionDate"],
                      value: date,
                    })
                  }
                />
              </div>

              <label> Select validity period </label>
              <div className="input-group mb-2">
                <select
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["validityinDays"],
                      value: e.target.value,
                    })
                  }
                >
                  {this.validityInDays()}
                </select>
              </div>
              <p className="text-red t-12 t-center"> {trackerError}</p>
              <Button
                theme="btn-primary ml-3 mt-2 float wicryptColor"
                buttonAction={() =>
                  this.props.setDeviceTracker(
                    deviceDetails ? deviceDetails.id : "",
                    timestamp,
                    validityinDays,
                    dataAmountInBytes,
                    userId
                  )
                }
              >
                {settingTracker ? <Loader /> : "Track Data"}
              </Button>
            </div>
          ) : (
                  <div className="col-lg-12">
                    <label> Device Name</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={deviceName}
                        onChange={(e) =>
                          this.props.getFormDetails({
                            props: ["deviceName"],
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* <label> mac Address</label>
            <div className="input-group mb-2">
                <input type="text"
                  className="form-control loginCred"
                  aria-describedby="basic-addon1"
                  value={deviceAddress}
                  onChange={(e) => this.props.getFormDetails({ props: ['deviceAddress'], value: e.target.value })}
                />
            </div> */}
                    <label>Connection Id(SSID)</label>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={deviceConId}
                        onChange={(e) =>
                          this.props.getFormDetails({
                            props: ["deviceConId"],
                            value: e.target.value,
                          })
                        }
                      />
                      <input
                        className="form-control mr-2 mt-2 loginCred"
                        value={".wc"}
                      />
                    </div>
                    <Button
                      theme="btn-primary ml-3 mt-2 float wicryptColor"
                      buttonAction={() =>
                        this.props.createDevice(deviceName, deviceConId)
                      }
                    >
                      {creatingdevice ? <Loader /> : "Create Device"}
                    </Button>
                  </div>
                )}
        </Modal>
        <div
          className="col-lg-12"
          style={{ display: "inline-flex", justifyContent: "space-between" }}
        >
          <div className="card-header mb-3">
            <h6 className="m-0">DEVICES</h6>
          </div>

          <div className="card-header mb-3 ">
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.toggleModal(true)}
            >
              {" "}
              Create Device
            </i>
          </div>
        </div>

        {managerDevices.length > 0 ? (
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
            // pagination={this.customPagination()}
            />
          </div>
        ) : (
            <div className="col-lg-12 mb-4">
              {gettingAllDevices ? (
                <CustomLoader />
              ) : (
                  <p className="voucherText"> You have no devices</p>
                )}
            </div>
          )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { managerDevices, currencyRates, trackerError } = state.Manager;
  const {
    newRate,
    funct,
    clickedDeviceId,
    newId,
    newAddress,
    newDeviceName,
    currencyValue,
    userId,
    deviceName,
    deviceAddress,
    deviceConId,
    dataAmountInBytes,
    validityinDays,
    subscriptionDate,
  } = state.User;
  const {
    settingRate,
    editingDevice,
    actionDone,
    gettingAllDevices,
    deletingDevice,
    creatingdevice,
    settingTracker,
  } = state.Loading;
  return {
    managerDevices,
    newRate,
    settingRate,
    clickedDeviceId,
    funct,
    newId,
    newAddress,
    newDeviceName,
    editingDevice,
    subscriptionDate,
    actionDone,
    gettingAllDevices,
    currencyRates,
    currencyValue,
    trackerError,
    userId,
    settingTracker,
    creatingdevice,
    validityinDays,
    deletingDevice,
    deviceName,
    deviceAddress,
    deviceConId,
    dataAmountInBytes,
  };
};

export default connect(
  MapStateToProps,
  {
    gettingDevices,
    getFormDetails,
    createDevice,
    setDeviceTracker,
    setDeviceRate,
    deleteDevice,
    changeToFalse,
    toggleModal,
    editDevice,
    getRates,
  }
)(ManagedDevices);
