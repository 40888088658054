import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionOverview } from "../../../Redux/actions";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { numberWithCommas } from "../../../Helper";
import {
  OverviewDescription,
  OverviewValue,
} from "./transactionoverviewstyles";
import TableHeader from "../../Reusables/tables/TableHeader";
import { transactionsHeaderOption } from "./options";
import { getSearchDetails } from "../../../Redux/actions/SearchAction";
import FundingChart from "./charts/FundingChart";
import WithdrawalChart from "./charts/WithdrawalChart";
import { clearSearch } from "../../../Redux/actions/GeneralAction";

const TransactionOverview = () => {
  const { gettingTransactionoverview } = useSelector((state) => state.Loading);
  const { transactionOverview } = useSelector((state) => state.Report);
  const { duration } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSearch());
  }, []);
  useEffect(() => {
    const queries = { duration };
    getTransactionOverview(dispatch, queries);
  }, [duration]);

  return (
    <div className="row mb-5 jc">
      <div className="col-lg-12">
        <div className="card ">
          <div className="row p-5">
            <TableHeader
              subHeader={transactionsHeaderOption}
              onSelect={(val, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: val,
                  })
                );
              }}
            />
          </div>
          <div className="col-lg-12">
            {gettingTransactionoverview ? (
              <div>
                <CustomLoader />{" "}
              </div>
            ) : transactionOverview !== undefined ? (
              <div className="">
                <div className="card">
                  <div
                    className="card-body d-flex jc"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div>
                      {" "}
                      <OverviewDescription>
                        Total Funding Amount
                      </OverviewDescription>{" "}
                      <OverviewValue>
                        {transactionOverview.funding &&
                          transactionOverview.funding.currency +
                            " " +
                            numberWithCommas(transactionOverview.funding.total)}
                      </OverviewValue>{" "}
                    </div>
                    <div>
                      {" "}
                      <OverviewDescription>
                        Total Withdrawal Amount
                      </OverviewDescription>{" "}
                      <OverviewValue color="yellow">
                        {transactionOverview.withdrawal &&
                          transactionOverview.withdrawal.currency +
                            " " +
                            numberWithCommas(
                              transactionOverview.withdrawal.total
                            )}
                      </OverviewValue>{" "}
                    </div>
                  </div>
                  <div className="card-body d-flex ">
                    <FundingChart
                      fundingOverview={transactionOverview.funding}
                    />
                    <WithdrawalChart
                      withdrawalOverview={transactionOverview.withdrawal}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {gettingTransactionoverview ? (
                  <CustomLoader />
                ) : (
                  <p className="voucherText">
                    {" "}
                    No transactions within this period
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="card mt-3">
          {transactionOverview.totalFundingAmount !== undefined ? (
            <div className="col-lg-12">
              <div className="card-body d-flex jc" style={{ flexWrap: "wrap" }}>
                <div className="col-lg-6 col-md-12">
                  <p className="mb-0">
                    {" "}
                    Total Amount of Vouchers Generated by Owners
                  </p>{" "}
                  <p className="overviewVal">
                    {" "}
                    {"NGN " +
                      numberWithCommas(
                        transactionOverview.vouchersGenerated
                          .virtualAccountGeneratedVoucher
                      )}
                  </p>{" "}
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="mb-0">
                    {" "}
                    Total Amount of Vouchers Generated by Agents
                  </p>{" "}
                  <p className="overviewVal">
                    {" "}
                    {"NGN " +
                      numberWithCommas(
                        transactionOverview.vouchersGenerated
                          .agentGeneratedVoucher
                      )}
                  </p>{" "}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionOverview;
