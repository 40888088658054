import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

//Generate Token, Get's Token Beneficiary, Set's Token to beneficiary
export const generateToken = (
  amount,
  deviceId,
  email,
  code,
  dataVolume,
  maxUsers,
  expiresAt
) => {
  let volume =
    dataVolume === "GB"
      ? amount * 1024 * 1024 * 1024
      : dataVolume === "KB"
        ? amount * 1024
        : amount * 1024 * 1024;
  let data = {
    code: code,
    totalBytes: volume,
    deviceId: deviceId,
    maxUsers: maxUsers,
    expiresAt: moment(expiresAt).format(),
  };
  return (dispatch) => {
    if (
      data.deviceId === "" ||
      data.maxUsers === "" ||
      data.totalBytes === "" ||
      data.code === ""
    ) {
      dispatch({
        type: _const.GETTING_BENEFICIAIRY,
        payload: false,
      });
      dispatch({
        type: _const.SET_TOKEN_ERROR,
        payload: "Please fill out all fields",
      });
    } else {
      dispatch({
        type: _const.GETTING_BENEFICIAIRY,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/token", data)
        .then((res) => {
          dispatch({
            type: _const.GETTING_BENEFICIAIRY,
            payload: false,
          });
          if (res.data.errors.length === 0) {
            dispatch({
              type: _const.MODAL_STATE,
              payload: false,
            });
            Swal.fire({
              text: "Token added successfully!",
              type: "success",
            });
            dispatch({
              type: _const.CLEAR_FORMS,
              payload: true,
            });
            dispatch({
              type: _const.TOKEN_SET,
              payload: true,
            });
          } else {
            dispatch({
              type: _const.SET_TOKEN_ERROR,
              payload: res.data ? res.data.errors[0] : "",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.GETTING_BENEFICIAIRY,
            payload: false,
          });
          dispatch({
            type: _const.SET_TOKEN_ERROR,
            payload: err.response.data
              ? err.response.data.message
              : "Something went wrong",
          });
          dispatch({
            type: _const.CLEAR_FORMS,
            payload: true,
          });
        });
    }
  };
};

//Set User To a Token
export const addUser = (email, token) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BENEFICIAIRY,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/users/by?email=${email}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_BENEFICIAIRY,
          payload: false,
        });
        if (res.data.data) {
          dispatch({
            type: _const.MODAL_STATE,
            payload: false,
          });
          Swal.fire({
            title: "Are you sure?",
            text:
              "You are about to assign a token to " +
              res.data.data.name +
              " would you like to continue?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.value) {
              dispatch({
                type: _const.GETTING_BENEFICIAIRY,
                payload: true,
              });
              axios
                .post(
                  config.apiUrl +
                  `/v2/token/${token}/users?userId=${res.data.data.id}`
                )
                .then((res) => {
                  if (res.data.errors.length === 0) {
                    Swal.fire({
                      text: `You have Successfully assigned token to ${email}`,
                      type: "success",
                    });
                    dispatch({
                      type: _const.GETTING_BENEFICIAIRY,
                      payload: false,
                    });
                    dispatch({
                      type: _const.CLEAR_FORMS,
                      payload: true,
                    });
                    dispatch({
                      type: _const.TOKEN_SET,
                      payload: true,
                    });
                  } else {
                    Swal.fire({
                      text: res.data ? res.data.errors[0] : "",
                      type: "error",
                    });
                    dispatch({
                      type: _const.GETTING_BENEFICIAIRY,
                      payload: false,
                    });
                    dispatch({
                      type: _const.CLEAR_FORMS,
                      payload: true,
                    });
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    text: "Something went wrong",
                    type: "error",
                  });
                  dispatch({
                    type: _const.CLEAR_FORMS,
                    payload: true,
                  });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Be Careful Next Time :)", "error");
              dispatch({
                type: _const.CLEAR_FORMS,
                payload: true,
              });
            }
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BENEFICIAIRY,
          payload: false,
        });
        if (err.response.status === 404) {
          dispatch({
            type: _const.MODAL_STATE,
            payload: false,
          });
          Swal.fire({
            text: "Email does not exist",
            type: "error",
          });
        }
      });
  };
};

//Get Tokens
export const getTokens = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_TOKENS,
      payload: true,
    });
    axios
      .get(config.apiUrl + "/v2/users/tokens")
      .then((res) => {
        dispatch({
          type: _const.GETTING_TOKENS,
          payload: false,
        });
        dispatch({
          type: _const.GET_TOKENS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_TOKENS,
          payload: false,
        });
      });
  };
};

//Get all users for a particular token
export const getAllTokenatedUsers = (id) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_USERS,
      payload: true,
    });
    dispatch({
      type: _const.TOKEN_PAGE,
      payload: true,
      id,
    });
    axios
      .get(config.apiUrl + `/v2/token/${id}/users`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_USERS,
          payload: false,
        });
        dispatch({
          type: _const.TOKENATED_USERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_USERS,
          payload: false,
        });
      });
  };
};

// Take me back to first page
export const takeMeBack = (payload) => {
  return {
    type: _const.TOKEN_PAGE,
    payload,
  };
};

//Delete User from Token
export const deleteUser = (tokenId, userId) => {
  return (dispatch) => {
    dispatch({
      type: _const.DELETING_USER,
      payload: true,
    });
    dispatch({
      type: _const.CLICKED_TOKEN_ID,
      payload: userId,
    });
    axios
      .delete(config.apiUrl + `/v2/token/${tokenId}/users?userId=${userId}`)
      .then((res) => {
        dispatch({
          type: _const.DELETING_USER,
          payload: false,
        });
        if (res.data.success) {
          Swal.fire({
            text: "User has been successfully deleted",
            type: "success",
          });
          dispatch({
            type: _const.DELETED,
            payload: true,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.DELETING_USER,
          payload: false,
        });
        Swal.fire({
          text: "Something went wrong!",
          type: "error",
        });
      });
  };
};

//Edit Token
export const editToken = (id, expiresAt, token) => {
  let data = {
    code: token.code,
    totalBytes: token.totalBytes,
    deviceId: token.deviceId,
    maxUsers: token.maxUsers,
    expiresAt: expiresAt,
  };

  return (dispatch) => {
    dispatch({
      type: _const.EDITING_TOKEN,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v2/token/${id}`, data)
      .then((res) => {
        dispatch({
          type: _const.EDITING_TOKEN,
          payload: false,
        });
        if (res.data.success) {
          Swal.fire({
            text: "Token has been edited successfully!",
            type: "success",
          });
          dispatch({
            type: _const.MODAL_STATE,
            payload: false,
          });
          dispatch({
            type: _const.TOKEN_SET,
            payload: true,
          });
        } else {
          Swal.fire({
            text: res.data ? res.data.errors[0] : "",
            type: "error",
          });
          dispatch({
            type: _const.MODAL_STATE,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.EDITING_TOKEN,
          payload: false,
        });
        Swal.fire({
          text: "Something went wrong",
          type: "error",
        });
        dispatch({
          type: _const.MODAL_STATE,
          payload: false,
        });
      });
  };
};

//Delete token
export const deleteToken = (tokenId) => {
  return (dispatch) => {
    Swal.fire({
      text: "Are you sure you want to delete this token?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        dispatch({
          type: _const.DELETING_TOKEN,
          payload: true,
          id: tokenId,
        });
        axios
          .delete(config.apiUrl + `/v2/token/${tokenId}`)
          .then((res) => {
            dispatch({
              type: _const.DELETING_TOKEN,
              payload: false,
            });
            if (res.data.success) {
              dispatch(getTokens());
              Swal.fire({
                text: "Token has been deleted successfully",
                type: "success",
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: _const.DELETING_TOKEN,
              payload: false,
            });
          });
      } else {
      }
    });
  };
};
