import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getRevenue} from '../../Redux/actions'
import {LineChart} from '../Reusables';
import moment from 'moment'

class DeviceStats extends Component{

  constructor(){
    super()
    this.state={
      months: [],
      totalUsage: [],
      xAxis: []
    }
  }
  componentWillMount(){
    this.props.getRevenue('30d')
  }

  componentWillReceiveProps(nextProps){
    const {allRevenue} = nextProps
    // if(nextProps.yearData.length > 0 & nextProps.monthByte == 0){
    //   let allYears = nextProps.yearData.sort((a, b) => { 
    //     let yearA = a.year + '-' + '0'+ a.month
    //     let yearB = b.year + '-' + '0'+ b.month
    //     return moment(yearA, "YYYY-DD") - moment(yearB, "YYYY-DD")
    //   });
    //   let months = allYears.map(month =>{ 
    //     return moment(month.month, 'MM').format('MMM') 
    //   })
    //   let totalUsage = allYears.map(usage =>{
    //     return usage.totalUsage/1000000000
    //   })
    //   this.setState({
    //     xAxis: months,
    //     totalUsage,
    //     months: this.extractMonth(nextProps.chartData)
    //   })
    // }else if(nextProps.chartData.length > 0 & nextProps.monthByte !== 0){
      // let allDays = allRevenue.filter( (a, b) => { 
      //   return a.month == 3
      // });
      let days =  allRevenue.map(billing =>{
        return billing.day
      })
      let totalBilling = allRevenue.map(usage =>{
        return usage.totalBilling * 360
      })
      this.setState({
        xAxis: days,
        totalBilling,
        months: this.extractMonth(allRevenue)
      })
    // }
  }

  extractMonth(chartData){
    let months= [];
    chartData.map((item, index) => months.push(item.month));
    return Array.from(new Set(months));
    
  }
    render() {
      const {title} = this.props
      const {months} = this.state
      const data = [{
        data: this.state.totalBilling,
        label:  months ? 'Total Revenue' : moment(months, 'MM').format('MMMM') + ' Revenue' ,
        backgroundColor: 'rgba(0,123,255,0.1)',
        borderColor: 'rgba(0,123,255,1)',
        pointBackgroundColor: 'rgb(0,123,255)',
        pointHoverBackgroundColor: 'rgba(255,65,105,1)',
      }
      ] 
     
      return (
  
        <div className="col-lg-6 col-md-6 col-sm-12 mb-4 w3-animate-left">
          <div className="card card-small h-100">
            <div className="card-header d-flex jc">
              <h6 className="m-0 text-bold">{title}</h6>
              <div className="row py-2 bg-light">
                <div className="col-12 col-sm-6">
                  <div id="blog-overview-date-range" className="input-daterange input-group input-group-sm my-auto ml-auto mr-auto ml-sm-auto mr-sm-0" style={{maxWidth: '350px'}}>
                    <select className="input-sm form-control" onChange = {(e)=> this.props.getRevenue(e.target.value)}> 
                    <option value = {'30d'}> Last 30 days</option>
                      <option value = {'7d'}> Last 7 days</option>
                      <option value = {'14d'}> Last 14 days</option>
                      
                      {/* <option value = {'1y'}> 1 year</option> */}
                    </select>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              
              <LineChart data={data} labels = {this.state.xAxis}/>
            </div>
          </div>
        </div>
      );
    }
  }

const MapStateToProps = (state) =>{
  const {allRevenue} = state.Manager
  return{
    allRevenue
  }
}

export default connect(MapStateToProps, {getRevenue })(DeviceStats)