import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  createVoucher,
  getvouchers,
  toggleModal,
  saveId,
  clearData,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import {  voucherHrs, voucherDays} from "../../Helper";
import {BigInput, ContinueBtn,  LayeredInput} from "../Reusables/inputs"
import {Label, ValidityContainer, InlineFlex, ValiditySelect} from './voucherstyles'


class CreateVoucher extends Component {
  
  render() {
    const {
      voucherRecepientPhone,
      generatingVoucher,
      voucherStatus,
      voucherError,
      voucherAmt,
      businessProfile,
      voucherstartDate,
      voucherendDate,
      voucherValidity,
      savedBusinessId,
      days,
    } = this.props;

 
    return (
        <Modal heading={"Generate Voucher"}>
         <LayeredInput
            placeholder=""
            title="Recepient's Phone Number"
            description="Enter the recepient's phone number"
            value={voucherRecepientPhone}
            type="number"
            onChange={(e) =>
                this.props.getFormDetails({
                  props: ["voucherRecepientPhone"],
                  value: e.target.value,
                })
              }
            // err={withdrawalOtpError}
        />
        <ValidityContainer className="col-lg-12">
        <Label> Voucher validity</Label>
            <InlineFlex>
            <div
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["days"],
                  value: true,
                })
              }
            >
              <input
                type="radio"
                id="days"
                className="ml-5"
                name="validity"
                checked={days}
              />
              <Label htmlFor="days" className="ml-3">
                Days
              </Label>
            </div>
            
            <div
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["days"],
                  value: false,
                })
              }
            >
              <input
                type="radio"
                id="hours"
                name="validity"
                className="ml-5"
                checked={!days}
              />
              <Label htmlFor="hours" className="ml-3">
                Hours
              </Label>
            </div>  
            </InlineFlex> 
         
            <div className="input-group mb-2">
              <ValiditySelect 
                className="form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherValidity"],
                    value: e.target.value,
                  })
                }
              >
                <option value=""> Select validity period</option>
                {days ? voucherDays() : voucherHrs()}
              </ValiditySelect >
            </div>
        </ValidityContainer>
        <BigInput
            title="Click to enter amount"
            type="number"
            value={voucherAmt}
            onChange={(e) =>
                this.props.getFormDetails({
                  props: ["voucherAmt"],
                  value: e.target.value,
                })
            }
            // err={withdrawalAmtError}
        />
        {voucherError !== "" ? (
          <p className="errorMsgs">
            <i className="material-icons">info</i>
            {voucherError}
          </p>
        ) : (
          ""
        )}
        <ContinueBtn 
            onClick={() =>
                this.props.createVoucher(
                  voucherRecepientPhone,
                  voucherAmt,
                  voucherValidity,
                  savedBusinessId,
                  businessProfile.voucherGenerationTransactionFee !== undefined
                    ? businessProfile.voucherGenerationTransactionFee
                    : "",
                  voucherstartDate,
                  voucherendDate,
                  voucherStatus,
                  "",
                  days
                )
              }
            title={generatingVoucher ? <Loader /> : "Set"} />
       
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    voucherRecepientPhone,
    voucherAmt,
    voucherstartDate,
    voucherendDate,
    voucherStatus,
    voucherValidity,
    voucherMsg,
    businessProfile,
    days,
    singlevouchercodeSearch
  } = state.User;
  const { generatingVoucher, gettingVouchers } = state.Loading;
  const {
    allVouchers,
    voucherError,
    savedBusinessId,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
  } = state.Voucher;
  return {
    allVouchers,
    voucherRecepientPhone,
    voucherAmt,
    voucherStatus,
    generatingVoucher,
    gettingVouchers,
    voucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
    voucherstartDate,
    voucherendDate,
    days,
    singlevouchercodeSearch
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  createVoucher,
  getvouchers,
  toggleModal,
  saveId,
  clearData,
})(CreateVoucher);
