import React from 'react';
import Loader from '../Reusables/loaders/MoonLoader';
import { connect } from 'react-redux';
import { getFormDetails, registerMerch, getByemail, logOut} from '../../Redux/actions';
import CustomModal from "../Reusables/modals/CustomModal";

const RegForm = (props) => {


    const { merchantAddress, merchantName, merchantregistering, merchantLogo, name, userId, merchantError, imagePreview } = props


    let data = {
        "userId": userId,
        "name": merchantName,
        "logoUrl": null,
        "officeAddress": merchantAddress,
        "logoFile": merchantLogo
    }



    return (
        <div className=" col-lg-5 offset-lg-3">
             
            <React.Fragment>
            <CustomModal
               id={120}
               heading=""
               close={false}
               content={
                   <React.Fragment>       
                       <div className="stats-small stats-small--1 card card-small">
                           <div className="card-body d-flex mb-5">
                           <div className="d-flex flex-column m-auto">
                               <img src="/Assets/check.svg" className="mt-5" width="100%" height="70px" alt="success" />
                               <p onClick={()=> props.logOut()} className=" pointer text-green mt-4 mb-5 text-center"> Registration Successful! <br/>
                               <span className="text-blue"> Log out and Log back in to continue! </span>
                               </p>
                           </div>
                           </div>
                       </div>
                   </React.Fragment>
               }
           /> </React.Fragment>

            <div className="card card-small card-post card-post--1">

                <div className="card-body">
                    <div>
                        <p className='wicryptmsg'> Create a Business account for {name}</p>
                        <div className="p-r">
                            <div className="avatar__preview mx-auto mb-4">
                                <img
                                    className="avatar__upload"
                                    id="Img1"
                                    src={
                                        imagePreview !== ""
                                            ? imagePreview
                                            : "https://assets.hongkiat.com/uploads/psd-text-svg/logo-example.jpg"
                                    }
                                    alt="advert"
                                />

                            </div>
                            <input type="file" name="file" id="file"
                                onChange={(e) => props.getFormDetails({ props: ['merchantLogo'], value: e.target.files[0] })}
                                className="uglyduckling" />
                            <label for="file" className="uglylabel"><i className="fa fa-edit fa-2x" aria-hidden="true" /></label>
                        </div>

                        <label> Business Name</label>
                        <div className="input-group ">

                            <input type="text"
                                className="form-control loginCred"
                                aria-label="MerchantName"
                                aria-describedby="basic-addon1"
                                value={merchantName}
                                onChange={(e) => props.getFormDetails({ props: ['merchantName'], value: e.target.value })}
                            />

                        </div>
                        <label> Merchant Address</label>
                        <div className="input-group ">

                            <input type="email"
                                className="form-control loginCred"
                                aria-label="MerchantAddress"
                                aria-describedby="basic-addon1"
                                onChange={(e) => props.getFormDetails({ props: ['merchantAddress'], value: e.target.value })}
                            />

                        </div>
                        {merchantError !== '' ? <p className='errorMsgs' ><i className="material-icons">info</i>{merchantError}</p> : ''}

                        <div className='loginBtn mt-3'>
                            <button onClick={() => props.registerMerch(data)}
                                className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin">
                                {merchantregistering ? <Loader /> : 'Register'}
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )

}

const MapStateToProps = (state) => {
    const { name, merchantName, merchantAddress, merchantLogo, merchantEmail, userId, emailFail, merchantError, imagePreview, merchnatsuccess} = state.User
    const { merchantregistering, fetchingname } = state.Loading
    return {

        merchantName,
        merchantAddress,
        merchantregistering,
        merchantLogo,
        merchantEmail,
        fetchingname,
        userId,
        emailFail,
        merchantError,
        imagePreview,
        name,
        merchnatsuccess

    }
}
export default connect(MapStateToProps, { getFormDetails, registerMerch, getByemail, logOut})(RegForm)