
import styled from "styled-components";
import { theme } from "../../theme";


export const SummaryTitle = styled.p`  
    color: ${theme.grey};
    margin-bottom: 5px;
`
export const SummaryValue = styled.p`  
    color: ${theme.black};
    font-weight: bold;
    margin-bottom: 5px;
`