import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toggleModal, clearData } from "./GeneralAction";

//Get Device Summary from this user
export const getDevices = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/users/devices/summary`)
      .then((res) => {
        dispatch({
          type: _const.GET_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

//Get all devices in our system
export const getAllDevices = (userId, name, status, deviceTier,type, model,orderBy, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ALL_WICRYPT_DEVICES,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/devices?UserId=${userId}&Name=${name}&Status=${status}&DeviceTier=${deviceTier}&Type=${type}&Model=${model}&OrderBy=${orderBy}&Page=${page}&PageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_ALL_WICRYPT_DEVICES,
          payload: false
        });
        dispatch({
          type: _const.GET_ALL_WICRYPT_DEVICES,
          payload: res.data.data.records,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ALL_WICRYPT_DEVICES,
          payload: false
        });
      });
  };
};


export const getAdminDeviceSummary = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/users/devices/summary")
      .then((res) => {
        dispatch({
          type: _const.GET_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

//Create Devices
export const createDevice = (deviceName, deviceConId) => {
  let data = {
    name: deviceName,
    macAddress: "",
    connectionId: deviceConId,
    rate: 0,
  };
  return (dispatch) => {
    if (deviceName === undefined || deviceName === "") {
    } else {
      dispatch({
        type: _const.CREATING_DEVICE,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/devices", data)
        .then((res) => {
          dispatch({
            type: _const.CREATING_DEVICE,
            payload: false,
          });
          dispatch(gettingDevices(""));
          dispatch(toggleModal(false));
          dispatch({
            type: _const.CLEAR_FORMS,
            payload: true,
          });
          if (res.data.errors.length === 0) {
            Swal.fire({
              text: "Device created successfully",
              type: "success",
            });
            // axios.get(config.apiUrl + "/v2/devices/my").then((my) => {
            //   dispatch(toggleModal(true, res.data.data.id, "setRate"));
            // });
          } else {
            Swal.fire({
              text: res.data.errors[0],
              type: "error",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.CREATING_DEVICE,
            payload: false,
          });
        });
    }
  };
};

//Set Rate for Each Device
export const setDeviceRate = (id, rate, role) => {
  let apiUrl = role.includes("Business")
    ? `/v2/devices/${id}/rate?rate=${rate}`
    : `/v2/devices/${id}/user/rate?rate=${rate}`;
  if (rate < 0) {
    Swal.fire({
      text: "You cannot set Negative Rate",
      type: "error",
    });
    return (dispatch) => {
      dispatch({
        type: _const.SETTING_RATE,
        payload: false,
        done: true,
      });
    };
  } else if (rate === 0) {
    return (dispatch) => {
      dispatch({
        type: _const.SETTING_RATE,
        payload: false,
        done: true,
      });
      Swal.fire({
        text:
          "You are about to allow users to browse unlimited on this device. If this is not what you want, please decline.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          dispatch({
            type: _const.SETTING_RATE,
            payload: true,
          });
          axios
            .put(config.apiUrl + apiUrl)
            .then((res) => {
              dispatch({
                type: _const.SETTING_RATE,
                payload: false,
                done: true,
              });
              if (res.data.errors.length === 0) {
                Swal.fire({
                  text: "Rate has been set successfully",
                  type: "success",
                });
              } else {
                Swal.fire({
                  text: res.data.errors[0],
                  type: "error",
                });
              }
              dispatch({
                type: _const.RATE_SET,
                payload: true,
              });
            })
            .catch((err) => {
              dispatch({
                type: _const.SETTING_RATE,
                payload: false,
                done: true,
              });
              Swal.fire({
                text: err.response
                  ? err.response.data.rate[0]
                  : "Something went wrong",
                type: "error",
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Be Careful Next Time :)", "error");
        }
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: _const.SETTING_RATE,
        payload: true,
      });
      axios
        .put(config.apiUrl + apiUrl)
        .then((res) => {
          dispatch({
            type: _const.SETTING_RATE,
            payload: false,
            done: true,
          });
          if (res.data.errors.length === 0) {
            Swal.fire({
              text: "Rate has been set successfully",
              type: "success",
            });
          } else {
            Swal.fire({
              text: res.data.errors[0],
              type: "error",
            });
          }
          dispatch({
            type: _const.RATE_SET,
            payload: true,
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.SETTING_RATE,
            payload: false,
            done: true,
          });
          Swal.fire({
            text: err.response
              ? err.response.data.rate[0]
              : "Something went wrong",
            type: "error",
          });
        });
    };
  }
};

//Get All Manager Device Details
export const gettingDevices = (deviceName) => {
  return (dispatch) => {
    dispatch({
      type: _const.GET_ALL_DEVICES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/devices/my?deviceName=${deviceName}`)
      .then((res) => {
        dispatch({
          type: _const.GET_ALL_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.GET_DEVICE_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GET_ALL_DEVICES,
          payload: false,
        });
      });
  };
};

//Edit Device Information
export const editDevice = (deviceId, newId, newDeviceName, deviceDetails) => {
  let data = {
    name: newDeviceName === "" ? deviceDetails.name : newDeviceName,
    macAddress: "",
    connectionId: newId === "" ? deviceDetails.connectionId : newId,
    rate: 0,
  };
  return (dispatch) => {
    dispatch({
      type: _const.EDITING_DEVICE,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v2/devices/${deviceId}`, data)
      .then((res) => {
        dispatch({
          type: _const.EDITING_DEVICE,
          payload: false,
          done: true,
        });
        Swal.fire({
          text: "You have successfully edited your device",
          type: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.EDITING_DEVICE,
          payload: false,
          done: true,
        });
        Swal.fire({
          text: "Something went wrong",
          type: "error",
        });
      });
  };
};

//Delete devices
export const deleteDevice = (id) => {
  return (dispatch) => {
    Swal.fire({
      text: "Are you sure you want to delete this device?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        dispatch({
          type: _const.DELETING_DEVICE,
          payload: true,
          id,
        });
        axios
          .delete(config.apiUrl + `/v2/devices/${id}`)
          .then((res) => {
            dispatch({
              type: _const.DELETING_DEVICE,
              payload: false,
            });
            if (res.data.success) {
              dispatch(gettingDevices(""));
              Swal.fire({
                text: "Device has been deleted successfully",
                type: "success",
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: _const.DELETING_DEVICE,
              payload: false,
            });
          });
      }
    });
  };
};

//Set up tracker for a device
export const setDeviceTracker = (
  deviceId,
  subscriptionDate,
  validityInDays,
  dataAmountInBytes,
  enteredById,
  businessId
) => {
  let data = {
    deviceId: deviceId,
    subscriptionDate: subscriptionDate,
    validityInDays: parseInt(validityInDays),
    dataAmountInBytes: dataAmountInBytes * 1024 * 1024 * 1024,
    enteredById: enteredById,
  };
  return (dispatch) => {
    if (validityInDays === 0 || dataAmountInBytes === "" || deviceId === "") {
      dispatch({
        type: _const.ERROR_WHILE_SETTING_UP_TRACKER,
        payload: "Please fill out all fields",
      });
    } else {
      dispatch({
        type: _const.SETTING_TRACKER,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/dataSubscriptionTracker", data)
        .then((res) => {
          dispatch({
            type: _const.SETTING_TRACKER,
            payload: false,
          });
          dispatch(toggleModal(false));
          dispatch(getTrackingInfo(businessId, "", 1, 50));
          dispatch(clearData());
          Swal.fire({
            text: "Tracker has been set up successfully!",
            type: "success",
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.SETTING_TRACKER,
            payload: false,
          });
          dispatch({
            type: _const.ERROR_WHILE_SETTING_UP_TRACKER,
            payload:
              err.response.data !== undefined
                ? err.response.data.message
                : "Something went wrong",
          });
        });
    }
  };
};

//Check the tracking information ffor that device
export const getTrackingInfo = (businessId, deviceId, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_TRACKER_INFO,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/dataSubscriptionTracker?businessId=${businessId}&deviceId=${deviceId}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_TRACKER_INFO,
          payload: false,
        });

        dispatch({
          type: _const.DEVICE_TRACKER_INFO,
          payload: res.data.data.records,
          totalrecords: res.data.data.totalRecords,
          currentPage: res.data.data.currentPage,
          totalPages: res.data.data.totalPages,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_TRACKER_INFO,
          payload: false,
        });
      });
  };
};

//Getting Devices for a Business
export const getBusinessDevices = (id) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BUSINESS_DEVICES,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/devices/businessDevices?businessId=${id}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_BUSINESS_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.GETTING_DEVICES_BY_BUSINESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BUSINESS_DEVICES,
          payload: true,
        });
      });
  };
};

//Device Live sessions
export const getDeviceSessions = (
  page,
  pageSize,
  businessId,
  deviceId,
  active,
  searchParam,
  startDateTimeStamp,
  endDatetimeStamp
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_SESIONS,
      payload: true,
    });
    let apiUrl = businessId
      ? `/v2/devices/Sessions?businessId=${businessId}&deviceId=${deviceId}&isActive=${active}&page=${page}&pageSize=${pageSize}&searchParam=${searchParam}&startDateTimeStamp=${startDateTimeStamp}&endDatetimeStamp=${endDatetimeStamp}`
      : `/v2/devices/Sessions/user&searchParam=${searchParam}`;
    axios
      .get(config.apiUrl + apiUrl)
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_SESIONS,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_SESSION_HISTORY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_SESIONS,
          payload: false,
        });
      });
  };
};

//Get device live session summary

export const getDeviceSessionSummary = (active, userId) => {
  return (dispatch) => {
    dispatch({
      type: _const.DEVICE_SESSION_SUMMARY,
      payload: {
        numberOfSessions: "",
        totalAmountGenerated: "",
        totalDataUsed: "",
      },
    });
    dispatch({
      type: _const.GETTING_DEVICE_SESION_SUMMARY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/devices/sessions/summary?isActive=${active}&userId=${userId}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_SESION_SUMMARY,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_SESSION_SUMMARY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_SESION_SUMMARY,
          payload: false,
        });
      });
  };
};

//Get Device activity logs
export const getDeviceActivityLogs = (deviceId, pageNumber, pageSize, activityType) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ACTIVITY_LOGS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/devices/activity-logs?DeviceId=${deviceId}&PageNumber=${pageNumber}&PageSize=${pageSize}&ActivityType=${activityType}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_ACTIVITY_LOGS,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_ACTIVITY_LOGS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ACTIVITY_LOGS,
          payload: false,
        });
      });
  };
};