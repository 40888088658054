import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import { toggleModal } from "./GeneralAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

// Create Business Token
export const createBusinessToken = (days, secondsAllocated, bytesAllocated, dataTokenUsageType, quantity, volume) => {

    let data = {
        "customerId": "",
        "secondsAllocated": Number(days ? secondsAllocated * 24 * 60 * 60 : secondsAllocated * 60 * 60),
        "bytesAllocated": volume === "GB" ? bytesAllocated * 1024 * 1024 * 1024  : volume === "MB" ?  bytesAllocated * 1024 * 1024 : bytesAllocated * 1024 ,
        "codeValidityInSeconds": 0,
        "dataTokenUsageType": dataTokenUsageType,
        "quantity": quantity
    }

    return (dispatch) => {
        if (dataTokenUsageType === "") {
            dispatch({
                type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                payload: 4,
            });
        } 
        else if (secondsAllocated === "" & dataTokenUsageType === "timed") {
            dispatch({
                type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                payload: 1,
            });
        } else if (bytesAllocated === "" & dataTokenUsageType === "data") {
            dispatch({
                type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                payload: 2,
            });
        } else if (quantity === "") {
            dispatch({
                type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                payload: 3,
            });
        } else {
            dispatch({
                type: _const.CREATING_BUSINESS_TOKEN,
                payload: true
            });
            dispatch({
                type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                payload: ""
            });
            axios.post(config.apiUrl + `/v2/dataTokens`, data).then((res) => {
                if (res.data.success) {
                    dispatch(toggleModal(false));
                    dispatch(getallBusinessTokens("", ""))
                    Swal.fire({
                        text: "Tokens Created",
                        type: "success",
                    });
                    dispatch({
                        type: _const.CREATING_BUSINESS_TOKEN,
                        payload: false
                    });
                    dispatch({
                        type: _const.BUSINESS_TOKEN_CREATED,
                        payload: false
                    });
                };
            }).catch((err) => {
                dispatch({
                    type: _const.CREATING_BUSINESS_TOKEN,
                    payload: false
                });
                dispatch({
                    type: _const.ERROR_CREATING_BUSINESS_TOKEN,
                    payload: err.response.data.message,
                });
            });
        };
    };
};

export const getallBusinessTokens = (status, usage, searchParam) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_ALL_BUSINESS_TOKEN,
            payload: true
        });
        axios.get(config.apiUrl + `/v2/dataTokens/business?voucherStatus=${status}&dataTokenUsageType=${usage}&searchParam=${searchParam}&api-version=${2}`).then((res) => {
            if (res.data.success) {
                dispatch({
                    type: _const.GETTING_ALL_BUSINESS_TOKEN,
                    payload: false
                });
                dispatch({
                    type: _const.ALL_BUSINESS_TOKEN,
                    payload: res.data.data
                });
            };
        }).catch((err) => {
            dispatch({
                type: _const.GETTING_ALL_BUSINESS_TOKEN,
                payload: false
            });
        });
    };
};

export const getallUserTokens = (status, usage, email) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_ALL_USER_TOKEN,
            payload: true
        });
        axios.get(config.apiUrl + `/v2/dataTokens/user?voucherStatus=${status}&dataTokenUsageType=${usage}&customerEmail=${email}`).then((res) => {
            if (res.data.success) {
                dispatch({
                    type: _const.GETTING_ALL_USER_TOKEN,
                    payload: false
                });
                dispatch({
                    type: _const.ALL_USER_TOKEN,
                    payload: res.data.data
                });
            };
        }).catch((err) => {
            dispatch({
                type: _const.GETTING_ALL_USER_TOKEN,
                payload: false
            });
            dispatch({
                type: _const.ALL_USER_TOKEN_ERR,
                payload: err.response.data.message
            });
        });
    };
};

export const getAdminTokens = (status, usage, email, businessId) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_ALL_USER_TOKEN,
            payload: false
        });
        dispatch({
            type: _const.GETTING_ALL_ADMIN_TOKENS,
            payload: true
        });
        axios.get(config.apiUrl + `/v2/dataTokens/admin/all?voucherStatus=${status}&dataTokenUsageType=${usage}&customerEmail=${email}`).then((res) => {
            if (res.data.success) {
                dispatch({
                    type: _const.GETTING_ALL_ADMIN_TOKENS,
                    payload: false
                });
                dispatch({
                    type: _const.ALL_ADMIN_TOKENS,
                    payload: res.data.data
                });
            };
        }).catch((err) => {
            dispatch({
                type: _const.GETTING_ALL_ADMIN_TOKENS,
                payload: false
            });
            dispatch({
                type: _const.ALL_ADMIN_TOKEN_ERR,
                payload: err.response.data.message
            });
        });
    };
};