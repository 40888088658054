import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRewardPercentage } from "../../../Redux/actions/AdminDevicesAction";
import CustomSlider from "../../Reusables/inputs/CustomSlider";
import CustomModal from "../../Reusables/modals/CustomModal";
import {
  Notification,
  NotificationText,
  SubmitButton,
  SubmitButtonContainer,
} from "./styles";

function SetMininReward() {
  const [percent, setPercent] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [user, setUser] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const res = JSON.parse(localStorage.getItem("selectedDevice"));
    const user = JSON.parse(localStorage.getItem("user"));
    //setPercent(res.miningPercentage);
    setUser(user);
    setSelectedDevice(res);
  }, []);

  return (
    <div>
      <CustomModal
        id={12}
        heading="Set Reward Payout"
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
            <div
              style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                padding: "20px",
              }}
            >
              <CustomSlider percent={percent} setPercent={setPercent} />
            </div>
            <Notification>
              <NotificationText>
                Note: This is the proportion of mined WNT that will be sent to
                the device owner while the rest goes to the staff wallet wallet.
              </NotificationText>
            </Notification>
            <SubmitButtonContainer>
              <SubmitButton
                onClick={() => {
                  setRewardPercentage(
                    selectedDevice.deviceId,
                    percent,
                    user.jwt,
                    dispatch
                  );
                }}
              >
                Save
              </SubmitButton>
            </SubmitButtonContainer>
          </div>
        }
      />
    </div>
  );
}

export default SetMininReward;
