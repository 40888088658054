import React, { Component } from 'react';
import ReduxThunk from 'redux-thunk'
import Router from './Router'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import './App.css';
import combineReducers from './Redux/reducers/index';
import { ThemeProvider } from "styled-components";
import {theme} from "./theme"

const store = createStore(combineReducers, {}, applyMiddleware(ReduxThunk))


class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme} >
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
