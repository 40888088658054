import React, { useState } from "react";
import {
  InputTagBox,
  Label,
  SelectedOption,
  DropdownContainer,
  Optionvalues,
  SelectContainer,
  InputText,
  SearchGroupWrap,
  Datalist,
  DropdownOptions,
  NotFound,
} from "./inputStyles";
import { TableActionButton } from "../tablestyles";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchDetails } from "../../../../Redux/actions";
import BeatLoading from "../../loaders/BeatLoading";

export const SelectBox = ({
  item,
  title,
  onDropdownReveal,
  toggleDropdown,
  name,
  disabled,
  options,
  show,
  inputType,
  placeholder,
  onClick,
  icon,
  onChange,
  searchvalue,
  onSelect,
  searchfunction,
  tableId,
  hasOptions,
  condition,
  dataLength,
  onSearchClick,
  datalistOptions,
  datalistVisible,
  setDatalistVisible,
  onSearchTableClick,
  searchvalueText,
  // setSearchValueText,
  gettingDataListOptions,
  dataDeviceModelVisible,
  setDataDeviceModelVisible,
  searchvalueDeviceModel,
  onSearchDeviceModel,
  gettingDataDeviceModelOptions,
  dataDeviceModelOptions,
  searchvalueManufacturer,
  onSearchManufacturer,
  dataManufacturerVisible,
  setDataManufacturerVisible,
  dataManufacturerOptions,
  gettingDataManufacturerOptions,

  setShowCustomDate,
}) => {
  const [value, setValue] = useState(0);
  const [clickedName, setClickedName] = useState("");
  const { activeTab } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [searchvalueContent, setSearchValueContent] = useState('');

  const updateInputValue = (newValue) => {
    setSearchValueContent(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [activeTab]);

  useEffect(()=>{
    if(datalistVisible && searchvalueText === clickedName){
      onSearchClick && onSearchClick();
      setDatalistVisible(false);
      setClickedName("");
    }
    if(dataDeviceModelVisible && searchvalueDeviceModel === clickedName){
      onSearchClick && onSearchClick();
      setDataDeviceModelVisible(false);
      setClickedName("");
    }
    if(dataManufacturerVisible && searchvalueManufacturer === clickedName){
      onSearchClick && onSearchClick();
      setDataManufacturerVisible(false);
      setClickedName("");
    }
  }, [searchvalueText, clickedName, datalistVisible, searchvalueDeviceModel, searchvalueManufacturer ])

  return (
    <SelectContainer className="form-group p-r mb-0">
      {inputType !== "button" && inputType !== "exportButton" &&  inputType !== "exportToEmail" && (
        <Label htmlfor={name}>
          {title}
          {" :"}
        </Label>
      )}
      {inputType === "select" ? (
        <InputTagBox onClick={onDropdownReveal} disabled={disabled}>
          <SelectedOption>
            <p>
              {options
                ? options[value] && options[value].option
                  ? options[value].option
                  : options[0] && options[0].option
                  ? options[0].option
                  : "Create options"
                : "Create options"}
            </p>
          </SelectedOption>
          {!show ? (
            <img src="/assets/chevron-downward.svg" height="5px" alt="" />
          ) : (
            ""
          )}
        </InputTagBox>
      ) : inputType === "textDropdown" ? (
        // user search
        <SearchGroupWrap>
          <div className="search-group">
            <InputText
              type="text"
              placeholder={placeholder}
              value={searchvalueText}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim(); // Trim the input value
                // setSearchValueText(trimmedValue);
                onChange && onChange({target: {value: trimmedValue}}, item.onChangeProp && item.onChangeProp);
              }}
              autoFocus
              customPadding="10px 42px 10px 15px"
              name={`${title}-options`}
              onBlur={() => setDatalistVisible(false)}
            />
            <button className="search-btn" onClick={()=> {onSearchTableClick && onSearchTableClick(); setDatalistVisible(true);}}  disabled={!searchvalueText || !searchvalueText.trim()}>
              {/* <img src="/Assets/search-btn.svg" alt="" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"/></svg>
            </button>
          </div>
          {datalistVisible && 
          <Datalist id={`${title}-options`}>
           {gettingDataListOptions ? (
              <div className="flex-center"><BeatLoading color="#e5b90a" size={10} /></div>
            ) : datalistOptions && datalistOptions.length !== 0 ? (
              datalistOptions.map((each, index) => (
                <DropdownOptions
                  key={index}
                  onClick={() => {
                      // Check if the input contains the "@" symbol to determine if it's an email
                    // const isEmail = searchvalueText.includes('@');
                    // const inputValue = isEmail ? each.email : each.name;
                    setClickedName(each.email);

                     // Update the input value when a suggestion is clicked
                     updateInputValue(each.email);
                    dispatch(
                      getSearchDetails({
                        props: [item.onChangeProp],
                        value: each.email,
                      })
                    );
                  }}
                >
                  {`${each.email}`}
                </DropdownOptions>
              ))
            ) : (
              <NotFound>Not a user</NotFound>
            )}
          </Datalist>}
        </SearchGroupWrap>
      ) : inputType === "textDeviceModel" ? (
        // Device model search
        <SearchGroupWrap>
          <div className="search-group">
            <InputText
              type="text"
              placeholder={placeholder}
              value={searchvalueDeviceModel}
              onChange={(e) =>{
                const trimmedValue = e.target.value.trim(); // Trim the input value
                onChange && onChange({target: {value: trimmedValue}}, item.onChangeProp && item.onChangeProp);
              }}
              autoFocus
              customPadding="10px 42px 10px 15px"
              name={`${title}-options`}
              onBlur={() => setDataDeviceModelVisible(false)}
            />
            <button className="search-btn" onClick={()=> {onSearchDeviceModel && onSearchDeviceModel(); setDataDeviceModelVisible(true);}}  disabled={!searchvalueDeviceModel || !searchvalueDeviceModel.trim()}>
              {/* <img src="/Assets/search-btn.svg" alt="" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"/></svg>
            </button>
          </div>
          {dataDeviceModelVisible && 
            <Datalist id={`${title}-options`}>
              {gettingDataDeviceModelOptions ? (
                <div className="flex-center"><BeatLoading color="#e5b90a" size={10} /></div>
              ) : (
                Array.isArray(dataDeviceModelOptions) && dataDeviceModelOptions.length !== 0 ? (
                  dataDeviceModelOptions
                    .filter((option) => option.name.toLowerCase().includes(searchvalueDeviceModel.toLowerCase()))
                    .map((filteredOption, index) => (
                      <DropdownOptions
                        key={index}
                        onClick={() => {
                          setClickedName(filteredOption.name);
                          dispatch(
                            getSearchDetails({
                              props: [item.onChangeProp],
                              value: filteredOption.name,
                            })
                          );
                        }}
                      >
                        {filteredOption.name}
                      </DropdownOptions>
                    ))
                ) : (
                  <NotFound>Not found</NotFound>
                )
              )}
            </Datalist>
          }
        </SearchGroupWrap>
      ) : inputType === "textDeviceManufacturer" ? (
        // Device manufacturer search
        <SearchGroupWrap>
          <div className="search-group">
            <InputText
              type="text"
              placeholder={placeholder}
              value={searchvalueManufacturer}
              onChange={(e) =>{
                const trimmedValue = e.target.value.trim(); // Trim the input value
                onChange && onChange({target: {value: trimmedValue}}, item.onChangeProp && item.onChangeProp);
              }}
              autoFocus
              customPadding="10px 42px 10px 15px"
              name={`${title}-options`}
              onBlur={() => setDataManufacturerVisible(false)}
            />
            <button className="search-btn" onClick={()=> {onSearchManufacturer && onSearchManufacturer(); setDataManufacturerVisible(true);}}  disabled={!searchvalueManufacturer || !searchvalueManufacturer.trim()}>
              {/* <img src="/Assets/search-btn.svg" alt="" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"/></svg>
            </button>
          </div>
          {dataManufacturerVisible && 
            <Datalist id={`${title}-options`}>
              {gettingDataManufacturerOptions ? (
                <div className="flex-center"><BeatLoading color="#e5b90a" size={10} /></div>
              ) : (
                dataManufacturerOptions && dataManufacturerOptions.length !== 0 ? (
                  // Filter the options based on search criteria
                  dataManufacturerOptions
                    .filter((option) => option.name.toLowerCase().includes(searchvalueManufacturer.toLowerCase()))
                    .map((filteredOption, index) => (
                      <DropdownOptions
                        key={index}
                        onClick={() => {
                          setClickedName(filteredOption.name);
                          dispatch(
                            getSearchDetails({
                              props: [item.onChangeProp],
                              value: filteredOption.name,
                            })
                          );
                        }}
                      >
                        {filteredOption.name}
                      </DropdownOptions>
                    ))
                ) : (
                  <NotFound>Not found</NotFound>
                )
              )}
            </Datalist>
          }
        </SearchGroupWrap>
      ) : inputType === "text" ? (
        <div className="search-group">
          <InputText
            type="text"
            placeholder={placeholder}
            value={searchvalueContent}
            onChange={(e) => {
              setSearchValueContent(e.target.value);
              onChange && onChange(e, item.onChangeProp && item.onChangeProp);
            }}
            autoFocus
            customPadding="10px 42px 10px 15px"
          />
          <button className="search-btn" onClick={onSearchClick} disabled={searchvalueContent ? !searchvalueContent.trim() : true}>
            {/* <img src="/Assets/search-btn.svg" alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"/></svg>
          </button>
        </div>
      ) : inputType === "number" ? (
          <div className="search-group">
            <InputText
              type="number"
              placeholder={placeholder}
              value={searchvalueContent}
              onChange={(e) => {
                setSearchValueContent(e.target.value);
                onChange && onChange(e, item.onChangeProp && item.onChangeProp);
              }}
              autoFocus
              customPadding="10px 42px 10px 15px"
            />
            <button className="search-btn" onClick={onSearchClick} disabled={searchvalueContent ? !searchvalueContent.trim() : true}>
              {/* <img src="/Assets/search-btn.svg" alt="" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z"/></svg>
            </button>
          </div>
      ) : inputType === "startTime" ? ( 
        <InputText
          type="date"
          placeholder={placeholder}
          value={searchvalue}
          onChange={(e) => {
            const selectedDate = e.target.value;
            const selectedYear = new Date(selectedDate).getFullYear();
        
            if (!selectedDate || selectedYear > 2000) {
              onChange && onChange(e, item.onChangeProp && item.onChangeProp);
            }
          }}
          autoFocus
          className="input-date"
        />
      
      ) : inputType === "endTime" ? (
        <InputText
          type="date"
          placeholder={placeholder}
          value={searchvalue}
          onChange={(e) => {
            const selectedDate = e.target.value;
            const selectedYear = new Date(selectedDate).getFullYear();
        
            if (!selectedDate || selectedYear > 2000) {
              onChange && onChange(e, item.onChangeProp && item.onChangeProp);
            }
          }}
          autoFocus
          className="input-date"
        />
      ) : inputType === "customStartTime" ? ( 
        <InputText
          type="date"
          placeholder={placeholder}
          value={searchvalue}
          onChange={(e) => {
            const selectedDate = e.target.value;
            const selectedYear = new Date(selectedDate).getFullYear();
        
            if (!selectedDate || selectedYear > 2000) {
              onChange && onChange(e, item.onChangeProp && item.onChangeProp);
            }
          }}
          autoFocus
          className="input-date"
        />
      
      ) : inputType === "customEndTime" ? (
        <InputText
          type="date"
          placeholder={placeholder}
          value={searchvalue}
          onChange={(e) => {
            const selectedDate = e.target.value;
            const selectedYear = new Date(selectedDate).getFullYear();
        
            if (!selectedDate || selectedYear > 2000) {
              onChange && onChange(e, item.onChangeProp && item.onChangeProp);
            }
          }}
          autoFocus
          className="input-date"
        />
      ) : inputType === "exportButton" ? (
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className={dataLength > 0 ? "export-button" : "export-button inactive"}
          table={tableId}
          filename={tableId}
          sheet="tablexls"
          buttonText={
            <>
              <img
                src="/Assets/dashboardicons/Extra/SVG/export.svg"
                alt=""
                style={{ marginRight: "10px" }}
              />
              {/* <i className="material-icons">{icon} </i> */}
              <span> {title}</span>
            </>
          }
        />
      ) : inputType === "exportToEmail" ? (
        <button className="export-button" onClick={onClick}>
          <>
            <img
              src="/Assets/dashboardicons/Extra/SVG/export.svg"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <span> {title}</span>
          </>
        </button>

      ) : (
        <TableActionButton onClick={onClick}>
          {icon && icon.substring(0, 1) === "/" ? (
            <img src={icon} alt="" style={{ marginRight: "15px" }} />
          ) : (
            <i
              className="material-icons mr-2 ml-2 t-14"
              aria-hidden="true"
              style={{ cursor: "pointer", marginRight: "15px" }}
            >
              {icon}
            </i>
          )}
          {title}
        </TableActionButton>
      )}

      {toggleDropdown ? (
        <DropdownContainer>
          {options &&
            options.map((each, index) => {
              return (
                <Optionvalues
                  key={each.option}
                  onClick={() => {
                    if (!hasOptions) {
                      setValue(index);
                      onSelect &&
                        onSelect(
                          each.option === "All"
                            ? ""
                            : condition === "retain-space"
                              ? each.option
                              : each.option.replace(/\s/g, ""),
                          item.onSelectProp
                        );
                      searchfunction && searchfunction();
                      onDropdownReveal();
                    } else {
                      setValue(index);
                      onSelect && onSelect(each.id, item.onSelectProp);
                    }

                    // when custom is selected show start and end date
                    if (each.option === "Custom") {
                      setShowCustomDate(true);
                    } else {
                      setShowCustomDate(false);
                    }
                    
                  }}
                  choosenOption={value === index ? true : false}
                >
                  {" "}
                  {each.option}
                </Optionvalues>
              );
            })}
        </DropdownContainer>
      ) : (
        ""
      )}
    </SelectContainer>
  );
};
