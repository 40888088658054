import styled from "styled-components";


export const SummaryTitle = styled.p`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.textMd};
  font-weight: ${({ theme }) => theme.textXBold};
`;
export const SubTitle = styled.p`
  color: ${({ theme }) => theme.grey};
  font-weight: ${({ theme }) => theme.textXBold};
  margin-bottom: 0;
`;

export const SummaryCard = styled.div`
  background-color: ${({ theme }) => theme.white} !important;
`;

export const SummaryValue = styled.p`
  color: ${props => (props.darktheme ? props.theme.black : props.theme.blue)};
  font-weight: ${({ theme }) => theme.textXBold};
  font-size: ${({ theme }) => theme.textSm};
  margin-bottom: 0px;
  text-transform: uppercase;
`;

export const LogList = styled.li`
  list-style: disc;
  border-left: 1px solid #5a6169;
  padding-left: 10px;
  margin-bottom: 20px;
`
export const LogDetails = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
`
export const LogStep = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props =>  props.theme.black};
`