import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import SubSidenav from "../Partials/SubSidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import { MainContent } from "../Partials/partials";
import AgentDataUsage from "./AgentDataUsage";

class AllAgentDataUsage extends Component {
  render() {
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle="Agent Data Usage" />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
           <AgentDataUsage />
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  return {
    state,
  };
};
export default connect(MapstateToProps)(AllAgentDataUsage);
