import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTopics } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { Link } from "react-router-dom";


const ListOfAllTopics = () => {

    const dispatch = useDispatch();

    // fetching all topics
    const { allTopics } = useSelector((state) => state.Topics);
    const { gettingAllTopics } = useSelector((state) => state.Loading);
  

    // const actualTopics = allTopics.filter(topic => !topic.toLowerCase().includes('dlq'))
    let actualTopicsRows = [];

    allTopics.map((topic) => {
      const lastPath = topic.split('/').pop();
        return actualTopicsRows.push({
          topics: <p className="mb-0">{lastPath}</p>,
          viewDetail: <Link className="t-center details text-white" to={`/topics/${lastPath}`}> View Detail </Link>
        });
    });

    useEffect(() => {
        dispatch(getAllTopics(false));
    }, []);

    const actualTopicsData = {
        columns: [
            {
                label: "Topics",
                field: "topics",
                sort: "asc",
                width: 150,
            },
            {
                label: "",
                field: "viewDetail",
                sort: "asc",
                width: 100,
            },
            
        ],

        rows: actualTopicsRows,
    };


    return (
        <div class="col-md-6 col-12">
            <div className="row mb-4">
                <div className="col-lg-12 inner-topic">
                    <CustomDataTable
                        data={actualTopicsData}
                        title="List of all the topics"
                        loading={gettingAllTopics}
                        noData={
                            <EmptyCard
                                img="/Assets/emptytransactions.svg"
                                title={"No Topics Available"}
                            // descr={"No Request available at the moment"}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ListOfAllTopics;
