import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, ArrowCard, CardTitle, CardValue, ValueFlex } from "./cards"

const DashboardCard = (props) => {
  const { title, value, link, closesidenav } = props
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 mb-4 w3-animate-bottom">
      <Link to={`${link}`} onClick ={closesidenav}>
        <ArrowCard>
          <CardTitle> {title}</CardTitle>
          <ValueFlex>
            <CardValue> {value}</CardValue>
            {link === '' ? '' : <Icon className="material-icons">chevron_right</Icon>}
          </ValueFlex>
        </ArrowCard>
      </Link>
    </div>
  )
}

export default DashboardCard