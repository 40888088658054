
import _const from "../actions/Types";

const initialState = {
  managerLoading: false,
  infoLoading: false,
  loggingIn: false,
  referalLoading: false,
  gettingData: false,
  settingToken: false,
  settingRate: false,
  editingDevice: false,
  actionDone: false,
  gettingAllDevices: false,
  gettingToken: false,
  tokenatedUsers: false,
  deletingUser: false,
  resettingPassword: false,
  loadingToken: false,
  editingToken: false,
  merchantregistering: false,
  gettingRequests: false,
  fetchingname: false,
  approvingVerification: false,
  decliningVerification: false,
  fetchingAgents: false,
  creatingAgent: false,
  assigningdevice: false,
  editingAgent: false,
  deletingDevice: false,
  deletingToken: false,
  makingWithdrawal: false,
  sendingOtp: false,
  gettingAcctName: false,
  gettingVouchers: false,
  generatingVoucher: false,
  creatingdevice: false,
  gettingSubBusiness: false,
  creatingSubBusiness: false,
  gettingDeviceReport: false,
  gettingAgentReport: false,
  gettingOneAgentReport: false,
  transactionHistory: false,
  registeringUser: false,
  resolvingBvn: false,
  upgradingAccount: false,
  gettingAgentBiz: false,
  gettingIndividualVoucher: false,
  gettingServiceCharge: false,
  creatingCharge: false,
  deletingBeneficiary: false,
  gettingBusinessReport: false,
  loadingSessions: false,
  loadingWithdrawals: false,
  gettingTransactions: false,
  gettingAllBusinesses: false,
  gettingAllAgentBusinesses: false,
  settingTracker: false,
  gettingBusinessDevices: false,
  gettingTrackingInfo: false,
  gettingTransactionoverview: false,
  gettingRemittanceReport: false,
  settingRemittance: false,
  creatingBulkVouchers: false,
  gettinguserGeneratedVouchers: false,
  gettingOutstandingRemits: false,
  gettingVoucherBalance: false,
  gettingCryptoWithdrawal: false,
  approvingDeleting: false,
  gettingBusinessAgents: false,
  gettingAgentDataUsage: false,
  creatingForms: false,
  deactivatingForm: false,
  activatingForm: false,
  gettingDynamicForms: false,
  gettingDynamicFormsReport: false,
  gettingCountries: false,
  creatingCountries: false,
  editingCountries: false,
  gettingoneCountry: false,
  gettingAllAdverts: false,
  approvingAdverts: false,
  rejectingAdverts: false,
  creatingAdvert: false,
  updatingbilling: false,
  creatingsubscriptionplans: false,
  gettingsubscriptionplans: false,
  editingsubscriptionplans: false,
  gettingsubscriptionbylocality: false,
  updatingsubscription: false,
  gettingBusinessToken: false,
  creatingBusinessToken: false,
  gettingUserToken: false,
  gettingalladmintoken: false,
  transferringCredit: false,
  gettingdevicesessions: false,
  gettingOneBusinessAgent: false,
  deletingAgentDevice: false,
  deletingAgent: false,
  deletingForm: false,
  gettingdevicesessionsummary: false,
  gettingvirtualacct: false,
  gettingUsers: false,
  verifyingId: false,
  creatingCommands: false,
  gettingCommands: false,
  deletingCommands: false,
  creatingBatchCommands: false,
  gettingDeviceCommands: false,
  gettingStakeLog: false,
  gettingStakeSummary: false,
  gettingAllWicryptDevices: false,
  gettingUserSpendControl: false,
  creatingUserSpend: false,
  gettingFraudReport: false,
  gettingFraudList: false,
  gettingDailyReports: false,
  creatingGlobalSpend: false,
  creatingglobalSpendControl: false,
  gettingUsersSpendControl: false,
  gettingActivityLogs: false,
  gettingEmailProviders: false,
  updatingProviderStatus: false,
  removingFromList: false,
  updatingNodeProviderStatus: false,
  creatingNodeProviderStatus: false,
  gettingUnremittedRemittance: false,
  initiatingRemittance: false,
  gettingCurrencies: false,
  gettingDailySwitchWalletReport: false,
  creatingCurrencies: false,
  gettingDailyUserReport: false,
  gettingOperations: false,
  creatingOperations: false,
  gettingProposedReward: false,
  acceptingUploads: false,
  rejectingUploads: false,
  exportingReport: false,
  gettingUploads: false,
  approvingUploads: false,
  uploadingRewards: false,
  gettingAllKYCRequests: false,
  gettingVerificationSummary: false,
  updatingBooster: false,
  invitingAdmin: false,
  changingAdmin: false,
  gettingUnstakeSummary: false,
  gettingStakingStatisticsReport: false,
  gettingUpcomingUnstakeReport: false,
  gettingUpcomingUnstakeOverview: false,
  gettingDistributedReward: false,
  gettingRewardDetails: false,
  gettingP2porderConfirmations: false,
  gettingP2PorderCancellations: false,
  gettingSwitchWalletWithdrawal: false,
  gettingSwitchWalletDeposits: false,
  gettingAllTopics: false,
  gettingSubscribersPerTopic: false,
  gettingNoOfMessagesPerSubscriber: false,
  updatingDeadLetterTopic: false,
  gettingNewTrancactionDetails: false,
  gettingP2pDetails: false,
  gettingDeviceDetails: false,
  gettingWhiteListedDevices: false,
  gettingDeviceModels: false,
  gettingDeviceManufacturers: false,
  gettingBoosterCycles: false,
  gettingLockupRequests: false,
  gettingInterestDisbursements: false,
  gettingAccruedInterests: false,
  gettingBoosterCyclesId: false,
  gettingBoosterCyclesUserId: false,
  gettingLockupRequestsId: false,
  gettingLockupRequestsUserId: false,
  gettingAccruedInterestsUserId: false,
  gettingApr: false,
  updatingApr: false,
  gettingBooster: false,
};

const Loading = (state = initialState, action) => {
  switch (action.type) {
    case _const.LOADING_INFO:
      return {
        ...state,
        infoLoading: action.payload,
      };

    case _const.CREATING_BULK_VOUCHERS:
      return { ...state, creatingBulkVouchers: action.payload };

    case _const.DELETING_SERVICE_CHARGE:
      return { ...state, deletingBeneficiary: action.payload };

    case _const.CREATING_SRVICE_CHARGE:
      return { ...state, creatingCharge: action.payload };

    case _const.GETTING_SERVICE_CHARGE:
      return {
        ...state,
        gettingServiceCharge: action.payload,
      };
    case _const.GETTING_ACCT_NAME:
      return { ...state, gettingAcctName: action.payload };

    case _const.LOGGING_USER:
      return {
        ...state,
        loggingIn: action.payload,
      };

    case _const.GETTING_MARKETERS:
      return { ...state, referalLoading: action.payload };

    case _const.GETTING_DATE_RANGE_DATA:
      return { ...state, gettingData: action.payload };

    case _const.LOADING_MANAGER:
      return { ...state, managerLoading: action.payload };

    case _const.GETTING_BENEFICIAIRY:
      return { ...state, settingToken: action.payload };

    case _const.SETTING_RATE:
      return { ...state, settingRate: action.payload, actionDone: action.done };

    case _const.EDITING_DEVICE:
      return {
        ...state,
        editingDevice: action.payload,
        actionDone: action.done,
      };

    case _const.CHANGE_FALSE:
      return { ...state, actionDone: action.payload };

    case _const.GET_ALL_DEVICES:
      return { ...state, gettingAllDevices: action.payload };

    case _const.GETTING_TOKENS:
      return { ...state, gettingToken: action.payload };

    case _const.GETTING_USERS:
      return { ...state, tokenatedUsers: action.payload };

    case _const.DELETING_USER:
      return { ...state, deletingUser: action.payload };

    case _const.RESETTING_PASSWORD:
      return { ...state, resettingPassword: action.payload };

    case _const.EDITING_TOKEN:
      return { ...state, editingToken: action.payload };

    case _const.REGISTERING_MERCHANT:
      return { ...state, merchantregistering: action.payload };

    case _const.GETTING_REQUESTS:
      return { ...state, gettingRequests: action.payload };

    case _const.GETTING_EMAIL:
      return { ...state, fetchingname: action.payload };

    case _const.APPROVING_VERFIFICATION:
      return { ...state, approvingVerification: action.payload };

    case _const.DECLINING_VERFIFICATION:
      return { ...state, decliningVerification: action.payload };

    case _const.FETCHING_ALL_AGENTS:
      return { ...state, fetchingAgents: action.payload };

    case _const.CREATING_AGENT:
      return { ...state, creatingAgent: action.payload };

    case _const.ASSIGNING_DEVICE:
      return { ...state, assigningdevice: action.payload };

    case _const.EDITING_AGENT:
      return { ...state, editingAgent: action.payload };

    case _const.DELETING_DEVICE:
      return { ...state, deletingDevice: action.payload };

    case _const.DELETING_TOKEN:
      return { ...state, deletingToken: action.payload };

    case _const.MAKING_WITHDRAWAL:
      return { ...state, makingWithdrawal: action.payload };

    case _const.SENDING_OTP:
      return { ...state, sendingOtp: action.payload };

    case _const.GETTING_VOUCHERS:
      return { ...state, gettingVouchers: action.payload };

    case _const.CREATING_VOUCHER:
      return { ...state, generatingVoucher: action.payload };

    case _const.CREATING_DEVICE:
      return { ...state, creatingdevice: action.payload };

    case _const.GETTING_SUB_BUSINESS:
      return { ...state, gettingSubBusiness: action.payload };

    case _const.CREATING_SUB_BUSINESS:
      return { ...state, creatingSubBusiness: action.payload };

    case _const.GETTING_DEVICE_REPORT:
      return { state, gettingDeviceReport: action.payload };

    case _const.GETTING_AGENT_REPORT:
      return { ...state, gettingAgentReport: action.payload };

    case _const.GETTING_ONE_AGENT:
      return { ...state, gettingOneAgentReport: action.payload };

    case _const.GETTING_TRANSACTION_HISTORY:
      return { ...state, transactionHistory: action.payload };

    case _const.REGISTERING_USER:
      return { ...state, registeringUser: action.payload };

    case _const.RESOLVING_BVN:
      return { ...state, resolvingBvn: action.payload };

    case _const.UPGRADING_ACCT:
      return { ...state, upgradingAccount: action.payload };

    case _const.GETTING_AGENT_BIZ_SUMMARY:
      return { ...state, gettingAgentBiz: action.payload };

    case _const.GETTING_INDIVIDUAL_VOUCHERS:
      return { ...state, gettingIndividualVoucher: action.payload };

    case _const.GETTING_BUSINESS_REPORT:
      return { ...state, gettingBusinessReport: action.payload };

    case _const.GETTING_BUSINESS_SESSIONS:
      return { ...state, loadingSessions: action.payload };

    case _const.LOADING_WITHDRAWALS:
      return { ...state, loadingWithdrawals: action.payload };

    case _const.GETTING_TRANSACTIONS:
      return { ...state, gettingTransactions: action.payload };

    case _const.GETTING_ALL_BUSINESSES:
      return { ...state, gettingAllBusinesses: action.payload };
    case _const.GETTING_ALL_Agent_BUSINESSES:
      return { ...state, gettingAllAgentBusinesses: action.payload };

    case _const.SETTING_TRACKER:
      return { ...state, settingTracker: action.payload };

    case _const.GETTING_BUSINESS_DEVICES:
      return { ...state, gettingBusinessDevices: action.payload };

    case _const.GETTING_TRACKER_INFO:
      return { ...state, gettingTrackingInfo: action.payload };

    case _const.GETTING_TRANSACTION_OVERVIEW:
      return { ...state, gettingTransactionoverview: action.payload };

    case _const.GETTING_REMITTANCE_REPORT:
      return { ...state, gettingRemittanceReport: action.payload };

    case _const.SETTING_REMITTANCE:
      return { ...state, settingRemittance: action.payload };

    case _const.GETTING_USER_GENERATED_VOUCHERS:
      return { ...state, gettinguserGeneratedVouchers: action.payload };

    case _const.GETTING_OUTSTANDING_REMITTANCE:
      return { ...state, gettingOutstandingRemits: action.payload };

    case _const.GETTING_VOUCHER_BALANCE:
      return { ...state, gettingVoucherBalance: action.payload };

    case _const.GETTING_CRYPT_WITHDRAWAL:
      return { ...state, gettingCryptoWithdrawal: action.payload };

    case _const.APPROVING_DELETING_CRYPTO:
      return { ...state, approvingDeleting: action.payload };

    case _const.GETTING_BUSINESS_AGENTS:
      return { ...state, gettingBusinessAgents: action.payload };

    case _const.GETTING_AGENT_DATA_USAGE:
      return { ...state, gettingAgentDataUsage: action.payload }

    case _const.CREATING_FORM:
      return { ...state, creatingForms: action.payload }

    case _const.DEACTIVATING_FORM:
      return { ...state, deactivatingForm: action.payload }

    case _const.ACTIVATING_FORM:
      return { ...state, activatingForm: action.payload }

    case _const.GETTING_DYNAMIC_FORMS:
      return { ...state, gettingDynamicForms: action.payload }

    case _const.GETTING_DYNAMIC_FORMS_REPORT:
      return { ...state, gettingDynamicFormsReport: action.payload }

    case _const.GETTING_COUNTRIES:
      return{...state, gettingCountries: action.payload}

    case _const.CREATING_COUNTRY:
      return{...state, creatingCountries: action.payload}

    case _const.EDITING_COUNTRY:
      return{...state, editingCountries: action.payload}

    case _const.GETTING_ONE_COUNTRY:
      return{...state, gettingoneCountry: action.payload}

    case _const.GETTING_ALL_ADVERTS:
      return{...state, gettingAllAdverts: action.payload}

    case _const.APPROVING_ADVERT:
      return{...state, approvingAdverts: action.payload}

    case _const.REJECTING_ADVERT:
      return{...state, rejectingAdverts: action.payload}

    case _const.CREATING_ADVERT:
      return{...state, creatingAdvert: action.payload}

    case _const.UPDATING_BILLING_TYPE:
      return{...state, updatingbilling: action.payload}

    case _const.CREATING_SUBSCRIPTION_PLANS:
      return{...state, creatingsubscriptionplans: action.payload}

    case _const.GETTING_SUBSCRIPTION_PLANS:
      return{...state, gettingsubscriptionplans: action.payload}

    case _const.EDITING_SUBSCRIPTION_PLANS:
      return{...state, editingsubscriptionplans: action.payload}

    case _const.GETTING_SUBSCRIPTION_BY_LOCALITY:
      return{...state, gettingsubscriptionbylocality: action.payload}

    case _const.UPDATING_SUBSCRIPTION:
      return{...state, updatingsubscription: action.payload}

    case _const.GETTING_ALL_BUSINESS_TOKEN:
      return{...state, gettingBusinessToken: action.payload}

    case _const.CREATING_BUSINESS_TOKEN:
      return{...state, creatingBusinessToken: action.payload}

    case _const.GETTING_ALL_USER_TOKEN:
      return{...state, gettingUserToken: action.payload}

    case _const.GETTING_ALL_ADMIN_TOKENS:
      return{...state, gettingalladmintoken: action.payload}

    case _const.TRANSFERING_CREDIT:
      return{...state, transferringCredit: action.payload}

    case _const.GETTING_DEVICE_SESIONS:
      return{...state, gettingdevicesessions: action.payload}

    case _const.GETTING_ONE_BUSINESS_AGENT:
      return{...state, gettingOneBusinessAgent: action.payload}

    case _const.DELETING_AGENT_DEVICE:
      return{...state, deletingAgentDevice: action.payload}

    case _const.DELETING_AGENT:
      return{...state, deletingAgent: action.payload}

    case _const.DELETING_FORM:
      return{...state, deletingForm: action.payload}

    case _const.GETTING_DEVICE_SESION_SUMMARY:
      return{...state, gettingdevicesessionsummary: action.payload}

    case _const.GETTING_VIRTUAL_ACCOUNT:
      return{...state, gettingvirtualacct: action.payload}

    case _const.GETTING_ALL_USERS:
      return{...state, gettingUsers: action.payload}

    case _const.VERIFYING_ID_CARD:
      return{...state, verifyingId: action.payload}

    case _const.GETTING_COMMANDS:
      return{...state, gettingCommands: action.payload}

    case _const.CREATING_COMMANDS:
      return{...state, creatingCommands: action.payload}

    case _const.DELETING_COMMAND:
      return{...state, deletingCommands: action.payload}

    case _const.CREATING_BATCH_MODE_COMMAND:
      return{...state, creatingBatchCommands: action.payload}

    case _const.CREATING_SINGLE_MODE_COMMAND:
      return{...state, creatingBatchCommands: action.payload}

    case _const.GETTING_DEVICES_COMMANDS:
      return{...state, gettingDeviceCommands: action.payload}

    case _const.GETTING_STAKE_LOG:
      return{...state, gettingStakeLog: action.payload}

    case _const.GETTING_STAKE_SUMMARY:
      return{...state, gettingStakeSummary: action.payload}

    case _const.GETTING_ALL_WICRYPT_DEVICES:
      return{...state, gettingAllWicryptDevices: action.payload}

    case _const.GETTING_USER_SPEND_CONTROL:
      return{...state, gettingUserSpendControl: action.payload}

    case _const.CREATING_USER_SPEND_CONTROL:
      return{...state, creatingUserSpend: action.payload}

    case _const.GETTING_FRAUD_REPORT:
      return{...state, gettingFraudReport: action.payload}

    case _const.GETTING_FRAUD_LIST:
      return{...state, gettingFraudList: action.payload}

    case _const.GETTING_DAILY_REPORTS:
      return{...state, gettingDailyReports: action.payload}

    case _const.CREATING_GLOBAL_SPEND_CONTROL:
      return{...state, creatingGlobalSpend: action.payload}

    case _const.GETTING_GLOBAL_SPEND_CONTROL:
      return{...state, creatingglobalSpendControl: action.payload}

    case _const.GETTING_USERS_SPEND_CONTROL:
      return{...state, gettingUsersSpendControl: action.payload}

    case _const.GETTING_ACTIVITY_LOGS:
      return{...state, gettingActivityLogs: action.payload}

    case _const.GETTING_PROVIDERS:
      return{...state, gettingEmailProviders: action.payload}

    case _const.UPDATING_PROVIDER_STATUS:
      return{...state, updatingProviderStatus: action.payload}

    case _const.GETTING_NODE_PROVIDERS:
      return{...state, gettingNodeProviders: action.payload}

    case _const.REMOVING_FROM_LIST:
      return{...state, removingFromList: action.payload}

    case _const.UPDATING_NODE_PROVIDER_STATUS:
      return{...state, updatingNodeProviderStatus: action.payload}

    case _const.CREATING_NODE_PROVIDER_STATUS:
      return{...state, creatingNodeProviderStatus: action.payload}

    case _const.GETTING_UNREMITTED_TOKENS:
      return{...state, gettingUnremittedRemittance: action.payload}

    case _const.INITIATING_REMITTANCE_COMMAND:
      return{...state, initiatingRemittance: action.payload}

    case _const.GETTING_CURRENCIES:
      return{...state, gettingCurrencies: action.payload}

    case _const.GETTING_DAILY_SWITCHWALLET_REPORTS:
      return{...state, gettingDailySwitchWalletReport: action.payload}

    case _const.CREATING_CURRENCY:
      return{...state, creatingCurrencies: action.payload}

    case _const.GETTING_DAILY_USER_REPORTS:
      return{...state, gettingDailyUserReport: action.payload}

    case _const.GETTING_OPERATIONS:
      return{...state, gettingOperations: action.payload}

    case _const.CREATING_OPERATION:
      return{...state, creatingOperations: action.payload}

    case _const.GETTING_WEEKLY_MINING_STATS:
      return{...state, gettingWeeklyMiningStats: action.payload}

    case _const.GETTING_PROPOSED_REWARDS:
      return{...state, gettingProposedReward: action.payload}

    case _const.ACCEPTING_UPLOADS:
      return{...state, acceptingUploads: action.payload}

    case _const.REJECTING_UPLOADS:
      return{...state, rejectingUploads: action.payload}

    case _const.EXPORTING_REPORT:
      return{...state, exportingReport: action.payload}

    case _const.GETTING_UPLOADS:
      return{...state, gettingUploads: action.payload}

    case _const.APPROVE_UPLOAD:
      return{...state, approvingUploads: action.payload}

    case _const.UPLOADING_REWARDS:
      return{...state, uploadingRewards: action.payload}

    case _const.GETTING_ALL_KYC_REQUESTS:
      return{...state, gettingAllKYCRequests: action.payload}

    case _const.GETTING_VERIFICATION_SUMMARY:
      return{...state, gettingVerificationSummary: action.payload}

    case _const.UPDATING_BOOSTER:
      return{...state, updatingBooster: action.payload}

    case _const.INVITING_ADMIN:
      return{...state, invitingAdmin: action.payload}

    case _const.CHANGING_ADMIN_ROLE:
      return{...state, changingAdmin: action.payload}

    case _const.GETTING_UNSTAKE_SUMMARY:
      return{...state, gettingUnstakeSummary: action.payload}

    case _const.GETTING_STAKING_STATISTICS_REPORT:
      return{...state, gettingStakingStatisticsReport: action.payload}

    case _const.GETTING_UPCOMING_UNSTAKE_REPORT:
      return{...state, gettingUpcomingUnstakeReport: action.payload}

    case _const.GETTING_UPCOMING_UNSTAKE_OVERVIEW:
      return{...state, gettingUpcomingUnstakeOverview: action.payload}

    case _const.GETTING_DISTRIBUTED_REWARD:
      return{...state, gettingDistributedReward: action.payload}

    case _const.GETTING_DISTRIBUTED_REWARD_DETAILS:
      return{...state, gettingRewardDetails: action.payload}

    case _const.GETTING_P2P_ORDER_CONFIRMATION:
      return{...state, gettingP2porderConfirmations: action.payload}

    case _const.GETTING_P2P_ORDER_CANCELLATIONS:
      return{...state, gettingP2PorderCancellations: action.payload}

    case _const.GETTING_SWITCHWALLET_DEPOSITS:
      return{...state, gettingSwitchWalletDeposits: action.payload}

    case _const.GETTING_SWITCHWALLET_WITHDRAWALS:
      return{...state, gettingSwitchWalletWithdrawal: action.payload}

    case _const.GETTING_ALL_TOPICS:
      return{...state, gettingAllTopics: action.payload}

    case _const.GETTING_SUBSCRIBERS_PER_TOPIC:
      return{...state, gettingSubscribersPerTopic: action.payload}

    case _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER:
      return{...state, gettingNoOfMessagesPerSubscriber: action.payload}

    case _const.UPDATING_DEAD_LETTER_TOPIC:
      return{...state, updatingDeadLetterTopic: action.payload}

    case _const.GETTING_NEW_TRANSACTION_DETAILS:
      return{...state, gettingNewTrancactionDetails: action.payload}
    
    case _const.GETTING_NEW_P2P_DETAILS:
      return{...state, gettingP2pDetails: action.payload}

    case _const.GETTING_DEVICE_DETAILS:
      return{...state, gettingDeviceDetails: action.payload}
    
    case _const.GETTING_WHITELISTED_DEVICES:
      return{...state, gettingWhiteListedDevices: action.payload}

    case _const.GETTING_DEVICE_MODELS:
      return{...state, gettingDeviceModels: action.payload}

    case _const.GETTING_DEVICE_MANUFACTURERS:
      return{...state, gettingDeviceManufacturers: action.payload}

    case _const.GETTING_BOOSTER_CYCLES:
      return{...state, gettingBoosterCycles: action.payload}
      
    case _const.GETTING_LOCKUP_REQUESTS:
      return{...state, gettingLockupRequests: action.payload}
      
    case _const.GETTING_INTEREST_DISBURSEMENTS:
      return{...state, gettingInterestDisbursements: action.payload}
  
    case _const.GETTING_ACCRUED_INTERESTS:
      return{...state, gettingAccruedInterests: action.payload}
    
    case _const.GETTING_BOOSTER_CYCLES_ID:
      return{...state, gettingBoosterCyclesId: action.payload}

    case _const.GETTING_BOOSTER_CYCLES_USER_ID:
      return{...state, gettingBoosterCyclesUserId: action.payload}
      
    case _const.GETTING_LOCKUP_REQUESTS_ID:
      return{...state, gettingLockupRequestsId: action.payload}
      
    case _const.GETTING_LOCKUP_REQUESTS_USER_ID:
      return{...state, gettingLockupRequestsUserId: action.payload}
  
    case _const.GETTING_ACCRUED_INTERESTS_USER_ID:
      return{...state, gettingAccruedInterestsUserId: action.payload}

    case _const.GETTING_APR:
      return{...state, gettingApr: action.payload}

    case _const.UPDATING_APR:
      return{...state, updatingApr: action.payload}
    
    case _const.GETTING_BOOSTER:
      return{...state, gettingBooster: action.payload}
    
    default:
      return state;
  }
};

export default Loading;
