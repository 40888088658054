import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { clearSearch, getAdminTransactions, getSearchDetails, getUsers } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import { newStakingColumns } from "../../utils/TableCols/NewTransactionsCols";
import { newStakingOptions } from "./options";

const NewStaking = () => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const {adminTransactions,gettingAdminTransactions} = useSelector((state) => state.Transactions);
  const { allusers } = useSelector((state) => state.Admin);
  const { startTime, endTime, Search, Page, PageSize, usersType } = useSelector((state) => state.Search);
  const { gettingUsers} = useSelector((state) => state.Loading);
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
  );
  const data = {
    columns: newStakingColumns,
    rows: allRows,
  };

  // This is to get all the users 
  const fetchAllUsers = () => {
    const queries = {Search};
    getUsers(dispatch, queries, usersType);
  }

  let availableUsers = allusers ? allusers.records : [];

  const transactionCategory = "staking";

  const fetchAdminTransactions = () => {
    const queries = {
      StartDate: startTime,
      EndDate: endTime,
      SearchParam: Search,
      Page,
      PageSize
    };
    getAdminTransactions(dispatch, transactionCategory, queries);
  }

  useEffect(() => {
    fetchAdminTransactions();
  }, [ startTime, endTime, Page, PageSize ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(Search === ""){
      fetchAdminTransactions();
    }
  },[Search])

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              title={`Stake History`}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  // subHeaderTypes={transactionsHeaderOptionTypes}
                  dataLength={adminTransactions.records ? adminTransactions.records.length : 0}
                  subHeader={newStakingOptions}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchTableClick={fetchAllUsers}
                  searchvalueText={Search}
                  datalistOptions={availableUsers}
                  gettingDataListOptions={gettingUsers}
                  onSearchClick={fetchAdminTransactions}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStaking;
