import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import SetToken from './SetToken'
import Beneficiaries from './Beneficiaries'


class AllSettings extends Component{
    render() {
     const{clickedToken} = this.props
      return (
         <div className="row">
         <Sidenav/>
             <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                 <div className="main-navbar sticky-top bg-white">
                     <Mainnav/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4">
                   {clickedToken ?  <Beneficiaries/> :  <SetToken/> }
                 </div>
             </main>
        </div>
      );
    }
  }

  const MapstateToProps = (state) =>{
    const{clickedToken}= state.Manager
    return{
      clickedToken
    }
  }
export default connect(MapstateToProps)( AllSettings)