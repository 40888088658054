// These are columns of the new transaction page

export const newTransferColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Sender",
    field: "sender",
    sort: "asc",
    width: 170,
  },
  {
    label: "Receiver",
    field: "receiver",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "Fee",
  //   field: "fee",
  //   sort: "asc",
  //   width: 100,
  // },
  {
    label: "Amount",
    field: "transferAmount",
    sort: "asc",
    width: 270,
  },
  {
    label: "Total Chargeable Amount",
    field: "totalChargeableAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Fee Remitted",
    field: "isFeeRemitted",
    sort: "asc",
    width: 70,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const newp2pColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Type",
    field: "type",
    sort: "asc",
    width: 70,
  },
  {
    label: "Customer",
    field: "customerInfo",
    sort: "asc",
    width: 170,
  },
  {
    label: "Buy Amount",
    field: "buyAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Sell Amount",
    field: "sellAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const newStakingColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "userName",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "userEmail",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "newTrancAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Duration",
    field: "lockPeriod",
    sort: "asc",
    width: 170,
  },
  {
    label: "Type",
    field: "stakeType",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const newMiningRewardColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "rewardWeek",
    sort: "asc",
    width: 170,
  },
  {
    label: "Device Name",
    field: "miningDeviceName",
    sort: "asc",
    width: 170,
  },
  {
    label: "BSSID",
    field: "miningBssid",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "newTrancAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const disbursedStakingRewardColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "Total disbursed rewards",
    field: "totalDisbursedRewards",
    sort: "asc",
    width: 170,
  },
  {
    label: "Total impacted users",
    field: "totalImpactedUsers",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const newStakingInterestClaimsColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "userName",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "userEmail",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "newTrancAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const newDisbursedStakingWeekColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "userName",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "userEmail",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "newTrancAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const cryptoWithdrawalColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Sender",
    field: "sender",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "cryptoAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Fee Remitted",
    field: "isFeeRemitted",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Hash",
    field: "transactionHash",
    sort: "asc",
    width: 170,
  },
  {
    label: "Destination Wallet Address",
    field: "destinationWalletAddress",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];

export const cryptoDepositColumns = [
  {
    label: "Date",
    field: "stakeDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Receiver",
    field: "receiver",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amountCreditedToWallet",
    sort: "asc",
    width: 170,
  },
  {
    label: "Fee Remitted",
    field: "isFeeRemitted",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Hash",
    field: "transactionHash",
    sort: "asc",
    width: 170,
  },
  {
    label: "Wallet Address",
    field: "address",
    sort: "asc",
    width: 170,
  },
  {
    label: "Depositor Wallet Address",
    field: "depositorWalletAddress",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 70,
  },
];