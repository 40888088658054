import React from "react";
import { InputTag, Label, SelectedOption, SelectErr, Icon, RightIcon, ScrollDropdownContainer, Optionvalues } from "./inputstyles"


export const AddSelect = ({ title, onDropdownReveal, onOptionSelect, toggleDropdown, name, value, disabled, err, required, options }) => {
    return (
        <div className="form-group p-r mb-0">
            
            <Label htmlfor={name}>{title}{required ? <span className="text-red"> *</span> : ""}</Label>
            <InputTag
                onClick={onDropdownReveal}
                disabled={disabled}
               
            >
                <RightIcon className="material-icons" >add_circle</RightIcon>
                <SelectedOption  className="ml-4">
                    {value}
                </SelectedOption>
                <Icon className="material-icons" >expand_more</Icon>
            </InputTag>
            <SelectErr>{err}</SelectErr>
            {toggleDropdown ?
                <ScrollDropdownContainer>
                    {options.map(each => {
                        return (
                            <Optionvalues key={each.id} onClick={()=>onOptionSelect(each)}> {each.name}</Optionvalues>
                        )
                    })}
                </ScrollDropdownContainer> : ""}
        </div>
    )
}

