let localPlatforms = [
  "localhost",
  "sandbox.wicrypt.com",
  "enterscale.wicrypt.com",
  "admin-stg.wicrypt.com",
];
let platform = localPlatforms.includes(window.location.hostname)
  ? "local"
  : "production";
let settings = {};

if (platform === "local") {
  settings = {
    apiUrl: "https://stage.wicrypt.com/api",
    apiCalc: "https://stgapi-calculator.wicrypt.com/api",
    adminApiUrl: "https://admin-api-stg.wicrypt.com/api",
    notifApiUrl: "https://notification-stg.wicrypt.com/api",
    polygonexplorerUrl: "https://mumbai.polygonscan.com",
    subect: "Wicrypt",
    baseUrl: "",
  };
} else {
  settings = {
    apiUrl: "https://api.wicrypt.com/api",
    adminApiUrl: "https://admin-api.wicrypt.com/api",
    notifApiUrl: "https://notification.wicrypt.com/api",
    polygonexplorerUrl: "https://polygonscan.com",
    apiCalc: "https://api-calculator.wicrypt.com/api",
    appName: "Wicrypt",
    subect: "Wicrypt",
    baseUrl: "",
  };
}

export default settings;
