import React, {Component} from "react";
import CustomModal from "../Reusables/modals/CustomModal";
import {ActionButton} from "../Reusables/inputs";
import {getAccount, copyToClipboard} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import {FundBox, FundTitle, SpanTitle, SpanValue, Funddescription, Wallettitle, AccountNumber} from "./walletstyles"
import { connect } from "react-redux";;




class FundWallet extends Component {

    componentWillMount() {
        this.props.getAccount(this.props.toggleSubnav === 1  || this.props.toggleSubnav === 2 ?  "Personal": "Business");
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.toggleSubnav !== this.props.toggleSubnav){
            this.props.getAccount(nextProps.toggleSubnav === 1  || nextProps.toggleSubnav === 2 ?  "Personal": "Business");
        }
    }

    render(){
        const {merchantAccount, merchantAccountName, bankName, toggleSubnav, copied, gettingvirtualacct} = this.props

        return (
            <CustomModal 
                id={1}
                close={true}
                heading={<div> 
                            {toggleSubnav === 1 || this.props.toggleSubnav === 2 ? <p >Fund Personal Wallet </p>: <p > Fund Business Wallet </p>} 
                            <Funddescription>  
                                Fund your wicrypt {toggleSubnav === 1 || this.props.toggleSubnav === 2 ? "personal wallet" : "business wallet"}  by transferring money directly to the bank account below
                            </Funddescription>
                        </div>}
                content={gettingvirtualacct? 
                <Loader /> :
                <React.Fragment>
                <FundBox>
                    {merchantAccount === 'N/A' ? 
                    <FundTitle className="text-red">
                   You can only fund your wallet after verifying your account
                </FundTitle>
                :
                <React.Fragment>

                    <Wallettitle> Account Details</Wallettitle>
                    <div className = 'd-flex mb-3'>
                        <div> 
                            <SpanTitle>ACCOUNT NUMBER</SpanTitle>
                            <AccountNumber>{" "} {merchantAccount}</AccountNumber>
                        </div>
                        <div> 
                            <ActionButton title={copied === merchantAccount ? "Copied" : "Copy"} img={'/Assets/images/copy.svg'} buttonAction={()=> this.props.copyToClipboard(merchantAccount)}/> 
                        </div>
                    </div>
                    <div className='d-flex'>
                    <SpanTitle>
                     BANK NAME
                     <SpanValue>{" "}{bankName}</SpanValue>
                    </SpanTitle>
                    <SpanTitle>
                    ACCOUNT NAME
                    <SpanValue> {" "}{merchantAccountName}</SpanValue>
                    </SpanTitle>
                    </div>
                    </React.Fragment>}
                    </FundBox>
                    {/* <Select
                        title={"Choose wallet to fund"}
                        onClick={() => console.log('hello')}
                        name={"Wallet to fund"}
                        value={''}
                        disabled={false}
                        err={''}
                    />
                    <BigInput
                        title="Click to enter amount"
                        type="number"
                        onChange={(e) =>
                            this.props.getFormDetails({
                              props: ["fundAmt"],
                              value: e.target.value,
                            })
                          }
                        value={fundAmt} />
                    <Select
                        title={"Payment Method"}
                        onClick={() => console.log('hello')}
                        name={"paymentMethod"}
                        value={''}
                        disabled={false}
                        err={''}
                    />
                    <ContinueBtn title="Continue" /> */}
                </React.Fragment>} />
        )
    }
  
}


const MapstateToProps = (state) => {
    const { fundAmt, copied} = state.User;
    const {merchantAccount, merchantAccountName, bankName} = state.Agent;
    const{toggleSubnav} = state.General;
    const {gettingvirtualacct} = state.Loading
    return {
        fundAmt,
        merchantAccount,
        merchantAccountName, 
        bankName,
        toggleSubnav,
        copied,
        gettingvirtualacct
    };
};


export default connect(MapstateToProps, {getAccount, copyToClipboard})(FundWallet)