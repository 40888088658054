import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { getFormDetails, editAgent } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";

class EditAgent extends Component {
  render() {
    const { allAgents, editingAgent, agentRole, clickedDeviceId } = this.props;

    let agent =
      allAgents.length > 0
        ? allAgents.find((agent) => {
          return agent.user.id === clickedDeviceId;
        })
        : "";

    return (
      <Modal heading={`CHANGE  ${agent.user.name}'s ROLE`}>
        <label> Role</label>
        <select
          className="form-control loginCred"
          onChange={(e) =>
            this.props.getFormDetails({
              props: ["agentRole"],
              value: e.target.value,
            })
          }
        >
          <option value="Agent"> Agent</option>
        </select>

        <Button
          theme=" ml-3 mt-2 float wicryptColor"
          buttonAction={() =>
            this.props.editAgent(clickedDeviceId, { role: agentRole })
          }
        >
          {editingAgent ? <Loader /> : "Enter"}
        </Button>
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const { agentRole, clickedDeviceId } = state.User;
  const { editingAgent } = state.Loading;
  const { allAgents } = state.Agent;
  return {
    allAgents,
    editingAgent,
    agentRole,
    clickedDeviceId,
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  editAgent,
})(EditAgent);
