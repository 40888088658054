import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllServiceCharge,
  getFormDetails,
  toggleModal,
  createServiceCharge,
  deleteServiceCharge,
} from "../../Redux/actions";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import {BeneficiaryDescription, BeneficiaryValue, ServiceValue, EmptyChargeDescription, EmptyChargeTitle,  } from './servicestyles'

class SetRate extends Component {
  componentWillMount() {
    this.props.getAllServiceCharge();
  }

  render() {
    const {
      serviceBeneficiaries,
      clickedId,
      chargeErr,
      funct,
      gettingServiceCharge,
      creatingCharge,
      deletingBeneficiary,
      beneficiaryEmail,
      percentage,
    } = this.props;

    var beneficiary = serviceBeneficiaries;
    return (
      <div className="row">
        <Modal
          heading={funct === "edit" ? "Edit Charge" : "ASSIGN SERVICE CHARGE"}
        >
          <div className="col-lg-12">
            <label> Email</label>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control loginCred"
                aria-describedby="basic-addon1"
                value={
                  funct === "edit"
                    ? beneficiary.beneficiary.email
                    : beneficiaryEmail
                }
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["beneficiaryEmail"],
                    value: e.target.value,
                  })
                }
              />
            </div>
            <label>Percentage</label>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control loginCred"
                aria-describedby="basic-addon1"
                value={percentage}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["percentage"],
                    value: e.target.value,
                  })
                }
              />
            </div>
            {chargeErr !== "" ? <p className="errorMsgs">{chargeErr}</p> : ""}
            <Button
              theme="btn-primary ml-3 mt-2 float wicryptColor"
              buttonAction={() =>
                this.props.createServiceCharge(
                  percentage,
                  funct === "edit"
                    ? beneficiary.beneficiary.email
                    : beneficiaryEmail
                )
              }
            >
              {creatingCharge ? <Loader /> : "Assign charge"}
            </Button>
          </div>
        </Modal>
        {serviceBeneficiaries.id === undefined & !gettingServiceCharge ?
        // <div className="col-lg-11 card mb-3">
        //  <div
        //   className="row"
        // >
        //   <div className="col-lg-12">
        //     <div className="col-lg-7"> 
        //       <ServiceHeading> Create Service Charge</ServiceHeading>
        //       <HeadingDescription>Create a service charge for you beneficiary</HeadingDescription> 
        //     </div>
        //     <div className="col-lg-3">
            <div className="details"  onClick={() => this.props.toggleModal(true)}>
              <i
                className="fa fa-plus mr-2"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                {" "}
              
            </i>
            Create charge beneficiary
            </div>
        //     </div>
        //   </div>
          
        // </div>
        // </div>
        :"" }
        <div className="col-lg-12" />
        {serviceBeneficiaries.id !== undefined ? (
          <div className="offset-lg-3 col-lg-5 mb-4">
            <div className="card card-small mb-4 p-3">

              <div className="h-100">
                <div className="card-header d-flex">
                  <h6 className="m-0 text-bold">
                    
                  </h6>
                  <div className="pointer">
                    {deletingBeneficiary ? (
                      clickedId === serviceBeneficiaries.id ? (
                        <i
                          className="fas fa-spinner fa-spin"
                          aria-hidden="true"
                        />
                      ) : (
                          <i
                            className="fa fa-trash"
                            onClick={() =>
                              this.props.deleteServiceCharge(
                                serviceBeneficiaries.id
                              )
                            }
                            aria-hidden="true"
                          />
                        )
                    ) : (
                        <i
                          className="fa fa-trash "
                          onClick={() =>
                            this.props.deleteServiceCharge(
                              serviceBeneficiaries.id
                            )
                          }
                          aria-hidden="true"
                        />
                      )}
                  </div>
                </div>
                <div className="card-body d-flex py-0 jc border-bottom">
                  <div> 
                    <BeneficiaryDescription> Name</BeneficiaryDescription>{" "}
                    {" "}
                    <BeneficiaryValue>
                      {serviceBeneficiaries.beneficiary.name}
                    </BeneficiaryValue>{" "}
                   
                  </div>
                  {/* <div>
                    {" "}
                    <i className="material-icons overviewVal t3">email</i>{" "}
                  </div> */}
                </div>
                <div className="card-body d-flex py-0 jc border-bottom">
                  <div>
                    <BeneficiaryDescription> Email</BeneficiaryDescription>{" "}
                    {" "}
                    <BeneficiaryValue>
                      {serviceBeneficiaries.beneficiary.email}
                    </BeneficiaryValue>{" "}
                  </div>
                </div>
                <div className="card-body d-flex py-0">
                  <div>
                    <BeneficiaryDescription> Service Charge</BeneficiaryDescription>{" "}
                    <ServiceValue>
                      {" "}
                      {serviceBeneficiaries.percentage}%
                    </ServiceValue>{" "}
                  </div>
                  {/* <div>
                    {" "}
                    <i className="material-icons overviewVal t3">
                    mdi-sack-percent 
                    </i>{" "}
                  </div> */}
                </div>
                {/* <div className="card-body t-center jc mt-3"> */}
                  {/* <button
                    disabled
                    className={
                      serviceBeneficiaries.beneficiary.verificationStatus ===
                        "UNVERIFIED"
                        ? "Pending"
                        : "Approved"
                    }
                  >
                    {serviceBeneficiaries.beneficiary.verificationStatus}{" "}
                  </button> */}
                  {/* <div>
                    <button
                      className="details"
                      onClick={() =>
                        this.props.toggleModal(
                          true,
                          serviceBeneficiaries.id,
                          "edit"
                        )
                      }
                    >
                      <i className="fa fa-edit" aria-hidden="true" />
                      Edit{" "}
                    </button>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        ) : (
            <div className="volucherNote">
              {gettingServiceCharge ? (
                <Loader size={10} />
              ) : (
                <div className="t-center">
                  <img src="/Assets/images/service.svg" height="70px" className="mb-2" alt="service"/>
                  <EmptyChargeTitle>
                    No Service Charge
                  </EmptyChargeTitle>
                  <EmptyChargeDescription>
                    {" "}
                    You have no service charge beneficiary, Create a service to have it display here
                  </EmptyChargeDescription>
                </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { serviceBeneficiaries, clickedId, chargeErr } = state.Service;
  const {
    beneficiaryEmail,
    percentage,
    deviceAddress,
    deviceConId,
    clickedDeviceId,
    funct,
  } = state.User;
  const {
    settingRate,
    editingDevice,
    actionDone,
    gettingServiceCharge,
    deletingBeneficiary,
    creatingCharge,
  } = state.Loading;
  return {
    serviceBeneficiaries,
    settingRate,
    editingDevice,
    actionDone,
    gettingServiceCharge,
    creatingCharge,
    deletingBeneficiary,
    beneficiaryEmail,
    percentage,
    deviceAddress,
    deviceConId,
    clickedId,
    clickedDeviceId,
    funct,
    chargeErr,
  };
};

export default connect(
  MapStateToProps,
  {
    getAllServiceCharge,
    getFormDetails,
    createServiceCharge,
    toggleModal,
    deleteServiceCharge,
  }
)(SetRate);
