import React, { Component } from "react";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  Select,
  BigInput,
  ContinueBtn,
  LayeredInput,
} from "../Reusables/inputs";
import { OptionTitle, OptionSubTitle, Err } from "./walletstyles";
import {
  getFormDetails,
  transferwicryptcredit,
  getByemail,
  handleModal,
} from "../../Redux/actions";
import { connect } from "react-redux";
import { numberWithCommas, validateamount, validateemail } from "../../Helper";
import Loader from "../Reusables/loaders/MoonLoader";
import { FundBox, FundTitle } from "./walletstyles";

class Transfer extends Component {
  handleselect(value) {
    const { toggleDropdown } = this.props;
    this.props.getFormDetails({
      props: ["toggleDropdown"],
      value: !toggleDropdown,
    });
    this.props.getFormDetails({
      props: ["selectedwalletoption"],
      value: value,
    });
  }
  handlesubmit() {
    const {
      transferrecipientemail,
      transferAmt,
      selectedwalletoption,
      balance,
      businessBalance,
      currencyValue,
    } = this.props;
    if (transferAmt === 0 || !validateamount(transferAmt)) {
      this.props.getFormDetails({
        props: ["transferAmtError"],
        value: "please enter a valid amount",
      });
    } else if (
      (selectedwalletoption === "Personal") &
      (transferAmt > balance * currencyValue)
    ) {
      this.props.getFormDetails({
        props: ["transferAmtError"],
        value:
          "You cannot transfer an amount greater than your personal balance",
      });
    } else if (
      (selectedwalletoption === "Business") &
      (transferAmt > businessBalance * currencyValue)
    ) {
      this.props.getFormDetails({
        props: ["transferAmtError"],
        value:
          "You cannot transfer an amount greater than your business balance",
      });
    } else if (
      transferrecipientemail === "" ||
      !validateemail(transferrecipientemail)
    ) {
      this.props.getFormDetails({
        props: ["transferEmailError"],
        value: "please enter a valid email",
      });
    } else {
      this.props.getFormDetails({
        props: ["transferAmtError"],
        value: "",
      });
      this.props.getFormDetails({
        props: ["transferEmailError"],
        value: "",
      });
      this.props.getByemail(transferrecipientemail);
    }
  }
  handlepin() {
    const {
      selectedwalletoption,
      logginInUser,
      userpin,
      transferAmt,
      currencyValue,
    } = this.props;
    if (userpin === "" || userpin.length !== 4) {
      this.props.getFormDetails({
        props: ["transferPinError"],
        value: "please enter your 6 digit pin",
      });
    } else {
      this.props.getFormDetails({
        props: ["transferPinError"],
        value: "",
      });
      this.props.transferwicryptcredit(
        selectedwalletoption,
        logginInUser.id,
        transferAmt / currencyValue,
        userpin
      );
    }
  }

  render() {
    const {
      transferAmt,
      role,
      transferrecipientemail,
      walletoptions,
      personaloptions,
      toggleDropdown,
      selectedwalletoption,
      transferError,
      transferPinError,
      balance,
      currencyValue,
      businessBalance,
      transferEmailError,
      fetchingname,
      transferAmtError,
      transferringCredit,
      logginInUser,
      userpin,
    } = this.props;
    let options = role.includes("Business") ? walletoptions : personaloptions;
    return (
      <React.Fragment>
        <CustomModal
          id={6}
          heading=""
          close={true}
          content={
            <React.Fragment>
              <div className="stats-small stats-small--1 card card-small">
                <div className="card-body d-flex mb-5">
                  <div className="d-flex flex-column m-auto">
                    <img
                      src="/Assets/check.svg"
                      className="mt-5"
                      width="100%"
                      height="70px"
                      alt="success"
                    />
                    <p className="text-green mt-4 mb-5"> Transfer Successful</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        />
        <CustomModal
          id={4}
          heading={
            <React.Fragment>
              {" "}
              <i
                onClick={() => this.props.handleModal(3)}
                className="fa fa-arrow-left pointer"
                aria-hidden="true"
              ></i>{" "}
              Confirm Transfer{" "}
            </React.Fragment>
          }
          close={true}
          content={
            <React.Fragment>
              <FundBox>
                <FundTitle>
                  You are about to transfer{" "}
                  {"NGN " + numberWithCommas(transferAmt)} to{" "}
                  {logginInUser.name} with email address {logginInUser.email}
                </FundTitle>
                <div className="d-flex">
                  <ContinueBtn
                    width={"150px"}
                    color="grey"
                    title={transferringCredit ? <Loader /> : "Close"}
                    onClick={() => this.props.handleModal(0)}
                  />
                  <ContinueBtn
                    width={"150px"}
                    title={transferringCredit ? <Loader /> : "Continue"}
                    onClick={() => this.props.handleModal(5)}
                  />
                </div>
              </FundBox>
            </React.Fragment>
          }
        />
        <CustomModal
          id={5}
          heading={
            <React.Fragment>
              {" "}
              <i
                onClick={() => this.props.handleModal(4)}
                className="fa fa-arrow-left pointer"
                aria-hidden="true"
              ></i>{" "}
              Confirm Transfer{" "}
            </React.Fragment>
          }
          close={true}
          content={
            <React.Fragment>
              <LayeredInput
                placeholder=""
                title="Pin"
                description="Enter your pin"
                value={userpin}
                type="password"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["userpin"],
                    value: e.target.value,
                  })
                }
                maxlength={4}
                err={transferPinError}
              />
              <Err> {transferError}</Err>
              {transferringCredit ? (
                <ContinueBtn title={<Loader />} />
              ) : (
                <ContinueBtn
                  title="Continue"
                  onClick={() => this.handlepin()}
                />
              )}
            </React.Fragment>
          }
        />
        <CustomModal
          heading="Transfer"
          id={3}
          close={true}
          content={
            <React.Fragment>
              <Select
                title={
                  role.includes("Business")
                    ? "Choose wallet to transfer from"
                    : "Transfer from your personal wallet"
                }
                onOptionSelect={this.handleselect.bind(this)}
                name={"transferwallet"}
                value={
                  <div>
                    <OptionTitle> {selectedwalletoption}</OptionTitle>
                    <OptionSubTitle>
                      {" "}
                      {selectedwalletoption === "Personal"
                        ? "NGN " + numberWithCommas(balance * currencyValue)
                        : "NGN " +
                          numberWithCommas(businessBalance * currencyValue)}
                    </OptionSubTitle>
                  </div>
                }
                disabled={false}
                err={""}
                show={!role.includes("Business") ? true : false}
                options={options}
                toggleDropdown={toggleDropdown}
                onDropdownReveal={() =>
                  this.props.getFormDetails({
                    props: ["toggleDropdown"],
                    value: !toggleDropdown,
                  })
                }
              />
              <BigInput
                title="Click to enter amount"
                type="number"
                value={transferAmt}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["transferAmt"],
                    value: e.target.value,
                  })
                }
                err={transferAmtError}
              />
              <LayeredInput
                placeholder="example@email.com"
                title="Email Address"
                description="Email addresss of the recepient"
                value={transferrecipientemail}
                // err={emailFail}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["transferrecipientemail"],
                    value: e.target.value,
                  })
                }
                err={transferEmailError}
              />
              {fetchingname ? (
                <ContinueBtn title={<Loader />} />
              ) : (
                <ContinueBtn
                  title="Continue"
                  onClick={() => this.handlesubmit()}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const MapstateToProps = (state) => {
  const {
    transferAmt,
    transferrecipientemail,
    walletoptions,
    toggleDropdown,
    transferPinError,
    role,
    personaloptions,
    selectedwalletoption,
    balance,
    currencyValue,
    businessBalance,
    userpin,
    logginInUser,
    emailFail,
    transferAmtError,
    transferEmailError,
  } = state.User;
  const { transferringCredit, fetchingname } = state.Loading;
  const { transferError } = state.Wallet;
  return {
    transferAmt,
    transferrecipientemail,
    walletoptions,
    toggleDropdown,
    selectedwalletoption,
    balance,
    currencyValue,
    businessBalance,
    transferringCredit,
    userpin,
    logginInUser,
    fetchingname,
    emailFail,
    transferError,
    transferAmtError,
    transferEmailError,
    transferPinError,
    personaloptions,
    role,
  };
};

export default connect(MapstateToProps, {
  getFormDetails,
  transferwicryptcredit,
  getByemail,
  handleModal,
})(Transfer);
