import React from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import CreateForm from './createform'
import SubSidenav from '../Partials/SubSidenav'
import { MainContent } from '../Partials/partials'


const DynamicForm = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Router Form" />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <CreateForm />
        </div>
      </MainContent>
    </div>
  );
}

export default DynamicForm