import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Fund History
export const getFundHistory = (
  transactionCategory,
  page,
  pageSize,
  businessId,
  userId
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_TRANSACTION_HISTORY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/transactions/all?businessId=${businessId}&userId=${userId}&transactionCategory=${transactionCategory}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_TRANSACTION_HISTORY,
            payload: false,
          });
          dispatch({
            type: _const.FUND_HISTORY,
            payload: res.data.data,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: _const.GETTING_TRANSACTION_HISTORY,
          payload: false,
        })
      );
  };
};

export const clearHistory = () => {
  return (dispatch) => {
    dispatch({
      type: _const.CLEAR_TRANSACTION_HISTORY,
      payload: true,
    });
  };
};

export const clearRemittance = () => {
  return (dispatch) => {
    dispatch({
      type: _const.CLEAR_REMITTANCE_REPORT,
      payload: true,
    });
  };
};

//Get Device Summary per daterange
export const getDeviceSummary = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_DEVICE_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/users/devices/summary/by?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_REPORT,
          payload: false,
        });
        if (res.data.errors.length === 0) {
          dispatch({
            type: _const.DEVICE_SUMMARY,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_REPORT,
          payload: false,
        });
      });
  };
};

//Get agent device report per date range
export const agentReport = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_AGENT_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/users/summary?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_AGENT_REPORT,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.AGENT_SUMMARY,
            payload: res.data.data,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: _const.GETTING_AGENT_REPORT,
          payload: false,
        })
      );
  };
};

//Get one agent report
export const oneagentReport = (startDate, endDate, email) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ONE_AGENT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/users/summary/byEmail?startDate=${startDate}&endDate=${endDate}&email=${email}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_ONE_AGENT,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.ONE_AGENT_REPORT,
            payload: res.data.data,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: _const.GETTING_ONE_AGENT,
          payload: false,
        })
      );
  };
};
//Get business report summary for admin
export const businessReport = (businessId) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BUSINESS_REPORT,
      payload: true,
    });
    let reports = `/v1/businesses/${businessId}`;
    axios
      .get(config.adminApiUrl + reports)
      .then((res) => {
        dispatch({
          type: _const.GETTING_BUSINESS_REPORT,
          payload: false,
        });
        dispatch({
          type: _const.BUSINESS_REPORT_SUMMARY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BUSINESS_REPORT,
          payload: false,
        });
      });
  };
};

//Get business session summary for admin
export const businessSessions = (
  businessId,
  isActive,
  startDateTimestamp,
  endDateTimestamp,
  deviceId,
  page,
  pageSize,
  role,
  dispatch
) => {
  let sessions = `/v2/reports/admin/sessions?businessId=${businessId}&isActive=${isActive}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&deviceId=${deviceId}&page=${page}&pageSize=${pageSize}`;
  dispatch({
    type: _const.GETTING_BUSINESS_SESSIONS,
    payload: true,
  });
  axios
    .get(config.apiUrl + sessions)
    .then((res) => {
      // dispatch(clearForms());
      dispatch({
        type: _const.GETTING_BUSINESS_SESSIONS,
        payload: false,
      });
      dispatch({
        type: _const.BUSINESS_SESSIONS_SUMMARY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_BUSINESS_SESSIONS,
        payload: false,
      });
    });
};

export const allSessions = (
  businessId,
  isActive,
  startDateTimestamp,
  endDateTimestamp,
  deviceId,
  page,
  pageSize,
  role
) => {
  let sessions = `/v2/reports/admin/sessions?businessId=${businessId}&isActive=${isActive}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&deviceId=${deviceId}&page=${page}&pageSize=${pageSize}`;
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BUSINESS_SESSIONS,
      payload: true,
    });
    axios
      .get(config.apiUrl + sessions)
      .then((res) => {
        // dispatch(clearForms());
        dispatch({
          type: _const.GETTING_BUSINESS_SESSIONS,
          payload: false,
        });
        dispatch({
          type: _const.BUSINESS_SESSIONS_SUMMARY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BUSINESS_SESSIONS,
          payload: false,
        });
      });
  };
};

//Get remittance summary for business
export const businessRemittance = (
  agentId,
  startDateTimestamp,
  endDateTimestamp,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_REMITTANCE_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/voucherRemittance/Business?agentId=${agentId}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
        dispatch({
          type: _const.REMITTANCE_REPORT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
      });
  };
};

//Get remittance summary for agent
export const agentRemittance = (
  businessId,
  startDateTimestamp,
  endDateTimestamp,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_REMITTANCE_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/voucherRemittance/Agent?businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
        dispatch({
          type: _const.REMITTANCE_REPORT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
      });
  };
};

//Get remittance summary for admin
export const adminRemittance = (
  businessId,
  startDateTimestamp,
  endDateTimestamp,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_REMITTANCE_REPORT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/voucherRemittance/Admin?businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
        dispatch({
          type: _const.REMITTANCE_REPORT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_REMITTANCE_REPORT,
          payload: false,
        });
      });
  };
};

//Set remittance rate
export const setRemittance = (businessId, amountInNGN, conversionsRate) => {
  return (dispatch) => {
    if (Number.isInteger(Number(amountInNGN)) === true) {
      let data = {
        businessId: businessId,
        amountInWC: Number(amountInNGN) / conversionsRate,
      };
      dispatch({
        type: _const.SETTING_REMITTANCE,
        payload: true,
      });
      axios
        .post(
          config.apiUrl + `/v2/voucherRemittance/RemittanceAllowance/Business`,
          data
        )
        .then((res) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMIT_SUCCESS,
            payload: true,
          });
          if (res.data.success) {
            Swal.fire({
              title: "Default remit rate set successfully!",
              type: "success",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMITTANCE_FAIL,
            payload:
              err.response.data !== undefined
                ? err.response.data.message
                : "Something went wrong",
          });
        });
    } else {
      dispatch({
        type: _const.REMITTANCE_FAIL,
        payload: "Amount to remit should be in Naira!",
      });
    }
  };
};

//Set remittance rate for an agent
export const setAgentRemittance = (
  businessId,
  amountInNGN,
  conversionsRate,
  userId
) => {
  return (dispatch) => {
    if (userId === "" || userId === undefined) {
      dispatch({
        type: _const.REMITTANCE_FAIL,
        payload: "You must select an agent",
      });
    } else if (Number.isInteger(Number(amountInNGN)) === true) {
      let data = {
        businessId: businessId,
        amountInWC: Number(amountInNGN) / conversionsRate,
        userId: userId,
      };
      dispatch({
        type: _const.SETTING_REMITTANCE,
        payload: true,
      });
      axios
        .post(
          config.apiUrl + `/v2/voucherRemittance/RemittanceAllowance/Agent`,
          data
        )
        .then((res) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMIT_SUCCESS,
            payload: true,
          });
          if (res.data.success) {
            Swal.fire({
              title: "Remit rate set successfully!",
              type: "success",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMITTANCE_FAIL,
            payload:
              err.response.data !== undefined
                ? err.response.data.message
                : "Something went wrong",
          });
        });
    } else {
      dispatch({
        type: _const.REMITTANCE_FAIL,
        payload: "Amount to remit should be in Naira!",
      });
    }
  };
};

//Record remittance rate for an agent
export const recordAgentRemittance = (
  remmitanceDateTimeSTamp,
  amountInNGN,
  conversionsRate,
  userId
) => {
  return (dispatch) => {
    if (userId === "" || userId === undefined) {
      dispatch({
        type: _const.REMITTANCE_FAIL,
        payload: "You must select an agent",
      });
    } else if (Number.isInteger(Number(amountInNGN)) === true) {
      let data = {
        remmitanceDateTimeSTamp: remmitanceDateTimeSTamp,
        amount: Number(amountInNGN) / conversionsRate,
        userId: userId,
      };
      dispatch({
        type: _const.SETTING_REMITTANCE,
        payload: true,
      });
      axios
        .post(config.apiUrl + `/v2/voucherRemittance`, data)
        .then((res) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMIT_SUCCESS,
            payload: true,
          });
          if (res.data.success) {
            Swal.fire({
              title: "Remit recorded successfully!",
              type: "success",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.SETTING_REMITTANCE,
            payload: false,
          });
          dispatch({
            type: _const.REMITTANCE_FAIL,
            payload:
              err.response.data !== undefined
                ? err.response.data.message
                : "Something went wrong",
          });
        });
    } else {
      dispatch({
        type: _const.REMITTANCE_FAIL,
        payload: "Amount to remit should be in Naira!",
      });
    }
  };
};

//Get all outstanding remiitance
export const outstandingRemittance = (
  businessUserId,
  startDateTimestamp,
  endDateTimestamp,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_OUTSTANDING_REMITTANCE,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/voucherRemittance/Business/OutstandingRemittance?businessUserId=${businessUserId}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_OUTSTANDING_REMITTANCE,
          payload: false,
        });

        dispatch({
          type: _const.OUTSTANDING_REMITTANCE_REPORT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_OUTSTANDING_REMITTANCE,
          payload: false,
        });
      });
  };
};

//get agent list
export const getbusinessagents = (businessId, page, pageSize) => {
  let sessions = `/v2/reports/admin/agents?businessId=${businessId}&page=${page}&pageSize=${pageSize}`;
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_BUSINESS_AGENTS,
      payload: true,
    });
    axios
      .get(config.apiUrl + sessions)
      .then((res) => {
        dispatch({
          type: _const.GETTING_BUSINESS_AGENTS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_BUSINESS_AGENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_BUSINESS_AGENTS,
          payload: false,
        });
      });
  };
};

//Business Agent Data Usage
export const getAgentDataUssage = (
  businessId,
  startDateTimestamp,
  endDateTimestamp,
  searchParam,
  dispatch
) => {
  dispatch({
    type: _const.GETTING_AGENT_DATA_USAGE,
    payload: true,
  });
  axios
    .get(
      config.apiUrl +
        `/v2/reports/businessAgentDataUsage?businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}&searchParam=${searchParam}`
    )
    .then((res) => {
      dispatch({
        type: _const.GETTING_AGENT_DATA_USAGE,
        payload: false,
      });
      dispatch({
        type: _const.BUSINESSAGENT_DATA_USAGE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_AGENT_DATA_USAGE,
        payload: false,
      });
    });
};
