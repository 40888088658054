import _const from "./Types";
//import imageCompression from "browser-image-compression";

export const changeActive = id => {
  return {
    type: _const.CHANGE_ACTIVE,
    id
  };
};

//Toggle Modal
export const toggleModal = (payload, id, funct) => {
  return dispatch => {
    // if(payload==false){
    //     dispatch(clearForms())
    // }
    dispatch({
      type: _const.MODAL_STATE,
      payload,
      id,
      funct
    });
  };
};

//Toggle Modal
export const handleModal = payload => {
  return dispatch => {
    dispatch({
      type: _const.TOGGLE_MODAL,
      payload
    });
  };
};

export const handleSubNav = payload => {
  return dispatch => {
    dispatch({
      type: _const.TOGGLE_SUBNAV,
      payload
    });
  };
};

export const clearForms = () => {
  return dispatch => {
    dispatch({
      type: _const.CLEAR_FORMS,
      payload: true
    });
  };
};

//Toggle Nav
export const toggleNav = payload => {
  return {
    type: _const.TOGGLE_NAV,
    payload
  };
};

export const clearData = () => {
  return {
    type: _const.CLEAR_DATA,
    payload: true
  };
};

export const saveId = payload => {
  return {
    type: _const.SAVE_BUSINESS_ID,
    payload
  };
};

//Change Action
export const changeToFalse = payload => {
  return dispatch => {
    dispatch({
      type: _const.CHANGE_FALSE,
      payload
    });
  };
};

//File upload
export const uploadFile = data => {
  return dispatch => {
    dispatch({
      type: _const.UPLOAD_IMAGE_FILE,
      value: data,
      payload: URL.createObjectURL(data.value)
    });
  };
};

export const handleImageChange = e => {
  return dispatch => {
    dispatch({
      type: _const.FILE_TO_BASE,
      payload: e.target.files[0]
    });

    // var options = {
    //   maxSizeMB: 2,
    //   maxWidthOrHeight: 1920,
    //   useWebWorker: true,
    // };
    // imageCompression(file, options)
    //   .then(function (compressedFile) {
    //     reader.readAsDataURL(compressedFile);
    //     reader.onloadend = () => {
    //       dispatch({
    //         type: _const.FILE_TO_BASE,
    //         payload: reader.result,
    //       });
    //     };
    //   })
    //   .catch(function (error) { });
  };
};

//Gets all Form Inputs
export const getFormDetails = payload => {
  return dispatch => {
    if (payload.props === "merchantLogo") {
      dispatch({
        type: _const.CREATE_IMAGE_PREVIEW,
        payload: URL.createObjectURL(payload.value),
        value: payload
      });
    }
    dispatch({
      type: _const.FILL_OUT_FORM,
      payload: payload
    });
  };
};

export const emptyForm = payload => {
  return dispatch => {
    dispatch({
      type: _const.EMPTY_FORM,
      payload: payload
    });
  };
};

//Open and close eye
export const handlePinReveal = payload => {
  return dispatch => {
    dispatch({
      type: _const.EYE_STATUS,
      payload
    });
  };
};

//Copy text
export const copyToClipboard = copyText => {
  return dispatch => {
    const textField = document.createElement("textarea");
    textField.innerText = copyText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    dispatch({
      type: _const.TEXT_COPIED,
      payload: copyText
    });
  };
};

// Toggles Edit Profile Information
export const editUserInfo = (payload, value) => {
  return {
    type: _const.EDIT_USERINFO,
    payload,
    fund: value
  };
};


// clear search 
export const clearSearch = () => {
  return dispatch => {
    dispatch({
      type: _const.CLEAR_SEARCH
    })
  }
}

export const clearModal = () => {
  return dispatch => {
    dispatch({
      type: _const.CLEAR_MODAL
    })
  }
}

// remove error messages
export const clearReplayError = () => {
  return {
    type: _const.CLEAR_REPLAY_ERROR,
    payload: true
  };
};