import React from "react";
import { SubmitButton, SubmitButtonContainer } from "../../Devices/modals/styles";
import Loader from "../../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../../Reusables/modals/CustomModal";

function RemitTokenModal({ onSubmit, setActiveError, updatingProviderStatus, walletAddress }) {

  return (
      <div>
          <CustomErrorModal
              id={86}
              heading="Initiate Token Remittance Status"
              subHeading={`Are you sure you want to remit this wallet Address - ${walletAddress}`}
              close={true}
              content={
                  <>
                    {setActiveError ? <p className="errorMsgs mt-3 t-center mb-0">{setActiveError}</p> : ""}
                    <SubmitButtonContainer onClick={onSubmit}>
                        <SubmitButton>{updatingProviderStatus ? <Loader /> : "Remit Token"}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default RemitTokenModal;
