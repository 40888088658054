export const adminHeaderOptions = [
  {
    id: 1,
    title: "Role",
    type: "select",
    onSelectProp: "role",
    options: [
      { id: 1, option: "All" },
      { id: 2, option: "SuperAdmin" },
      { id: 3, option: "Treasurer" },
      { id: 4, option: "Oracle" },
      { id: 5, option: "Admin" },
      { id: 6, option: "Technical" },
      { id: 7, option: "Agent" },
      { id: 8, option: "Operations" },
    ],
  },
  {
    id: 2,
    type: "text",
    title: "Search",
    placeholder: "Name/Email",
    onChangeProp: "search",
  },
];

export const adminColumn = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 270,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 270,
  },
  {
    label: "Role",
    field: "role",
    sort: "asc",
    width: 150,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 150,
  },
];
