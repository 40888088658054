import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../Redux/actions";
import {
  changeTransactionType,
  getAdminTransactions,
} from "../../Redux/actions/TransactionsAction";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import EmptyCard from "../Reusables/cards/EmptyCard";
import {
  transactionsOptions,
  transactionsHeaderOptionTypes,
  transactionsCommonColumns,
  miningRewardColumns,
  cryptoColumns,
  p2pColumns,
  miningRewardsOptions,
  p2pOptions,
  transferOptions,
} from "../UserDetails/options";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import { useState } from "react";

const UserTransactionsReport = ({ userId }) => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");
  const {
    adminTransactions,
    transactionType,
    gettingAdminTransactions,
  } = useSelector((state) => state.Transactions);
  const {
    TransactionType,
    TransferType,
    duration,
    Status,
    Page,
    PageSize,
    WalletType,
    ReferenceCode,
  } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "admin",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab
  );
  const data = {
    columns:
      activeTab === 2
        ? cryptoColumns
        : activeTab === 3
        ? p2pColumns
        : activeTab === 5
        ? miningRewardColumns
        : transactionsCommonColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  const fetchAdminTransactions = () => {
    const queries = {
      TransactionType,
      TransferType,
      Duration: duration,
      Status,
      Page,
      PageSize,
      WalletType,
      ReferenceCode,
      UserId: userId,
    };
    getAdminTransactions(dispatch, transactionType, queries);
  }

  useEffect(() => {
    fetchAdminTransactions();
  }, [
    transactionType,
    TransferType,
    TransactionType,
    duration,
    Status,
    Page,
    PageSize,
    WalletType,
    // ReferenceCode,
  ]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  subHeaderTypes={transactionsHeaderOptionTypes}
                  subHeader={
                    activeTab === 5
                      ? miningRewardsOptions
                      : activeTab === 3
                      ? p2pOptions
                      : activeTab === 4
                      ? transferOptions
                      : transactionsOptions
                  }
                  ontabClick={(type) => {
                    dispatch(clearSearch());
                    dispatch(changeTransactionType(type));
                  }}
                  onClick={() => {
                    dispatch(toggleModal(true));
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={ReferenceCode}
                  onSearchClick={fetchAdminTransactions}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTransactionsReport;
