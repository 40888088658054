import React from "react";
import { Link } from "react-router-dom";
import Button from "../buttons/Button";
import Loader from "../loaders/MoonLoader";
import { connect } from "react-redux";
import { getFormDetails } from "../../../Redux/actions";

const ForgotCard = props => {
  const { heading, logIn, logging } = props;
  return (
    <div className="col-lg-6 offset-lg-3">
      <div className="card card-small card-post card-post--1">
        <img className="logo" src="/Assets/wicrypt.svg" alt="" />
        <div className="card-body pt-0">
          <div className="row w3-animate-bottom">
            <div className="col-lg-6 offset-lg-3 fade-in">
              <div className="t-center p-10 mt-5">
                <h5 className="card-title cardHeading">{heading}</h5>
                <p className="wicryptmsg t-14 mb-5">
                  {" "}
                  Enter your Wicrypt email, and we’ll send you instructions on
                  how to reset your PIN.
                </p>
              </div>
              <label> Email address</label>
              <div className="input-group ">
                <input
                  type="email"
                  className="form-control loginCred"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={e =>
                    props.getFormDetails({
                      props: ["loginEmail"],
                      value: e.target.value
                    })
                  }
                />
              </div>
              <div className="loginBtn mt-5 mb-5">
                <Button
                  buttonAction={logIn}
                  theme="clickLogin mb-2 mr-1 bold loginbutton"
                >
                  {logging ? <Loader /> : "Send me instructions"}
                </Button>
              </div>
              <div className="t-center mt-3 mb-5">
                <Link to="/" className="text-blue bold t-16">
                  Back to Log in{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img className="sideWave" src="/Assets/Intersection 1.png" alt="" />
        <img className="leftsideWave" src="/Assets/Intersection 2.png" alt="" />
      </div>
    </div>
  );
};

const MapStateToProps = state => {
  return { state };
};
export default connect(
  MapStateToProps,
  { getFormDetails }
)(ForgotCard);
