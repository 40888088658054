import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { batchModeCommand, handleModal } from "../../Redux/actions";
import CustomModal from "../Reusables/modals/CustomModal";
import {
    NotificationText,
    ModeOption
} from "./styles";

const IssueCommandOptions = () => {

    const dispatch = useDispatch();


    const { commandId } = useSelector((state) => state.User);


    return (
        <div>
            <CustomModal
                id={52}
                heading="Issue Command Mode"
                close={true}
                content={
                    <div
                        style={{
                            width: "532px",
                        }}
                    >
                        <ModeOption onClick={() => dispatch(handleModal(53)) } >
                            <NotificationText>
                                Single Mode
                            </NotificationText>
                        </ModeOption>
                        <ModeOption onClick={() => { dispatch(batchModeCommand(commandId)); dispatch(handleModal(0)) }}>
                            <NotificationText>
                                Batch Mode
                            </NotificationText>
                        </ModeOption>


                    </div>
                }
            />
        </div>
    );
};

export default IssueCommandOptions;
