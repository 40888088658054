import styled from "styled-components";
import { theme, device } from "../../../theme";

export const Label = styled.label`
  color: ${({ theme }) => theme.grey};
  padding-bottom: 10px;
  font-size: 0.6rem;
`;
export const BigInputLabel = styled.p`
  color: ${({ theme }) => theme.opaquegrey};
  padding: 20px;
  font-size: 0.7rem;
  text-align: center;
  font-weight: ${({ theme }) => theme.textBold};
  margin-bottom: 0;
`;
export const InputTag = styled.div`
  background: #ffffff;
  border-radius: 4px;
  min-height: 36px;
  font-weight: 400;
  // margin-bottom: 20px;
  cursor: pointer;
  position: relative;
`;

export const InputContainer = styled.div`
  background: #ffffff;
  border-radius: 4px;
  min-height: 36px;
  font-weight: 400;
  // margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding: 5px;
`;

export const SelectedOption = styled.p`
  padding: 10px;
  border-bottom: 1px solid #d2cbcb;
`;

export const SelectErr = styled.p`
  font-size: ${theme.textXs};
  color: red;
  margin-bottom: 0;
  padding: 5px;
  // position: absolute;
`;
export const Icon = styled.i`
  color: ${({ theme }) => theme.yellow};
  position: absolute !important;
  top: 30% !important;
  right: 3% !important;
`;
export const RightIcon = styled.i`
  color: ${({ theme }) => theme.yellow};
  position: absolute !important;
  top: 30% !important;
  left: 3% !important;
`;
export const InputBox = styled.div`
  background: #ffffff;
  border-radius: 4px;
  height: 120px;
  font-weight: 400;
  position: relative;
  text-align: center;
  margin-bottom: 1rem;
`;
export const InputValue = styled.input`
  border: none;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.black};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.black};
  }
  :active {
    border-bottom: none !important;
  }
  :focus {
    border-bottom: none !important;
  }
  @media ${device.tablet} {
    font-size: 25px;
  }
`;
export const CurrencyValue = styled.p`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.textXs};
`;
export const ContinueButton = styled.button`
  background-color: ${(props) =>
    props.color ? props.color : theme.blue} !important;
  border-radius: 40px !important;
  color: ${({ theme }) => theme.white} !important;
  width: ${(props) => (props.width ? "100px" : "100%")} !important;
  border: none;
  @media ${device.tablet} {
    width: ${(props) => (props.width ? props.width : "100%")} !important;
  }
`;
export const ButtonContainer = styled.div`
  margin: 20px 0px;
`;
export const ActionBtnContainer = styled.div`
  border-radius: 36px;
  padding: 5px;
  background: ${(props) =>
    props.active ? theme.yellow : "rgba(255, 255, 255, 0.08)"};
  color: ${(props) => (props.active ? theme.white : "#CFA809")};
  font-size: 10px;
  cursor: pointer;
  // min-width: 76px;
  border: 1.1727px solid #cfa809;
  text-align: center;
  margin: 10px;
  z-index: 100;
  @media ${device.tablet} {
    min-width: 100px;
  }
`;
export const DropdownContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
  position: absolute;
  cursor: pointer;
  min-width: 250px;
  top: 70%;
  padding: 20px;
  z-index: 100000;
  box-shadow: 1px 1px 1px 1px #b7b7b7;
`;
export const ScrollDropdownContainer = styled.div`
    border-radius: 8px;
    background: ${({ theme }) => theme.white};
    // position: absolute;
    cursor: pointer;
    min-width: 150px;
    height: 100px;
    overflow-y: scroll !important;
    // top: 70%;
    padding: 20px;
    z-index: 100000;
    box-shadow: 1px 1px 1px 1px #b7b7b7;]

`;
export const Optionvalues = styled.p`
  border-bottom: 1px solid #6b6b6b;
  font-size: 0.7rem;
  margin-bottom: 0.8rem;
`;
export const InputDescription = styled.p`
  font-size: ${({ theme }) => theme.textXs};
  margin-bottom: 8px;
`;
export const LayeredInputBox = styled.input`
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #D2CBCB !important;
    margin-bottom: 8px;
    height: 20px !important;
    padding-left: 0 !important;
    font-size: ${({ theme }) => theme.textXs} !important;
    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${({ theme }) => theme.grey};
        font-size: ${({ theme }) => theme.textXs} !important;
        padding-left: 0 !important;
    }
    :-ms-input-placeholder {
        color: ${({ theme }) => theme.grey};
        font-size: ${({ theme }) => theme.textXs} !important;
        padding-left: 0 !important;
    }
    :active{
        border-bottom: 1px solid #344684; !important;
    }
    :focus{
        border-bottom: 1px solid #344684; !important;
    }
`;

export const Center = styled.div``;
export const Slider = styled.div`
  width: 100%;
  height: 100%;
`;
export const ShowValue = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
`;
export const CurrentValue = styled.span`
  position: absolute;
  width: 45px;
  height: 48px;
  width: 67px;
  border-radius: 48px;
  padding: 12px 16px 12px 16px;
  background: #e5b910;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  left: 50%;
  transform: translateX(-50%) scale(1);
  transform-origin: bottom;
  //   transition: transform 300ms ease-in-out;
  left: ${(props) => props.left};

  //   &.active {
  //     transform: translate(-50%) scale(1);
  //   }
`;
export const Customslider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #e2e4e8;
  border-radius: 3px;
  outline: none;
  border: none;
  background: #e5b910;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 28px;
    width: 28px;
    border-radius: 100px;
    background: #e5b910;
    cursor: pointer;
  }
`;
export const Range = styled.span`
  margin-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: space-between;

  div {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #6b6b6b;
  }

  div:nth-child(2) {
    right: 0;
  }
`;

export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  label {
    padding-bottom: 26.25px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.black};
  }
`;

export const PinBox = styled.input`
      height: 50px;
          width: 50px;
         text-align: center;
         color: ${({ theme }) => theme.blue};
         font-size: 70px;
         background: #f5f5f5;
         border: 1.02px solid #25346a;
         border-radius: 7.00977px;
       `;
