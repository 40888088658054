import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import * as options from "./options";
import TableHeader from "../Reusables/tables/TableHeader";
import { activityLogCols } from "../../utils/TableCols/DeviceCols";
import { getDeviceLogs } from "../../utils/TableRows/DevicesRows";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceActivityLogs } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { clearSearch } from "../../Redux/actions/GeneralAction";


const DeviceActivityLogs = ({deviceId}) => {
    const dispatch = useDispatch();
    const { Page, ActivityType, Name } = useSelector((state) => state.Search);
    const { deviceLogs, deviceLogsCurrent, deviceLogsTotalPages } = useSelector((state) => state.Devices)
    const { gettingActivityLogs } = useSelector((state) => state.Loading);

    const allRows = getDeviceLogs(deviceLogs, deviceId, dispatch, 6);
    const data = {
        columns: activityLogCols,
        rows: allRows,
    };

    useEffect(() => {
        dispatch(clearSearch());
    }, []);
    useEffect(() => {
        dispatch(getDeviceActivityLogs(deviceId, Page, 30, ActivityType))
    }, [Page, deviceId, ActivityType]);
    return (
        <div className="row">
            <div className="col-lg-12 mb-4">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            pageCount={deviceLogsTotalPages}
                            currentPage={deviceLogsCurrent}
                            header={
                                <TableHeader
                                    subHeader={options.activityTypeOptions}
                                    onChange={(e, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );
                                    }}
                                    searchvalue={Name}
                                />
                            }
                            loading={gettingActivityLogs}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"No activity logs"}
                                // descr={"No Live Sessions available at the moment"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceActivityLogs;
