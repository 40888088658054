import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFundHistory,
  clearHistory,
  getFormDetails,
} from "../../Redux/actions";
import moment from "moment";
import CustomLoader from "../Reusables/loaders/CustomLoader";
// import { numberWithCommas } from "../../Helper";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { withRouter } from "react-router-dom";
import TableHeader from "../Reusables/tables/TableHeader";
import { activitylogsOptions } from "./options";

class ActivityLogs extends Component {
  componentWillMount() {
    const { allBusinessTransactions, selectedUserId } = this.props;
    if (allBusinessTransactions === 0) {
      this.props.getFundHistory("", 1, 50, "", selectedUserId);
    }
  }

  customPagination() {
    const {
      totalBusinessTransactionPages,
      currentBusinessTransactionPage,
      fundCategory,
      selectedUserId,
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== currentBusinessTransactionPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getFundHistory(
              fundCategory,
              currentBusinessTransactionPage - 1,
              50,
              "",
              selectedUserId
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; totalBusinessTransactionPages >= i; i++) {
      if (i === currentBusinessTransactionPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getFundHistory(fundCategory, i, 50, "", selectedUserId)
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (totalBusinessTransactionPages > currentBusinessTransactionPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getFundHistory(
              fundCategory,
              currentBusinessTransactionPage + 1,
              50,
              "",
              selectedUserId
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      fundHistory,
      transactionHistory,
      // currencyValue,
      // selectedUserId,
    } = this.props;

    let allRows = [];
    fundHistory.map((transc) => {
      return allRows.push({
        timestamp:
          moment(transc.createdAt).format("L") +
          " | " +
          moment(transc.createdAt).format("LT"),
        ipAdress: "291.121.202.51",
        location: "Nigeria",
        device: "Iphone 13pro",
        actionType: "Transaction",
        action: "User funded N2000 to buisness wallet via crypto",
      });
    });

    const data = {
      columns: [
        {
          label: "Timestamp",
          field: "timestamp",
          sort: "asc",
          width: 170,
        },
        {
          label: "IP Address",
          field: "ipAddress",
          sort: "asc",
          width: 170,
        },
        {
          label: "Location",
          field: "location",
          sort: "asc",
          width: 170,
        },
        {
          label: "Device",
          field: "device",
          sort: "asc",
          width: 170,
        },
        {
          label: "Action Type",
          field: "action",
          sort: "asc",
          width: 170,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            {transactionHistory ? (
              <div className="col-lg-12 mb-4">
                <CustomLoader />{" "}
              </div>
            ) : transactionHistory & (fundHistory.length === 0) ? (
              <p> </p>
            ) : (
              <div className="col-lg-12 mb-4">
                <CustomDataTable
                  data={data}
                  pagination={this.customPagination()}
                  tableId={"report-table"}
                  header={
                    <TableHeader
                      subHeader={activitylogsOptions}
                      onClick={() => this.props.toggleModal(true)}
                      onChange={(e, props) => {
                        this.props.getFormDetails({
                          props: [props],
                          value: e.target.value,
                        });
                      }}
                      onSelect={(val, props) => {
                        this.props.getFormDetails({
                          props: [props],
                          value: val,
                        });
                      }}
                      // searchvalue={tokenSearch}
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  let selectedUserId = props.match.params.userId;
  const {
    fundHistory,
    totalBusinessTransactionPages,
    currentBusinessTransactionPage,
    allBusinessTransactions,
  } = state.Report;
  const { businessProfile, currencyValue, fundCategory } = state.User;
  const { transactionHistory } = state.Loading;
  return {
    fundHistory,
    businessProfile,
    transactionHistory,
    currencyValue,
    totalBusinessTransactionPages,
    currentBusinessTransactionPage,
    allBusinessTransactions,
    fundCategory,
    selectedUserId,
  };
};

export default withRouter(
  connect(MapStateToProps, { getFundHistory, clearHistory, getFormDetails })(
    ActivityLogs
  )
);
