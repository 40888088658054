import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesByCommand, getFormDetails, getAllDevices, getSearchDetails, } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { SelectDevice } from "../Reusables/selects/selectDevice";
import {HoverList} from "./style"
import TableHeader from "../Reusables/tables/TableHeader";


const DevicesByCommands = () => {

    const dispatch = useDispatch();

    const { devicedByCommand, totalDevicePages, currentDevicePage } = useSelector((state) => state.RemoteCommand);
    const { gettingDeviceCommands} = useSelector((state) => state.Loading);
    const { commandStatus, commandDevice} = useSelector((state) => state.User);
    const { page, deviceName, deviceCommandStatus } = useSelector((state) => state.Search);
    const { allDevices } = useSelector((state) => state.Devices);

    const id = window.location.pathname.split("/").at(-1);


    let allRows = [];
    devicedByCommand.map((each) => {
        return allRows.push({
            deviceName: each.deviceName,
            commandName: each.commandName,
            timesIssued: each.issuedCommandCount,
            timesExecuted: each.executedCommandCount,
            status: <div
                className={each.hasPendingCommand ? "Pending p-2 t-center" : "Approved p-2 t-center"}
            >
                {each.hasPendingCommand ? "Pending" : "Completed"}
            </div>,
        });
    });

    const data = {
        columns: [
            {
                label: "Device Name",
                field: "deviceName",
                sort: "asc",
                width: 170,
            },
            {
                label: "Command",
                field: "commandName",
                sort: "asc",
                width: 170,
            },
            {
                label: "Number of times issued",
                field: "timesIssued",
                sort: "asc",
                width: 170,
            },
            {
                label: "Number of times executed",
                field: "timesExecuted",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };

    const deviceComandOptions = [
        // {
        //   id: 1,
        //   title: "Status",
        //   type: "select",
        //   onSelectProp: "deviceCommandStatus",
        //   options: [
        //     {
        //       id: 1,
        //       option: "Command Status",
        //       value: "",
        //     },
        //     {
        //       id: 2,
        //       option: "Pending",
        //       value: "true",
        //     },
        //     {
        //       id: 3,
        //       option: "Completed",
        //       value: "false",
        //     },
        //   ],
        // },
        {
          id: 3,
          title: "Search",
          type: "text",
          placeholder: "Enter Search here",
          onChangeProp: "deviceName",
        },
      ];

    const fetchDevicesByCommand = () => {
        dispatch(getDevicesByCommand(id, deviceName, commandStatus, page, 30))
    }
    
    useEffect(() => {
        fetchDevicesByCommand();
    }, [commandStatus, id, page]);

     // This is to refresh the page when the search input is empty
    useEffect(()=>{
        if(deviceName === ""){
            fetchDevicesByCommand();
        }
    },[deviceName])

    useEffect(() => {
        dispatch(getAllDevices("", commandDevice, "", "", "", "", "", 1, 50));
    }, [commandDevice]);
  
    const handleClick = (device) =>{
        dispatch(getFormDetails({ props: ['commandDevice'], value: device.deviceName }))
    }


    return (
        <div className=" mb-4">
            <div className="card card-small mb-4 w3-animate-bottomd">
                <div className="row">
                    <div className="col-lg-12 p-3">
                        <div className="col-lg-6"></div>
                        {/* <div className="col-lg-3 fl-right">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                <input type="text"
                                value={commandDevice}
                                className="input-sm form-control"
                                placeholder="Search Device" onChange={(e) =>
                                    dispatch(getFormDetails({
                                        props: ["commandDevice"],
                                        value: e.target.value,
                                    }))
                                } />
                                    {commandDevice && <HoverList><SelectDevice allDevices={allDevices} handleClick={handleClick}/></HoverList>}
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-3 fl-right">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <select onChange={(e) => dispatch(getFormDetails({ props: ['commandStatus'], value: e.target.value }))}
                                        name="status" id="status" className="form-control">
                                        <option value="" >Command Status</option>
                                        <option value="true" >Pending</option>
                                        <option value="false">Completed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <CustomDataTable
                        loading={gettingDeviceCommands}
                        data={data}
                        pageCount={totalDevicePages}
                        currentPage={currentDevicePage}
                        header={
                            <TableHeader
                              subHeader={deviceComandOptions}
                              onChange={(e, props) => {
                                dispatch(
                                  getSearchDetails({
                                    props: [props],
                                    value: e.target.value,
                                  })
                                );
                              }}
                              onSelect={(val, props) => {
                                dispatch(
                                  getSearchDetails({
                                    props: [props],
                                    value: val,
                                  })
                                );
                              }}
                              onSearchClick={fetchDevicesByCommand}
                            />
                          }
                        noData={
                            <EmptyCard
                                img="/Assets/emptytransactions.svg"
                                title={`No devices meet the requirements for this command`}
                            />
                        }
                    />
                </div>
            </div>

        </div>
    );
};

export default DevicesByCommands;
