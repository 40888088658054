import styled from "styled-components";
import { theme } from "../../theme";

export const ProfileTitle = styled.p`
  font-size: ${theme.textXs};
  margin-bottom: 0;
`;
export const ProfileValue = styled.p`
  font-size: ${theme.textSm};
  color: ${theme.grey};
  font-weight: bold;
`;
export const BigblueText = styled.p`
  font-size: ${theme.textMd};
  color: #344684;
  font-weight: bold;
`;
export const UserAvatar = styled.img`
  border: 5px solid #e8e8e8;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;
export const WicryptPattern = styled.img`
  position: absolute;
  right: 0;
  opacity: 0.2;
  bottom: 0;
  z-index: 10;
`;
