import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {
  cryptoWithdrawal,
  approvedeletecrypto,
  toggleModal,
  getFormDetails,
} from "../../Redux/actions";
import { connect } from "react-redux";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { pagination } from "../../Helper";

class Business extends Component {
  componentWillMount() {
    if (this.props.allCryptoWithdrawals.length === 0) {
      this.props.cryptoWithdrawal(1, 30);
    }
  }

  customPagination() {
    const { allCryptoTotalPages, allCryptoCurrentPage } = this.props;
    var pageButtons = pagination(allCryptoCurrentPage, allCryptoTotalPages, 5);
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.cryptoWithdrawal(allCryptoCurrentPage - 1, 30)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === allCryptoCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.cryptoWithdrawal(pageButtons[i], 30)}
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.cryptoWithdrawal(allCryptoCurrentPage + 1, 30)
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      allCryptoWithdrawals,
      gettingCryptoWithdrawal,
      approvingDeleting,
      approvedeleteid,
      approve,
      withdrawalrejectreasons,
      clickedDeviceId,
      funct,
    } = this.props;
    let allRows = [];
    allCryptoWithdrawals.map((data) => {
      return allRows.push({
        name: data.user.name,
        email: data.user.email,
        verificationStatus: data.user.verificationStatus,
        withdrawalStatus: data.status,
        withdrawalamt: data.amount,
        currency: data.currency.code,
        approve:
          data.status === "COMPLETE" ? (
            "-"
          ) : data.status === "FAILED" ? (
            "-"
          ) : (
            <button
              className="verify btn"
              onClick={() => this.props.approvedeletecrypto(data.id, true, "")}
            >
              {(approvingDeleting === true) &
              (data.id === approvedeleteid) &
              (approve === true) ? (
                <Loader color={"white"} />
              ) : (
                "Approve"
              )}
            </button>
          ),
        reject:
          data.status === "COMPLETE" ? (
            "-"
          ) : data.status === "FAILED" ? (
            "-"
          ) : (
            <button
              className="btn reject"
              onClick={() => this.props.toggleModal(true, data.id, false)}
            >
              {approvingDeleting &
              (data.id === approvedeleteid) &
              (approve === false) ? (
                <Loader color={"white"} />
              ) : (
                "Reject"
              )}
            </button>
          ),
      });
    });

    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 170,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Verification Status",
          field: "verificationStatus",
          sort: "asc",
          width: 170,
        },
        {
          label: "Withdrawal Status",
          field: "withdrawalStatus",
          sort: "asc",
          width: 170,
        },
        {
          label: "Amount",
          field: "withdrawalamt",
          sort: "asc",
          width: 170,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 170,
        },
        {
          label: "Approve",
          field: "approve",
          sort: "asc",
          width: 170,
        },
        {
          label: "Reject",
          field: "reject",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="col-lg-12 mb-4">
        <Modal heading={`Reason for rejection`}>
          <div className="input-group mb-2">
            <input
              type="text"
              className="form-control loginCred mb3"
              aria-describedby="basic-addon1"
              value={withdrawalrejectreasons}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["withdrawalrejectreasons"],
                  value: e.target.value,
                })
              }
            />
          </div>

          <Button
            disabled={withdrawalrejectreasons === "" ? true : false}
            theme=" ml-3 mt-2 float wicryptColor"
            buttonAction={() => {
              this.props.approvedeletecrypto(
                clickedDeviceId,
                funct,
                withdrawalrejectreasons
              );
              this.props.toggleModal(false);
            }}
          >
            Enter
          </Button>
        </Modal>
        <CustomDataTable
          data={data}
          pagination={this.customPagination()}
          placeholder={"Search with name or email"}
          loading={gettingCryptoWithdrawal}
          noData={
            <p className="voucherText t-center">
              {" "}
              No pending crypto withdrawal requests
            </p>
          }
        />
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const {
    allCryptoWithdrawals,
    allCryptoTotalRecords,
    allCryptoCurrentPage,
    allCryptoTotalPages,
    approvedeleteid,
    approve,
  } = state.Manager;
  const { gettingCryptoWithdrawal, approvingDeleting } = state.Loading;
  const { withdrawalrejectreasons, clickedDeviceId, funct } = state.User;
  return {
    allCryptoWithdrawals,
    gettingCryptoWithdrawal,
    allCryptoTotalRecords,
    allCryptoCurrentPage,
    allCryptoTotalPages,
    approvingDeleting,
    approvedeleteid,
    approve,
    withdrawalrejectreasons,
    clickedDeviceId,
    funct,
  };
};
export default connect(MapStateToProps, {
  cryptoWithdrawal,
  approvedeletecrypto,
  getFormDetails,
  toggleModal,
})(Business);
