import React, { useEffect, useState } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
// import StakeStats from "./StakeStats";
import { claimsCols, stakeCols } from "../../utils/TableCols/usersCols";
import { getStakeRows } from "../../utils/TableRows/usersRows";
import { useSelector, useDispatch } from "react-redux";
import { getStakeHistory } from "../../Redux/actions";
import { toggleModal } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { clearSearch } from "../../Redux/actions/GeneralAction";

import EmptyCard from "../Reusables/cards/EmptyCard";
import { unStakeCols } from "./../../utils/TableCols/usersCols";
import NewStakeLog from "../Staking/NewStakeLog";
import { StakeHeaderOptionTypes, stakeOptions } from "../UserDetails/options";

const Stake = ({ userId }) => {
  const [ stakeDetail, setStakeDetail ] = useState({});
  const { gettingStakeHistory, stakeHistory } = useSelector(
    (state) => state.Admin
  );
  const { activeTab, copied } = useSelector((state) => state.User);

  const { duration, Status, type, page, pageSize, stakeType } = useSelector(
    (state) => state.Search
  );

  const dispatch = useDispatch();
  let allRows = getStakeRows(stakeHistory.records, dispatch, copied, setStakeDetail);

  const data = {
    columns:
      activeTab === 1 ? stakeCols : activeTab === 2 ? unStakeCols : claimsCols,
    rows: allRows,
  };

  useEffect(() => {
    let actionType = activeTab === 1 ? "stake" : activeTab === 2 ? "unstake" : "claims"
    const queries = {
      UserId: userId,
      Duration: duration,
      Status: Status,
      Type: type,
      Page: page,
      PageSize: pageSize,
    };
    getStakeHistory(dispatch, queries, actionType);
  }, [duration, page, Status, type, stakeType]);

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      {/* <StakeStats /> */}
      <div className="col-lg-12 mt-1">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              title={`Stake History`}
              pageCount={stakeHistory.totalPages}
              currentPage={stakeHistory.currentPage}
              header={
                <TableHeader
                  subHeaderTypes={StakeHeaderOptionTypes}
                  subHeader={stakeOptions}
                  onClick={() => dispatch(toggleModal(true))}
                  ontabClick={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: ["stakeType"],
                        value: val,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={tokenSearch}
                />
              }
              loading={gettingStakeHistory}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={`Sorry,  you have no ${stakeType} history`}
                  // descr={"No stake history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
      {stakeDetail && stakeDetail.id && <NewStakeLog id={50} stakeLog={stakeDetail} />}
    </div>
  );
};

export default Stake;
