import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allsubscriptionplans, toggleModal, getFormDetails } from "../../Redux/actions";
import CreateSubscription from "./CreateSubsription";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { numberWithCommas, pagination } from "../../Helper";
import EditSubscription from "./EditSubscription";

class AllSubscriptions extends Component {

    componentDidMount() {
        this.props.allsubscriptionplans(1, 30, '')
    }
    componentWillReceiveProps(nextProps) {
        const {
          searchSubscription
        } = nextProps;
        if (
          searchSubscription !== this.props.searchSubscription
        ) {
          this.props.getallAgents(1, 30, searchSubscription);
        }
      } 

    customPagination() {
        const { currentsubscriptionpage, allsubscriptionpages } = this.props;
        var paginate = [];
        var pageButtons = pagination(currentsubscriptionpage, allsubscriptionpages, 5);
        if (pageButtons[0] === -1) {
            paginate.push(
                <p
                    onClick={() =>
                        this.props.allsubscriptionplans(
                            currentsubscriptionpage - 1,
                            30,
                        )
                    }
                    className="pointer"
                >
                    &laquo;
                </p>
            );
        }
        for (let i = 0; i < pageButtons.length; i++) {
            if (pageButtons[i] < 1) continue;
            if (pageButtons[i] === currentsubscriptionpage) {
                paginate.push(
                    <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
                        {pageButtons[i]}
                    </p>
                );
            } else {
                paginate.push(
                    <p
                        onClick={() => this.props.allsubscriptionplans(
                            pageButtons[i],
                            30,
                        )}
                        className="pointer"
                        key={pageButtons[i]}
                        value={pageButtons[i]}
                    >
                        {pageButtons[i]}
                    </p>
                );
            }
        }
        if (pageButtons[pageButtons.length - 1] === -1) {
            paginate.push(
                <p
                    onClick={() =>
                        this.props.allsubscriptionplans(
                            currentsubscriptionpage + 1,
                            30,
                        )
                    }
                    className="pointer"
                >
                    &raquo;
            </p>
            );
        }
        return paginate;
    }

    render() {
        const { subscriptionplans, gettingsubscriptionplans, funct, searchSubscription} = this.props;
        let allRows = [];
        subscriptionplans.map((plan) => {
            return allRows.push({
                name: plan.name,
                monthlyFee: numberWithCommas(plan.monthlyFee),
                yearlyFee: numberWithCommas(plan.yearlyFee),
                country: plan.country.name,
                minimumDevices: plan.minimumDevices,
                maximumDevices: plan.maximumDevices,
                edit: (
                    <button
                        className="details"
                        onClick={() => this.props.toggleModal(true, plan.id, "edit")}
                    >
                        <i className="fa fa-edit" aria-hidden="true" />
                        Edit{" "}
                    </button>
                ),

            });
        });

        const data = {
            columns: [
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Monthly Fee",
                    field: "monthlyFee",
                },
                {
                    label: "Yearly Fee",
                    field: "yearlyFee",
                },
                {
                    label: "Country",
                    field: "country",
                },
                {
                    label: "Min Devices",
                    field: "minimumDevices",
                },
                {
                    label: "Max Devices",
                    field: "maximumDevices",
                },
                {
                    label: "Edit",
                    field: "edit",
                },
            ],

            rows: allRows,
        };
        return (
            <div className="col-lg-12">
                {funct !== "edit" ? <CreateSubscription /> : <EditSubscription />}
                {gettingsubscriptionplans ? (
                    <div className="col-lg-12 ">
                        <div className=" t-center mt-4">
                            <CustomLoader />
                        </div>
                    </div>
                ) : (
                    <CustomDataTable
                        data={data}
                        buttontitle={"Create Subscription plan"}
                        placeholder= "Search subscription plan"
                        onClick={() => this.props.toggleModal(true)}
                        pagination={this.customPagination()}
                        searchfunction={(e) => this.props.getFormDetails({ props: ['searchSubscription'], value: e.target.value })}
                        searchvalue={searchSubscription}
                    />
                )}
            </div>
        );
    }
}

const MapstateToProps = (state) => {
    const { subscriptionplans, currentsubscriptionpage, allsubscriptionpages } = state.Subscription;
    const { gettingsubscriptionplans } = state.Loading;
    const { funct, searchSubscription} = state.User;
    return {
        subscriptionplans,
        gettingsubscriptionplans,
        currentsubscriptionpage,
        allsubscriptionpages,
        funct,
        searchSubscription
    }
}
export default connect(MapstateToProps, { allsubscriptionplans, toggleModal, getFormDetails })(AllSubscriptions)