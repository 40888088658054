
import styled from "styled-components";
import { theme } from "../../theme";


export const CustomFormContainer = styled.div`
    background-color: #fefefe;
    border-radius: 4px;
    padding: 20px;
`

export const Label = styled.label`
    color: ${theme.black};
    font-size: ${theme.textXs};
    font-weight: bold;
`

export const NextButton = styled.button`
    box-sizing: border-box;
    border-radius: 30px;
    padding: 5px;
    width: 25%;
    cursor: pointer;
    background: #F8ECBC;
    border: 1px solid #CFA809;
    color: #CFA809;
`