import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import {
  getFormDetails,
  createVoucher,
  getagentvouchers,
  toggleModal,
  saveId,
  clearData,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import moment from "moment";
import Modal from "../Reusables/modals/Modal";
import { numberWithCommas, voucherHrs, voucherDays } from "../../Helper";
import { MDBDataTable } from "mdbreact";
import InfiniteScroll from "../Reusables/cards/LoadMore";
import Datepicker from "react-datepicker";


class GenerateAgentVoucher extends Component {
  componentWillMount() {
    this.props.clearData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      voucherstartDate,
      voucherStatus,
      voucherendDate,
      selectedBusiness,
    } = nextProps;
    if (
      (voucherstartDate !== this.props.voucherstartDate ||
        voucherStatus !== this.props.voucherStatus ||
        voucherendDate !== this.props.voucherendDate ||
        selectedBusiness !== this.props.selectedBusiness) &
      (selectedBusiness !== "")
    ) {
      this.props.clearData();
      this.props.getagentvouchers(
        selectedBusiness,
        voucherstartDate,
        1,
        50,
        voucherStatus,
        voucherendDate
      );
    }
  }


  fetchMoreData = () => {
    const {
      voucherstartDate,
      voucherStatus,
      voucherendDate,
      allVouchersCurrentPage,
      allVouchersTotalPages,
      selectedBusiness,
    } = this.props;

    let page = allVouchersCurrentPage;
    if (page < allVouchersTotalPages) {
      page++;
    }

    this.props.getagentvouchers(
      selectedBusiness,
      voucherstartDate,
      page,
      50,
      voucherStatus,
      voucherendDate
    );
  };

  render() {
    const {
      allVouchers,
      voucherRecepientPhone,
      affiliatedBusiness,
      generatingVoucher,
      gettingVouchers,
      voucherStatus,
      voucherError,
      voucherAmt,
      voucherstartDate,
      voucherendDate,
      voucherValidity,
      voucherMsg,
      allVouchersTotalRecords,
      selectedBusiness,
      days,
    } = this.props;

    let mybiz = affiliatedBusiness.find(
      (biz) => biz.business.id === selectedBusiness
    );

    let allRows = [];
    allVouchers.map((voucher) => {
      return allRows.push({
        code: voucher.code,
        email: voucher.owner.username,
        value:
          numberWithCommas(voucher.voucherValue) +
          "WC | " +
          numberWithCommas(voucher.voucherValue * 360) +
          "NGN",
        balance:
          numberWithCommas(voucher.balance) +
          "WC | " +
          numberWithCommas(voucher.balance * 360) +
          "NGN",
        expiry:
          voucher.expiryDate == null
            ? "N/A"
            : moment(voucher.expiryDate).format("MMMM Do YYYY"),
        status: (
          <b
            className={
              voucher.voucherStatus === "Exhausted"
                ? "text-red"
                : voucher.voucherStatus === "Inactive"
                  ? "text-orange"
                  : "text-green"
            }
          >
            {" "}
            {voucher.voucherStatus}{" "}
          </b>
        ),
      });
    });

    const data = {
      columns: [
        {
          label: "Voucher Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Recepient email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Value",
          field: "value",
          sort: "asc",
          width: 170,
        },
        {
          label: "Balance",
          field: "logo",
          sort: "asc",
          width: 170,
        },
        {
          label: "Expiry",
          field: "expiry",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <Modal heading={"Generate Voucher"}>
          <label> Recepient's Phone Number</label>
          <div className="input-group mb-2">
            <input
              type="phone"
              className="form-control loginCred"
              aria-describedby="basic-addon1"
              value={voucherRecepientPhone}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["voucherRecepientPhone"],
                  value: e.target.value,
                })
              }
            />
          </div>

          <label> Voucher validity</label>
          <div className="row mb-3">
            <div className="col-lg-12">
              <div
                className="p-10"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["days"],
                    value: true,
                  })
                }
              >
                <input
                  type="radio"
                  id="days"
                  className="ml-3"
                  name="validity"
                  checked={days}
                />
                <label htmlFor="days" className="ml-3">
                  Days
                </label>
              </div>
              <div
                className="p-10"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["days"],
                    value: false,
                  })
                }
              >
                <input
                  type="radio"
                  id="hours"
                  name="validity"
                  className="ml-3"
                  checked={!days}
                />
                <label htmlFor="hours" className="ml-3">
                  Hours
                </label>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group mb-2">
                <select
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["voucherValidity"],
                      value: e.target.value,
                    })
                  }
                >
                  <option value=""> Select validity period</option>
                  {days ? voucherDays() : voucherHrs()}
                </select>
              </div>
            </div>
          </div>
          <div className="input-group mb-2">
            <label> Voucher amount</label>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control loginCred"
                aria-describedby="basic-addon1"
                value={voucherAmt}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherAmt"],
                    value: e.target.value,
                  })
                }
              />
              <span className="input-group-text">
                <select className="form-control loginCred">
                  <option value="NGN">NGN</option>
                </select>
              </span>
            </div>
          </div>
          <label> Business Name</label>
          <div className="input-group mb-2">
            <select
              className="form-control loginCred"
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["selectedBusiness"],
                  value: e.target.value,
                })
              }
            >
              <option>Select Business</option>
              {affiliatedBusiness.map((business) => {
                return (
                  <option value={business.business.id}>
                    {business.business.name}
                  </option>
                );
              })}
            </select>
          </div>
          {voucherError !== "" ? (
            <p className="errorMsgs">
              <i className="material-icons mr-1">info</i>
              {voucherError}
            </p>
          ) : (
              ""
            )}
          <Button
            theme="wicryptColor ml-3 mt-2 float"
            disabled={selectedBusiness === "" ? true : false}
            buttonAction={() =>
              this.props.createVoucher(
                voucherRecepientPhone,
                voucherAmt,
                voucherValidity,
                selectedBusiness,
                mybiz.business.voucherGenerationTransactionFee,
                voucherstartDate,
                voucherendDate,
                voucherStatus,
                "agent",
                days
              )
            }
          >
            {generatingVoucher ? <Loader /> : "Enter"}
          </Button>
        </Modal>

        <div className="col-lg-12">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="d-flex row">
              <h6 className="m-0">VOUCHERS</h6>
              <div className="createbtn">
                {generatingVoucher ? (
                  <Button theme="btn-primary ml-3 mt-2 actionButton wicryptColor">
                    {" "}
                    <Loader />
                  </Button>
                ) : (
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.toggleModal(true)}
                    >
                      {" "}
                    Generate Voucher
                    </i>
                  )}
              </div>
            </div>

            <div className="mt-3 d-flex row">
              <div className="dateRange col-lg-12">
                <div className="mr-3"> FROM</div>
                <Datepicker
                  className="dateRange form-control"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["voucherstartDate"],
                      value: e.getTime(),
                    })
                  }
                  selected={voucherstartDate}
                  dateFormat={"dd/MM/yyyy"}
                />

                <div className="mr-3 ml-3"> TO</div>
                <Datepicker
                  className="dateRange form-control"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["voucherendDate"],
                      value: e.getTime(),
                    })
                  }
                  selected={voucherendDate}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>

              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherStatus"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Voucher Status</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}> Inactive</option>
                <option value={"Exhausted"}> Exhausted</option>
              </select>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["selectedBusiness"],
                    value: e.target.value,
                  })
                }
              >
                <option>Select Business</option>
                {affiliatedBusiness.map((business) => {
                  return (
                    <option value={business.business.id}>
                      {business.business.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        {allVouchers.length > 0 ? (
          <div className="col-lg-12 mb-4">
            <div className="card card-small mb-4 dataTable">
              <InfiniteScroll
                dataLength={allVouchers.length}
                hasMore={
                  allVouchersTotalRecords > allVouchers.length ? true : false
                }
                items={
                  <div className="card card-small mb-4 dataTable">
                    <MDBDataTable
                      striped
                      hover
                      data={data}
                      searchLabel="Search"
                      responsive
                    />
                  </div>
                }
                moreData={this.fetchMoreData}
              />
            </div>
          </div>
        ) : (
            <div
              className="volucherNote"
              style={
                voucherMsg === "close" ? { display: "none" } : { display: "block" }
              }
            >
              {allVouchers.length === 0 && gettingVouchers === true ? (
                <Loader size={10} />
              ) : allVouchers.length === 0 && gettingVouchers === false ? (
                <p className="voucherText">
                  {" "}
                You have no vouchers matching the specified specs.
                </p>
              ) : (
                    ""
                  )}
            </div>
          )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    voucherRecepientPhone,
    voucherAmt,
    voucherstartDate,
    voucherendDate,
    voucherStatus,
    voucherReceipientEmail,
    voucherValidity,
    voucherMsg,
    businessProfile,
    selectedBusiness,
    days,
  } = state.User;
  const { generatingVoucher, gettingVouchers } = state.Loading;
  const { affiliatedBusiness } = state.Manager;
  const {
    allVouchers,
    voucherError,
    savedBusinessId,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
  } = state.Voucher;
  return {
    allVouchers,
    voucherReceipientEmail,
    affiliatedBusiness,
    voucherRecepientPhone,
    voucherAmt,
    voucherStatus,
    generatingVoucher,
    gettingVouchers,
    voucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
    voucherstartDate,
    voucherendDate,
    selectedBusiness,
    days,
  };
};

export default connect(
  MapStateToProps,
  {
    getFormDetails,
    createVoucher,
    getagentvouchers,
    toggleModal,
    saveId,
    clearData,
  }
)(GenerateAgentVoucher);
