import React from "react";
import EnterPin from "./EnterPin";
import ForgotPin from "./ForgotPin";
import InviteAdmin from "./InviteAdmin";

function Invitation() {
  return (
    <div>
      <InviteAdmin />
      <EnterPin />
      <ForgotPin />
    </div>
  );
}

export default Invitation;
