import React from "react";
import { useSelector } from "react-redux";
import CustomModal from "../Reusables/modals/CustomModal";
import moment from "moment";
import {
  LogList,
  LogDetails,
  LogStep
} from "./styles";
import EmptyCard from "../Reusables/cards/EmptyCard";


const SpendControlActivity = () => {

  const { userSpendActivity } = useSelector((state) => state.SpendControl);

  return (
    <div>
      <CustomModal
        id={58}
        heading="Spend Control Activity"
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
            <div className="card mb-4 w3-animate-bottomd">
              <div className="row">

                <div className="col-lg-12">
                  <ul>
                    {userSpendActivity[0] && userSpendActivity[0].activityLogDetail.length > 0 ? userSpendActivity[0].activityLogDetail.map(each => {
                      return (<LogList>
                        <LogStep> <span>{moment(each.executedAt).format('DD-MM-YY, h:mm:ss a')}</span></LogStep>
                        <LogStep> {each.actor}</LogStep>
                        <LogDetails> {each.initiatedByUser && "Initialted By: " + each.initiatedByUser.name} </LogDetails>
                        <LogDetails> {each.initiatedByUser && each.initiatedByUser.email} </LogDetails>
                      </LogList>)
                    }) : <li><EmptyCard img="/Assets/emptytransactions.svg"
                      title={"No Activity log"}
                      descr={"This user is using the Global spend control"} /></li>}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SpendControlActivity;
