import React from "react";
import { numberWithCommas } from "../../../../Helper";
import { PieChart } from "../../../Reusables";
import { PieContainer } from "../transactionoverviewstyles";

const FundingChart = ({ fundingOverview }) => {
  return (
    <PieContainer>
      {fundingOverview && (
        <PieChart
          data={[fundingOverview.cryptoFunding]}
          backgroundColorArray={["#25346A"]}
          labels={[
            // `Debit Card Funding ${"NGN " +
            //   numberWithCommas(
            //     fundingOverview.debitCardAmount
            //   )}`,
            // `Bank Transfer ${"NGN " +
            //   numberWithCommas(fundingOverview.bankTransfer )}
            //                   `,
            `Crypto Funding ${fundingOverview.currency +
              " " +
              numberWithCommas(fundingOverview.cryptoFunding)}
                            `,
          ]}
        />
      )}
    </PieContainer>
  );
};
// , "#4E69C6", "#C1CCF4"

export default FundingChart;
