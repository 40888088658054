/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import LinkButton from "../../Components/Reusables/buttons/LinkButton";
import { handleSubNav, handleModal, selectAdmin } from "../../Redux/actions";

export const getActiveAdminRows = (data, dispatch) => {
  const allRows = data
    ? data.map((user) => {
      return {
        name: user.name,
        email: user.email,
        role: user.role,
        status: user.status,
        view: <div
        className="details t-center"
        onClick={() => {
          dispatch(handleModal(15));
          dispatch(selectAdmin(user));
          // dispatch(getFormDetails({
          //   props: ["commandId"],
          //   value: each.commandId
          //   ,
          // })
          // )
        }}
      >
        Change Role
        {/* {creatingBatchCommands && commandId === each.commandId ? <Loader /> : "Issue command"} */}
      </div>,
        // view: (
        //   <LinkButton
        //     text={"View Profile"}
        //     color={"yellow"}
        //     link={`/admins/singleAdmin/${user.userId}`}
        //     size={"medium"}
        //     onClick={() => dispatch(handleSubNav(1))}
        //   />
        // ),
      };
    })
    : [];
  return allRows;
};
export const getInActiveAdminRows = (data, dispatch) => {
  const allRows = data
    ? data.map((user) => {
      return {
        name: user.name,
        email: user.email,
        role: user.role,
        status: user.status,
        // view: <div
        //   className="details t-center"
        //   onClick={() => {
        //     dispatch(handleModal(15));
        //     dispatch(selectAdmin(user));
           
        //   }}
        // >
        //   Change Role
        // </div>,
        view: (
          <LinkButton
            text={"View Profile"}
            color={"yellow"}
            link={`/admins/singleAdmin/${user.userId}`}
            size={"medium"}
          />
        ),
      };
    })
    : [];
  return allRows;
};
