import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import Cycles from "./Cycles";
import LockupRequests from "./LockupRequests";
import InterestDisbursements from "./InterestDisbursements";
import AccruedInterests from "./AccruedInterests";
import RewardApr from "./RewardApr";

const NewTransactions = () => {
  const { toggleSubnav } = useSelector((state) => state.General);
  let items = [
    { id: 1, item: "Cycles" },
    { id: 2, item: "Lockup Requests" },
    { id: 3, item: "Interest Disbursements" },
    { id: 4, item: "Accrued Interests" },
    { id: 5, item: "Reward Booster APR" },
  ];
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav pageTitle="Reward Booster" listItems={items} />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? (
            <Cycles />
          ) : toggleSubnav === 2 ? (
            <LockupRequests />
          ) : toggleSubnav === 3 ? (
            <InterestDisbursements />
          ) : toggleSubnav === 4 ? (
            <AccruedInterests />
          ) : <RewardApr />}
         
        </div>
      </MainContent>
    </div>
  );
};

export default NewTransactions;
