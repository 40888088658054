import React from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import AdminTokenReport from './AdminTokenReport'
import {MainContent} from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";


const index = () => {

    return (
        <div className="row">
            <Sidenav />
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Data Tokens"/>
                </div>

                <BreadCrumbs />
                <div className="main-content-container container-fluid px-4 mt-5">
                    <SubSidenav />
                    <AdminTokenReport />
                </div>
            </MainContent>
        </div>
    );

}



export default index