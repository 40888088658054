import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { getSearchDetails, getAllKYCs, getVerificationSummary, toggleModal } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import TableHeader from "../Reusables/tables/TableHeader";
import IconCard from "../Reusables/cards/IconCard";
import moment from "moment"
import Loader from "../Reusables/loaders/HashLoader";
import {
    numberWithCommaswithoutdecimals,
} from "../../Helper";
import { ViewDetail } from "./ViewDetail";

const KYCDetails = () => {

    const dispatch = useDispatch();

    const { kycRequests, kycRequestsTotalPages, kycRequestsCurrentPage, verificationSummary } = useSelector((state) => state.Verification);
    const { gettingAllKYCRequests, gettingVerificationSummary } = useSelector((state) => state.Loading)
    const [reconciliationOptions, setreconciliationOptions] = useState(
        [
            {
                id: 1,
                title: "Duration",
                type: "select",
                onSelectProp: "duration",
                options: [
                    {
                        id: 1,
                        option: "All",
                    },
                    {
                        id: 2,
                        option: "Last Week",
                    },
                    {
                        id: 3,
                        option: "Last Month",
                    },
                    {
                        id: 4,
                        option: "Last 5 Months",
                    },
                    {
                        id: 5,
                        option: "Last Year",
                    },
                    {
                        id: 6,
                        option: "Custom",
                    },
                ],
            },
            {
                id: 2,
                title: "Search",
                type: "text",
                placeholder: "Enter name or email",
                onChangeProp: "search",
            },
            {
                id: 3,
                title: "Status",
                type: "select",
                onSelectProp: "verificationStatus",
                options: [
                    {
                        id: 1,
                        option: "All",
                    },
                    {
                        id: 2,
                        option: "Approved",
                    },
                    {
                        id: 3,
                        option: "Cancelled",
                    }
                ],
            },
        ]);
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    const {
        duration,
        Page,
        PageSize,
        startTime,
        endTime,
        search,
        verificationStatus
    } = useSelector((state) => state.Search);

    let allRows = [];
    kycRequests.map((report) => {
        return allRows.push({
            dateCreated: moment(report.createdAt).format("DD-MM-YYYY"),
            user: <div className="flex">
                <img
                    alt="selfie-img"
                    onClick={() => { dispatch(toggleModal(true)); setImage(report.selfieImageUrl); setTitle(report.userName) }}
                    className="selfie-img user avatar" src={report.selfieImageUrl}
                />
                <div>
                    <p className="mb-0">{report.userName}</p>
                    <p>{report.userEmail}</p>
                </div>
            </div>,
            nationality: report.nationality,
            previewID: <div className="pointer text-blue" onClick={() => { dispatch(toggleModal(true)); setTitle(report.userName + "'s " + report.idType); setImage(report.identityCardUrl) }}> {report.idType}</div>,
            status: <div> <button className={report.verificationRequestStatus + " mb-1"}>{report.verificationRequestStatus}</button> <p>{report.reasonforRejection && "Reason: " + report.reasonforRejection}</p></div>,
            reviewedBy: report.reviewedBy
        });
    });
    const data = {
        columns: [
            {
                label: "Date",
                field: "dateCreated",
                sort: "asc",
                width: 170,
            },
            {
                label: "User",
                field: "user",
                sort: "asc",
                width: 170,
            },
            {
                label: "Nationality",
                field: "nationality",
                sort: "asc",
                width: 170,
            },
            {
                label: "Preview ID",
                field: "previewID",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
            {
                label: "Reviewed By",
                field: "reviewedBy",
                sort: "asc",
                width: 170,
            },

        ],
        rows: allRows,
    };

    const fetchKYC = () => {
        let start = startTime ? new Date(startTime).getTime() : ""
        let end = endTime ? new Date(endTime).getTime() : ""
        dispatch(getAllKYCs(start, end, duration, verificationStatus, search, Page, PageSize))
    }

    useEffect(() => {
        fetchKYC()
    }, [duration, Page, PageSize, startTime, endTime, verificationStatus]);

    // This is to refresh the page when the search input is empty
    useEffect(()=>{
        if(search === ""){
            fetchKYC();
        }
    },[search])

    useEffect(() => {
        let start = startTime ? new Date(startTime).getTime() : ""
        let end = endTime ? new Date(endTime).getTime() : ""
        dispatch(getVerificationSummary(start, end, duration));
    }, [duration, startTime, endTime,]);

    useEffect(() => {
        if (duration === "Custom") {

            setreconciliationOptions([
                {
                    id: 1,
                    title: "Duration",
                    type: "select",
                    onSelectProp: "duration",
                    options: [
                        {
                            id: 1,
                            option: "All",
                        },
                        {
                            id: 2,
                            option: "Last Week",
                        },
                        {
                            id: 3,
                            option: "Last Month",
                        },
                        {
                            id: 4,
                            option: "Last 5 Months",
                        },
                        {
                            id: 5,
                            option: "Last Year",
                        },
                        {
                            id: 6,
                            option: "Custom",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "Start Time",
                    type: "startTime",
                    onChangeProp: "startTime",
                },
                {
                    id: 4,
                    title: "End Time",
                    type: "endTime",
                    onChangeProp: "endTime",
                },
                {
                    id: 2,
                    title: "Search",
                    type: "text",
                    placeholder: "Enter name or email",
                    onChangeProp: "search",
                },
                {
                    id: 3,
                    title: "Status",
                    type: "select",
                    onSelectProp: "verificationStatus",
                    options: [
                        {
                            id: 1,
                            option: "All",
                        },
                        {
                            id: 2,
                            option: "Approved",
                        },
                        {
                            id: 3,
                            option: "Cancelled",
                        }
                    ],
                },
            ]);
        } else {
            setreconciliationOptions(
                [
                    {
                        id: 1,
                        title: "Duration",
                        type: "select",
                        onSelectProp: "duration",
                        options: [
                            {
                                id: 1,
                                option: "All",
                            },
                            {
                                id: 2,
                                option: "Last Week",
                            },
                            {
                                id: 3,
                                option: "Last Month",
                            },
                            {
                                id: 4,
                                option: "Last 5 Months",
                            },
                            {
                                id: 5,
                                option: "Last Year",
                            },
                            {
                                id: 6,
                                option: "Custom",
                            },
                        ],
                    },
                    {
                        id: 2,
                        title: "Search",
                        type: "text",
                        placeholder: "Enter name or email",
                        onChangeProp: "search",
                    },
                    {
                        id: 3,
                        title: "Status",
                        type: "select",
                        onSelectProp: "verificationStatus",
                        options: [
                            {
                                id: 1,
                                option: "All",
                            },
                            {
                                id: 2,
                                option: "Approved",
                            },
                            {
                                id: 3,
                                option: "Cancelled",
                            }
                        ],
                    },
                ]
            );
        }
    }, [duration]);

    return (
        <div className="row">
            <ViewDetail image={image} title={title} />
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"kyc-report"}
                            pageCount={kycRequestsTotalPages}
                            currentPage={kycRequestsCurrentPage}
                            header={
                                <div className="row">
                                    <div className="col-lg-12 mb-3">

                                        <TableHeader
                                            subHeader={reconciliationOptions}

                                            onSelect={(val, props) => {
                                                dispatch(
                                                    getSearchDetails({
                                                        props: [props],
                                                        value: val,
                                                    })
                                                );
                                            }}
                                            onChange={(e, props) => {
                                                dispatch(
                                                    getSearchDetails({
                                                        props: [props],
                                                        value: e.target.value,
                                                    })
                                                );
                                            }}
                                            onSearchClick={fetchKYC}
                                        />
                                    </div>

                                    <IconCard
                                        title={"Total Requests"}
                                        image={"/Assets/dashboardicons/Extra/SVG/admin.svg"}
                                        value={
                                            gettingVerificationSummary ? (
                                                <Loader />
                                            ) : verificationSummary ? (
                                                (numberWithCommaswithoutdecimals(verificationSummary.totalRequests
                                                ) || 0)
                                            ) : (
                                                "0"
                                            )
                                        }
                                        id={1}
                                        link={"#"}
                                    />
                                    <IconCard
                                        title={"Pending Requests"}
                                        image={"/Assets/dashboardicons/Extra/SVG/admin.svg"}
                                        value={
                                            gettingVerificationSummary ? (
                                                <Loader />
                                            ) : verificationSummary ? (
                                                (numberWithCommaswithoutdecimals(verificationSummary.totalPendingRequests
                                                ) || 0)
                                            ) : (
                                                "0"
                                            )
                                        }
                                        id={1}
                                        link={"#"}
                                    />
                                    <IconCard
                                        title={"Approved Requests"}
                                        image={"/Assets/dashboardicons/Extra/SVG/admin.svg"}
                                        value={
                                            gettingVerificationSummary ? (
                                                <Loader />
                                            ) : verificationSummary ? (
                                                (numberWithCommaswithoutdecimals(verificationSummary.totalApprovedRequests
                                                ) || 0)
                                            ) : (
                                                "0"
                                            )
                                        }
                                        id={1}
                                        link={"#"}
                                    />
                                    <IconCard
                                        title={"Rejected Requests"}
                                        image={"/Assets/dashboardicons/Extra/SVG/admin.svg"}
                                        value={
                                            gettingVerificationSummary ? (
                                                <Loader />
                                            ) : verificationSummary ? (
                                                (numberWithCommaswithoutdecimals(verificationSummary.totalRejectedRequests
                                                ) || 0)
                                            ) : (
                                                "0"
                                            )
                                        }
                                        id={1}
                                        link={"#"}
                                    />

                                </div>
                            }
                            loading={gettingAllKYCRequests}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    // title={"Error Fetching Users!"}
                                    descr={"No requests for this period"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KYCDetails;
