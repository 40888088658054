import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { deviceTrackDataHeaderOptions } from "../Devices/options";
import { deviceTrackerCols } from "../../utils/TableCols/DeviceCols";
import { getDeviceTrackerRows } from "../../utils/TableRows/DevicesRows";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceTrackData } from "../../Redux/actions/AdminDevicesAction";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";

const Tracker = () => {
  const deviceId = window.location.pathname.split("/").at(-1);
  const { gettingDeviceTrackData, deviceTrackData } = useSelector(
    (state) => state.Admin
  );
  const { Name, Page, Status } = useSelector((state) => state.Search);
  const dispatch = useDispatch();
  let allRows = getDeviceTrackerRows(deviceTrackData.records);

  const data = {
    columns: deviceTrackerCols,
    rows: allRows,
  };

  useEffect(() => {
    const queries = { DeviceName: Name, Page, Status };
    getDeviceTrackData(dispatch, queries, deviceId);
  }, [Name, Page, Status]);

  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <CustomDataTable
          data={data}
          title={"Track device's data status"}
          pageCount={deviceTrackData.totalPages}
          currentPage={deviceTrackData.currentPage}
          header={
            <TableHeader
              subHeader={deviceTrackDataHeaderOptions}
              onChange={(e, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: e.target.value,
                  })
                );
              }}
              onSelect={(val, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: val,
                  })
                );
              }}
              searchvalue={Name}
            />
          }
          loading={gettingDeviceTrackData}
          noData={
            <EmptyCard
              img="/Assets/emptytransactions.svg"
              title={"Sorry,  you have no records For the selected specs"}
              // descr={"No tracking records available at the moment"}
            />
          }
        />
      </div>
    </div>
  );
};

export default Tracker;
