import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleNav,
} from "../../Redux/actions";
import {
  TopnavContainer,
  PageTitle,
  Avatar,
  InlineFlex,
} from "./partials";
import { Icon, ValueFlex } from "../Reusables/cards/cards";
import TopNavDropDown from "./TopNavDropDown";
import MobileNav from "./MobileNav";
import SubMainnav from "./SubMainnav";
import SupportDropdown from "./SupportDropdown";

class Mainnav extends Component {
  render() {
    const { navToggle, supportToggle, pageTitle, listItems } = this.props;

    return (
      <React.Fragment>
        <MobileNav listItems={listItems} />
        <TopnavContainer>
          <ValueFlex>
            <PageTitle> {pageTitle}</PageTitle>
            <InlineFlex>
              <Avatar
                alt="user avatar"
                src="/Assets/images/avatars/user_icon.svg"
              />
              <Icon
                className="material-icons"
                onClick={() => this.props.toggleNav(!navToggle)}
              >
                {navToggle ? "expand_less" : "expand_more"}
              </Icon>
            </InlineFlex>
          </ValueFlex>
          {navToggle ? <TopNavDropDown /> : ""}
          {supportToggle ? <SupportDropdown /> : ""}
          {listItems ? <SubMainnav listItems={listItems} /> : ""}
        </TopnavContainer>
      </React.Fragment>
    );
  }
}

const MapStateToProps = (state) => {
  const { role, name, supportToggle } = state.User;
  const {navToggle } = state.Report;
  return {
    navToggle,
    role,
    name,
    supportToggle,
  };
};
export default connect(MapStateToProps, {
  toggleNav,
})(Mainnav);
