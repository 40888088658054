import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import DeviceFilter from './DeviceFilter'
import { MainContent } from '../Partials/partials'

class DeviceReport extends Component{
    render() {
    
      return (
         <div className="row">
         <Sidenav/>
             <MainContent className="main-content">
                 <div className="main-navbar sticky-top bg-white">
                     <Mainnav/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4">
                  <DeviceFilter/>
                 </div>
             </MainContent>
        </div>
      );
    }
  }

  const MapstateToProps = (state) =>{
    
    return{
      state
    }
  }
export default connect(MapstateToProps)( DeviceReport)