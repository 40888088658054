import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Marketer from "./Marketer";
import Mainnav from "../Partials/Mainnav";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";

const GetReferals = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Referral Count" />
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          <Marketer />
        </div>
      </MainContent>
    </div>
  );
};

export default GetReferals;
