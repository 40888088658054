import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  height: 30px;
  min-width: ${(props) => (props.size === "medium" ? "108px" : "159px")};
  border-radius: 36px;
  border: 1px solid
    ${(props) => (props.color === "yellow" ? "#E5B910" : "#c4c4c4")};
  margin-left: 10px;
`;
const Left = styled.span`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin-right: 5px;
`;

function LinkButton({ link, text, onClick, size, color }) {
  return (
    <ButtonContainer to={link} onClick={onClick} size={size} color={color}>
      <Left style={{ color: color === "yellow" ? "#E5B910" : "" }}>
        {text}
      </Left>
      {color === "yellow" ? (
        <img
          src="/Assets/dashboardicons/Extra/SVG/yellow-caret-right.svg"
          alt="righticon"
          style={{ marginLeft: "0 !important" }}
        />
      ) : (
        <img
          src="/Assets/dashboardicons/Extra/SVG/righticon.svg"
          alt="righticon"
          style={{ marginLeft: "0 !important" }}
        />
      )}
    </ButtonContainer>
  );
}

export default LinkButton;
