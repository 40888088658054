import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { handleModal } from "./GeneralAction";

//Get topics
export const getAllTopics = (type) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ALL_TOPICS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v4/gcp/topics?isDeadLetter=${type}`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_ALL_TOPICS,
            payload: false
          });
          if(type){
            dispatch({
              type: _const.DLQ_TOPICS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: _const.ALL_TOPICS,
              payload: res.data.data
            });
          }
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ALL_TOPICS,
          payload: false
        });
      });
  };
};

// get subscribers for a single topic 
export const getSubscribersPerTopic = (topic) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_SUBSCRIBERS_PER_TOPIC,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v4/gcp/topics/${topic}/subscribers`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_SUBSCRIBERS_PER_TOPIC,
            payload: false
          });
          dispatch({
            type: _const.SUBSCRIBERS_PER_TOPIC,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_SUBSCRIBERS_PER_TOPIC,
          payload: false
        });
      });
  };
};

// get number of messages per subscriber
export const getNoOfMessagesPerSubscriber = (subscribers) => {
  if(!subscribers.length) return;
   return (dispatch) => {
    dispatch({
      type: _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER,
      payload: true
    });

    dispatch({
      type: _const.NO_OF_MESSAGES_PER_SUBSCRIBER,
      payload: []
    });
   
      const requests = [];
      for(const subscriber of subscribers){
        requests.push(axios.get(config.apiUrl + `/v4/gcp/subscribers/${subscriber}/messages?numberOfMessage=100`))
      }
      Promise.all(requests)
        .then(res => {
          const numberData = res.map((response)=>response.data.data)
  
          dispatch({
            type: _const.NO_OF_MESSAGES_PER_SUBSCRIBER,
            payload: numberData
          });
      
          dispatch({
            type: _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER,
            payload: false
          });
        })
        .catch(err => {
            dispatch({
              type: _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER,
              payload: false
            });
          });
     

  //  await axios
  //     .get(`https://api.wicrypt.com/api/v4/gcp/subscribers/${subscribers}/messages?numberOfMessage=100`)
  //     .then(res => {
  //       if (res.data.success) {
  //         dispatch({
  //           type: _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER,
  //           payload: false
  //         });
  //         dispatch({
  //           type: _const.NO_OF_MESSAGES_PER_SUBSCRIBER,
  //           payload: res.data.data
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       dispatch({
  //         type: _const.GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER,
  //         payload: false
  //       });
  //     });
  };
};

// Replay a dead letter topic
export const updateDeadLetterTopic = (topic, subscriber) => {
  return dispatch => {
    dispatch({
      type: _const.UPDATING_DEAD_LETTER_TOPIC,
      payload: true
    });
    axios
      .put(config.apiUrl + `/v4/gcp/${topic}/drive-dead-letter/${subscriber}`,  {
        headers: {
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors",
        },
      })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.UPDATING_DEAD_LETTER_TOPIC,
            payload: false
          });
          dispatch({
            type: _const.DEAD_LETTER_TOPIC,
            payload: res.data
          });
          dispatch(handleModal(0));
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
        } 
        // else{
        //   dispatch({
        //     type: _const.ERROR_UPDATING_DLQ,
        //     payload: res.data.message
        //   });
        //   console.log(res.data)
        // }
      })
      .catch(err => {
        dispatch({
          type: _const.UPDATING_DEAD_LETTER_TOPIC,
          payload: false
        });
        dispatch({
          type: _const.ERROR_UPDATING_DEAD_LETTER_TOPIC,
          payload: err.message,
        });
      });
  };
};