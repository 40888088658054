import React, { useState } from "react";
import {
  Center,
  CurrentValue,
  Customslider,
  Range,
  ShowValue,
  Slider,
} from "./inputstyles";

function CustomSlider({ percent, setPercent }) {
  const [value, setValue] = useState(percent);
  const [left, setLeft] = useState(`${percent}%`);
  //   const [active, setActive] = useState(false);

  return (
    <Center>
      <Slider>
        <ShowValue>
          <CurrentValue left={left}>
            {value}
            {"%"}
          </CurrentValue>
        </ShowValue>
        <Customslider
          type="range"
          min="0"
          max="100"
          defaultValue="50"
          steps="1"
          onChange={(e) => {
            setValue(e.target.value);
            setPercent(e.target.value);
            setLeft(`${value}%`);
          }}
          value={value}
        />
        <Range>
          <div>0</div>
          <div>100</div>
        </Range>
      </Slider>
    </Center>
  );
}

export default CustomSlider;
