import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessAgents } from "../../Redux/actions/BusnessLogAction";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { BusinessAgentCols } from "../../utils/TableCols/BusinessCols";
import { getBusinessAgentRows } from "../../utils/TableRows/BusinessRows";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import { businnessHeaderOptions } from "./options";

const BusinessAgents = () => {
  const dispatch = useDispatch();
  const { Name, Page } = useSelector((state) => state.Search);
  const { gettingBusinessAgents, businessAgents } = useSelector(
    (state) => state.Businesses
  );
  let allRows = getBusinessAgentRows(businessAgents.records);

  const data = {
    columns: BusinessAgentCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);
  const fetchBusinessAgent = () => {
    const queries = { Name, Page };
    getBusinessAgents(dispatch, queries);
  }
  useEffect(() => {
    fetchBusinessAgent();
  }, [Page]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(Name === ""){
      fetchBusinessAgent();
    }
  },[Name])

  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              pageCount={businessAgents.totalPages}
              currentPage={businessAgents.currentPage}
              header={
                <TableHeader
                  subHeader={businnessHeaderOptions}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={Name}
                  onSearchClick={fetchBusinessAgent}
                />
              }
              loading={gettingBusinessAgents}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no business agents"}
                  // descr={"No Business Agents available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessAgents;
