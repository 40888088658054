import styled from "styled-components/macro";

export const SelectContainer = styled.div``;

export const Label = styled.label`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.textXs};
  margin-bottom: 0px;
`;

export const InputTagBox = styled.div`
  background: ${({ theme }) => theme.themebody};
  min-height: 36px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0;
  cursor: pointer;
  position: relative;

  border: ${({ theme }) => `1px solid ${theme.grey}`};
  border-radius: 26px;
  padding: 10px;
  width: 178px;
  height: 40px;
  background-color: ${({ theme }) => theme.themebody};
  //   border-bottom: 0.7px solid #c4c4c4;
  margin-bottom: 20px;
  margin-top: 0px;
  color: ${({ theme }) => theme.grey};

  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const InputText = styled.input`
  background: ${({ theme }) => theme.themebody};
  min-height: 36px;
  font-weight: 500;
  display: flex;
  /* padding: 15px 20px; */
  padding: ${({ customPadding }) => customPadding || '15px 10px 15px'};
  margin: 0;
  border: ${({ theme }) => `1px solid ${theme.grey}`};
  border-radius: 26px;
  /* padding: 10px; */
  width: 200px;
  height: 40px;
  background-color: ${({ theme }) => theme.themebody};
  margin-bottom: 20px;
  margin-top: 0px;
  color: ${({ theme }) => theme.grey};
  outline: none;
  font-size: ${({ theme }) => theme.textXs};
`;

export const InputTag = styled.div`
  background: ${({ theme }) => theme.white};
  min-height: 36px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0;
  cursor: pointer;
  position: relative;

  border: none;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  border-bottom: 0.7px solid #c4c4c4;
  margin-bottom: 20px;
  margin-top: 0pxx;
  color: ${({ theme }) => theme.grey};

  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    img {
      margin: 2px 0px;
      height: 5px;
    }
  }
`;

export const SelectedOption = styled.div`
  /* border-bottom: 1px solid #d2cbcb; */
  border: 0px;
  margin-bottom: 0px;
  p {
    margin: 0;
    font-size: 0.75rem !important;
  }
`;

export const SelectErr = styled.p`
  font-size: ${({ theme }) => theme.textXs};
  color: red;
  margin-bottom: 0;
  margin: 0;
  /* padding: 5px; */
  // position: absolute;
`;

export const DropdownContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
  position: absolute;
  cursor: pointer;
  min-width: 178px;
  max-height: 278px;
  overflow-y: auto;
  top: 70%;
  padding: 20px;
  z-index: 100000;
  box-shadow: 1px 1px 2px 2px #b7b7b7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const Optionvalues = styled.p`
  border-bottom: 1px solid #d2d2d2;
  font-size: 0.7rem;
  margin-bottom: 0.8rem;
  color: ${(props) => props.choosenOption && "#cfa809"};
  width: 100%;
`;

export const SearchGroupWrap = styled.div `
  position: relative;
  /* width: 240px; */
`
export const Datalist = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 150px;
  overflow: hidden auto;
  gap: 5px;
  border-radius: 4px;
  padding: 6px 0;
  background: #FFFFFF;
  z-index: 9;
  box-shadow: 0px 25px 35px 0px #00000026;
  /* box-shadow: 0px 3.156003475189209px 21.040023803710938px 10.520011901855469px #00000026; */

`

export const DropdownOptions = styled.button `
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 16px;
  text-align: left;
  font-size: 0.7rem;
  background: #fff;
  cursor: pointer;
  color: #6B6B6B;
  transition: color 0.4s ease-in;
  &:hover {
    color: #E5B910;
  }
`

export const NotFound = styled.div `
  display: grid;
  place-items: center;
  font-size: 1rem;
  height: 100%;
`