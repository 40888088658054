import styled from "styled-components";
import { theme, device } from "../../../theme";

export const Icon = styled.i`
  color: ${(props) => (props.color ? theme[props.color] : theme.yellow)};
  cursor: pointer;
`;

export const ArrowCard = styled.div`
         background: #ffffff;
         border-radius: 8px;
         padding: 15px;
         min-height: 100px;
         @media ${device.laptop} {
           min-height: 110px;
         }
         border: ${(props) =>
           props.bordered ? "1px solid #C4C4C433" : "none"};
         border-radius: 10px;
       `;
export const CardTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  color: #747474;
  margin-bottom: 0px;
  font-size: 10px;
  @media ${device.laptop} {
    font-size: 16px;
  }
`;
export const CardValue = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 54px;
  margin-bottom: 0px;
  color: #000;
  @media ${device.laptop} {
    font-size: 20px;
  }
`;
export const ValueFlex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  @media ${device.laptop} {
    padding-bottom: 0px;
  }
`;
export const IconContainer = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  background: rgba(229, 185, 9, 0.1);
  text-align: center;
  position: relative;
`;
export const CardIcon = styled.img`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
export const EyeIcon = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
export const DescriptionCard = styled.div`
  position: absolute;
  right: 15%;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(24 29 39 / 10%), 0 15px 30px rgb(24 29 39 / 10%);
  top: 22%;
  height: 85px;
`;
export const CardDescription = styled.p`
  padding: 20px;
  font-size: 0.7rem;
`;
