import React from "react";
import LinkButton from "../../Components/Reusables/buttons/LinkButton";
import moment from "moment";
import { ActionButtonContainer } from "../../Components/AcctVerification/verificationstyle";
import { handleSubNav, oneRequest} from "../../Redux/actions";
import { shortenAddress, numberWithCommas } from "../../Helper";
import config from "../../Redux/reducers/config";
import { useDispatch } from "react-redux";


export const allUsersRows = (data, dispatch, activeTab) => {
  const allRows = data
    ? data.map((user) => {
        return {
          name: user.name,
          email: user.email,
          referralCode: user.referralCode,
          country: user.country ? user.country : "N/A",
          lastLoginDate:
            user.lastLoginDate > 0
              ? `${moment(user.lastLoginDate).format("DD-MM-YYYY")} |
            ${moment(user.lastLoginDate).format("LT")}`
              : "N/A",
          type: user.accountType,
          status: (
            <p
              className={
                user.status === "Unverified" ? "unverified" : "verified"
              }
            >
              {user.status === "Unverified" ? "Not Verified" : "Verified"}{" "}
            </p>
          ),
          view: (
            <LinkButton
              link={`/user/${user.userId}`}
              text={"View"}
              size="medium"
              onClick={() => {
                localStorage.setItem("currentSingleUser", JSON.stringify(user));
                localStorage.setItem("activeTab", activeTab);
                localStorage.setItem("backBtn", "/wicryptusers");
                dispatch(handleSubNav(1));
              }}
            />
          ),
        };
      })
    : [];
  return allRows;
};

export const getStakeRows = (data, setStakeDetail) => {
  let dispatch = useDispatch();
  const allRows = data
    ? data.map((stake) => {
        return {
          action: stake.action || "N/A",
          name: stake.user.name || "N/A",
          type: stake.type || "N/A",
          price: stake.wntPrice || "N/A",
          duration: stake.duration || "N/A",
          reward: stake.claimedReward + " WNT" || "N/A",
          amount: numberWithCommas(stake.amount) + " WNT" || "N/A",
          // transactionHash: <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/tx/${stake.transactionHash}`}> {shortenAddress(stake.transactionHash)} </a> ,
          transactionHash: stake.transactionHash ? <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/tx/${stake.transactionHash}`}> {shortenAddress(stake.transactionHash)} </a> : <p>----</p> ,

          status: <div className={stake.status && stake.status.toUpperCase()}>{stake.status}</div>,
          date:
            moment(stake.createdAt).format("DD-MM-YYYY") +
            " | " +
            moment(stake.createdAt).format("LT"),
          // viewMore: (<div
          //     className="details t-center"
          //     onClick={() => {
          //       console.log("I am the stake row")
          //       dispatch(handleModal(50));
          //       setStakeDetail(stake);
          //     }}
          //   >
          //     View More
          //   </div>),
          viewMore:
          <LinkButton 
          link={`/stake/${stake.id}`}
          text={"View More"}
          size="medium"
          onClick={() => console.log("I am the stake row")}
        />,

        };
      })
    : [];
  return allRows;
};
export const getAllVerificationRequestRows = (data, dispatch) => {
  const allRows = data
    ? data.map((request) => {
        return {
          name: request.name || "N/A",
          email: request.email || "N/A",
          phone: request.phoneNumber || "N/A",
          country: request.country || "N/A",
          submitted: moment(request.requestSubmissionDate).format(
            "MMMM Do YYYY"
          ),
          status: (
            <ActionButtonContainer>
              <button
                type="button"
                className={`btn ${request.verificationStatus}`}
                value={request.id}
              >
                {" "}
                {request.verificationStatus === "Cancelled"
                  ? "Rejected"
                  : request.verificationStatus === "Pending"
                  ? "Pending"
                  : request.verificationStatus === "Verified"
                  ? "Verified"
                  : request.verificationStatus}
              </button>
            </ActionButtonContainer>
          ),
          // ),
          view: (
            <LinkButton
              link={`/oneverification/${request.id}`}
              text={"View Document"}
              onClick={() => dispatch(oneRequest(request.id))}
            />
            // <Link to="/oneverification">
            //   <Icon
            //     className="material-icons"
            //     onClick={}
            //   >
            //     chevron_right
            //   </Icon>
            // </Link>
          ),
        };
      })
    : [];
  return allRows;
};

export const getStaketatisticsRows = (data) => {
  const allRows = data
    ? data.map((stake) => {
        return {
          name: stake.name,
          email: stake.email,
          amount: numberWithCommas(stake.amount) + " WNT" || "N/A",
          duration: stake.duration,
          stakedOn: moment(stake.stakedOn).format("DD-MM-YYYY"),
          unstakeDate: stake.duration === "Flexible" ? "---" : moment(stake.unstakeDate).format("DD-MM-YYYY"),
          // unstakeDate: stake.claimedReward + " WNT" || "N/A",
          
          // transactionHash: <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/tx/${stake.transactionHash}`}> {shortenAddress(stake.transactionHash)} </a>,
          // status: <div className={stake.status && stake.status.toUpperCase()}>{stake.status}</div>,
          // date:
          //   moment(stake.createdAt).format("DD-MM-YYYY") +
          //   " | " +
          //   moment(stake.createdAt).format("LT"),
        //   view:
        //   <button
        //   link={`/stake/${stake.id}`}
        //   text={"View Details"}
        //   size="medium"
        //   onClick={() => console.log("I am the stake row")}
        // />,

        };
      })
    : [];
  return allRows;
};

export const getDistributedRows = (data) => {
  const allRows = data
    ? data.map((reward) => {
        return {
          week: reward.week,
          totalWNTDistributed: numberWithCommas(reward.totalWNTDistributed) + " WNT" || "N/A",
          totalWNTDistributedInUSD: numberWithCommas(reward.totalWNTDistributedInUSD) + " USD" || "N/A",
          totalNumberOfStakers: reward.totalNumberOfStakers,
          view:
          <LinkButton 
          link={`/stake/reward/${reward.disbursedRewardId}`}
          text={"View Details"}
          size="medium"
          onClick={() => console.log("I am the stake row")}
        />,
        };
      })
    : [];
  return allRows;
};

export const getDistributedDetailsRows = (data) => {
  const allRows = data
    ? data.map((reward) => {
        return {
          name: reward.name,
          email: reward.email,
          rewardAmount: numberWithCommas(reward.rewardAmount) + " WNT" || "N/A",
          stakedAmount: numberWithCommas(reward.stakedAmount) + " WNT" || "N/A",
          duration: reward.duration,
        };
      })
    : [];
  return allRows;
};