import React, { Component } from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import SubSidenav from '../Partials/SubSidenav'
import GenerateVoucher from './GenerateVoucher'
import GenerateBulkVoucher from '../BulkVouchers/Generatebulkvouchers'
import VoucherBalance from '../VoucherBalance/VoucherBalance'
import { connect } from 'react-redux'
import { MainContent } from '../Partials/partials'


class Tokens extends Component {
  render() {
    const{role, toggleSubnav} = this.props;
    let voucherArray = role.includes('Business') ? [{id: 1, item: "Check Voucher Balance"},{id: 3, item: "Single Voucher"}, {id: 2, item: "Bulk Voucher"}] : 
    role.includes('Agent') ? [{id: 1, item: "Check Voucher Balance"}, {id: 2, item: "Bulk Voucher"}] 
    : [{id: 1, item: "Check Voucher Balance"}]
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white mb-5">
            <Mainnav pageTitle="Voucher" listItems={voucherArray}/>
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            {toggleSubnav === 3 ? <GenerateVoucher />
            : toggleSubnav === 2 ?  <GenerateBulkVoucher />:
            <VoucherBalance /> }
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const {role} = state.User;
  const {toggleSubnav} = state.General;
  return {
    role,
    toggleSubnav
  }
}

export default connect(MapstateToProps)(Tokens)