import React, { Component } from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import ManagerStats from "./ManagerStats";
import RevenueStats from "./revenueStats";
import { connect } from "react-redux";
import { getDevices, getManager, agentReport, businessReport, getvouchers} from "../../Redux/actions";
import Summary from "./summary";
import SubSidenav from "../Partials/SubSidenav";
import {MainContent} from "../Partials/partials"

class ManagerDashboard extends Component {
  constructor() {
    super();
    this.state = {
      pieLabels: [],
      sessionData: [],
      revenueData: [],
      usageData: [],
    };
  }

  componentWillMount() {
    const { voucherstartDate, voucherendDate} = this.props;
    this.props.getManager();
    this.props.businessReport(this.props.myBusinessId, 'Business');
    this.props.agentReport();
    this.props.getvouchers("", voucherstartDate, 1, 30, "", voucherendDate, '');
    this.props.getDevices('')
  }

  componentWillReceiveProps(nextProps) {
    const { devices } = nextProps;
    let device = devices.map((device) => {
      if (device.deviceName === null) {
        return "Unnamed";
      }
      return device.deviceName;
    });
    let sessions = devices.map((device) => {
      return device.sessionsCount;
    });
    let revenues = devices.map((device) => {
      return device.totalRevenue;
    });
    let usage = devices.map((device) => {
      return device.totalUsage;
    });
    this.setState({
      pieLabels: device,
      sessionData: sessions,
      revenueData: revenues,
      usageData: usage,
    });
  }

  render() {
    const { pieLabels, sessionData, usageData, revenueData } = this.state;
    let deviceWithMostSession = "";
    let deviceWithMostRevenue = "";
    let mostUsedDevice = "";
    let session = 0;
    let revenue = 0;
    let usage = 0;

    for (let i = 0; i < sessionData.length; i++) {
      if (session === 0) {
        session = sessionData[i];
        deviceWithMostSession = pieLabels[i];
      } else if (session < sessionData[i]) {
        session = sessionData[i];
        deviceWithMostSession = pieLabels[i];
      }
    }

    for (let i = 0; i < revenueData.length; i++) {
      if (revenue === 0) {
        revenue = revenueData[i];
        deviceWithMostRevenue = pieLabels[i];
      } else if (revenue < revenueData[i]) {
        revenue = revenueData[i];
        deviceWithMostRevenue = pieLabels[i];
      }
    }

    for (let i = 0; i < usageData.length; i++) {
      if (usage === 0) {
        usage = usageData[i];
        mostUsedDevice = pieLabels[i];
      } else if (usage < usageData[i]) {
        usage = usageData[i];
        mostUsedDevice = pieLabels[i];
      }
    }

    var totalSessions = sessionData.reduce(function (a, b) {
      return a + b;
    }, 0);
    var dataUsed = usageData.reduce(function (a, b) {
      return a + b;
    }, 0);


    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle={"Dashboard"} />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5 mb-5">
            <SubSidenav />
            <ManagerStats totalSessions={totalSessions} dataUsed={dataUsed} />
            <div className="row">
              {/* <Overview
                title="Overview"
                dataBought={bought}
                dataSold={sold}
                usage={dataUsed}
                sessions={totalSessions}
              /> */}
              <RevenueStats
                title="Total Revenue in NGN"
                labels={pieLabels}
                data={sessionData}
              />
              <Summary
                title="Summary"
                revenue={deviceWithMostRevenue}
                usage={mostUsedDevice}
                sessions={deviceWithMostSession}
              />
            </div>
          </div>
        </MainContent>
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const { devices } = state.Manager;
  const { bought, sold, myBusinessId,voucherstartDate,voucherendDate} = state.User;

  return {
    devices,
    bought,
    sold,
    myBusinessId,
    voucherstartDate,
    voucherendDate,
  };
};

export default connect(
  MapStateToProps,
  { agentReport, getDevices, getManager,businessReport, getvouchers }
)(ManagerDashboard);
