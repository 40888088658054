import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import NewTransfer from "./NewTransfer";
import NewStaking from "./NewStaking";
import ProjectedUnstake from "./ProjectedUnstake";
import StakingInterestClaims from "./StakingInterestClaim";
import NewP2P from "./NewP2P";
import NewMiningRewards from "./NewMiningRewards";
import DisbursedStakingRewards from "./DisbursedStakingRewards";
import CryptoDeposit from "./CryptoDeposit";
import CryptoWithdrawal from "./CryptoWithdrawal";

const NewTransactions = () => {
  const { toggleSubnav } = useSelector((state) => state.General);
  let items = [
    { id: 1, item: "Transfer" },
    { id: 2, item: "P2P" },
    { id: 3, item: "Mining Rewards" },
    { id: 4, item: "Crypto Deposit" },
    { id: 5, item: "Crypto Withdrawal" },
    { id: 6, item: "Staking" },
    { id: 7, item: "Disbursed Staking Reward" },
    { id: 8, item: "Staking Interest Claims" },
    { id: 9, item: "Projected Unskate" },
  ];
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav pageTitle="New Transactions" listItems={items} />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? (
            <NewTransfer />
          ) : toggleSubnav === 2 ? (
            <NewP2P />
          ) : toggleSubnav === 3 ? (
            <NewMiningRewards />
          ) : toggleSubnav === 4 ? (
            <CryptoDeposit />
          ) : toggleSubnav === 5 ? (
            <CryptoWithdrawal />
          ) : toggleSubnav === 6 ? (
            <NewStaking  />
          ) : toggleSubnav === 7 ? (
            <DisbursedStakingRewards />
          ) : toggleSubnav === 8 ? (
            <StakingInterestClaims />
          ) : <ProjectedUnstake />}
         
        </div>
      </MainContent>
    </div>
  );
};

export default NewTransactions;
