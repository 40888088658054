import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toggleModal } from "./GeneralAction";

//Get all agents
export const getallAgents = (page, pageSize, agentName) => {
  return (dispatch) => {
    dispatch({
      type: _const.FETCHING_ALL_AGENTS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/users?page=${page}&pageSize=${pageSize}&agentName=${agentName}`
      )
      .then((res) => {
        dispatch({
          type: _const.FETCHING_ALL_AGENTS,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.ALL_AGENTS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: _const.ALL_AGENTS,
            payload: [],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.FETCHING_ALL_AGENTS,
          payload: false,
        });
      });
  };
};

//Get devices to assign to agent
export const agentDevice = (id) => {
  return (dispatch) => {
    dispatch({
      type: _const.DEVICE_TO_ASSIGN,
      payload: id,
    });
  };
};

//Clear agent list
export const clearAgents = () => {
  return (dispatch) => {
    dispatch({
      type: _const.CLEAR_AGENTS,
      payload: [],
    });
  };
};

//Create Agents
export const createAgents = (data) => {
  return (dispatch) => {
    if (data.email === "" || data.role === "") {
      dispatch({
        type: _const.CREATE_AGENT_ERROR,
        payload: "The email is required ",
      });
    } else {
      dispatch({
        type: _const.CREATING_AGENT,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/businesses/users", data)
        .then((res) => {
          dispatch({
            type: _const.CREATING_AGENT,
            payload: false,
          });
          if (res.data.success) {
            Swal.fire({
              text: "Agent Created",
              type: "success",
            });
            dispatch(toggleModal(false));
            dispatch({
              type: _const.CLEAR_AGENTS,
              payload: [],
            });
            dispatch(getallAgents(1, 30, ""));
          } else {
            dispatch({
              type: _const.CREATE_AGENT_ERROR,
              payload: res.data.errors[0],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.CREATING_AGENT,
            payload: false,
          });
          dispatch({
            type: _const.CREATE_AGENT_ERROR,
            payload: err.response.data.errors[0],
          });
        });
    }
  };
};

//Assign agent to device
export const assignAgents = (email, devices, id) => {
  let alldevices = [];
  devices.map((device) => {
    return alldevices.push(device.value);
  });
  let data = {
    email: email,
    devices: alldevices,
  };
  return (dispatch) => {
    dispatch({
      type: _const.ASSIGNING_DEVICE,
      payload: true,
    });
    axios
      .post(config.apiUrl + "/v2/businesses/devices", data)
      .then((res) => {
        dispatch({
          type: _const.ASSIGNING_DEVICE,
          payload: false,
        });
        if (res.data.success) {
          Swal.fire({
            text: "Device has been assigned successfully",
            type: "success",
          });
          dispatch(toggleModal(false));
          dispatch(getonebusinessAgent("", id));
          dispatch({
            type: _const.CLEAR_FORMS,
            payload: true,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.ASSIGNING_DEVICE,
          payload: false,
        });
      });
  };
};

//Edit agent role
export const editAgent = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: _const.EDITING_AGENT,
      payload: true,
    });
    axios
      .put(config.apiUrl + `/v2/businesses/users/${id}`, data)
      .then((res) => {
        dispatch({
          type: _const.EDITING_AGENT,
          payload: false,
        });
        if (res.data.success) {
          Swal.fire({
            text: "Device has been assigned successfully",
            type: "success",
          });
          dispatch(toggleModal(false));
          dispatch({
            type: _const.CLEAR_AGENTS,
            payload: [],
          });
          dispatch(getallAgents(1, 30, ""));
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.EDITING_AGENT,
          payload: false,
        });
        dispatch(toggleModal(false));
        dispatch({
          type: _const.CLEAR_AGENTS,
          payload: [],
        });
        dispatch(getallAgents(1, 30, ""));
      });
  };
};

//Get virtual account
export const getAccount = (wallettype) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_VIRTUAL_ACCOUNT,
      payload: true,
    });
    let apiUrl =
      wallettype === "Personal"
        ? "/v2/virtualAccount"
        : "/v2/virtualAccount/Business";
    axios
      .get(config.apiUrl + apiUrl)
      .then((res) => {
        dispatch({
          type: _const.GETTING_VIRTUAL_ACCOUNT,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.MERCHANT_ACCOUNT,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_VIRTUAL_ACCOUNT,
          payload: false,
        });
      });
  };
};

//Get all Businesses
export const allBusinesses = (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_ALL_BUSINESSES,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  axios
    .get(config.adminApiUrl + `/v1/businesses`, {
      params,
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ALL_BUSINESSES,
        payload: false,
      });
      dispatch({
        type: _const.ALL_BUSINESSES,
        payload: res.data.data,
        totalrecords: res.data.data.totalRecords,
        currentPage: res.data.data.currentPage,
        totalPages: res.data.data.totalPages,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ALL_BUSINESSES,
        payload: false,
      });
    });
};

export const allBusinessesForOperations = (
  page,
  pageSize,
  businessName,
  dispatch
) => {
  dispatch({
    type: _const.GETTING_ALL_BUSINESSES,
    payload: true,
  });
  axios
    .get(
      config.apiUrl +
        `/v2/businesses?page=${page}&pageSize=${pageSize}&businessName=${businessName}`
    )
    .then((res) => {
      dispatch({
        type: _const.GETTING_ALL_BUSINESSES,
        payload: false,
      });
      dispatch({
        type: _const.ALL_BUSINESSES,
        payload: res.data.data,
        totalrecords: res.data.meta.total,
        currentPage: res.data.meta.page,
        totalPages: res.data.meta.numberOfPages,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ALL_BUSINESSES,
        payload: false,
      });
    });
};

//Get business profile
export const getBusinessProfile = (role) => {
  return (dispatch) => {
    axios.get(config.apiUrl + "/v2/businesses/profile").then((res) => {
      if (res.data.success) {
        dispatch({
          type: _const.BUSINESS_PROFILE,
          payload: res.data.data,
        });
        localStorage.setItem("business", JSON.stringify(res.data.data));
        // dispatch(businessReport(res.data.data.id, role));
      }
    });
  };
};

//Get all businesses affiliated with a user
export const getAgentBusiness = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/businesses/me")
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: _const.AFFILIATED_BUSINESSES,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => err);
  };
};

export const getAgentBizSummary = (businessId, userId, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_AGENT_BIZ_SUMMARY,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/${businessId}/users/${userId}/summary?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_AGENT_BIZ_SUMMARY,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.AGENT_BIZ_SUMMARY,
            payload: res.data.data,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: _const.GETTING_AGENT_BIZ_SUMMARY,
          payload: false,
        })
      );
  };
};

//Get one business agent
export const getonebusinessAgent = (businessId, businessUserId) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ONE_BUSINESS_AGENT,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
          `/v2/businesses/AgentInformation?businessId=${businessId}&businessUserId=${businessUserId}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_ONE_BUSINESS_AGENT,
          payload: false,
        });
        dispatch({
          type: _const.BUSINESS_AGENT_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ONE_BUSINESS_AGENT,
          payload: false,
        });
      });
  };
};

//Delete device for agents
export const deleteAgentDevice = (userDeviceId, businessUserId, businessId) => {
  let data = {
    userDeviceId: userDeviceId,
    businessUserId: businessUserId,
    businessId: businessId,
  };
  return (dispatch) => {
    dispatch({
      type: _const.DELETING_AGENT_DEVICE,
      payload: true,
    });
    axios
      .delete(config.apiUrl + "/v2/businesses/devices", { data: data })
      .then((res) => {
        dispatch({
          type: _const.DELETING_AGENT_DEVICE,
          payload: false,
        });
        dispatch(toggleModal(false));
        dispatch(getonebusinessAgent(businessId, businessUserId));
      })
      .catch((err) => {
        dispatch({
          type: _const.DELETING_AGENT_DEVICE,
          payload: false,
        });
      });
  };
};

//Delete agents for  business
export const deleteAgent = (businessUserId, businessId) => {
  let data = {
    businessUserId: businessUserId,
    businessId: businessId,
  };
  return (dispatch) => {
    dispatch({
      type: _const.DELETING_AGENT,
      payload: true,
    });
    axios
      .delete(config.apiUrl + "/v2/businesses/users", { data: data })
      .then((res) => {
        dispatch({
          type: _const.DELETING_AGENT,
          payload: false,
        });
        if (!res.data.errors.length > 0) {
          dispatch(toggleModal(false));
          dispatch(getallAgents(1, 30, ""));
          dispatch({
            type: _const.DELETE_AGENT_SUCEESS,
            payload: true,
          });
        } else {
          dispatch({
            type: _const.DELETE_AGENT_FAIL,
            payload: res.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.DELETING_AGENT,
          payload: false,
        });
      });
  };
};
