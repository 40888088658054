import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllAdverts,
  approveAdverts,
  rejectAdverts,
  toggleModal,
  getAdvertById,
  handleModal
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import moment from "moment";
import CustomCardLoader from "../Reusables/loaders/CustomCardLoader";
import Modal from "../Reusables/modals/Modal";
import AdvertModal from "./AdvertModal";

class ViewAdvert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedAdvert: null,
      rejectedAdvert: null,
      selectedBusinessName: null,
      selectedStartDate: null,
    };
  }

  componentDidMount() {
    this.props.getAllAdverts(1, 30);
  }

  handleAccept = (id, businessName, startDate) => {
    this.setState({
      acceptedAdvert: id,
      selectedBusinessName: businessName,
      selectedStartDate: startDate,
    });
    this.props.handleModal(81); // Open AdvertModal for accepting
  };

  handleReject = (id, businessName, startDate) => {
    this.setState({
      rejectedAdvert: id,
      selectedBusinessName: businessName,
      selectedStartDate: startDate,
    });
    this.props.handleModal(82); // Open AdvertModal for rejecting
  };

  render() {
    const {
      alladverts,
      approvingAdverts,
      rejectingAdverts,
      id,
      gettingAllAdverts,
      advert,
      advertError
    } = this.props;
    return (
      <div className="">
        <Modal heading={"Advert Details"}>
          <div className="card">
            <img
              className="card-img-top rounded"
              src={advert.images}
              alt="advert"
            />
            <div className="card-footer p-3">
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-12">Business name</span>
                </div>
                <div className="col-auto">
                  <span className="t-12">Status</span>
                </div>
              </div>
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-13">{advert.businessName}</span>
                </div>
                <div className="col-auto">
                  <span className="t-13">{advert.status}</span>
                </div>
              </div>
            </div>
            <div className="card-footer p-3">
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-12">Start date</span>
                </div>
                <div className="col-auto">
                  <span className="t-12">End date</span>
                </div>
              </div>
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-13">
                    {moment(advert.startDate).format("MMM Do YYYY")}
                  </span>
                </div>
                <div className="col-auto">
                  <span className="t-13">
                    {moment(advert.endDate).format("MMM Do YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer p-3">
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-12">Country</span>
                </div>
                <div className="col-auto">
                  <span className="t-12">City</span>
                </div>
              </div>
              <div className="row align-items-center no-gutters">
                <div className="col">
                  <span className="t-13">{alladverts.country}</span>
                </div>
                <div className="col-auto">
                  <span className="t-13">{alladverts.city}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <AdvertModal
          modalId={81}
          acceptingRejectingAdverts={approvingAdverts}
          acceptReject={"accept"}
          businessName={this.state.selectedBusinessName}
          startDate={this.state.selectedStartDate}
          onSubmit={() => this.props.approveAdverts(this.state.acceptedAdvert)}
          advertError={advertError}
        />
        <AdvertModal
          modalId={82}
          acceptingRejectingAdverts={rejectingAdverts}
          acceptReject={"reject"}
          businessName={this.state.selectedBusinessName}
          startDate={this.state.selectedStartDate}
          onSubmit={() => this.props.rejectAdverts(this.state.rejectedAdvert)}
          advertError={advertError}
        />
        <div className="col-lg-12">
          <div className="row">
            {gettingAllAdverts ? (
              <CustomCardLoader />
            ) : (
              alladverts.map((each, i) => {
                return (
                  <div key={i} className="col-lg-3 col-md-6 col-12 mb-3">
                    <div className="card">
                      <img
                        src={each.images[0]}
                        className="card-img-top"
                        alt="advert"
                        onClick={() => {
                          this.props.toggleModal(true);
                          this.props.getAdvertById(each.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="mt-3 mb-3">
                        {each.status === "Pending" ? (
                          <div className="d-flex">
                            <div
                               onClick={() =>
                                this.handleAccept(
                                  each.id,
                                  each.businessName,
                                  moment(each.startDate).format("MMM Do YYYY")
                                )
                              }
                              className="btn button Approved"
                            >
                              {approvingAdverts && id === each.id ? (
                                <Loader />
                              ) : (
                                "Accept"
                              )}
                            </div>
                            <div
                              onClick={() =>
                                this.handleReject(
                                  each.id,
                                  each.businessName,
                                  moment(each.startDate).format("MMM Do YYYY")
                                )
                              }
                              className="btn button Failed"
                            >
                              {rejectingAdverts && id === each.id ? (
                                <Loader />
                              ) : (
                                "Reject"
                              )}
                            </div>
                          </div>
                        ) : each.status === "Approved" ? (
                          <div className="t-center">
                            <button disabled className="btn button Approved">
                              APPROVED
                            </button>
                          </div>
                        ) : (
                          <div className="t-center">
                            <button disabled className="btn button Failed">
                              {each.status}
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="card-footer border-top p-3">
                        <div className="row align-items-center no-gutters">
                          <div className="col">
                            <span className="t-12">Business name</span>
                          </div>
                          <div className="col-auto">
                            <span className="t-12">Start date</span>
                          </div>
                        </div>
                        <div className="row align-items-center no-gutters">
                          <div className="col">
                            <span className="t-13">{each.businessName}</span>
                          </div>
                          <div className="col-auto">
                            <span className="t-13">
                              {moment(each.startDate).format("MMM  Do YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const { alladverts, id, advert, advertError} = state.Adverts;
  const {
    approvingAdverts,
    rejectingAdverts,
    gettingAllAdverts
  } = state.Loading;

  return {
    alladverts,
    approvingAdverts,
    rejectingAdverts,
    id,
    gettingAllAdverts,
    advert,
    advertError
  };
};

export default connect(
  MapStateToProps,
  {
    getAllAdverts,
    rejectAdverts,
    approveAdverts,
    toggleModal,
    getAdvertById,
    handleModal
  }
)(ViewAdvert);
