import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Helmet} from 'react-helmet'


ReactDOM.render(<div>
    <Helmet>
    <link href="https://use.fontawesome.com/releases/v5.0.6/css/all.css" rel="stylesheet"/>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" 
    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous"/>
    <link rel="stylesheet" href="/Assets/styles/shards-dashboards.1.1.0.min.css"/>
    <link rel="stylesheet" href="/Assets/styles/extras.1.1.0.min.css"/>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="theme-color" content="#000000" />
    <meta name="wicrypt" content="free data for browsing" />
    <link rel="apple-touch-icon" sizes="57x57" href="../public/Assets/apple-icon-57x57.png"/>
    <link rel="apple-touch-icon" sizes="60x60" href="../public/Assets/apple-icon-60x60.png"/>
    <link rel="apple-touch-icon" sizes="72x72" href="../public/Assets/apple-icon-72x72.png"/>
    <link rel="apple-touch-icon" sizes="76x76" href="../public/Assets/apple-icon-76x76.png"/>
    <link rel="apple-touch-icon" sizes="114x114" href="../public/Assets/apple-icon-114x114.png"/>
    <link rel="apple-touch-icon" sizes="120x120" href="../public/Assets/apple-icon-120x120.png"/>
    <link rel="apple-touch-icon" sizes="144x144" href="../public/Assets/apple-icon-144x144.png"/>
    <link rel="apple-touch-icon" sizes="152x152" href="../public/Assets/apple-icon-152x152.png"/>
    <link rel="apple-touch-icon" sizes="180x180" href="../public/Assets/apple-icon-180x180.png"/>
    <link rel="icon" type="image/png" sizes="192x192"  href="../public/Assets/android-icon-192x192.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="../public/Assets/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="96x96" href="../public/Assets/favicon-96x96.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="../public/Assets/favicon-16x16.png"/>
    <link rel="manifest" href="../public/Assets/manifest.json"/>
    <meta name="msapplication-TileColor" content="#ffffff"/>
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
    <meta name="theme-color" content="#ffffff"/>

    </Helmet> 
    <App /></div>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
