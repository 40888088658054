import React, { useEffect } from "react";
import SmallStats from "./smallStats";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import { SimpleSelect } from "../Reusables/selects/SimpleSelect";
import LatestRequests from "./LatestRequest";
import LatestDevices from "./LatestDevices";
import {
  getMiningStatistics,
  getSessionsStatistics,
  getTotalNumberOfDevices,
  getTotalUsers,
  getTotalAdminUsers,
} from "../../Redux/actions/AdminDevicesAction";
import {getStakingSummary} from "../../Redux/actions"
import { useDispatch, useSelector } from "react-redux";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { clearSearch } from "../../Redux/actions/GeneralAction";

const statOptions = [
  {
    id: 1,
    option: "All",
    value: "",
  },
  {
    id: 2,
    option: "Last Week",
    value: "LastWeek",
  },
  {
    id: 3,
    option: "Last Month",
    value: "LastMonth",
  },
  {
    id: 4,
    option: "Last 5 Months",
    value: "Last5Months",
  },
  {
    id: 5,
    option: "Last Year",
    value: "LastYear",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const { duration } = useSelector((state) => state.Search);

  useEffect(() => {
    getTotalNumberOfDevices(dispatch, duration);
    getSessionsStatistics(dispatch, duration);
    getMiningStatistics(dispatch, duration);
    getTotalUsers(dispatch, duration);
    getTotalAdminUsers(dispatch, duration);
    dispatch(getStakingSummary(duration))
  }, [duration]);

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Dashboard" />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <div
            className="row flex items-right justify-end"
            style={{ paddingRight: "30px" }}
          >
            <SimpleSelect
              options={statOptions}
              onSelect={(val, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: val,
                  })
                );
              }}
            />
          </div>
          <SmallStats />
          <div className="row mt-1" style={{ marginBottom: "50px" }}>
            <LatestRequests />
            <LatestDevices />
            {/* <DataOverview />
            <TotalRevenue />
            <SessionGraph />
            <SharedData /> */}
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default Dashboard;
