import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCommands, getFormDetails, } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  SubmitButton,
  SubmitButtonContainer,
} from "./styles";

const CreateCommands = () => {

  const dispatch = useDispatch();


  const { commandCode, commandName } = useSelector((state) => state.User);
  const {creatingCommands} = useSelector((state)=> state.Loading);
  const {createCommandError} = useSelector((state)=> state.RemoteCommand)


  return (
    <div>
      <CustomModal
        id={50}
        heading="Create Remote Command"
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
            <div>

              <label> Command Name</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={commandName}
                  onChange={(e) =>
                    dispatch(getFormDetails({
                      props: ["commandName"],
                      value: e.target.value,
                    })
                    )
                  }
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            <div>

              <label> Command Code</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="text"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={commandCode}
                  onChange={(e) =>
                    dispatch(getFormDetails({
                      props: ["commandCode"],
                      value: e.target.value,
                    })
                    )
                  }
                  style={{ width: "400px" }}
                />
              </div>
            </div>
           { createCommandError && <p className="errorMsgs"> {createCommandError}</p>}

            <SubmitButtonContainer>
              <SubmitButton
                onClick={() => {
                  dispatch(createCommands(
                    {
                      "commandCode": Number(commandCode),
                      "commandName": commandName
                    }
                  ))
                }}
              >
                {creatingCommands ? <Loader /> : "Save" }
              </SubmitButton>
            </SubmitButtonContainer>
          </div>
        }
      />
    </div>
  );
};

export default CreateCommands;
