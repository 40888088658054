import React from 'react'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Table from './WithdrawalTable'
import Mainnav from '../Partials/Mainnav'
import { MainContent } from '../Partials/partials'



const Withdrawals = () => {
    return (
        <div className="row">
            <Sidenav />
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Withdrawal Requests"/>
                </div>
                <BreadCrumbs />
                <div className="main-content-container container-fluid px-4 mt-5">
                    <Table />
                </div>
            </MainContent>

        </div>
    )
}

export default Withdrawals