import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeRoleContainer,
  DropDown,
  Option,
  SelectContainer,
  SelectTitle,
  StakeStatValue,
  EmailInput,
} from "../../AdminDetails/styles";
import { theme } from "../../../theme";
import { ContinueBtn } from "../../Reusables/inputs/ContinueButton";
import { ButtonContainer } from "../../Reusables/inputs/inputstyles";
import CustomModal from "../../Reusables/modals/CustomModal";
import Loader from "../../Reusables/loaders/MoonLoader";
import { adminInvite } from "../../../Redux/actions/AdminAction";



function InviteAdmin() {
  const dispatch = useDispatch();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { adminRoles, adminError } = useSelector(
    (state) => state.Admin
  );
  const [selectedOption, setSelectedOption] = useState(0);
  const [email, setEmail] = useState("");
  const [err, setError] = useState("");
  const { invitingAdmin } = useSelector((state) => state.Loading);

  const handleAdminInvite = () =>{
    if(!email){
      setError("Please enter a valid email")
    }else{
      setError("")
      let data = {
        email: email,
        role: adminRoles[selectedOption]
      }
      dispatch(adminInvite(data))
    }
    
  }


  return (
    <div>
      <CustomModal
        id={16}
        heading="Invite Admin"
        subHeading="Send an email invitation to a new admin to enable them sign up"
        close={true}
        content={
          <>
            <ChangeRoleContainer>
              <div>
                <SelectTitle>Email Address</SelectTitle>
                <EmailInput
                  type="text"
                  placeholder="example@useremail.com"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <SelectTitle>Select Role</SelectTitle>
                <SelectContainer
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  <StakeStatValue
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {adminRoles[selectedOption]}
                  </StakeStatValue>
                  <img src="/assets/chevron-downward.svg" height="5px" alt="" />
                </SelectContainer>
                {toggleDropdown && (
                  <DropDown>
                    {adminRoles.map((item,index) => (
                      <Option
                        key={item}
                        onClick={() => {
                          setSelectedOption(index);
                          setToggleDropdown(false);
                        }}
                        selected={selectedOption === index}
                      >
                        {item}
                      </Option>
                    ))}
                  </DropDown>
                )}
                {err || adminError ? <p className="errorMsgs mt-3 t-center">{err || adminError}</p> : ""} 
              </div>
            </ChangeRoleContainer>
            <ButtonContainer>
              <ContinueBtn
                onClick={() => handleAdminInvite()}
                title={invitingAdmin ? <Loader /> : "Submit"}
                darktheme={theme}
              />
            </ButtonContainer>
          </>
        }
      />
    </div>
  );
}

export default InviteAdmin;
