import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import * as options from "./options";
import TableHeader from "../Reusables/tables/TableHeader";
import { liveSessionCols } from "../../utils/TableCols/DeviceCols";
import { getLiveSessionRows } from "../../utils/TableRows/DevicesRows";
import { useDispatch, useSelector } from "react-redux";
import { getAdminLiveSessions } from "../../Redux/actions/AdminAction";
import { handleSubNav } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { clearSearch } from "../../Redux/actions/GeneralAction";

const DeviceLiveSession = () => {
  const { currentValue, selectedDevice } = useSelector((state) => state.User);
  const { Page, duration, Search, Name } = useSelector((state) => state.Search);
  const { gettingAdminLiveSessions, adminLiveSessions } = useSelector(
    (state) => state.Admin
  );
  const dispatch = useDispatch();

  const allRows = getLiveSessionRows(
    adminLiveSessions.records,
    currentValue,
    "",
    dispatch,
    selectedDevice
  );
  const data = {
    columns: liveSessionCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);
  const fetchLiveSession = () => {
    const queries = { Page, duration, Search, DeviceName: Name };
    getAdminLiveSessions(dispatch, queries);
  }
  useEffect(() => {
    fetchLiveSession();
  }, [Page, duration]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(Name === ""){
      fetchLiveSession();
    }
  },[Name])

  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              pageCount={adminLiveSessions.totalPages}
              currentPage={adminLiveSessions.currentPage}
              header={
                <TableHeader
                  subHeader={options.liveSessionSearch}
                  onClick={() => dispatch(handleSubNav(5))}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={Name}
                  onSearchClick={fetchLiveSession}
                />
              }
              loading={gettingAdminLiveSessions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no live sessions"}
                  // descr={"No Live Sessions available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceLiveSession;
