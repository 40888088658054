import React, { Component } from "react";
import { toggleModal } from "../../../Redux/actions";
import { connect } from "react-redux";
import {
  ModalTitle,
  ModalContainer,
  ModalHeader,
  ModalClose,
  ModalCotent,
} from "./modalstyles";

class CustomModal extends Component {
  render() {
    const { heading } = this.props;
    return (
      <div>
        {this.props.modal ? (
          <ModalContainer>
            <ModalCotent className="w3-animate-bottom">
              {/* <div className="col-lg-5 mb-4">
                            <div className="card card-small mb-4 custom-modal-card"> */}
              <ModalHeader>
                <ModalTitle>{heading}</ModalTitle>
                <ModalClose onClick={() => this.props.toggleModal(false)}>
                  <i className="fas fa-times" />
                </ModalClose>
              </ModalHeader>
              {/* <ul className="list-group list-group-flush">
                                    <li className="list-group-item p-3">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12"> */}
              {this.props.children}
              {/* </div>
                                        </div>
                                    </li>
                                </ul> */}
              {/* </div>
                        </div> */}
            </ModalCotent>
          </ModalContainer>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { modal } = state.User;
  return { modal };
};
export default connect(MapStateToProps, { toggleModal })(CustomModal);
