import React, { Component } from 'react';
import {
    getFormDetails,
    getAllUserAdverts,
    handleImageChange,
    createUserAdverts,
    toggleModal
} from "../../Redux/actions";
import { connect } from "react-redux";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";
import { voucherHrs, voucherDays } from "../../Helper";
import moment from "moment";
import CustomCardLoader from "../Reusables/loaders/CustomCardLoader"

class UploadAdvert extends Component {

    componentDidMount() {
        this.props.getAllUserAdverts(1, 30)
    }

    render() {
        const { alladverts, advertImg, creatingAdvert, voucherValidity, externalLink, days, errorcreatingadvert, advertstartdate, gettingAllAdverts } = this.props;
        let createdata = {
            "startDate": moment(advertstartdate).format(),
            "validityInHours": Number(days ? voucherValidity * 24 : voucherValidity),
            "country": "string",
            "city": "string",
            "advertImages": [
                {
                    "base64Image": advertImg,
                    "externalLink": externalLink
                }
            ]
        }
        let noneservererror = ["", 1, 2, 3, 4]
        return (
            <div className="">
                <Modal heading={"Upload Advert"}>
                    <div className="p-r">
                        <div className="avatar__preview mx-auto mb-4">
                            <img
                                className="avatar__upload"
                                id="Img1"
                                src={
                                    advertImg !== ""
                                        ? advertImg
                                        : "https://assets.hongkiat.com/uploads/psd-text-svg/logo-example.jpg"
                                }
                                alt="advert"
                            />

                        </div>
                        <input type="file" name="file" id="file"
                            onChange={(e) => this.props.handleImageChange(e)}
                            className="uglyduckling" />
                        <label for="file" className="uglylabel"><i className="fa fa-edit fa-2x" aria-hidden="true" /></label>
                    </div>
                    <label> Enter Link to the Advert</label>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control loginCred"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={externalLink}
                            onChange={(e) =>
                                this.props.getFormDetails({
                                    props: ["externalLink"],
                                    value: e.target.value,
                                })
                            }
                        />
                    </div>

                    <label> Validity</label>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div
                                className="p-10"
                                onChange={(e) =>
                                    this.props.getFormDetails({
                                        props: ["days"],
                                        value: true,
                                    })
                                }
                            >
                                <input
                                    type="radio"
                                    id="days"
                                    className="ml-3"
                                    name="validity"
                                    checked={days}
                                />
                                <label htmlFor="days" className="ml-3">
                                    Days
                </label>
                            </div>
                            <div
                                className="p-10"
                                onChange={(e) =>
                                    this.props.getFormDetails({
                                        props: ["days"],
                                        value: false,
                                    })
                                }
                            >
                                <input
                                    type="radio"
                                    id="hours"
                                    name="validity"
                                    className="ml-3"
                                    checked={!days}
                                />
                                <label htmlFor="hours" className="ml-3">
                                    Hours
                </label>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="input-group mb-2">
                                <select
                                    className="form-control loginCred"
                                    onChange={(e) =>
                                        this.props.getFormDetails({
                                            props: ["voucherValidity"],
                                            value: e.target.value,
                                        })
                                    }
                                >
                                    <option value=""> Select validity period</option>
                                    {days ? voucherDays() : voucherHrs()}
                                </select>
                            </div>
                        </div>
                    </div>
                    {errorcreatingadvert === 4 ? (
                        <p className="errorMsgs">
                            <i className="material-icons">How long do you want your advert to last?</i>
                            {errorcreatingadvert}
                        </p>
                    ) : (
                            ""
                        )}
                    <label>Start Date</label>
                    <div className="input-group mb-2">
                        <input
                            type="date"
                            className="form-control loginCred"
                            aria-describedby="basic-addon1"
                            value={advertstartdate}
                            onChange={(e) =>
                                this.props.getFormDetails({
                                    props: ["advertstartdate"],
                                    value: e.target.value,
                                })
                            }
                        />
                    </div>
                    {errorcreatingadvert === 2 ? (
                        <p className="errorMsgs">
                            <i className="material-icons">info</i>
                            When do you want your advert to start running?
                        </p>
                    ) : (
                            ""
                        )}
                    {errorcreatingadvert === 3 ? (
                        <p className="errorMsgs">
                            <i className="material-icons">info</i>
                            Please select a date in the future
                        </p>
                    ) : (
                            ""
                        )}
                    {!noneservererror.includes(errorcreatingadvert) ? (
                        <p className="errorMsgs">
                            <i className="material-icons">info</i>
                            {errorcreatingadvert}
                        </p>
                    ) : (
                            ""
                        )}
                    <Button
                        theme="wicryptColor ml-3 mt-2 float"
                        buttonAction={() =>
                            this.props.createUserAdverts(
                                createdata
                            )
                        }
                    >
                        {creatingAdvert ? <Loader /> : "Enter"}
                    </Button>
                </Modal>
                <div className="row mb-5">
                    <div className="card-header mb-3" style={{ width: "100%" }}>
                        <div className="d-flex align-items-center">
                            <h6>ADVERTS</h6>
                            <div className="details">
                                <div className="upload-btn-wrapper">
                                    <i className="fa fa-caret-down" aria-hidden="true"
                                        style={{ cursor: "pointer" }}>
                                        {" "}
                                        Upload Advert
                                    </i>
                                    <input type="file" name="file" accept=".png, .jpg, .jpeg"
                                        onChange={(e) => {
                                            this.props.handleImageChange(e)
                                            this.props.toggleModal(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {gettingAllAdverts ? <CustomCardLoader /> : alladverts.map((each, i) => {
                        return (
                            <div key={i} className="col-lg-3 col-md-6 col-12 mb-3">
                                <div className="card mb-4">
                                    <img src={each.images[0]} className="card-img-top" alt="advert" />
                                    <div className="card-body">
                                        <div className="t-center">
                                            <button className={each.status + " btn button text-white"}><i className="far fa-dot-circle"></i> {each.status}</button>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top">
                                        <div className="row align-items-center no-gutters">
                                            <div className="col">
                                                <span className="t-12">Start date</span>
                                            </div>
                                            <div className="col-auto">
                                                <span className="t-12">End date</span>
                                            </div>
                                        </div>
                                        <div className="row align-items-center no-gutters">
                                            <div className="col">
                                                <span className="t-13">{moment(each.startDate).format("MMM Do YYYY")}</span>
                                            </div>
                                            <div className="col-auto">
                                                <span className="t-13">{moment(each.endDate).format("MMM Do YYYY")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const MapStateToProps = (state) => {
    const { alladverts, errorcreatingadvert } = state.Adverts;
    const { advertImg, days, advertstartdate, voucherValidity, externalLink } = state.User;
    const { creatingAdvert, gettingAllAdverts } = state.Loading;
    return {
        alladverts,
        creatingAdvert,
        errorcreatingadvert,
        days,
        advertImg,
        advertstartdate,
        voucherValidity,
        gettingAllAdverts,
        externalLink
    };
};

export default connect(MapStateToProps, {
    getFormDetails,
    getAllUserAdverts,
    handleImageChange,
    createUserAdverts,
    toggleModal
})(UploadAdvert);