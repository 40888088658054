import _const from '../actions/Types'

const initialState = {
    dateTo: new Date(),
    dateFrom: new Date(),
    sessionsPerRange: '',
    revenuesPerRange: '',
    dataPerRange: '',
    error: [],
    pickaRange: false
}

const OverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.GET_DATETO:
            return { ...state, dateTo: action.payload, sessionsPerRange: '', revenuesPerRange: '', dataPerRange: '', pickaRange: false }

        case _const.GET_DATEFROM:
            return { ...state, dateFrom: action.payload, sessionsPerRange: '', revenuesPerRange: '', dataPerRange: '', pickaRange: false }

        case _const.DATA_RANGE_DATA:
            let totalData = action.payload.data.reduce(function (prev, curr) {
                return prev + curr.totalUsage;
            }, 0)
            let totalSession = action.payload.data.reduce(function (prev, curr) {
                return prev + curr.sessionCount;
            }, 0)
            let totalRevenues = action.payload.data.reduce(function (prev, curr) {
                return prev + curr.totalBilling;
            }, 0)

            return { ...state, error: action.payload.errors, dataPerRange: totalData, sessionsPerRange: totalSession, revenuesPerRange: totalRevenues }
        case _const.PICK_A_RANGE:
            return { ...state, pickaRange: action.payload }

        default:
            return state
    }
}

export default OverviewReducer