export const BusinessAgentCols = [
  {
    label: "Agent Name",
    field: "agent",
    sort: "asc",
    width: 100,
  },
  {
    label: "Managed Devices",
    field: "manageddevices",
    sort: "asc",
    width: 100,
  },
  {
    label: "Currency",
    field: "currency",
    sort: "asc",
    width: 100,
  },
  {
    label: "Voucher Allowance",
    field: "voucherremittanceallowance",
    sort: "asc",
    width: 100,
  },

  {
    label: "Verification Status",
    field: "status",
    sort: "asc",
    width: 100,
  },
];

export const businessesCols = [
         {
           label: "Business Name",
           field: "name",
           sort: "asc",
           width: 370,
         },
         {
           label: "Office Address",
           field: "address",
           sort: "asc",
           width: 370,
         },
        //  {
        //    label: "Voucher Generation Fee",
        //    field: "voucherfee",
        //    sort: "asc",
        //    width: 270,
        //  },
         {
           label: "",
           field: "report",
           sort: "asc",
           width: 130,
         },
       ];
