import _const from "../actions/Types";

const initialState = {
  active: false,
  id: 1,
  navList: [
    {
      id: 1,
      title: "Dashboard",
      link: "user/dashboard",
      icon: "home",
    },
    {
      id: 2,
      title: "Referrals",
      link: "manager/referals",
      icon: "note_add",
    },
    {
      id: 3,
      title: "My Devices",
      link: "setting",
      icon: "phonelink_setup",
    },
    {
      id: 4,
      title: "Check Voucher Balance",
      link: "voucher/balance",
      icon: "phonelink_setup",
    },
    {
      id: 5,
      title: "Profile",
      link: "manager",
      icon: "account_box",
    },
  ],
};

const UserNav = (state = initialState, action) => {
  switch (action.type) {
    case _const.CHANGE_ACTIVE:
      let active = true;
      let id = action.id;
      return {
        ...state,
        active,
        id,
      };
    default:
      return { ...state };
  }
};

export default UserNav;
