export const cyclesColumns = [
  {
    label: "Start Date",
    field: "lockUpStartDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "End Date",
    field: "lockUpEndDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Lock Period",
    field: "lockPeriod",
    sort: "asc",
    width: 170,
  },
  {
    label: "Booster Status",
    field: "boosterStatus",
    sort: "asc",
    width: 170,
  },
  {
    label: "View More",
    field: "viewDetails",
    sort: "asc",
    width: 70,
  },
];

export const lockupColumns = [
  {
    label: "Date",
    field: "transactionTimeStamp",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 70,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Cycle Info",
    field: "cycleId",
    sort: "asc",
    width: 170,
  },
  {
    label: "View More",
    field: "viewDetails",
    sort: "asc",
    width: 170,
  },
];

export const interestDisbursementsColumns = [
  {
    label: "Date",
    field: "disbursedAt",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "Total Disbursement Interest",
    field: "totalDisbursementInterest",
    sort: "asc",
    width: 170,
  },
  {
    label: "Total Impacted Users",
    field: "totalImpactedUsers",
    sort: "asc",
    width: 170,
  },
];

export const accruedInterestsColumns = [
  {
    label: "Date",
    field: "disbursedAt",
    sort: "asc",
    width: 170,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Cycle Info",
    field: "cycleId",
    sort: "asc",
    width: 170,
  },
];

export const accruedInterestsUserColumns = [
  {
    label: "Date",
    field: "transactionTimeStamp",
    sort: "asc",
    width: 170,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Cycle Info",
    field: "cycleId",
    sort: "asc",
    width: 170,
  },
];

export const cyclesUserColumns = [
  {
    label: "Start Date",
    field: "lockUpStartDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "End Date",
    field: "lockUpEndDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Lock Period",
    field: "lockPeriod",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "boosterStatus",
    sort: "asc",
    width: 170,
  },
  {
    label: "View More",
    field: "viewDetails",
    sort: "asc",
    width: 70,
  },
];

export const lockupUserColumns = [
  {
    label: "Date",
    field: "transactionTimeStamp",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "Mining Reward Info",
  //   field: "rewardId",
  //   sort: "asc",
  //   width: 170,
  // },
  {
    label: "Cycle Info",
    field: "cycleId",
    sort: "asc",
    width: 170,
  },
];

export const lockupCyclesColumns = [
  {
    label: "Date",
    field: "transactionTimeStamp",
    sort: "asc",
    width: 170,
  },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "User Details",
    field: "userDetails",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Cycle Info",
    field: "cycleId",
    sort: "asc",
    width: 170,
  },
];

// APR columns
export const aprColumns = [
  {
    label: "S/N",
    field: "id",
    sort: "asc",
    width: 170,
  },
  {
    label: "APR",
    field: "apr",
    sort: "asc",
    width: 170,
  },
  {
    label: "Lock Period",
    field: "lockPeriod",
    sort: "asc",
    width: 170,
  }
];