
import styled from "styled-components";
import { theme } from "../../theme";


export const OverviewTitle = styled.p`  
    color: ${theme.grey};
    font-size: ${theme.textXs}; 
    margin-bottom: 5px;
`
export const OverviewValue = styled.p`  
    color: ${(props) => props.color? theme[props.color] : theme.blue};
    font-size: ${theme.textMd}; 
    margin-bottom: 20px;
    font-weight: bold;

`
export const BigSummaryValue = styled.p`  
    color: ${(props) => props.color? theme[props.color] : theme.blue};
    font-size: ${theme.textSmd}; 
    margin-bottom: 50px;
    font-weight: bold;

`
export const DFlex = styled.div`  
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

`
export const LoaderContainer = styled.div`
    padding: 80px;
    display: flex;
    justify-content: center;
`
export const OverviewLabel = styled.p`
    color: ${theme.black};
    font-weight: ${theme.textBold}
    font-size: ${theme.textSm};
    margin-bottom: 0px;
`
