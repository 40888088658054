import styled from "styled-components";

export const StyledParagraph = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  color: #6b6b6b;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border-color: ${props =>
      props.value === props.duration
        ? "#E5B909"
        : "#dcdcdc"};
  &:hover {
    border-color: #E5B909;
  }
  margin-bottom: 0px;
  /* ${({ value, duration }) =>
    value === duration &&
    `
    border-color: "#E5B909";
  `} */
`;