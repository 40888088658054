import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails, getSearchDetails } from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { getTransactionRows } from "../../../utils/TableRows/transactionsTableRows";
import { newDisbursedStakingWeekColumns } from "../../../utils/TableCols/NewTransactionsCols";
import Mainnav from "../../Partials/Mainnav";
import { MainContent } from "../../Partials/partials";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import SubSidenav from "../../Partials/SubSidenav";

const DisbursedStakingListPage = ({history}) => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const transactionCategory = "disbursed-staking-rewards";
  const [transcId, setTranscId] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const newTranscId = window.location.pathname.split("/").at(-1);
    setTranscId(newTranscId);
  }, []); 
 
  useEffect(() => {
    if (transcId) {
      dispatch(getNewTransactionDetails(transactionCategory, transcId));
    }
  }, [transcId, dispatch]);

  let allRows = getTransactionRows(
    newTransactionDetails ? newTransactionDetails.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
  );
  const data = {
    columns: newDisbursedStakingWeekColumns,
    rows: allRows,
  };

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav 
            pageTitle={
              <button
              className="pointer mb-0"
              onClick={goBack}
              style={{background: 'none', border: 'none'}}
            >
              {" "}
              <i className="material-icons">arrow_back</i> Transaction Report
            </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4">
          <SubSidenav />
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <CustomDataTable
                    data={data}
                    tableId={"transactions-report"}
                    pageCount={newTransactionDetails.totalPages}
                    currentPage={newTransactionDetails.currentPage}
                    header={
                      <TableHeader
                        dataLength={newTransactionDetails.records ? newTransactionDetails.records.length : 0}
                        // subHeader={ newDisbursedStakingWeekOptions }
                      
                        onChange={(e, props) => {
                          dispatch(
                            getSearchDetails({
                              props: [props],
                              value: e.target.value,
                            })
                          );
                        }}
                        onSelect={(val, props) => {
                          dispatch(
                            getSearchDetails({
                              props: [props],
                              value: val,
                            })
                          );
                        }}
                        // onSearchClick={fetchAdminTransactions}
                        // searchvalue={ReferenceCode}
                      />
                    }
                    loading={gettingNewTrancactionDetails}
                    noData={
                      <EmptyCard
                        img="/Assets/emptytransactions.svg"
                        title={"Sorry,  you have no transaction history"}
                        // descr={"No transaction history available at the moment"}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default DisbursedStakingListPage;
