import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import {dateRangeData } from "../../Redux/actions";
import Loader from "../Reusables/loaders/HashLoader";
import moment from "moment";
import {
  numberWithCommas,
  numberWithCommaswithoutdecimals
} from "../../Helper";
import filesize from "filesize";
import DualDatePicker from "../Reusables/cards/DualDatePicker";
import {
  OverviewTitle,
  OverviewValue,
  BigSummaryValue,
  DFlex,
  LoaderContainer
} from "./overviewstyles";
import {OverviewLabel} from './overviewstyles'

class DatePickerView extends Component {
  componentWillMount() {
    const { overviewDateFrom, overviewDateTo } = this.props;
    this.props.dateRangeData(
      moment(overviewDateFrom).format("YYYYMMDD"),
      moment(overviewDateTo).format("YYYYMMDD")
    );
  }

  componentWillReceiveProps(nextProps) {
    const { overviewDateFrom, overviewDateTo } = this.props;
    if (overviewDateTo !== nextProps.overviewDateTo || overviewDateFrom !== nextProps.overviewDateFrom) {
      this.props.dateRangeData(
        moment(nextProps.overviewDateFrom).format("YYYYMMDD"),
        moment(nextProps.overviewDateTo).format("YYYYMMDD")
      );
    }
  }

  render() {
    const {
      overviewDateFrom,
      overviewDateTo,
      gettingData,
      dataPerRange,
      sessionsPerRange,
      revenuesPerRange,
    } = this.props;

    return (
      <div className="col-lg-6">
        <div className="card card-small mb-4">
          <div className="card-header border-bottom d-flex">
            <OverviewLabel className="mb-3"> Overview</OverviewLabel>
            <DualDatePicker
              dateTo={overviewDateTo}
              dateFrom={overviewDateFrom}
              dateFromLabel="overviewDateFrom"
              dateToLabel="overviewDateTo"
            />
          </div>
          <div className="container">
            {gettingData ? 
            <LoaderContainer>
              <Loader /> 
            </LoaderContainer>
            
            : 
            <div className="col-md-6 col-lg enterCode">
              <DFlex>
                <div>
                  <OverviewTitle> Sessions</OverviewTitle>
                  <OverviewValue color={"black"}>
                    {" "}
                    {numberWithCommaswithoutdecimals(sessionsPerRange)}{" "}
                  </OverviewValue>
                </div>
                <div>
                  <OverviewTitle> Data Consumed </OverviewTitle>
                  <OverviewValue color={"black"}>
                    {" "}
                    {filesize(dataPerRange)}
                  </OverviewValue>
                </div>
              </DFlex>
              <OverviewTitle> Revenue Generated </OverviewTitle>
              <BigSummaryValue>
                {" "}
                {numberWithCommas(revenuesPerRange)} USD{" "}
              </BigSummaryValue>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    dataPerRange,
    revenuesPerRange,
    sessionsPerRange,
    pickaRange
  } = state.Overview;
  const {overviewDateTo, overviewDateFrom,} = state.User
  const { gettingData } = state.Loading;
  return {
    overviewDateTo,
    overviewDateFrom,
    gettingData,
    dataPerRange,
    revenuesPerRange,
    sessionsPerRange,
    pickaRange
  };
};

export default connect(
  MapStateToProps,
  {dateRangeData }
)(DatePickerView);
