// activity logs --------------------------------------------
export const activitylogsOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Action Type",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Transaction",
      },
      {
        id: 3,
        option: "Account",
      },
    ],
  },
  {
    id: 3,
    title: "Search",
    type: "text",
    placeholder: "Enter location, ip or device",
  },
  {
    id: 4,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "report-table",
  },
];

// transaction-------------------------------------------------------
export const transactionsHeaderOptionTypes = [
  {
    id: 1,
    name: "Bank",
  },
  {
    id: 2,
    name: "Crypto",
  },
  {
    id: 3,
    name: "P2P",
  },
  {
    id: 4,
    name: "Transfer",
  },
  {
    id: 5,
    name: "Mining-Reward",
  },
];

export const p2pWebhooks = [
  {
    id: 1,
    name: "P2P Confirmations",
  },
  {
    id: 2,
    name: "P2P Cancellations",
  },
];

export const switchWalletOptions = [
  {
    id: 1,
    name: "Switchallet Report",
  },
  {
    id: 2,
    name: "Webhook Deposits",
  },
  {
    id: 3,
    name: "Webhook Withdrawals",
  },
];

export const p2pOptions = [
  // {
  //   id: 1,
  //   title: "Wallet",
  //   onSelectProp: "WalletType",
  //   type: "select",
  //   options: [
  //     {
  //       id: 1,
  //       option: "All",
  //     },
  //     {
  //       id: 2,
  //       option: "Personal",
  //     },
  //     {
  //       id: 3,
  //       option: "Business",
  //     },
  //     {
  //       id: 3,
  //       option: "WNT",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: "Type",
    onSelectProp: "TransactionType",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Buy",
      },
      {
        id: 3,
        option: "Sell",
      },
      {
        id: 4,
        option: "Swap",
      }
    ],
  },
  {
    id: 3,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 4,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Completed",
      },
      {
        id: 3,
        option: "Cancelled",
      },
      // {
      //   id: 4,
      //   option: "Pending",
      // },
    ],
  },
  {
    id: 5,
    title: "Reference Code",
    type: "text",
    placeholder: "Enter Code here",
    onChangeProp: "ReferenceCode",
  },
  {
    id: 6,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "transactions-report",
  },
];

export const transferOptions = [
         {
           id: 1,
           title: "Type",
           onSelectProp: "TransferType",
           type: "select",
           options: [
             {
               id: 1,
               option: "All",
             },
             {
               id: 2,
               option: "Credit",
             },
             {
               id: 2,
               option: "Debit",
             },
           ],
         },
         {
           id: 3,
           title: "Duration",
           type: "select",
           onSelectProp: "duration",
           options: [
             {
               id: 1,
               option: "All",
             },
             {
               id: 2,
               option: "Last Week",
             },
             {
               id: 3,
               option: "Last Month",
             },
             {
               id: 4,
               option: "Last 5 Months",
             },
             {
               id: 5,
               option: "Last Year",
             },
           ],
         },
         {
           id: 4,
           title: "Reference Code",
           type: "text",
           placeholder: "Enter code here",
           onChangeProp: "ReferenceCode",
         },
         {
           id: 5,
           title: "Export Report",
           type: "exportButton",
           icon: "receipt_long",
           tableId: "transactions-report",
         },
       ];

export const transactionsOptions = [
  // {
  //   id: 1,
  //   title: "Wallet",
  //   type: "select",
  //   onSelectProp: "WalletType",
  //   options: [
  //     {
  //       id: 1,
  //       option: "All",
  //     },
  //     {
  //       id: 2,
  //       option: "Personal",
  //     },
  //     {
  //       id: 3,
  //       option: "Business",
  //     },
  //     {
  //       id: 4,
  //       option: "WNT",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: "Type",
    type: "select",
    onSelectProp: "TransactionType",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Funding",
      },
      {
        id: 3,
        option: "Withdraw",
      },
      {
        id: 4,
        option: "Withdrawal Reversal",
      },
      // {
      //   id: 5,
      //   option: "Transfer",
      // },
      // {
      //   id: 6,
      //   option: "Voucher",
      // },
      // {
      //   id: 7,
      //   option: "Staking",
      // },
      // {
      //   id: 8,
      //   option: "Mining Reward",
      // },
    ],
  },
  {
    id: 3,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 4,
    title: "Reference Code",
    type: "text",
    placeholder: "Enter code here",
    onChangeProp: "ReferenceCode",
  },
  {
    id: 5,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "transactions-report",
  },
];
export const miningRewardsOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Type",
    type: "select",
    onSelectProp: "TransactionType",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Weekly Reward",
      },
      {
        id: 3,
        option: "Claim Reward",
      },
    ],
  },
  {
    id: 3,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Successful",
      },
      {
        id: 3,
        option: "Pending",
      },
      {
        id: 4,
        option: "Failed",
      },
    ],
  },
  {
    id: 5,
    title: "Search",
    type: "text",
    placeholder: "Search here",
    onChangeProp: "userId",
  },
  // {
  //   id: 4,
  //   title: "Export Report",
  //   type: "exportButton",
  //   icon: "receipt_long",
  //   tableId: "transactions-report",
  // },
];

export const orderConfirmationOptions = [
  {
    id: 1,
    title: "OrderType",
    type: "select",
    onSelectProp: "orderType",
    options: [
      {
        id: 1,
        option: "Swap",
      },
      {
        id: 2,
        option: "Buy",
      },
      {
        id: 3,
        option: "Sell",
      },
    ],
  },
  {
    id: 2,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Pending",
      },
      {
        id: 3,
        option: "Completed",
      },
      {
        id: 4,
        option: "Failed",
      },
      {
        id: 5,
        option: "Not Reflected",
      },
      {
        id: 6,
        option: "Not Propagated",
      },
    ],
  },
  {
    id: 3,
    title: "Search",
    type: "text",
    placeholder: "Enter Order Reference here",
    onChangeProp: "search",
  },
];

export const orderCancellationOptions = [
  
  {
    id: 1,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Pending",
      },
      {
        id: 3,
        option: "Completed",
      },
      {
        id: 4,
        option: "Failed",
      },
      {
        id: 5,
        option: "Not Reflected",
      },
      {
        id: 6,
        option: "Not Propagated",
      },
      {
        id: 7,
        option: "Cancelled",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Enter Order Reference here",
    onChangeProp: "search",
  },
];

export const transactionsCommonColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "Wallet",
  //   field: "wallet",
  //   sort: "asc",
  //   width: 170,
  // },
  {
    label: "Transaction Type",
    field: "type",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const p2pConfirmationColumn = [
  {
    label: "Created At",
    field: "createdDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed On",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Customer Detail",
    field: "customerDetail",
    sort: "asc",
    width: 170,
  },
  {
    label: "Liquidity Provider",
    field: "liquidityProvider",
    sort: "asc",
    width: 170,
  },
  {
    label: "Order Reference",
    field: "referenceCode",
    sort: "asc",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 70,
  },
  {
    label: "Order Status",
    field: "orderStatusConfirm",
    sort: "asc",
    width: 70,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const switchWalletReportColumn = [
  {
    label: "Date",
    field: "dateCreated",
    sort: "asc",
    width: 170,
  },
  {
    label: "Deposits",
    field: "deposits",
    sort: "asc",
    width: 170,
  },
  {
    label: "Withdrawals",
    field: "withdrawals",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 170,
  },

]

export const switchWalletDepositColumn = [
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed On",
    field: "processedOn",
    sort: "asc",
    width: 170,
  },
  {
    label: "Client",
    field: "merchantClient",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed Status",
    field: "statusProcessed",
    sort: "asc",
    width: 170,
  },

]

export const switchWalletWithdrawalColumn = [
  {
    label: "Created At",
    field: "createdAt",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed On",
    field: "processedOn",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed Status",
    field: "statusProcessed",
    sort: "asc",
    width: 170,
  },

]

export const p2pCancellationColumn = [
  {
    label: "Created At",
    field: "createdDate",
    sort: "asc",
    width: 170,
  },
  {
    label: "Processed On",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Initiated by",
    field: "initiatedBy",
    sort: "asc",
    width: 170,
  },
  {
    label: "Order Reference",
    field: "orderReference",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 70,
  },
  {
    label: "Failure Reason",
    field: "failureReason",
    sort: "asc",
    width: 70,
  },
  {
    label: "Order Status",
    field: "orderStatus",
    sort: "asc",
    width: 70,
  },
];

export const p2pColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Type",
    field: "type",
    sort: "asc",
    width: 170,
  },
  {
    label: "Customer",
    field: "customerInfo",
    sort: "asc",
    width: 170,
  },
  {
    label: "Liquidity Provider",
    field: "provider",
    sort: "asc",
    width: 170,
  },
  {
    label: "Buy Amount",
    field: "buyAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Sell Amount",
    field: "sellAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const cryptoColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 470,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "Wallet",
  //   field: "wallet",
  //   sort: "asc",
  //   width: 170,
  // },
  // {
  //   label: "Network",
  //   field: "network",
  //   sort: "asc",
  //   width: 170,
  // },
  {
    label: "Wallet Address",
    field: "address",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Type",
    field: "type",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reference Code",
    field: "referenceCode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const miningRewardColumns = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "Type",
  //   field: "type",
  //   sort: "asc",
  //   width: 170,
  // },
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

// devices options
export const deviceOptions = [
  {
    id: 1,
    title: "Device Type",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Original",
      },
      {
        id: 3,
        option: "Jump Start",
      },
    ],
  },
  {
    id: 2,
    title: "Model",
    type: "select",
    options: [
      { id: 1, option: "All" },
      { id: 2, option: "Spider" },
      { id: 3, option: "Lynx" },
      { id: 2, option: "Basic Spider" },
      { id: 3, option: "Basic Lynx" },
    ],
  },
  {
    id: 3,
    title: "Top Filter",
    type: "select",
    options: [
      { id: 1, option: "Default" },
      { id: 2, option: "Top Sessions" },
      { id: 3, option: "Top Data Usage" },
      { id: 4, option: "Top Uptime" },
      { id: 5, option: "Top Rewards" },
      { id: 6, option: "Top Revenue" },
      { id: 7, option: "Top Users" },
    ],
  },
  {
    id: 5,
    title: "Search",
    type: "text",
    placeholder: "Device name",
  },
  {
    id: 6,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "user-device-report",
  },
];

// stake-------------------------------------------------------------------------------------
export const StakeHeaderOptionTypes = [
  {
    id: 1,
    name: "Stake",
  },
  {
    id: 2,
    name: "Unstake",
  },
  {
    id: 3,
    name: "Claims",
  },
];

export const stakeOptions = [
  {
    id: 1,
    title: "Period",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "ABOUT TO INITIATE",
      },
      {
        id: 3,
        option: "PENDING",
      },
      {
        id: 4,
        option: "CONFIRMED",
      },
      {
        id: 5,
        option: "FAILED",
      },
      {
        id: 6,
        option: "NOT_PROPAGATED",
      },
      {
        id: 7,
        option: "SKIPPED",
      },
    ],
  },
  {
    id: 3,
    title: "Duration",
    onSelectProp: "type",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "NO_LOCK",
      },
      {
        id: 3,
        option: "THREE_MONTHS",
      },
      {
        id: 4,
        option: "SIX_MONTHS",
      },
      {
        id: 5,
        option: "NINE_MONTHS",
      },
      {
        id: 6,
        option: "TWELVE_MONTHS",
      },
    ],
  },
 
];

export const statOptionsStake = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "stakeDurationFilter",
    options: [
      {
        id: 1,
        option: "Flexible",
      },
      {
        id: 2,
        option: "Three Months",
      },
      {
        id: 3,
        option: "Six Months",
      },
      {
        id: 4,
        option: "Nine Months",
      },
      {
        id: 5,
        option: "Twelve Months",
      },
    ],
  },
 
];

export const SwitchwalletDepositOptions = [
  // {
  //   id: 1,
  //   title: "Currency",
  //   onSelectProp: "Currency",
  //   type: "select",
  //   options: [
  //     {
  //       id: 1,
  //       option: "All",
  //     },
  //     {
  //       id: 2,
  //       option: "USDT",
  //     },
  //     {
  //       id: 2,
  //       option: "WNT",
  //     },
  //   ],
  // },
  {
    id: 1,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Pending",
      },
      {
        id: 3,
        option: "Confirmed",
      },
      {
        id: 4,
        option: "Failed",
      },
      {
        id: 5,
        option: "Not Propagated",
      },
      {
        id: 6,
        option: "Not Reflected",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Enter transaction hash or email or wallet address here",
    onChangeProp: "search",
  },
];
// ------------------------------------------------------------------------------------------------------------------------
// New transaction option for users

export const newTransferUserOptions = [
 
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Reference Code",
    type: "text",
    placeholder: "Enter code here",
    onChangeProp: "ReferenceCode",
  },
];

export const newp2pUserOptions = [
 
 {
   id: 1,
   title: "Order Type",
   onSelectProp: "TransactionType",
   type: "select",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Buy",
     },
     {
       id: 3,
       option: "Sell",
     },
     {
       id: 4,
       option: "Swap",
     }
   ],
 },
 {
   id: 2,
   title: "Duration",
   type: "select",
   onSelectProp: "duration",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Last Week",
     },
     {
       id: 3,
       option: "Last Month",
     },
     {
       id: 4,
       option: "Last 5 Months",
     },
     {
       id: 5,
       option: "Last Year",
     },
   ],
 },
 // {
 //   id: 3,
 //   title: "Status",
 //   type: "select",
 //   onSelectProp: "Status",
 //   options: [
 //     {
 //       id: 1,
 //       option: "All",
 //     },
 //     {
 //       id: 2,
 //       option: "Submitted",
 //     },
 //     {
 //       id: 3,
 //       option: "Cancelled",
 //     },
 //     {
 //       id: 4,
 //       option: "Acknowledged",
 //     },
 //     {
 //       id: 5,
 //       option: "FundedProvider",
 //     },
 //     {
 //       id: 6,
 //       option: "FundReceivedFromUser",
 //     },
 //     {
 //       id: 7,
 //       option: "FundedUser",
 //     },
 //     {
 //       id: 8,
 //       option: "Completed",
 //     },
 //     {
 //       id: 9,
 //       option: "OrderTimedOut",
 //     },
 //   ],
 // },
 {
   id: 3,
   title: "Status",
   type: "select",
   onSelectProp: "Status",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Completed",
     },
     {
       id: 3,
       option: "Cancelled",
     },
     // {
     //   id: 4,
     //   option: "Pending",
     // },
   ],
 },

 {
   id: 4,
   title: "Reference Code",
   type: "text",
   placeholder: "Enter Code here",
   onChangeProp: "ReferenceCode",
 },
];

export const newStakingUserOptions = [
  
  {
    id: 1,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "startTime",
  },
  {
    id: 2,
    title: "End Date",
    type: "endTime",
    onChangeProp: "endTime",
  },
];

export const cryptoWithdrawalUserOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "LastWeek",
      },
      {
        id: 3,
        option: "LastMonth",
      },
      {
        id: 4,
        option: "Last3Months",
      },
      {
        id: 5,
        option: "LastYear",
      },
      {
        id: 6,
        option: "Custom",
      },
    ],
  },
  {
    id: 2,
    title: "Start Date",
    type: "customStartTime",
    onChangeProp: "startTime",
  },
  {
    id: 3,
    title: "End Date",
    type: "customEndTime",
    onChangeProp: "endTime",
  },
  {
    id: 4,
    title: "Currency Id",
    type: "text",
    placeholder: "Enter currency Id here",
    onChangeProp: "CurrencyId",
  },
  {
    id: 5,
    title: "Transaction Hash",
    type: "text",
    placeholder: "Enter transaction hash here",
    onChangeProp: "TransactionHash",
  },
  {
    id: 6,
    title: "Destination Wallet Address",
    type: "text",
    placeholder: "Enter address here",
    onChangeProp: "DestinationWalletAddress",
  },
  {
    id: 7,
    title: "Withdrawal Reference",
    type: "text",
    placeholder: "Enter Code here",
    onChangeProp: "WithdrawalReference",
  },
];

export const cryptoDepositUserOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "LastWeek",
      },
      {
        id: 3,
        option: "LastMonth",
      },
      {
        id: 4,
        option: "Last3Months",
      },
      {
        id: 5,
        option: "LastYear",
      },
      {
        id: 6,
        option: "Custom",
      },
    ],
  },
  {
    id: 2,
    title: "Start Date",
    type: "customStartTime",
    onChangeProp: "startTime",
  },
  {
    id: 3,
    title: "End Date",
    type: "customEndTime",
    onChangeProp: "endTime",
  },
  {
    id: 4,
    title: "Currency Id",
    type: "text",
    placeholder: "Enter currency Id here",
    onChangeProp: "CurrencyId",
  },
  {
    id: 5,
    title: "Transaction Hash",
    type: "text",
    placeholder: "Enter transaction hash here",
    onChangeProp: "TransactionHash",
  },
  {
    id: 6,
    title: "Receiver Wallet Address",
    type: "text",
    placeholder: "Enter address here",
    onChangeProp: "ReceiverWalletAddress",
  },
];

export const NewtransactionsHeaderOptionTypes = [
  {
    id: 1,
    name: "Transfer",
  },
  {
    id: 2,
    name: "P2P",
  },
  {
    id: 3,
    name: "Crypto-Withdrawal",
  },
  {
    id: 4,
    name: "Crypto-Deposit",
  },
  {
    id: 5,
    name: "Mining-Reward",
  },
  {
    id: 6,
    name: "Staking",
  },
  {
    id: 7,
    name: "Staking-Interest-Claims",
  },
];