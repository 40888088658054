import styled from "styled-components";
import { theme } from "../../theme";

export const StakeStatData = styled.div`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: ${theme.grey};
margin-top: 10px;
`;
export const StakeStatValue = styled.div`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #29313c;
margin-bottom: 10px;
`;