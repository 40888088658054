import React, { Component } from "react";
import { connect } from "react-redux";
import { declineVerification, getFormDetails } from "../../Redux/actions";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";

class RejectVerification extends Component {
  render() {
    const {
      oneRequest,
      status,
      startDate,
      endDate,
      verificationRejectionReason,
      decliningVerification,
    } = this.props;
    return (
      <Modal heading={`Reason for rejection`}>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control loginCred mb3"
            aria-describedby="basic-addon1"
            onChange={(e) =>
              this.props.getFormDetails({
                props: ["verificationRejectionReason"],
                value: e.target.value,
              })
            }
            style={{ width: "400px" }}
          />
        </div>
        {decliningVerification ? (
          <Button theme=" ml-3 mt-2 float wicryptColor">
            {" "}
            <Loader />
          </Button>
        ) : (
          <Button
            disabled={verificationRejectionReason === "" ? true : false}
            theme=" ml-3 mt-2 float wicryptColor"
            buttonAction={() =>
              this.props.declineVerification(
                {
                  reasonForRejection: verificationRejectionReason,
                  requestId: oneRequest.id,
                },
                status,
                startDate,
                endDate
              )
            }
          >
            Enter
          </Button>
        )}
      </Modal>
    );
  }
}
const MapStateToProps = (state) => {
  const { oneRequest } = state.Verification;
  const {
    status,
    startDate,
    endDate,
    verificationRejectionReason,
  } = state.User;
  const { decliningVerification } = state.Loading;
  return {
    oneRequest,
    status,
    startDate,
    endDate,
    verificationRejectionReason,
    decliningVerification,
  };
};
export default connect(MapStateToProps, {
  declineVerification,
  getFormDetails,
})(RejectVerification);
