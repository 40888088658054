import React, { Component } from "react";
// import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {
  adminRemittance,
  getFormDetails,
  allBusinesses,
  clearRemittance,
} from "../../Redux/actions";
import { connect } from "react-redux";
import InfiniteScroll from "../Reusables/cards/LoadMore";
import Datepicker from "react-datepicker";
import { numberWithCommas } from "../../Helper";
import moment from "moment";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CustomLoader from "../Reusables/loaders/CustomLoader";

class AgentRemmitance extends Component {
  componentWillMount() {
    const {
      selectedBusiness,
      remitStart,
      remitEnd,
      remittanceReport,
    } = this.props;
    if (remittanceReport.length === 0) {
      this.props.adminRemittance(selectedBusiness, remitStart, remitEnd, 1, 50);
    }
    if (this.props.allBusiness.length === 0) {
      this.props.allBusinesses(1, 30, "");
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedBusiness, remitStart, remitEnd } = nextProps;
    if (
      selectedBusiness !== this.props.selectedBusiness ||
      remitStart !== this.props.remitStart ||
      remitEnd !== this.props.remitEnd
    ) {
      this.props.clearRemittance();
      this.props.adminRemittance(selectedBusiness, remitStart, remitEnd, 1, 50);
    }
  }

  customPagination() {
    const {
      remittanceTotalPages,
      remittanceCurrentPage,
      remitStart,
      remitEnd,
      selectedBusiness,
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.adminRemittance(
              selectedBusiness,
              remitStart,
              remitEnd,
              remittanceCurrentPage - 1,
              50
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; remittanceTotalPages >= i; i++) {
      if (i === remittanceCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.adminRemittance(
                selectedBusiness,
                remitStart,
                remitEnd,
                i,
                50
              )
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (remittanceTotalPages > remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.adminRemittance(
              selectedBusiness,
              remitStart,
              remitEnd,
              remittanceCurrentPage + 1,
              50
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  fetchMorBusinesses = () => {
    const { allBusinessCurrentPage, allBusinessTotalPages } = this.props;

    let page = allBusinessCurrentPage;
    if (page < allBusinessTotalPages) {
      page++;
    }

    this.props.allBusinesses(page, 30, "");
  };

  render() {
    const {
      remittanceReport,
      allBusiness,
      remitStart,
      remitEnd,
      gettingRemittanceReport,
      allBusinessTotalRecords,
      conversionsRate,
    } = this.props;

    let allRows = [];
    remittanceReport.map((remit) => {
      return allRows.push({
        date: moment
          .parseZone(remit.remittanceDateTimeStamp)
          .format("YYYY-MM-DD"),
        business: remit.agent.business.name,
        username: remit.agent.user.userName,
        voucherRemittanceAllowance:
          remit.agent.voucherRemittanceAllowance +
          "WC | NGN" +
          numberWithCommas(
            remit.agent.voucherRemittanceAllowance * conversionsRate
          ),
        defaultRemittanceAllowance:
          remit.agent.business.defaultRemittanceAllowance +
          "WC | NGN" +
          numberWithCommas(
            remit.agent.business.defaultRemittanceAllowance * conversionsRate
          ),
        amount:
          remit.amountRemitted +
          "WC | NGN" +
          numberWithCommas(remit.amountRemitted * conversionsRate),
      });
    });

    const data = {
      columns: [
        {
          label: "Date Remitted",
          field: "date",
          sort: "asc",
          width: 170,
        },
        {
          label: "Business Name",
          field: "business",
          sort: "asc",
          width: 170,
        },
        {
          label: "Agent Username",
          field: "username",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Remittance Allowance",
          field: "voucherRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Default Remittance Allowance",
          field: "defaultRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Amount Remitted",
          field: "amount",
          sort: "asc",
          width: 270,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3">
          <div className="mt-3 d-flex">
            <div className="mr-3"> FROM</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitStart"],
                  value: e.getTime(),
                })
              }
              selected={remitStart}
              dateFormat={"dd/MM/yyyy"}
            />

            <div className="mr-3 ml-3"> TO</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitEnd"],
                  value: e.getTime(),
                })
              }
              selected={remitEnd}
              dateFormat={"dd/MM/yyyy"}
            />

            {/* </div> */}
            <InfiniteScroll
              dataLength={allBusiness.length}
              hasMore={
                allBusiness.length < allBusinessTotalRecords ? true : false
              }
              items={
                <select
                  className="input-sm form-control"
                  style={{ width: "auto" }}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["selectedBusiness"],
                      value: e.target.value,
                    })
                  }
                >
                  <option>Select Business</option>
                  {allBusiness &&
                    allBusiness.map((business) => {
                      return (
                        <option value={business.id}>{business.name}</option>
                      );
                    })}
                </select>
              }
              moreData={this.fetchMorBusinesses}
            />
          </div>
        </div>
        {gettingRemittanceReport & (remittanceReport.length === 0) ? (
          <div className="volucherNote mt-5 t-center ">
            <CustomLoader size="10" />{" "}
          </div>
        ) : remittanceReport.length > 0 ? (
          <CustomDataTable data={data} pagination={this.customPagination()} />
        ) : (
          <p className="voucherText t-center">
            {" "}
            Select a business and a date range to view valid data
          </p>
        )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const {
    remittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    conversionsRate,
  } = state.Report;
  const {
    allBusiness,
    allBusinessTotalRecords,
    allBusinessCurrentPage,
    allBusinessTotalPages,
  } = state.Manager;
  const { selectedBusiness, remitStart, remitEnd } = state.User;
  const { gettingRemittanceReport } = state.Loading;
  return {
    remittanceReport,
    allBusiness,
    selectedBusiness,
    remitStart,
    remitEnd,
    gettingRemittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    allBusinessTotalRecords,
    allBusinessCurrentPage,
    conversionsRate,
    allBusinessTotalPages,
  };
};
export default connect(MapStateToProps, {
  adminRemittance,
  getFormDetails,
  clearRemittance,
  allBusinesses,
})(AgentRemmitance);
