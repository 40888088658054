import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import { handleModal } from './GeneralAction'
import {getBusinessBalance} from "./ManagerAction"


//Transfer wicrypt credit
export const transferwicryptcredit = (
  transfertype,
  recepientId,
  amount,
  pin,
  
) => {
    let data = {amount, pin}
    let transferUrl = transfertype === "Personal" ? `/v2/users/${recepientId}/transfer` : `/v2/users/${recepientId}/business/transfer`
  return (dispatch) => {
    dispatch({
      type: _const.TRANSFERING_CREDIT,
      payload: true,
    });
    dispatch({
        type: _const.TRANSFERING_WICRYPT_ERROR,
        payload: ''
    })
    axios
      .post(config.apiUrl + transferUrl, data)
      .then((res) => {
        dispatch({
          type: _const.TRANSFERING_CREDIT,
          payload: false,
        });
        if(res.data.success){
            dispatch(handleModal(6));
            dispatch(getBusinessBalance())
            dispatch({
                type: _const.CREDIT_TRANSFERRED,
                payload: true
            })
        }else{
            dispatch({
                type: _const.TRANSFERING_WICRYPT_ERROR,
                payload: res.data.messgae
            })
        }
      })
      .catch((err) => {
          dispatch({
              type: _const.TRANSFERING_WICRYPT_ERROR,
              payload: err.response.data.message
          })
        dispatch({
          type: _const.TRANSFERING_CREDIT,
          payload: false,
        });
      });
  };
};


//get max withdrawable balance on personal wallet
export const getmaxwithdrawalforpersonal = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/fiatTransfer/PersonalWalletProfit`)
      .then((res) => {
        dispatch({
          type: _const.MAX_WITHDRAWABLE,
          payload: res.data.data
        })
      })
      .catch((err) => {
          
      });
  };
};