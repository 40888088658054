import _const from './Types'
import axios from 'axios'
import config from '../reducers/config'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { toggleModal } from './GeneralAction'

//Get all service charge beneficiaries
export const getAllServiceCharge = () => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_SERVICE_CHARGE,
            payload: true
        })
        axios.get(config.apiUrl + `/v2/serviceCharge`)
            .then(res => {
                dispatch({
                    type: _const.GETTING_SERVICE_CHARGE,
                    payload: false
                })
                dispatch({
                    type: _const.ALL_SERVICE_CHARGE,
                    payload: res.data.data === null ? {} : res.data.data
                })

            })
            .catch(err =>
                dispatch({
                    type: _const.GETTING_SERVICE_CHARGE,
                    payload: false
                })
            )
    }


}

//Beneficiary deleted successfully
export const deleteServiceCharge = (id) => {
    return (dispatch) => {
        Swal.fire({
            text: 'Are you sure you want to delete this beneficiary?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                dispatch({
                    type: _const.DELETING_SERVICE_CHARGE,
                    payload: true,
                    id: id
                })
                axios.delete(config.apiUrl + '/v2/serviceCharge')
                    .then(res => {
                        dispatch({
                            type: _const.DELETING_SERVICE_CHARGE,
                            payload: false,
                            id: ''
                        })

                        if (res.data.success) {
                            dispatch(getAllServiceCharge())
                            Swal.fire({
                                text: 'Beneficiary deleted successfully!',
                                type: 'success'
                            })
                        }
                    })
                    .catch(err =>
                        dispatch({
                            type: _const.DELETING_SERVICE_CHARGE,
                            payload: true,
                            id: ''
                        }))

            }
        })
    }


}

export const createServiceCharge = (percentage, emailAddress) => {
    let data = {
        "percentage": percentage,
        "emailAddress": emailAddress
    }

    return (dispatch) => {
        dispatch({
            type: _const.CREATING_SRVICE_CHARGE,
            payload: true
        })
        dispatch({
            type: _const.CHARGE_FAILED,
            payload: ""
        })
        axios.post(config.apiUrl + '/v2/serviceCharge', data)
            .then(res => {
                dispatch({
                    type: _const.CREATING_SRVICE_CHARGE,
                    payload: false
                })
                if (res.data.success) {
                    dispatch(getAllServiceCharge())
                    dispatch(toggleModal(false))
                    Swal.fire({
                        text: 'Service Charge assigned successfully',
                        type: 'success'
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: _const.CREATING_SRVICE_CHARGE,
                    payload: false
                })
                dispatch({
                    type: _const.CHARGE_FAILED,
                    payload: err.response ? err.response.data.message : 'Something went wrong'
                })
            }
            )
    }


}