import React from "react";
import { useSelector } from "react-redux";
import { SubmitButton, SubmitButtonContainer } from "../Devices/modals/styles";
import Loader from "../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../Reusables/modals/CustomModal";
import { TopicContent, TopicSubContent } from "./topicStyles";

function ReplayDeadLetterModal({ subscriberName, onSubmit, replayError }) {
  const { updatingDeadLetterTopic } = useSelector((state) => state.Loading);
//   const clearReplayErrorMain = () => {
//     dispatch(clearReplayError());
//   };

  return (
      <div>
          <CustomErrorModal
              id={80}
              heading="Replay Dead Letter Topic"
              close={true}
              content={
                  <>
                    <TopicContent className="t-center">Are you sure you want to replay this dead letter topic: </TopicContent>
                    <TopicSubContent className="t-center fw-bolder"> {subscriberName}</TopicSubContent>
                    {replayError ? <p className="errorMsgs mt-3 t-center mb-0">{replayError}</p> : ""}
                    <SubmitButtonContainer onClick={onSubmit}>
                        <SubmitButton>{updatingDeadLetterTopic ? <Loader /> : "Confirm"}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default ReplayDeadLetterModal;
