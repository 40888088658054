import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleNav,
} from "../../Redux/actions";
import {
  MobileNavContainer,
  LogoContainer,
  InlineFlex,
  MobileLogo,
} from "./partials";
import { ValueFlex } from "../Reusables/cards/cards";
import TopNavDropDown from "./TopNavDropDown";
import SubMainnav from "./SubMainnav";

const Mainnav = ({ listItems }) => {
  const { navToggle } = useSelector((state) => state.Report);
  const dispsatch = useDispatch();

  return (
    <MobileNavContainer>
      <ValueFlex>
        <LogoContainer>
          <MobileLogo alt="img-logo" src="/Assets/images/logo.svg" />
        </LogoContainer>
        <InlineFlex>
          <img
            src="/Assets/images/Union.svg"
            onClick={() => dispsatch(toggleNav(!navToggle))}
            alt="harmburger menu"
          />
        </InlineFlex>
      </ValueFlex>
      {navToggle ? <TopNavDropDown /> : ""}
      {listItems ? <SubMainnav listItems={listItems} /> : ""}
    </MobileNavContainer>
  );
};

export default Mainnav;
