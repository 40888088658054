import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { initializeUser, logOut } from "./Redux/actions";
import { connect } from "react-redux";
import PrivateRoutes from "./Routes/privateRoute";
import Invitation from "./Components/Admins/Modal/Invitation";

class Router extends Component {
  componentWillMount() {
    this.props.initializeUser();
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let expiresIn = user.expires;
      let today = Math.round(new Date().getTime() / 1000);
      if (expiresIn <= today) {
        this.props.logOut();
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="h-100">
          <div className="container-fluid">
            <Invitation />
            <Switch>
              <Route path="/" component={PrivateRoutes} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

const MapStateToProps = (state) => {
  return state;
};

export default connect(MapStateToProps, { initializeUser, logOut })(Router);
