import React from "react";
import { numberWithCommas } from "../../../../Helper";
import { PieChart } from "../../../Reusables";
import { PieContainer } from "../transactionoverviewstyles";

const WithdrawalChart = ({ withdrawalOverview }) => {
  return (
    <PieContainer>
      {withdrawalOverview && (
        <PieChart
          data={[
            withdrawalOverview.fiatWithdrawal,
            withdrawalOverview.fiatWithdrawalReversal,
            withdrawalOverview.cryptoWithdrawal,
          ]}
          backgroundColorArray={["#EFE6BF", "#CFA809", "#D5C481"]}
          labels={[
            // `Fiat Withdrawals ${withdrawalOverview.currency +
            //   " " +
            //   numberWithCommas(withdrawalOverview.fiatWithdrawal)}`,
            // `Fiat Withdrawal Reversal ${withdrawalOverview.currency +
            //   " " +
            //   numberWithCommas(withdrawalOverview.fiatWithdrawalReversal)}`,
            `Crypto Withdrawal ${withdrawalOverview.currency +
              " " +
              numberWithCommas(withdrawalOverview.cryptoWithdrawal)}`,
          ]}
        />
      )}
    </PieContainer>
  );
};

export default WithdrawalChart;
