import React from 'react'
import Sidenav from '../Partials/Sidenav'
import Mainnav from '../Partials/Mainnav'
import Fund from './Fund'
import BreadCrumbs from '../Partials/Breadcrumbs'
import { MainContent } from '../Partials/partials'



const UsersMap = () =>{
    return (
        <div className="row">
        <Sidenav/>
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Transaction Report"/>
                </div> 
                <BreadCrumbs/>
                <div className="main-content-container container-fluid px-4 mt-5">
                <Fund />
                </div>
            </MainContent>
            
       </div>
    )
}

export default UsersMap