import React, { Component } from "react";
import { connect } from "react-redux";
import { voucherBalance, getFormDetails } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { numberWithCommas, precise_round } from "../../Helper";

class VoucherBalance extends Component {
  render() {
    const {
      gettingVoucherBalance,
      totalvoucherBalance,
      voucherbalancePhoneNumber,
      voucherCode,
      balanceErr,
      phoneNumberError,
      voucherCodeError,
    } = this.props;

    return (
      <div>
        <div className="col-lg-6 offset-lg-2 col-xs-12">
          <div className="card card-small mb-4">
            <div className="card-header border-bottom d-flex jc">
              <h6 className="m-0">CHECK VOUCHER BALANCE</h6>
            </div>

            <div className="container">
              <div className="col-md-6 col-lg enterCode">
                <form>
                  <label> Enter Phone Number </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control loginCred"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={voucherbalancePhoneNumber}
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["voucherbalancePhoneNumber"],
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="text-red t-12">
                    {" "}
                    {phoneNumberError !== "" ? "*" + phoneNumberError : ""}
                  </div>{" "}
                  <label> Enter Voucher Code</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control loginCred"
                      value={voucherCode}
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["voucherCode"],
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="text-red t-12">
                    {" "}
                    {voucherCodeError !== "" ? "*" + voucherCodeError : ""}
                  </div>{" "}
                  <span className="text-red t-12">
                    {" "}
                    {balanceErr !== "" ? "*" + balanceErr : ""}
                  </span>
                  <div className=" mb-4 mt-3 authbtns">
                    <button
                      type="button"
                      className="mb-4 btn wicryptColor customBtn float button"
                      onClick={() => {
                        if (!voucherbalancePhoneNumber) {
                          this.props.getFormDetails({
                            props: ["phoneNumberError"],
                            value: "Please enter your phone number",
                          });
                        } else if (!voucherCode) {
                          this.props.getFormDetails({
                            props: ["voucherCodeError"],
                            value: "Please enter your voucher code",
                          });
                        } else {
                          this.props.getFormDetails({
                            props: ["phoneNumberError"],
                            value: "",
                          });
                          this.props.getFormDetails({
                            props: ["voucherCodeError"],
                            value: "",
                          });
                          this.props.voucherBalance(
                            voucherbalancePhoneNumber,
                            voucherCode
                          );
                        }
                      }}
                    >
                      {gettingVoucherBalance === true ? <Loader /> : "Enter"}
                    </button>
                  </div>
                  <div className="clearfix" />
                </form>
              </div>
            </div>
          </div>
          {totalvoucherBalance.voucher_count === undefined ? (
            ""
          ) : (
            <div className="p-20">
              <div className="row">
                <div className="t-center col-lg-4 col-sm-12">
                  <p className="mb-0 t2 bold">
                    {" "}
                    {totalvoucherBalance.voucher_count}
                  </p>
                  <p className="t-12"> Voucher Count</p>
                </div>
                <div className="t-center col-lg-4 col-sm-12">
                  <p className="mb-0 t2 bold">
                    {precise_round(totalvoucherBalance.balance, 2) + " WC "}
                  </p>
                  <p className="t-12"> Amount in WC</p>
                </div>
                <div className="t-center col-lg-4 col-sm-12">
                  <p className="mb-0 t2 bold">
                    {" NGN " +
                      numberWithCommas(totalvoucherBalance.balance_in_naira)}
                  </p>
                  <p className="t-12"> Amount in NGN</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { gettingVoucherBalance } = state.Loading;
  const { totalvoucherBalance, checkedVoucher, balanceErr } = state.Voucher;
  const {
    voucherbalancePhoneNumber,
    voucherCode,
    phoneNumberError,
    voucherCodeError,
  } = state.User;
  return {
    gettingVoucherBalance,
    totalvoucherBalance,
    voucherbalancePhoneNumber,
    voucherCode,
    checkedVoucher,
    balanceErr,
    phoneNumberError,
    voucherCodeError,
  };
};

export default connect(MapStateToProps, { voucherBalance, getFormDetails })(
  VoucherBalance
);
