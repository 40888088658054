import _const from "../actions/Types";

const initialState = {
  active: false,
  id: 1,
  adminsubnav: [
    // {
    //   id: 15,
    //   title: "Profile",
    //   link: "manager",
    //   icon: "account_box",
    //   activeImage: "/Assets/dashboardicons/activeicons/SVG/Profile.svg",
    //   inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Profile.svg",
    // },
    //  {
    //   id: 13,
    //   title: "Countries",
    //   link: "countries",
    //   icon: "map",
    //   activeImage: "/Assets/adminicons/Active/Countries.svg",
    //   inactiveImage: "/Assets/adminicons/Inactive/Countries.svg",
    // },
    // {
    //   id: 16,
    //   title: "Subscriptions",
    //   link: "subscriptions",
    //   icon: "subscriptions",
    //   activeImage: "/Assets/adminicons/Active/Subscription.svg",
    //   inactiveImage: "/Assets/adminicons/Inactive/Subscription.svg",
    // },
    // {
    //   id: 14,
    //   title: "Track Voucher",
    //   link: "trackvoucher",
    //   icon: "list",
    //   activeImage: "/Assets/dashboardicons/activeicons/SVG/Voucher.svg",
    //   inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Voucher.svg",
    // },
  ],
  generalNav: [
    {
      id: 1,
      title: "Dashboard",
      link: "manager/dashboard",
      icon: "home",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Dashboard.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Dashboard.svg",
      subtitle: "Track your apps across devices",
    },
    {
      id: 16,
      title: "Wallet",
      link: "wallets",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/wallet.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/wallet.svg",
      subtitle: "Track your wallet balance",
    },
    {
      id: 3,
      title: "Devices",
      link: "devices",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
      subtitle: "Track your Device performance",
    },
    {
      id: 7,
      title: "Voucher",
      link: `voucher`,
      icon: "account_box",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Voucher.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Voucher.svg",
      subtitle: "Track your Voucher status",
    },
    {
      id: 8,
      title: "Agents",
      link: "agents",
      icon: "account_box",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Agent.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Agent.svg",
      subtitle: "Track your Agent's activities",
    },
  ],
  adminNav: [
    {
      id: 1,
      title: "Dashboard",
      link: "admin/dashboard",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 2,
      title: "Users",
      link: "wicryptusers",
      activeImage: "/Assets/adminicons/Active/Profile.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Profile.svg",
    },
    {
      id: 3,
      title: "Devices",
      link: "devices",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
    },
    {
      id: 4,
      title: "Wallet",
      link: "transcOverview",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Transactions.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Transactions.svg",
    },
    {
      id: 19,
      title: "New Transactions",
      link: "new-transactions",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Transactions.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Transactions.svg",
    },
    {
      id: 6,
      title: "Remote Commands",
      link: "commands",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 7,
      title: "Spend Control",
      link: "spendcontrol",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 8,
      title: "Stake",
      link: "admin/stake",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 20,
      title: "Reward Booster",
      link: "reward-booster",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Transactions.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Transactions.svg",
    },
    {
      id: 9,
      title: "Businesses",
      link: "businesses",
      icon: "vertical_split",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
    {
      id: 10,
      title: "Miners Rewards",
      link: "minersReward",
      icon: "keyboard_return",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
    {
      id: 12,
      title: "Adverts",
      link: "viewadvert",
      icon: "featured_video",
      activeImage: "/Assets/adminicons/Active/Adverts-copy.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Adverts-copy.svg",
    },
    {
      id: 18,
      title: "Message Broker",
      link: "topics",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Session.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Session.svg",
    },
    {
      id: 5,
      title: "Admins",
      link: "admins",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 11,
      title: "Settings",
      link: "settings",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Settings.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Settings.svg",
    },
  ],
  superadminNav: [
    {
      id: 1,
      title: "Dashboard",
      link: "admin/dashboard",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 2,
      title: "Users",
      link: "wicryptusers",
      activeImage: "/Assets/adminicons/Active/Profile.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Profile.svg",
    },
    {
      id: 3,
      title: "Devices",
      link: "devices",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
    },
    {
      id: 4,
      title: "Wallet",
      link: "transcOverview",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Transactions.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Transactions.svg",
    },
    {
      id: 6,
      title: "Remote Commands",
      link: "commands",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 7,
      title: "Spend Control",
      link: "spendcontrol",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 8,
      title: "Stake",
      link: "admin/stake",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 9,
      title: "Businesses",
      link: "businesses",
      icon: "vertical_split",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
    {
      id: 10,
      title: "Miners Rewards",
      link: "minersReward",
      icon: "keyboard_return",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
    {
      id: 12,
      title: "Adverts",
      link: "viewadvert",
      icon: "featured_video",
      activeImage: "/Assets/adminicons/Active/Adverts-copy.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Adverts-copy.svg",
    },
    {
      id: 5,
      title: "Admins",
      link: "admins",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 11,
      title: "Settings",
      link: "settings",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Settings.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Settings.svg",
    },
  ],
  operationsNav:  [
    {
      id: 1,
      title: "Dashboard",
      link: "user/dashboard",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 7,
      title: "Users",
      link: "wicryptusers",
      activeImage: "/Assets/adminicons/Active/Profile.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Profile.svg",
    },
    {
      id: 3,
      title: "Devices",
      link: "devices",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
    },
    {
      id: 6,
      title: "Transactions",
      link: "transcOverview",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Transactions.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Transactions.svg",
    },
    {
      id: 10,
      title: "Spend Control",
      link: "spendcontrol",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    {
      id: 10,
      title: "Miners Rewards",
      link: "minersReward",
      icon: "keyboard_return",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
    {
      id: 12,
      title: "Stake",
      link: "admin/stake",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 11,
      title: "Businesses",
      link: "businesses",
      icon: "vertical_split",
      activeImage: "/Assets/adminicons/Active/Businesses.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
    },
  ],
  technicalNav:  [
    {
      id: 1,
      title: "Dashboard",
      link: "user/dashboard",
      activeImage: "/Assets/adminicons/Active/Dashboard.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    },
    {
      id: 2,
      title: "Devices",
      link: "devices",
      activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
      inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
    },
    {
      id: 3,
      title: "Remote Commands",
      link: "commands",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/admin.svg",
      inactiveImage: "/Assets/adminicons/Inactive/admin.svg",
    },
    
  ],
  developerNav: [
    // {
    //   id: 1,
    //   title: "Dashboard",
    //   link: "user/dashboard",
    //   activeImage: "/Assets/adminicons/Active/Dashboard.svg",
    //   inactiveImage: "/Assets/adminicons/Inactive/Dashboard.svg",
    // },
    {
      id: 18,
      title: "Message Broker",
      link: "topics",
      icon: "note_add",
      activeImage: "/Assets/adminicons/Active/Session.svg",
      inactiveImage: "/Assets/adminicons/Inactive/Session.svg",
    },
  ],
  // operationsNav: [
  //   {
  //     id: 1,
  //     title: "Dashboard",
  //     link: "user/dashboard",
  //     icon: "home",
  //     activeImage: "/Assets/dashboardicons/activeicons/SVG/Dashboard.svg",
  //     inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Dashboard.svg",
  //   },
  //   // {
  //   //   id: 2,
  //   //   title: "Referrals",
  //   //   link: "manager/referals",
  //   //   icon: "account_tree",
  //   //   activeImage: "/Assets/adminicons/Active/Referral.svg",
  //   //   inactiveImage: "/Assets/adminicons/Inactive/Referral.svg",
  //   // },
  //   // {
  //   //   id: 3,
  //   //   title: "Devices",
  //   //   link: "devices",
  //   //   icon: "stay_primary_landscape",
  //   //   activeImage: "/Assets/dashboardicons/activeicons/SVG/Device.svg",
  //   //   inactiveImage: "/Assets/dashboardicons/Inactiveicons/JPG/Device.jpg",
  //   // },
  //   {
  //     id: 4,
  //     title: "Track Voucher Balance",
  //     link: "voucher/balance",
  //     icon: "phonelink_setup",
  //     activeImage: "/Assets/dashboardicons/activeicons/SVG/Voucher.svg",
  //     inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Voucher.svg",
  //   },
  //   {
  //     id: 5,
  //     title: "Sessions",
  //     link: "allsessions",
  //     icon: "data_usage",
  //     activeImage: "/Assets/adminicons/Active/Session.svg",
  //     inactiveImage: "/Assets/adminicons/Inactive/Session.svg",
  //   },
  //   {
  //     id: 17,
  //     title: "Requests",
  //     link: "wicryptusers",
  //     icon: "vertical_split",
  //     activeImage: "/Assets/adminicons/Active/Requests.svg",
  //     inactiveImage: "/Assets/adminicons/Inactive/Requests.svg",
  //   },
  //   {
  //     id: 6,
  //     title: "Businesses",
  //     link: "businesses",
  //     icon: "vertical_split",
  //     activeImage: "/Assets/adminicons/Active/Businesses.svg",
  //     inactiveImage: "/Assets/adminicons/Inactive/Businesses.svg",
  //   },
  //   // {
  //   //   id: 8,
  //   //   title: "Business Report",
  //   //   link: "operation/businesssreport",
  //   //   icon: "bar_chart",
  //   // },
  //   {
  //     id: 9,
  //     title: "Agent's Data Usage",
  //     link: "agent/dataUsage",
  //     icon: "trending_up",
  //     activeImage: "/Assets/dashboardicons/activeicons/SVG/Agent.svg",
  //     inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Agent.svg",
  //   },
  //   // {
  //   //   id: 10,
  //   //   title: "Profile",
  //   //   link: "manager",
  //   //   icon: "account_box",
  //   //   activeImage: "/Assets/dashboardicons/activeicons/SVG/User.svg",
  //   //   inactiveImage: "/Assets/dashboardicons/Inactiveicons/SVG/Profile.svg",
  //   // },
  // ],
};

const NavReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.CHANGE_ACTIVE:
      let active = true;
      let id = action.id;
      return {
        ...state,
        active,
        id,
      };
    default:
      return { ...state };
  }
};

export default NavReducer;
