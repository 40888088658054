import React, { Component } from 'react';
import { connect } from "react-redux";
import Modal from "../Reusables/modals/Modal";
import { toggleModal, getallBusinessTokens, getFormDetails, createBusinessToken } from "../../Redux/actions";
import Button from "../Reusables/buttons/Button";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import { voucherHrs, voucherDays } from "../../Helper";
import Loader from "../Reusables/loaders/MoonLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import filesize from 'filesize'

class CreateToken extends Component {
  componentDidMount() {
    this.props.getallBusinessTokens("", "", this.props.tokenSearch)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tokenusagetype !== this.props.tokenusagetype || nextProps.tokenStatus !== this.props.tokenStatus || nextProps.tokenSearch !== this.props.tokenSearch) {
      this.props.getallBusinessTokens(nextProps.tokenStatus, nextProps.tokenusagetype, nextProps.tokenSearch)
    }

  }
  render() {
    const { gettingBusinessToken, allbusinessToken, bytesAllocated, creatingBusinessToken, days,
      secondsAllocated, quantity, dataTokenUsageType, errorcreatingbusinesstoken, volume, tokenSearch } = this.props;
    let allRows = [];
    allbusinessToken.map((token) => {
      return allRows.push({
        phone: token.dataTokenOwner ? token.dataTokenOwner.phoneNumber ? token.dataTokenOwner.phoneNumber: "-" : "-",
        code: token.code,
        dataallocated: filesize(token.bytesAllocated),
        dataused: filesize(token.bytesUsed),
        dataTokenUsageType: token.dataTokenUsageType,
        secondsAllocated: token.secondsAllocated + " secs",
        voucherStatus: token.voucherStatus,
      });
    });
    const data = {
      columns: [
        {
          label: "Assigned to",
          field: "phone",
          sort: "asc",
          width: 170,
        },
        {
          label: "Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Data Allocated",
          field: "dataallocated",
          sort: "asc",
          width: 170,
        },
        {
          label: "Data Used",
          field: "dataused",
          sort: "asc",
          width: 170,
        },
        {
          label: "Usage Type",
          field: "dataTokenUsageType",
          sort: "asc",
          width: 170,
        },
        {
          label: "Validity Period",
          field: "secondsAllocated",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "voucherStatus",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="mt-5">
        <Modal heading={"Create Token here"}>
          
        <label>What type of token do you want to generate?</label>
          <div>
          <div className="input-group mb-2">
            <select className="form-control loginCred" onChange={(e) =>
              this.props.getFormDetails({
                props: ["dataTokenUsageType"],
                value: e.target.value,
              })
            }>
              <option value=""> </option>
              <option value="data"> Data</option>
              <option value="timed"> Timed</option>
            </select>
          </div>
          {errorcreatingbusinesstoken === 4 ? <p className='errorMsgs' ><i className="material-icons">info</i>What type of tokens would you like to genrate?</p> : ''}
          </div>
          {dataTokenUsageType === "data" ? 
          <div>
 <label>Data Volume</label>
          <div className="input-group mb-2">
            <input
              type="number"
              className="form-control loginCred"
              aria-describedby="basic-addon1"
              value={bytesAllocated}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["bytesAllocated"],
                  value: e.target.value,
                })
              }
            />
            <span className="input-group-text">
                <select className="form-control loginCred" onChange={(e) =>
                this.props.getFormDetails({
                  props: ["volume"],
                  value: e.target.value,
                })
              }>
                  <option value="GB">GB</option>
                  <option value="MB">MB</option>
                  {/* <option value="KB">KB</option> */}
                </select>
            </span>
          </div>
          {errorcreatingbusinesstoken === 2 ? <p className='errorMsgs' ><i className="material-icons">info</i>How much data will you like to generate?</p> : ''}
          <label>Quantity</label>
          <div className="input-group mb-2">
            <input
              type="number"
              onChange={(e) => this.props.getFormDetails({
                props: ["quantity"],
                value: e.target.value,
              })}
              className="form-control loginCred"
            />
          </div>
          {errorcreatingbusinesstoken === 3 ? <p className='errorMsgs' ><i className="material-icons">info</i>How many data tokens would you like to genrate?</p> : ''}
          </div> :
          dataTokenUsageType !== "timed" ?  <div></div>:
          <div>
          <label>How long do you want the token to last? </label>
          <div className="row mb-3">
            <div className="col-lg-12">
              <div
                className="p-10"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["days"],
                    value: true,
                  })
                }
              >
                <input
                  type="radio"
                  id="days"
                  className="ml-3"
                  name="validity"
                  checked={days}
                />
                <label htmlFor="days" className="ml-3">
                  Days
                </label>
              </div>
              <div
                className="p-10"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["days"],
                    value: false,
                  })
                }
              >
                <input
                  type="radio"
                  id="hours"
                  name="validity"
                  className="ml-3"
                  checked={!days}
                />
                <label htmlFor="hours" className="ml-3">
                  Hours
                </label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group mb-2">
                <select
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["secondsAllocated"],
                      value: e.target.value,
                    })
                  }
                >
                  <option value=""> Select validity period</option>
                  {days ? voucherDays() : voucherHrs()}
                </select>
              </div>
            </div>
          </div>
          {errorcreatingbusinesstoken === 1 ? <p className='errorMsgs' ><i className="material-icons">info</i>How long should the data last?</p> : ''}
          
          <label>Quantity</label>
          <div className="input-group mb-2">
            <input
              type="number"
              onChange={(e) => this.props.getFormDetails({
                props: ["quantity"],
                value: e.target.value,
              })}
              className="form-control loginCred"
            />
          </div>
          {errorcreatingbusinesstoken === 3 ? <p className='errorMsgs' ><i className="material-icons">info</i>How many tokens would you like to genrate?</p> : ''}
          </div>}
         
          
          {![1, 2, 3, 4,""].includes(errorcreatingbusinesstoken) ? <p className='errorMsgs' ><i className="material-icons">info</i>{errorcreatingbusinesstoken}</p> : ''}
          
          
          <Button
            buttonAction={() => this.props.createBusinessToken(days, secondsAllocated, bytesAllocated, dataTokenUsageType, quantity, volume)}
            theme="wicryptColor ml-3 mt-2 float">{creatingBusinessToken ? <Loader /> : "Enter"}</Button>
        </Modal>

        <div className="row mb-5">
          <div className="mb-3 col-lg-12">

            <div className="mt-3 d-flex">
              {/* <div className="mr-3"> FROM</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherstartDate"],
                    value: e.getTime(),
                  })
                }
                selected={voucherstartDate}
                dateFormat={"dd/MM/yyyy"}
              />

              <div className="mr-3 ml-3"> TO</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherendDate"],
                    value: e.getTime(),
                  })
                }
                selected={voucherendDate}
                dateFormat={"dd/MM/yyyy"}
              /> */}

              {/* </div> */}
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["tokenStatus"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Status</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}> Inactive</option>
                <option value={"Exhausted"}> Exhausted</option>
                <option value={"Expired"}> Expired</option>
              </select>
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["tokenusagetype"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Usage Type</option>
                <option value={"Timed"}>Timed</option>
                <option value={"data"}> Data</option>
              </select>
            </div>
          </div>

          {gettingBusinessToken & allbusinessToken.length === 0 ? (
            <div className="col-lg-12">
              <div className=" t-center mt-4">
                <CustomLoader />
              </div>
            </div>)
          // ) : allbusinessToken.length === 0 && !gettingBusinessToken ? (
          //   <div className="col-lg-12 mb-4">
          //     <p className="voucherText t-center">
          //       {" "}
          //                   No tokens to show.
          //     </p>
          //   </div>
          // ) : (
            :(
                <div className="col-lg-12">
                  <div className=" t-center mt-4">
                    <CustomDataTable
                      data={data}
                      placeholder={"Search token"}
                      onClick={() => this.props.toggleModal(true)}
                      buttontitle={"Add Token"}
                      searchfunction={(e) => this.props.getFormDetails({ props: ['tokenSearch'], value: e.target.value })}
                      searchvalue={tokenSearch}
                    />
                  </div>
                </div>
              )}
        </div>
      </div>
    )
  }
}

const MapStateToProps = (state) => {
  const { gettingBusinessToken, creatingBusinessToken } = state.Loading;
  const { allbusinessToken, errorcreatingbusinesstoken } = state.DataToken
  const { bytesAllocated, secondsAllocated, days, quantity, dataTokenUsageType, tokenusagetype, tokenStatus, volume, tokenSearch } = state.User
  return {
    gettingBusinessToken,
    allbusinessToken,
    bytesAllocated,
    secondsAllocated,
    days,
    quantity,
    dataTokenUsageType,
    errorcreatingbusinesstoken,
    creatingBusinessToken,
    tokenusagetype,
    tokenStatus,
    volume,
    tokenSearch
  };
};

export default connect(MapStateToProps, { toggleModal, getallBusinessTokens, getFormDetails, createBusinessToken })(CreateToken);