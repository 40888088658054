import React, { Component } from "react";
import { connect } from "react-redux";
import {
  logOut,
  getAgentBusiness,
  getBusinessProfile,
  toggleNav,
  getFormDetails,
} from "../../Redux/actions";
import { SupportNavDropDown, SupportTitle, Icon, SupportBtn } from "./partials";

class SupportDropDown extends Component {
  render() {
    const { supportToggle } = this.props;
    return (
      <SupportNavDropDown
        onClick={() =>
          this.props.getFormDetails({
            props: ["supportToggle"],
            value: !supportToggle,
          })
        }
      >
        <SupportTitle> Customer Support</SupportTitle>
        <a
          href="https://wicrypt.com/faq.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-flex mt-2">
            <p className="mb-3"> Help Center (FAQs)</p>
            <Icon className="material-icons t-14">chevron_right</Icon>
          </div>
        </a>
        <div
          className="d-flex mt-2"
          onClick={() => window.Tawk_API.toggle()}
          style={{ cursor: "pointer" }}
        >
          <p className="mb-3"> Online Chat</p>
          <Icon className="material-icons t-14">chevron_right</Icon>
        </div>
        <a href="tel:09039861005" target="_blank" rel="noopener noreferrer">
          <div className="d-flex mt-2">
            <p className="mb-3"> Contact Support</p>
            <SupportBtn>
              {" "}
              <p> Call</p>{" "}
            </SupportBtn>
          </div>
        </a>
        <a
          href="mailto:hello@wicrypt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-flex mt-2">
            <p className="mb-3"> Email Support</p>
            <SupportBtn>
              {" "}
              <p> Send email</p>{" "}
            </SupportBtn>
          </div>
        </a>
      </SupportNavDropDown>
    );
  }
}

// Live chat (tawk.to): Handled by Emeka (Finland)
const MapStateToProps = (state) => {
  const { supportToggle } = state.User;
  return {
    supportToggle,
  };
};
export default connect(MapStateToProps, {
  logOut,
  getAgentBusiness,
  toggleNav,
  getBusinessProfile,
  getFormDetails,
})(SupportDropDown);
