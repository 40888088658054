import React, { Component } from "react";
import BreadCrumbs from "../Partials/Breadcrumbs";
import VoucherBalance from "./VoucherBalance";
import { connect } from "react-redux";

class index extends Component {
  render() {
    return (
      <div className="row background">
        <main className="main-content col-lg-12 col-md-12 col-sm-12">
          <div className="mt-5 lgLogo">
            <img className="logo" src="/Assets/logo.png" alt="logo" />
          </div>
          <div className="main-content-container container-fluid">
            <BreadCrumbs />
            <div className="row">
              <div className="col-lg-6 theWelctext ">
                <div className="ml-5 mt-5 welcome">
                  Check Your Voucher Balance!
                </div>
                <p className="ml-5 mt-5 download">
                  {" "}
                  Download the Wicrypt app and create an account for free!{" "}
                </p>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wicrypt.app"
                    className="downloadLinks"
                  >
                    <img
                      alt="downloadBtns"
                      className="downloadBtns"
                      src="/Assets/playstore.png"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/ng/app/wicrypt/id1451821605"
                    className="downloadLinks"
                  >
                    <img
                      alt="downloadBtns"
                      className="downloadBtns"
                      src="/Assets/appStore.png"
                    />
                  </a>
                </div>
              </div>
              <VoucherBalance />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  return {
    state,
  };
};
export default connect(MapstateToProps)(index);
