import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toggleModal, handleModal } from "./GeneralAction";

//This gets all the manager's information
export const getManager = () => {
  return (dispatch) => {
    dispatch({
      type: _const.LOADING_MANAGER,
      payload: true,
    });
    axios
      .get(config.apiUrl + "/v2/users/dashboard")
      .then((res) => {
        dispatch({
          type: _const.LOADING_MANAGER,
          payload: false,
        });
        dispatch({
          type: _const.GET_MANAGER_INFO,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.LOADING_MANAGER,
          payload: false,
        });
      });
  };
};

export const getRates = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/utils/currencies")
      .then((res) => {
        dispatch({
          type: _const.GET_CURRENCY_RATES,
          payload: res.data.data,
        });
      })
      .catch((err) => err);
  };
};

//Get withdrawal token
export const getWithdrawalToken = () => {
  return (dispatch) => {
    dispatch({
      type: _const.SENDING_OTP,
      payload: true,
    });
    axios
      .post(config.apiUrl + "/v2/fiatTransfer/otp")
      .then((res) => {
  
        dispatch({
          type: _const.SENDING_OTP,
          payload: false,
        });
        dispatch(handleModal(9))
        // dispatch({
        //   type: _const.OTP_SENT,
        //   payload: true,
        // });
      })
      .catch((err) => {
        dispatch({
          type: _const.SENDING_OTP,
          payload: false,
        });
        dispatch({
          type: _const.OTP_ERROR,
          payload: err.response ? err.response.data.message : "Something went wrong",
        });
      });
  };
};

//Get list of banks
export const getBanks = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/utils/banks")
      .then((res) => {
        dispatch({
          type: _const.ALL_BANKS,
          payload: res.data.data,
        });
      })
      .catch((err) => err);
  };
};

//Get account name
export const getAcctName = (acctNum, bankId) => {
  return (dispatch) => {
    dispatch({
      type: _const.ACC_NAME_ERR,
      payload: "",
    });
    dispatch({
      type: _const.GETTING_ACCT_NAME,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/fiatTransfer/accountName?accountNumber=${acctNum}&bankId=${bankId}`
      )

      .then((res) => {
        dispatch({
          type: _const.GETTING_ACCT_NAME,
          payload: false,
        });
        // if (res.data.data.accountName !== "") {
          dispatch({
            type: _const.ACCOUNT_NAME,
            payload: res.data.data.accountName,
          });
        // } else {
        //   dispatch({
        //     type: _const.ACC_NAME_ERR,
        //     payload: "Counld not resolve account name",
        //   });
        // }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ACCT_NAME,
          payload: false,
        });
        dispatch({
          type: _const.ACC_NAME_ERR,
          payload: err.response.data ? err.response.data.message : "Something went wrong",
        });
      });
  };
};

//Make a withdrawal
export const makeWithdrawal = (
  bankId,
  accountNumber,
  amount,
  naration,
  currency,
  pin,
  otp,
  selectedwalletoption
) => {
  let data = {
    bankId: bankId,
    accountNumber: accountNumber,
    amount: amount,
    narration: naration,
    currency: currency,
    businessId: "",
    pin: pin,
    otp: otp,
  };
  
  return (dispatch) => {
    dispatch({
      type: _const.MAKING_WITHDRAWAL,
      payload: true,
    });
    let apiUrl = selectedwalletoption === "Personal" ? "/v2/fiatTransfer" : "/v2/fiatTransfer/Business"
    axios
      .post(config.apiUrl + apiUrl, data)
      .then((res) => {
        dispatch({
          type: _const.MAKING_WITHDRAWAL,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.WITHDRAWAL_SUCCESSFUL,
            payload: true
          })
          dispatch({
            type: _const.CLEAR_FORMS,
            payload: true,
          });
          dispatch(getBusinessBalance());
          dispatch(handleModal(10))
        }
      })
      .catch((err) => {
        dispatch(handleModal(101))
        dispatch({
          type: _const.WITHDRAWAL_ERROR,
          payload: err.response.data.message,
        });
        dispatch({
          type: _const.MAKING_WITHDRAWAL,
          payload: false,
        });
        dispatch({
          type: _const.CLEAR_FORMS,
          payload: true,
        });
        dispatch(getBusinessBalance());
      });
  };
};
//Get Business balance after making withdrawal
export const getBusinessBalance = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/users/summary")
      .then((res) => {
        dispatch({
          type: _const.GET_BUSINESS_BALANCE,
          payload: res.data.data,
        });
      })
      .catch((err) => { });
  };
};

//This gets the flat rate for tokens
export const getRate = () => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + "/v2/rate/flat")
      .then((res) => {
        dispatch({
          type: _const.TOKEN_RATE,
          payload: res.data.rate,
        });
      })
      .catch((err) => { });
  };
};

///Create a sub business
export const createSubBusiness = (data) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userId", data.userId);
  bodyFormData.append("name", data.name);
  bodyFormData.append("logoUrl", data.logoUrl);
  bodyFormData.append("officeAddress", data.officeAddress);
  bodyFormData.append("logoFile", data.logoFile);
  return (dispatch) => {
    if (data.logoFile === "" || data.name === "") {
      dispatch({
        type: _const.MERCHANT_REG_ERROR,
        payload: "Logo file and Business Name are required",
      });
    } else {
      dispatch({
        type: _const.CREATING_SUB_BUSINESS,
        payload: true,
      });

      axios
        .post(config.apiUrl + "/v2/businesses/subsidiary/alt", bodyFormData)
        .then((res) => {
          dispatch({
            type: _const.CREATING_SUB_BUSINESS,
            payload: false,
          });
          if (res.data.success) {
            dispatch({
              type: _const.MERCHANT_REGISTERED,
              payload: true,
            });
            dispatch(toggleModal(false));
            dispatch({
              type: _const.CLEAR_SUB_BUSINESS,
              payload: ""
            });
            dispatch(getSubBusiness(1, 50));
            Swal.fire({
              text: "Sub business registered successfully!",
              type: "success",
            });
          } else {
            dispatch({
              type: _const.MERCHANT_REG_ERROR,
              payload: res.data.errors,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.CREATING_SUB_BUSINESS,
            payload: false,
          });
          dispatch({
            type: _const.MERCHANT_REG_ERROR,
            payload: err.response
              ? err.response.data.data
              : "Something went wrong",
          });
        });
    }
  };
};

//Get all subsidiary businesses
export const getSubBusiness = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_SUB_BUSINESS,
      payload: true,
    });

    axios
      .get(config.apiUrl + `/v2/businesses/subsidiary?page=${page}&pageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_SUB_BUSINESS,
          payload: false,
        });
        if (res.data.success) {
          dispatch({
            type: _const.ALL_SUB_BUSINESS,
            payload: res.data,

          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_SUB_BUSINESS,
          payload: false,
        });
      });
  };
};
