import React, { useRef } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { getSearchDetails } from "../../../Redux/actions/SearchAction";
import CustomLoader from "../loaders/CustomLoader";
import {
  TableLabel,
  TableHead,
  TableValues,
  Table,
  TableContainer,
  TableFlex,
  Searchbox,
  TableActionButton,
  PageTitle,
} from "./tablestyles";

const CustomDataTable = ({
  data,
  pagination,
  id,
  placeholder,
  onClick,
  buttontitle,
  customleft,
  title,
  searchfunction,
  customright,
  searchvalue,
  doublelayered,
  layeredbuttontitle,
  doublelayeredtitle,
  header,
  tableId,
  currentTable,
  pageCount,
  currentPage,
  notification,
  loading,
  noData,
}) => {
  const tableScroll = useRef();
  const dispatch = useDispatch();
  const scrollToTop = () => {
    tableScroll.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const ChangePage = (prop) => {
    const { selected } = prop;
    dispatch(
      getSearchDetails({
        props: ["Page"],
        value: selected + 1,
      })
    );
    dispatch(
      getSearchDetails({
        props: ["page"],
        value: selected + 1,
      })
    );
  };
  return (
    <div
      className="card card-small mb-4 w3-animate-bottom"
      id={id}
      ref={tableScroll}
    >
      <TableContainer>
        {/* <TableFlex></TableFlex> */}
        {doublelayered ? (
          <TableFlex>
            {doublelayeredtitle ? (
              <PageTitle> {doublelayeredtitle}</PageTitle>
            ) : (
              ""
            )}
            {layeredbuttontitle ? (
              <TableActionButton onClick={onClick} className="mb-3">
                {" "}
                <i
                  className="material-icons mr-2 ml-2 t-14"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                >
                  add_circle
                </i>
                {layeredbuttontitle}
              </TableActionButton>
            ) : (
              ""
            )}
          </TableFlex>
        ) : (
          ""
        )}
        <TableFlex>
          {title && <PageTitle> {title}</PageTitle>}
          {placeholder && (
            <Searchbox
              placeholder={placeholder}
              value={searchvalue}
              onChange={searchfunction}
            />
          )}
          {buttontitle ? (
            <TableActionButton onClick={onClick}>
              {" "}
              <i
                className="material-icons mr-2 ml-2 t-14"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                add_circle
              </i>
              {buttontitle}
            </TableActionButton>
          ) : (
            ""
          )}
        </TableFlex>
        <TableFlex>
          {customleft && customleft }
          {customright && customright}
        </TableFlex>
        {header && <TableFlex>{header}</TableFlex>}
        {notification}
        {loading ? (
          <CustomLoader size="10" />
        ) : (
          <>
            {data.rows.length !== 0 ? (
              <Table className="table mb-0" id={tableId}>
                <TableHead>
                  <tr>
                    {data.columns.map((column, i) => {
                      return (
                        <TableLabel scope="col" key={i} className="border-0">
                          {column.label}
                        </TableLabel>
                      );
                    })}
                  </tr>
                </TableHead>
                <tbody>
                  {data.rows.map((row, i) => {
                    return (
                      <tr key={i}>
                        {data.columns.map((column, i) => {
                          return (
                            <TableValues
                              key={i}
                              style={{ width: column.width }}
                            >
                              {row[`${column.field}`]}
                            </TableValues>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              noData
            )}
          </>
        )}

        <div className="col-lg-12 t-center">
          <div className="pagination">{pagination}</div>
          {pageCount > 0  && (
            <ReactPaginate
              previousLabel={"< "}
              nextLabel={" >"}
              pageCount={pageCount}
              onPageChange={ChangePage}
              containerClassName={"paginationBttns"}
              activeClassName={"paginationActive"}
              marginPagesDisplayed={4}
              pageRangeDisplayed={4}
              onClick={scrollToTop}
              initialPage={currentPage - 1}
            />
          )}
        </div>
        <TableFlex>
          {customleft && customleft }
          {customright && customright}
        </TableFlex>
      </TableContainer>
     
    </div>
  );
};

export default CustomDataTable;
