import React from "react";
import {SubTitle, SectionBody, SectionValue, SectionTitle} from './dahboardstyles'

const Summary = ({title, revenue, usage, sessions}) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 mb-4 w3-animate-right">
      <div className="card card-small h-100 p-4">
        <SubTitle> {title} </SubTitle>
        <SectionBody>
          <SectionValue> {usage}</SectionValue>
          <SectionTitle> Most Used </SectionTitle>
        </SectionBody>
        <SectionBody>
          <SectionValue> {sessions}</SectionValue>
          <SectionTitle> Device with Most Sessions</SectionTitle>
        </SectionBody>
        <SectionBody>
          <SectionValue> {revenue}</SectionValue>
          <SectionTitle last> Device with Most Revenue</SectionTitle>
        </SectionBody>
      </div>
    </div>
  );
  
}

export default Summary;
