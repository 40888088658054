import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails } from "../../../Redux/actions";
import { numberWithCommas } from "../../../Helper";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import EmptyCard from "../../Reusables/cards/EmptyCard";

const NewSingleTransactionReportPage = ({history}) => {

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [transcId, setTranscId] = useState(null);
  const transactionCategory = "transfer";

  const goBack = () => {
    history.goBack();
  };
  
  useEffect(() => {
    const newTranscId = window.location.pathname.split("/").at(-1);
    setTranscId(newTranscId);
  }, []); 

  useEffect(() => {
    if (transcId) {
      dispatch(getNewTransactionDetails(transactionCategory, transcId));
    }
  }, [transcId, dispatch]);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingNewTrancactionDetails ?
          <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
             {Object.keys(newTransactionDetails).length !== 0 ? 
             <div className="card-body p-6 ">
                <div className="row mb-2">
                  
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Amount</StakeStatData>
                      {newTransactionDetails.amount && newTransactionDetails.currency ? (
                          <StakeStatValue>
                            {numberWithCommas(newTransactionDetails.amount, 4)} {newTransactionDetails.currency}
                          </StakeStatValue>
                        ) : 0}
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Reference</StakeStatData>
                      <StakeStatValue>
                        {newTransactionDetails.referenceCode ? newTransactionDetails.referenceCode : "N/A"}
                      </StakeStatValue>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      {newTransactionDetails.createdAt ? (
                        <StakeStatValue>
                          {moment(newTransactionDetails.createdAt).format("DD-MM-YYYY")} |{" "}
                          {moment(newTransactionDetails.createdAt).format("LT")}
                        </StakeStatValue>
                      ) : "N/A"}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      {newTransactionDetails.status ? (
                        <StakeStatValue className={newTransactionDetails.status.toUpperCase()}>{newTransactionDetails.status}</StakeStatValue>
                        ) : "N/A"}
                    </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Sender</StakeStatData>
                      <StakeStatValue>Name: {newTransactionDetails.sender && newTransactionDetails.sender.name ? newTransactionDetails.sender.name : "N/A"}</StakeStatValue>
                      <StakeStatValue>Email: {newTransactionDetails.sender && newTransactionDetails.sender.email ? newTransactionDetails.sender.email : "N/A"}</StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Receiver</StakeStatData>
                      <StakeStatValue>Name: {newTransactionDetails.receiver && newTransactionDetails.receiver.name ? newTransactionDetails.receiver.name : "N/A"}</StakeStatValue>
                      <StakeStatValue>Email: {newTransactionDetails.receiver && newTransactionDetails.receiver.email ? newTransactionDetails.receiver.email : "N/A"}</StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Fee Remitted</StakeStatData>
                      {newTransactionDetails.isFeeRemitted !== undefined && (
                        <StakeStatValue>{newTransactionDetails.isFeeRemitted === true ? "True" : "False"}</StakeStatValue>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Total Chargeable Amount</StakeStatData>
                      {newTransactionDetails.totalChargeableAmount && newTransactionDetails.currency ? (
                        <StakeStatValue>{newTransactionDetails.totalChargeableAmount} {newTransactionDetails.currency}</StakeStatValue>
                        ) : 0}
                    </div>
                  
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-md-12">
                      <StakeStatData>Description</StakeStatData>
                      <StakeStatValue>{newTransactionDetails.description ? newTransactionDetails.description : "N/A"}</StakeStatValue>
                    </div>
                  
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  
                    <div className="col-md-6">
                      <StakeStatData>Sender Wallet State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th colSpan={2}>Available Balance</th>
                              <th colSpan={2}>Locked Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            <tr>
                            {/* Available Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            {/* Locked Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            </tr>
                            <tr>
                              <TableValues>
                                {newTransactionDetails.senderWalletStateChange && newTransactionDetails.senderWalletStateChange.availableBalanceBefore ? 
                                numberWithCommas(newTransactionDetails.senderWalletStateChange.availableBalanceBefore, 4) : 0}</TableValues>
                              <TableValues>
                                {newTransactionDetails.senderWalletStateChange && newTransactionDetails.senderWalletStateChange.availableBalanceAfter ? 
                                numberWithCommas(newTransactionDetails.senderWalletStateChange.availableBalanceAfter, 4) : 0}</TableValues>
                              <TableValues>
                                {newTransactionDetails.senderWalletStateChange && newTransactionDetails.senderWalletStateChange.lockedBalanceBefore ? 
                                numberWithCommas(newTransactionDetails.senderWalletStateChange.lockedBalanceBefore, 4) : 0}</TableValues>
                              <TableValues>
                                {newTransactionDetails.senderWalletStateChange && newTransactionDetails.senderWalletStateChange.lockedBalanceAfter ? 
                                numberWithCommas(newTransactionDetails.senderWalletStateChange.lockedBalanceAfter, 4) : 0}</TableValues>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                   
                    </div>
                    <div className="col-md-6">
                      <StakeStatData>Receiver Wallet State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th colSpan={2}>Available Balance</th>
                              <th colSpan={2}>Locked Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            <tr>
                            {/* Available Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            {/* Locked Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            </tr>
                            {newTransactionDetails.receiver && (
                              <tr>
                                <TableValues>
                                  {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.availableBalanceBefore ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.availableBalanceBefore, 4) : 0}</TableValues>
                                <TableValues>
                                  {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.availableBalanceAfter ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.availableBalanceAfter, 4) : 0}</TableValues>
                                <TableValues>
                                  {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.lockedBalanceBefore ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.lockedBalanceBefore, 4) : 0}</TableValues>
                                <TableValues>
                                  {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.lockedBalanceAfter ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.lockedBalanceAfter, 4) : 0}</TableValues>
                              </tr>
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  
                </div>
              </div> :  
                <EmptyCard
                img="/Assets/emptytransactions.svg"
                title={"Sorry,  you have no transaction history"}
              />
              }
            </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default NewSingleTransactionReportPage;
