import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Reusables/loaders/HashLoader";
import IconCard from "../Reusables/cards/IconCard";
import { SimpleSelect } from "../Reusables/selects/SimpleSelect";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import {
  numberWithCommaswithoutdecimals, precise_round,
} from "../../Helper";
import { getUpcomingUnstakeReport, getUpcomingUnstakeOverview } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { stakeStatCols } from "../../utils/TableCols/usersCols";
import { getStaketatisticsRows } from "../../utils/TableRows/usersRows";

const statOptions = [
  {
    id: 1,
    option: "Next Week",
    value: "NextWeek",
  },
  {
    id: 2,
    option: "Next Two Weeks",
    value: "NextTwoWeeks",
  },
  {
    id: 3,
    option: "Next One Month",
    value: "NextOneMonth",
  },
  {
    id: 4,
    option: "Next Three Months",
    value: "NextThreeMonths",
  },
];


const UpcomingUnstake = () => {
  const dispatch = useDispatch();



  const { gettingUpcomingUnstakeReport, gettingUpcomingUnstakeOverview} = useSelector((state) => state.Loading);

  const { upcomingUnstakeReport, upcomingUnstakeOverview } = useSelector((state) => state.Stake);

  const { upcomingDuration } = useSelector((state) => state.Search);

    useEffect(() => {
    dispatch(getUpcomingUnstakeReport(upcomingDuration, 1, 30));
    dispatch(getUpcomingUnstakeOverview(upcomingDuration));
  }, [upcomingDuration]);

  let allRows = getStaketatisticsRows(upcomingUnstakeReport.records);

  const data = {
    columns: stakeStatCols,
    rows: allRows,
  };


  return (
    <div>
      <div
        className="row flex items-right justify-end"
        style={{ paddingRight: "30px" }}
      >
        <SimpleSelect
          options={statOptions}
          selectProp="upcomingDuration"
          onSelect={(val, props) => {
            dispatch(
              getSearchDetails({
                props: [props],
                value: val,
              })
            );
          }}
        />
      </div>

      <div className="row  p-2">
        <IconCard
          title={"Total WNT"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUpcomingUnstakeOverview ? (
              <Loader />
            ) : upcomingUnstakeOverview ? (
              (numberWithCommaswithoutdecimals(precise_round(upcomingUnstakeOverview.totalWNT, 3)) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Total WNT (USD)"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUpcomingUnstakeOverview ? (
              <Loader />
            ) : upcomingUnstakeOverview ? (
              (numberWithCommaswithoutdecimals(precise_round(upcomingUnstakeOverview.totalWNTInUsd, 3)) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Number of Accounts"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUpcomingUnstakeOverview ? (
              <Loader />
            ) : upcomingUnstakeOverview ? (
              (numberWithCommaswithoutdecimals(upcomingUnstakeOverview.numberOfAccount) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
      </div>
      <div className="row">
      <div className="col-lg-12 mt-1">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"staking-report"}
              title={`Staking Statistics`}
              pageCount={upcomingUnstakeReport.totalPages}
              currentPage={upcomingUnstakeReport.currentPage}
            //   header={
            //     <TableHeader
            //       subHeaderTypes={StakeHeaderOptionTypes}
            //       subHeader={stakeOptions}
            //       onClick={() => dispatch(toggleModal(true))}
            //       ontabClick={(val, props) => {
            //         dispatch(
            //           getSearchDetails({
            //             props: ["stakeType"],
            //             value: val,
            //           })
            //         );
            //       }}
            //       onSelect={(val, props) => {
            //         dispatch(
            //           getSearchDetails({
            //             props: [props],
            //             value: val,
            //           })
            //         );
            //       }}
            //       // searchvalue={tokenSearch}
            //     />
            //   }
              loading={gettingUpcomingUnstakeReport}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  //title={`Sorry,  you have no ${stakeType} history`}
                  // descr={"No stake history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default UpcomingUnstake;
