// All devicess -------------------------------



export const allDevicesHeaderOptions = [
  {
    id: 1,
    title: "Start Date",
    type: "startTime",
    onChangeProp: "startTime",
  },
  {
    id: 2,
    title: "End Date",
    type: "endTime",
    onChangeProp: "endTime",
  },
  {
    id: 3,
    title: "Device Model",
    type: "textDeviceModel",
    placeholder: "Enter Device Model",
    onChangeProp: "DeviceModel",
  },
  {
    id: 4,
    title: "Manufacturer",
    type: "textDeviceManufacturer",
    placeholder: "Enter Manufacturer",
    onChangeProp: "Manufacturer",
  },
  {
    id: 5,
    title: "Device Mode",
    type: "select",
    onChangeProp: "",
    onSelectProp: "DeviceMode",
    options: [
      { id: 1, option: "All" },
      { id: 2, option: "Personal" },
      { id: 3, option: "Business" },
    ],
  },
  {
    id: 6,
    title: "Search",
    type: "text",
    placeholder: "Enter name/email/ssid/bssid",
    onChangeProp: "Search",
  },
  {
    id: 7,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "all-devices-report",
  },
];

export const allDevicesHeaderOptionTypes = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Online",
  },
  {
    id: 3,
    name: "Offline",
  },
  {
    id: 4,
    name: "Blacklisted",
  },
];

export const liveSessionSearch = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Device name",
    onChangeProp: "Name",
  },
  {
    id: 3,
    title: "View Session History",
    type: "button",
    icon: "/Assets/dashboardicons/Extra/SVG/Group 1929.svg",
  },
];

export const liveSessionHistorySearch = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Device name",
    onChangeProp: "Name",
  },
  {
    id: 3,
    title: "Export Report",
    type: "exportButton",
    icon: "receipt_long",
    tableId: "devices-history-report",
  },
];

export const overviewHeaderOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  // {
  //   id: 5,
  //   title: "Export Report",
  //   type: "exportButton",
  //   icon: "receipt_long",
  //   tableId: "overview-report",
  // },
];

export const deviceTrackDataHeaderOptions = [
  {
    id: 1,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Online",
      },
      {
        id: 3,
        option: "Offline",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Device name",
    onChangeProp: "Name",
  },
];

export const activityTypeOptions = [
  {
    id: 1,
    title: "Activity Type",
    type: "select",
    onSelectProp: "ActivityType",
    options: [
      { id: 1, option: "All" },
      {
        id: 2,
        option: "DeviceRegistration",
      },
      {
        id: 3,
        option: "DeviceArchitectureUpdate",
      },
      {
        id: 4,
        option: "DeviceRateUpdate",
      },
      {
        id: 5,
        option: "DeleteDevice",
      },
      {
        id: 6,
        option: "DeviceModeUpdate",
      },
    ],
  },
];
