import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import AllUsers from "./AllUsers";
import RequestTable from "../AcctVerification/VerificationTable";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { useSelector } from "react-redux";
import KYCDetails from "./KYCDetails";

const index = () => {
  const { toggleSubnav } = useSelector((state) => state.General);
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle="Users"
            listItems={[
              { id: 1, item: "All Users" },
              { id: 3, item: "KYC Verification Details" },
              { id: 2, item: "Requests" },
            ]}
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? <AllUsers /> : toggleSubnav === 2 ? <RequestTable /> : <KYCDetails />}
        </div>
      </MainContent>
    </div>
  );
};

export default index;
