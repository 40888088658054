import React, { useEffect } from "react";
import {
  allBusinesses,
  toggleModal,
  getFormDetails,
} from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import UpdateBilling from "./UpdateBilling";
import { businnessHeaderOptions } from "./options";
import TableHeader from "../Reusables/tables/TableHeader";
import { getBusinessRows } from "../../utils/TableRows/BusinessRows";
import { businessesCols } from "../../utils/TableCols/BusinessCols";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import EmptyCard from "../Reusables/cards/EmptyCard";


const Business = () => {
  const {
    allBusiness,
    allBusinessCurrentPage,
    allBusinessTotalPages,
  } = useSelector((state) => state.Manager);
  const { gettingAllBusinesses } = useSelector((state) => state.Loading);
  const { businessNameSearch } = useSelector((state) => state.User);
  const { page, pageSize } = useSelector((state) => state.Search);
  const allRows = getBusinessRows(allBusiness.records);
  const dispatch = useDispatch();


    useEffect(() => {
      dispatch(clearSearch());
    }, []);

  const fetchBusinessName = () => {
    const queries = {
      Name: businessNameSearch,
      Page: page,
      PageSize: pageSize,
    };
    allBusinesses(dispatch, queries);
  }

  useEffect(() => {
    fetchBusinessName();
  }, [ page]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(businessNameSearch === ""){
      fetchBusinessName();
    }
  },[businessNameSearch])

  const data = {
    columns: businessesCols,
    rows: allRows,
  };


  return (
    <div className="col-lg-12 mb-4">
      <UpdateBilling />
      <CustomDataTable
        loading={gettingAllBusinesses}
        data={data}
        pageCount={allBusinessTotalPages}
        currentPage={allBusinessCurrentPage}
        header={
          <TableHeader
            subHeader={businnessHeaderOptions}
            onClick={() => dispatch(toggleModal(true))}
            onChange={(e, props) => {
              dispatch(
                getFormDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            // searchvalue={businessNameSearch}
            onSearchClick={fetchBusinessName}
          />
        }
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no registered businesses "}
            // descr={"No User available at the moment"}
          />
        }
      />
    </div>
  );
};

export default Business;
