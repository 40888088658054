import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getBoosterCycles, getSearchDetails } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { getRewardBoosterRows } from "../../utils/TableRows/rewardBoosterRow";
import { cyclesColumns } from "../../utils/TableCols/RewardBoosterCols";
import { cyclesOptions } from "./options";

const Cycles = () => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { boosterCycles } = useSelector((state) => state.RewardBooster);
  const { gettingBoosterCycles} = useSelector((state) => state.Loading);
  const { lockPeriod, lockStartDate, lockEndDate, Status, page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getRewardBoosterRows( boosterCycles ? boosterCycles.records : [], 
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );

  const data = {
    columns: cyclesColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(getBoosterCycles(Status, lockPeriod, lockStartDate, lockEndDate, page, pageSize));
  }, [Status, lockPeriod, lockStartDate, lockEndDate, page, pageSize]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"Reward Booster"}
              pageCount={boosterCycles.totalPages}
              currentPage={boosterCycles.currentPage}
              header={
                <TableHeader
                  dataLength={boosterCycles.records ? boosterCycles.records.length : 0}
                  subHeader={ cyclesOptions }
                 
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                />
              }
              loading={gettingBoosterCycles}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry, you have no booster cycle history"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cycles;
