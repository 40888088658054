import React, { Component } from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import Mainnav from "../Partials/Mainnav";
import Table from "./VerificationTable";
import { MainContent } from "../Partials/partials";
import CryptoWithdrawalRequests from "../CryptoWithdrawal/requests";
import WithdrawalRequests from "../Requests/WithdrawalTable";
import SubSidenav from "../Partials/SubSidenav";

class AcctVerification extends Component {
  render() {
    const { toggleSubnav } = this.props;
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white mb-5">
            <Mainnav
              pageTitle="Requests"
              listItems={[
                { id: 1, item: "Verification Requests" },
                { id: 2, item: "Fiat Withdrawal Requests" },
                // { id: 3, item: "Crypto Withdrawal Requests" }
              ]}
            />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            {toggleSubnav === 1 ? (
              // <div>
              //   <Filter />
              <Table />
            ) : // </div>
            toggleSubnav === 2 ? (
              <WithdrawalRequests />
            ) : (
              <CryptoWithdrawalRequests />
            )}
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { onePage } = state.Verification;
  const { toggleSubnav } = state.General;
  return {
    onePage,
    toggleSubnav,
  };
};

export default connect(MapStateToProps, {})(AcctVerification);
