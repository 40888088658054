import _const from "../actions/Types";

const initialState = {
  allVouchers: [],
  voucherError: "",
  individualVouchers: [],
  checkedVoucher: false,
  savedBusinessId: "",
  allVouchersTotalRecords: 0,
  allVouchersCurrentPage: 1,
  allVouchersTotalPages: 1,
  userGeneratedVouchers: [],
  userGeneratedVouchersTotalRecords: 0,
  userGeneratedVouchersCurrentPage: 1,
  userGeneratedVouchersTotalPages: 1,
  bulkVoucherError: "",
  balanceErr: "",
  totalvoucherBalance: {}
};

const VoucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_VOUCHERS:
      return {
        ...state,
        allVouchers: action.payload,
        allVouchersTotalRecords: action.totalrecords,
        allVouchersCurrentPage: action.currentPage,
        allVouchersTotalPages: action.totalPages,
      };

    case _const.ALL_USER_GENERATED_VOUCHERS:
      return {
        ...state,
        userGeneratedVouchers: action.payload,
        userGeneratedVouchersTotalRecords: action.totalrecords,
        userGeneratedVouchersCurrentPage: action.currentPage,
        userGeneratedVouchersTotalPages: action.totalPages,
      }

    case _const.CLEAR_DATA:
      return {
        ...state, allVouchers: [], userGeneratedVouchers: [],
        allVouchersTotalRecords: 0, allVouchersCurrentPage: 1,
        userGeneratedVouchersTotalRecords: 0, userGeneratedVouchersCurrentPage: 1,
        userGeneratedVouchersTotalPages: 1, allVouchersTotalPages: 1,
      };

    case _const.BULK_VOUCHER_ERROR:
      return { ...state, bulkVoucherError: action.payload };

    case _const.CREATE_VOUCHER_ERROR:
      return { ...state, voucherError: action.payload };

    case _const.ALL_INDIVIDUAL_VOUCHERS:
      return {
        ...state,
        individualVouchers: action.payload,
        checkedVoucher: true,
      };

    case _const.GETTING_INDIVIDUAL_VOUCHERS:
      return { ...state, checkedVoucher: false };

    case _const.SAVE_BUSINESS_ID:
      return { ...state, savedBusinessId: action.payload };

    case _const.VOUCHER_BALANCE:
      return { ...state, totalvoucherBalance: action.payload }

    case _const.VOUCHER_BALANCE_ERR:
      return { ...state, balanceErr: action.payload, totalvoucherBalance: {} }

    default:
      return state;
  }
};

export default VoucherReducer;
