import React, { Component } from "react";
import { connect } from "react-redux";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { agentReport, getFormDetails } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import AgentCard from "./AgentCard";
import { numberWithCommas } from "../../Helper";
import filesize from "filesize";

class AgentFilter extends Component {
  componentWillMount() {
    this.props.agentReport(this.props.dateStart, this.props.dateEnd);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dateStart !== this.props.dateStart ||
      nextProps.dateEnd !== this.props.dateEnd
    ) {
      this.props.agentReport(
        nextProps.dateStart,
        nextProps.dateEnd
      );
    }
  }

  render() {
    const { dateStart, dateEnd, gettingAgentReport, agentSummary } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card-header mb-1">
            <h6 className="m-0">AGENT REPORT</h6>
          </div>
          <div className="card-header mb-3" style={{ width: "50%" }}>
            <div className="mt-3 dateRange">
              <div className="mr-3"> FROM</div>
              <Datepicker
                className="dateRange"
                selected={dateStart}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["dateStart"],
                    value: e.getTime(),
                  })
                }
                dateFormat={"dd/MM/yyyy"}
              />
              <div className="mr-3 ml-3"> TO</div>
              <Datepicker
                className="dateRange"
                selected={dateEnd}
                onChange={(e) =>
                  this.props.getFormDetails({ props: ["dateEnd"], value: e.getTime() })

                }
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>
          <div className="row">
            {(agentSummary.length > 0) & gettingAgentReport ? (
              <div className="volucherNote">
                <Loader size={10} />{" "}
              </div>
            ) : agentSummary.length > 0 ? (
              agentSummary.map((agent) => {
                return (
                  <AgentCard
                    key={agent.id}
                    agentName={
                      agent.agentName == null ? "Unnamed" : agent.agentName
                    }
                    revenue={"NGN" + numberWithCommas(agent.totalRevenue * 360)}
                    usage={filesize(agent.totalUsage)}
                  />
                );
              })
            ) : (
                  <div className="volucherNote">
                    {gettingAgentReport ? (
                      <Loader size={10} />
                    ) : (
                        <p className="voucherText"> No sessions within this period</p>
                      )}
                  </div>
                )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { dateStart, dateEnd, userId } = state.User;
  const { gettingAgentReport } = state.Loading;
  const { agentSummary } = state.Agent;
  return {
    dateStart,
    dateEnd,
    userId,
    gettingAgentReport,
    agentSummary,
  };
};

export default connect(
  MapStateToProps,
  { agentReport, getFormDetails }
)(AgentFilter);
