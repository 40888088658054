import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../Redux/actions";
import {
  changeTransactionType,
  getAdminTransactions,
} from "../../Redux/actions/TransactionsAction";
import { clearSearch } from "../../Redux/actions/GeneralAction";

import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { useState } from "react";
import UserTransactionReportSingle from "./UserTransactionDetailsSingle";
import { cryptoDepositColumns, cryptoWithdrawalColumns, newMiningRewardColumns, newStakingColumns, newStakingInterestClaimsColumns, newTransferColumns, newp2pColumns } from "../../utils/TableCols/NewTransactionsCols";
import { NewtransactionsHeaderOptionTypes, cryptoDepositUserOptions, cryptoWithdrawalUserOptions, newStakingUserOptions, newTransferUserOptions, newp2pUserOptions } from "./options";
import { disbursedStakingRewardOptions } from "../NewTransactions/options";

const NewUserTransactionsReport = ({ userId }) => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");
  const [ transactionDetails, setTransactionDetails ] = useState({});

  const {
    adminTransactions,
    transactionType,
    gettingAdminTransactions,
  } = useSelector((state) => state.Transactions);
  const {
    TransactionType,
    TransferType,
    duration,
    Status,
    Page,
    PageSize,
    ReferenceCode,
    Search,
    TransactionHash,
    CurrencyId,
    DestinationWalletAddress,
    WithdrawalReference,
    startTime,
    endTime,
    ReceiverWalletAddress,
    Week,
  } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "user",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
    setTransactionDetails
  );
  const data = {
    columns:
      activeTab === 2
        ? newp2pColumns
        : activeTab === 3
        ? cryptoWithdrawalColumns
        : activeTab === 4
        ? cryptoDepositColumns
        : activeTab === 5
        ? newMiningRewardColumns
        : activeTab === 6
        ? newStakingColumns
        : activeTab === 7
        ? newStakingInterestClaimsColumns
        : newTransferColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  const fetchAdminTransactionMain = () => {
    const queries = {
      UserId: userId,
      Page,
      PageSize,
    };
  
    // Add specific queries based on transactionType
    if (transactionType === 'transfer') {
      queries.SearchParam = Search;
      queries.Duration = duration;
      queries.ReferenceCode = ReferenceCode;
    } else if (transactionType === 'p2p') {
      queries.SearchParam = Search;
      queries.Duration = duration;
      queries.ReferenceCode = ReferenceCode;
      queries.Status = Status;
      queries.TransactionType = TransactionType;
    } else if (transactionType === 'crypto-withdrawal') {
      queries.Search = Search;
      queries.CurrencyId = CurrencyId;
      queries.Duration = duration;
      queries.TransactionHash = TransactionHash;
      queries.DestinationWalletAddress = DestinationWalletAddress;
      queries.WithdrawalReference = WithdrawalReference;
      queries.StartDate = startTime;
      queries.EndDate = endTime;
    } else if (transactionType === 'crypto-deposit') {
      queries.Search = Search;
      queries.CurrencyId = CurrencyId;
      queries.Duration = duration;
      queries.TransactionHash = TransactionHash;
      queries.ReceiverWalletAddress = ReceiverWalletAddress;
      queries.StartDate = startTime;
      queries.EndDate = endTime;
    } else if (transactionType === 'mining-reward') {
      queries.Week = Week;
      queries.StartDate = startTime;
      queries.EndDate = endTime;
    } else {
      queries.SearchParam = Search; 
      queries.StartDate = startTime;
      queries.EndDate = endTime;
    }
    getAdminTransactions(dispatch, transactionType, queries);
  }

  useEffect(() => {
    // if (duration === "Custom" && (startTime === "" || endTime === "")) return;

    fetchAdminTransactionMain();
  }, [
    transactionType,
    TransactionType,
    TransferType,
    duration,
    Status,
    startTime,
    endTime,
    Page,
    PageSize,
  ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(ReferenceCode === "" && Search === "" && Week === "" && CurrencyId === "" && TransactionHash === "" && DestinationWalletAddress === "" && WithdrawalReference === "" && ReceiverWalletAddress === ""){
      fetchAdminTransactionMain();
    }
  },[ReferenceCode, Search, Week, CurrencyId, TransactionHash, DestinationWalletAddress, WithdrawalReference, ReceiverWalletAddress])

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  subHeaderTypes={NewtransactionsHeaderOptionTypes}
                  subHeader={
                    activeTab === 2
                      ? newp2pUserOptions
                      : activeTab === 3
                      ? cryptoWithdrawalUserOptions
                      : activeTab === 4
                      ? cryptoDepositUserOptions
                      : activeTab === 5
                      ? disbursedStakingRewardOptions
                      : activeTab === 6
                      ? newStakingUserOptions
                      : activeTab === 7
                      ? newStakingUserOptions
                      : newTransferUserOptions
                  }
                  ontabClick={(type) => {
                    dispatch(changeTransactionType(type));
                    dispatch(clearSearch());
                  }}
                  onClick={() => {
                    dispatch(toggleModal(true));
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={ReferenceCode}
                  onSearchClick={fetchAdminTransactionMain}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                />
              }
            />
          </div>
        </div>
      </div>
      {transactionDetails && transactionDetails.id && <UserTransactionReportSingle id={60} singleTransactionDetails={transactionDetails} />}
    </div>
  );
};

export default NewUserTransactionsReport;
