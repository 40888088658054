import _const from "../actions/Types";

const initialState = {
  dateFilteredBusinessFinancialAuditLogs: [],
  gettingBusinessAgents: false,
  businessAgents: {},
};

const AuditReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.GET_FILTERED_BY_DATE_BUSINESS_AUDIT_LOGS:
      return {
        ...state,
        dateFilteredBusinessFinancialAuditLogs: action.payload,
      };
    case _const.GETTING_BUSINESS_AGENTS:
      return {
        ...state,
        gettingBusinessAgents: action.payload,
      };
    case _const.BUSINESS_AGENTS:
      return {
        ...state,
        businessAgents: action.payload,
      };
    default:
      return state;
  }
};

export default AuditReducer;
