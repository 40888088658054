import React from 'react'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Mainnav from '../Partials/Mainnav'
import RegForm from './RegForm'
import { MainContent } from '../Partials/partials'



const MerchantReg = () =>{
    return (
        <div className="row">
        <Sidenav/>
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Business Registration"/>
                </div>  
                <BreadCrumbs/>       
                <div className="main-content-container container-fluid px-4 mt-5">
                   <RegForm/>
                </div>
            </MainContent>
       </div>
    )
}

export default MerchantReg