import React, { useState } from "react";
import {
  Container,
  SelectorsContainer,
  TabsContainer,
  Tab,
} from "./tablestyles.js";
import { SelectBox } from "./Reusables/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import { changeActiveTab } from "../../../Redux/actions/UserAction.js";

const TableHeader = ({
  subHeader,
  onClick,
  onChange,
  onSelect,
  searchfunction,
  searchvalue,
  hasOptions,
  myOptions,
  subHeaderTypes,
  ontabClick,
  dataLength,
  onSearchClick,
  datalistOptions,
  onSearchTableClick,
  searchvalueText,
  gettingDataListOptions,
  searchvalueDeviceModel,
  onSearchDeviceModel,
  gettingDataDeviceModelOptions,
  dataDeviceModelOptions,
  searchvalueManufacturer,
  onSearchManufacturer,
  dataManufacturerOptions,
  gettingDataManufacturerOptions,
  // setSearchValueText
}) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [activeSelector, setActiveSelector] = useState(0);
  const { activeTab } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  // useState to track whether onSearchClick is called, which will make the search dropdown active
  const [datalistVisible, setDatalistVisible] = useState(false);
  const [dataDeviceModelVisible, setDataDeviceModelVisible] = useState(false);
  const [dataManufacturerVisible, setDataManufacturerVisible] = useState(false);

  // state to show or hide start and end date
  const [showCustomDate, setShowCustomDate] = useState(false);

  const filteredMyOption = () => {
    const result = myOptions.map((item) => {
      return { id: item.id, option: item.formCode };
    });
    return [{ id: 1, option: "All" }].concat(result);
  };
  // useEffect(() => {
  //   dispatch(changeActiveTab(1));
  // }, []);

  const onOptionSelect = () => {};
  return (
    <Container>
      <TabsContainer>
        {subHeaderTypes &&
          subHeaderTypes.map((type) => (
            <Tab
              key={type.id}
              active={activeTab}
              id={type.id}
              onClick={() => {
                dispatch(changeActiveTab(type.id));
                ontabClick && ontabClick(type.name.toLowerCase());
              }}

            >
              {type.name}
            </Tab>
          ))}
      </TabsContainer>
      <SelectorsContainer>
        <>
          {subHeader &&
            subHeader.map((item, index) => (
              (showCustomDate || (item.type !== "customStartTime" && item.type !== "customEndTime")) && (<SelectBox
                key={item + index}
                title={item.title}
                onDropdownReveal={() => {
                  setActiveSelector(item.id);
                  setToggleDropdown(!toggleDropdown);
                }}
                onOptionSelect={() => onOptionSelect()}
                toggleDropdown={
                  activeSelector === item.id && item.type === "select"
                    ? toggleDropdown
                    : false
                }
                onClick={(item.type === "button"  || item.type === "exportToEmail") && onClick}
                name={"Title"}
                options={
                  hasOptions && myOptions ? filteredMyOption() : item.options
                }
                inputType={item.type}
                placeholder={item.placeholder}
                icon={item.icon && item.icon}
                onChange={onChange}
                onSelect={onSelect}
                searchfunction={searchfunction}
                item={item}
                tableId={item.tableId}
                searchvalue={searchvalue}
                hasOptions={hasOptions}
                condition={item.condition && item.condition}
                dataLength={dataLength}
                onSearchClick={onSearchClick}
                // datalist={item.title === "Search"}
                datalistOptions={datalistOptions}
                datalistVisible={datalistVisible}
                setDatalistVisible={setDatalistVisible}
                onSearchTableClick={onSearchTableClick}
                searchvalueText={searchvalueText}
                // setSearchValueText={setSearchValueText}
                gettingDataListOptions={gettingDataListOptions}
                // for device model
                dataDeviceModelOptions={dataDeviceModelOptions}
                dataDeviceModelVisible={dataDeviceModelVisible}
                setDataDeviceModelVisible={setDataDeviceModelVisible}
                searchvalueDeviceModel={searchvalueDeviceModel}
                onSearchDeviceModel={onSearchDeviceModel}
                gettingDataDeviceModelOptions={gettingDataDeviceModelOptions}
                // for device manufacurer
                searchvalueManufacturer={searchvalueManufacturer}
                onSearchManufacturer={onSearchManufacturer}
                dataManufacturerVisible={dataManufacturerVisible}
                setDataManufacturerVisible={setDataManufacturerVisible}
                dataManufacturerOptions={dataManufacturerOptions}
                gettingDataManufacturerOptions={gettingDataManufacturerOptions}

                setShowCustomDate={setShowCustomDate}
              />)
            ))}
        </>
      </SelectorsContainer>
    </Container>
  );
};

export default TableHeader;
