

// fraud-------------------------------------------------------------------------------------
export const fraudColumns = [
    {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 170,
    },
    {
        label: "Prohibited Operations",
        field: "prohibitedOperations",
        sort: "asc",
        width: 170,
    },
    {
        label: "Fraudster Details",
        field: "fraudster",
        sort: "asc",
        width: 170,
    },
    {
        label: "Reason for Suspicion",
        field: "reason",
        sort: "asc",
        width: 170,
    },
    {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 170,
    },
    {
        label: "",
        field: "remove",
        sort: "asc",
        width: 170,
    },

];

export const fraudListOptions = [
    {
        id: 1,
        title: "Status",
        type: "select",
        onSelectProp: "Status",
        options: [
            {
                id: 1,
                option: "All",
            },
            {
                id: 2,
                option: "Under Review",
            },
            {
                id: 3,
                option: "Suspended",
            },
            {
                id: 4,
                option: "Released",
            },
        ],
    },
    {
        id: 2,
        title: "Operation",
        type: "select",
        onSelectProp: "operation",
        options: [
            {
                id: 1,
                option: "",
            },
            {
                id: 2,
                option: "Funding",
            },
            {
                id: 3,
                option: "Withdrawal",
            },
            {
                id: 4,
                option: "Funding_P2P",
            },
            {
                id: 5,
                option: "Withdrawal_P2P",
            },
            {
                id: 6,
                option: "Transfer",
            },
            {
                id: 7,
                option: "Staking",
            },
            {
                id: 8,
                option: "Unstaking",
            },
            {
                id: 9,
                option: "ForceUnlock",
            },
            {
                id: 10,
                option: "StakingRewardClaim",
            },
            {
                id: 11,
                option: "MiningRewardClaim",
            },
            
        ],
    },
    {
        id: 3,
        title: "Search",
        type: "text",
        placeholder: "Search...",
        onChangeProp: "search",
    },

];