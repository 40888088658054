export const LatestDevicesCols = [
  // {
  //   label: "Device ID",
  //   field: "deviceId",
  //   sort: "asc",
  //   width: 270,
  // },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 270,
  },
  {
    label: "Mac Address",
    field: "macAddress",
    sort: "asc",
    width: 270,
  },
  {
    label: "BSSID",
    field: "bssid",
    sort: "asc",
    width: 270,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 270,
  },
];

export const allDeviceCols = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 70,
  },
  {
    label: "Date",
    field: "created",
    sort: "asc",
    width: 270,
  },
  {
    label: "Device Owner",
    field: "owner",
    sort: "asc",
    width: 270,
  },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 270,
  },
  {
    label: "BSSID",
    field: "bsid",
    sort: "asc",
    width: 270,
  },
  {
    label: "Model",
    field: "model",
    sort: "asc",
    width: 170,
  },
  {
    label: "Device Mode",
    field: "deviceMode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Manufacturer",
    field: "manufacturer",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 270,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const userDeviceCols = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 70,
  },
  {
    label: "Date",
    field: "created",
    sort: "asc",
    width: 270,
  },
  {
    label: "Device Name",
    field: "deviceName",
    sort: "asc",
    width: 270,
  },
  {
    label: "BSSID",
    field: "bsid",
    sort: "asc",
    width: 270,
  },
  {
    label: "Model",
    field: "model",
    sort: "asc",
    width: 170,
  },
  {
    label: "Device Mode",
    field: "deviceMode",
    sort: "asc",
    width: 170,
  },
  {
    label: "Manufacturer",
    field: "manufacturer",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 270,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 70,
  },
];

export const liveSessionCols = [
  {
    label: "#",
    field: "key",
    sort: "asc",
    width: 100,
  },
  {
    label: "Device Name",
    field: "device",
    sort: "asc",
    width: 100,
  },
  {
    label: "Device Manager(Agent)",
    field: "agent",
    sort: "asc",
    width: 100,
  },
  {
    label: "Connected User",
    field: "connecteduser",
    sort: "asc",
    width: 100,
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    width: 100,
  },
  {
    label: "Data Used",
    field: "dataused",
    sort: "asc",
    width: 100,
  },
  {
    label: "Amount Generated",
    field: "amtGenerated",
    sort: "asc",
    width: 100,
  },
  // {
  //   label: "",
  //   field: "viewMore",
  //   sort: "asc",
  //   width: 100,
  // },
];

export const deviceSessionHistoryCol = [
  {
    label: "Client",
    field: "client",
    sort: "asc",
    width: 100,
  },
  {
    label: "Device",
    field: "device",
    sort: "asc",
    width: 100,
  },
  {
    label: "Rate",
    field: "rate",
    sort: "asc",
    width: 100,
  },
  {
    label: "Created",
    field: "created",
    sort: "asc",
    width: 100,
  },
  {
    label: "Updated",
    field: "updated",
    sort: "asc",
    width: 100,
  },
  // {
  //   label: "",
  //   field: "view",
  //   sort: "asc",
  //   width: 100,
  // },
];

export const deviceTrackerCols = [
  {
    label: "Subscription Date",
    field: "subscriptiondate",
    sort: "asc",
    width: 100,
  },
  {
    label: "Device name",
    field: "dataAmountInBytes",
    sort: "asc",
    width: 100,
  },
  {
    label: "Data Value",
    field: "dataAmountInBytes",
    sort: "asc",
    width: 100,
  },

  {
    label: "Data Used",
    field: "dataUsedInBytes",
    sort: "asc",
    width: 100,
  },
  {
    label: "Data Remaining",
    field: "dataRemainingInBytes",
    sort: "asc",
    width: 100,
  },
  {
    label: "Validity",
    field: "subscriptiontionValidity",
    sort: "asc",
    width: 100,
  },
  {
    label: "Expiry Date",
    field: "expiryDate",
    sort: "asc",
    width: 100,
  },
];

export const activityLogCols = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 100,
  },
  {
    label: "Activity Type",
    field: "deviceActivityType",
    sort: "asc",
    width: 100,
  },
  {
    label: "Request Details",
    field: "requestDetails",
    sort: "asc",
    width: 100,
  },
  {
    label: "Request IP",
    field: "requestIp",
    sort: "asc",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 100,
  },
  {
    label: "Payload",
    field: "payload",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 100,
  },
];

export const whiteListedCols = [
  {
    label: "Date",
    field: "whiteListedAtTimeStamp",
    sort: "asc",
    width: 100,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 100,
  },
  {
    label: "Mac Address",
    field: "macAddress",
    sort: "asc",
    width: 100,
  },
 
];