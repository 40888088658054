import React from 'react'

const BusinessCard = (props) => {
   const {title, value, nairaExchange, smallTitle } = props
    return(
        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
          <div className="stats-small stats-small--1 card card-small businessCard">
            <div className="card-body p-0 d-flex">
              <div className="d-flex flex-column m-auto">
                <div className="stats-small__data text-center">
                  <span className="stats-small__label text-uppercase" style={{fontSize: '10px', color: '#fff', marginTop: '0.7rem'}}>{title} <br/>{smallTitle}
                  </span> 
                  <h6  style ={{fontSize: '1.4rem', color: '#fff'}} className="stats-small__value count my-3 ">{value}</h6>
                    { nairaExchange ?<p style ={{color: '#fff'}} > {nairaExchange}</p> :  ''}
                </div>
              </div>
            </div>
            </div>
        </div>
    )
   
}

export default BusinessCard