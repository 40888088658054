
import styled from "styled-components";
import { device } from "../../theme";


export const LogList = styled.li`
  list-style: disc;
  border-left: 1px solid #5a6169;
  padding-left: 10px;
  margin-bottom: 20px;
`
export const LogDetails = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
`
export const LogStep = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props =>  props.theme.black};
`
export const PersonalBox = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 /* max-width: 380px; */
 width: 100%;
 gap: 5px;
 padding: 10px;
 border: 1px solid #bbbfc4;
 border-radius: 8px;
 @media ${device.laptopL}{
  flex-direction: row;
  gap: 2rem;
}
@media (min-width: 1500px){
   width: fit-content;
 }
`

export const PersonalInfo = styled.div`
  display: grid;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29313c;
  text-align: center;
  p.email{
    min-width: 80%;
  }
 
  @media ${device.laptopL}{
    text-align: left;
 }
`

export const UserDetailsAvatar = styled.img`
  border: 2px solid #e8e8e8;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;