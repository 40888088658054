import _const from '../actions/Types';

const initialState = {
    errorcreatingbusinesstoken: "",
    allbusinessToken: [],
    alluserToken: [],
    allusertokenErr: "",
    alladminToken: [],
    alladminTokenErr: ""
}

const DataTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.ERROR_CREATING_BUSINESS_TOKEN:
            return { ...state, errorcreatingbusinesstoken: action.payload }

        case _const.ALL_BUSINESS_TOKEN:
            return{...state, allbusinessToken: action.payload}

        case _const.ALL_USER_TOKEN:
            return{...state, alluserToken: action.payload}

        case _const.ALL_USER_TOKEN_ERR:
            return{...state, allusertokenErr: action.payload}

        case _const.ALL_ADMIN_TOKENS:
            return{...state, alladminToken: action.payload}

        case _const.ALL_ADMIN_TOKEN_ERR:
            return{...state, alladminTokenErr: action.payload}

        default:
            return state
    }
}

export default DataTokenReducer; 