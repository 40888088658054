import React, { Component } from "react";
import { connect } from "react-redux";
import { forgotPassword } from "../../Redux/actions";
import { withRouter } from "react-router-dom";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Forgot from "../Reusables/cards/ForgotCard";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      notEmail: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin() {
    let checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (checkEmail.test(this.props.loginEmail)) {
      this.props.forgotPassword(this.props.loginEmail);
    }
  }

  render() {
    const { resettingPassword } = this.props;
    return (
      <div className="row background">
        <main className="main-content col-lg-12 col-md-12 col-sm-12">
          <div className="main-content-container container-fluid px-4">
            <BreadCrumbs />
            <div className="row mt-5">
              <Forgot
                heading="Reset PIN"
                logIn={this.handleLogin}
                logging={resettingPassword}
              />
            </div>
          </div>
          <div className='privacy mt-4'>
            <div>
              <p className="policies"> Copyright © 2018-2021. All rights reserved. Wicrypt Limited</p>
            </div>
            <div>
              <a href="https://wicrypt.com/help/" className="tanda policies"> Help Center</a>
              <a href="https://wicrypt.com/privacy-policy.html" className="tanda policies"> Privacy Policy</a>
              <a href="https://wicrypt.com/terms-condition.html" className="tanda policies"> Terms and Condition</a>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { loginEmail } = state.User;
  const { resettingPassword } = state.Loading;
  return {
    loginEmail,
    resettingPassword,
  };
};
export default withRouter(
  connect(
    MapStateToProps,
    { forgotPassword }
  )(ForgotPassword)
);
