import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Profiletabs from "./profiletabs";
import SubSidenav from "../Partials/SubSidenav"
import { MainContent } from "../Partials/partials";

const index = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Profile" />
        </div>
        <SubSidenav />
        <BreadCrumbs />
        <Profiletabs />
      </MainContent>
    </div>
  );
};

export default index;

