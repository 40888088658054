// import React, { Component } from "react";
// import { handleModal, clearModal } from "../../../Redux/actions";
// import { connect } from "react-redux";
// import {
//   ModalTitle,
//   ModalContainer,
//   ModalHeader,
//   ModalClose,
//   ModalCotent,
//   ModalSubTitle,
// } from "./modalstyles";

// class CustomModal extends Component {
//   render() {
//     const { heading, content, id, modalId, close, subHeading } = this.props;
   
//     return (
//       <section >
//         {id === modalId ? (
//           <ModalContainer>
//             <ModalCotent className="w3-animate-bottom">
//               <ModalHeader>
//                 <ModalTitle>{heading}</ModalTitle>
//                 {close ? (
//                   <ModalClose
//                     onClick={() => {this.props.handleModal(0); this.props.clearModal()}}
//                     className="material-icons"
//                   >
//                     {" "}
//                     close{" "}
//                   </ModalClose>
//                 ) : (
//                   ""
//                 )}
//               </ModalHeader>
//               <ModalSubTitle>{subHeading}</ModalSubTitle>
//               {content}
//             </ModalCotent>
//           </ModalContainer>
//         ) : (
//           ""
//         )}
//       </section>
//     );
//   }
// }
// const MapStateToProps = (state) => {
//   const { modalId } = state.General;
//   return {
//     modalId,
//   };
// };
// export default connect(MapStateToProps, { handleModal, clearModal })(CustomModal);

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleModal, clearModal, clearReplayError } from "../../../Redux/actions";
import {
  ModalTitle,
  ModalContainer,
  ModalHeader,
  ModalClose,
  ModalCotent,
  ModalSubTitle,
} from "./modalstyles";
import useOutsideClick from "../../../hooks/useOutsideClick";

const CustomModal = ({ heading, content, id, close, subHeading }) => {
  const dispatch = useDispatch();
  const {modalId} = useSelector((state) => state.General);
  const modalRef = useRef(null)

  useOutsideClick(modalRef, () => {
    dispatch(handleModal(0));
    dispatch(clearModal());
  });

  return (
    <section>
      {id === modalId ? (
        <ModalContainer ref={modalRef}>
          <ModalCotent className="w3-animate-bottom">
            <ModalHeader>
              <ModalTitle>{heading}</ModalTitle>
              {close ? (
                <ModalClose
                  onClick={() => {
                    dispatch(handleModal(0));
                    dispatch(clearModal());
                  }}
                  className="material-icons"
                >
                  {" "}
                  close{" "}
                </ModalClose>
              ) : (
                ""
              )}
            </ModalHeader>
            <ModalSubTitle>{subHeading}</ModalSubTitle>
            {content}
          </ModalCotent>
        </ModalContainer>
      ) : (
        ""
      )}
    </section>
  );
};

export const CustomErrorModal = ({ heading, content, id, close, subHeading }) => {
  const dispatch = useDispatch();
  const {modalId} = useSelector((state) => state.General);
  const modalRef = useRef(null)

  useOutsideClick(modalRef, () => {
    dispatch(handleModal(0));
    dispatch(clearModal());
    dispatch(clearReplayError());
  });

  return (
    <section>
      {id === modalId ? (
        <ModalContainer ref={modalRef}>
          <ModalCotent className="w3-animate-bottom">
            <ModalHeader>
              <ModalTitle>{heading}</ModalTitle>
              {close ? (
                <ModalClose
                  onClick={() => {
                    dispatch(handleModal(0));
                    dispatch(clearModal());
                    dispatch(clearReplayError());
                  }}
                  className="material-icons"
                >
                  {" "}
                  close{" "}
                </ModalClose>
              ) : (
                ""
              )}
            </ModalHeader>
            <ModalSubTitle>{subHeading}</ModalSubTitle>
            {content}
          </ModalCotent>
        </ModalContainer>
      ) : (
        ""
      )}
    </section>
  );
};

export default CustomModal;


export const CustomAPRModal = ({ heading, content, id, close, subHeading, onClose }) => {
  const dispatch = useDispatch();
  const {modalId} = useSelector((state) => state.General);
  const modalRef = useRef(null)

  useOutsideClick(modalRef, () => {
    dispatch(handleModal(0));
    dispatch(clearModal());
    dispatch(clearReplayError());
    onClose();
  });

  return (
    <section>
      {id === modalId ? (
        <ModalContainer ref={modalRef}>
          <ModalCotent className="w3-animate-bottom">
            <ModalHeader>
              <ModalTitle>{heading}</ModalTitle>
              {close ? (
                <ModalClose
                  onClick={() => {
                    dispatch(handleModal(0));
                    dispatch(clearModal());
                    dispatch(clearReplayError());
                    onClose();
                  }}
                  className="material-icons"
                >
                  {" "}
                  close{" "}
                </ModalClose>
              ) : (
                ""
              )}
            </ModalHeader>
            <ModalSubTitle>{subHeading}</ModalSubTitle>
            {content}
          </ModalCotent>
        </ModalContainer>
      ) : (
        ""
      )}
    </section>
  );
};
