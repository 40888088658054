import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import { toggleModal } from "./GeneralAction";

//Get Countries
export const getCountries = (page, pageSize, searchParam) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_COUNTRIES,
      payload: true
    });
    axios
      .get(
        config.apiUrl +
          `/v2/countries?page=${page}&pageSize=${pageSize}&searchParam=${searchParam}`
      )
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_COUNTRIES,
            payload: false
          });
          dispatch({
            type: _const.ALL_COUNTRIES,
            payload: res.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_COUNTRIES,
          payload: false
        });
      });
  };
};

//Create Country
export const createCountry = () => {
  return dispatch => {
    dispatch({
      type: _const.CREATING_COUNTRY,
      payload: true
    });
    axios
      .post(config.apiUrl + "/v2/countries")
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.CREATING_COUNTRY,
            payload: false
          });
          dispatch({
            type: _const.COUNTRY_CREATED,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_COUNTRY,
          payload: false
        });
      });
  };
};

//Edit Country
export const editCountry = (countryId, data) => {
  return dispatch => {
    dispatch({
      type: _const.EDITING_COUNTRY,
      payload: true
    });
    axios
      .put(config.apiUrl + `/v2/countries/${countryId}`, data)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.EDITING_COUNTRY,
            payload: false
          });
          dispatch({
            type: _const.COUNTRY_EDITED,
            payload: res.data.data
          });
          dispatch(getCountries(1, 30));
          dispatch(toggleModal(false));
        }
      })
      .catch(err => {
        dispatch({
          type: _const.EDITING_COUNTRY,
          payload: false
        });
        dispatch({
          type: _const.ERROR_EDITING_COUNTRY,
          payload: false
        });
      });
  };
};

//Get One Country
export const getOneCountry = countryId => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ONE_COUNTRY,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v2/countries/${countryId}`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_ONE_COUNTRY,
            payload: false
          });
          dispatch({
            type: _const.ONE_COUNTRY,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ONE_COUNTRY,
          payload: false
        });
      });
  };
};
