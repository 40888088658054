import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { assignAgents, agentDevice } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import Select from "react-dropdown-select";

class AssignDevices extends Component {
  render() {
    const {
      managerDevices,
      clickedDeviceId,
      agentDevices,
      assigningdevice,
      businessuserId
    } = this.props;

    let agent = clickedDeviceId;

    const options = managerDevices
      ? managerDevices.map((device) => {
          return { value: device.id, label: device.name };
        })
      : "";
    return (
      <Modal heading={`Assign a new device`}>
        <div className="h-200">
          <Select
            multi
            options={options}
            onChange={(val) => this.props.agentDevice(val)}
          />
        </div>
        <Button
          theme="wicryptColor ml-3 mt-2 float"
          buttonAction={() =>
            this.props.assignAgents(agent.email, agentDevices, businessuserId)
          }
        >
          {assigningdevice ? <Loader /> : "Assign"}
        </Button>
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const { clickedDeviceId } = state.User;
  const { assigningdevice } = state.Loading;
  const { managerDevices } = state.Manager;
  const { allAgents, agentDevices } = state.Agent;
  return {
    managerDevices,
    allAgents,
    clickedDeviceId,
    agentDevices,
    assigningdevice,
  };
};

export default connect(
  MapStateToProps,
  {
    assignAgents,
    agentDevice,
  }
)(AssignDevices);
