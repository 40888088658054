import { combineReducers } from "redux";
import NavReducer from "./nav";
import BreadCrumbs from "./breadcrumbs";
import LongCardData from "./longCardData";
import Sessions from "./SessionReducer";
import Loading from "./LoaderReducer";
import User from "./UserReducer";
import Marketer from "./MarketReducer";
import Overview from "./OverviewReducer";
import UserNav from "./usernav";
import Manager from "./ManagerReducer";
import Verification from "./VerificationReducer";
import Agent from "./AgentReducer";
import BusinessNav from "./businessnav";
import Report from "./ReportReducer";
import Voucher from "./VoucherReducer";
import Service from "./ServiceReducer";
import DynamicForm from "./DynamicFormReducer";
import Countries from "./CountriesReducer";
import Adverts from "./AdvertReducer";
import Admin from "./AdminReducer";
import Subscription from "./SubscriptionReducer";
import DataToken from "./DataTokenReducer";
import General from "./GeneralReducer";
import Wallet from "./WalletReducer";
import Devices from "./DeviceReducer";
import Search from "./SearchReducer";
import Transactions from "./TransactionsReducer";
import Businesses from "./BusinessLogReducer";
import RemoteCommand from "./CommandReducer";
import Stake from "./StakeReducer";
import SpendControl from "./SpendControlReducer";
import Fraud from "./FraudReducer";
import Miners from "./MinersReducer";
import Topics from "./TopicsReducer";
import RewardBooster from "./RewardBoosterReducer";

export default combineReducers({
  navLinks: NavReducer,
  breadCrumbs: BreadCrumbs,
  LongCardData: LongCardData,
  Sessions,
  Loading,
  User,
  Marketer,
  Overview,
  UserNav,
  Manager,
  Verification,
  Agent,
  BusinessNav,
  Report,
  Voucher,
  Service,
  DynamicForm,
  Countries,
  Adverts,
  Admin,
  Subscription,
  DataToken,
  General,
  Wallet,
  Devices,
  Search,
  Transactions,
  Businesses,
  RemoteCommand,
  Stake,
  SpendControl,
  Fraud,
  Miners,
  Topics,
  RewardBooster,
});
