import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import {
  switchWalletTransactionReports,
  getSearchDetails,
  clearSearch,
  getswitchWalletDeposits,
  getswitchWalletWithdrawals,
} from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import TableHeader from "../../Reusables/tables/TableHeader";
import { switchWalletOptions } from "../../UserDetails/options";
import {
  switchWalletReportColumn,
  switchWalletDepositColumn,
  switchWalletWithdrawalColumn,
  SwitchwalletDepositOptions,
} from "../../UserDetails/options";
import { switchwalletReport } from "../../../utils/TableRows/transactionsTableRows";

const SwitchWalletReport = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state.User);

  const {
    switchWalletData,
    switchWallettotalPages,
    switchWalletcurrentPage,
    switchWalletDeposits,
    switchWalletWithdrawals,
  } = useSelector((state) => state.Transactions);
  const {
    gettingDailySwitchWalletReport,
    gettingSwitchWalletDeposits,
    gettingSwitchWalletWithdrawal,
  } = useSelector((state) => state.Loading);
  const [reconciliationOptions, setreconciliationOptions] = useState([
    {
      id: 1,
      title: "Duration",
      type: "select",
      onSelectProp: "duration",
      options: [
        {
          id: 1,
          option: "All",
        },
        {
          id: 2,
          option: "Last Week",
        },
        {
          id: 3,
          option: "Last Month",
        },
        {
          id: 4,
          option: "Last 5 Months",
        },
        {
          id: 5,
          option: "Last Year",
        },
        {
          id: 6,
          option: "Custom",
        },
      ],
    },
  ]);
  const {
    duration,
    Page,
    PageSize,
    startTime,
    endTime,
    search,
    Status,
  } = useSelector((state) => state.Search);

  let allRows = switchwalletReport(
    activeTab === 1
      ? switchWalletData
      : activeTab === 2
      ? switchWalletDeposits.records
      : switchWalletWithdrawals.records
  );

  const data = {
    columns:
      activeTab === 1
        ? switchWalletReportColumn
        : activeTab === 2
        ? switchWalletDepositColumn
        : switchWalletWithdrawalColumn,
    rows: allRows,
  };

  const fetchSwitchWallet = () => {
    if (activeTab === 1) {
      let start = startTime ? new Date(startTime).getTime() : "";
      let end = endTime ? new Date(endTime).getTime() : "";
      dispatch(
        switchWalletTransactionReports(start, end, duration, Page, PageSize)
      );
    } else if (activeTab === 2) {
      dispatch(
        getswitchWalletDeposits(
          Page,
          PageSize,
          search,
          Status,
        )
      );
    } else {
      dispatch(
        getswitchWalletWithdrawals(
          Page,
          PageSize,
          search,
          "",
          Status,
        )
      );
    }
  }

  useEffect(() => {
    fetchSwitchWallet();
  }, [
    duration,
    Page,
    PageSize,
    startTime,
    endTime,
    activeTab,
    Status,
    // search
  ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchSwitchWallet();
    }
  },[search])

  useEffect(() => {
    if (duration === "Custom") {
      setreconciliationOptions([
        {
          id: 1,
          title: "Duration",
          type: "select",
          onSelectProp: "duration",
          options: [
            {
              id: 1,
              option: "All",
            },
            {
              id: 2,
              option: "Last Week",
            },
            {
              id: 3,
              option: "Last Month",
            },
            {
              id: 4,
              option: "Last 5 Months",
            },
            {
              id: 5,
              option: "Last Year",
            },
            {
              id: 6,
              option: "Custom",
            },
          ],
        },
        {
          id: 3,
          title: "Start Time",
          type: "startTime",
          onChangeProp: "startTime",
        },
        {
          id: 4,
          title: "End Time",
          type: "endTime",
          onChangeProp: "endTime",
        },
      ]);
    } else {
      setreconciliationOptions([
        {
          id: 1,
          title: "Duration",
          type: "select",
          onSelectProp: "duration",
          options: [
            {
              id: 1,
              option: "All",
            },
            {
              id: 2,
              option: "Last Week",
            },
            {
              id: 3,
              option: "Last Month",
            },
            {
              id: 4,
              option: "Last 5 Months",
            },
            {
              id: 5,
              option: "Last Year",
            },
            {
              id: 6,
              option: "Custom",
            },
          ],
        },
      ]);
    }
  }, [duration]);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"reconciliation-report"}
              pageCount={
                activeTab === 1
                  ? switchWallettotalPages
                  : activeTab === 2
                  ? switchWalletDeposits.totalPages
                  : switchWalletWithdrawals.totalPages
              }
              currentPage={
                activeTab === 1
                  ? switchWalletcurrentPage
                  : activeTab === 2
                  ? switchWalletDeposits.currentPage
                  : switchWalletWithdrawals.currentPage
              }
              header={
                <TableHeader
                  subHeaderTypes={switchWalletOptions}
                  subHeader={
                    activeTab === 1
                      ? reconciliationOptions
                      : SwitchwalletDepositOptions
                  }
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  ontabClick={(type) => {
                    dispatch(clearSearch());
                    // dispatch(changeTransactionType(type));
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSearchClick={fetchSwitchWallet}
                />
              }
              loading={
                activeTab === 1
                  ? gettingDailySwitchWalletReport
                  : activeTab === 2
                  ? gettingSwitchWalletDeposits
                  : gettingSwitchWalletWithdrawal
              }
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={
                    "You have no  account reconcilliation report for the set parameters"
                  }
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchWalletReport;
