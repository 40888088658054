import React from "react";
import { SubmitButton, SubmitButtonContainer } from "../Devices/modals/styles";
import Loader from "../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../Reusables/modals/CustomModal";

function SetNodeProviderActiveModal({ onSubmit, setActiveError, updatingProviderStatus, proverdersName }) {

  return (
      <div>
          <CustomErrorModal
              id={84}
              heading="Set As Active"
              subHeading={`Set this node provdiver - ${proverdersName} - to active`}
              close={true}
              content={
                  <>
                    {setActiveError ? <p className="errorMsgs mt-3 t-center mb-0">{setActiveError}</p> : ""}
                    <SubmitButtonContainer onClick={onSubmit}>
                        <SubmitButton>{updatingProviderStatus ? <Loader /> : "Confirm"}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default SetNodeProviderActiveModal;
