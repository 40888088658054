import _const from '../actions/Types'

const initialState = {
    allcountries: [],
    countriesCurrentPage: 1,
    countriesTotalPages: 1,
    countriesTotalRecords: 1,
    errorEditingCountry: ""
}

const CountriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.ALL_COUNTRIES:
            return { ...state, allcountries: action.payload.data, 
                countriesCurrentPage: action.payload.meta.page, 
                countriesTotalPages: action.payload.meta.numberOfPages,
                countriesTotalRecords: action.payload.meta.total
             }

        case _const.ERROR_EDITING_COUNTRY:
            return{...state, errorEditingCountry: action.payload }

        default:
            return state
    }
}

export default CountriesReducer