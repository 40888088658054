import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUserSpendControl} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  SubmitButton,
  SubmitButtonContainer,
} from "./style";

const CustomControl = ({ userId }) => {

  const dispatch = useDispatch();
  const [customData, setCustom] = useState({ 
    "currencyId": 0,
    "operation": 0,
    "singleTransactionVolume": 0,
    "dailyCumulativeVolume": 0,
    "cumulativeVolume7days": 0,
    "maxDailyTransactionCount": 0,
    "maxTransactionCount7Days": 0, 
    "userId": ""});


  const { operation} = useSelector((state) => state.User);
  const { userSpendError} = useSelector((state) => state.SpendControl);
  const { creatingUserSpend } = useSelector((state) => state.Loading);


  useEffect(()=>{
    setCustom({...customData, 
    "singleTransactionVolume": operation.singleTransactionVolume, 
    "dailyCumulativeVolume": operation.dailyCumulativeVolume,
    "cumulativeVolume7days": operation.cumulativeVolume7days,
    "maxDailyTransactionCount": operation.maxDailyTransactionCount,
    "maxTransactionCount7Days": operation.maxTransactionCount7Days, 
  })
  }, [operation])


  const handleSubmit = () =>{

    dispatch(createUserSpendControl({...customData, 
    "currencyId": operation.currencyId,
    "operation": operation.operation, 
    "userId": userId}))
  }

  return (
    <div>
      <CustomModal
        id={55}
        heading={`Set spend control for ${operation.operation}`}
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
            <div>

              <label> Single Transaction Volume</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="number"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.singleTransactionVolume}
                  onChange={(e) => setCustom({...customData, "singleTransactionVolume": Number(e.target.value)})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            <div>

              <label> Daily Cummulative Volume</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="number"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.dailyCumulativeVolume}
                  onChange={(e) => setCustom({...customData, "dailyCumulativeVolume": Number(e.target.value)})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            <div>

              <label> Cummulative Volume for 7 days</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="number"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.cumulativeVolume7days}
                  onChange={(e) => setCustom({...customData, "cumulativeVolume7days": Number(e.target.value)})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>
            <div>

              <label> Max Transaction Count</label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="number"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.maxDailyTransactionCount}
                  onChange={(e) => setCustom({...customData, "maxDailyTransactionCount": Number(e.target.value)})}
                  style={{ width: "400px" }}
                />
              </div>
            </div> <div>

              <label> Max Transaction Count for 7 days </label>
              <div className="input-group mb-2 mt-2">
                <input
                  type="number"
                  className="form-control loginCred mb3"
                  aria-describedby="basic-addon1"
                  value={customData && customData.maxTransactionCount7Days}
                  onChange={(e) => setCustom({...customData, "maxTransactionCount7Days": Number(e.target.value)})}
                  style={{ width: "400px" }}
                />
              </div>
            </div>


            {userSpendError && <p className="errorMsgs"> {userSpendError}</p>}

            <SubmitButtonContainer>
              <SubmitButton
                onClick={() => handleSubmit()}
              >
                {creatingUserSpend ? <Loader /> : "Save"}
              </SubmitButton>
            </SubmitButtonContainer>
          </div>
        }
      />
    </div>
  );
};

export default CustomControl;
