import React, { Component } from 'react';
import Mainnav from '../Partials/Mainnav';
import Sidenav from '../Partials/Sidenav';
import BreadCrumbs from '../Partials/Breadcrumbs';
import Upgrade from './upgradeacct';
import { Link } from 'react-router-dom';
import { MainContent } from '../Partials/partials';



class index extends Component {
    render() {

        return (
            <div className="row">
                <Sidenav />
                <MainContent className="main-content">
                    <div className="main-navbar sticky-top bg-white">
                        <Mainnav pageTitle={<Link to="/manager"> <i className="material-icons">arrow_back</i> Verify account </Link>} />
                    </div>
                    <BreadCrumbs />
                    <div className="main-content-container container-fluid px-4 mt-5">
                        <Upgrade />
                    </div>

                </MainContent>
            </div>
        );
    }
}


export default index