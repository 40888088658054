import React, { useEffect, useState } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import UserTransactions from "./UserTransactions";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { useSelector, useDispatch } from "react-redux";
import ActivityLogs from "./ActivityLogs";
import Devices from "./Devices";
import Profile from "./Profile";
import ChangeRole from "./modals/ChangeRole";
import { handleSubNav } from "../../Redux/actions";
import axios from "axios";
import config from "../../Redux/reducers/config";

const index = (props) => {
  let selectedUserId = props.match.params.userId;
  const { toggleSubnav } = useSelector((state) => state.General);
  const [current, setCurrent] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrent(toggleSubnav);
  }, []);

  const [userProfile, setUserProfile] = useState({});
  const userId = window.location.pathname.split("/").at(-1);

  useEffect(() => {
    const getUserProfile = async () => {
      await axios
        .get(config.adminApiUrl + `/v1/users/profile/${userId}`)
        .then((res) => {
          setUserProfile(res.data.data);
        });
    };
    getUserProfile();
  }, []);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link
                to="/admins"
                className="pointer mb-0"
                onClick={() => dispatch(handleSubNav(current))}
              >
                {" "}
                <i className="material-icons">arrow_back</i> {userProfile.name}
              </Link>
            }
            listItems={[
              { id: 1, item: "Profile" },
              // { id: 2, item: "Activity Logs" },
              { id: 3, item: "Transactions" },
              { id: 4, item: "Devices" },
            ]}
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <ChangeRole />
          {toggleSubnav === 1 ? (
            <Profile userProfile={userProfile} />
          ) : toggleSubnav === 2 ? (
            <ActivityLogs />
          ) : toggleSubnav === 3 ? (
            <UserTransactions userId={selectedUserId} />
          ) : (
            <Devices userId={selectedUserId} />
          )}
        </div>
      </MainContent>
    </div>
  );
};

export default index;
