import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getLockupRequests, getMiningWeeks, getSearchDetails } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { getRewardBoosterRows } from "../../utils/TableRows/rewardBoosterRow";
import { lockupColumns } from "../../utils/TableCols/RewardBoosterCols";
// import { lockupOptions } from "./options";

const LockupRequests = () => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { lockupRequests } = useSelector((state) => state.RewardBooster);
  const { allMiningWeeks } = useSelector((state) => state.Miners);
  const { gettingLockupRequests} = useSelector((state) => state.Loading);
  const { week, startDate, endDate, page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  const lockupOptions = [
    {
      id: 1,
      title: "Week",
      type: "select",
      onChangeProp: "",
      onSelectProp: "week",
      options: allMiningWeeks,
  },
    {
      id: 2,
      title: "Start Date",
      type: "startTime",
      onChangeProp: "startDate",
    },
    {
      id: 3,
      title: "End Date",
      type: "endTime",
      onChangeProp: "endDate",
    },
  ];

  let allRows = getRewardBoosterRows( lockupRequests ? lockupRequests.records : [],
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );



  const data = {
    columns: lockupColumns,
    rows: allRows,
  };

  const fetchLockupRequests = () => {
    dispatch(getLockupRequests(week, startDate, endDate, page, pageSize ));
  }

  useEffect(() => {
    dispatch(getMiningWeeks())
  }, []);
  
  useEffect(() => {
      fetchLockupRequests();
  }, [week, startDate, endDate, page, pageSize]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);


  // // This is to refresh the page when the search input is empty
  // useEffect(()=>{
  //   if(week === ""){
  //     fetchLockupRequests();
  //   }
  // },[week])

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"Reward Booster"}
              pageCount={lockupRequests.totalPages}
              currentPage={lockupRequests.currentPage}
              header={
                <TableHeader
                  dataLength={lockupRequests.records ? lockupRequests.records.length : 0}
                  subHeader={ lockupOptions }
                 
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchClick={fetchLockupRequests}
                />
              }
              loading={gettingLockupRequests}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry, you have no lockup requests history"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockupRequests;
