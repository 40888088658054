import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { p2pOrderConfirmations, p2pOrderCancellations, clearSearch,  getSearchDetails} from "../../../Redux/actions";
import { getP2PWebhook } from "../../../utils/TableRows/transactionsTableRows";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import {orderConfirmationOptions, orderCancellationOptions, p2pWebhooks, p2pConfirmationColumn, p2pCancellationColumn} from "../../UserDetails/options";
import {OrderConfirmationDetail} from "./orderConfirmation"



const TransactionsReport = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector((state) => state.User);
  
  const [ orderDetail, setOrderDetail ] = useState({});
  const {gettingP2porderConfirmations, gettingP2PorderCancellations} = useSelector((state)=> state.Loading);
  const { orderConfirmations, orderCancellations} = useSelector((state) => state.Transactions);
  const {
    orderType,
    Status,
    Page,
    PageSize,
    search,
  } = useSelector((state) => state.Search);

  const handleRecord = () =>{
    if(activeTab === 1){
      return orderConfirmations ? orderConfirmations.records : []
    }else{
      return orderCancellations ? orderCancellations.records : []
    }

  }

  let allRows = getP2PWebhook( handleRecord(), setOrderDetail);
  const data = {
    columns: activeTab === 1 ? p2pConfirmationColumn : p2pCancellationColumn,
    rows: allRows,
  };

  const fetchP2pOrder = () => {
    if(activeTab === 1){
      dispatch(p2pOrderConfirmations(Page, PageSize, "", orderType, search, Status));
    }else{
      dispatch(p2pOrderCancellations(Page, PageSize, search, Status));
    }
  }

  useEffect(() => {
    fetchP2pOrder();
  }, [Page, PageSize, activeTab, Status, orderType  ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchP2pOrder();
    }
  },[search])


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
           { <CustomDataTable
              data={data}
              tableId={"p2p-webhooks"}
              pageCount={ activeTab === 1 ? orderConfirmations.totalPages : orderCancellations.totalPages}
              currentPage={orderConfirmations.currentPage}
              header={
                <TableHeader
                  subHeaderTypes={p2pWebhooks}
                  dataLength={activeTab === 1 ? orderConfirmations.records ? orderConfirmations.records.length : 0 : orderCancellations.records ? orderCancellations.records.length : 0}
                  subHeader={activeTab === 1 ? orderConfirmationOptions : orderCancellationOptions }
                  ontabClick={(type) => {
                    dispatch(clearSearch());
                    // dispatch(changeTransactionType(type));
                  }}
                  // onClick={() => {
                  //   dispatch(toggleModal(true));
                  // }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchClick={fetchP2pOrder}
                  // searchvalue={search}
                />
              }
              loading={activeTab === 1  ? gettingP2porderConfirmations : gettingP2PorderCancellations}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no P2P webhook history"}
                />
              }
            />}
          </div>
        </div>
      </div>
      {orderDetail && orderDetail.id && <OrderConfirmationDetail id={50} orderDetails={orderDetail} />}
    </div>
  );
};

export default TransactionsReport;
