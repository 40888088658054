import _const from '../actions/Types';

const initialState = {
  boosterCycles: {},
  lockupRequests: {},
  interestDisbursements: {},
  accruedInterests: {},
  boosterCyclesId: {},
  boosterCyclesUserId: {},
  lockupRequestsId: {},
  lockupRequestsUserId: {},
  accruedInterestsUserId: {},
  rewardBoosterapr: [],
  updateApr: {},
  aprError: "",
}

const RewardBoosterReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case _const.BOOSTER_CYCLES:
          return {
            ...state,
            boosterCycles: action.payload,
          };

        case _const.LOCKUP_REQUESTS:
          return {
            ...state,
            lockupRequests: action.payload,
          };

        case _const.INTEREST_DISBURSEMENTS:
          return {
            ...state,
            interestDisbursements: action.payload,
          };

        case _const.ACCRUED_INTERESTS:
          return {
            ...state,
            accruedInterests: action.payload,
          };

        case _const.BOOSTER_CYCLES_ID:
          return {
            ...state,
            boosterCyclesId: action.payload,
          };

        case _const.BOOSTER_CYCLES_USER_ID:
          return {
            ...state,
            boosterCyclesUserId: action.payload,
          };

        case _const.LOCKUP_REQUESTS_ID:
          return {
            ...state,
            lockupRequestsId: action.payload,
          };

        case _const.LOCKUP_REQUESTS_USER_ID:
          return {
            ...state,
            lockupRequestsUserId: action.payload,
          };

        case _const.ACCRUED_INTERESTS_USER_ID:
          return {
            ...state,
            accruedInterestsUserId: action.payload,
          };
        
        case _const.CLEAR_SEARCH:
          return {
            ...state,
            boosterCycles: {},
            lockupRequests: {},
            interestDisbursements: {},
            accruedInterests: {},
            boosterCyclesId: {},
            boosterCyclesUserId: {},
            lockupRequestsId: {},
            lockupRequestsUserId: {},
            accruedInterestsUserId: {},
          };

        case _const.REWARD_BOOSTER_APR:
          return {
            ...state,
            rewardBoosterapr: action.payload,
          };

        case _const.UPDATE_APR:
          return {
            ...state,
            updateApr: action.payload,
          };

        case _const.APR_ERROR:
          return{
            ...state, 
            aprError: action.payload
          };
        
        case _const.CLEAR_REPLAY_ERROR:
          return {...state, aprError: ""};          
    
        default:
          return state;
      }
}

export default RewardBoosterReducer