import React from "react";
import {Link} from "react-router-dom";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import DevicesByCommands from "./DevicesByCommand";


const DeviceCommands = () => {


  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle={ <Link to="/commands"> <i className="material-icons">arrow_back</i> Command Details</Link>}/>
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          <DevicesByCommands />
        </div>
      </MainContent>
    </div>
  );
};

export default DeviceCommands;
