import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { StakeStatData, StakeStatValue } from "../UserDetails/styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import { changeActiveTab, clearForms, handleSubNav } from "../../Redux/actions";
import { changeTransactionType } from "../../Redux/actions/TransactionsAction";
import {
  numberWithCommaswithoutdecimals
} from "../../Helper";

const UserTransactionReport = (props) => {
  const singleTransactionDetails = JSON.parse(
    localStorage.getItem("singleTransactionDetails")
  );
  const userId = window.location.pathname.split("/").at(-2);
  const currentTab = localStorage.getItem("activeInnerTab");

  const dispatch = useDispatch();

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link
                to={`/user/${userId}`}
                className="pointer mb-0"
                onClick={() => {
                  dispatch(changeActiveTab(Number(currentTab)));
                  dispatch(
                    changeTransactionType(
                      parseInt(currentTab) === 1
                        ? "bank"
                        : parseInt(currentTab) === 2
                        ? "crypto"
                        : parseInt(currentTab) === 3
                        ? "p2p"
                        : parseInt(currentTab) === 2
                        ? "transfer"
                        : "mining-rewards"
                    )
                  );
                  dispatch(clearForms());
                  dispatch(handleSubNav(4));
                }}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          <div className="col-lg-12 ">
            <div className="card">
              <div className="card-body p-6 ">
                <div className="row mb-2">
                  {singleTransactionDetails.name && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Name</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.name}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.deviceName && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Name</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.deviceName}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.email && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Email</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.email}
                      </StakeStatValue>
                    </div>
                  )}
                  {/* {singleTransactionDetails.id && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Transation ID</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.id}
                      </StakeStatValue>
                    </div>
                  )} */}
                  {singleTransactionDetails.walletAddress && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet Address</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.walletAddress}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.referenceCode && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Reference</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.referenceCode}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {singleTransactionDetails.transactionType && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Type</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.transactionType}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.liquidityProvider && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Liquidity Provider</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.liquidityProvider}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.buyAmount && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Buy Amount</StakeStatData>
                      <StakeStatValue>
                        {numberWithCommaswithoutdecimals(singleTransactionDetails.buyAmount)}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.sellAmount && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Sell Amount</StakeStatData>
                      <StakeStatValue>
                        {numberWithCommaswithoutdecimals(singleTransactionDetails.sellAmount)}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.currency && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Currency</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.currency}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.bankName && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Bank Name</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.bankName}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.accountNumber && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Account Number</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.accountNumber}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.network && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Network</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.network}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.amount && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Amount</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.amount}{" "}
                        {singleTransactionDetails.currency}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                {singleTransactionDetails.liquidationProvider && (
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    {singleTransactionDetails.liquidationProvider && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Liquidity Provider</StakeStatData>
                        <StakeStatValue>
                          {singleTransactionDetails.liquidationProvider}
                        </StakeStatValue>
                      </div>
                    )}
                    {singleTransactionDetails.buyCurrrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy Currency</StakeStatData>
                        <StakeStatValue>
                          {singleTransactionDetails.buyCurrrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {singleTransactionDetails.sellCurrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Currency</StakeStatData>
                        <StakeStatValue>
                          {singleTransactionDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {singleTransactionDetails.buyAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy amount</StakeStatData>
                        <StakeStatValue>
                          {singleTransactionDetails.buyAmount}{" "}
                          {singleTransactionDetails.buyCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {singleTransactionDetails.sellAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Amount</StakeStatData>
                        <StakeStatValue>
                          {singleTransactionDetails.sellAmount}{" "}
                          {singleTransactionDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {singleTransactionDetails.destination && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Destination</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.destination}
                      </StakeStatValue>
                    </div>
                  )}
                  {/* {singleTransactionDetails.wntPrice && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>WNT Price</StakeStatData>
                      <StakeStatValue>
                        $ {singleTransactionDetails.wntPrice}
                      </StakeStatValue>
                    </div>
                  )} */}
                  {singleTransactionDetails.wallet && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.wallet}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.createdAt && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      <StakeStatValue>
                        {moment(singleTransactionDetails.createdAt).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        |{" "}
                        {moment(singleTransactionDetails.createdAt).format(
                          "LT"
                        )}
                      </StakeStatValue>
                    </div>
                  )}
                   {singleTransactionDetails.week && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Week</StakeStatData>
                      <StakeStatValue>
                        {singleTransactionDetails.week}
                      </StakeStatValue>
                    </div>
                  )}
                  {singleTransactionDetails.status && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      <StakeStatValue className={singleTransactionDetails.status.toUpperCase()}>
                        {singleTransactionDetails.status}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default UserTransactionReport;
