import React from "react";
import { ContinueButton, ButtonContainer} from "./inputstyles"


export const ContinueBtn = ({ title, onClick, disabled, color, width}) => {
    return (
        <ButtonContainer>
            <ContinueButton 
                className="form-control" 
                onClick={onClick}
                disabled={disabled}
                color={color}
                width={width}
                >
                {title}  
            </ContinueButton>
        </ButtonContainer>
    )
}

