import React, { useState } from "react";
import {
  InputTagBox,
  Label,
  SelectedOption,
  DropdownContainer,
  Optionvalues,
  SelectContainer,
} from "./selectStyle";

export const SimpleSelect = ({
  item,
  title,
  onDropdownReveal,
  name,
  disabled,
  options,
  onSelect,
  searchfunction,
  hasOptions,
  selectProp
}) => {
  const [value, setValue] = useState(0);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  return (
    <SelectContainer className="form-group p-r mb-0">
      {title && (
        <Label htmlfor={name}>
          {title}
          {" :"}
        </Label>
      )}
      <InputTagBox
        onClick={() => setToggleDropdown(!toggleDropdown)}
        disabled={disabled}
      >
        <SelectedOption>
          <p>{options && options[value].option}</p>
        </SelectedOption>
        <img src="/Assets/chevron-downward.svg" height="5px" alt="" />
      </InputTagBox>
      {toggleDropdown ? (
        <DropdownContainer>
          {options &&
            options.map((each, index) => {
              return (
                <Optionvalues
                  key={each.option}
                  onClick={() => {
                    if (!hasOptions) {
                      setValue(index);
                      onSelect &&
                        onSelect(each.value, selectProp ? selectProp : "duration");
                      searchfunction && searchfunction();
                      onDropdownReveal && onDropdownReveal();
                      setToggleDropdown(false);
                    } else {
                      setValue(index);
                      onSelect && onSelect(each.id, selectProp ? selectProp : "duration");
                    }
                  }}
                  choosenOption={value === index ? true : false}
                >
                  {" "}
                  {each.option}
                </Optionvalues>
              );
            })}
        </DropdownContainer>
      ) : (
        ""
      )}
    </SelectContainer>
  );
};
