import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommands, handleModal, getFormDetails } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import Loader from "../Reusables/loaders/MoonLoader";
import LinkButton from "../../Components/Reusables/buttons/LinkButton";

const ListCommands = () => {

    const dispatch = useDispatch();

    const { remoteCommands } = useSelector((state) => state.RemoteCommand);
    const { gettingCommands, creatingBatchCommands } = useSelector((state) => state.Loading);
    const { commandId, role } = useSelector((state) => state.User);


    let allRows = [];
    remoteCommands.map((each) => {
        return allRows.push({
            command: each.commandName,
            timesIssued: each.numberOfTimesIssued,
            devicesIssued: each.numberOfDevicesIssuedCommand,
            devicesExecuted: each.numberOfDevicesExecutedCommand,
            issueCommand: <div
                className="details t-center"
                onClick={() => {
                    dispatch(handleModal(52));
                    dispatch(getFormDetails({
                        props: ["commandId"],
                        value: each.commandId
                        ,
                    })
                    )
                }}
            >
                {creatingBatchCommands && commandId === each.commandId ? <Loader /> : "Issue command"}
            </div>,
            deleteCommand: <div className="t-center">
                <img src="/Assets/images/delete.svg"
                    className=" t-center pointer"
                    height="20px"
                    alt="view more"
                    onClick={() => {
                        dispatch(handleModal(51));
                        dispatch(getFormDetails({
                            props: ["commandId"],
                            value: each.commandId
                            ,
                        })
                        )
                    }}
                />
            </div>,
            viewMore:    
            <LinkButton
                text={"View More"}
                color={"yellow"}
                link={`/commands/${each.commandId}`}
                size={"medium"}
            />

               
        });
    });


    useEffect(() => {
        dispatch(getCommands())
    }, []);

    const data = {
        columns: [
            {
                label: "Command",
                field: "command",
                sort: "asc",
                width: 170,
            },
            {
                label: "Number of times issued",
                field: "timesIssued",
                sort: "asc",
                width: 170,
            },
            {
                label: "Devices Issued on",
                field: "devicesIssued",
                sort: "asc",
                width: 170,
            },
            {
                label: "Devices Executed on",
                field: "devicesExecuted",
                sort: "asc",
                width: 170,
            },
            !role.includes("Operations") &&  {
                label: "",
                field: "issueCommand",
                sort: "asc",
                width: 170,
            },
            !role.includes("Operations") && {
                label: "",
                field: "deleteCommand",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "viewMore",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    return (
        <div className=" mb-4">
            {role.includes("Operations")? null : 
            <div className="card card-small mb-4 w3-animate-bottomd">
                <div className="row">
                    <div className="col-lg-12 p-3">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-3 fl-right">
                            <button onClick={() =>
                                dispatch(handleModal(50)
                                )} className="btn wicryptColor"> Create Command</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="row">
                {gettingCommands ? (
                    <div className="col-lg-12 mb-4">
                        <CustomLoader />{" "}
                    </div>
                ) : (
                    <div className="col-lg-12">
                        <CustomDataTable
                            data={data}
                        />
                    </div>)}
            </div>

        </div>
    );
};

export default ListCommands;
