import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveAdmins, getAdminRoles } from "../../Redux/actions/AdminAction";
import { toggleModal } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { adminColumn, adminHeaderOptions } from "./options";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { getActiveAdminRows } from "../../utils/TableRows/adminRows";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { clearSearch } from "../../Redux/actions/GeneralAction";


const ActiveAdmins = () => {
  const { activeAdmins, gettingActiveAdmins, changeAdminRoles } = useSelector(
    (state) => state.Admin
  );
  const { page, search, role } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  const allRows = getActiveAdminRows(activeAdmins.records, dispatch);
  const data = {
    columns: adminColumn,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
    dispatch(getAdminRoles())
  }, []);

  const fetchActiveAdmins = () => {
    const queries = {
      page,
      search,
      role,
    };

    getActiveAdmins(dispatch, queries);
  }

  useEffect(() => {
    fetchActiveAdmins();
  }, [page, role, changeAdminRoles ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchActiveAdmins();
    }
  },[search])

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              pageCount={activeAdmins.totalPages}
              currentPage={activeAdmins.currentPage}
              header={
                <TableHeader
                  subHeader={adminHeaderOptions}
                  onClick={() => {
                    dispatch(toggleModal(true));
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={tokenSearch}
                  onSearchClick={fetchActiveAdmins}
                />
              }
              loading={gettingActiveAdmins}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no active admins "}
                  // descr={"No active admins available at the moment for the selected specs"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ActiveAdmins;
