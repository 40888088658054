import React, { useEffect } from "react";
import { allDeviceCols } from "../../utils/TableCols/DeviceCols";
import { getAllDevicesRows } from "../../utils/TableRows/DevicesRows";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { allDevicesHeaderOptions } from "./options";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDevices } from "../../Redux/actions/AdminAction";
import { getDeviceModels, getManufacturers, toggleModal } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import { Notification, TableFlex } from "../Reusables/tables/tablestyles";

const AllDevices = () => {
  const dispatch = useDispatch();
  
  const { adminDevices, gettingAdminDevices, deviceModels, deviceManufacturers } = useSelector((state) => state.Admin);
  const { activeTab } = useSelector((state) => state.User);
  const { toggleSubnav } = useSelector((state) => state.General);
  const { startTime, endTime, Search, Manufacturer, DeviceModel, DeviceMode, Page, PageSize } = useSelector(
    (state) => state.Search
  );
  const { gettingDeviceModels, gettingDeviceManufacturers } = useSelector((state) => state.Loading);

  const allRows = getAllDevicesRows(adminDevices.records, "", "", activeTab, toggleSubnav);

  const data = {
    columns: allDeviceCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  const fetchAdminDevices = () => {
    // const queries = { Page, Name, Status: deviceStatus, Type, Model, OrderBy };
    const queries = { StartDate:startTime, EndDate:endTime, Search, Manufacturer, DeviceModel, DeviceMode, Page, PageSize };
    getAdminDevices(dispatch, queries);
  }

   // This is to get the device manufacturers
   const fetchDeviceManufacturers = () => {
    dispatch(getManufacturers(1, 30));
  }
  let availableManufacturers = deviceManufacturers ? deviceManufacturers.records : [];

  // This is to get the device model
  const fetchDeviceModels = () => {
    dispatch(getDeviceModels());
  }
  let availableModel = deviceModels ? deviceModels : [];

  useEffect(() => {
    fetchAdminDevices();
  }, [ startTime, endTime, DeviceMode, Page, PageSize]);

  // This is to refresh the page when all search input is empty
  useEffect(()=>{
    if(Search === "" && Manufacturer === "" && DeviceModel === "" ){
      fetchAdminDevices();
    }
  },[Search, Manufacturer, DeviceModel])

  return (
    <div className="">
      <CustomDataTable
        tableId={"all-devices-report"}
        data={data}
        pageCount={adminDevices.totalPages}
        currentPage={adminDevices.currentPage}
        notification={
          <TableFlex>
            <Notification>
              Note: Device names with this icon
              <img src="/Assets/adminicons/info.svg" alt="info" />
              is backed by an NFT
            </Notification>
          </TableFlex>
        }
        header={
          <TableHeader
            // subHeaderTypes={allDevicesHeaderOptionTypes}
            subHeader={allDevicesHeaderOptions}
            dataLength = {adminDevices.records ? adminDevices.records.length : 0}
            ontabClick={(val) => {
              dispatch(
                getSearchDetails({
                  props: ["deviceStatus"],
                  value: val === "all" ? "" : val,
                })
              );
              dispatch(clearSearch());
            }}
            onClick={() => dispatch(toggleModal(true))}
            onChange={(e, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            onSearchClick={fetchAdminDevices}
            onSearchTableClick={fetchAdminDevices}

            onSearchDeviceModel={fetchDeviceModels}
            searchvalueDeviceModel={DeviceModel}
            dataDeviceModelOptions={availableModel}
            gettingDataDeviceModelOptions={gettingDeviceModels}

            searchvalueManufacturer={Manufacturer}
            onSearchManufacturer={fetchDeviceManufacturers}
            dataManufacturerOptions={availableManufacturers}
            gettingDataManufacturerOptions={gettingDeviceManufacturers}
          />
        }
        loading={gettingAdminDevices}
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no device"}
            // descr={"No Device available at the moment"}
          />
        }
      />
    </div>
  );
};

export default AllDevices;
