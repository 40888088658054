import React, { Component } from "react";
import { connect } from "react-redux";
import { copyToClipboard } from "../../Redux/actions";
import { ProfileTitle, ProfileValue, BigblueText } from './profilestyles'


class Details extends Component {

  render() {
    const {
      copied,
      refCode,
      role,
      businessProfile,
    } = this.props;
    var show = role.includes("Business");

    return (
      <React.Fragment>
        {show ?      
        <div className="stats-small stats-small--1 card card-small mt-3">
        <div className="card-body">
          <div className="p-20">
          <div className="row">
            <div className="col-lg-6 fade-in">
              <ProfileTitle> REFERRAL CODE</ProfileTitle>
              <BigblueText> {refCode} 
                  <span>
                    {" "}
                    <i
                      onClick={() =>
                        this.props.copyToClipboard(refCode)
                      }
                      className="material-icons text-orange pointer"
                    >
                      file_copy
                    </i>{" "}
                    {copied === refCode ? <p className="copied"> copied </p> : ""}
                  </span>
              </BigblueText>
            </div>
          </div>
          <hr />
          
          <div className="row">
          <div className="col-lg-6 fade-in">
              <ProfileTitle> BILLING TYPE</ProfileTitle>
              <ProfileValue> {businessProfile.billingType === 1 ? "NORMAL" : "SUBSCRIPTION"}</ProfileValue>
            </div>
            <div className="col-lg-6 fade-in">
              <ProfileTitle> API KEY</ProfileTitle>
              <ProfileValue>  {businessProfile.apiKey}
              <span>
                    {" "}
                    <i
                      onClick={() =>
                        this.props.copyToClipboard(businessProfile.apiKey)
                      }
                      className="material-icons text-orange pointer"
                    >
                      file_copy
                    </i>{" "}
                    {copied === businessProfile.apiKey ? <p className="copied"> copied </p> : ""}
                  </span>
              </ProfileValue>
            </div>
          </div>  
        </div>
      </div> 
      </div> : "" }
      </React.Fragment>

    );
  }
}

const MapstateToProps = (state) => {
  const {
    copied,
    refCode,
    role,
    businessProfile,
  } = state.User;

  return {
    copied,
    refCode,
    role,
    businessProfile,
  };
};
export default connect(
  MapstateToProps,
  {
    copyToClipboard,
  }
)(Details);
