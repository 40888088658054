const initialState  = {
    longCard : [
    {
        id: 1,
        badge: "Business",
        heading: 'Conduct at an replied removal an amongst',
        paragraph: 'However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see...',
        date: '28 February 2019',
        // postImage: require('../../Assets/images/content-management/1.jpeg'),
        // avatar: require('../../Assets/images/avatars/0.jpg'),
        author: 'Written by Anna Kunis'
    },
    {
        id: 2,
        badge: "Travel",
        heading: 'Off tears are day blind smile alone had ready',
        paragraph: 'Is at purse tried jokes china ready decay an. Small its shy way had woody downs power. To denoting admitted speaking learning my...',
        date: '29 February 2019',
        // postImage: require('../../Assets/images/content-management/2.jpeg'),
        // avatar: require('../../Assets/images/avatars/1.jpg'),
        author: 'Written by Anna Kunis'
    },
    {
        id: 3,
        badge: "Technology",
        heading: 'Difficult in delivered extensive at direction',
        paragraph: 'Is at purse tried jokes china ready decay an. Small its shy way had woody downs power. To denoting admitted speaking learning my...',
        date: '29 February 2019',
        // postImage: require('../../Assets/images/content-management/3.jpeg'),
        // avatar: require('../../Assets/images/avatars/2.jpg'),
        author: 'Written by Anna Kunis'
    },
    {
        id: 4,
        badge: "Technology",
        heading: 'Difficult in delivered extensive at direction',
        paragraph: 'How but sons mrs lady when. Her especially are unpleasant out alteration continuing unreserved ready road market resolution...',
        date: '29 February 2019',
        // postImage: require('../../Assets/images/content-management/4.jpeg'),
        // avatar: require('../../Assets/images/avatars/3.jpg'),
        author: 'Written by Anna Kunis'
    },
    ]
}

const LongCardData = (state = initialState, action) => {
    return(
        state
    )
}

export default LongCardData