import React, { useEffect } from "react";
import { userDeviceCols } from "../../utils/TableCols/DeviceCols";
import { getAllDevicesRows } from "../../utils/TableRows/DevicesRows";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDevices } from "../../Redux/actions/AdminAction";
import { getDeviceModels, getManufacturers, toggleModal } from "../../Redux/actions";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import {allDevicesHeaderOptions} from "../Devices/options";
import { Notification, TableFlex } from "../Reusables/tables/tablestyles";

const UserDevices = ({ userId }) => {
  const { adminDevices, gettingAdminDevices, deviceModels, deviceManufacturers } = useSelector((state) => state.Admin);
  const { startTime, endTime, Search, Manufacturer, DeviceModel, DeviceMode, Page, PageSize } = useSelector((state) => state.Search);
  const { activeTab } = useSelector((state) => state.User);
  const { toggleSubnav } = useSelector((state) => state.General);
  const { gettingDeviceModels, gettingDeviceManufacturers } = useSelector((state) => state.Loading);

  const allRows = getAllDevicesRows(
    adminDevices.records,
    userId,
    false,
    activeTab,
    toggleSubnav
  );
  const dispatch = useDispatch();

  const data = {
    columns: userDeviceCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  const fetchUserAdminDevices = () => {
    const queries = {UserId: userId, StartDate:startTime, EndDate:endTime, Search, Manufacturer, DeviceModel, DeviceMode, Page, PageSize};
    getAdminDevices(dispatch, queries);
  }

    // This is to get the device manufacturers
    const fetchDeviceManufacturers = () => {
      dispatch(getManufacturers(1, 30));
    }
    let availableManufacturers = deviceManufacturers ? deviceManufacturers.records : [];

  // This is to get the device model
  const fetchDeviceModels = () => {
    dispatch(getDeviceModels());
  }
  let availableModel = deviceModels ? deviceModels : [];

  useEffect(() => {
    fetchUserAdminDevices();
  }, [userId, startTime, endTime, DeviceMode, Page, PageSize]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(Search === "" && Manufacturer === "" && DeviceModel === "" ){
      fetchUserAdminDevices();
    }
  },[Search, Manufacturer, DeviceModel])

  return (
    <div className="">
      <CustomDataTable
        tableId={"all-devices-report"}
        data={data}
        pageCount={adminDevices.totalPages}
        currentPage={adminDevices.currentPage}
        notification={
          <TableFlex>
            <Notification>
              Note: Device names with this icon
              <img src="/Assets/adminicons/info.svg" alt="info" />
              is backed by an NFT
            </Notification>
          </TableFlex>
        }
        header={
          <TableHeader
            // subHeaderTypes={allDevicesHeaderOptionTypes}
            subHeader={allDevicesHeaderOptions}
            ontabClick={(val) => {
              dispatch(clearSearch())
              dispatch(
                getSearchDetails({
                  props: ["deviceStatus"],
                  value: val,
                })
              );
            }}
            onClick={() => dispatch(toggleModal(true))}
            onChange={(e, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            onSearchClick={fetchUserAdminDevices}
            onSearchTableClick={fetchUserAdminDevices}

            onSearchDeviceModel={fetchDeviceModels}
            searchvalueDeviceModel={DeviceModel}
            dataDeviceModelOptions={availableModel}
            gettingDataDeviceModelOptions={gettingDeviceModels}

            searchvalueManufacturer={Manufacturer}
            onSearchManufacturer={fetchDeviceManufacturers}
            dataManufacturerOptions={availableManufacturers}
            gettingDataManufacturerOptions={gettingDeviceManufacturers}
          />
        }
        loading={gettingAdminDevices}
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no devices"}
            // descr={"No Device available at the moment"}
          />
        }
      />
    </div>
  );
};

export default UserDevices;
