import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {
  agentRemittance,
  getFormDetails,
  clearRemittance,
} from "../../Redux/actions";
import { connect } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import Datepicker from "react-datepicker";
import { numberWithCommas } from "../../Helper";
import moment from "moment";
import CustomLoader from "../Reusables/loaders/CustomLoader";

class AgentRemmitance extends Component {
  componentWillMount() {
    this.props.clearRemittance();
  }
  componentWillReceiveProps(nextProps) {
    const { selectedBusiness, remitStart, remitEnd } = nextProps;
    if (
      selectedBusiness !== this.props.selectedBusiness ||
      remitStart !== this.props.remitStart ||
      remitEnd !== this.props.remitEnd
    ) {
      this.props.clearRemittance();
      this.props.agentRemittance(selectedBusiness, remitStart, remitEnd, 1, 50);
    }
  }

  customPagination() {
    const { selectedBusiness, remittanceTotalPages, remittanceCurrentPage, remitStart, remitEnd } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessRemittance(selectedBusiness, remitStart, remitEnd, remittanceCurrentPage - 1, 50)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; remittanceTotalPages >= i; i++) {
      if (i === remittanceCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.businessRemittance(selectedBusiness, remitStart, remitEnd, i, 50)}
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (remittanceTotalPages > remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessRemittance(selectedBusiness, remitStart, remitEnd, remittanceCurrentPage + 1, 50)
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }


  render() {
    const {
      remittanceReport,
      affiliatedBusiness,
      remitStart,
      remitEnd,
      gettingRemittanceReport,
      conversionsRate,
    } = this.props;

    let allRows = [];
    remittanceReport.map((remit) => {
      return allRows.push({
        date: moment
          .parseZone(remit.remittanceDateTimeStamp)
          .format("YYYY-MM-DD"),
        business: remit.agent.business.name,
        username: remit.agent.user.userName,
        voucherRemittanceAllowance:
          remit.agent.voucherRemittanceAllowance +
          "WC | NGN" +
          numberWithCommas(
            remit.agent.voucherRemittanceAllowance * conversionsRate
          ),
        amount:
          remit.amountRemitted +
          "WC | NGN" +
          numberWithCommas(remit.amountRemitted * conversionsRate),
      });
    });

    const data = {
      columns: [
        {
          label: "Date Remitted",
          field: "date",
          sort: "asc",
          width: 170,
        },
        {
          label: "Business Name",
          field: "business",
          sort: "asc",
          width: 170,
        },
        {
          label: "Agent Username",
          field: "username",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Remittance Allowance",
          field: "voucherRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Amount Remitted",
          field: "amount",
          sort: "asc",
          width: 270,
        },
      ],

      rows: allRows,
    };
    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3">
          <div className="d-flex">
            <h6 className="m-0">AGENT REMITTANCE</h6>
          </div>

          <div className="mt-3 d-flex">
            {/* <div className='dateRange col-lg-12'> */}
            <div className="mr-3"> FROM</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitStart"],
                  value: e.getTime(),
                })
              }
              selected={remitStart}
              dateFormat={"dd/MM/yyyy"}
            />

            <div className="mr-3 ml-3"> TO</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitEnd"],
                  value: e.getTime(),
                })
              }
              selected={remitEnd}
              dateFormat={"dd/MM/yyyy"}
            />

            {/* </div> */}
            <select
              className="input-sm form-control"
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["selectedBusiness"],
                  value: e.target.value,
                })
              }
            >
              <option>Select Business</option>
              {affiliatedBusiness.map((business) => {
                return (
                  <option value={business.business.id}>
                    {business.business.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {gettingRemittanceReport & (remittanceReport.length === 0) ? (
          <CustomLoader />
        ) : remittanceReport.length > 0 ? (
          <CustomDataTable
            data={data}
            pagination={this.customPagination()}
          />
        ) : (
              <p className="voucherText t-center">
                {" "}
            Select a business and date range to view valid data
              </p>
            )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const {
    remittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    conversionsRate,
  } = state.Report;
  const { affiliatedBusiness } = state.Manager;
  const { selectedBusiness, remitStart, remitEnd } = state.User;
  const { gettingRemittanceReport } = state.Loading;
  return {
    remittanceReport,
    affiliatedBusiness,
    selectedBusiness,
    remitStart,
    remitEnd,
    gettingRemittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    conversionsRate,
  };
};
export default connect(
  MapStateToProps,
  { agentRemittance, getFormDetails, clearRemittance }
)(AgentRemmitance);
