import _const from "../actions/Types";

const initialState = {
  duration: "",
  TransactionType: "",
  TransferType: "",
  Duration: "",
  Status: "",
  Page: 1,
  PageSize: 30,
  WalletType: "",
  ReferenceCode: "",
  page: 1,
  pageSize: 30,
  search: "",
  adminSearch: "",
  role: "",
  Email: "",
  Reference: "",

  // device
  Name: "",
  Type: "",
  Model: "",
  OrderBy: "",
  deviceStatus: "",
  Search: "",
  Manufacturer: "",
  DeviceModel: "",
  DeviceMode: "",
  Week: "",

  // user
  type: "",
  country: "",
  usersType: "",

  // stake
  stakeType: "stake",
  userId: "",
  stakeDurationFilter: "",
  upcomingDuration: "NextWeek",

  //account reconciliation
  ReconicilationCurrency: "",

  //fraud 
  operation: "",
  ActivityType:"",

  //date
  startTime: "",
  endTime: "",

  //currency
  enabled: null,

  //miners
  metCriteria: "Yes",
  selectedMiningWeek: "",
  statisticsWeek: "",

  verificationStatus: "",
  hasReward: "Yes",
  unstakeDuration: "NextWeek",
  orderType: "",
  Currency: "",
  TransactionHash: "",

  // Remote command
  deviceName: "",
  deviceCommandStatus: "",

  // New transaction
  CurrencyId: "",
  DestinationWalletAddress: "",
  WithdrawalReference: "",
  ReceiverWalletAddress: "",

  // Reward booster
  lockPeriod: "",
  lockStartDate: "",
  lockEndDate: "",
  week: "",
  startDate: "",
  endDate: "",
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.SEARCH_VALUE:
      return {
        ...state,
        [action.payload.props]: action.payload.value,
      };
    case _const.CLEAR_SEARCH:
      return {
        ...state,
        duration: "",
        TransactionType: "",
        Duration: "",
        Status: "",
        Page: 1,
        PageSize: 30,
        WalletType: "",
        ReferenceCode: "",
        Reference: "",
        
        page: 1,
        pageSize: 30,
        search: "",
        adminSearch: "",
        role: "",
        Email: "",

        // device
        Name: "",
        Type: "",
        Model: "",
        OrderBy: "",
        deviceStatus: "",
        Search: "",
        Manufacturer: "",
        DeviceModel: "",
        DeviceMode: "",
        Week: "",

        // user
        type: "",
        country: "",

        // stake
        stakeType: "stake",
        userId: "",

        TransactionHash: "",
        stakeDurationFilter: "",

        // Remote command
        deviceName: "",
        deviceCommandStatus: "",

        //date
        startTime: "",
        endTime: "",

         // New transaction
        CurrencyId: "",
        DestinationWalletAddress: "",
        WithdrawalReference: "",
        ReceiverWalletAddress: "",

        // Reward booster
        lockPeriod: "",
        lockStartDate: "",
        lockEndDate: "",
        week: "",
        startDate: "",
        endDate: "",

      };
    default:
      return state;
  }
};

export default SearchReducer;
