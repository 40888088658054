import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  toggleNav,
  getFormDetails,
  getallVerificationRequests,
} from "../../Redux/actions";
import { Link } from "react-router-dom";
import {
  DropdownAvatar,
  TopNavDropDown,
  UserName,
  Email,
  Label,
  SidenavLink,
  SideIcons,
  SubTitle,
  HideonDesktop,
  Badge,
} from "./partials";
// import Invitation from "../Admins/Modal/Invitation";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useEffect } from "react";
import { handleSubNav, clearSearch } from "../../Redux/actions/GeneralAction";
import { changeActiveTab } from "../../Redux/actions/UserAction";
import { changeTransactionType } from "../../Redux/actions/TransactionsAction";

const TopDropDown = ({ navToggle }) => {
  const { role, name, email, togglesubnav } = useSelector(
    (state) => state.User
  );
  const {
    adminNav,
    userNav,
    operationsNav,
    technicalNav,
    developerNav
  } = useSelector((state) => state.navLinks);
  const { verificationTotalRecords } = useSelector(
    (state) => state.Verification
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const queries = {
      Page: 1,
      PageSize: 5,
    };
    getallVerificationRequests(dispatch, queries);
  }, []);

  // let general = [
  //   "/manager/dashboard",
  //   "/wallets",
  //   "/devices",
  //   "/voucher",
  //   "/agents",
  // ];
  let viewnav = ["/manager"];
    let nav =
    role.includes("Admin") || role.includes("SuperAdmin")
      ? adminNav
      : role.includes("Operations")
      ? operationsNav
      : role.includes("Technical")
      ? technicalNav
      : role.includes("Developer") 
      ? developerNav
      : userNav;

  return (
    <TopNavDropDown onClick={() => dispatch(toggleNav(!navToggle))}>
      <div className="d-flex mt-2">
        <div>
          <DropdownAvatar
            alt="user avatar"
            src="/Assets/images/avatars/user_icon.svg"
          />
        </div>
        <div>
          <UserName>{name}</UserName>
          <Email> {email}</Email>
          <Label>
            {role.includes("Admin") || role.includes("SuperAdmin")
              ? "Admin"
              : role.includes("Operations")
              ? "Operations"
              : "User"}
          </Label>
        </div>
        <div>
          {/* <Link to="/manager">
                  <Icon className="material-icons">chevron_right</Icon>
                </Link> */}
        </div>
      </div>
      <hr />
      <HideonDesktop>
        {nav.map((link) => {
          return (
            <Link to={`/${link.link}`}>
              <SidenavLink
                key={link.id}
                active={window.location.pathname === "/" + link.link}
              >
                <SideIcons
                  src={
                    window.location.pathname === "/" + link.link
                      ? link.activeImage
                      : link.inactiveImage
                  }
                />
                {link.title}
                <SubTitle active={window.location.pathname === "/" + link.link}>
                  {" "}
                  {link.subtitle}
                </SubTitle>
                {link.title === "Users" && (
                  <Badge>{verificationTotalRecords}</Badge>
                )}
              </SidenavLink>
            </Link>
          );
        })}
        {role.includes("Admin") ||
        role.includes("SuperAdmin") ||
        role.includes("Operations") ? (
          " "
        ) : (
          <React.Fragment>
            <SidenavLink
              onClick={() => {
                dispatch(handleSubNav(1));
                dispatch(clearSearch(1));
                dispatch(changeActiveTab(1));
                dispatch(changeTransactionType("transfer"));
                dispatch(
                  getFormDetails({
                    props: ["togglesubnav"],
                    value: !togglesubnav,
                  })
                );
              }}
              active={viewnav.includes(window.location.pathname)}
            >
              <SideIcons
                src={
                  viewnav.includes(window.location.pathname)
                    ? "/Assets/dashboardicons/activeicons/SVG/viewall.svg"
                    : "/Assets/dashboardicons/inactiveicons/SVG/viewall.svg"
                }
              />
              View All
            </SidenavLink>
            {/* <div>
                <AdvertButton
                  className="form-control"
                  onClick={() => dispatch(handleModal(16)}
                >
                  Invite Admin
                </AdvertButton>
              </div> */}
          </React.Fragment>
        )}
      </HideonDesktop>
      <SidenavLink onClick={() => dispatch(logOut())}>
        <RiLogoutCircleRLine />
        <span>Log Out</span>
      </SidenavLink>
    </TopNavDropDown>
  );
};

export default TopDropDown;
