import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Reusables/loaders/HashLoader";
import IconCard from "../Reusables/cards/IconCard";
import { SimpleSelect } from "../Reusables/selects/SimpleSelect";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import {
  numberWithCommaswithoutdecimals,
} from "../../Helper";
import { getStakingSummary } from "../../Redux/actions"

const statOptions = [
  {
    id: 1,
    option: "All",
    value: "",
  },
  {
    id: 2,
    option: "Last Week",
    value: "LastWeek",
  },
  {
    id: 3,
    option: "Last Month",
    value: "LastMonth",
  },
  {
    id: 4,
    option: "Last 5 Months",
    value: "Last5Months",
  },
  {
    id: 5,
    option: "Last Year",
    value: "LastYear",
  },
];


const StakeSummary = () => {
  const dispatch = useDispatch();

  const { gettingStakeSummary } = useSelector((state) => state.Loading);

  const { stakingSummary } = useSelector((state) => state.Stake);

  const { duration } = useSelector((state) => state.Search);
  useEffect(() => {
    dispatch(getStakingSummary(duration))
  }, [duration]);

  return (
    <div>
      <div
        className="row flex items-right justify-end"
        style={{ paddingRight: "30px" }}
      >
        <SimpleSelect
          options={statOptions}
          onSelect={(val, props) => {
            dispatch(
              getSearchDetails({
                props: [props],
                value: val,
              })
            );
          }}
        />
      </div>

      <div className="row">
        <IconCard
          title={"Completed Stake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalCompletedStake
              ) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Completed Claims"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalCompletedRewardClaim

              ) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Completed Unstake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalCompletedUnstake) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Failed Claim"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalFailedRewardClaim) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Failed Stake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalFailedStake) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Failed Unstake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalFailedUnstake) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Pending Claim"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalPendingRewardClaim
              ) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Pending Stake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalPendingStake
              ) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Pending Unstake"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingStakeSummary ? (
              <Loader />
            ) : stakingSummary ? (
              (numberWithCommaswithoutdecimals(stakingSummary.totalPendingUnstake

              ) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
      </div>
    </div>
  );
};
export default StakeSummary;
