import _const from './Types'
import axios from 'axios'
import config from '../reducers/config'
import { toggleModal } from "./GeneralAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const allsubscriptionplans = (page, pagesize, searchParam) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_SUBSCRIPTION_PLANS,
            payload: true
        })
        axios.get(config.apiUrl + `/v2/subscription-plans?page=${page}&pageSize=${pagesize}&searchParam=${searchParam}`)
            .then(res => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_PLANS,
                    payload: false
                })
                dispatch({
                    type: _const.ALL_SUBSCRIPTION_PLANS,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_PLANS,
                    payload: false
                })
            })
    }
}

export const createsubscriptionplans = (data) => {
    return (dispatch) => {
        if (data.name === "") {
            dispatch({
                type: _const.CREATING_SUBSCRIPTION_ERROR,
                payload: 1
            })
        } else if (data.monthlyFee === "") {
            dispatch({
                type: _const.CREATING_SUBSCRIPTION_ERROR,
                payload: 2
            })
        } else if (data.yearlyFee === "") {
            dispatch({
                type: _const.CREATING_SUBSCRIPTION_ERROR,
                payload: 3
            })
        }
        else {
            dispatch({
                type: _const.CREATING_SUBSCRIPTION_ERROR,
                payload: ""
            })
            dispatch({
                type: _const.CREATING_SUBSCRIPTION_PLANS,
                payload: true
            })
            axios.post(config.apiUrl + `/v2/subscription-plans`, data)
                .then(res => {
                    dispatch({
                        type: _const.CREATING_SUBSCRIPTION_PLANS,
                        payload: false
                    });
                    dispatch(toggleModal(false));
                    Swal.fire({
                        title: "Subscription Created!",
                        type: "success",
                    });
                    dispatch(allsubscriptionplans(1, 30))
                })
                .catch(err => {
                    dispatch({
                        type: _const.CREATING_SUBSCRIPTION_PLANS,
                        payload: false
                    })
                    dispatch({
                        type: _const.CREATING_SUBSCRIPTION_ERROR,
                        payload: err.response.data.message
                    })
                })
        }
    }
}

export const editsubscriptionplans = (id, data) => {
    return (dispatch) => {
        dispatch({
            type: _const.EDITING_SUBSCRIPTION_PLANS,
            payload: true
        })
        axios.put(config.apiUrl + `/v2/subscription-plans/${id}`, data)
            .then(res => {
                dispatch({
                    type: _const.CREATING_SUBSCRIPTION_ERROR,
                    payload: ""
                })
                dispatch({
                    type: _const.EDITING_SUBSCRIPTION_PLANS,
                    payload: false
                });
                dispatch(toggleModal(false));
                Swal.fire({
                    title: "Subscription Edited!",
                    type: "success",
                });
                dispatch(allsubscriptionplans(1, 30))
            })
            .catch(err => {
                dispatch({
                    type: _const.EDITING_SUBSCRIPTION_PLANS,
                    payload: false
                })
                dispatch({
                    type: _const.CREATING_SUBSCRIPTION_ERROR,
                    payload: err.response.data.message
                })
            })
    }
}

export const getsubscriptionplansbylocality = () => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_SUBSCRIPTION_BY_LOCALITY,
            payload: true
        })
        axios.get(config.apiUrl + `/v2/subscription-plans/locality`)
            .then(res => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_BY_LOCALITY,
                    payload: false
                })
                dispatch({
                    type: _const.ALL_SUBSCRIPTION_BY_LOCALITY,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_BY_LOCALITY,
                    payload: false
                })
            })
    }
}
export const usersubscription = (recurring, planId, planType) => {
    return (dispatch) => {
        dispatch({
            type: _const.UPDATING_SUBSCRIPTION,
            payload: true
        })
        dispatch({
            type: _const.PLAN_TYPE,
            payload: planType,
            planId
        })
        axios.post(config.apiUrl + `/v2/subscriptions?recurring=${recurring}&planId=${planId}&planType=${planType}`)
            .then(res => {
                
                dispatch({
                    type: _const.UPDATING_SUBSCRIPTION,
                    payload: false
                })
                dispatch({
                    type: _const.PLAN_TYPE,
                    payload: "",
                    planId: ""
                });
                dispatch(getsubscriptionplansbylocality())
                Swal.fire({
                    text: "Subscription Successful!",
                    type: "success",
                });

            })
            .catch(err => {
                dispatch({
                    type: _const.PLAN_TYPE,
                    payload: ""
                })
                dispatch({
                    type: _const.UPDATING_SUBSCRIPTION,
                    payload: false,
                    planId: ""
                })
                Swal.fire({
                    text: err.response.data.message,
                    type: "error",
                  });
            })
    }
}

export const currentsubscriptionplan = () => {
    return (dispatch) => {
        axios.get(config.apiUrl + `/v2/subscriptions/current`)
            .then(res => {
                dispatch({
                    type: _const.CURRENT_SUBSCRIPTION_PLAN,
                    payload: res.data.data
                })
            })
            .catch(err => {
               
            })
    }
}

export const usersubscriptionhistory = (page, pagesize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_SUBSCRIPTION_HISTORY,
            payload: true
        })
        axios.get(config.apiUrl + `/v2/subscriptions/me?page=${page}&pageSize=${pagesize}`)
            .then(res => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_HISTORY,
                    payload: false
                })
                dispatch({
                    type: _const.ALL_SUBSCRIPTION_HISTORY,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: _const.GETTING_SUBSCRIPTION_HISTORY,
                    payload: false
                })
            })
    }
}

