import React, { useEffect} from "react";
import { StakeStatData, StakeStatValue} from "../UserDetails/styles";
import moment from "moment";
import {formatBillingrate} from "../../Helper";
import { useDispatch, useSelector } from "react-redux";
import {getCountryCurrency, getDeviceDetails, handleSubNav} from "../../Redux/actions";
import { Link } from "react-router-dom";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "./styles";
import CustomLoader from "../Reusables/loaders/CustomLoader";


const DeviceDetails = () => {
  const { currencyValue, currencyCode } = useSelector((state) => state.User);
  const {deviceDetails} = useSelector((state)=> state.Admin);
  const { gettingDeviceDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();

  const deviceId = window.location.pathname.split("/").at(-1);

  useEffect(() => {
    dispatch(getDeviceDetails(deviceId))
    getCountryCurrency();
  }, [deviceId]);

  return (
    <div className="col-lg-12">
     { gettingDeviceDetails ?
      <CustomLoader size="10" /> :
        <div className="card w3-animate-bottom">
          {deviceDetails && (
            <div className="card-body p-6 ">
              <div className="row mb-2">
              
                {deviceDetails.deviceOwner &&
                  <div className="col-lg-6 col-md-6">
                    <PersonalBox>
                      <UserDetailsAvatar
                        className="rounded-circle"
                        src="/Assets/images/avatars/user_icon.svg"
                        alt="User Avatar"
                      />
                      <PersonalInfo>
                        <span>{deviceDetails.deviceOwner && deviceDetails.deviceOwner.name ? deviceDetails.deviceOwner.name : "N/A"}</span>
                        <span>{deviceDetails.deviceOwner.email && deviceDetails.deviceOwner.email ? deviceDetails.deviceOwner.email : "N/A"}</span>
                      </PersonalInfo>
                      {deviceDetails.deviceOwner && deviceDetails.deviceOwner.id ? 
                        <Link to={`/user/${deviceDetails.deviceOwner.id}`} 
                        onClick={() => {dispatch(handleSubNav(1));}}
                        >
                          <div className="details min-width"> Details </div></Link> : <div className="details min-width"> Not Available </div>}
                    </PersonalBox>
                  </div>
                  }
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>BSSID</StakeStatData>
                  <StakeStatValue>{deviceDetails.bssid || "N/A"}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>SSID</StakeStatData>
                  <StakeStatValue>{deviceDetails.ssid || "N/A"}</StakeStatValue>
                </div>
              </div>
              <div
                className="row mt-2 pt-2"
                style={{ borderTop: "1px solid #E8E8E8" }}
              >
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Total Data Used</StakeStatData>
                  <StakeStatValue>{deviceDetails.totalDataUsed || 0}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Total Connection Count</StakeStatData>
                  <StakeStatValue>{deviceDetails.totalConnectionCount || 0}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Total Unique Connection Count</StakeStatData>
                  <StakeStatValue>{deviceDetails.totalUniqueConnectionCount || 0}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Total Device Uptime</StakeStatData>
                  {deviceDetails.totalDeviceUptime ? 
                    <StakeStatValue>
                      {deviceDetails.totalDeviceUptime}
                    </StakeStatValue> :  <StakeStatValue> N/A  </StakeStatValue>}
                </div>
              </div>
              <div
                className="row mt-2 pt-2"
                style={{ borderTop: "1px solid #E8E8E8" }}
              >
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Earnings</StakeStatData>
                  <StakeStatValue>
                    {deviceDetails.totalMiningRewardEarned || "N/A"} WNT
                  </StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Billing Rate</StakeStatData>
                  {deviceDetails.billingRate ? <StakeStatValue>
                    {formatBillingrate(
                      deviceDetails.billingRate,
                      currencyValue,
                      currencyCode
                    )}
                  </StakeStatValue> : 0}
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Last Traffic Update Date</StakeStatData>
                  <StakeStatValue>
                  {deviceDetails.lastTrafficUpdateDate ? `${moment(deviceDetails.lastTrafficUpdateDate).format(
                      "DD-MM-YYYY"
                    )} | ${moment(deviceDetails.lastTrafficUpdateDate).format("LT")}` : "N/A"}
                  </StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device ID</StakeStatData>
                  <StakeStatValue>
                    {deviceDetails.id || ""}
                  </StakeStatValue>
                </div>
                
              </div>
              <div
                className="row mt-2 pt-2"
                style={{ borderTop: "1px solid #E8E8E8" }}
              >
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Model</StakeStatData>
                  <StakeStatValue>{deviceDetails.deviceModel || "N/A"}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Mode</StakeStatData>
                  <StakeStatValue>{deviceDetails.deviceMode || "N/A"}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Mode Update TimeStamp</StakeStatData>
                  <StakeStatValue>
                      {/* {moment(deviceDetails.deviceModeUpdateTimeStamp).format( "DD-MM-YYYY")}{" "}|{" "}{moment(deviceDetails.deviceModeUpdateTimeStamp).format("LT")} */}
                      {deviceDetails.deviceModeUpdateTimeStamp ? `${moment(deviceDetails.deviceModeUpdateTimeStamp).format("DD-MM-YYYY")} | ${moment(deviceDetails.deviceModeUpdateTimeStamp).format("LT")}` : "N/A"}
                  </StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Architecture</StakeStatData>
                  <StakeStatValue>
                    {deviceDetails.deviceArchitecture || "N/A"}
                  </StakeStatValue>
                </div>
                
              </div>
              <div
                className="row mt-2 pt-2"
                style={{ borderTop: "1px solid #E8E8E8" }}
              >
                
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Device Tier</StakeStatData>
                  <StakeStatValue>{deviceDetails.deviceTier || "N/A"}</StakeStatValue>
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>Status</StakeStatData>
                  {deviceDetails.status ? <StakeStatValue 
                    className={`${deviceDetails.status === "Online" || deviceDetails.status === "ACTIVE" ? "online" : deviceDetails.status === "Offline" ? "offline" : "blocked"}`}>
                      {deviceDetails.status}
                  </StakeStatValue> : "N/A"}
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>State</StakeStatData>
                  {deviceDetails.state ? <StakeStatValue className={`${deviceDetails.state === "Online" ? "online" : deviceDetails.state === "Offline" ? "offline" : "blocked"}`}>{deviceDetails.state}</StakeStatValue> : "N/A"}
                </div>
                <div className="col-lg-3 col-md-6">
                  <StakeStatData>NFT Enabled</StakeStatData>
                  <StakeStatValue>
                    {deviceDetails.isBackedByNft ? "True" : "False"}
                  </StakeStatValue>
                </div>
              </div>
              <hr />
              <div
                className="d-flex"
              >
                <div className="col-lg-3 col-md-6 d-flex">
                  <div style={{ display: "flex", position: "relative" }}>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default DeviceDetails;
