import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

//Get fraud list 
export const getFraudList = (page, pageSize, searchParam, status, operation) => {
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_FRAUD_LIST,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/fraudlist?PageSize=${pageSize}&PageNumber=${page}&SearchParam=${searchParam}&Status=${status}&Operation=${operation}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_FRAUD_LIST,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.FRAUD_LIST,
              payload: res.data.data,
            });
          }
       
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_FRAUD_LIST,
            payload: false,
          });
      });
  };
};

//Get user fraud report
export const getUserFraudReport = (userId) => {
    return (dispatch) => {
      dispatch({
          type: _const.GETTING_FRAUD_REPORT,
          payload: true,
        });
      axios
        .get(config.adminApiUrl + `/v1/fraudlist/user-fraud-report/${userId}`)
        .then((res) => {
          dispatch({
              type: _const.GETTING_FRAUD_REPORT,
              payload: false,
            });
            if(res.data.isSuccessful){
              dispatch({
                type: _const.FRAUD_REPOPRT,
                payload: res.data.data,
              });
            }
        })
        .catch((err) => {
          dispatch({
              type: _const.GETTING_FRAUD_REPORT,
              payload: false,
            });
        });
    };
};
  
//Remove from fraud list
export const removeFromList = (fraudListId) => {
  return (dispatch) => {
    dispatch({
        type: _const.REMOVING_FROM_LIST,
        payload: true,
      });
    axios
      .put(config.apiUrl + `/v2/fraudlist/release-user/${fraudListId}`)
      .then((res) => {
        dispatch({
            type: _const.REMOVING_FROM_LIST,
            payload: false,
          });
          console.log(res.data, 'i am the res')
          if(res.data.data.isSuccessful){
            dispatch(getFraudList(1, 30, "", "", ""))
            Swal.fire({
              title: "User removed from fraud list successfully!",
              type: "success",
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.REMOVING_FROM_LIST,
            payload: false,
          });
        if(err.response){
          Swal.fire({
            title: "Somthing went wrong",
            type: err.response.data ? err.response.data.message : "Something went wrong",
          });
        }
      });

  };
};