import React from "react";
import { PinBox } from "./inputstyles";

const PinBoxes = (props) => {
  const { name, onChange, keyUp } = props;
  return (
    <PinBox
      name={name}
      onChange={onChange}
      type="password"
      onKeyUp={keyUp}
      maxLength={1}
    />
  );
};

export default PinBoxes;
