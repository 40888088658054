import React, { Component } from "react";
import { getFormDetails, createDynamicForm } from "../../Redux/actions";
import { connect } from "react-redux";
import PreviewForm from "./previewform";
import AllForms from "./allforms";
import Newform from "./newform";

class CreateDynamicForm extends Component {
  render() {
    const { createnewform } = this.props;
    return (
      <div>
        {!createnewform ? (
          <div className="row">
            <AllForms />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="card-header mb-3" style={{ width: "100%" }}>
                <div className="d-flex">
                  <h6 className="m-0">
                    <i
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        this.props.getFormDetails({
                          props: ["createnewform"],
                          value: false,
                        })
                      }
                    >
                      {" "}
                      Back
                    </i>
                  </h6>
                </div>
              </div>
            </div>
            <Newform />
            <PreviewForm />
          </div>
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { createnewform } = state.User;
  return {
    createnewform,
  };
};

export default connect(
  MapStateToProps,
  { getFormDetails, createDynamicForm }
)(CreateDynamicForm);
