import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import {
  getCountries,
  toggleModal,
  editCountry,
  getFormDetails
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { pagination } from "../../Helper";

class CountriesList extends Component {
  componentWillMount() {
    this.props.getCountries(1, 30, '');
  }

  componentWillReceiveProps(nextProps) {
    const {
      searchCountry
    } = nextProps;
    if (
      searchCountry !== this.props.searchCountry
    ) {
      this.props.getCountries(1, 30, searchCountry);
    }
  } 


  customPagination() {
    const { countriesTotalPages, countriesCurrentPage } = this.props;
    var pageButtons = pagination(countriesCurrentPage, countriesTotalPages, 5);
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() => this.props.getCountries(countriesCurrentPage - 1, 30)}
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === countriesCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.getCountries(pageButtons[i], 30)}
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() => this.props.getCountries(countriesCurrentPage + 1, 30)}
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      allcountries,
      editingCountries,
      gettingCountries,
      clickedDeviceId,
      baseconversionrate,
      basehourlyrate,
      errorEditingCountry,
      searchCountry
    } = this.props;

    let allRows = [];
    allcountries.map(country => {
      return allRows.push({
        name: country.name,
        conversionrate: country.baseConversionRate,
        currencyCode: country.isoThreeLetterCurrencyCode,
        hourlyadvertrate: country.advertHourlyRate + "WC",
        basehourlyadvertrate: country.baseAdvertHourlyRate + "WC",
        editCountry: (
          <button
            className="details"
            onClick={() => this.props.toggleModal(true, country.id, "edit")}
          >
            <i className="fa fa-edit" aria-hidden="true" />
            Edit{" "}
          </button>
        )
      });
    });

    const data = {
      columns: [
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Conversion Rate",
          field: "conversionrate"
        },
        {
          label: "Currency Code",
          field: "currencyCode"
        },
        {
          label: "Hourly Advert Rate",
          field: "hourlyadvertrate"
        },
        {
          label: "Base Hourly Rate",
          field: "basehourlyadvertrate"
        },
        {
          label: "Edit",
          field: "editCountry"
        }
      ],

      rows: allRows
    };
    let country = allcountries.find(country => country.id === clickedDeviceId);
    let editdata = {};
    country !== undefined
      ? (editdata = {
          name: country.name,
          isoTwoLetterCode: country.isoTwoLetterCode,
          isoThreeLetterCurrencyCode: country.isoThreeLetterCurrencyCode,
          baseConversionRate:
            baseconversionrate === ""
              ? country.baseConversionRate
              : baseconversionrate,
          baseAdvertHourlyRate:
            basehourlyrate === ""
              ? country.baseHourlyAdvertRate
              : basehourlyrate,
          entityStatus: "ACTIVE"
        })
      : (editdata = {});
    return (
      <div className="">
        <Modal heading={"Edit Country"}>
          <label> Base Conversion Rate</label>
          <div className="input-group mb-2">
            <input
              type="phone"
              className="form-control loginCred"
              aria-describedby="basic-addon1"
              // value={baseconversionrate}
              defaultValue={
                country !== undefined ? country.baseConversionRate : ""
              }
              onChange={e =>
                this.props.getFormDetails({
                  props: ["baseconversionrate"],
                  value: e.target.value
                })
              }
            />
          </div>
          <label> Base Hourly Rate</label>
          <div className="input-group mb-2">
            <input
              type="phone"
              className="form-control loginCred"
              aria-describedby="basic-addon1"
              // value={basehourlyrate}
              defaultValue={
                country === undefined ? "" : country.baseHourlyAdvertRate
              }
              onChange={e =>
                this.props.getFormDetails({
                  props: ["basehourlyrate"],
                  value: e.target.value
                })
              }
            />
          </div>
          {errorEditingCountry !== "" ? (
            <p className="errorMsgs">
              <i className="material-icons">info</i>
              {errorEditingCountry}
            </p>
          ) : (
            ""
          )}
          <Button
            theme="wicryptColor ml-3 mt-2 float"
            buttonAction={() => this.props.editCountry(country.id, editdata)}
          >
            {editingCountries ? <Loader /> : "Enter"}
          </Button>
        </Modal>
        {gettingCountries && allcountries.length === 0 ? (
          <div className="col-lg-12 ">
            <div className=" t-center mt-4">
              <CustomLoader />
            </div>
          </div>
        ) : (
          <CustomDataTable
            data={data}
            pagination={this.customPagination()}
            placeholder={"Search country"}
            searchfunction={(e) => this.props.getFormDetails({ props: ['searchCountry'], value: e.target.value })}
            searchvalue={searchCountry}
          />
        )}
      </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    voucherRecepientPhone,
    voucherAmt,
    voucherstartDate,
    voucherendDate,
    voucherStatus,
    voucherReceipientEmail,
    voucherValidity,
    voucherMsg,
    businessProfile,
    days,
    clickedDeviceId,
    baseconversionrate,
    basehourlyrate,
    searchCountry
  } = state.User;
  const { editingCountries, gettingCountries } = state.Loading;
  const { voucherError, savedBusinessId } = state.Voucher;
  const {
    allcountries,
    countriesCurrentPage,
    countriesTotalPages,
    errorEditingCountry,
    countriesTotalRecords
  } = state.Countries;
  return {
    allcountries,
    voucherReceipientEmail,
    voucherRecepientPhone,
    voucherAmt,
    voucherStatus,
    editingCountries,
    gettingCountries,
    voucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    countriesCurrentPage,
    countriesTotalPages,
    countriesTotalRecords,
    clickedDeviceId,
    voucherstartDate,
    voucherendDate,
    days,
    baseconversionrate,
    basehourlyrate,
    errorEditingCountry,
    searchCountry
  };
};

export default connect(
  MapStateToProps,
  {
    getCountries,
    toggleModal,
    editCountry,
    getFormDetails
  }
)(CountriesList);
