import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { businessReport, getFormDetails } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import {
  numberWithCommas,
  numberWithCommaswithoutdecimals,
} from "../../Helper";
import {
  ServiceHeading,
  HeadingDescription,
  BeneficiaryDescription,
  BeneficiaryValue,
  BigSummaryValue,
} from "./summarystyles";

class Summary extends Component {
  componentDidMount() {
    const { businessId } = this.props;
    this.props.businessReport(businessId);
  }

  render() {
    const {
      bizsummaryReport,
      gettingBusinessReport,
      conversionsRate,
    } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            {gettingBusinessReport ? (
              <div className="volucherNote mt-5">
                <Loader size="10" />{" "}
              </div>
            ) : (
              <div className="mt-5 offset-lg-2 col-lg-7 col-md-6 col-sm-12 mb-4">
                <div className="card card-small h-100 p-5">
                  <div className="card-header border-bottom">
                    <ServiceHeading>
                      {bizsummaryReport.businessDetails !== undefined
                        ? bizsummaryReport.businessDetails.name
                        : ""}
                    </ServiceHeading>
                    <HeadingDescription> Business Summary </HeadingDescription>
                  </div>

                  <div className="card-body row py-0 jc">
                    <div className="col-lg-4">
                      <BeneficiaryDescription>
                        {" "}
                        Total Sales Amount
                      </BeneficiaryDescription>{" "}
                      <BeneficiaryValue>
                        {" "}
                        {"NGN " +
                          numberWithCommas(
                            bizsummaryReport.sales * conversionsRate
                          )}
                      </BeneficiaryValue>{" "}
                    </div>
                    <div className="col-lg-4"> </div>
                    <div className="col-lg-4">
                      <BeneficiaryDescription>
                        {" "}
                        Total Service Charge
                      </BeneficiaryDescription>{" "}
                      <BeneficiaryValue color={"yellow"}>
                        {" "}
                        {"NGN " +
                          numberWithCommas(
                            bizsummaryReport.serviceCharge * conversionsRate
                          )}
                      </BeneficiaryValue>{" "}
                    </div>
                  </div>

                  <div className="card-body row py-0 jc border-bottom">
                    <div className="col-lg-4">
                      <BeneficiaryDescription>
                        {" "}
                        Business Balance
                      </BeneficiaryDescription>{" "}
                      <BeneficiaryValue>
                        {" "}
                        {"NGN " +
                          numberWithCommas(
                            bizsummaryReport.balance * conversionsRate
                          )}
                      </BeneficiaryValue>{" "}
                    </div>
                    <div className="col-lg-4"> </div>
                    <div className="col-lg-4">
                      <BeneficiaryDescription>
                        {" "}
                        Total Wicrypt Commission
                      </BeneficiaryDescription>{" "}
                      <BeneficiaryValue color={"yellow"}>
                        {" "}
                        {"NGN " +
                          numberWithCommas(
                            bizsummaryReport.wicryptCommission * conversionsRate
                          )}
                      </BeneficiaryValue>{" "}
                    </div>
                  </div>
                  <div className="card-body row py-0 jc">
                    <div className="col-lg-4">
                      {" "}
                      <BeneficiaryDescription>
                        Agents
                      </BeneficiaryDescription>{" "}
                      <BigSummaryValue>
                        {bizsummaryReport.agents}
                      </BigSummaryValue>{" "}
                    </div>
                    <div className="col-lg-4"> </div>
                    <div className="col-lg-4">
                      <BeneficiaryDescription>
                        {" "}
                        Sub business
                      </BeneficiaryDescription>{" "}
                      <BigSummaryValue>
                        {" "}
                        {bizsummaryReport.subBusinesses !== undefined
                          ? bizsummaryReport.subBusinesses
                          : ""}
                      </BigSummaryValue>{" "}
                    </div>
                  </div>
                  <div className="card-body row py-0 jc">
                    <div className="col-lg-4">
                      <BeneficiaryDescription> Devices</BeneficiaryDescription>{" "}
                      <BigSummaryValue>
                        {" "}
                        {bizsummaryReport.devices}
                      </BigSummaryValue>{" "}
                    </div>
                    <div className="col-lg-4"> </div>
                    <div className="col-lg-4">
                      <BeneficiaryDescription>Sessions</BeneficiaryDescription>{" "}
                      <BigSummaryValue>
                        {numberWithCommaswithoutdecimals(
                          bizsummaryReport.sessions
                        )}
                      </BigSummaryValue>{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { gettingBusinessReport } = state.Loading;
  const { bizsummaryReport, conversionsRate } = state.Report;
  return {
    gettingBusinessReport,
    bizsummaryReport,
    conversionsRate,
  };
};

export default connect(MapStateToProps, { businessReport, getFormDetails })(
  Summary
);
