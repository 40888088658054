import React from "react";
import { Link } from "react-router-dom";
import {
  Icon,
  ArrowCard,
  CardTitle,
  CardValue,
  ValueFlex,
  CardIcon,
  IconContainer,
  DescriptionCard,
  CardDescription,
} from "./cards";

const IconCards = (props) => {
  const {
    title,
    value,
    link,
    image,
    description,
    toggledescription,
    id,
    selectedid,
    bordered,
  } = props;
  return (
    <div className="col-lg-4 col-6 col-md-6 col-sm-6 p-1 w3-animate-top">
      <ArrowCard bordered={bordered}>
        <ValueFlex className="pb-0">
          <ValueFlex className="pb-0">
            <IconContainer>
              <CardIcon src={image} alt={title} />
            </IconContainer>
            <div>
              <CardTitle> {title}</CardTitle>
              <CardValue> {value}</CardValue>
            </div>
          </ValueFlex>
          {link !== "#" && (
            <ValueFlex>
              <Link
                to={link || "#"}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Icon
                  className="material-icons"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  chevron_right
                </Icon>
              </Link>
            </ValueFlex>
          )}
          {!description ? (
            ""
          ) : (
            <Icon
              onClick={toggledescription}
              color={selectedid === id ? "yellow" : "grey"}
              className="material-icons"
            >
              help_outline
            </Icon>
          )}
          {selectedid === id ? (
            <DescriptionCard>
              <CardDescription> {description}</CardDescription>
            </DescriptionCard>
          ) : (
            ""
          )}
        </ValueFlex>
      </ArrowCard>
    </div>
  );
};

export default IconCards;
