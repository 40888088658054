import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toggleModal } from "./GeneralAction";

//Get all verification requests
export const getallVerificationRequests = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_REQUESTS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/users/pending-verification-requests`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_REQUESTS,
        payload: false,
      });
      dispatch({
        type: _const.GET_VERIFICATION_REQUESTS,
        payload: res.data.data.records,
        totalrecords: res.data.data.totalRecords,
        currentPage: res.data.data.currentPage,
        totalPages: res.data.data.totalPages,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_REQUESTS,
        payload: false,
      });
    });
};

//Approve verification request
export const approveVerification = (data, status, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: _const.APPROVING_VERFIFICATION,
      payload: true,
    });
    axios
      .post(
        config.apiUrl + `/v2/accountVerification/VerificationRequest/Approve`,
        data
      )
      .then((res) => {
        dispatch({
          type: _const.APPROVING_VERFIFICATION,
          payload: false,
        });
        dispatch({
          type: _const.TAKE_USER_BACK,
          payload: false,
        });
        dispatch(toggleModal(false));
        // dispatch(
        //   getallVerificationRequests(status, startDate, endDate, 1, 50, "")
        // );
        Swal.fire({
          text: "Verification Successful!",
          type: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.APPROVING_VERFIFICATION,
          payload: false,
        });
      });
  };
};

//Decline verification requests
export const declineVerification = (data, status, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: _const.DECLINING_VERFIFICATION,
      payload: true,
    });
    axios
      .post(
        config.apiUrl + `/v2/accountVerification/VerificationRequest/Reject`,
        data
      )
      .then((res) => {
        dispatch({
          type: _const.TAKE_USER_BACK,
          payload: false,
        });
        dispatch(toggleModal(false));
        // dispatch(
        //   getallVerificationRequests(status, startDate, endDate, 1, 50, "")
        // );
        dispatch({
          type: _const.DECLINING_VERFIFICATION,
          payload: false,
        });
        Swal.fire({
          text: "Verification declined successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.DECLINING_VERFIFICATION,
          payload: false,
        });
      });
  };
};

//Get one verification request
export const oneRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: _const.ONE_VERIFICATION_REQUEST,
      payload: id,
    });
  };
};

//Take a user back to previous page
export const goBack = () => {
  return (dispatch) => {
    dispatch({
      type: _const.TAKE_USER_BACK,
      payload: false,
    });
  };
};

//Get all country's verification id type

export const countryVerificationTypes = (countryCode) => {
  return (dispatch) => {
    axios
      .get(
        config.apiUrl +
        `/v2/accountVerification/smileIdType?searchParam=${countryCode}`
      )
      .then((res) => {
        dispatch({
          type: _const.VERIFICATION_TYPES,
          payload: res.data.data,
        });
      })
      .catch((err) => { });
  };
};

//Veirfy Identity Card
export const verifyIdCard = (IdType, RequestToVerifyAccountId, IdNumber) => {
  let data = {
    IdType: IdType,
    RequestToVerifyAccountId: RequestToVerifyAccountId,
    IdNumber: IdNumber,
  };
  return (dispatch) => {
    if (IdType === "") {
      dispatch({
        type: _const.ID_VERIFICATION_FAIL,
        payload: 1,
      });
    } else if (IdNumber === "") {
      dispatch({
        type: _const.ID_VERIFICATION_FAIL,
        payload: 2,
      });
    } else {
      dispatch({
        type: _const.VERIFYING_ID_CARD,
        payload: true,
      });
      axios
        .post(
          config.apiUrl + `/v2/accountVerification/ValidateIdentityCard`,
          data
        )
        .then((res) => {
          dispatch({
            type: _const.VERIFYING_ID_CARD,
            payload: false,
          });
          dispatch({
            type: _const.ID_VERIFICATION_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.VERIFYING_ID_CARD,
            payload: false,
          });
          dispatch({
            type: _const.ID_VERIFICATION_FAIL,
            payload: err.response.data.message,
          });
        });
    }
  };
};

//Get verification request summary
export const getVerificationSummary = (startTime, endTime, duration) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_VERIFICATION_SUMMARY,
      payload: true,
    });

    axios
      .get(config.adminApiUrl + `/v1/KYCVerification/summary?startDateTimestamp=${startTime}&EndDateTimestamp=${endTime}&Duration=${duration}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_VERIFICATION_SUMMARY,
          payload: false,
        });
        dispatch({
          type: _const.VERIFICATION_SUMMARY,
          payload: res.data.data
        })
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_VERIFICATION_SUMMARY,
          payload: false,
        });
      });
  }
};

//Get all verification requests
export const getAllKYCs = (startTime, endTime, duration, status, search, page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ALL_KYC_REQUESTS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/KYCVerification?Search=${search}&VerificationRequestStatus=${status}&StartDateTimeStamp=${startTime}&EndDateTimeStamp=${endTime}&Duration=${duration}&Page=${page}&PageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_ALL_KYC_REQUESTS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_KYC_REQUESTS,
          payload: res.data.data.records,
          totalrecords: res.data.data.totalRecords,
          currentPage: res.data.data.currentPage,
          totalPages: res.data.data.totalPages,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ALL_KYC_REQUESTS,
          payload: false,
        });
      });
  }
};