import React, { Component } from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import { connect } from 'react-redux'
import Summary from './summary'
import { Link } from 'react-router-dom'
import { MainContent } from '../Partials/partials'



class BusinessSummary extends Component {
  render() {

    return (
      <div className="row">
        <Sidenav />
        
        <MainContent className="main-content">
        <Mainnav pageTitle={<Link to="/businesses"> <i className="material-icons">arrow_back</i> Business Report </Link>} />
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <Summary businessId={this.props.businessId} />
          </div>

        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state, props) => {
  const businessId = props.match.params.businessId;
  return {
    businessId
  }
}
export default connect(MapstateToProps)(BusinessSummary)