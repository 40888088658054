import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import AdminRemittance from './AdminRemittance'


class Agent extends Component{
    render() {
     
      return (
         <div className="row">
         <Sidenav/>
         
             <main className="main-content col-lg-9 col-md-9 col-sm-12 p-0 offset-lg-3 offset-md-3">
                 <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Remittance Report"/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4 mt-5">
                    <AdminRemittance/>            
                 </div>
             </main>
             
        </div>
      );
    }
  }

  const MapstateToProps = (state) =>{
   
    return{
      state
    }
  }
export default connect(MapstateToProps)( Agent)