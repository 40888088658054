import React, { Component } from "react";
import { connect } from "react-redux";
import { voucherBalance, getFormDetails } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { numberWithCommas, precise_round } from "../../Helper";
import Button from "../Reusables/buttons/Button";

class VoucherBalance extends Component {
  render() {
    const {
      gettingVoucherBalance,
      totalvoucherBalance,
      voucherbalancePhoneNumber,
      voucherCode,
      balanceErr,
    } = this.props;
    return (
      <div className=" col-lg-4">
        <div className="card card-small card-post card-post--1">
          <div className="card-body">
            <h5 className="card-title mb-0">
              <div to="" className="text-fiord-blue vouchercardHeading ">
                Voucher Balance
              </div>
            </h5>
            <p className="wicryptmsg mb-5"> Check your voucher balance</p>
            <img className="sideWave" src="/Assets/sideWave.png" alt="" />
            <label>
              {" "}
              Enter Phone Number <span className="text-red"> * Required</span>
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control loginCred"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={voucherbalancePhoneNumber}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherbalancePhoneNumber"],
                    value: e.target.value,
                  })
                }
              />
            </div>
            <label> Enter Voucher Code</label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control loginCred"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={voucherCode}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherCode"],
                    value: e.target.value,
                  })
                }
              />
            </div>
            <span className="text-red t-12 mb-3">
              {" "}
              {balanceErr !== "" ? "*" + balanceErr : ""}
            </span>
            <div className="loginBtn">
              <Button
                buttonAction={() =>
                  this.props.voucherBalance(
                    voucherbalancePhoneNumber,
                    voucherCode
                  )
                }
                theme="btn-primary wicryptColor clickLogin"
              >
                {gettingVoucherBalance ? <Loader /> : "Enter"}
              </Button>
            </div>
          </div>
          {totalvoucherBalance.voucher_count === undefined ? (
            ""
          ) : (
              <div className="p-20">
                <div className="row">
                  <div className="t-center col-lg-4 col-sm-12">
                    <p className="mb-0 t2 bold">
                      {" "}
                      {totalvoucherBalance.voucher_count}
                    </p>
                    <p className="t-12"> Voucher Count</p>
                  </div>
                  <div className="t-center col-lg-4 col-sm-12">
                    <p className="mb-0 t2 bold">
                      {precise_round(totalvoucherBalance.balance, 3) + " WC "}
                    </p>
                    <p className="t-12"> Amount in WC</p>
                  </div>
                  <div className="t-center col-lg-4 col-sm-12">
                    <p className="mb-0 t2 bold">
                      {" NGN " +
                        numberWithCommas(totalvoucherBalance.balance_in_naira)}
                    </p>
                    <p className="t-12"> Amount in NGN</p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { gettingVoucherBalance } = state.Loading;
  const { totalvoucherBalance, checkedVoucher, balanceErr } = state.Voucher;
  const { voucherbalancePhoneNumber, voucherCode } = state.User;
  return {
    gettingVoucherBalance,
    totalvoucherBalance,
    voucherbalancePhoneNumber,
    voucherCode,
    checkedVoucher,
    balanceErr,
  };
};

export default connect(
  MapStateToProps,
  { voucherBalance, getFormDetails }
)(VoucherBalance);
