import React from "react";
import moment from "moment";
import { numberWithCommas, precise_round } from "../../Helper";
import { SummaryTitle, SummaryValue } from "./sessionstyles";

const ViewMore = (props) => {
  const { selectedsummary, conversionsRate } = props;
  return (
    <div className="row">
      <div className="col-lg-12 mt-2">
        <div className="card card-small mb-4 w3-animate-bottom">
          <div className="p-5">
            <div className="row">
              <div className="col-lg-3">
                <SummaryTitle> Host</SummaryTitle>
                <SummaryValue>{selectedsummary.host.userName}</SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Client</SummaryTitle>
                <SummaryValue>{selectedsummary.client.userName}</SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Agent</SummaryTitle>
                <SummaryValue>{selectedsummary.agent.userName}</SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Device</SummaryTitle>
                <SummaryValue>
                  {selectedsummary.sessionDevice.deviceName}
                </SummaryValue>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <SummaryTitle>Amount Received by Host</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.amountReceivedByHost * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Amount Spent by Client</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.amountSpentByClient * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Voucher's Opening Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.client.voucherOpeningBalance *
                        conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Average Data Usage Per Minute</SummaryTitle>
                <SummaryValue>
                  {precise_round(
                    selectedsummary.averageDataUsagePerMinute / 1048576,
                    2
                  ) + "MB"}
                </SummaryValue>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <SummaryTitle>Host's Opening Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.host.openingBalance * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Client's Opening Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.client.openingBalance * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Voucher's Closing Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.client.voucherClosingBalance *
                        conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Session Created At</SummaryTitle>
                <SummaryValue>
                  {moment
                    .parseZone(selectedsummary.sessionCreationTmeStamp)
                    .format("MMMM Do YYYY, h:mm a")}
                </SummaryValue>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <SummaryTitle>Host's Closing Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.host.closingBalance * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Client's Closing Balance</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.client.closingBalance * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Referral Amount</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.referralAmount * conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Session Last Updated At</SummaryTitle>
                <SummaryValue>
                  {moment
                    .parseZone(selectedsummary.sessionLastUpdateTimeStamp)
                    .format("MMMM Do YYYY, h:mm a")}
                </SummaryValue>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <SummaryTitle>Rate</SummaryTitle>
                <SummaryValue>
                  {numberWithCommas(
                    selectedsummary.sessionDevice.rate * 1048576 * 360
                  ) + " NGN/GB "}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Data Used in Bytes</SummaryTitle>
                <SummaryValue>
                  {precise_round(selectedsummary.dataUsedInBytes / 1048576, 2) +
                    "MB"}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Service Charge</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.serviceChargeToBeneficiary *
                        conversionsRate
                    )}
                </SummaryValue>
              </div>
              <div className="col-lg-3">
                <SummaryTitle>Wicrypt Comission</SummaryTitle>
                <SummaryValue>
                  {"NGN " +
                    numberWithCommas(
                      selectedsummary.wicryptCommision * conversionsRate
                    )}
                </SummaryValue>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMore;
