import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import { handleModal } from "./GeneralAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

//Get Remote Commands
export const getCommands = () => {
    return dispatch => {
      dispatch({
        type: _const.GETTING_COMMANDS,
        payload: true
      });
      axios
        .get(
          config.apiUrl +
            `/v2/device/remoteCommand`
        )
        .then(res => {
          if (res.data.success) {
            dispatch({
              type: _const.GETTING_COMMANDS,
              payload: false
            });
            dispatch({
              type: _const.ALL_COMMANDS,
              payload: res.data.data
            });
          }
        })
        .catch(err => {
          dispatch({
            type: _const.GETTING_COMMANDS,
            payload: false
          });
        });
    };
  };

//Create Remote Commands
export const createCommands = (data) => { 
  return dispatch => {
    if(!data.commandName){
      dispatch({
        type: _const.CREATING_COMMANDS_ERROR,
        payload: "Command name is required"
      });
    }else if(!data.commandCode){
      dispatch({
        type: _const.CREATING_COMMANDS_ERROR,
        payload: "Command code is required"
      });
    }else{
      dispatch({
        type: _const.CREATING_COMMANDS_ERROR,
        payload: ""
      });
      dispatch({
        type: _const.CREATING_COMMANDS,
        payload: true
      });
      axios
        .post(
          config.apiUrl +
            `/v2/device/remoteCommand`,
            data
        )
        .then(res => {
          dispatch({
            type: _const.CREATING_COMMANDS,
            payload: false
          });
          if (res.data.data.isSuccessful) {
            dispatch({
              type: _const.CREATING_COMMANDS_SUCCESSFUL,
              payload: res.data.data
            });
            dispatch(getCommands())
            dispatch(handleModal(0))
          }else{
            dispatch({
              type: _const.CREATING_COMMANDS_ERROR,
              payload: res.data.data.message
            });
          }
        })
        .catch(err => {
          dispatch({
            type: _const.CREATING_COMMANDS_ERROR,
            payload: err.response.data.message
          });
          dispatch({
            type: _const.CREATING_COMMANDS,
            payload: false
          });
        });
    }
    
  };
};

//Create Remote Commands
export const deleteCommand = (commandId) => {
  return dispatch => {
    dispatch({
      type: _const.DELETING_COMMAND,
      payload: true
    });
    axios
      .delete(
        config.apiUrl +
          `/v2/device/remoteCommand/${commandId}`,
      )
      .then(res => {
        dispatch({
          type: _const.DELETING_COMMAND,
          payload: false
        });
        if (res.data.data.isSuccessful) {
          
          dispatch({
            type: _const.DELETING_COMMAND_SUCCESSFUL,
            payload: res.data.data
          });
          dispatch(getCommands())
          dispatch(handleModal(0))
          Swal.fire({
            text: `You have Successfully deleted this command`,
            type: "success",
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${res.data.data.message}`,
          })
        }
      })
      .catch(err => {
        dispatch({
          type: _const.DELETING_COMMAND,
          payload: false
        });
      });
  };
};

//Get all devices that a command has been performed on 
export const getDevicesByCommand = (commandId, deviceName, hasPendingCommand, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_DEVICES_COMMANDS,
      payload: true
    });
    axios
      .get(
        config.adminApiUrl + `/v1/remote-commands/devices-info/${commandId}?DeviceName=${deviceName}&HasPendingCommand=${hasPendingCommand}&Page=${page}&PageSize=${pageSize}`
      )
      .then(res => {
        if (res.data) {
          dispatch({
            type: _const.GETTING_DEVICES_COMMANDS,
            payload: false
          });
          dispatch({
            type: _const.ALL_DEVICES_BY_COMMANDS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_DEVICES_COMMANDS,
          payload: false
        });
      });
  };
};

//Issue single mode remote command
export const singleModeCommand = (data) => {
  return dispatch => {
    dispatch({
      type: _const.CREATING_SINGLE_MODE_COMMAND,
      payload: true
    });
    axios
      .post(
        config.apiUrl +
          `/v2/device/remoteCommand/singleMode`,
          data
      )
      .then(res => {
        dispatch({
          type: _const.CREATING_SINGLE_MODE_COMMAND,
          payload: false
        });
        if (res.data.data.isSuccessful) {
         
          dispatch({
            type: _const.CREATING_SINGLE_MODE_COMMAND_SUCCESSFUL,
            payload: res.data.data
          });
          dispatch(getCommands());
          Swal.fire({
            text: `Command has been issued successfully!`,
            type: "success",
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${res.data.data.message}`,
          })
        }
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_SINGLE_MODE_COMMAND,
          payload: false
        });
      });
  };
};

//Issue single mode remote command
export const batchModeCommand = (commandId) => {
  return dispatch => {
    dispatch({
      type: _const.CREATING_BATCH_MODE_COMMAND,
      payload: true
    });
    axios
      .post(config.apiUrl + `/v2/device/remoteCommand/batchMode/${commandId}`)
      .then(res => {
        dispatch({
          type: _const.CREATING_BATCH_MODE_COMMAND,
          payload: false
        });
        if (res.data.data.isSuccessful) {
          dispatch({
            type: _const.CREATING_BATCH_MODE_COMMAND_SUCCESSFUL,
            payload: res.data.data
          });
          dispatch(getCommands());
          Swal.fire({
            text: `Command has been issued successfully!`,
            type: "success",
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${res.data.data.message}`,
          })
          
        }
      })
      .catch(err => {
        dispatch({
          type: _const.CREATING_BATCH_MODE_COMMAND,
          payload: false
        });
      });
  };
};