import React from "react";
import Search from "../tables/Search";

const CustomCardLoader = () => {

    let data = { rows: [1, 2, 3, 4, 5, 6, 7], columns: [1, 2, 3, 4, 5, 6, 7] }
    return (
        <div className="row">
            <div className="col-lg-12">
                <Search />
            </div>
            {data.rows.map((i) => {
                return (
                    <div key={i} className="col-lg-3 col-md-6 col-12 mb-3">
                        <div className="card">
                            <p className="table-label skeleton-image"></p>
                            <div className="card-footer border-top p-3">
                                <div className="row align-items-center no-gutters">
                                    <p className="mb-2 table-label skeleton-location"></p>
                                </div>
                                <div className="row align-items-center no-gutters">
                                    <p className="mb-2 table-label skeleton-location"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default CustomCardLoader