export const newTransferOptions = [
 
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "Status",
  //   type: "select",
  //   onSelectProp: "Status",
  //   options: [
  //     {
  //       id: 1,
  //       option: "All",
  //     },
  //     {
  //       id: 2,
  //       option: "Submitted",
  //     },
  //     {
  //       id: 3,
  //       option: "Cancelled",
  //     },
  //     {
  //       id: 4,
  //       option: "Acknowledged",
  //     },
  //     {
  //       id: 5,
  //       option: "FundedProvider",
  //     },
  //     {
  //       id: 6,
  //       option: "FundReceivedFromUser",
  //     },
  //     {
  //       id: 7,
  //       option: "FundedUser",
  //     },
  //     {
  //       id: 8,
  //       option: "Completed",
  //     },
  //     {
  //       id: 9,
  //       option: "OrderTimedOut",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: "Status",
    type: "select",
    onSelectProp: "Status",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Completed",
      },
      {
        id: 3,
        option: "Cancelled",
      },
      // {
      //   id: 4,
      //   option: "Pending",
      // },
    ],
  },

  {
    id: 4,
    title: "Reference Code",
    type: "text",
    placeholder: "Enter code here",
    onChangeProp: "ReferenceCode",
  },
  {
   id: 3,
   title: "Search",
   type: "textDropdown",
   placeholder: "Enter email here",
   onChangeProp: "Search",
 },
 
];

export const newp2pOptions = [
 
 {
   id: 1,
   title: "Order Type",
   onSelectProp: "TransactionType",
   type: "select",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Buy",
     },
     {
       id: 3,
       option: "Sell",
     },
     {
       id: 4,
       option: "Swap",
     }
   ],
 },
 {
   id: 2,
   title: "Duration",
   type: "select",
   onSelectProp: "duration",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Last Week",
     },
     {
       id: 3,
       option: "Last Month",
     },
     {
       id: 4,
       option: "Last 5 Months",
     },
     {
       id: 5,
       option: "Last Year",
     },
   ],
 },
 // {
 //   id: 3,
 //   title: "Status",
 //   type: "select",
 //   onSelectProp: "Status",
 //   options: [
 //     {
 //       id: 1,
 //       option: "All",
 //     },
 //     {
 //       id: 2,
 //       option: "Submitted",
 //     },
 //     {
 //       id: 3,
 //       option: "Cancelled",
 //     },
 //     {
 //       id: 4,
 //       option: "Acknowledged",
 //     },
 //     {
 //       id: 5,
 //       option: "FundedProvider",
 //     },
 //     {
 //       id: 6,
 //       option: "FundReceivedFromUser",
 //     },
 //     {
 //       id: 7,
 //       option: "FundedUser",
 //     },
 //     {
 //       id: 8,
 //       option: "Completed",
 //     },
 //     {
 //       id: 9,
 //       option: "OrderTimedOut",
 //     },
 //   ],
 // },
 {
   id: 3,
   title: "Status",
   type: "select",
   onSelectProp: "Status",
   options: [
     {
       id: 1,
       option: "All",
     },
     {
       id: 2,
       option: "Completed",
     },
     {
       id: 3,
       option: "Cancelled",
     },
     // {
     //   id: 4,
     //   option: "Pending",
     // },
   ],
 },

 {
   id: 4,
   title: "Reference Code",
   type: "text",
   placeholder: "Enter Code here",
   onChangeProp: "ReferenceCode",
 },
 {
   id: 5,
   title: "Search",
   type: "textDropdown",
   placeholder: "Enter email here",
   onChangeProp: "Search",
 },
];

export const newStakingOptions = [
 
 {
   id: 1,
   title: "Start Date",
   type: "startTime",
   onChangeProp: "startTime",
 },
 {
   id: 2,
   title: "End Date",
   type: "endTime",
   onChangeProp: "endTime",
 },
 {
   id: 3,
   title: "Search",
   type: "textDropdown",
   placeholder: "Enter email here",
   onChangeProp: "Search",
 },
];

export const disbursedStakingRewardOptions = [
 {
   id: 1,
   title: "Week",
   type: "text",
   placeholder: "Enter Week here",
   onChangeProp: "Week",
 },
 {
   id: 2,
   title: "Start Date",
   type: "startTime",
   onChangeProp: "startTime",
 },
 {
   id: 3,
   title: "End Date",
   type: "endTime",
   onChangeProp: "endTime",
 },
];

export const projectedUnstakeOptions = [
 {
   id: 1,
   title: "Duration",
   type: "select",
   onSelectProp: "duration",
   options: [
     {
       id: 1,
       option: "NO_LOCK",
     },
     {
       id: 2,
       option: "THREE_MONTHS",
     },
     {
       id: 3,
       option: "SIX_MONTHS",
     },
     {
       id: 4,
       option: "NINE_MONTHS",
     },
     {
       id: 5,
       option: "TWELVE_MONTHS",
     },
   ],
 },
 {
   id: 2,
   title: "Start Date",
   type: "startTime",
   onChangeProp: "startTime",
 },
 {
   id: 3,
   title: "End Date",
   type: "endTime",
   onChangeProp: "endTime",
 },
];

export const newDisbursedStakingWeekOptions = [
 {
   id: 1,
   title: "Search",
   type: "text",
   placeholder: "Enter Code here",
   onChangeProp: "Search",
 },
];

export const cryptoWithdrawalOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "LastWeek",
      },
      {
        id: 3,
        option: "LastMonth",
      },
      {
        id: 4,
        option: "Last3Months",
      },
      {
        id: 5,
        option: "LastYear",
      },
      {
        id: 6,
        option: "Custom",
      },
    ],
  },
  {
    id: 2,
    title: "Start Date",
    type: "customStartTime",
    onChangeProp: "startTime",
  },
  {
    id: 3,
    title: "End Date",
    type: "customEndTime",
    onChangeProp: "endTime",
  },
  {
    id: 4,
    title: "Transaction Hash",
    type: "text",
    placeholder: "Enter transaction hash here",
    onChangeProp: "TransactionHash",
  },
  {
    id: 5,
    title: "Destination Wallet Address",
    type: "text",
    placeholder: "Enter address here",
    onChangeProp: "DestinationWalletAddress",
  },
  {
    id: 6,
    title: "Withdrawal Reference",
    type: "text",
    placeholder: "Enter Code here",
    onChangeProp: "WithdrawalReference",
  },
  {
    id: 7,
    title: "Search",
    type: "textDropdown",
    placeholder: "Enter email here",
    onChangeProp: "Search",
  },
];

export const cryptoDepositOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "LastWeek",
      },
      {
        id: 3,
        option: "LastMonth",
      },
      {
        id: 4,
        option: "Last3Months",
      },
      {
        id: 5,
        option: "LastYear",
      },
      {
        id: 6,
        option: "Custom",
      },
    ],
  },
  {
    id: 2,
    title: "Start Date",
    type: "customStartTime",
    onChangeProp: "startTime",
  },
  {
    id: 3,
    title: "End Date",
    type: "customEndTime",
    onChangeProp: "endTime",
  },
  {
    id: 4,
    title: "Transaction Hash",
    type: "text",
    placeholder: "Enter transaction hash here",
    onChangeProp: "TransactionHash",
  },
  {
    id: 5,
    title: "Receiver Wallet Address",
    type: "text",
    placeholder: "Enter address here",
    onChangeProp: "ReceiverWalletAddress",
  },
  {
    id: 6,
    title: "Search",
    type: "textDropdown",
    placeholder: "Enter email here",
    onChangeProp: "Search",
  },
];
