import React from "react";
import Modal from "../Reusables/modals/Modal";

export const ViewDetail = ({image, title}) => {


    return (
      <div className="col-lg-12">
        <Modal heading={title}>
          <div className="card">
            <img
              className="card-img-top rounded"
              src={image}
              alt={title}
            />
          </div>
        </Modal>
       
      </div>
    );
}


