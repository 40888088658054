import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import SpendControlData from "./SpendControlData";
import CreateGlobal from "./CreateGlobal";
import GlobalActivityLog from "./GlobalActivityLog";
import GlobalSpendControl from "./GlobalSpendControl"


const SpendControl = () => {
  
  const { toggleSubnav} = useSelector((state) => state.General);
  let spendControlArray = [
    { id: 1, item: "Global Spend Control" },
    { id: 2, item: "Global Activity Log" },
    { id: 3, item: "Custom Spend Control" },
  ];

  return (
    <div className="row">
      <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle="Spend Control" listItems={spendControlArray}/>
          </div>
          <div className="main-content-container container-fluid px-4 mt-5">
            <BreadCrumbs />
            <SubSidenav />
            {toggleSubnav === 1 ? (
              <>
                <GlobalSpendControl />
                <CreateGlobal />
              </>
            ) : toggleSubnav === 2 ? <GlobalActivityLog />  : (
              <SpendControlData/>
            )}
          </div>
        </MainContent>
    </div>
  );
};

export default SpendControl;
