import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHeader from "../Reusables/tables/TableHeader";
import { getRewardBoosterApr, getSearchDetails, handleModal } from '../../Redux/actions';
import { rewardAprOptions } from './options';
import { getAPRRows } from "../../utils/TableRows/rewardBoosterRow";
import { aprColumns } from "../../utils/TableCols/RewardBoosterCols";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import UpdateAPR from "./UpdateAPR";

const RewardApr = () => {
  const dispatch = useDispatch();
  const { lockPeriod} = useSelector((state) => state.Search);
  const { rewardBoosterapr } = useSelector((state) => state.RewardBooster);
  const { gettingApr } = useSelector((state) => state.Loading);

  let allRows = getAPRRows( rewardBoosterapr ? rewardBoosterapr : [] );

  const data = {
    columns: aprColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(getRewardBoosterApr( lockPeriod ));
  }, [ lockPeriod ]);

  return (
    <div className="row">
    <div className="col-lg-12">
      <div className="row">
        <div className="col-lg-12 mb-4 position-relative">
          <div className="position-absolute apr-update-btn">
            <button
              className="btn button apr-btn"
              onClick={() => {
                dispatch(handleModal(59));
              }}
            >Update Apr</button>
          </div>
          <CustomDataTable
            data={data}
            tableId={"Reward Booster APR"}
            header={
              <TableHeader
                subHeader={ rewardAprOptions }
               
                onChange={(e, props) => {
                  dispatch(
                    getSearchDetails({
                      props: [props],
                      value: e.target.value,
                    })
                  );
                }}
                onSelect={(val, props) => {
                  dispatch(
                    getSearchDetails({
                      props: [props],
                      value: val,
                    })
                  );
                }}
              />
            }
            loading={gettingApr}
            noData={
              <EmptyCard
                img="/Assets/emptytransactions.svg"
                title={"Sorry,  you have no interest disbursements history"}
              />
            }
          />
        </div>
      </div>
    </div>
    <UpdateAPR />
  </div>
  )
}

export default RewardApr