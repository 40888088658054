import React, { Component } from "react";
import { connect } from "react-redux";
import {
  businessSessions,
  getFormDetails,
  getBusinessDevices,
  gettingDevices,
  clearData,
  toggleModal,
} from "../../Redux/actions";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import { numberWithCommas, precise_round } from "../../Helper";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "../Reusables/modals/Modal";
import CustomDataTable from "../Reusables/tables/CustomDataTable";

class Summary extends Component {
  componentWillMount() {
    this.props.clearData();
    this.props.gettingDevices('');
  }
  componentWillReceiveProps(nextProps) {
    const {
      isActive,
      sessionDateStart,
      sessionDateEnd,
      sessionDeviceId,
      selectedAffiliatedBusiness,
      role,
    } = nextProps;
    // var date = sessionDateStart;
    // var starttimestamp = date.getTime();
    // var date2 = sessionDateEnd;
    // var endtimestamp = date2.getTime();
    if (
      isActive !== this.props.isActive ||
      sessionDateStart !== this.props.sessionDateStart ||
      sessionDateEnd !== this.props.sessionDateEnd ||
      sessionDeviceId !== this.props.sessionDeviceId ||
      selectedAffiliatedBusiness !== this.props.selectedAffiliatedBusiness
    ) {
      this.props.clearData();
      this.props.businessSessions(
        selectedAffiliatedBusiness,
        isActive,
        sessionDateStart,
        sessionDateEnd,
        sessionDeviceId,
        1,
        50,
        role
      );
    }
  }



  customPagination() {
    const { selectedAffiliatedBusiness,
      isActive,
      sessionDateStart,
      sessionDateEnd,
      sessionDeviceId,
      businessSessionsCurrentPage,
      businessSessionsTotalPages,
      role,
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== businessSessionsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessSessions(
              selectedAffiliatedBusiness,
              isActive,
              sessionDateStart,
              sessionDateEnd,
              sessionDeviceId,
              businessSessionsCurrentPage - 1,
              50,
              role
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; businessSessionsTotalPages >= i; i++) {
      if (i === businessSessionsCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.businessSessions(
              selectedAffiliatedBusiness,
              isActive,
              sessionDateStart,
              sessionDateEnd,
              sessionDeviceId,
              i,
              50,
              role
            )}
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (businessSessionsTotalPages > businessSessionsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessSessions(
              selectedAffiliatedBusiness,
              isActive,
              sessionDateStart,
              sessionDateEnd,
              sessionDeviceId,
              businessSessionsCurrentPage + 1,
              50,
              role
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      bizsessionSummary,
      loadingSessions,
      managerDevices,
      conversionsRate,
      sessionDateStart,
      sessionDateEnd,
      businessSessionsTotalRecords,
      affiliatedBusiness,
      clickedDeviceId,
    } = this.props;

    let allRows = [];
    bizsessionSummary.map((session, i) => {
      return allRows.push({
        // host: session.host.userName,
        name: session.client.userName,
        device: session.sessionDevice.deviceName,
        rate:
          numberWithCommas(session.sessionDevice.rate * 1048576 * 360) +
          " NGN/GB ",
        createdAt: moment
          .parseZone(session.sessionCreationTmeStamp)
          .format("MMMM Do YYYY, h:mm a"),
        updatedAt: moment
          .parseZone(session.sessionLastUpdateTimeStamp)
          .format("MMMM Do YYYY, h:mm a"),
        view: (
          <div
            className="details t-center"
            onClick={() => this.props.toggleModal(true, session.sessionId)}
          >
            View More
          </div>
        ),
      });
    });

    const data = {
      columns: [
        // {
        //   label: "Host",
        //   field: "host",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Client",
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Device",
          field: "device",
          sort: "asc",
          width: 100,
        },
        {
          label: "Rate",
          field: "rate",
          sort: "asc",
          width: 100,
        },
        {
          label: "Created",
          field: "createdAt",
          sort: "asc",
          width: 100,
        },
        {
          label: "Updated",
          field: "updatedAt",
          sort: "asc",
          width: 100,
        },
        {
          label: "View",
          field: "view",
          sort: "asc",
          width: 100,
        },
      ],

      rows: allRows,
    };

    var selectedsummary =
      clickedDeviceId === ""
        ? {}
        : bizsessionSummary.find((session) => {
          return session.sessionId === clickedDeviceId;
        });
    var empty = [undefined, "", null];

    return (
      <div className="row">
        {empty.includes(clickedDeviceId) ? (
          ""
        ) : (
            <Modal heading={`Session Summary`}>
              <div className="p-2">
                <dl>
                  <dt> Host</dt>
                  <dd>{selectedsummary.host.userName}</dd>
                  <hr />
                  <dt>Client</dt>
                  <dd>{selectedsummary.client.userName}</dd>
                  <hr />
                  <dt>Agent</dt>
                  <dd>{selectedsummary.agent.userName}</dd>
                  <hr />
                  <dt>Device</dt>
                  <dd>{selectedsummary.sessionDevice.deviceName}</dd>
                  <hr />
                  <dt>Rate</dt>
                  <dd>
                    {numberWithCommas(
                      selectedsummary.sessionDevice.rate * 1048576 * 360
                    ) + " NGN/GB "}
                  </dd>
                  <hr />
                  <dt>Data Used in Bytes</dt>
                  <dd>
                    {precise_round(selectedsummary.dataUsedInBytes / 1048576, 2) +
                      "MB"}
                  </dd>
                  <hr />
                  <dt>Amount Received by Host</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.amountReceivedByHost * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Amount Spent by Client</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.amountSpentByClient * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Client's Opening Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.client.openingBalance * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Client's Closing Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.client.closingBalance * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Voucher's Opening Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.client.voucherOpeningBalance *
                        conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Voucher's Closing Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.client.voucherClosingBalance *
                        conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Host's Opening Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.host.openingBalance * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Host's Closing Balance</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.host.closingBalance * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Wicrypt Comission</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.wicryptCommision * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Referral Amount</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.referralAmount * conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Service Charge</dt>
                  <dd>
                    {"NGN " +
                      numberWithCommas(
                        selectedsummary.serviceChargeToBeneficiary *
                        conversionsRate
                      )}
                  </dd>
                  <hr />
                  <dt>Average Data Usage Per Minute</dt>
                  <dd>
                    {precise_round(
                      selectedsummary.averageDataUsagePerMinute / 1048576,
                      2
                    ) + "MB"}
                  </dd>
                  <hr />
                  <dt>Session Created At</dt>
                  <dd>
                    {moment
                      .parseZone(selectedsummary.sessionCreationTmeStamp)
                      .format("MMMM Do YYYY, h:mm a")}
                  </dd>
                  <hr />
                  <dt>Session Last Updated At</dt>
                  <dd>
                    {moment
                      .parseZone(selectedsummary.sessionLastUpdateTimeStamp)
                      .format("MMMM Do YYYY, h:mm a")}
                  </dd>
                </dl>
              </div>
            </Modal>
          )}
        <div className="col-lg-12 mb-4">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="">
              <h6 className="m-0"> {businessSessionsTotalRecords} SESSIONS</h6>
            </div>
            <div className="mt-3 d-flex row">
              <div className="dateRange col-lg-12">
                <div className="mr-3"> FROM</div>
                <DatePicker
                  className="dateRange form-control"
                  selected={sessionDateStart}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["sessionDateStart"],
                      value: date.getTime(),
                    })
                  }
                />
                <div className="mr-3 ml-3"> TO</div>
                <DatePicker
                  className="dateRange form-control"
                  selected={sessionDateEnd}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["sessionDateEnd"],
                      value: date.getTime(),
                    })
                  }
                />
              </div>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["isActive"],
                    value: e.target.value,
                  })
                }
              >
                <option value={"True"}>Active Sessions</option>
                <option value={"False"}> Inactive Sessions</option>
              </select>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["sessionDeviceId"],
                    value: e.target.value,
                  })
                }
              >
                <option value="">Select Device</option>

                {managerDevices.map((each) => {
                  return <option value={each.id}>{each.name}</option>;
                })}
              </select>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["selectedAffiliatedBusiness"],
                    value: e.target.value,
                  })
                }
              >
                <option value="">Select Business</option>
                {affiliatedBusiness.map((business) => {
                  return (
                    <option value={business.business.id}>
                      {business.business.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row">
            {loadingSessions & (bizsessionSummary.length === 0) ? (
              <div className="col-lg-12 mb-4">
                <CustomLoader />{" "}
              </div>
            ) : (
                <div className="col-lg-12 mb-4">
                  {bizsessionSummary.length > 0 ? (
                    <CustomDataTable
                      data={data}
                      pagination={this.customPagination()}
                    />
                  ) : (
                      <p className="voucherText t-center">
                        {" "}
                    No sessions report for the selected specs
                      </p>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { loadingSessions } = state.Loading;
  const {
    bizsessionSummary,
    conversionsRate,
    businessSessionsTotalRecords,
    businessSessionsCurrentPage,
    businessSessionsTotalPages,
  } = state.Report;
  const {
    sessionDateStart,
    sessionDateEnd,
    isActive,
    sessionDeviceId,
    myBusinessId,
    selectedAffiliatedBusiness,
    role,
    clickedDeviceId,
  } = state.User;
  const { managerDevices, businessDevices, affiliatedBusiness } = state.Manager;
  return {
    loadingSessions,
    bizsessionSummary,
    conversionsRate,
    sessionDateStart,
    sessionDateEnd,
    isActive,
    sessionDeviceId,
    managerDevices,
    myBusinessId,
    businessDevices,
    businessSessionsTotalRecords,
    businessSessionsCurrentPage,
    businessSessionsTotalPages,
    affiliatedBusiness,
    selectedAffiliatedBusiness,
    role,
    clickedDeviceId,
  };
};

export default connect(
  MapStateToProps,
  {
    businessSessions,
    getFormDetails,
    gettingDevices,
    getBusinessDevices,
    clearData,
    toggleModal,
  }
)(Summary);