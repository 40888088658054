import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getAccruedInterestsUser, getSearchDetails } from "../../../Redux/actions";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { getRewardBoosterRows } from "../../../utils/TableRows/rewardBoosterRow";
import { accruedInterestsUserColumns } from "../../../utils/TableCols/RewardBoosterCols";
import { accruedInterestsOptions } from "../options";

const AccruedUserPage = ({userId}) => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { accruedInterestsUserId } = useSelector((state) => state.RewardBooster);
  const { gettingAccruedInterestsUserId} = useSelector((state) => state.Loading);
  const { startDate, endDate, page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getRewardBoosterRows( accruedInterestsUserId ? accruedInterestsUserId.records : [], 
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );

  const data = {
    columns: accruedInterestsUserColumns,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(getAccruedInterestsUser (userId, startDate, endDate, page, pageSize ));
  }, [userId, startDate, endDate, page, pageSize]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"Reward Booster"}
              pageCount={accruedInterestsUserId.totalPages}
              currentPage={accruedInterestsUserId.currentPage}
              header={
                <TableHeader
                  dataLength={accruedInterestsUserId.records ? accruedInterestsUserId.records.length : 0}
                  subHeader={ accruedInterestsOptions }
                  
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
               
                />
              }
              loading={gettingAccruedInterestsUserId}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry, you have no accrued interest history"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccruedUserPage;
