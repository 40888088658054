import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTopics, handleSubNav } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { Link } from "react-router-dom";


const ListOfAllTopics = () => {

    const dispatch = useDispatch();

    // fetching all topics
    const { dlqTopics} = useSelector((state) => state.Topics);
    const { gettingAllTopics } = useSelector((state) => state.Loading);
  

    let allRows = [];
    dlqTopics.map((each) => {
      const lastPath = each.split('/').pop();
        return allRows.push({
          topics: <p className="mb-0">{lastPath}</p>,
          viewDetail: <Link className="t-center details text-white" to={`/topics/${lastPath}`}> View Detail </Link>
        });
    });


    useEffect(() => {
        dispatch(getAllTopics(true));
    }, []);

    const data = {
        columns: [
            {
                label: "Dead Letter Topics",
                field: "topics",
                sort: "asc",
                width: 150,
            },
            {
                label: "",
                field: "viewDetail",
                sort: "asc",
                width: 100,
            },
            
        ],

        rows: allRows,
    };


    return (
        <div class=" col-md-6 col-12">
            <div className="row mb-4">
                <div className="col-lg-12 inner-topic">
                    <CustomDataTable
                        data={data}
                        title="List of all the dead letter topics"
                        loading={gettingAllTopics}
                        noData={
                            <EmptyCard
                                img="/Assets/emptytransactions.svg"
                                title={"No Deadletter Topics Available"}
                            // descr={"No Request available at the moment"}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ListOfAllTopics;
