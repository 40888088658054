import React, { Component } from "react";
import { BeatLoader } from "react-spinners";

class BeatLoading extends Component {
  render() {
    const { size, color } = this.props;
    return (
      <div className="sweet-loading">
        <BeatLoader
          size={size ? size : 30}
          color={color || "#3d5170"}
          loading={true}
          margin={"1px"}
        />
      </div>
    );
  }
}

export default BeatLoading;
