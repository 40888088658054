import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { uniqBy } from "lodash";
import rolesConfig from "./config";
import * as Routes from "./index";
import Login from "../Components/Authentication/Login";
import Register from "../Components/Authentication/Register";
import ForgotPassword from "../Components/Authentication/Forgot";
import ResetPassword from "../Components/Authentication/Reset";
import SelfSeriveVoucher from "../Components/SelfSeriveVoucher";

class PrivateRoutes extends Component {
  render() {
    const { role } = this.props;

    const roles = role
      ? role.includes("Admin") || role.includes("SuperAdmin")
        ? ["admin"]
        : role.includes("Operations")
        ? ["operations"]
        : role.includes("Technical")
        ? ["technical"]
        : role.includes("Developer")
        ? ["developer"]
        : ["redirect"]
      : [""];

    let allowedRoutes = roles.reduce((acc, role) => {
      return [...acc, ...rolesConfig[role].routes];
    }, []);

    // For removing duplicate entries.
    allowedRoutes = uniqBy(allowedRoutes, "component");

    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/passwords/reset" component={ResetPassword} />
        <Route
          exact
          path="/selfservice/voucher/balance"
          component={SelfSeriveVoucher}
        />
        {allowedRoutes.map(({ component, url }) => (
          <Route
            key={component}
            // exact path={`${match.path}${url}`}
            exact
            path={`${url}`}
            component={Routes[component]}
          />
        ))}
      </Switch>
    );
  }
}

const MapStateToProps = (state) => {
  return { role: state.User.role };
};

export default withRouter(connect(MapStateToProps, {})(PrivateRoutes));
