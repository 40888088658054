import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../Redux/reducers/config";
import { WalletData, WalletType, WalletValue, SmallerWalletValue } from "./styles";
import { WicryptPattern } from "../User/profilestyles";
import { numberWithCommas } from "../../Helper";
import Loader from "../Reusables/loaders/MoonLoader";

function Wallets() {
  const [walletBalance, setWalletBalance] = useState([]);
  const [loadingWallets, setWalletLoader] = useState(false);
  const userId = window.location.pathname.split("/").at(-1);


  useEffect(() => {
    const getWalletBalance = async () => {
      setWalletLoader(true)
      await axios
        .get(config.adminApiUrl + `/v1/users/${userId}/wallet-balance`)
        .then((res) => {
            setWalletBalance(res.data.data)
            setWalletLoader(false)
          }
        )
        .catch((err)=>{
          setWalletLoader(false)
        })
    };
    getWalletBalance();
  }, []);


  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <TotalWalletBalance loading={loadingWallets} usdBalance={walletBalance.usdWorthOfAllBalances || 0} />
        <Balances loading={loadingWallets} balances={walletBalance.balances || []} />
      </div>
    </div>
  );
}

export const TotalWalletBalance = ({ usdBalance, loading }) => {
  const [visible, setVisibile] = useState(true);
  return (
    <div
      className="card w3-animate-bottom col-lg-12 p-8 wallet-card"
    >
      <div>
        <WalletData className="pt-4">
          Total Value
          {visible ? (
            <i
              className="material-icons mr-2 ml-2 t-14"
              aria-hidden="true"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setVisibile(false);
              }}
            >
              visibility_off
            </i>
          ) : (
            <i
              className="material-icons mr-2 ml-2 t-14"
              aria-hidden="true"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setVisibile(true);
              }}
            >
              visibility_on
            </i>
          )}
        </WalletData>
        <WalletValue> {loading ? <Loader /> : `USD ${visible ? numberWithCommas(usdBalance, 4) : "*****"}` }</WalletValue>

      </div>
    </div>
  )
}

export const Balances = ({ balances }) => {
  const [visible, setVisible] = useState(true);
  return (

    <div
      style={{ marginBottom: "10px"}}
    >
      {balances.map((balance) => {
        return (
          <div
            className="card w3-animate-bottom mb-2"
          >
            <div className="card-body p-6">
              {" "}
              <WalletType>
                <img src={balance.iconUrlSvg} alt={balance.currency} height="20px" className="mr-1" />
                {balance.currencyName}{" "}
                ({balance.currency})
                {visible ? (
                  <i
                    className="material-icons mr-2 ml-2 t-14"
                    aria-hidden="true"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    visibility_off
                  </i>
                ) : (
                  <i
                    className="material-icons mr-2 ml-2 t-14"
                    aria-hidden="true"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    visibility_on
                  </i>
                )}
              </WalletType>
              <div className="row d-flex ">
                <div className="col-lg-4 mb-2">
                  <WalletData>Total</WalletData>
                  <WalletValue>
                    {balance ? balance.currency : "N/A"}
                    {visible
                      ? balance
                        ? " " + numberWithCommas(balance.total, 4)
                        : "0"
                      : " *******"}
                  </WalletValue>
                  <SmallerWalletValue>
                  ~
                  {"USD"}
                    {visible
                      ? " " +
                      (balance
                        ? numberWithCommas(balance.totalUSDBalances, 4)
                        : "0")
                      : " *******"}
                  </SmallerWalletValue>
                </div>
                <div className="col-lg-4">
                  <WalletData>Available</WalletData>
                  <WalletValue>
                    {balance ? balance.currency : "N/A"}
                    {visible
                      ? " " +
                      (balance
                        ? numberWithCommas(balance.available, 4)
                        : "0")
                      : " *******"}
                  </WalletValue>
                  <SmallerWalletValue>
                  ~
                  {"USD"}
                    {visible
                      ? " " +
                      (balance
                        ? numberWithCommas(balance.availableUSDBalances, 4)
                        : "0")
                      : " *******"}
                  </SmallerWalletValue>
                </div>
                <div className="col-lg-4">
                  <WalletData>In Orders</WalletData>
                  <WalletValue>
                    {balance ? balance.currency : "N/A"}
                    {visible
                      ? " " +
                      (balance
                        ? numberWithCommas(balance.inOrders, 4)
                        : "0")
                      : " *******"}
                  </WalletValue>
                  <SmallerWalletValue>
                    ~
                  {"USD"}
                    {visible
                      ? " " +
                      (balance
                        ? numberWithCommas(balance.inOrdersUSDBalances, 4)
                        : "0")
                      : " *******"}
                  </SmallerWalletValue>
                </div>
              </div>
              <WicryptPattern
                src="/Assets/cardicons/wicryptpattern.svg"
                alt="wicryptpattern"
                style={{ width: "auto", height: "70%" }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Wallets;
