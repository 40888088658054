import _const from "../actions/Types";

const initialState = {
  blockingDevice: false,
  devicesesions: [],
  devicesesionsCurrentPage: 1,
  devicesesionsTotalPages: 1,
  devicesesionsTotalRecords: 1,
  totalDataUsed: "",
  numberOfSessions: "",
  totalAmountGenerated: "",
  numberOfDevices: "",
  gettingTotalDevices: false,
  gettingTotalDevicesOnce: false,
  totalOfAllDevices: {},
  totalOfAllDevicesOnce: {},
  gettingSessionsSummary: false,
  sessionSummary: {},
  gettingMiningStats: false,
  miningStatistics: {},
  gettingDeviceStatusCount:false,
  deviceStatusCount:false,
  allDevices: [],
  deviceLogs: [], 
  deviceLogsCurrent: 1, 
  deviceLogsTotalPages: 1
    
};

const DeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.GET_ALL_WICRYPT_DEVICES:
      return{...state, allDevices: action.payload}

    case _const.DEVICE_SESSION_HISTORY:
      return {
        ...state,
        devicesesions: action.payload.records,
        devicesesionsCurrentPage: action.payload.currentPage,
        devicesesionsTotalPages: action.payload.totalPages,
        devicesesionsTotalRecords: action.payload.totalRecords,
      };
    case _const.GET_ALL_TOTAL_DEVICES:
      return {
        ...state,
        gettingTotalDevices: action.payload,
      };
    case _const.GET_ALL_TOTAL_DEVICES_ONCE:
      return {
        ...state,
        gettingTotalDevicesOnce: action.payload,
      };
    case _const.ALL_TOTAL_DEVICES:
      return {
        ...state,
        totalOfAllDevices: action.payload,
      };
    case _const.ALL_TOTAL_DEVICES_ONCE:
      return {
        ...state,
        totalOfAllDevicesOnce: action.payload,
      };
    case _const.GETTIND_DEVICE_SESSIONS:
      return {
        ...state,
        gettingSessionsSummary: action.payload,
      };
    case _const.SESSION_STATISTICS:
      return {
        ...state,
        sessionSummary: action.payload,
      };
    case _const.GETTING_MINING_STATS:
      return {
        ...state,
        gettingMiningStats: action.payload,
      };
    case _const.MINING_STATISTICS:
      return {
        ...state,
        miningStatistics: action.payload,
      };
    case _const.GETTING_DEVICE_STATUS_COUNT:
      return {
        ...state,
        gettingDeviceStatusCount: action.payload,
      };
    case _const.DEVICE_STATUS_COUNT:
      return {
        ...state,
        deviceStatusCount: action.payload,
      };
    case _const.BLOCKING_DEVICE:
      return {
        ...state,
        blockingDevice: action.payload,
      };

    case _const.DEVICE_SESSION_SUMMARY:
      return {
        ...state,
        numberOfDevices: action.payload.numberOfDevices,
        numberOfSessions: action.payload.numberOfSessions,
        totalAmountGenerated: action.payload.totalAmountGenerated,
        totalDataUsed: action.payload.totalDataUsed,
      };

    case _const.DEVICE_ACTIVITY_LOGS:
      return{
        ...state, 
        deviceLogs: action.payload.records, 
        deviceLogsCurrent: action.payload.currentPage, 
        deviceLogsTotalPages: action.payload.totalPages
      }
      
    default:
      return state;
  }
};

export default DeviceReducer;
