import React, { useEffect } from "react";
import LinkButton from "../Reusables/buttons/LinkButton";
import {
  Label,
  LeftContent,
  RightContent,
  Request,
  LinkText,
  Center,
} from "./dahboardstyles";
import { useDispatch, useSelector } from "react-redux";
import { getallVerificationRequests, oneRequest } from "../../Redux/actions";
import { MoonLoader } from "react-spinners";
import EmptyCard from "../Reusables/cards/EmptyCard";

const LatestRequests = () => {
  const { verificationRequests } = useSelector((state) => state.Verification);
  const { gettingRequests } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();

  useEffect(() => {
    const queries = {
      Page: 1,
      PageSize: 5,
    };
    getallVerificationRequests(dispatch, queries);
  }, []);

  return (
    <div className="col-lg-6 mb-4 p-1">
      <div className="card card-small" style={{ height: "500px" }}>
        <div className="card-header border-bottom">
          <Label>Latest Verification Requests</Label>
        </div>
        <div className="card-body pt-0">
          <div className="flex flex-col  items-center justify-between">
            {!gettingRequests ? (
              verificationRequests.length ? (
                verificationRequests.map((item, index) => (
                  <Request key={item + index} even={(index + 1) % 2 === 0}>
                    <LeftContent>
                      <div>{item.name}</div>
                    </LeftContent>{" "}
                    <Center>
                      {/* <img
                      src={`https://flagcdn.com/${item.countryCode.toLowerCase()}.svg`}
                      alt="country flag"
                    /> */}
                      {}
                    </Center>
                    <RightContent>
                      <LinkButton
                        text={"View Document"}
                        link={`oneVerification/${item.id}`}
                        onClick={() => dispatch(oneRequest(item.id))}
                      />
                    </RightContent>
                  </Request>
                ))
              ) : (
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"No latest Requests"}
                  // descr={"No User available at the moment"}
                />
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader />
              </div>
            )}
          </div>
        </div>
        <div
          className="card-footer flex items-center justify-center"
          style={{ color: "#CFA809" }}
        >
          <LinkText to="/wicryptusers">See All</LinkText>
        </div>
      </div>
    </div>
  );
};

export default LatestRequests;
