import React from "react";
import { MdFileCopy } from "react-icons/md";
import moment from "moment";
import { Link } from "react-router-dom";
import { copyHandle, formatString, numberWithCommas, shortenAddress } from "../../Helper";
import { useDispatch } from "react-redux";
import { handleSubNav } from "../../Redux/actions";

export const getRewardBoosterRows = (
  data,
  copied,
  setCopied,
  currentRow,
  setCurrentRow,
  type,
  setType,
) => {
  const dispatch = useDispatch();
  let allRows = data
    ? data.map((reward, index) => {
      return {
        id: reward.id || "N/A",
        lockPeriod: reward.lockPeriod ? formatString(reward.lockPeriod)  : "N/A",
        lockUpStartDate: moment(reward.lockUpStartDate).format("DD-MM-YYYY") +
                        " | " +
                        moment(reward.lockUpStartDate).format("LT"),
        lockUpEndDate: moment(reward.lockUpEndDate).format("DD-MM-YYYY") +
                      " | " +
                      moment(reward.lockUpEndDate).format("LT"),
        boosterStatus: reward.boosterStatus ? <p  className={` ${reward.boosterStatus === 'LockReward' ? 'text-red' : reward.boosterStatus === 'RemitRewardToWallet' ? 'text-orange' : reward.boosterStatus === 'RewardReleased' ? 'text-green' : ''}`}>
          {formatString(reward.boosterStatus)} </p> : "N/A",
        userDetails: reward.userInfo && (<Link to={`/user/${reward.userInfo.id}`} 
          onClick={() => {reward.boosterStatus ? dispatch(handleSubNav(10)) : reward.week ? dispatch(handleSubNav(11)) : dispatch(handleSubNav(12))}} 
          className="user-info">
          <p className="mb-1">Name: {reward.userInfo && reward.userInfo.name ? reward.userInfo.name : "N/A"}</p>
          <p className="mb-0">Email: {reward.userInfo && reward.userInfo.email ? reward.userInfo.email : "N/A"}</p>
          </Link>),
        week: reward.week || "N/A",
        amount: reward.amount && (numberWithCommas(reward.amount, 2) + " " + reward.currency),
        cycleId: reward.cycleId ? (
          <Link 
            to={`/reward-booster/cycles/${reward.cycleId}`}
            style={{ cursor: "pointer" }}
            className="address"
          >
            <div className="details t-center">View Cycle</div>
          </Link>
        ) : (
          "N/A"
        ),
        // rewardId: reward.rewardId ? (
        //   <Link 
        //     to={`/reward-booster/cycles/${reward.rewardId}`}
        //     style={{ cursor: "pointer" }}
        //     className="address"
        //   >
        //     <div className="details t-center">View Mining Reward</div>
        //   </Link>
        // ) : (
        //   "N/A"
        // ),
        rewardId: reward.rewardId ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("rewardId");
              setCopied(true);
              copyHandle(reward.rewardId);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(reward.rewardId)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "rewardId" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        referenceCode: reward.referenceCode ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("reference");
              setCopied(true);
              copyHandle(reward.referenceCode);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(reward.referenceCode)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "reference" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        viewDetails: (
          <Link
          to={
            reward.boosterStatus === "LockReward" || reward.boosterStatus === "RemitRewardToWallet" || reward.boosterStatus === "RewardReleased" 
              ? `/reward-booster/cycles/${reward.id}`
              : `/reward-booster/lockup-requests/cycle/${reward.cycleId}`
          }
          ><div className="details t-center">More Details</div>
          </Link>
        ),
        date:
          moment(reward.createdAt).format("DD-MM-YYYY") +
          " | " +
          moment(reward.createdAt).format("LT"),
        transactionTimeStamp:
          moment(reward.transactionTimeStamp).format("DD-MM-YYYY") +
          " | " +
          moment(reward.transactionTimeStamp).format("LT"),
        disbursedAt:
          moment(reward.disbursedAt).format("DD-MM-YYYY") +
          " | " +
          moment(reward.disbursedAt).format("LT"),
        totalImpactedUsers: reward.totalImpactedUsers || 0,
        totalDisbursementInterest: reward.totalDisbursementInterest ? numberWithCommas(reward.totalDisbursementInterest, 2) : 0,
        status: reward.status && (
          <div className={reward.status.toUpperCase()}>
            {reward.status || "N/A"}
          </div>
        ),
        
      };
      })
    : [];
  return allRows;
};

export const getAPRRows = (
  data,
) => {
  let allRows = data
    ? data.map((reward, index) => {
      return {
        id: (index + 1),
        apr: reward.apr ? reward.apr : "N/A",
        lockPeriod: reward.lockPeriod ? formatString(reward.lockPeriod)  : "N/A",
      };
      })
    : [];
  return allRows;
};