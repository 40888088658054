/* eslint-disable no-unused-expressions */
import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

export const getAdminTransactions = async (dispatch, type, parameters) => {
  dispatch({
    type: _const.GETTING_ADMIN_TRANSACTIONS,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  await axios
    .get(config.adminApiUrl + `/v1/transactions/${type}`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_ADMIN_TRANSACTIONS,
        payload: false,
      });
      dispatch({
        type: _const.ADMIN_TRANSACTIONS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_ADMIN_TRANSACTIONS,
        payload: false,
      });
    });
};

export const changeTransactionType = (type) => {
  return (dispatch) => {
    dispatch({
      type: _const.SET_TRANSACTION_TYPE,
      payload: type,
    });
  };
};

//Daily transaction reports
export const dailyTransactionReports = (StartDateTimestamp, EndDateTimestamp, Duration, Page, PageSize) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_DAILY_REPORTS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/accountReconciliation/daily-transaction-reports?StartDateTimestamp=${StartDateTimestamp}&EndDateTimestamp=${EndDateTimestamp}&Duration=${Duration}&Page=${Page}&PageSize=${PageSize}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_DAILY_REPORTS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.DAILY_REPORTS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_DAILY_REPORTS,
            payload: false,
          });
      });
  };
}

//Daily transaction reports
export const oneDailyTransactionReport = (dateTimeStamp) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_DAILY_REPORTS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/accountReconciliation/daily-transaction-detail/${dateTimeStamp}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_DAILY_REPORTS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.DAILY_REPORT,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_DAILY_REPORTS,
            payload: false,
          });
      });
  };
}

//Daily transaction reports from Switch Wallet
export const switchWalletTransactionReports = (StartDateTimestamp, EndDateTimestamp, Duration, Page, PageSize) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/accountReconciliation/switchwallet-daily-balance-report?StartDateTimestamp=${StartDateTimestamp}&EndDateTimestamp=${EndDateTimestamp}&Duration=${Duration}&Page=${Page}&PageSize=${PageSize}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.SWITCHWALLET_REPORT,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
            payload: false,
          });
      });
  };
}

//Daily transaction reports from Switch Wallet
export const switchWalletTransactionReport = (dateTimeStamp) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/accountReconciliation/switchwallet-daily-balance-report/${dateTimeStamp}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.SWITCHWALLET_DAILY_REPORT,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_DAILY_SWITCHWALLET_REPORTS,
            payload: false,
          });
      });
  };
}

//Transaction report for a user 
export const dailyUserTransactionReports = (UserId, BusinessId, StartDateTimestamp, EndDateTimestamp, Duration, Page, PageSize) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_DAILY_USER_REPORTS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/accountReconciliation/daily-user-balance-reports?UserId=${UserId}&BusinessId=${BusinessId}&StartDateTimestamp=${StartDateTimestamp}&EndDateTimestamp=${EndDateTimestamp}&Duration=${Duration}&Page=${Page}&PageSize=${PageSize}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_DAILY_USER_REPORTS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.DAILY_REPORTS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_DAILY_USER_REPORTS,
            payload: false,
          });
      });
  };
}

//P2p Order Confirmations
export const p2pOrderConfirmations = (Page, PageSize, email, orderType, Search, orderStatus) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_P2P_ORDER_CONFIRMATION,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/webhook-report/p2p-order-confirmations?Page=${Page}&PageSize=${PageSize}&Email=${email}&OrderType=${orderType}&Search=${Search}&OrderStatus=${orderStatus}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_P2P_ORDER_CONFIRMATION,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.P2P_ORDER_CONFIRMATIONS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_P2P_ORDER_CONFIRMATION,
            payload: false,
          });
      });
  };
}

//P2p Order Cancellations
export const p2pOrderCancellations = (Page, PageSize, Search, orderStatus) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_P2P_ORDER_CANCELLATIONS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/webhook-report/p2p-order-cancellations?Page=${Page}&PageSize=${PageSize}&Search=${Search}&OrderStatus=${orderStatus}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_P2P_ORDER_CANCELLATIONS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.P2P_ORDER_CANCELLATIONS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_P2P_ORDER_CANCELLATIONS,
            payload: false,
          });
      });
  };
}

//Switchwallet deposits
export const getswitchWalletDeposits = (Page, PageSize, search, status) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_SWITCHWALLET_DEPOSITS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/webhook-report/switchwallet-deposits?Page=${Page}&PageSize=${PageSize}&Search=${search}&Status=${status}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_SWITCHWALLET_DEPOSITS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.SWITCHWALLET_DEPOSITS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_SWITCHWALLET_DEPOSITS,
            payload: false,
          });
      });
  };
}

//Switchwallet withdrawals
export const getswitchWalletWithdrawals = (Page, PageSize, search, reference, status) =>{
  return (dispatch) => {
    dispatch({
        type: _const.GETTING_SWITCHWALLET_WITHDRAWALS,
        payload: true,
      });
    axios
      .get(config.adminApiUrl + `/v1/webhook-report/switchwallet-withdrawals?Page=${Page}&PageSize=${PageSize}&Search=${search}&Reference=${reference}&Status=${status}`)
      .then((res) => {
        dispatch({
            type: _const.GETTING_SWITCHWALLET_WITHDRAWALS,
            payload: false,
          });
          if(res.data.isSuccessful){
            dispatch({
              type: _const.SWITCHWALLET_WITHDRAWALS,
              payload: res.data.data,
            });
          }
      })
      .catch((err) => {
        dispatch({
            type: _const.GETTING_SWITCHWALLET_WITHDRAWALS,
            payload: false,
          });
      });
  };
}

// To get the new transactions details
export const getNewTransactionDetails = (newTransactionType, transactionID) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_NEW_TRANSACTION_DETAILS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/transactions/${newTransactionType}/${transactionID}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_NEW_TRANSACTION_DETAILS,
            payload: false
          });
          dispatch({
            type: _const.NEW_TRANSACTION_DETAILS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_NEW_TRANSACTION_DETAILS,
          payload: false
        });
      });
  };
};

// To get the new transactions P@P details this includes their types (bull, sell, swap)
export const getP2PDetails = (transactionType, transactionID) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_NEW_P2P_DETAILS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/transactions/p2p/${transactionType}/${transactionID}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_NEW_P2P_DETAILS,
            payload: false
          });
          dispatch({
            type: _const.NEW_P2P_DETAILS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_NEW_P2P_DETAILS,
          payload: false
        });
      });
  };
};