import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails } from "../../../Redux/actions";
import { numberWithCommas, shortenAddress } from "../../../Helper";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "../../Devices/styles";
import { StakeStatValuePre } from "../style";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import { MdFileCopy } from "react-icons/md";

const CryptoDepositDetailPage = ({ history }) => {

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [depositId, setDepositId] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const cryptoType = "crypto-deposit";


  const goBack = () => {
    history.goBack();
  };

  // I added this in a useEffect, please check if it works effectively
  useEffect(() => {
    const newDepositId = window.location.pathname.split("/").at(-1);
    setDepositId(newDepositId);
  }, []);

  useEffect(() => {
    if (depositId) {
      dispatch(getNewTransactionDetails(cryptoType, depositId));
    }
  }, [depositId, dispatch]);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingNewTrancactionDetails ?
            <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
             {Object.keys(newTransactionDetails).length !== 0 ?
              <div className="card-body p-6 ">
                <div className="row mb-2">
                  { newTransactionDetails.receiver &&
                    <div className="col-lg-6 col-md-6">
                    <PersonalBox>
                      <UserDetailsAvatar
                        className="rounded-circle"
                        src="/Assets/images/avatars/user_icon.svg"
                        alt="User Avatar"
                      />
                      <PersonalInfo>
                        <span>{newTransactionDetails.receiver && newTransactionDetails.receiver.name ? newTransactionDetails.receiver.name : "N/A"}</span>
                        <span>{newTransactionDetails.receiver && newTransactionDetails.receiver.email ? newTransactionDetails.receiver.email : "N/A"}</span>
                      </PersonalInfo>
                      <Link to={`/user/${newTransactionDetails.receiver.id}`}><div className="details min-width"> Details </div></Link>
                    </PersonalBox>
                    </div>
                  }

                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Reference Code</StakeStatData>
                    { newTransactionDetails.referenceCode ? (
                      <StakeStatValue>
                        {newTransactionDetails.referenceCode}
                      </StakeStatValue>
                    ) : "N/A"}
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Transaction Hash</StakeStatData>
                    { newTransactionDetails.transactionHash ? (
                      <StakeStatValue>
                        {newTransactionDetails.transactionHash}
                      </StakeStatValue>
                    ) : "N/A"}
                  </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Total Deposit Amount</StakeStatData>
                    { newTransactionDetails.totalDepositAmount ? (
                      <StakeStatValue>
                        {numberWithCommas(newTransactionDetails.totalDepositAmount, 4)} {newTransactionDetails.currency}
                      </StakeStatValue>
                    ) : 0}
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <StakeStatData> Amount Credited To Wallet </StakeStatData>
                    { newTransactionDetails.amountCreditedToWallet ? (
                      <StakeStatValue>
                        {numberWithCommas(newTransactionDetails.amountCreditedToWallet, 4)} {newTransactionDetails.currency}
                      </StakeStatValue>
                    ) : 0}
                  </div>
                  
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Fee</StakeStatData>
                    {newTransactionDetails.fee ? <StakeStatValue>{numberWithCommas(newTransactionDetails.fee, 4)}</StakeStatValue> : 0.00}
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Fee Remitted</StakeStatData>
                    {newTransactionDetails.isFeeRemitted !== undefined && (
                      <StakeStatValue>{newTransactionDetails.isFeeRemitted === true ? "True" : "False"}</StakeStatValue>
                      )}
                  </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-lg-6 col-md-6">
                      <StakeStatData>Wallet Address</StakeStatData>
                      { newTransactionDetails.walletAddress ? <StakeStatValue>{newTransactionDetails.walletAddress}</StakeStatValue> : "N/A"}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <StakeStatData>Depositor Wallet Address</StakeStatData>
                      { newTransactionDetails.depositorWalletAddress ? <StakeStatValue>{newTransactionDetails.depositorWalletAddress}</StakeStatValue> : "N/A"}
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-lg-12 col-md-12">
                      <StakeStatData>Description</StakeStatData>
                      { newTransactionDetails.description ? <StakeStatValue>{newTransactionDetails.description}</StakeStatValue> : "N/A"}
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-md-6">
                      <StakeStatData>Receiver Wallet State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th colSpan={2}>Available Balance</th>
                              <th colSpan={2}>Locked Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            <tr>
                            {/* Available Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            {/* Locked Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            </tr>
                            {newTransactionDetails.receiverWalletStateChange && (
                              <tr>
                                <TableValues>
                                  {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.availableBalanceBefore ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.availableBalanceBefore, 4) : 0}
                                </TableValues>
                                <TableValues>
                                  { newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.availableBalanceAfter ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.availableBalanceAfter, 4) : 0}
                                </TableValues>
                                <TableValues>
                                  { newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.lockedBalanceBefore ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.lockedBalanceBefore, 4) : 0}
                                </TableValues>
                                <TableValues>
                                  { newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.lockedBalanceAfter ? 
                                  numberWithCommas(newTransactionDetails.receiverWalletStateChange.lockedBalanceAfter, 4) : 0}
                                </TableValues>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8", marginBottom: "1rem"}}
                  >
                   <div className="col-md-12">
                      <StakeStatData>Wallet Transaction</StakeStatData>
                      <div style={{ marginTop: "5px" }} className="table-on-mobile">
                        <Table>
                          <TableHead>
                            <tr>
                              <th scope="col">Reference</th>
                              <th scope="col">Total Deposit Amount</th>
                              <th scope="col">Fee</th>
                              <th scope="col">Wallet Address</th>
                              <th scope="col">Fee Remitted</th>
                            </tr>
                          </TableHead>
                          <tbody style={{marginTop: "4px"}}>
                            <tr>

                              {newTransactionDetails.referenceCode ? <TableValues data-label="Reference">
                                <div 
                                  onClick={() => {
                                    navigator.clipboard.writeText(newTransactionDetails.referenceCode);
                                    setIsCopied(true);
                                    setTimeout(() => {
                                      setIsCopied(false);
                                    }, 1500);
                                  }}
                                  >
                                  <span className="">{shortenAddress(newTransactionDetails.referenceCode)}</span>
                                  <span className="pl-2"><MdFileCopy /></span>
                                  <span className="pl-2">{isCopied ? "Copied" : ""}</span>
                                </div>
                              </TableValues> : "N/A"}

                              {newTransactionDetails.totalDepositAmount ? 
                                <TableValues data-label="Total Deposit Amount">{numberWithCommas(newTransactionDetails.totalDepositAmount, 2)} {newTransactionDetails.currency}</TableValues> : "N/A" }

                              {newTransactionDetails.fee ? 
                                <TableValues data-label="Fee">{newTransactionDetails.fee}</TableValues> : 0.00 }

                              {newTransactionDetails.walletAddress ? <TableValues data-label="Wallet Address">{newTransactionDetails.walletAddress}
                              </TableValues> : "N/A"}

                              {newTransactionDetails.isFeeRemitted !== undefined ? 
                                <TableValues data-label="Fee Remitted">{newTransactionDetails.isFeeRemitted === true ? "True" : "False"}</TableValues> : "N/A" }


                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                      <div className="col-lg-12 col-md-12">
                        <StakeStatData>Crypto Deposit Webhook</StakeStatData>
                        {newTransactionDetails.cryptoDepositWebhook ? (
                          <StakeStatValuePre>{JSON.stringify(newTransactionDetails.cryptoDepositWebhook, null, 2)}</StakeStatValuePre>) : "N/A"}
                      </div>
                    
                </div>
                
              </div> :
               <EmptyCard
               img="/Assets/emptytransactions.svg"
               title={"Sorry,  you have no transaction history"}
               // descr={"No transaction history available at the moment"}
             />
              }
            </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default CryptoDepositDetailPage;
