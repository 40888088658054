import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import ProviderList from "./ProviderList";
import CreateNodeProvider from "./CreateNodeProvider";


const NodeProvider = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Node Provider"/>
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          <ProviderList />
          <CreateNodeProvider />
        </div>
      </MainContent>
    </div>
  );
};

export default NodeProvider;
