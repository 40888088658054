import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmailProviders, handleModal, updateProviderStatus } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import Loader from "../Reusables/loaders/MoonLoader";
import EmptyCard from "../Reusables/cards/EmptyCard";
import moment from "moment";
import SetActiveModal from "./modals/SetActiveModal";

const ProviderList = () => {

    const dispatch = useDispatch();

    const { emailProviders, setActiveError } = useSelector((state) => state.Admin);
    const { gettingEmailProviders, updatingProviderStatus } = useSelector((state) => state.Loading);
    const [id, setId] = useState("");
    const [proverdersName, setProvidersName] = useState("");

    let allRows = [];
    emailProviders.map((each) => {
        return allRows.push({
            lastSet: moment(each.lastUpdatedOn).format("DD-MM-YYYY, h:mm a"),
            name: each.name,
            provider: each.provider,
            status: each.isActive ? <div className="SUCCESS"> Active </div> : <div className="PENDING"> Not Active </div>,
            setstatus: each.isActive ? <div className="SUCCESS">  </div> :
                <div className="details" onClick={() => { dispatch(handleModal(83)); setId(each.id); setProvidersName(each.name)}}>
                     Set as Active
                </div>
        });
    });

    useEffect(() => {
        dispatch(getEmailProviders())
    }, []);

    const data = {
        columns: [
            {
                label: "Last Set",
                field: "lastSet",
                sort: "asc",
                width: 170,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 170,
            },
            {
                label: "Provider",
                field: "provider",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "setstatus",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    return (
        <div className=" mb-4">
            <div className="row">
                <div className="col-lg-12">
                    <CustomDataTable
                        data={data}
                        loading={gettingEmailProviders}
                        noData={
                            <EmptyCard
                              img="/Assets/emptytransactions.svg"
                              title={"Sorry, There are no email providers available"}
                              // descr={"No transaction history available at the moment"}
                            />
                          }
                    />
                </div>
            </div>
            <SetActiveModal
                onSubmit={() => { dispatch(updateProviderStatus(id)); setId(id) }} 
                setActiveError={setActiveError}
                updatingProviderStatus={updatingProviderStatus}
                proverdersName={proverdersName}
            />
        </div>
    );
};

export default ProviderList;
