import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleModal, getUserSpendControl, getUserSpendControlActivityLog } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { createUserSpendControl} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { SpendInput } from "./styles";


const SpendCOntrolUser = ({ userProfile, userId }) => {

    const dispatch = useDispatch();
    const { userSpendControl } = useSelector((state) => state.SpendControl);
    const { gettingUserSpendControl, creatingUserSpend} = useSelector((state) => state.Loading);
    const { role } = useSelector((state) => state.User);
    const [customData, setCustom] = useState([]);


    const handleChange = (spendData, value, key) => {
        const foundIndex = customData.findIndex((data) => data.operation === spendData.operation && data.currency === spendData.currency);

        if (foundIndex !== -1) {
            const newData = [...customData];
            newData[foundIndex] = {
                ...newData[foundIndex],
                [key]: Number(value)
            };
            setCustom(newData);
        } else {
            const { id, ...rest } = spendData;
            setCustom([...customData, { ...rest, [key]: Number(value), "userId": userId }]);
        }
    };

    const handleSubmit = () => { setCustom([]); dispatch(createUserSpendControl(customData)) }

    const setDisabled = () =>{
        if(role.includes("Admin") || role.includes("SuperAdmin") ){
            return false
        }
        return true;
    }

 
    let allRows = [];
    userSpendControl.map((each) => {
        return allRows.push({
            operation: <div> <p> {each.operation}</p></div>,
            source: each.source,
            currency: each.currency,
            singleTransactionVolume: <SpendInput disabled={setDisabled()} type="number"
            onChange={(e) => handleChange(each, e.target.value, "singleTransactionVolume")}
            defaultValue={each.singleTransactionVolume} />,
            //numberWithCommaswithoutdecimals(each.singleTransactionVolume) + " USD",
            dailyCumulativeVolume: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "dailyCumulativeVolume")}
                defaultValue={each.dailyCumulativeVolume} />,
            //numberWithCommaswithoutdecimals(each.dailyCumulativeVolume) + " USD",
            cumulativeVolume7days: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "cumulativeVolume7days")}
                defaultValue={each.cumulativeVolume7days} />,
            //numberWithCommaswithoutdecimals(each.cumulativeVolume7days) + " USD",
            maxDailyTransactionCount: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "maxDailyTransactionCount")}
                defaultValue={each.maxDailyTransactionCount} />,
            //numberWithCommaswithoutdecimals(each.maxDailyTransactionCount),
            maxTransactionCount7Days: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "maxTransactionCount7Days")}
                defaultValue={each.maxTransactionCount7Days} />,
        });
    });

    
    useEffect(() => {
        dispatch(getUserSpendControl(userId));
        dispatch(getUserSpendControlActivityLog(userId))

    }, [userId]);

    const data = {
        columns: [
            {
                label: "Operation",
                field: "operation",
                sort: "asc",
                width: 170,
            },
            {
                label: "Source",
                field: "source",
                sort: "asc",
                width: 170,
            },
            {
                label: "Currency",
                field: "currency",
                sort: "asc",
                width: 170,
            },
            {
                label: "Single Transaction Volume (USD)",
                field: "singleTransactionVolume",
                sort: "asc",
                width: 170,
            },
            {
                label: "Daily Cummulative Volume (USD)",
                field: "dailyCumulativeVolume",
                sort: "asc",
                width: 170,
            },
            {
                label: "Weekly Cummulative Volume (USD)",
                field: "cumulativeVolume7days",
                sort: "asc",
                width: 170,
            },
            {
                label: "Max Daily Transaction Count",
                field: "maxDailyTransactionCount",
                sort: "asc",
                width: 170,
            },
            {
                label: "Weekly Max Transaction Count",
                field: "maxTransactionCount7Days",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    return (

        <div className="row mt-3">

            <div className="col-lg-12 w3-animate-bottom">
                <div className="card card-small mb-4 w3-animate-bottom">
                    <div className="row">
                        <div className="col-lg-12 p-3 mb-5">
                            <div className="col-lg-7"></div>
                            <div className="col-lg-3 fl-right">
                                <button onClick={() =>
                                    dispatch(handleModal(58)
                                    )} className="btn wicryptColor"> View Activity Log</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div
                        className="card-body d-flex p-4"
                        style={{ flexWrap: "wrap", gap: "20px" }}
                    >
                        <CustomDataTable
                            data={data}
                            title={userProfile.name + "'s Spend Control"}
                            loading={gettingUserSpendControl}
                            customleft={<div />}
                            customright={customData.length > 0 || creatingUserSpend ? 
                            <div className="details t-center"
                                onClick={() => handleSubmit()}
                            >
                               {creatingUserSpend ? <Loader color="#fefe"/> : "Save"}
                            </div> : <div />}
                        />
                    </div>

                </div>

            </div>
        </div>

    );
};

export default SpendCOntrolUser;
