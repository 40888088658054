import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  resolveBvn,
  getFormDetails,
  verifyAcct,
  uploadFile,
  getBanks,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { LayeredInput, AddSelect, SelectCountry } from "../Reusables/inputs";
import { OptionSubTitle } from "./upgradestyle";

let countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

class UpgradeAcct extends Component {
  componentDidMount() {
    this.props.getBanks();
  }

  handlecountryselect(value) {
    const { toggleDropdown } = this.props;
    this.props.getFormDetails({
      props: ["toggleDropdown"],
      value: !toggleDropdown,
    });
    this.props.getFormDetails({
      props: ["CountryCode"],
      value: value,
    });
  }

  handlebankselect(value) {
    const { bankdropdown } = this.props;
    this.props.getFormDetails({
      props: ["bankdropdown"],
      value: !bankdropdown,
    });
    this.props.getFormDetails({
      props: ["bankId"],
      value: value.id,
    });
    this.props.getFormDetails({
      props: ["selectedbank"],
      value: value.name,
    });
  }

  render() {
    const {
      BVN,
      bvnErr,
      allBanks,
      toggleDropdown,
      selectedbank,
      bankId,
      bankdropdown,
      upgradingAccount,
      passportImg,
      passportImg1,
      idCard,
      idCard1,
      CountryCode,
      bvnFirstName,
      bvnLastName,
      bvnMiddleName,
      bvnAccountNumber,
    } = this.props;

    let bvnData = {
      bvn: BVN,
      firstName: bvnFirstName,
      middleName: bvnMiddleName,
      lastName: bvnLastName,
      bankId: bankId,
      accountNumber: bvnAccountNumber,
    };
    let data = {
      IdentityCardImage: idCard,
      PassportImage: passportImg,
      BvnNumber: BVN,
      CountryCode: CountryCode.code,
    };
    return (
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="card card-small mb-4  p-4">
            {/* <div className="card-header">
                            <p className='mb-0'> Verify your wicrypt account using your BVN (Bank Verification Number). </p>
                            <p style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}> *Disclaimer: Your BVN does not give us access to your bank account or bank</p>
                        </div> */}
            <div className="col-lg-12 uploads">
              <div className="col-lg-6 mb-4">
                <p className="mb-0 t-12 bold text-black"> Passport Photo</p>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) =>
                    this.props.uploadFile({
                      props: ["passportImg"],
                      value: e.target.files[0],
                    })
                  }
                  className="inputfile"
                />
                <label for="file">
                  {passportImg1 !== "" ? (
                    <div className="previewImg">
                      <img
                        className="businessImg"
                        id="Img1"
                        src={passportImg1}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="mt-4"> Upload a passport</p>
                    </div>
                  )}
                </label>
              </div>

              <div className="col-lg-6 mb-4">
                <p className="mb-0 t-12 bold text-black"> ID Card</p>

                <input
                  type="file"
                  name="idCard"
                  id="idCard"
                  onChange={(e) =>
                    this.props.uploadFile({
                      props: ["idCard"],
                      value: e.target.files[0],
                    })
                  }
                  className="inputfile"
                />
                <label for="idCard">
                  {idCard1 !== "" ? (
                    <div className="previewImg">
                      <img
                        className="businessImg"
                        id="Img1"
                        src={idCard1}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      <p className="mt-4"> Upload a valid ID</p>
                    </div>
                  )}
                </label>
              </div>
            </div>
            {bvnErr === 2 ? (
              <p className="errorMsgs">
                <i className="material-icons">info</i>Please Upload a valid
                passport
              </p>
            ) : (
              ""
            )}
            {bvnErr === 1 ? (
              <p className="errorMsgs">
                <i className="material-icons">info</i>Please Upload a valid ID
              </p>
            ) : (
              ""
            )}

            {/* <label> Country </label>
                        <select className="loginCred mb-2 mt-2 form-control" style={{ width: 'auto' }}
                            onChange={(e) => this.props.getFormDetails({ props: ['CountryCode'], value: e.target.value })}>
                            {countries.map(each => {
                                return (<option value={each.code}>{each.name}</option>

                                )
                            })}

                        </select> */}
            <SelectCountry
              title={"Select country"}
              onOptionSelect={this.handlecountryselect.bind(this)}
              name={"selectcountry"}
              value={CountryCode.name}
              disabled={false}
              err={
                bvnErr === 3 ? (
                  <p className="errorMsgs">
                    <i className="material-icons">info</i>Select your country of
                    residence
                  </p>
                ) : (
                  ""
                )
              }
              options={countries}
              toggleDropdown={toggleDropdown}
              onDropdownReveal={() =>
                this.props.getFormDetails({
                  props: ["toggleDropdown"],
                  value: !toggleDropdown,
                })
              }
            />

            {CountryCode.code === "NG" ? (
              <div>
                <LayeredInput
                  title={"BVN"}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["BVN"],
                      value: e.target.value,
                    })
                  }
                  description={
                    "Your BVN does not give us access to your bank account or transactions."
                  }
                  placeholder={"Enter your BVN here"}
                  type={"number"}
                  value={BVN}
                  err={
                    bvnErr === 4 ? (
                      <React.Fragment>
                        <i className="material-icons">info</i>BVN is required
                      </React.Fragment>
                    ) : (
                      ""
                    )
                  }
                  maxlength={11}
                  disabled={false}
                  loading={false}
                />
                <div className="row">
                  <div className="col-lg-6">
                    <LayeredInput
                      title={"First Name"}
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["bvnFirstName"],
                          value: e.target.value,
                        })
                      }
                      description={""}
                      placeholder={"Enter your first name here"}
                      type={"text"}
                      value={bvnFirstName}
                      err={
                        bvnErr === 5 ? (
                          <React.Fragment>
                            <i className="material-icons">info</i>First name is
                            required
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      }
                      disabled={false}
                      loading={false}
                    />
                  </div>
                  <div className="col-lg-6">
                    <LayeredInput
                      title={"Last Name"}
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["bvnLastName"],
                          value: e.target.value,
                        })
                      }
                      description={""}
                      placeholder={"Enter your last name here"}
                      type={"text"}
                      value={bvnLastName}
                      err={
                        bvnErr === 6 ? (
                          <React.Fragment>
                            <i className="material-icons">info</i>Last name is
                            required
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      }
                      disabled={false}
                      loading={false}
                    />
                  </div>
                </div>
                {/* <label>Middle Name</label>
                        <div className="input-group mb-2">

                            <input type="text"
                                className="form-control loginCred mb3"
                                aria-describedby="basic-addon1"
                                value={bvnMiddleName}
                                onChange={(e) => this.props.getFormDetails({ props: ['bvnMiddleName'], value: e.target.value })}
                            />
                        </div> */}
                <div className="row">
                  <div className="col-lg-6">
                    <AddSelect
                      title={"Bank name"}
                      name={"bankaccount"}
                      value={
                        <div>
                          <OptionSubTitle> {selectedbank}</OptionSubTitle>
                        </div>
                      }
                      options={allBanks}
                      err={
                        bvnErr === 7 ? (
                          <React.Fragment>
                            <i className="material-icons">info</i>Please select
                            a bank
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      }
                      onDropdownReveal={() =>
                        this.props.getFormDetails({
                          props: ["bankdropdown"],
                          value: !bankdropdown,
                        })
                      }
                      toggleDropdown={bankdropdown}
                      onOptionSelect={this.handlebankselect.bind(this)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <LayeredInput
                      title={"Acccount Number"}
                      onChange={(e) =>
                        this.props.getFormDetails({
                          props: ["bvnAccountNumber"],
                          value: e.target.value,
                        })
                      }
                      description={""}
                      placeholder={"Enter your account number here"}
                      type={"number"}
                      value={bvnAccountNumber}
                      err={
                        bvnErr === 8 ? (
                          <React.Fragment>
                            <i className="material-icons">info</i>Account number
                            is required
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      }
                      disabled={false}
                      loading={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {["", 1, 2, 3, 4, 5, 6, 7, 8].includes(bvnErr) ? (
              ""
            ) : (
              <p className="errorMsgs">
                <i className="material-icons">info</i>
                {bvnErr}
              </p>
            )}
            <div className="loginBtn mt-5">
              <button
                onClick={() => this.props.resolveBvn(bvnData, data)}
                className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin"
              >
                {upgradingAccount ? <Loader /> : "Verify Account"}
              </button>
            </div>
            <p className="t-8 t-center mt-3 mb-0">
              Your verification will be completed within{" "}
              <span className="bold">24hours </span>of submission.
            </p>
            {/* <div className='loginBtn mt-5'>
                            <button onClick={() => this.props.verifyAcct(data)}
                                className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin">
                                {upgradingAccount ? <Loader /> : 'Verify Account'}
                            </button>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    BVN,
    once,
    bvnErr,
    accountname,
    passportImg1,
    idCard,
    idCard1,
    passportImg,
    CountryCode,
    bvnFirstName,
    bvnLastName,
    bvnMiddleName,
    bankdropdown,
    bvnAccountNumber,
    bankId,
    selectedbank,
    toggleDropdown,
  } = state.User;
  const { resolvingBvn, upgradingAccount } = state.Loading;
  const { allBanks } = state.Manager;

  return {
    BVN,
    once,
    resolvingBvn,
    bvnErr,
    accountname,
    upgradingAccount,
    passportImg1,
    idCard,
    idCard1,
    passportImg,
    CountryCode,
    bvnFirstName,
    bvnLastName,
    bvnMiddleName,
    bvnAccountNumber,
    allBanks,
    bankId,
    bankdropdown,
    selectedbank,
    toggleDropdown,
  };
};

export default connect(MapStateToProps, {
  resolveBvn,
  getFormDetails,
  verifyAcct,
  uploadFile,
  getBanks,
})(UpgradeAcct);
