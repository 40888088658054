import React from "react";
import { getWithdrawal, getFormDetails } from "../../Redux/actions";
import { useSelector } from "react-redux";
import moment from "moment";
import { numberWithCommas, pagination } from "../../Helper";
import DualDatePicker from "../Reusables/cards/DualDatePicker";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { allBusinessesForOperations } from "./../../Redux/actions/MerchantAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const WithdrawalTable = () => {
  const {
    totalWithdrawals,
    withdrawalCurrentPage,
    withdrawalTotalPages,
  } = useSelector((state) => state.Sessions);
  const { loadingWithdrawals } = useSelector((state) => state.Loading);
  const { allBusiness } = useSelector((state) => state.Manager);
  const {
    withdrawaldateEnd,
    withdrawaldateStart,
    withdrawalStatus,
    paymentGateway,
    withdrawalbusinessId,
  } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  useEffect(() => {
    getWithdrawal(
      withdrawalbusinessId,
      withdrawaldateStart,
      withdrawaldateEnd,
      withdrawalStatus,
      paymentGateway,
      1,
      50,
      dispatch
    );
    allBusinessesForOperations("", "", "", dispatch);
  }, [
    withdrawaldateEnd,
    withdrawaldateStart,
    withdrawalStatus,
    paymentGateway,
    withdrawalbusinessId,
  ]);

  const customPagination = () => {
    var pageButtons = pagination(
      withdrawalCurrentPage,
      withdrawalTotalPages,
      5
    );
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            getWithdrawal(
              withdrawalbusinessId,
              withdrawaldateStart,
              withdrawaldateEnd,
              withdrawalStatus,
              paymentGateway,
              withdrawalCurrentPage - 1,
              50,
              dispatch
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === withdrawalCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              getWithdrawal(
                withdrawalbusinessId,
                withdrawaldateStart,
                withdrawaldateEnd,
                withdrawalStatus,
                paymentGateway,
                pageButtons[i],
                50,
                dispatch
              )
            }
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            dispatch(
              getWithdrawal(
                withdrawalbusinessId,
                withdrawaldateStart,
                withdrawaldateEnd,
                withdrawalStatus,
                paymentGateway,
                withdrawalCurrentPage + 1,
                50,
                dispatch
              )
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  };

  let allRows = [];
  totalWithdrawals.map((withdrawal) => {
    return allRows.push({
      date: moment(withdrawal.createdAt).format("YYYY-MM-DD"),
      fullName:
        withdrawal.user !== null
          ? withdrawal.user.name
          : withdrawal.business.name,
      // address:
      //   withdrawal.user !== null
      //     ? withdrawal.user.email
      //     : withdrawal.business.officeAddress,
      paymentGateway:
        (withdrawal.flutterwaveTransfers == null) &
        (withdrawal.paystackTransfers == null)
          ? ""
          : withdrawal.paystackTransfers == null
          ? "Flutterwave"
          : "Paystack",
      accountNumber:
        (withdrawal.flutterwaveTransfers == null) &
        (withdrawal.paystackTransfers == null)
          ? ""
          : withdrawal.flutterwaveTransfers !== null
          ? withdrawal.flutterwaveTransfers.accountNumber
          : withdrawal.paystackTransfers.accountNumber,
      bankName:
        (withdrawal.flutterwaveTransfers == null) &
        (withdrawal.paystackTransfers == null)
          ? ""
          : withdrawal.flutterwaveTransfers !== null
          ? withdrawal.flutterwaveTransfers.bankName
          : withdrawal.paystackTransfers.bankName,
      amount: "NGN " + numberWithCommas(withdrawal.amount),
      paymentGateWayFee:
        (withdrawal.flutterwaveTransfers == null) &
        (withdrawal.paystackTransfers == null)
          ? ""
          : withdrawal.flutterwaveTransfers !== null
          ? "NGN " + withdrawal.flutterwaveTransfers.paymentGatewayFee
          : "NGN " + withdrawal.paystackTransfers.paymentGatewayFee,
      transactionFee: "NGN " + withdrawal.transactionFee,
      status: (
        <button
          type="button"
          className={`btn button ${withdrawal.transferStatus}`}
        >
          {withdrawal.transferStatus}
        </button>
      ),
    });
  });

  const data = {
    columns: [
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "fullName",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Address",
      //   field: "address",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Payment Gateway",
        field: "paymentGateway",
        sort: "asc",
        width: 100,
      },
      {
        label: "Account Number",
        field: "accountNumber",
        sort: "asc",
        width: 100,
      },
      {
        label: "Bank Name",
        field: "bankName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Payment GateWay Fee",
        field: "paymentGateWayFee",
        sort: "asc",
        width: 100,
      },
      {
        label: "Transaction Fee",
        field: "transactionFee",
        sort: "asc",
        width: 100,
      },
      {
        label: "Withdrawal Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
    ],

    rows: allRows,
  };

  return (
    <div className="col-lg-12 mb-4">
      <div className="card-header mb-3" style={{ width: "100%" }}>
        <div
          className="mt-3 dateRange flex"
          style={{ display: "flex", alignItems: "center" }}
        >
          <select
            className="input-sm form-control m-10"
            style={{ width: "auto" }}
            onChange={(e) =>
              dispatch(
                getFormDetails({
                  props: ["withdrawalStatus"],
                  value: e.target.value,
                })
              )
            }
          >
            <option value={""}>Select Withdrawal Status</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Approved"}> Approved</option>
            <option value={"Failed"}> Failed</option>
            <option value={" AwaitingResponseFromPaymentGateway"}>
              {" "}
              Awaiting Response
            </option>
          </select>
          <select
            className="input-sm form-control m-10"
            style={{ width: "auto" }}
            onChange={(e) =>
              dispatch(
                getFormDetails({
                  props: ["paymentGateway"],
                  value: e.target.value,
                })
              )
            }
          >
            <option value={""}>Select Payment Gateway</option>
            <option value={"Flutterwave"}>Flutterwave</option>
            <option value={"Paystack"}> Paystack</option>
          </select>
        </div>
      </div>

      <CustomDataTable
        data={data}
        pagination={customPagination()}
        // placeholder="Search name"
        loading={loadingWithdrawals}
        customright={
          <div className="d-flex">
            <select
              className="selectbox"
              onChange={(e) =>
                dispatch(
                  getFormDetails({
                    props: ["withdrawalbusinessId"],
                    value: e.target.value,
                  })
                )
              }
            >
              <option>Select Business</option>
              {allBusiness.length > 0
                ? allBusiness.map((each) => {
                    return <option value={each.id}>{each.name}</option>;
                  })
                : ""}
            </select>
            <DualDatePicker
              dateTo={withdrawaldateEnd}
              dateFrom={withdrawaldateStart}
              dateFromLabel="withdrawaldateStart"
              dateToLabel="withdrawaldateEnd"
            />
          </div>
        }
        noData={
          <p className="voucherText t-center">
            {" "}
            No withdrawal report for the selected specs
          </p>
        }
      />
    </div>
  );
};

export default WithdrawalTable;
