import React from "react";
import { getFormDetails } from "../../../Redux/actions";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

const DualDatePicker = props => {
  const { dateTo, dateFrom, dateFromLabel, dateToLabel } = props;

  return (
    <div className="datepickerStyle">
      <label className="inline-flex p-r">
        <div className="t-12 p-8"> From:</div>
        <DatePicker
          className="dateRange"
          selected={dateFrom}
          onChange={date =>
            props.getFormDetails({
              props: dateFromLabel,
              value: date.getTime()
            })
          }
          dateFormat={"dd/MM/yyyy"}
        />
        <i className="material-icons text-orange carlendarIcon">
          carlendar_today
        </i>
      </label>
      <label className="inline-flex p-r">
        <div className="t-12 p-8"> To:</div>
        <DatePicker
          className="dateRange"
          selected={dateTo}
          onChange={date =>
            props.getFormDetails({
              props: dateToLabel,
              value: date.getTime()
            })
          }
          dateFormat={"dd/MM/yyyy"}
        />
        <i className="material-icons text-orange carlendarIcon">
          carlendar_today
        </i>
      </label>
    </div>
  );
};

const MapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  MapStateToProps,
  { getFormDetails }
)(DualDatePicker);
