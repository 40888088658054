import React, { Component } from "react";
// import RegCard from "../Reusables/cards/RegCard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BreadCrumbs from "../Partials/Breadcrumbs";
import SuccessCard from "../Reusables/cards/SuccessCard"

class Register extends Component {
  componentDidMount() {
    if (this.props.auth) {
      this.timeoutHandle = setTimeout(()=>{
        this.props.history.push("/user/dashboard");
   }, 2000);
      
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timeoutHandle);
}

  render() {
    return (
      <div className="row background">
        <main className="main-content col-lg-12 col-md-12 col-sm-12">      
          <div className="main-content-container container-fluid px-4">
            <BreadCrumbs />    
            <div className="row mt-5">
              <SuccessCard msg={'Account Created Successfully'}/>
              {/* <RegCard heading="Create PIN" /> */}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { auth } = state.User;

  return {
    auth,
  };
};
export default withRouter(
  connect(
    MapStateToProps,
    {}
  )(Register)
);
