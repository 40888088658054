import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

//Getting Referals on Referal Code Input
export const getMarketers = (code) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_MARKETERS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `/v2/users/referrals/code/${code}?pageSize=${500}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_MARKETERS,
          payload: false,
        });
        dispatch({
          type: _const.GET_MARKETERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_MARKETERS,
          payload: false,
        });
        dispatch({
          type: _const.GETTING_MARKETERS_FAILED,
          payload: "The referral code does not exist",
        });
      });
  };
};

//Setting Referal Code to the reducer
export const referalupdate = (ref) => {
  return (dispatch) => {
    dispatch({
      type: _const.UPDATE_REFERAL_CODE,
      payload: ref,
    });
  };
};
