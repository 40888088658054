import styled from "styled-components";
import { theme } from "../../theme";


export const ControlContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`
export const ControlList = styled.div`
    margin-bottom: 20px;

`
export const OperationName = styled.p`
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${theme.textSm};
    margin-bottom: 0px;
`
export const Data = styled.p`
    margin-bottom: 0px;
`
export const DataValue = styled.span`
    font-weight: ${theme.textBold};
    color: ${theme.blue};
`

export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 493px;
  height: 50px;
  background: #25346a;
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  outline: none;
  border: none;
`;
export const LogList = styled.li`
  list-style: disc;
  border-left: 1px solid #5a6169;
  padding-left: 10px;
  margin-bottom: 20px;
`
export const LogDetails = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
`
export const LogStep = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props =>  props.theme.black};
`

export const SpendInput = styled.input`
  text-align: center;
  border: none;
`