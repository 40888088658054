import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpendControl, getUsersSpendControl, getGlobalSpendControlActivityLog, createSpendControl } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { SpendInput } from "./style";
import Loader from "../Reusables/loaders/MoonLoader";



const GlobalSpendControlData = () => {

    const dispatch = useDispatch();
    const { globalSpendControl } = useSelector((state) => state.SpendControl);
    const { creatingglobalSpendControl, creatingGlobalSpend } = useSelector((state) => state.Loading);
    const { role } = useSelector((state) => state.User);
    const [customData, setCustom] = useState([]);


    const handleChange = (spendData, value, key) => {
        const foundIndex = customData.findIndex((data) => data.operation === spendData.operation && data.currency === spendData.currency);

        if (foundIndex !== -1) {
            const newData = [...customData];
            newData[foundIndex] = {
                ...newData[foundIndex],
                [key]: Number(value)
            };
            setCustom(newData);
        } else {
            const { id, ...rest } = spendData;
            setCustom([...customData, { ...rest, [key]: Number(value) }]);
        }
    };

    const handleSubmit = () => { setCustom([]); dispatch(createSpendControl(customData)) }

    const setDisabled = () =>{
        if(role.includes("Admin") || role.includes("SuperAdmin") ){
            return false
        }

        return true;
    }


    let allRows = [];
    globalSpendControl.map((each) => {
        return allRows.push({
            operation: <div> <p> {each.operation}</p></div>,
            currency: each.currency,
            singleTransactionVolume: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "singleTransactionVolume")}
                defaultValue={each.singleTransactionVolume} />,
            //numberWithCommaswithoutdecimals(each.singleTransactionVolume) + " USD",
            dailyCumulativeVolume: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "dailyCumulativeVolume")}
                defaultValue={each.dailyCumulativeVolume} />,
            //numberWithCommaswithoutdecimals(each.dailyCumulativeVolume) + " USD",
            cumulativeVolume7days: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "cumulativeVolume7days")}
                defaultValue={each.cumulativeVolume7days} />,
            //numberWithCommaswithoutdecimals(each.cumulativeVolume7days) + " USD",
            maxDailyTransactionCount: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "maxDailyTransactionCount")}
                defaultValue={each.maxDailyTransactionCount} />,
            //numberWithCommaswithoutdecimals(each.maxDailyTransactionCount),
            maxTransactionCount7Days: <SpendInput disabled={setDisabled()} type="number"
                onChange={(e) => handleChange(each, e.target.value, "maxTransactionCount7Days")}
                defaultValue={each.maxTransactionCount7Days} />,
        });
    });

    console.log(customData, 'i am the cutom data')

    useEffect(() => {
        dispatch(getSpendControl());
        dispatch(getUsersSpendControl());
        dispatch(getGlobalSpendControlActivityLog());
    }, []);

    const data = {
        columns: [
            {
                label: "Operation",
                field: "operation",
                sort: "asc",
                width: 170,
            },
            {
                label: "Currency",
                field: "currency",
                sort: "asc",
                width: 170,
            },
            {
                label: "Single Transaction Volume (USD)",
                field: "singleTransactionVolume",
                sort: "asc",
                width: 170,
            },
            {
                label: "Daily Cummulative Volume (USD)",
                field: "dailyCumulativeVolume",
                sort: "asc",
                width: 170,
            },
            {
                label: "Weekly Cummulative Volume (USD)",
                field: "cumulativeVolume7days",
                sort: "asc",
                width: 170,
            },
            {
                label: "Max Daily Transaction Count",
                field: "maxDailyTransactionCount",
                sort: "asc",
                width: 170,
            },
            {
                label: "Weekly Max Transaction Count",
                field: "maxTransactionCount7Days",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    return (
        <div className="mb-4 mt-5">
            <div className="row">
                <div className="col-lg-12 p-3 mb-5">
                    <CustomDataTable
                        data={data}
                        title="Global Spend Control"
                        loading={creatingglobalSpendControl}
                        customleft={<div />}
                        customright={customData.length > 0  || creatingGlobalSpend ? <div className="details t-center"
                            onClick={() => handleSubmit()}
                        >
                            {creatingGlobalSpend ? <Loader color="#fefe"/> : "Save"}
                        </div> : <div />}
                    // buttontitle ={"Add Global Spend Control"}
                    // onClick= {() =>dispatch(handleModal(55))}
                    />
                </div>
            </div>

        </div>
    );
};

export default GlobalSpendControlData;
