import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import OneVerification from "./OneVerification";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import { Link } from "react-router-dom";

const AcctVerificationDetail = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link to="/wicryptusers">
                {" "}
                <i className="material-icons">arrow_back</i> Verification
                Details{" "}
              </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <OneVerification />
        </div>
      </MainContent>
    </div>
  );
};

export default AcctVerificationDetail;
