import React from "react";
import { UserAvatar } from "../User/profilestyles";
import { WicryptPattern } from "../Wallets/walletstyles";
import { DataKey, DataValue, ProfileDetails } from "./styles";

function Profile({ userProfile }) {
  return (
    <div className="main-content-container container-fluid px-4 mt-5">
      <div className="row">
        <div className="card col-lg-12 w3-animate-bottom">
          <div
            className="crd-body d-flex p-4"
            style={{ flexWrap: "wrap", gap: "20px" }}
          >
            <div className=" col-lg-4 pt-4 ">
              <UserAvatar
                alt="user avatar"
                src={
                  userProfile.profileImageUrl ||
                  "/Assets/images/avatars/user_icon.svg"
                }
              />
            </div>
            <div className=" col-lg-6">
              <ProfileDetails>
                <DataKey>Name</DataKey>
                <DataValue>{userProfile.name}</DataValue>
              </ProfileDetails>
              <ProfileDetails>
                <DataKey>Email</DataKey>
                <DataValue>{userProfile.email}</DataValue>
              </ProfileDetails>
              <ProfileDetails>
                <DataKey>Account Type</DataKey>
                <DataValue>{userProfile.accountType}</DataValue>
              </ProfileDetails>
              <ProfileDetails>
                <DataKey>Referral Code</DataKey>
                <DataValue> {userProfile.referralCode}</DataValue>
              </ProfileDetails>
              {/* <ProfileDetails>
                <DataKey>Billing Type</DataKey>
                <DataValue>{userProfile.billingType}</DataValue>
              </ProfileDetails> */}
              {/* <ProfileDetails>
                <div>
                  <button
                    // onClick={() => this.props.toggleModal(true, "", "approve")}
                    className="btn button accept"
                  >
                    Unblock User
                  </button>
                </div>
              </ProfileDetails> */}
            </div>
            <WicryptPattern
              src="/Assets/cardicons/wicryptpattern.svg"
              alt="wicryptpattern"
              style={{ width: "20%", height: "50%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
