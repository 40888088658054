import React, { useEffect } from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import UserStats from "./userStats";
import { useDispatch, useSelector } from "react-redux";
import { clearSearch, getDevices, getManager } from "../../Redux/actions";
// import Onboarding from "../Onboarding"
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import {
  getMiningStatistics,
  getSessionsStatistics,
  getTotalAdminUsers,
  getTotalNumberOfDevices,
  getTotalUsers,
} from "../../Redux/actions/AdminDevicesAction";
import LatestRequests from "./LatestRequest";
import LatestDevices from "./LatestDevices";
import { SimpleSelect } from "../Reusables/selects/SimpleSelect";
import { getSearchDetails } from "../../Redux/actions/SearchAction";

const statOptions = [
  {
    id: 1,
    option: "All",
    value: "",
  },
  {
    id: 2,
    option: "Last Week",
    value: "LastWeek",
  },
  {
    id: 3,
    option: "Last Month",
    value: "LastMonth",
  },
  {
    id: 4,
    option: "Last 5 Months",
    value: "Last5Months",
  },
  {
    id: 5,
    option: "Last Year",
    value: "LastYear",
  },
];

const UserDashboard = () => {
  // const { devices } = useSelector((state) => state.Manager);
  // const [pieLabels, setPieLabels] = useState([]);
  // const [sessionData, setSessionData] = useState([]);
  // const [revenueData, setRevenueData] = useState([]);
  // const [usageData, setUsageData] = useState([]);
  const dispatch = useDispatch();
  const { duration } = useSelector((state) => state.Search);
  const { role } = useSelector((state) => state.User);

  useEffect(() => {
    getDevices("");
    getManager();
    // setPieLabels(devices);
    // setSessionData(devices.sessionscount);
    // setRevenueData(devices.totalRevenue);
    // setUsageData(devices.totalUsage);
    getTotalNumberOfDevices(dispatch, duration);
    getSessionsStatistics(dispatch, duration);
    getMiningStatistics(dispatch, duration);
    getTotalUsers(dispatch, duration);
    getTotalAdminUsers(dispatch, duration);
  }, [duration]);

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  // var totalSessions =
  //   sessionData &&
  //   sessionData.reduce(function(a, b) {
  //     return a + b;
  //   }, 0);
  return (
    <div className="row">
      {/* <Onboarding /> */}
      {/* <Modal /> */}
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Dashboard" />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <div
            className="row flex items-right justify-end"
            style={{ paddingRight: "30px" }}
          >
            <SimpleSelect
              options={statOptions}
              onSelect={(val, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: val,
                  })
                );
              }}
            />
          </div>
          <UserStats />
          {/* <div className = 'row'>
                    <DeviceStats title = 'Sessions per Device' devices={this.props.devices}  labels = {pieLabels} data= {sessionData}/>
                    <DeviceStats title = 'Usage per Device' devices={this.props.devices}  labels = {pieLabels} data= {usageData} usage = {'GB'}/>
                    <DeviceStats title = 'Revenue per Device' devices={this.props.devices}  labels = {pieLabels} data= {revenueData} rev = {'$'}/>
                </div> */}

          <div className="row" style={{ marginBottom: "50px" }}>
            {!role.includes('Technical') ?  <LatestRequests /> : null }
            <LatestDevices />
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default UserDashboard;
