import React, { Component } from "react";
import {
  getFormDetails,
  dynamicFormReport,
  clearResponse,
  allDynamicForm,
  toggleModal,
} from "../../Redux/actions";
import { connect } from "react-redux";
import Loader from "../Reusables/loaders/MoonLoader";
import InfiniteScroll from "../Reusables/cards/LoadMore";
import { MDBDataTable } from "mdbreact";
import Modal from "../Reusables/modals/Modal";



class AllForms extends Component {
  componentDidMount() {
    if (this.props.allDynamicFormReport.length === 0) {
      this.props.dynamicFormReport(this.props.formId, 1, 50);
    }
    if (this.props.allForms.length === 0) {
      this.props.allDynamicForm("", 1, 50);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.formId !== nextProps.formId) {
      this.props.clearResponse();
      this.props.dynamicFormReport(nextProps.formId, 1, 50);
    }
  }

  fetchMoreData = () => {
    const {
      allDynamicFormsReportCurrentPage,
      allDynamicFormsReportTotalPages,
      formId,
    } = this.props;

    let page = allDynamicFormsReportCurrentPage;
    if (page < allDynamicFormsReportTotalPages) {
      page++;
    }

    this.props.dynamicFormReport(formId, page, 50);
  };

  render() {
    const {
      gettingDynamicFormsReport,
      allDynamicFormReport,
      reportformNo,
      allDynamicFormsReportTotalRecords,
      allForms,
      clickedDeviceId,
      responseformat
    } = this.props;

    let allRows = [];
    allDynamicFormReport.map((response, i) => {
      return allRows.push({
        sn: i + 1,
        code: response.formCode,
        status: (
          <b
            className={
              response.entityStatus === "Exhausted"
                ? "text-red"
                : response.entityStatus === "INACTIVE"
                  ? "text-orange"
                  : "text-green"
            }
          >
            {" "}
            {response.entityStatus}{" "}
          </b>
        ),
        formresponse: (
          <button
            className="details mr-3"
            onClick={() => this.props.toggleModal(true, i)}
          >
            {" "}
            View Response{" "}
          </button>
        ),
      });
    });

    const data = {
      columns: [
        {
          label: "S/N",
          field: "sn",
          sort: "asc",
          width: 170,
        },
        {
          label: "Form Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Form Status",
          field: "status",
          sort: "asc",
          width: 170,
        },
        {
          label: "Response",
          field: "formresponse",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };
    let clickedresponse =
      allDynamicFormReport.length > 0
        ? allDynamicFormReport.find((response, i) => {
          return i === clickedDeviceId;
        })
        : "";
    return (
      <div className="col-lg-12 mb-4">
        <Modal heading={`Form Response`}>
          {clickedresponse === "" || clickedresponse === undefined
            ? ""
            : clickedresponse.fields.map((eachField, i) => {
              return (
                <div className="col-lg-12" key={i}>
                  <label>{eachField.fieldName}</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control loginCred"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={eachField.fieldText}
                      disbled={true}
                    />
                  </div>
                </div>
              );
            })}
        </Modal>
        <div className="card-header mb-3 d-flex" style={{ width: "100%" }}>
          <div>
            {/* <p className="trackers"> Response Report </p> */}
          </div>
          <div className="mt-3 row">
            <select
              className="input-sm form-control"
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["formId"],
                  value: e.target.value,
                })
              }
            >
              <option value="">Select Form</option>
              {allForms.length > 0
                ? allForms.map((each) => {
                  return <option value={each.id}>{each.formCode}</option>;
                })
                : ""}
            </select>
          </div>
        </div>
        <div className="t-center mb-5 mt-3">
          <button
            className={responseformat === 0 ? "btn  ml-3 button PendingList" : "btn ml-3 button Neutral"}
            onClick={() => this.props.getFormDetails({
              props: ["responseformat"],
              value: 0,
            })}
          >
            {" "}
           List View{" "}
          </button><button
            className={responseformat === 1 ? "btn  ml-3 button PendingList" : "btn ml-3 button Neutral"}
            onClick={() => this.props.getFormDetails({
              props: ["responseformat"],
              value: 1,
            })}
          >
            {" "}
            Individual Responses{" "}
          </button>

        </div>
        {gettingDynamicFormsReport ? (
          <div className="volucherNote mt-5 t-center">
            <Loader size={10} />{" "}
          </div>
        ) : !gettingDynamicFormsReport & (allDynamicFormReport.length === 0) ? (
          <p className="voucherText t-center"> No response found </p>
        ) : (
              ""
            )}
        {responseformat === 0 ?
          allDynamicFormReport.length !== 0 ? (
            <div className="col-lg-10 offset-lg-1 mb-4">
              <div className="card card-small mb-4 dataTable">
                <h5 className="bold t-center">
                  {" "}
                  {allDynamicFormsReportTotalRecords} Form Response
                {allDynamicFormsReportTotalRecords > 1 ? "s" : ""}!
                </h5>
                {/* <div className="t-right mb-3 mt-3">
                  <ReactHTMLTableToExcel
                    className="btn btn-info"
                    table="emp"
                    filename="Form responses"
                    sheet="Sheet"
                    buttonText="Export excel"
                  />
                </div> */}
                <InfiniteScroll
                  dataLength={allDynamicFormReport.length}
                  hasMore={
                    allDynamicFormReport.length <
                      allDynamicFormsReportTotalRecords
                      ? true
                      : false
                  }
                  items={
                    <div className="card card-small mb-4 dataTable" >
                      <MDBDataTable
                        striped
                        hover
                        data={data}
                        searchLabel="Search"
                        responsive
                        id="emp"
                      />
                    </div>
                  }
                  moreData={this.fetchMoreData}
                />
              </div>
            </div>
          ) : (
              ""
            ) :
          <InfiniteScroll
            dataLength={allDynamicFormReport.length}
            hasMore={
              allDynamicFormReport.length < allDynamicFormsReportTotalRecords
                ? true
                : false
            }
            items={allDynamicFormReport.map((eachReport, i) => {
              return (
                <div className="offset-lg-3 col-lg-6" key={i}>
                  {reportformNo === i ? (
                    <div className="card card-small mb-4">
                      <div className="preview">
                        <div className="mt-5 mb-5">
                          <div className="t-center">
                            <h5 className="bold">
                              {" "}
                              {allDynamicFormsReportTotalRecords} Form Response
                            {allDynamicFormsReportTotalRecords > 1 ? "s" : ""}!
                          </h5>
                            <p className="t-12 p-2">
                              {" "}
                            Response for form with form code -{" "}
                              <span className="bold"> {eachReport.formCode}</span>
                            </p>
                          </div>
                          {eachReport.fields.map((eachField, i) => {
                            return (
                              <div className="col-lg-12" key={i}>
                                <label>{eachField.fieldName}</label>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className="form-control loginCred"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={eachField.fieldText}
                                    disbled={true}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="card-header d-flex">
                          <h6
                            onClick={() =>
                              reportformNo > 0
                                ? this.props.getFormDetails({
                                  props: ["reportformNo"],
                                  value: reportformNo - 1,
                                })
                                : ""
                            }
                            className={
                              reportformNo > 0
                                ? "m-0 bold pointer"
                                : "grey-text m-0 bold"
                            }
                          >
                            Prev
                        </h6>
                          <h6
                            onClick={() =>
                              reportformNo < allDynamicFormsReportTotalRecords - 1
                                ? this.props.getFormDetails({
                                  props: ["reportformNo"],
                                  value: reportformNo + 1,
                                })
                                : ""
                            }
                            className={
                              reportformNo < allDynamicFormsReportTotalRecords - 1
                                ? "m-0 bold pointer"
                                : "grey-text m-0 bold"
                            }
                          >
                            Next
                        </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              );
            })}
          />}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    allDynamicFormReport,
    allDynamicFormsReportCurrentPage,
    allDynamicFormsReportTotalRecords,
    allDynamicFormsReportTotalPages,
    allForms,
  } = state.DynamicForm;
  const { gettingDynamicFormsReport } = state.Loading;
  const { reportformNo, formId, clickedDeviceId, responseformat } = state.User;
  return {
    gettingDynamicFormsReport,
    allDynamicFormReport,
    allDynamicFormsReportCurrentPage,
    allDynamicFormsReportTotalRecords,
    allDynamicFormsReportTotalPages,
    reportformNo,
    formId,
    allForms,
    clickedDeviceId,
    responseformat
  };
};

export default connect(
  MapStateToProps,
  {
    getFormDetails,
    dynamicFormReport,
    clearResponse,
    allDynamicForm,
    toggleModal,
  }
)(AllForms);
