import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  generatebulkvouchers,
  getusergeneratedvouchers,
  toggleModal,
  saveId,
  clearData,
} from "../../Redux/actions";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import moment from "moment";
import { numberWithCommas } from "../../Helper";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CreateBulkVoucher from "./CreateBulkVoucher";

class GenerateBulkVouchers extends Component {
  componentWillMount() {
    const {
      bulkvoucherReceipient,
      bulkvoucherstartDate,
      bulkvoucherStatus,
      bulkvoucherendDate,
      bulkvouchercodeSearch,
    } = this.props;
    this.props.getusergeneratedvouchers(
      bulkvoucherReceipient,
      bulkvoucherstartDate,
      1,
      50,
      bulkvoucherStatus,
      bulkvoucherendDate,
      "",
      bulkvouchercodeSearch
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      bulkvoucherReceipient,
      bulkvoucherstartDate,
      bulkvoucherStatus,
      bulkvoucherendDate,
      bulkvouchercodeSearch,
    } = nextProps;
    if (
      bulkvoucherstartDate !== this.props.bulkvoucherstartDate ||
      bulkvoucherStatus !== this.props.bulkvoucherStatus ||
      bulkvouchercodeSearch !== this.props.bulkvouchercodeSearch ||
      bulkvoucherendDate !== this.props.bulkvoucherendDate ||
      (bulkvoucherReceipient !== this.props.bulkvoucherReceipient) &
        (bulkvoucherReceipient.length === 11)
    ) {
      this.props.getusergeneratedvouchers(
        bulkvoucherReceipient,
        bulkvoucherstartDate,
        1,
        50,
        bulkvoucherStatus,
        bulkvoucherendDate,
        "",
        bulkvouchercodeSearch
      );
    }
  }

  customPagination() {
    const {
      userGeneratedVouchersTotalPages,
      userGeneratedVouchersCurrentPage,
      bulkvoucherReceipient,
      bulkvoucherstartDate,
      bulkvoucherStatus,
      bulkvoucherendDate,
      bulkvouchercodeSearch,
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== userGeneratedVouchersCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getusergeneratedvouchers(
              bulkvoucherReceipient,
              bulkvoucherstartDate,
              userGeneratedVouchersCurrentPage - 1,
              50,
              bulkvoucherStatus,
              bulkvoucherendDate,
              "",
              bulkvouchercodeSearch
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; userGeneratedVouchersTotalPages >= i; i++) {
      if (i === userGeneratedVouchersCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getusergeneratedvouchers(
                bulkvoucherReceipient,
                bulkvoucherstartDate,
                i,
                50,
                bulkvoucherStatus,
                bulkvoucherendDate,
                "",
                bulkvouchercodeSearch
              )
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (userGeneratedVouchersTotalPages > userGeneratedVouchersCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getusergeneratedvouchers(
              bulkvoucherReceipient,
              bulkvoucherstartDate,
              userGeneratedVouchersCurrentPage + 1,
              50,
              bulkvoucherStatus,
              bulkvoucherendDate,
              "",
              bulkvouchercodeSearch
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      userGeneratedVouchers,

      gettinguserGeneratedVouchers,

      bulkvouchercodeSearch,
    } = this.props;

    let allRows = [];
    userGeneratedVouchers.map((voucher) => {
      return allRows.push({
        code: voucher.code,
        batchCode: voucher.batchCode,
        email:
          voucher.owner.username !== "" ? voucher.owner.username : "Unassigned",
        value:
          numberWithCommas(voucher.voucherValue) +
          "WC | " +
          numberWithCommas(voucher.voucherValue * 360) +
          "NGN",
        balance:
          numberWithCommas(voucher.balance) +
          "WC | " +
          numberWithCommas(voucher.balance * 360) +
          "NGN",
        expiry:
          voucher.expiryDate === null
            ? "N/A"
            : moment(voucher.expiryDate).format("MMMM Do YYYY"),
        status: (
          <b
            className={
              voucher.voucherStatus === "Exhausted"
                ? "text-red"
                : voucher.voucherStatus === "Inactive"
                ? "text-orange"
                : "text-green"
            }
          >
            {" "}
            {voucher.voucherStatus}{" "}
          </b>
        ),
      });
    });

    const data = {
      columns: [
        {
          label: "Voucher Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Batch Code",
          field: "batchCode",
          sort: "asc",
          width: 170,
        },
        {
          label: "Owner's email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Value",
          field: "value",
          sort: "asc",
          width: 170,
        },
        {
          label: "Balance",
          field: "balance",
          sort: "asc",
          width: 170,
        },
        {
          label: "Expiry",
          field: "expiry",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <CreateBulkVoucher />
        {/* 
        <div className="col-lg-12">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="d-flex row">
              <h6 className="m-0">VOUCHERS</h6>
              <div>
                {creatingBulkVouchers ? (
                  <Button theme="btn-primary ml-3 mt-2 actionButton wicryptColor">
                    {" "}
                    <Loader />
                  </Button>
                ) : (
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.toggleModal(true)}
                    >
                      {" "}
                    Generate Bulk Vouchers
                    </i>
                  )}
              </div>
            </div>

            <div className="mt-3 d-flex row">
              <div className="dateRange col-lg-12">
                <div className="mr-3"> FROM</div>
                <Datepicker
                  className="dateRange form-control"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["bulkvoucherstartDate"],
                      value: e.getTime(),
                    })
                  }
                  selected={bulkvoucherstartDate}
                  dateFormat={"dd/MM/yyyy"}
                />

                <div className="mr-3 ml-3"> TO</div>
                <Datepicker
                  className="dateRange form-control"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["bulkvoucherendDate"],
                      value: e.getTime(),
                    })
                  }
                  selected={bulkvoucherendDate}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>

              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["bulkvoucherStatus"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Voucher Status</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}> Inactive</option>
                <option value={"Exhausted"}> Exhausted</option>
              </select>
              <div className="fl-right">
                <div className="input-group mb-2">
                  <input
                    type="number"
                    className="form-control input-sm loginCred"
                    aria-describedby="basic-addon1"
                    placeholder="Recepient's Phone"
                    value={bulkvoucherReceipient}
                    onChange={(e) =>
                      this.props.getFormDetails({
                        props: ["bulkvoucherReceipient"],
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-12 mb-4">
          {gettinguserGeneratedVouchers &&
          userGeneratedVouchers.length === 0 ? (
            <CustomLoader />
          ) : (
            <CustomDataTable
              data={data}
              pagination={this.customPagination()}
              placeholder={"Search voucher code"}
              onClick={() => this.props.toggleModal(true)}
              buttontitle={"Generate Vouchers"}
              searchfunction={(e) =>
                this.props.getFormDetails({
                  props: ["bulkvouchercodeSearch"],
                  value: e.target.value,
                })
              }
              searchvalue={bulkvouchercodeSearch}
            />
          )}
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    bulkvoucherReceipient,
    voucherAmt,
    bulkvoucherstartDate,
    bulkvoucherendDate,
    bulkvoucherStatus,
    voucherReceipientEmail,
    voucherValidity,
    voucherMsg,
    businessProfile,
    selectedBusiness,
    voucherQty,
    days,
    bulkvouchercodeSearch,
  } = state.User;
  const { creatingBulkVouchers, gettinguserGeneratedVouchers } = state.Loading;
  const { affiliatedBusiness } = state.Manager;
  const {
    userGeneratedVouchers,
    bulkVoucherError,
    savedBusinessId,
    userGeneratedVouchersCurrentPage,
    userGeneratedVouchersTotalPages,
    userGeneratedVouchersTotalRecords,
  } = state.Voucher;
  return {
    userGeneratedVouchers,
    voucherReceipientEmail,
    affiliatedBusiness,
    voucherQty,
    bulkvoucherReceipient,
    voucherAmt,
    bulkvoucherStatus,
    creatingBulkVouchers,
    gettinguserGeneratedVouchers,
    bulkVoucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    userGeneratedVouchersCurrentPage,
    userGeneratedVouchersTotalPages,
    userGeneratedVouchersTotalRecords,
    bulkvoucherstartDate,
    bulkvoucherendDate,
    selectedBusiness,
    days,
    bulkvouchercodeSearch,
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  generatebulkvouchers,
  getusergeneratedvouchers,
  toggleModal,
  saveId,
  clearData,
})(GenerateBulkVouchers);
