import React from "react";
import { Link } from "react-router-dom";
import {
  getFormDetails,
  toggleNav,
  handleSubNav,
  changeActiveTab,
} from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  SidenavContainer,
  MainLogo,
  SidenavLinks,
  LogoContainer,
  SidenavLink,
  SideIcons,
  SubTitle,
  // AdvertButton,
  Badge,
} from "./partials";
import { clearSearch } from "../../Redux/actions/GeneralAction";
import { changeTransactionType } from "../../Redux/actions/TransactionsAction";
// import Invitation from "../Admins/Modal/Invitation";

const Sidenav = () => {
  const { role, togglesubnav } = useSelector((state) => state.User);
  const { adminNav, userNav, operationsNav, technicalNav, developerNav } = useSelector(
    (state) => state.navLinks
  );
  const { verificationTotalRecords } = useSelector(
    (state) => state.Verification
  );
  const dispatch = useDispatch();

  let viewnav = ["/manager"];
  let nav =
    role.includes("Admin") || role.includes("SuperAdmin")
      ? adminNav
      : role.includes("Operations")
      ? operationsNav
      :role.includes("Technical")
      ? technicalNav
      : role.includes("Developer") 
      ? developerNav
      : userNav;
  return (
    <SidenavContainer>
      <LogoContainer>
        <MainLogo alt="img-logo" src="/Assets/images/logo.svg" />
      </LogoContainer>
      <SidenavLinks>
        {nav.map((link) => {
          return (
            <Link to={`/${link.link}`}>
              <SidenavLink
                key={link.id}
                onClick={() => {
                  dispatch(handleSubNav(1));
                  dispatch(clearSearch(1));
                  dispatch(changeActiveTab(1));
                  dispatch(changeTransactionType("transfer"));
                  dispatch(
                    getFormDetails({
                      props: ["togglesubnav"],
                      value: false,
                    })
                  );
                  dispatch(toggleNav(false));
                }}
                active={window.location.pathname === "/" + link.link}
              >
                <SideIcons
                  src={
                    window.location.pathname === "/" + link.link
                      ? link.activeImage
                      : link.inactiveImage
                  }
                />
                {link.title}
                <SubTitle active={window.location.pathname === "/" + link.link}>
                  {" "}
                  {link.subtitle}
                </SubTitle>
                {link.title === "Users" && (
                  <Badge>{verificationTotalRecords || 0}</Badge>
                )}
              </SidenavLink>
            </Link>
          );
        })}
        {role.includes("Admin") ||
        role.includes("SuperAdmin") ||
        role.includes("Operations") || 
        role.includes("Developer") || 
        role.includes("Technical") ? (
          " "
        ) : (
          <React.Fragment>
            <SidenavLink
              onClick={() => {
                dispatch(
                  getFormDetails({
                    props: ["togglesubnav"],
                    value: !togglesubnav,
                  })
                );
              }}
              active={viewnav.includes(window.location.pathname)}
            >
              <SideIcons
                src={
                  viewnav.includes(window.location.pathname)
                    ? "/Assets/dashboardicons/activeicons/SVG/viewall.svg"
                    : "/Assets/dashboardicons/inactiveicons/SVG/viewall.svg"
                }
              />
              View All
            </SidenavLink>
            {/* <div>
                <AdvertButton
                  className="form-control"
                  onClick={() => dispatch(handleModal(16)}
                >
                  Invite Admin
                </AdvertButton>
              </div> */}
          </React.Fragment>
        )}
        {/* } */}
      </SidenavLinks>
    </SidenavContainer>
  );
};

export default Sidenav;
