import _const from "./Types";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { handleModal } from "./GeneralAction";

// device overview
export const getTotalNumberOfDevices = async (dispatch, duration) => {
  dispatch({
    type: _const.GET_ALL_TOTAL_DEVICES,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/devices/statistics", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GET_ALL_TOTAL_DEVICES,
        payload: false,
      });
      dispatch({
        type: _const.ALL_TOTAL_DEVICES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GET_ALL_TOTAL_DEVICES,
        payload: false,
      });
    });
};
export const getTotalNumberOfDevicesOnce = async (dispatch, duration) => {
  dispatch({
    type: _const.GET_ALL_TOTAL_DEVICES_ONCE,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/devices/statistics", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GET_ALL_TOTAL_DEVICES_ONCE,
        payload: false,
      });
      dispatch({
        type: _const.ALL_TOTAL_DEVICES_ONCE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GET_ALL_TOTAL_DEVICES_ONCE,
        payload: false,
      });
    });
};

export const getSessionsStatistics = async (dispatch, duration) => {
  dispatch({
    type: _const.GETTIND_DEVICE_SESSIONS,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/sessions/statistics", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTIND_DEVICE_SESSIONS,
        payload: false,
      });
      dispatch({
        type: _const.SESSION_STATISTICS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTIND_DEVICE_SESSIONS,
        payload: false,
      });
    });
};

export const getMiningStatistics = async (dispatch, duration) => {
  dispatch({
    type: _const.GETTING_MINING_STATS,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/mining/stats", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_MINING_STATS,
        payload: false,
      });
      dispatch({
        type: _const.MINING_STATISTICS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_MINING_STATS,
        payload: false,
      });
    });
};

export const getTotalUsers = async (dispatch, duration) => {
  dispatch({
    type: _const.GETTING_TOTAL_USERS,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/users/count", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_TOTAL_USERS,
        payload: false,
      });
      dispatch({
        type: _const.TOTAL_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_TOTAL_USERS,
        payload: false,
      });
    });
};
export const getTotalAdminUsers = async (dispatch, duration) => {
  dispatch({
    type: _const.GETTING_TOTAL_ADMINS,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/admin-user/count", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_TOTAL_ADMINS,
        payload: false,
      });
      dispatch({
        type: _const.TOTAL_ADMINS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_TOTAL_ADMINS,
        payload: false,
      });
    });
};

export const getDeviceStatusCount = async (dispatch, duration) => {
  dispatch({
    type: _const.GETTING_DEVICE_STATUS_COUNT,
    payload: true,
  });
  await axios
    .get(config.adminApiUrl + "/v1/devices/status/count", {
      params: { duration },
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_DEVICE_STATUS_COUNT,
        payload: false,
      });
      dispatch({
        type: _const.DEVICE_STATUS_COUNT,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_DEVICE_STATUS_COUNT,
        payload: false,
      });
    });
};

export const blockDevice = async (dispatch, deviceId) => {
  dispatch({
    type: _const.BLOCKING_DEVICE,
    payload: true,
  });
  await axios
    .patch(config.adminApiUrl + `/v1/devices/block/${deviceId}`)
    .then((res) => {
      Swal.fire({
        title: "Device Blocked Successfully",
        type: "success",
      });
      dispatch({
        type: _const.BLOCKING_DEVICE,
        payload: false,
      });
    })
    .catch((err) => {
      Swal.fire({
        title: "Somthing went wrong",
        type: "error",
      });
      dispatch({
        type: _const.BLOCKING_DEVICE,
        payload: false,
      });
    });
};

export const unBlockDevice = async (dispatch, deviceId) => {
  dispatch({
    type: _const.BLOCKING_DEVICE,
    payload: true,
  });
  await axios
    .patch(config.adminApiUrl + `/v1/devices/block/${deviceId}`)
    .then((res) => {
      Swal.fire({
        title: "Device unBlocked Successfully",
        type: "success",
      });
      dispatch({
        type: _const.BLOCKING_DEVICE,
        payload: false,
      });
    })
    .catch((err) => {
      Swal.fire({
        title: "Somthing went wrong",
        type: "error",
      });
      dispatch({
        type: _const.BLOCKING_DEVICE,
        payload: false,
      });
    });
};
export const setRewardPercentage = async (
  deviceId,
  rewardPercentage,
  token,
  dispatch
) => {
  const payload = { deviceId, rewardPercentage };
  await axios
    .patch(config.adminApiUrl + `/v1/devices/reward-payout`, payload, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      Swal.fire({
        title: "Reward Percentage set Successfully",
        type: "success",
      });
      dispatch(handleModal(0));
    })
    .catch((err) => {
      Swal.fire({
        title: "Somthing went wrong",
        type: "error",
      });
      dispatch(handleModal(0));
    });
};

export const getDeviceTrackData = async (dispatch, parameters, deviceId) => {
  dispatch({
    type: _const.GETTING_DEVICE_TRACK_DATA,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/devices/${deviceId}/datausage`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_DEVICE_TRACK_DATA,
        payload: false,
      });
      dispatch({
        type: _const.DEVICE_TRACK_DATA,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_DEVICE_TRACK_DATA,
        payload: false,
      });
    });
};

export const getLiveSessionHistory = async (dispatch, parameters) => {
  dispatch({
    type: _const.GETTING_LIVE_SESSION_HISTORY,
    payload: true,
  });
  const params = parameters;
  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await axios
    .get(config.adminApiUrl + `/v1/sessions/history`, {
      params,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res) => {
      dispatch({
        type: _const.GETTING_LIVE_SESSION_HISTORY,
        payload: false,
      });
      dispatch({
        type: _const.LIVE_SESSION_HISTORY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_LIVE_SESSION_HISTORY,
        payload: false,
      });
    });
};

export const getDeviceDetails = (deviceId) => {

  return(dispatch) =>{
    dispatch({
      type: _const.GETTING_DEVICE_DETAILS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/devices/${deviceId}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_DEVICE_DETAILS,
          payload: false,
        });
        dispatch({
          type: _const.DEVICE_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_DEVICE_DETAILS,
          payload: false,
        });
      });
  }
 
};

// To get the whitelisted devices
export const getWhiteListedDevices = (deviceId) => {

  return(dispatch) =>{
    dispatch({
      type: _const.GETTING_WHITELISTED_DEVICES,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/devices/whitelisted-devices/${deviceId}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_WHITELISTED_DEVICES,
          payload: false,
        });
        dispatch({
          type: _const.WHITELISTED_DEVICES,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_WHITELISTED_DEVICES,
          payload: false,
        });
      });
  }
 
};

// To get the list of device model
export const getDeviceModels = () => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_DEVICE_MODELS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/devices/device-models`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_DEVICE_MODELS,
            payload: false
          });
          dispatch({
            type: _const.DEVICE_MODELS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_DEVICE_MODELS,
          payload: false
        });
      });
  };
};

// To get the list of manufacturers available
export const getManufacturers = (Page, PageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_DEVICE_MANUFACTURERS,
      payload: true,
    });
    axios
      .get(config.adminApiUrl + `/v1/devices/manufacturers?Page=${Page}&PageSize=${PageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_DEVICE_MANUFACTURERS,
            payload: false
          });
          dispatch({
            type: _const.DEVICE_MANUFACTURERS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_DEVICE_MANUFACTURERS,
          payload: false
        });
      });
  };
};