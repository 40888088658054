import axios from "axios";
import _const from "./Types";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { handleModal } from "./GeneralAction";

//Get booster cycles
export const getBoosterCycles = (Status, lockPeriod, lockStartDate, lockEndDate, page, pageSize ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BOOSTER_CYCLES,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/cycles?status=${Status}&lockPeriod=${lockPeriod}&lockStartDate=${lockStartDate}&lockEndDate=${lockEndDate}&page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_BOOSTER_CYCLES,
            payload: false
          });
          dispatch({
            type: _const.BOOSTER_CYCLES,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BOOSTER_CYCLES,
          payload: false
        });
      });
  };
};

export const getLockupRequests = ( week, startDate, endDate, page, pageSize ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_LOCKUP_REQUESTS,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/lockup-requests?week=${week}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_LOCKUP_REQUESTS,
            payload: false
          });
          dispatch({
            type: _const.LOCKUP_REQUESTS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_LOCKUP_REQUESTS,
          payload: false
        });
      });
  };
};

export const getInterestDisbursements = ( startDate, endDate, page, pageSize ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_INTEREST_DISBURSEMENTS,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/interest-disbursements?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_INTEREST_DISBURSEMENTS,
            payload: false
          });
          dispatch({
            type: _const.INTEREST_DISBURSEMENTS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_INTEREST_DISBURSEMENTS,
          payload: false
        });
      });
  };
};

export const getAccruedInterests = ( startDate, endDate, page, pageSize ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ACCRUED_INTERESTS,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/accrued-interests?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_ACCRUED_INTERESTS,
            payload: false
          });
          dispatch({
            type: _const.ACCRUED_INTERESTS,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ACCRUED_INTERESTS,
          payload: false
        });
      });
  };
};

export const getBoosterCyclesId = ( cycleId ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BOOSTER_CYCLES_ID,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/cycles/${cycleId}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_BOOSTER_CYCLES_ID,
            payload: false
          });
          dispatch({
            type: _const.BOOSTER_CYCLES_ID,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BOOSTER_CYCLES_ID,
          payload: false
        });
      });
  };
};

export const getBoosterCyclesUserId = (userId, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_BOOSTER_CYCLES_USER_ID,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/cycles/user/${userId}?page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_BOOSTER_CYCLES_USER_ID,
            payload: false
          });
          dispatch({
            type: _const.BOOSTER_CYCLES_USER_ID,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_BOOSTER_CYCLES_USER_ID,
          payload: false
        });
      });
  };
};

export const getLockupRequestsId = (cycleId, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_LOCKUP_REQUESTS_ID,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/lockup-requests/cycle/${cycleId}?page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_LOCKUP_REQUESTS_ID,
            payload: false
          });
          dispatch({
            type: _const.LOCKUP_REQUESTS_ID,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_LOCKUP_REQUESTS_ID,
          payload: false
        });
      });
  };
};

export const getLockupRequestsUserId = (userId, page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_LOCKUP_REQUESTS_USER_ID,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/lockup-requests/users/${userId}?page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_LOCKUP_REQUESTS_USER_ID,
            payload: false
          });
          dispatch({
            type: _const.LOCKUP_REQUESTS_USER_ID,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_LOCKUP_REQUESTS_USER_ID,
          payload: false
        });
      });
  };
};

export const getAccruedInterestsUser = (userId, startDate, endDate, page, pageSize ) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ACCRUED_INTERESTS_USER_ID,
      payload: true
    });
    axios
      .get(config.adminApiUrl + `/v1/reward-booster/accrued-interests/user/${userId}?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.GETTING_ACCRUED_INTERESTS_USER_ID,
            payload: false
          });
          dispatch({
            type: _const.ACCRUED_INTERESTS_USER_ID,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ACCRUED_INTERESTS_USER_ID,
          payload: false
        });
      });
  };
};

// Getting reward booster APR
export const getRewardBoosterApr = (lockPeriod) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_APR,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v4/reward-booster/apr?lockPeriod=${lockPeriod}`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_APR,
            payload: false
          });
          dispatch({
            type: _const.REWARD_BOOSTER_APR,
            payload: res.data.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_APR,
          payload: false
        });
      });
  };
};

// Updating reward booster APR
export const updateRewardApr = (data) => {
  return dispatch => {
    dispatch({
      type: _const.UPDATING_APR,
      payload: true
    });
    axios
      .put(config.apiUrl + `/v4/reward-booster/apr`, data)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.UPDATING_APR,
            payload: false
          });
          dispatch({
            type: _const.UPDATE_APR,
            payload: res.data.data
          });

          dispatch(handleModal(0));
          dispatch(getRewardBoosterApr(""));
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.UPDATING_APR,
          payload: false
        });
        dispatch({
          type: _const.APR_ERROR,
          payload: err.message ? err.message : "Something when wrong",
        });
      });
  };
};