import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const getWithdrawal = (
  businessId,
  startDateTimeStamp,
  endDateTimeStamp,
  transferStatus,
  paymentGateway,
  page,
  pageSize,
  dispatch
) => {
  dispatch({
    type: _const.LOADING_WITHDRAWALS,
    payload: true,
  });
  axios
    .get(
      config.apiUrl +
        `/v2/withdrawalReport?businessId=${businessId}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&transferStatus=${transferStatus}&paymentGateway=${paymentGateway}&page=${page}&pageSize=${pageSize}`
    )
    .then((res) => {
      dispatch({
        type: _const.LOADING_WITHDRAWALS,
        payload: false,
      });
      dispatch({
        type: _const.GET_ALL_WITHDRAWALS,
        payload: res.data.records,
        totalrecords: res.data.totalRecords,
        currentPage: res.data.currentPage,
        totalPages: res.data.totalPages,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.LOADING_WITHDRAWALS,
        payload: false,
      });
    });
};

export const getTransactions = (
  transactionCategory,
  transactionSubCategory,
  startDateTimeStamp,
  endDateTimeStamp,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_TRANSACTIONS,
      payload: true,
    });

    axios
      .get(
        config.apiUrl +
          `/v2/transactions/verified?transactionCategory=${transactionCategory}&transactionSubCategory=${transactionSubCategory}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_TRANSACTIONS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_TRANSACTIONS,
          payload: res.data.data,
          totalrecords: res.data.meta.total,
          currentPage: res.data.meta.page,
          totalPages: res.data.meta.numberOfPages,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_TRANSACTIONS,
          payload: false,
        });
      });
  };
};

export const getTransactionOverview = (dispatch, queries) => {
  dispatch({
    type: _const.GETTING_TRANSACTION_OVERVIEW,
    payload: true,
  });
  const params = queries;
  axios
    .get(config.adminApiUrl + `/v1/transactions/overview`, { params })
    .then((res) => {
      dispatch({
        type: _const.GETTING_TRANSACTION_OVERVIEW,
        payload: false,
      });
      dispatch({
        type: _const.TRANSACTIONS_OVERVIEW,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: _const.GETTING_TRANSACTION_OVERVIEW,
        payload: false,
      });
    });
};

export const transactionSummary = () => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_TRANSACTIONS,
      payload: true,
    });

    axios
      .get(config.apiUrl + "/v2/transactions/summary")
      .then((res) => {
        dispatch({
          type: _const.GETTING_TRANSACTIONS,
          payload: false,
        });
        dispatch({
          type: _const.TRANSACTION_SUMMARY,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_TRANSACTIONS,
          payload: false,
        });
      });
  };
};

//Get all crypto withdrawal requests
export const cryptoWithdrawal = (page, pageSize) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CRYPT_WITHDRAWAL,
      payload: true,
    });

    axios
      .get(config.apiUrl + `/v2/withdrawals?page=${page}&pageSize=${pageSize}`)
      .then((res) => {
        dispatch({
          type: _const.GETTING_CRYPT_WITHDRAWAL,
          payload: false,
        });
        dispatch({
          type: _const.ALL_CRYPTO_WITHDRAWALS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CRYPT_WITHDRAWAL,
          payload: false,
        });
      });
  };
};

//Approve or Delete a Pending Crypto withdrawal
export const approvedeletecrypto = (id, success, failureReason) => {
  return (dispatch) => {
    dispatch({
      type: _const.APPROVING_DELETING_CRYPTO,
      payload: true,
    });
    dispatch({
      type: _const.FIELD_ACTION,
      payload: id,
      approve: success,
    });

    axios
      .put(
        config.apiUrl +
          `/v2/withdrawals/${id}/${success}?failureReason=${failureReason}`
      )
      .then((res) => {
        dispatch({
          type: _const.APPROVING_DELETING_CRYPTO,
          payload: false,
        });
        Swal.fire({
          text: `Withdrawal ${
            success === true ? "accepted" : "declined"
          } successfully!`,
          type: "success",
        });
        dispatch({
          type: _const.APPROVING_DELETE_SUCCESS,
          payload: true,
        });
        dispatch(cryptoWithdrawal(1, 30));
      })
      .catch((err) => {
        dispatch({
          type: _const.APPROVING_DELETING_CRYPTO,
          payload: false,
        });
      });
  };
};
