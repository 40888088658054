import styled from "styled-components";
import { theme } from "../../../theme";

export const Table = styled.table`
  width: 100%;
`;
export const TableHead = styled.thead`
  background: #f4f4f4;
  border-radius: 5px;
`;
export const TableLabel = styled.th`
  color: ${theme.grey};
  font-size: ${theme.textXs};
`;
export const TableValues = styled.td`
  color: ${theme.grey};
  font-size: ${theme.textXs};
  font-weight: ${theme.textBold};
`;
export const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;
export const TableFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const Searchbox = styled.input`
  background: #fdfdfd;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 20px;
  color: #aeaeae;
  width: 30%;
  font-size: ${theme.textXs};
  padding-left: 30px;
`;
export const TableActionButton = styled.button`
  border-radius: 36px;
  color: ${theme.white};
  font-size: ${theme.textXs};
  cursor: pointer;
  min-width: 236px;
  background: ${theme.yellow};
  border: 1px solid rgba(207, 168, 9, 0.35);
  font-weight: 600;
  text-align: center;
  height: 40px;
`;
export const PageTitle = styled.p`
  color: ${theme.black};
  font-weight: ${theme.textBold};
  margin-bottom: 0;
`;
export const ViewMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(229, 185, 16, 1);
  margin: 10px 0;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* gap: 20px; */
  margin-top: 10px;
`;

export const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
  gap: 15px;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0 24px;
  width: 100%;
  
`;

export const Tab = styled.div`
  border: 1px solid rgba(229, 185, 16, 1);
  color: ${(props) =>
    props.active === props.id ? "white" : "rgba(229, 185, 16, 1)"};
  background-color: ${(props) =>
    props.active === props.id ? "rgba(229, 185, 16, 1)" : "transparent"};
  min-width: 82px;
  height: 30px;
  padding: 5px 24px 5px 24px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const Notification = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6b6b6b;
`;
