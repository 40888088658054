import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails, handleSubNav } from "../../../Redux/actions";
import { numberWithCommas } from "../../../Helper";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "../../Devices/styles";
import EmptyCard from "../../Reusables/cards/EmptyCard";

const DisbursedRewardDetailsPage = ({history}) => {

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const transactionCategory = "disbursed-staking-reward-detail";
  const [rewardDetailId, setRewardDetailId] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const newRewardDetailId = window.location.pathname.split("/").at(-1);
    setRewardDetailId(newRewardDetailId);
  }, []); 
  
  useEffect(() => {
    if (rewardDetailId) {
      dispatch(getNewTransactionDetails(transactionCategory, rewardDetailId));
    }
  }, [rewardDetailId, dispatch]);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingNewTrancactionDetails ?
          <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
             {Object.keys(newTransactionDetails).length !== 0 ? 
             <div className="card-body p-6 ">
                <div className="row mb-2">
                  { newTransactionDetails.userInfo &&
                    <div className="col-lg-6 col-md-6">
                    <PersonalBox>
                      <UserDetailsAvatar
                        className="rounded-circle"
                        src="/Assets/images/avatars/user_icon.svg"
                        alt="User Avatar"
                      />
                      <PersonalInfo>
                        <span>{newTransactionDetails.userInfo && newTransactionDetails.userInfo.name ? newTransactionDetails.userInfo.name : "N/A"}</span>
                        <span>{newTransactionDetails.userInfo && newTransactionDetails.userInfo.email ? newTransactionDetails.userInfo.email : "N/A"}</span>
                      </PersonalInfo>
                      {newTransactionDetails.userInfo && newTransactionDetails.userInfo.id ? 
                        <Link to={`/user/${newTransactionDetails.userInfo.id}`} 
                        onClick={() => {dispatch(handleSubNav(1));}}
                        >
                          <div className="details min-width"> Details </div></Link> : <div className="details min-width"> Not Available </div>}
                    </PersonalBox>
                  </div>
                  }
                    <div className="col-lg-4 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      {newTransactionDetails.transactionTimeStamp ? (
                        <StakeStatValue>
                          {moment(newTransactionDetails.transactionTimeStamp).format("DD-MM-YYYY")} |{" "}
                          {moment(newTransactionDetails.transactionTimeStamp).format("LT")}
                        </StakeStatValue>
                      ) : "N/A"}
                    </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-md-6">
                      <StakeStatData>Staking info snapshot</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Staked At</th>
                              <th>Duration</th>
                              <th>Stake Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            {newTransactionDetails.stakingInfoSnapshot ? (
                              <tr>
                               { newTransactionDetails.stakingInfoSnapshot.stakedAt ? (<TableValues>
                                  {moment(newTransactionDetails.stakingInfoSnapshot.stakedAt).format("DD-MM-YYYY")} |{" "}
                                  {moment(newTransactionDetails.stakingInfoSnapshot.stakedAt).format("LT")}
                                </TableValues>) : <TableValues>N/A</TableValues>}
                                {newTransactionDetails.stakingInfoSnapshot.duration && newTransactionDetails.stakingInfoSnapshot.duration ? 
                                  <TableValues>{newTransactionDetails.stakingInfoSnapshot.duration}</TableValues> : <TableValues>N/A</TableValues>}
                                {newTransactionDetails.stakingInfoSnapshot && newTransactionDetails.stakingInfoSnapshot.stakedBalance && newTransactionDetails.currency ? 
                                  <TableValues>{numberWithCommas(newTransactionDetails.stakingInfoSnapshot.stakedBalance, 4)} {newTransactionDetails.currency}</TableValues> : <TableValues>0</TableValues>}
                              </tr>
                            ) : "N/A"}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  <div className="col-md-6">
                      <StakeStatData>Wallet Transaction</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Date</th>
                              {/* <th>Reference</th> */}
                              <th>Amount</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            <tr>
                              { newTransactionDetails.transactionTimeStamp ?
                                <TableValues>
                                  {moment(newTransactionDetails.transactionTimeStamp).format("DD-MM-YYYY")} |{" "}
                                  {moment(newTransactionDetails.transactionTimeStamp).format("LT")}
                                </TableValues>
                                : "N/A"}
                                {/* <TableValues>{newTransactionDetails.reference}</TableValues> */}
                                {newTransactionDetails.amount && newTransactionDetails.currency ? <TableValues>{numberWithCommas(newTransactionDetails.amount, 4)} {newTransactionDetails.currency }</TableValues> : 0}
                              </tr> 
                          </tbody>
                        </Table>
                      </div>
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-md-6">
                      <StakeStatData>Reward Balance State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Balance Before</th>
                              <th>Balance After</th>
                            </tr>
                          </TableHead>
                          {newTransactionDetails.rewardBalanceStateChange && (
                            <tbody>
                              <tr>
                               {newTransactionDetails.rewardBalanceStateChange && newTransactionDetails.rewardBalanceStateChange.balanceBefore ? 
                                <TableValues>{numberWithCommas(newTransactionDetails.rewardBalanceStateChange.balanceBefore, 1) }</TableValues> : <TableValues>0</TableValues>}
                                {newTransactionDetails.rewardBalanceStateChange && newTransactionDetails.rewardBalanceStateChange.balanceAfter ? 
                                  <TableValues>{numberWithCommas(newTransactionDetails.rewardBalanceStateChange.balanceAfter, 1) }</TableValues> : <TableValues>0</TableValues>}
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </div>
                    </div>
                 
                </div>
               
              </div> :
               <EmptyCard
               img="/Assets/emptytransactions.svg"
               title={"Sorry, you have no transaction history"}
             />
              }
            </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default DisbursedRewardDetailsPage;
