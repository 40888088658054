import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFundHistory,
  clearHistory,
  getFormDetails
} from "../../Redux/actions";
import moment from "moment";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import { numberWithCommas } from "../../Helper";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";

class BusinessHistory extends Component {
  componentWillMount() {
    const { myBusinessId } = this.props;
    this.props.getFundHistory("", 1, 50, myBusinessId, "");
  }

  customPagination() {
    const {
      totalBusinessTransactionPages,
      currentBusinessTransactionPage,
      fundCategory,
      myBusinessId
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== currentBusinessTransactionPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getFundHistory(
              fundCategory,
              currentBusinessTransactionPage - 1,
              50,
              myBusinessId,
              ""
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; totalBusinessTransactionPages >= i; i++) {
      if (i === currentBusinessTransactionPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getFundHistory(fundCategory, i, 50, myBusinessId, "")
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (totalBusinessTransactionPages > currentBusinessTransactionPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getFundHistory(
              fundCategory,
              currentBusinessTransactionPage + 1,
              50,
              myBusinessId,
              ""
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const { fundHistory, transactionHistory, currencyValue } = this.props;

    let allRows = [];
    fundHistory.map(transc => {
      return allRows.push({
        name: <div className="transcBkg"> </div>,
        type: transc.category,
        method: transc.transactionSubCategory,
        date: moment(transc.createdAt).format("L"),
        time: moment(transc.createdAt).format("LT"),
        amount: (
          <b
            className={
              transc.isCredit ? "bold t-12 Funding" : " bold t-12 Transfer"
            }
          >
            {transc.isCredit ? (
              "NGN " +
              numberWithCommas(
                transc.currency === "WC"
                  ? transc.realAmount * currencyValue
                  : transc.realAmount
              )
            ) : (
              <div>
                {" "}
                <p className="mb-0">
                  {"NGN " +
                    numberWithCommas(
                      transc.currency === "WC"
                        ? transc.realAmount * currencyValue
                        : transc.realAmount
                    )}
                </p>
                <p className="mb-0 t-8 littleamt">~{transc.amount}WC</p>
              </div>
            )}
          </b>
        )
      });
    });

    const data = {
      columns: [
        {
          label: "",
          field: "name",
          sort: "asc",
          width: 170
        },
        {
          label: "Transaction Type",
          field: "type",
          sort: "asc",
          width: 170
        },
        {
          label: "Payment Method",
          field: "method",
          sort: "asc",
          width: 170
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 170
        },
        {
          label: "Time",
          field: "time",
          sort: "asc",
          width: 170
        },
        {
          label: "Amount",
          field: "amount",
          sort: "asc",
          width: 170
        }
      ],

      rows: allRows
    };

    return (
      <div className="row w3-animate-left">
        <div className="col-lg-12">
          {/* <div className="card-header mb-3 d-flex jc">
            <h6 className="m-0">TRANSACTION HISTORY</h6>
            <div className="row border-bottom py-2 bg-light">
              <div className="col-12 col-sm-6 d-flex">
                <div
                  id="blog-overview-date-range"
                  className="input-daterange input-group input-group-sm my-auto ml-auto mr-auto ml-sm-auto mr-sm-0"
                >
                  <select
                    className="input-sm form-control"
                    style={{ width: "auto" }}
                    onChange={(e) => {
                      this.props.clearHistory();
                      this.props.getFormDetails({ props: ['fundCategory'], value: e.target.value })
                      this.props.getFundHistory(e.target.value, 1, 50);
                    }}
                  >
                    <option value={""}>Select Category</option>
                    <option value={"Funding"}>Funding</option>
                    <option value={"Withdraw"}> Withdrawal</option>
                    <option value={"Transfer"}> Transfer</option>
                    <option value={"Voucher"}> Voucher</option>
                    <option value={"WithdrawalReversal"}>
                      {" "}
                      WithdrawalReversal
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            {transactionHistory ? (
              <div className="col-lg-12 mb-4">
                <CustomLoader />{" "}
              </div>
            ) : !transactionHistory & (fundHistory.length === 0) ? (
              <EmptyCard
                img="/Assets/emptytransactions.svg"
                title={"Sorry,  there is no Transaction History"}
                descr={"Start making transactions to have them appear here."}
              />
            ) : (
              <div className="col-lg-12 mb-4">
                <CustomDataTable
                  data={data}
                  pagination={this.customPagination()}
                  title={"Transaction History"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const {
    fundHistory,
    totalBusinessTransactionPages,
    currentBusinessTransactionPage,
    allBusinessTransactions
  } = state.Report;
  const {
    businessProfile,
    currencyValue,
    fundCategory,
    myBusinessId
  } = state.User;
  const { transactionHistory } = state.Loading;
  return {
    fundHistory,
    businessProfile,
    transactionHistory,
    currencyValue,
    totalBusinessTransactionPages,
    currentBusinessTransactionPage,
    allBusinessTransactions,
    fundCategory,
    myBusinessId
  };
};

export default connect(
  MapStateToProps,
  { getFundHistory, clearHistory, getFormDetails }
)(BusinessHistory);
