import React from "react";
import { getFormDetails } from "../../../Redux/actions";
import { connect } from "react-redux";

const SearchBusiness = (props) => {
  const { allBusiness } = props;

  return (
    <select
      className="form-control search"
      style={{ width: "auto" }}
      onChange={(e) =>
        props.getFormDetails({
          props: ["selectedAffiliatedBusiness"],
          value: e.target.value,
        })
      }
    >
      <option value="">Select Business</option>
      {allBusiness.length &&
        allBusiness.map((business) => {
          return <option value={business.id}>{business.name}</option>;
        })}
    </select>
  );
};

const MapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(MapStateToProps, { getFormDetails })(SearchBusiness);
