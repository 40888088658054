import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccount } from "../../Redux/actions";

class FundAcct extends Component {
  componentWillMount() {
    this.props.getAccount();
  }

  render() {
    const { merchantAccount } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12 fade-in">
          <div className="text-center">
            <p>
              {" "}
              Make Payment into the Account Number below to fund your Business
              Wallet
            </p>
            <span className="text-muted d-block mb-2">
              {" "}
              ACCOUNT NUMBER: <b className="bankDetails">{merchantAccount}</b>
            </span>
            <span className="text-muted d-block mb-2">
              {" "}
              BANK NAME: <b className="bankDetails"> Providus Bank</b>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const { merchantAccount } = state.Agent;

  return {
    merchantAccount,
  };
};
export default connect(
  MapstateToProps,
  {
    getAccount,
  }
)(FundAcct);
