import React, { Component } from 'react';

class AgentCard extends Component {
    render() {
        const { agentName, revenue, usage } = this.props
        return (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <div className="card card-small h-100">
                    <div className="card-header border-bottom">
                        <h6 className="m-0 text-bold">{agentName}</h6>
                    </div>
                    <div className="card-body d-flex py-0 jc border-bottom">
                        <div> <p className='overviewVal'>{usage}</p> <p> Data Used</p> </div>
                        <div> <i className="material-icons overviewVal t3">signal_wifi_4_bar</i> </div>
                    </div>
                    <div className="card-body d-flex py-0 jc border-bottom">
                        <div><p className='overviewVal'> {revenue}</p> <p> Revenue</p>  </div>
                        <div> <i className="material-icons overviewVal t3">money</i> </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AgentCard;