import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNodeProviders, updateNodeProviderStatus, handleModal, copyToClipboard } from "../../Redux/actions";
import { shortenAddress } from "../../Helper";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import Loader from "../Reusables/loaders/MoonLoader";
import EmptyCard from "../Reusables/cards/EmptyCard";
import SetNodeProviderActiveModal from "./SetNodeProviderActiveModal";

const NodeProviderList = () => {

    const dispatch = useDispatch();

    const { nodeProviders, setActiveError } = useSelector((state) => state.Admin);
    const { gettingNodeProviders, updatingNodeProviderStatus } = useSelector((state) => state.Loading);
    const [id, setId] = useState("");
    const [nodeURL, setNodeURL] = useState("");
    const [proverdersName, setProvidersName] = useState("");

    let allRows = [];
    nodeProviders.map((each) => {
        return allRows.push({
            name: each.providerName,
            nodeUrl: <div>{shortenAddress(each.nodeUrl)}
                <p 
                    style={{ cursor: "pointer", color: "#e5b909", }}
                    onClick={() => {setNodeURL(each.nodeUrl); dispatch(copyToClipboard(each.nodeUrl))}}
                >
                    {nodeURL === each.nodeUrl ? "Copied " : "Copy URL "} 
                    <img src="/Assets/images/copy.svg" alt="copy"  />
                </p>
                </div>,
                status: each.isEnabled ? <div className="SUCCESS"> Active </div> : <div className="PENDING"> Not Active </div>,
             setstatus: each.isEnabled ? <div className="SUCCESS">  </div> :
             <div className="details" onClick={() => { dispatch(handleModal(84)); setId(each.id); setProvidersName(each.providerName)}}>
                Set as Active
            </div>
        });
    });


    useEffect(() => {
        dispatch(getNodeProviders())
    }, []);

    const data = {
        columns: [
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 170,
            },
            {
                label: "NodeUrl",
                field: "nodeUrl",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "setstatus",
                sort: "asc",
                width: 170,
            },
        ],

        rows: allRows,
    };


    return (
        <div className=" mb-4">
            <div className="row">
                <div className="col-lg-12">
                    <CustomDataTable
                        data={data}
                        loading={gettingNodeProviders}
                        title="Node Providers"
                        buttontitle={"Create Node Provider"}
                        onClick={() =>dispatch(handleModal(57))}
                        noData={
                            <EmptyCard
                                img="/Assets/emptytransactions.svg"
                                title={"Sorry, There are no node providers available"}
                            // descr={"No transaction history available at the moment"}
                            />
                        }
                    />
                </div>
            </div>
            <SetNodeProviderActiveModal
                onSubmit={() => { dispatch(updateNodeProviderStatus(id)); setId(id) }} 
                setActiveError={setActiveError}
                updatingProviderStatus={updatingNodeProviderStatus}
                proverdersName={proverdersName}
            />
        </div>
    );
};

export default NodeProviderList;
