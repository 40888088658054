import React, { useEffect, useState } from "react";
import { Value } from "../../AgentReport/agentstyles";
import { SubmitButton, SubmitButtonContainer } from "../../Devices/modals/styles";
import CustomModal from "../../Reusables/modals/CustomModal";
import {
  ChangeRoleContainer,
  DropDown,
  SelectContainer,
  SelectTitle,
  Option,
} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Reusables/loaders/MoonLoader";
import { changeAdminRole } from "../../../Redux/actions";

function ChangeRole() {
  let dispatch = useDispatch();

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { adminRoles, selectedAdmin, adminError } = useSelector(
    (state) => state.Admin
  );

  const {changingAdmin} = useSelector((state)=> state.Loading);

  const handleSubmit = () =>{
    let data = {
      userId: selectedAdmin ? selectedAdmin.userId : "",
      role: selectedOption
    }
    dispatch(changeAdminRole(data))
  }

  useEffect(()=>{
    if(selectedAdmin){
      setSelectedOption(selectedAdmin.role)
    }
    
  }, [selectedAdmin])

  return (
    <div>
      <CustomModal
        id={15}
        heading="Change Role"
        subHeading={`Change existing role for ${selectedAdmin?selectedAdmin.name : ""}`}
        close={true}
        content={
          <>
            <ChangeRoleContainer>
              <SelectTitle>Select Role</SelectTitle>

              <SelectContainer
                onClick={() => setToggleDropdown(!toggleDropdown)}
              >
                <Value style={{ fontSize: "12px", marginBottom: "0px" }}>
                  {selectedOption}
                </Value>
                <img src="/assets/chevron-downward.svg" height="5px" alt="" />
              </SelectContainer>
              {toggleDropdown && (
                <DropDown>
                  {adminRoles.map((item) => (
                      <Option
                        key={item}
                        onClick={() => {
                          setSelectedOption(item);
                          setToggleDropdown(false);
                        }}
                        selected={selectedOption === item}
                      >
                        {item}
                      </Option>
                    ))}
                </DropDown>
              )}
              {adminError ? <p className="errorMsgs mt-3 t-center">{adminError}</p> : ""} 
            </ChangeRoleContainer>
            <SubmitButtonContainer onClick={()=> handleSubmit()}>
              <SubmitButton>{changingAdmin ? <Loader /> : "Confirm"}</SubmitButton>
            </SubmitButtonContainer>
          </>
        }
      />
    </div>
  );
}

export default ChangeRole;
