import styled from "styled-components/macro";

export const SelectContainer = styled.div``;

export const Label = styled.label`
  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.textXs};
  margin-bottom: 0px;
`;

export const InputTagBox = styled.div`
  background: ${({ theme }) => theme.themebody};
  min-height: 36px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0;
  cursor: pointer;
  position: relative;

  border: ${({ theme }) => `1px solid ${theme.grey}`};
  border-radius: 26px;
  padding: 10px;
  width: 178px;
  height: 40px;
  background-color: ${({ theme }) => theme.themebody};
//   border-bottom: 0.7px solid #c4c4c4;
  margin-bottom: 20px;
  margin-top: 0px;
  color: ${({ theme }) => theme.grey};

  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const InputText = styled.input`
  background: ${({ theme }) => theme.themebody};
  min-height: 36px;
  font-weight: 500;
  display: flex;
  padding: 15px 20px;
  margin: 0;
  border: ${({ theme }) => `1px solid ${theme.grey}`};
  border-radius: 26px;
  padding: 10px;
  width: 200px;
  height: 40px;
  background-color: ${({ theme }) => theme.themebody};
  margin-bottom: 20px;
  margin-top: 0px;
  color: ${({ theme }) => theme.grey};
  outline: none;
  font-size: ${({ theme }) => theme.textXs};
`;

export const InputTag = styled.div`
  background: ${({ theme }) => theme.white};
  min-height: 36px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0;
  cursor: pointer;
  position: relative;

  border: none;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  border-bottom: 0.7px solid #c4c4c4;
  margin-bottom: 20px;
  margin-top: 0pxx;
  color: ${({ theme }) => theme.grey};

  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    img {
      margin: 2px 0px;
      height: 5px;
    }
  }
`;

export const SelectedOption = styled.div`
  /* border-bottom: 1px solid #d2cbcb; */
  border: 0px;
  margin-bottom: 0px;
  p {
    margin: 0;
    font-size: 0.75rem !important;
  }
`;

export const SelectErr = styled.p`
  font-size: ${({ theme }) => theme.textXs};
  color: red;
  margin-bottom: 0;
  margin: 0;
  /* padding: 5px; */
  // position: absolute;
`;

export const DropdownContainer = styled.div`
         border-radius: 8px;
         background: ${({ theme }) => theme.white};
         position: absolute;
         cursor: pointer;
         min-width: 178px;
         top: 75%;
         padding: 20px;
         z-index: 100000;
         box-shadow: 1px 1px 2px 2px #b7b7b7;
         box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
       `;

export const Optionvalues = styled.p`
  border-bottom: 1px solid #6b6b6b;
  font-size: 0.7rem;
  margin-bottom: 0.8rem;
  color: ${(props) => props.choosenOption && "#cfa809"};
  width: 100%;
`;

export const DeviceUl = styled.ul`
  margin-top: 20px;
  padding: 10px;
`
export const DeviceLi = styled.li`
  padding: 7px;
  box-shadow: 1px 1px 2px 2px #b7b7b7;
  margin-bottom: 10px;
  
`
export const DeviceList = styled.div`
  height: 200px;
  overflow-y: scroll;
`