import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommand, } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  SubmitButton,
  SubmitButtonContainer,
  Notification,
  NotificationText
} from "./styles";

const DeleteCommands = () => {

  const dispatch = useDispatch();


  const { commandId } = useSelector((state) => state.User);
  const {deletingCommands} = useSelector((state)=> state.Loading)

  return (
    <div>
      <CustomModal
        id={51}
        heading="Delete Command"
        close={true}
        content={
          <div
            style={{
              width: "532px",
            }}
          >
          <Notification>
              <NotificationText>
                Are you sure you want to delete this command?
              </NotificationText>
            </Notification>
            <SubmitButtonContainer>
              <SubmitButton
                onClick={() => {
                  dispatch(deleteCommand(commandId))
                }}
              >
                {deletingCommands ? <Loader /> : "Delete" }
              </SubmitButton>
            </SubmitButtonContainer>
          </div>
        }
      />
    </div>
  );
};

export default DeleteCommands;
