import React, {Component} from 'react';
import {DotLoader
} from 'react-spinners';

const override = {
  display: 'block',
  marginLeft: '5px',
  borderColor: 'red',
}
 
class Loader extends Component {
 
  render() {
    const {size} = this.props
    return (
      <div className='sweet-loading'>
        <DotLoader
          style= {override}
          size={size ? size : 30}
          color={'#3d5170'}
          loading={true}
          margin= {'1px'}
        />
      </div> 
    )
  }
}

export default Loader