

export const theme = {

    //Default colors
    white: "#FFFFFF",
    blue: "#344684",
    yellow: "#E5B909",
    black: "#242424",
    grey: "#6B6B6B",
    green: " #339966",
    red: "#FF506B",
    lightblue: "#E7EBF7",
    lightgrey: "#F7F9FA",
    opaquegrey: "#A6A6A6",
    mutedgrey: '#959595',
    selectgrey: '#f4f4f4',

    //Text sizes
    textLg: "3rem",
    textSmd: "2.0rem",
    textMd: "1.5rem",
    textSm: "1.0rem",
    textXs: "0.7rem",
    textXXs: "0.5rem",

    //Text weight
    textNormal: 500,
    textBold: 600,
    textXBold: 'bold',


};


const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};
