import React, { Component } from "react";
import UserDetail from "./userdetail";
import { connect } from "react-redux";
import UserWallet from "./userwallet";
import FundAcct from "./fundacct";
import Withdraw from "./withdraw";
import Details from "./details"
import { editUserInfo } from '../../Redux/actions'

class ProfileTabs extends Component {

  render() {
    // const { role } = this.props
    // var show = role.includes('Business')
    return (
      <div className="main-content-container container-fluid px-4 mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-6 mb-4">
            <div className="stats-small stats-small--1 card card-small">
              <div className="card-body">
                <div>
                  {/* <ul className="nav nav-tabs">
                    <li className={action === 1 ? "cycle-tab-item active" : "cycle-tab-item"} onClick={() => this.props.editUserInfo(true, 1)}>
                      <a
                        className="nav-link"
                        role="tab"
                        data-toggle="tab"
                        href="#home"
                      >
                        Profile
                      </a>
                    </li>
                    <li className={action === 2 ? "cycle-tab-item active" : "cycle-tab-item"} onClick={() => this.props.editUserInfo(true, 2)}>
                      <a
                        className="nav-link"
                        role="tab"
                        data-toggle="tab"
                        href="#profile"
                      >
                        Wallet
                      </a>
                    </li>
                    {show ?
                      <li className={action === 3 ? "cycle-tab-item active" : "cycle-tab-item"} onClick={() => this.props.editUserInfo(true, 3)}>
                        <a
                          className="nav-link"
                          role="tab"
                          data-toggle="tab"
                          href="#messages"
                        >
                          Fund
                      </a>
                      </li> : ""}
                    {show ?
                      <li className={action === 4 ? "cycle-tab-item active" : "cycle-tab-item"} onClick={() => this.props.editUserInfo(true, 4)}>
                        <a
                          className="nav-link"
                          role="tab"
                          data-toggle="tab"
                          href="#settings"
                        >
                          Withdraw
                      </a>
                      </li>
                      : ""}
                  </ul> */}
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <UserDetail />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <UserWallet />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="messages"
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      <FundAcct />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="settings"
                      role="tabpanel"
                      aria-labelledby="settings-tab"
                    >
                      <Withdraw />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <Details />
          </div>
        </div>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const {

    role,
  } = state.User;
  const { action } = state.Manager
  return {

    role,
    action

  };
};
export default connect(MapstateToProps, { editUserInfo })(ProfileTabs);
