import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { clearSearch, getAdminTransactions, getSearchDetails, getUsers } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import { newTransferColumns } from "../../utils/TableCols/NewTransactionsCols";
import { newTransferOptions } from "./options";

const NewTransfer = () => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");
  // const [searchValueText, setSearchValueText] = useState('');

  const {
    adminTransactions,
    gettingAdminTransactions,
  } = useSelector((state) => state.Transactions);
  const {
    duration,
    Status,
    Page,
    PageSize,
    ReferenceCode,
    userId,
    Search,
    usersType
  } = useSelector((state) => state.Search);
  const { allusers } = useSelector((state) => state.Admin);
  const { gettingUsers} = useSelector((state) => state.Loading);
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
  );
  const data = {
    columns: newTransferColumns,
    rows: allRows,
  };

  // This is to get all the users 
  const fetchAllUsers = () => {
    const queries = {Search};
    getUsers(dispatch, queries, usersType);
  }

  let availableUsers = allusers ? allusers.records : [];

  const transactionCategory = "transfer";
  const fetchAdminTransactions = () => {
    const queries = {
      Duration: duration,
      Status,
      Page,
      PageSize,
      ReferenceCode,
      userId,
      SearchParam: Search,
    };
    getAdminTransactions(dispatch, transactionCategory, queries);
  }

  // useEffect(() => {
  //   setSearchValueText(Search);
  // }, [Search]);

  useEffect(() => {
    // if(duration === "") {
      fetchAdminTransactions();
    // }
  }, [
    duration,
    Status,
    Page,
    PageSize,
    userId,
    // ReferenceCode,
  ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(ReferenceCode === "" && Search === ""){
      fetchAdminTransactions();
    }
  },[ReferenceCode, Search])

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              title={`Transfer History`}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  // subHeaderTypes={transactionsHeaderOptionTypes}
                  dataLength={adminTransactions.records ? adminTransactions.records.length : 0}
                  subHeader={ newTransferOptions}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchTableClick={fetchAllUsers}
                  searchvalueText={Search}
                  // setSearchValueText={setSearchValueText}
                  datalistOptions={availableUsers}
                  gettingDataListOptions={gettingUsers}
                  onSearchClick={fetchAdminTransactions}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTransfer;
