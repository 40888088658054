import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../Redux/actions";

class NotFound extends Component {
  componentDidMount() {
    if (!this.props.auth) {
      this.props.logOut();
    }
  }
  render() {
    return (
      <div className="error">
        <div className="error__content">
          <h2>404</h2>
          <h3>Something went wrong!</h3>
          <p>You seem lost.</p>
          <Link to={"/"} onClick={()=>  this.props.logOut()}>
            <button type="button" className="btn btn-accent btn-pill">
              ← Go Back
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

const MapstateToProps = state => {
  const { auth, role } = state.User;
  return {
    auth,
    role
  };
};
export default connect(MapstateToProps, {logOut})(NotFound);
