import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { getWeeklyStatistics, getSearchDetails, getMiningWeeks, handleSubNav } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import moment from "moment";
import TableHeader from "../Reusables/tables/TableHeader";
import {
     numberWithCommaswithoutdecimals
} from "../../Helper";
import { Link } from "react-router-dom";


const StakingStatistics = () => {

    const dispatch = useDispatch();
    const { weeklyStats, weeklyStatsCurrentPage, allMiningWeeks, weeklyStatsTotalPages } = useSelector((state) => state.Miners);
    const { gettingWeeklyMiningStats } = useSelector((state) => state.Loading)
    const {
        Page,
        PageSize,
        metCriteria,
        search,
        hasReward,
        statisticsWeek
    } = useSelector((state) => state.Search);

    const minersOptions = [
        {
            id: 1,
            title: "Week",
            type: "select",
            onChangeProp: "",
            onSelectProp: "statisticsWeek",
            options: allMiningWeeks,
        },
        {
            id: 2,
            title: "Met Min Staking Requirements?",
            type: "select",
            onChangeProp: "",
            onSelectProp: "metCriteria",
            options: [
                {
                    id: 1,
                    option: "Yes",
                },
                {
                    id: 2,
                    option: "No",
                },
                {
                    id: 3,
                    option: "--",
                },
            ],
        },
        {
            id: 4,
            title: "Has Reward?",
            type: "select",
            onChangeProp: "",
            onSelectProp: "hasReward",
            options: [
                {
                    id: 1,
                    option: "Yes",
                },
                {
                    id: 2,
                    option: "No",
                },
                {
                    id: 3,
                    option: "--",
                },
            ],
        },
        {
            id: 3,
            title: "Search",
            type: "text",
            placeholder: "Search...",
            onChangeProp: "search",
        },
    ]

    let allRows = [];
    weeklyStats.map((stats) => {
        return allRows.push({
            userDetails: <Link 
                        onClick={() => {dispatch(handleSubNav(1)); localStorage.setItem("backBtn", "/minersReward");}} 
            className="text-blue" to={`/user/${stats.ownerDetail ? stats.ownerDetail.ownerId : null}`}>
                <p className="mb-0">{stats.ownerDetail ? stats.ownerDetail.name : null} </p>
                <p>{stats.ownerDetail ? stats.ownerDetail.email : null} </p>
            </Link>,
            reward: numberWithCommaswithoutdecimals(stats.reward),
            dataUsage: <div>
                <p className="mb-0"> Explorer Usage Uptime: {stats.explorerUsage ? stats.explorerUsage.uptimeInHrs : null} Hrs</p>
                <p className="mb-0"> Explorer Usage(MB): {stats.explorerUsage ? numberWithCommaswithoutdecimals(stats.explorerUsage.usageInMB) : null} MB</p>
                <p className="mb-0"> Core Usage Uptime: {stats.coreUsage ? stats.coreUsage.uptimeInHrs : null} Hrs</p>
                <p className="mb-0"> Core Usage(MB): {stats.coreUsage ? numberWithCommaswithoutdecimals(stats.coreUsage.usageInMB) : null} MB</p>
            </div>,
            deviceInfo: <div>
                <p className="mb-0"> Name:   
                    <Link 
                        onClick={() => dispatch(handleSubNav(1))} 
                        className="text-blue" 
                        to={`/devices/${stats.deviceInfo ? stats.deviceInfo.deviceId: null}`}>
                        {" " +stats.deviceInfo ? stats.deviceInfo.name : null}
                    </Link> 
                </p>
                <p className="mb-0"> Mode: {stats.deviceInfo ? stats.deviceInfo.mode : null}</p>
                <p className="mb-0"> Tier: {stats.deviceInfo ? stats.deviceInfo.tier : null}</p>
                <p className="mb-0"> Last Activity: {stats.deviceInfo ? moment(stats.deviceInfo.lastActivity).format("DD-MM-YYYY") : "N/A"}</p>
            </div>,
            stakingInfo: <div>
                <p className="mb-0">Staking Score: {numberWithCommaswithoutdecimals(stats.score)}</p>
                <p className="mb-0"> Staked WNT: {stats.stakingInfo ? numberWithCommaswithoutdecimals(stats.stakingInfo.stakedWNT) : null}</p>
                <p className="mb-0"> Staked WNT (USD): {stats.stakingInfo ? numberWithCommaswithoutdecimals(stats.stakingInfo.stakedWNTUSDValue) : null}</p>
            </div>,
           status: <div> 
                <p className="mb-0">Met minimum staking requirements?: {stats.meetsMinimumStakingRequirement ? "Yes" : "No"}</p>
                <p className="mb-0">Has Reward?: {stats.hasReward ? "Yes" : "No"}</p>
            </div>
        });
    });
    const data = {
        columns: [
            {
                label: "User",
                field: "userDetails",
                sort: "asc",
                width: 30,
            },
            {
                label: "Reward",
                field: "reward",
                sort: "asc",
                width: 170,
            },
            {
                label: "Device Info",
                field: "deviceInfo",
                sort: "asc",
                width: 170,
            },
            {
                label: "Data Usage",
                field: "dataUsage",
                sort: "asc",
                width: 170,
            },
            {
                label: "Staking Info",
                field: "stakingInfo",
                sort: "asc",
                width: 170,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 170,
            }

        ],
        rows: allRows,
    };

    useEffect(() => {
        dispatch(getMiningWeeks())
    }, []);

    const fetchWeeklyStatistics = () => {
        dispatch(
            getWeeklyStatistics(
                statisticsWeek, 
                Page, 
                PageSize, 
                metCriteria === "Yes" ? true : metCriteria === "No" ?  false : "",
                search,
                hasReward === "Yes" ? true : hasReward === "No" ? false : "",
                ));
    }

    useEffect(() => { 
        fetchWeeklyStatistics();
    }, [Page, PageSize, metCriteria,hasReward, statisticsWeek]);

    // This is to refresh the page when the search input is empty
    useEffect(()=>{
        if(search === ""){
            fetchWeeklyStatistics();
        }
    },[search])

    return (
        <div className="row mt-5">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"currency-control"}
                            pageCount={weeklyStatsTotalPages}
                            currentPage={weeklyStatsCurrentPage}
                            header={
                                <TableHeader
                                    subHeader={minersOptions}
                                    onSelect={(val, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: val,
                                            })
                                        );
                                    }}
                                    onChange={(e, props) => {
                                        dispatch(
                                            getSearchDetails({
                                                props: [props],
                                                value: e.target.value,
                                            })
                                        );
                                    }}
                                    onSearchClick={fetchWeeklyStatistics}
                                />
                            }
                            loading={gettingWeeklyMiningStats}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={"Not found"}
                                    descr={"Staking Detail not found"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StakingStatistics;
