import { useEffect } from "react";

const useOutsideClick = ( ref, handleClick ) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (( event.target ) === ref.current) {
        handleClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleClick]);
};

export default useOutsideClick;
