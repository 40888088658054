import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { clearSearch, getAdminTransactions, getSearchDetails } from "../../Redux/actions";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import TableHeader from "../Reusables/tables/TableHeader";
import { getTransactionRows } from "../../utils/TableRows/transactionsTableRows";
import { disbursedStakingRewardColumns } from "../../utils/TableCols/NewTransactionsCols";
import { disbursedStakingRewardOptions } from "./options";

const DisbursedStakingRewards = () => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { adminTransactions, gettingAdminTransactions } = useSelector((state) => state.Transactions);
  const {Week, startTime, endTime, Page, PageSize } = useSelector((state) => state.Search);

  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
  );
  const data = {
    columns: disbursedStakingRewardColumns,
    rows: allRows,
  };

  const transactionCategory = "disbursed-staking-rewards";

  const fetchAdminTransactions = () => {
    const queries = {
      Week,
      StartDate: startTime,
      EndDate: endTime,
      Page,
      PageSize
    };
    getAdminTransactions(dispatch, transactionCategory, queries);
  }

  useEffect(() => {
    fetchAdminTransactions();
  }, [ startTime, endTime, Page, PageSize ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if( Week === ""){
      fetchAdminTransactions();
    }
  },[ Week])

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              title={`Disbursed Staking Reward History`}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  dataLength={adminTransactions.records ? adminTransactions.records.length : 0}
                  subHeader={ disbursedStakingRewardOptions}
                 
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchClick={fetchAdminTransactions}
                  // searchvalue={ReferenceCode}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbursedStakingRewards;
