import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { handleModal } from "./GeneralAction";

//Logging User in and saving details to Local Storage
export const logInUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: _const.LOGGING_USER,
      payload: true,
    });
    axios
      .post(config.adminApiUrl + "/v1/auth/login", data)
      .then((res) => {
        dispatch({
          type: _const.LOGGING_USER,
          payload: false,
        });
        if (res.data.isSuccessful) {
          dispatch({
            type: _const.LOGIN_USER,
            payload: res.data.data,
          });
        } else {
          if (res.data.errors)
            dispatch({
              type: _const.LOGIN_FAIL,
              payload: res.data ? res.data.errors[0] : "",
            });
          else
            dispatch({
              type: _const.LOGIN_FAIL,
              payload: res.data ? res.data.message : "",
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.LOGGING_USER,
          payload: false,
        });
        if (err.response && err.response.data.errors) {
          dispatch({
            type: _const.LOGIN_FAIL,
            payload: err.response ? err.response.data.errors[0] : "",
          });
        } else {
          if (err.message === "Request failed with status code 401")
            dispatch({
              type: _const.LOGIN_FAIL,
              payload: "unAuthorized",
            });
          else
            dispatch({
              type: _const.LOGIN_FAIL,
              payload: err.message ? err.message : "",
            });
        }
      });
  };
};

export const resolveBvn = (bvnData, data) => {
  let bodyFormData = new FormData();
  bodyFormData.append("IdentityCardImage", data.IdentityCardImage);
  bodyFormData.append("PassportImage", data.PassportImage);
  bodyFormData.append("BvnNumber", data.BvnNumber);
  bodyFormData.append("CountryCode", data.CountryCode);
  return (dispatch) => {
    if (bvnData.firstName === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 5,
      });
    } else if (bvnData.lastName === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 6,
      });
    } else if (bvnData.bankId === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 7,
      });
    } else if (bvnData.accountNumber === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 8,
      });
    } else if (data.IdentityCardImage === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 1,
      });
    } else if (data.PassportImage === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 2,
      });
    } else if (data.BvnNumber.CountryCode === "") {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 3,
      });
    } else if (
      (data.BvnNumber.CountryCode === "NG") &
      (data.BvnNumber === "")
    ) {
      dispatch({
        type: _const.BVN_RESOLUTION_ERROR,
        payload: 4,
      });
    } else {
      dispatch({
        type: _const.UPGRADING_ACCT,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/accountVerification/ValidateBvn", bvnData)
        .then((res) => {
          dispatch({
            type: _const.UPGRADING_ACCT,
            payload: false,
          });
          if (res.data.data.isValid) {
            dispatch(verifyAcct(bodyFormData));
            // dispatch({
            //   type: _const.BVN_RESOLVED,
            //   payload: res.data.data.lastName + " " + res.data.data.firstName,
            // });
          } else {
            dispatch({
              type: _const.BVN_RESOLUTION_ERROR,
              payload: res.data.errors[0],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.UPGRADING_ACCT,
            payload: false,
          });
          dispatch({
            type: _const.BVN_RESOLUTION_ERROR,
            payload: err.response
              ? err.response.data.errors[0]
              : "Something went wrong",
          });
        });
    }
  };
};
// gett rate
export const getCountryCurrency = (country) => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/utils/countryRate?country=${country}`)
      .then((res) => {
        dispatch({
          type: _const.CURRENCY_RATE,
          payload: res.data.data,
        });
      })
      .catch((err) => {});
  };
};

//Verify acct
export const verifyAcct = (bodyFormData) => {
  return (dispatch) => {
    dispatch({
      type: _const.UPGRADING_ACCT,
      payload: true,
    });
    axios
      .post(
        config.apiUrl + "/v2/accountVerification/VerificationRequests",
        bodyFormData
      )
      .then((res) => {
        dispatch({
          type: _const.UPGRADING_ACCT,
          payload: false,
        });

        if (res.data.success) {
          Swal.fire({
            title: "Account Verification Submitted Successfully!",
            type: "success",
          });
          dispatch({
            type: _const.UPGRADING_ACCT_SUCCESS,
            payload: true,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.UPGRADING_ACCT,
          payload: false,
        });
        dispatch({
          type: _const.BVN_RESOLUTION_ERROR,
          payload: err.response
            ? err.response.data.message
            : "Something went wrong",
        });
      });
  };
};

//For registration using google
export const registerUser = (data) => {
  let datatosend = {
    name: data.name,
    referrer: data.referrer,
    email: data.email,
    password: data.password,
    macAddress: null,
  };
  return (dispatch) => {
    if (data.password === "") {
      dispatch({
        type: _const.REG_INPUT_ERROR,
        payload: "Password is required",
      });
    } else if (data.password !== data.confirmpassword) {
      dispatch({
        type: _const.REG_INPUT_ERROR,
        payload: "Passwords do not match",
      });
    } else {
      dispatch({
        type: _const.REG_INPUT_ERROR,
        payload: "",
      });
      dispatch({
        type: _const.REGISTERING_USER,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/auth/signup", datatosend)
        .then((res) => {
          dispatch({
            type: _const.REGISTERING_USER,
            payload: false,
          });
          if (res.data.success) {
            dispatch({
              type: _const.LOGIN_USER,
              payload: res.data.data,
            });
          } else {
            dispatch({
              type: _const.REG_ERROR,
              payload: res.data ? res.data.errors[0] : "Something went wrong",
              status: 0,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.REGISTERING_USER,
            payload: false,
          });
          dispatch({
            type: _const.REG_ERROR,
            payload: err.response
              ? err.response.data.message
              : "Something went wrong",
            status: 0,
          });
        });
    }
  };
};

//For getting user details on page refresh
export const initializeUser = () => {
  return (dispatch) => {
    let user = localStorage.getItem("user");

    if (user) {
      dispatch({
        type: _const.INITIALIZE_USER,
        payload: JSON.parse(user),
      });
    }
  };
};

//For Logging User Out
export const logOut = () => {
  return (dispatch) => {
    window.location.replace("/");
    localStorage.clear();
    dispatch({
      type: _const.LOG_OUT,
      payload: true,
    });
  };
};

//Forgot Password
export const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch({
      type: _const.RESETTING_PASSWORD,
      payload: true,
    });
    axios
      .post(config.apiUrl + `/v2/auth/password/forgot?email=${email}`)
      .then((res) => {
        dispatch({
          type: _const.RESETTING_PASSWORD,
          payload: false,
        });
        Swal.fire({
          text:
            "An email has been sent to this address,follow the link sent to you to continue",
          type: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.RESETTING_PASSWORD,
          payload: false,
        });
        Swal.fire({
          text: "Something went wrong!",
          type: "error",
        });
      });
  };
};

//Reset Password
export const resetPassword = (email, token, newPass, confirmnewPass) => {
  let data = {
    email: email,
    token: token,
    newPassword: newPass,
  };
  return (dispatch) => {
    if (newPass.length !== 4) {
      dispatch({
        type: _const.RESET_PASSWORD_FAIL,
        payload: "Password has to be 4 digits long!",
      });
    } else if (newPass !== confirmnewPass) {
      dispatch({
        type: _const.RESET_PASSWORD_FAIL,
        payload: "Passwords do not match",
      });
    } else {
      dispatch({
        type: _const.RESET_PASSWORD_FAIL,
        payload: "",
      });
      dispatch({
        type: _const.RESETTING_PASSWORD,
        payload: true,
      });
      axios
        .post(config.apiUrl + "/v2/auth/password/reset", data)
        .then((res) => {
          dispatch({
            type: _const.RESETTING_PASSWORD,
            payload: false,
          });

          if (res.data.success === true) {
            Swal.fire({
              title: "Password Changed Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.RESETTING_PASSWORD,
            payload: false,
          });
          Swal.fire({
            text: "Something went wrong!",
            type: "error",
          });
        });
    }
  };
};

//Registering merchant
export const registerMerch = (data) => {
  let bodyFormData = new FormData();
  bodyFormData.append("userId", data.userId);
  bodyFormData.append("name", data.name);
  bodyFormData.append("logoUrl", data.logoUrl);
  bodyFormData.append("officeAddress", data.officeAddress);
  bodyFormData.append("logoFile", data.logoFile);

  return (dispatch) => {
    if (data.logoFile === "") {
      dispatch({
        type: _const.MERCHANT_REG_ERROR,
        payload: "Logo file is required",
      });
    } else if (data.name === "") {
      dispatch({
        type: _const.MERCHANT_REG_ERROR,
        payload: "Business Name is required",
      });
    } else {
      dispatch({
        type: _const.MERCHANT_REG_ERROR,
        payload: "",
      });
      dispatch({
        type: _const.REGISTERING_MERCHANT,
        payload: true,
      });
      axios
        .post(config.apiUrl + `/v2/businesses?pageSize=${50000}`, bodyFormData)
        .then((res) => {
          dispatch({
            type: _const.REGISTERING_MERCHANT,
            payload: false,
          });

          if (res.data.success) {
            dispatch({
              type: _const.MERCHANT_REGISTERED,
              payload: true,
            });
            dispatch({
              type: _const.MERCHANT_REGISTRATION_SUCCESSFUL,
              payload: true,
            });
            // Swal.fire({
            //   text: "Registration Successful!",
            //   type: "success",
            // });
          } else {
            dispatch({
              type: _const.MERCHANT_REG_ERROR,
              payload: res.data.errors,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.REGISTERING_MERCHANT,
            payload: false,
          });
          dispatch({
            type: _const.MERCHANT_REG_ERROR,
            payload: err.response
              ? err.response.data.data
              : "Something went wrong",
          });
        });
    }
  };
};

//Get user by email
export const getByemail = (email) => {
  return (dispatch) => {
    if (email === "") {
    } else {
      dispatch({
        type: _const.GETTING_EMAIL,
        payload: true,
      });
      axios
        .get(config.apiUrl + `/v2/users/by?email=${email}`)
        .then((res) => {
          dispatch(handleModal(4));
          dispatch({
            type: _const.GETTING_EMAIL,
            payload: false,
          });
          dispatch({
            type: _const.FOUND_USER_INFO,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.BY_EMAIL_FAIL,
            payload: err.response
              ? err.response.data.errors[0]
              : "Something went wrong", 
          });
          dispatch({
            type: _const.GETTING_EMAIL,
            payload: false,
          });
        });
    }
  };
};

//Getting user's agent status (Archived because the Agent status have been added to the user object)
// export const getUserStatus = () => {
//   return (dispatch) => {
//     axios
//       .get(config.apiUrl + `/v2/users/AgentStatus`)
//       .then((res) => {
//         dispatch({
//           type: _const.IS_AGENT,
//           payload: res.data.data.isAgent,
//         });
//       })
//       .catch((err) => { });
//   };
// };
