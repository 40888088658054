import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import SingleSubscriberTopic from "./SingleSubscriberTopic";

const SingleTopic = ({ history }) => {
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav
            pageTitle={
              <span className="pointer" onClick={handleGoBack}>
                <i className="material-icons">arrow_back</i> Subscribers to the topic
              </span>
            }
          />
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          <SingleSubscriberTopic />
        </div>
      </MainContent>
    </div>
  );
};

export default SingleTopic;
