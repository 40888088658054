
import _const from '../actions/Types'

const initialState = {
    totalWithdrawals: [],
    chartData: [],
    yearData: [],
    month: 0,
    monthByte: 0,
    transactions: [],
    withdrawalTotalRecords: 0,
    withdrawalCurrentPage: 1,
    withdrawalTotalPages: 1,
    transactionsTotalRecords: 0,
    transactionsCurrentPage: 1,
    transactionsTotalPages: 1
}

const Session = (state = initialState, action) => {
    switch (action.type) {
        case _const.GET_ALL_WITHDRAWALS:
            return {
                ...state, totalWithdrawals: action.payload,
                withdrawalTotalRecords: action.totalrecords,
                withdrawalCurrentPage: action.currentPage,
                withdrawalTotalPages: action.totalPages
            }

        case _const.CLEAR_DATA:
            return { ...state, totalWithdrawals: [], transactions: [] }

        case _const.ALL_TRANSACTIONS:
            return {
                ...state, transactions: [...state.transactions, ...action.payload],
                transactionsTotalRecords: action.totalrecords, transactionsCurrentPage: action.currentPage,
                transactionsTotalPages: action.totalPages
            }

        case _const.GET_CHARTS:
            return { ...state, chartData: action.payload }

        case _const.GET_YEAR_CHARTS:
            return { ...state, yearData: action.payload }

        case _const.GET_MONTH_GRAPH:
            return { ...state, month: action.payload }

        case _const.GET_MONTH_BYTE:
            return { ...state, monthByte: action.payload }

        case _const.TRANSACTION_SUMMARY:
            return { ...state, summary: action.payload }

        default: return state
    }
}

export default Session