import _const from "../actions/Types"


const initialState = {
    
    transferError: "",
    maxWithdrable: ""
}

const WalletReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.TRANSFERING_WICRYPT_ERROR:
            return { ...state, transferError: action.payload }

        case _const.MAX_WITHDRAWABLE:
            return{...state, maxWithdrable: action.payload.amountWIthdrawable}
        default:
            return state
    }
}

export default WalletReducer