import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Business from "./Businesses";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import { connect } from "react-redux";
import BusinessAgents from "../BusinessAgents/businessAgents";

class AllBusiness extends Component {
  render() {
    const { toggleSubnav } = this.props;
    let businessArray = [
      { id: 1, item: "Registered Businesses" },
      { id: 2, item: "Business Agents" }
    ];
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white mb-5">
            <Mainnav pageTitle="Businesses" listItems={businessArray} />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            {toggleSubnav === 1 ? <Business /> : <BusinessAgents />}
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = state => {
  const { toggleSubnav } = state.General;
  return {
    toggleSubnav
  };
};

export default connect(MapstateToProps)(AllBusiness);
