import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { oneDailyTransactionReport } from "../../Redux/actions";
import { StakeStatData, StakeStatValue } from "./style";
import CustomLoader from "../Reusables/loaders/CustomLoader";


const Details = ({ selectedDate }) => {
    const { dailyReport } = useSelector((state) => state.Transactions);
    const { gettingDailyReports } = useSelector((state) => state.Loading)
    const { transactions, date } = dailyReport;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(oneDailyTransactionReport(selectedDate))
    }, [selectedDate]);


    return (
        <div className="col-lg-12">
            <div className="card w3-animate-bottom">
                <div className="card-body p-6 ">
                    { gettingDailyReports ? null : <h6 className="m-0 text-bold mb-5">{moment(date).format("DD-MM-YYYY")}</h6>}
                    {gettingDailyReports ? <CustomLoader /> : transactions && (
                        transactions.map(report => {
                            return (<div >
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <StakeStatData>Transaction Type</StakeStatData>
                                        <StakeStatValue>
                                            {report.transactionType}
                                        </StakeStatValue>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <StakeStatData>Total Amount</StakeStatData>
                                        <StakeStatValue>
                                            {report.totalAmount}
                                        </StakeStatValue>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <StakeStatData>Transaction Count</StakeStatData>
                                        <StakeStatValue>
                                            {report.transactionCount}
                                        </StakeStatValue>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <StakeStatData>Currency</StakeStatData>
                                        <StakeStatValue>
                                            {report.currency}
                                        </StakeStatValue>
                                    </div>

                                </div>
                                <hr />
                            </div>)
                        })

                    )}
                </div>
            </div>
        </div>
    );
};

export default Details;
