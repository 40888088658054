import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import { connect } from "react-redux";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Sessions from "./Sessions";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import ViewMore from "./ViewMore";
import { toggleModal } from "../../Redux/actions";

class Business extends Component {
  render() {
    const { bizsessionSummary, conversionsRate, clickedDeviceId } = this.props;

    var selectedsummary =
      clickedDeviceId === ""
        ? {}
        : bizsessionSummary.find((session) => {
            return session.sessionId === clickedDeviceId;
          });
    var empty = [undefined, "", null];
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav
              pageTitle={
                empty.includes(clickedDeviceId) ? (
                  "Sessions"
                ) : (
                  <p
                    className="pointer mb-0"
                    onClick={() => this.props.toggleModal(false, "")}
                  >
                    {" "}
                    <i className="material-icons">arrow_back</i> Session Summary
                  </p>
                )
              }
            />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            {empty.includes(clickedDeviceId) ? (
              <Sessions />
            ) : (
              <ViewMore
                heading="Session Summary"
                selectedsummary={selectedsummary}
                conversionsRate={conversionsRate}
              />
            )}
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { bizsessionSummary, conversionsRate } = state.Report;
  const { clickedDeviceId } = state.User;
  return {
    bizsessionSummary,
    conversionsRate,
    clickedDeviceId,
  };
};

export default connect(MapStateToProps, {
  toggleModal,
})(Business);
