import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  SubTopnavContainer,
  SubTopnavItem,
  SubTopnavList,
} from "./partials";
import { handleSubNav } from "../../Redux/actions";
import { changeActiveTab } from "./../../Redux/actions/UserAction";
import {changeTransactionType} from '../../Redux/actions/TransactionsAction';

const SubMainnav = ({listItems}) => {

  const dispatch = useDispatch();
  const toggleSubnav = useSelector((state) => state.General.toggleSubnav);
  const verificationTotalRecords = useSelector(
  (state) => state.Verification.verificationTotalRecords
  );

  return (
    <SubTopnavContainer>
      <SubTopnavList>
        {listItems.map((each) => {
          return (
            <SubTopnavItem
              key={each.id}
              active={each.id === toggleSubnav}
              onClick={() => {
                dispatch(handleSubNav(each.id));
                dispatch(changeActiveTab(1));
                dispatch(changeTransactionType("transfer"));

              }}
            >
              {each.item}{" "}
              {each.item === "Requests" && (
                <Badge submainnav={true}>
                  {verificationTotalRecords || 0}
                </Badge>
              )}
            </SubTopnavItem>
          );
        })}
      </SubTopnavList>
    </SubTopnavContainer>
  );
}

export default SubMainnav;
