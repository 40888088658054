import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import {connect} from 'react-redux';
import BreadCrumbs from "../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import SetMininReward from "./modals/SetMininReward";
import SelectDeviceType from "./modals/SelectDeviceType";
import AssignSubAdmin from "./modals/AssignSubAdmin";
import { changeActiveTab, clearForms,  handleSubNav,} from "../../Redux/actions";
import { changeTransactionType } from "../../Redux/actions/TransactionsAction";
import DeviceActivityLogs from "./DeviceActivityLogs";
import DeviceDetails from "./DeviceDetails";
import WhiteListedDevices from "./WhiteListedDevices";


class SingleDevice extends Component {

  constructor(props) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
  }


  handleGoBack(tab) {
    this.props.history.goBack();
    this.props.handleSubNav(tab);
  }

  render() {
    const {deviceId, toggleSubnav, deviceDetails} = this.props;
    const mainTab = localStorage.getItem("mainTab");
    const currentTab = localStorage.getItem("activeTab");
    let deviceArray = [
      { id: 1, item: "Device Details" },
      { id: 2, item: "Device Activity Logs" },
      { id: 3, item: "Whitelisted Devices" },
    ];

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link
                to={`/devices`}
                className="pointer mb-0"
                onClick={() => {
                  this.handleGoBack(JSON.parse(mainTab))
                  this.props.changeActiveTab(Number(currentTab));
                  this.props.changeTransactionType(
                      currentTab === 1
                        ? "all"
                        : currentTab === 2
                        ? "online"
                        : currentTab === 3
                        ? "offline"
                        : "blacklisted"
                    )
                  this.props.changeTransactionType("transfer");
                  this.props.clearForms();
                }}
              >
                {" "}
                <i className="material-icons">arrow_back</i>{" "}
                {deviceDetails && deviceDetails.ssid}
              </Link>
            }
            listItems={deviceArray}
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mb-5 mt-5">
          <SubSidenav />
          <SetMininReward />
          <SelectDeviceType />
          <AssignSubAdmin />
          {toggleSubnav === 1 ? 
            <DeviceDetails /> 
            : toggleSubnav === 2 ?
            <DeviceActivityLogs deviceId={deviceId}/> :
            <WhiteListedDevices deviceId={deviceId} /> 
          }
        </div>
      </MainContent>
    </div>
  );
  }
};

const MapstateToProps = (state, props) =>{
  const {deviceId} = props.match.params;
  const { toggleSubnav } = state.General;
  const {deviceDetails} = state.Admin;
  return{
    deviceId,
    toggleSubnav,
    deviceDetails
  }
}

export default connect(MapstateToProps, {handleSubNav, clearForms, changeActiveTab, changeTransactionType})(SingleDevice)