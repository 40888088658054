import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

export const getAdminInfo = () => {
  return (dispatch) => {
    dispatch({
      type: _const.LOADING_INFO,
      payload: true,
    });
    axios
      .get(config.apiUrl + "/v2/admin/dashboard")
      .then((res) => {
        dispatch({
          type: _const.LOADING_INFO,
          payload: false,
        });
        dispatch({
          type: _const.GET_ADMIN_INFO,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.LOADING_INFO,
          payload: false,
        });
      });
  };
};

export const changeLocation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.CHANGE_LOCATION,
      payload,
    });
  };
};
export const changeActiveTab = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.ACTIVE_TAB,
      payload,
    });
  };
};

export const switchInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.SWITCH_INFO,
      payload,
    });
  };
};
