import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";
import { getFormDetails, createAgents } from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";

class RegAgent extends Component {
  render() {
    const { creatingAgent, agentEmail, createError, agentRole } = this.props;
    let data = { email: agentEmail, role: agentRole };

    return (
      <Modal heading="CREATE AGENT">
        <label>Email </label>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control loginCred mb3"
            aria-describedby="basic-addon1"
            value={agentEmail}
            onChange={(e) =>
              this.props.getFormDetails({
                props: ["agentEmail"],
                value: e.target.value,
              })
            }
          />
        </div>
        {createError !== "" ? (
          <p className="errorMsgs">
            <i className="material-icons">info</i>
            {createError}
          </p>
        ) : (
          ""
        )}
        <Button
          theme=" ml-3 mt-2 float wicryptColor"
          buttonAction={() => this.props.createAgents(data)}
        >
          {creatingAgent ? <Loader /> : "Enter"}
        </Button>
      </Modal>
    );
  }
}

const MapStateToProps = (state) => {
  const { agentEmail, agentRole } = state.User;
  const { creatingAgent } = state.Loading;
  const { createError } = state.Agent;
  return {
    creatingAgent,
    agentEmail,
    createError,
    agentRole,
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  createAgents,
})(RegAgent);
