import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import ManagedDevices from './ManagedDevices'
import { MainContent } from '../Partials/partials'


class index extends Component{
    render() {
     
      return (
         <div className="row">
         <Sidenav/>
         
             <MainContent className="main-content">
                 <div className="main-navbar sticky-top bg-white">
                     <Mainnav/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4">
                 <ManagedDevices/>            
                 </div>
             </MainContent>
             
        </div>
      );
    }
  }

  const MapstateToProps = (state) =>{
   
    return{
      state
    }
  }
export default connect(MapstateToProps)( index)