import React, { Component } from "react";
// import { MDBDataTable } from "mdbreact";
import {
  getTransactions,
  getFormDetails,
  clearData
} from "../../Redux/actions";
import { connect } from "react-redux";
import { numberWithCommas } from "../../Helper";
import DualDatePicker from "../Reusables/cards/DualDatePicker";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import EmptyCard from "../Reusables/cards/EmptyCard";

class FundTable extends Component {
  componentWillMount() {
    const {
      transactionCategory,
      transactionSubCategory,
      transactionDateStart,
      transactionDateEnd,
      transactions
    } = this.props;

    if (transactions.length === 0) {
      this.props.getTransactions(
        transactionCategory,
        transactionSubCategory,
        transactionDateStart,
        transactionDateEnd,
        1,
        50
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      transactionCategory,
      transactionSubCategory,
      transactionDateStart,
      transactionDateEnd
    } = nextProps;

    if (
      transactionCategory !== this.props.transactionCategory ||
      transactionSubCategory !== this.props.transactionSubCategory ||
      transactionDateStart !== this.props.transactionDateStart ||
      transactionDateEnd !== this.props.transactionDateEnd
    ) {
      this.props.clearData();
      this.props.getTransactions(
        transactionCategory,
        transactionSubCategory,
        transactionDateStart,
        transactionDateEnd,
        1,
        50
      );
    }
  }

  customPagination() {
    const {
      transactionsTotalPages,
      transactionsCurrentPage,
      transactionDateStart,
      transactionSubCategory,
      transactionDateEnd
    } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== transactionsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getTransactions(
              "",
              transactionDateStart,
              transactionsCurrentPage - 1,
              30,
              transactionSubCategory,
              transactionDateEnd
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; transactionsTotalPages >= i; i++) {
      if (i === transactionsCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getTransactions(
                "",
                transactionDateStart,
                i,
                30,
                transactionSubCategory,
                transactionDateEnd
              )
            }
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (transactionsTotalPages > transactionsCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getTransactions(
              "",
              transactionDateStart,
              transactionsCurrentPage + 1,
              30,
              transactionSubCategory,
              transactionDateEnd
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      transactions,
      gettingTransactions,
      transactionDateStart,
      transactionDateEnd,
      conversionsRate
    } = this.props;

    let allRows = [];
    transactions.map(transaction => {
      return allRows.push({
        date: transaction.createdAt.slice(0, 10),
        name: transaction.user == null ? "" : transaction.user.name,
        email: transaction.user == null ? "" : transaction.user.email,
        amount:
          transaction.user == null
            ? ""
            : "NGN " + numberWithCommas(transaction.amount * conversionsRate),
        category: transaction.category,
        transactionType: transaction.isCredit === true ? "Credit" : "Debit",
        reference: transaction.reference
      });
    });

    const data = {
      columns: [
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 170
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270
        },
        {
          label: "Amount",
          field: "amount",
          sort: "asc",
          width: 270
        },
        {
          label: "Transaction Category",
          field: "category",
          sort: "asc",
          width: 270
        },
        {
          label: "Transaction type",
          field: "transactionType",
          sort: "asc",
          width: 270
        },

        {
          label: "Reference",
          field: "reference",
          sort: "asc",
          width: 150
        }
      ],

      rows: allRows
    };

    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3" style={{ width: "100%" }}>
          <div className="mt-3 row">
            <div className=" d-flex offset-lg-6 dateRange">
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["transactionCategory"],
                    value: e.target.value
                  })
                }
              >
                <option value={"Funding"}>Funding</option>
                <option value={"Withdraw"}> Withdraw</option>
                <option value={"WithdrawalReversal"}>
                  {" "}
                  WithdrawalReversal
                </option>
                <option value={"Transfer"}>Transfer</option>
                <option value={"Voucher"}>Voucher</option>
              </select>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["transactionSubCategory"],
                    value: e.target.value
                  })
                }
              >
                <option>Select Transaction Sub Category</option>
                <option value={"Crypto_Funding"}>Crypto Funding</option>
                <option value={"Bank_Transfer_Funding"}>
                  {" "}
                  Bank Transfer Funding
                </option>
                <option value={"Debit_Card_Funding"}>
                  {" "}
                  Debit Card Funding
                </option>
                <option value={"Bank_Transfer_withdrawal"}>
                  {" "}
                  Bank Transfer Withdrawal
                </option>
                <option value={"Wicrypt_Transfer"}> Wicrypt Transfer</option>
                <option value={"Crypto_Withdrawal"}> Crypto Withdrawal</option>
                <option value={"Voucher_Generation"}>
                  {" "}
                  Voucher Generation
                </option>
              </select>
            </div>
          </div>
        </div>
        {gettingTransactions & (transactions.length === 0) ? (
          <div className="mt-5 t-center">
            <CustomLoader size="10" />{" "}
          </div>
        ) : (
          <CustomDataTable
            data={data}
            pagination={this.customPagination()}
            placeholder={"Search name or email"}
            customright={
              <div className="d-flex">
                <DualDatePicker
                  dateTo={transactionDateEnd}
                  dateFrom={transactionDateStart}
                  dateFromLabel="transactionDateStart"
                  dateToLabel="transactionDateEnd"
                />
                {/* <div>
                  <p> filter option</p> 
                  <div>
                    <p> Transaction Type</p>
                  </div>
                  <div>
                    <p> Sub category</p>
                  </div>
                </div> */}
              </div>
            }
          />
        )}
        {gettingTransactions || transactions.length > 0 ? (
          ""
        ) : (
          <div className="row">
            <EmptyCard
              img="/Assets/emptytransactions.svg"
              title={
                "Sorry,  you have no transaction requests match the selected specs"
              }
              descr={
                "You can view transactions if they are available for the selected specs."
              }
            />{" "}
          </div>
        )}
      </div>
    );
  }
}
const MapStateToProps = state => {
  const {
    transactions,
    transactionsCurrentPage,
    transactionsTotalPages,
    transactionsTotalRecords
  } = state.Sessions;
  const { gettingTransactions } = state.Loading;
  const { conversionsRate } = state.Report;
  const {
    transactionSubCategory,
    transactionCategory,
    transactionDateStart,
    transactionDateEnd
  } = state.User;
  return {
    transactions,
    gettingTransactions,
    transactionSubCategory,
    transactionCategory,
    transactionDateStart,
    transactionDateEnd,
    conversionsRate,
    transactionsCurrentPage,
    transactionsTotalPages,
    transactionsTotalRecords
  };
};
export default connect(
  MapStateToProps,
  { getTransactions, getFormDetails, clearData }
)(FundTable);
