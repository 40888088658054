
import styled from "styled-components";
import { theme } from "../../theme";

export const CardTitle = styled.p`
    color: ${theme.black};
    font-weight: ${theme.textBold};
    margin-bottom: 0;
`
export const SectionWidth = styled.div`
    width: 40%
`
export const SectionHalfWidth = styled.div`
    width: 20%;
    text-align: right;
`
export const SectionValue = styled.p`
    margin-top: 0.2rem;
    font-size: ${theme.textSm};
    margin-bottom: 0;
    font-weight: ${theme.textBold}

`
export const FullWidth = styled.div`
    width: 100%
`
export const SubTitle = styled.p`
    color: ${theme.grey};
    font-weight: ${theme.textBold};
    margin-bottom: 0.5rem;
    font-size: ${theme.textSm}
`
export const Value = styled.p`
    color: ${theme.grey};
    font-weight: ${theme.textBold};
    margin-bottom: 0;
    font-size: 1rem
`
export const Icon = styled.i`  
    color: ${(props) => props.color? theme[props.color] : theme.yellow};
    cursor: pointer;
`
export const AgentCardBtn = styled.button`
    border: 1.096px solid rgba(172, 172, 172, 0.5);
    box-sizing: border-box;
    border-radius: 39.456px;
    padding:5px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 0.7rem;
    padding-left: 20px;
    color: #828282;
    background-color: transparent;
`
export const BlueValue = styled.p`
    color: ${theme.blue};
    font-weight: ${theme.textXBold};
    font-size: ${theme.textMd};
`