import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { numberWithCommas } from "../../Helper";
import {
  businessRemittance,
  getFormDetails,
  getallAgents,
  clearRemittance,
} from "../../Redux/actions";
import { connect } from "react-redux";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import Datepicker from "react-datepicker";
import moment from "moment";
import CustomDataTable from "../Reusables/tables/CustomDataTable";

class AgentRemmitance extends Component {
  componentWillMount() {
    const {
      selectedAgent,
      remitStart,
      remitEnd,
      remittanceReport,
      allAgents
    } = this.props;
    if (allAgents.length === 0) {
      this.props.getallAgents(1, 30, '');
    }
    if (remittanceReport.length === 0) {
      this.props.businessRemittance(selectedAgent, remitStart, remitEnd, 1, 50);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedAgent, remitStart, remitEnd } = nextProps;
    if (
      selectedAgent !== this.props.selectedAgent ||
      remitStart !== this.props.remitStart ||
      remitEnd !== this.props.remitEnd
    ) {
      this.props.clearRemittance();
      this.props.businessRemittance(selectedAgent, remitStart, remitEnd, 1, 50);
    }
  }

  customPagination() {
    const { selectedAgent, remittanceTotalPages, remittanceCurrentPage, remitStart, remitEnd } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessRemittance(selectedAgent, remitStart, remitEnd, remittanceCurrentPage - 1, 50)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; remittanceTotalPages >= i; i++) {
      if (i === remittanceCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.businessRemittance(selectedAgent, remitStart, remitEnd, i, 50)}
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (remittanceTotalPages > remittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.businessRemittance(selectedAgent, remitStart, remitEnd, remittanceCurrentPage + 1, 50)
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      remittanceReport,
      allAgents,
      remitStart,
      remitEnd,
      gettingRemittanceReport,
      conversionsRate,
    } = this.props;

    let allRows = [];
    remittanceReport.map((remit) => {
      return allRows.push({
        date: moment
          .parseZone(remit.remittanceDateTimeStamp)
          .format("YYYY-MM-DD"),
        username: remit.agent.user.userName,
        voucherRemittanceAllowance:
          remit.agent.voucherRemittanceAllowance +
          "WC | NGN" +
          numberWithCommas(
            remit.agent.voucherRemittanceAllowance * conversionsRate
          ),
        defaultRemittanceAllowance:
          remit.agent.business.defaultRemittanceAllowance +
          "WC | NGN" +
          numberWithCommas(
            remit.agent.business.defaultRemittanceAllowance * conversionsRate
          ),
        amount:
          remit.amountRemitted +
          "WC | NGN" +
          numberWithCommas(remit.amountRemitted * conversionsRate),
      });
    });

    const data = {
      columns: [
        {
          label: "Date Remitted",
          field: "date",
          sort: "asc",
          width: 170,
        },
        {
          label: "Agent Username",
          field: "username",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Remittance Allowance",
          field: "voucherRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Default Remittance Allowance",
          field: "defaultRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Amount Remitted",
          field: "amount",
          sort: "asc",
          width: 270,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3">
          <div className="d-flex">
            <h6 className="m-0">REMITTANCE REPORT</h6>
          </div>

          <div className="mt-3 d-flex">
            <div className="mr-3"> FROM</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitStart"],
                  value: e.getTime(),
                })
              }
              selected={remitStart}
              dateFormat={"dd/MM/yyyy"}
            />

            <div className="mr-3 ml-3"> TO</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["remitEnd"],
                  value: e.getTime(),
                })
              }
              selected={remitEnd}
              dateFormat={"dd/MM/yyyy"}
            />
            <select
              className="input-sm form-control"
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["selectedAgent"],
                  value: e.target.value,
                })
              }
            >
              <option value={''}>Select Agent</option>
              {allAgents.map((agent) => {
                return <option value={agent.user.id}>{agent.user.name}</option>;
              })}
            </select>
          </div>
        </div>
        {gettingRemittanceReport & (remittanceReport.length === 0) ? (
          <CustomLoader />
        ) : remittanceReport.length > 0 ? (
          <CustomDataTable
            data={data}
            pagination={this.customPagination()}
          />
        ) : (
              <p className="voucherText t-center">
                {" "}
            Select an agent and a date range to view valid data
              </p>
            )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const {
    remittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    conversionsRate,
  } = state.Report;
  const { allAgents } = state.Agent;
  const { selectedAgent, remitStart, remitEnd } = state.User;
  const { gettingRemittanceReport } = state.Loading;
  return {
    remittanceReport,
    allAgents,
    selectedAgent,
    remitStart,
    remitEnd,
    gettingRemittanceReport,
    remittanceTotalRecords,
    remittanceCurrentPage,
    remittanceTotalPages,
    conversionsRate,
  };
};
export default connect(
  MapStateToProps,
  { businessRemittance, getFormDetails, clearRemittance, getallAgents }
)(AgentRemmitance);
