import _const from "../actions/Types";

const initialState = {
  allAgents: [],
  agentDevices: [],
  createError: "",
  merchantAccount: "",
  agentSummary: [],
  hasMoreAgents: false,
  agentsCurrentPage: 1,
  agentsTotalPage: 1,
  bankName: '',
  merchantAccountName: '',
  deleteAgentSuccess: false,
  deleteAgentFail: ""
};

const AgentReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_AGENTS:
      return {
        ...state,
        allAgents: action.payload.data,
        hasMoreAgents: action.payload.meta.hasNext,
        agentsCurrentPage: action.payload.meta.page,
        agentsTotalPage: action.payload.meta.numberOfPages,
        deleteAgentSuccess: false
      };

    case _const.CLEAR_AGENTS:
      return { ...state, allAgents: action.payload };

    case _const.DEVICE_TO_ASSIGN:
      return { ...state, agentDevices: action.payload };

    case _const.CREATE_AGENT_ERROR:
      return { ...state, createError: action.payload };

    case _const.MERCHANT_ACCOUNT:
      return { ...state, merchantAccount: action.payload.accountNumber, merchantAccountName: action.payload.accountName, bankName: action.payload.bankName };

    case _const.CLEAR_FORMS:
      return { ...state, agentDevices: [] };

    case _const.AGENT_SUMMARY:
      return { ...state, agentSummary: action.payload };

    case _const.DELETE_AGENT_SUCEESS:
      return{...state, deleteAgentSuccess: action.payload}

    case _const.DELETE_AGENT_FAIL:
      return{...state, deleteAgentFail: action.payload}

    case _const.MODAL_STATE:
      return{...state, deleteAgentFail: ""}

    default:
      return state;
  }
};

export default AgentReducer;
