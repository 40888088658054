import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  LogList,
  LogDetails,
  LogStep
} from "./style";
import {
  numberWithCommaswithoutdecimals,
} from "../../Helper";

const GlobalActivityLog = () => {

  const { globalSpendActivity } = useSelector((state) => state.SpendControl)


  return (
    <div>
      <div className="mb-4 mt-5">
        <div className="card mb-4 w3-animate-bottom pt-5">
          <div className="row">

            <div className="col-lg-12">
              <ul>

                {globalSpendActivity.length > 0 ? globalSpendActivity.map(each => {
                  return each.activityLogDetail.length > 0 ? each.activityLogDetail.map(each => {
                    return (
                      <LogList>
                        <LogStep> <span>{moment(each.executedAt).format('DD-MM-YY') + " | " + each.action}</span></LogStep>
                        <LogStep> {each.actor}</LogStep>
                        <LogDetails> {each.initiatedByUser && "Initialted By: " + each.initiatedByUser.name} </LogDetails>
                        <LogDetails> {each.initiatedByUser && each.initiatedByUser.email} </LogDetails>
                        <LogStep className="mt-2">  {each.requestPayload && "Request Payload:"}</LogStep>
                        <LogDetails> {each.requestPayload && "Operation: " + each.requestPayload.operation} </LogDetails>
                        <LogDetails> {each.requestPayload && "Weekly Cummulative Volume: " + numberWithCommaswithoutdecimals(each.requestPayload.cumulativeVolume7days) + "WNT"} </LogDetails>
                        <LogDetails> {each.requestPayload && "Daily Cummulative Volume: " + numberWithCommaswithoutdecimals(each.requestPayload.dailyCumulativeVolume) + "WNT"} </LogDetails>
                        <LogDetails> {each.requestPayload && "Single Transaction Volume: " + numberWithCommaswithoutdecimals(each.requestPayload.singleTransactionVolume) + "WNT"} </LogDetails>
                        <LogDetails> {each.requestPayload && "Max Daily Transaction Count: " + each.requestPayload.maxDailyTransactionCount} </LogDetails>
                        <LogDetails> {each.requestPayload && "Max Weekly Transaction Count: " + each.requestPayload.maxTransactionCount7Days} </LogDetails>
                      </LogList>
                    )
                  }) : null
                }) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalActivityLog;
