const _const = {
  // business
  GETTING_BUSINESS_AGENTS: "GETTING_BUSINESS_AGENTS",
  BUSINESS_AGENTS: "BUSINESS_AGENTS",

  // Side nav actions
  CHANGE_ACTIVE: "CHANGE ACTIVE",
  CHANGE_FALSE: "CHANGE_FALSE",
  SAVE_BUSINESS_ID: "SAVE_BUSINESS_ID",
  TOGGLE_SUBNAV: "TOGGLE_SUBNAV",

  //General action
  FILE_TO_BASE: "FILE_TO_BASE",
  ACTIVE_TAB: "ACTIVE_TAB",
  CLEAR_MODAL: "CLEAR_MODAL",

  //Withdrawal actions
  GET_ALL_WITHDRAWALS: "GET_ALL_WITHDRAWALS",
  ALL_BANKS: "ALL_BANKS",
  OTP_ERROR: "OTP_ERROR",
  WITHDRAWAL_ERROR: "WITHDRAWAL_ERROR",
  ACCOUNT_NAME: "ACCOUNT_NAME",
  ACC_NAME_ERR: " ACC_NAME_ERR",
  SENDING_OTP: "SENDING_OTP",
  OTP_SENT: "OTP_SENT",
  GETTING_ACCT_NAME: "GETTING_ACCT_NAME",
  TRANSACTIONS_OVERVIEW: "TRANSACTIONS_OVERVIEW",
  GETTING_TRANSACTION_OVERVIEW: "GETTING_TRANSACTION_OVERVIEW",
  GETTING_CRYPT_WITHDRAWAL: "GETTING_CRYPT_WITHDRAWAL",
  ALL_CRYPTO_WITHDRAWALS: "ALL_CRYPTO_WITHDRAWALS",
  APPROVING_DELETING_CRYPTO: "APPROVING_DELETING_CRYPTO",
  FIELD_ACTION: "FIELD_ACTION",
  APPROVING_DELETE_SUCCESS: "APPROVING_DELETE_SUCCESS",
  WITHDRAWAL_SUCCESSFUL: "WITHDRAWAL_SUCCESSFUL",
  MAX_WITHDRAWABLE: "MAX_WITHDRAWABLE",

  //Merchant actions
  FETCHING_ALL_AGENTS: "FETCHING_ALL_AGENTS",
  ALL_AGENTS: "ALL_AGENTS",
  CREATING_AGENT: "CREATING_AGENT",
  CLEAR_AGENTS: "CLEAR_AGENTS",
  DELETING_AGENT: "DELETING_AGENT",
  DELETE_AGENT_SUCEESS: "DELETE_AGENT_SUCEESS",
  DELETING_AGENT_DEVICE: "DELETING_AGENT_DEVICE",
  MERCHANT_REGISTERED: "MERCHANT_REGISTERED",
  MERCHANT_REG_ERROR: "MERCHANT_REG_ERROR",
  BY_EMAIL_FAIL: "BY_EMAIL_FAIL",
  DEVICE_TO_ASSIGN: "DEVICE_TO_ASSIGN",
  ASSIGNING_DEVICE: "ASSIGNING_DEVICE",
  EDITING_AGENT: "EDITING_AGENT",
  CREATE_AGENT_ERROR: "CREATE_AGENT_ERROR",
  MERCHANT_ACCOUNT: "MERCHANT_ACCOUNT",
  FUND_HISTORY: "FUND_HISTORY",
  TOKEN_RATE: "TOKEN_RATE",
  GET_BUSINESS_BALANCE: "GET_BUSINESS_BALANCE",
  ALL_BUSINESSES: "ALL_BUSINESSES",
  CREATE_IMAGE_PREVIEW: "CREATE_IMAGE_PREVIEW",
  AFFILIATED_BUSINESSES: "AFFILIATED_BUSINESSES",
  GETTING_AGENT_BIZ_SUMMARY: "GETTING_AGENT_BIZ_SUMMARY",
  AGENT_BIZ_SUMMARY: "AGENT_BIZ_SUMMARY",
  BUSINESS_AGENT_DETAILS: "BUSINESS_AGENT_DETAILS",
  GETTING_VIRTUAL_ACCOUNT: "GETTING_VIRTUAL_ACCOUNT",

  //Loader actions
  LOADING_SESSIONS: "LOADING_SESSIONS",
  LOGGING_USER: "LOGGING_USER",
  LOADING_INFO: "LOADING_INFO",
  GETTING_MARKETERS: "GETTING_MARKETERS",
  GETTING_DATE_RANGE_DATA: "GETTINGDATERANGEDATA",
  LOADING_MANAGER: "LOADING_MANAGER",
  GETTING_BENEFICIAIRY: "GETTING_BENEFICIAIRY",
  GET_ALL_WICRYPT_DEVICES: "GET_ALL_WICRYPT_DEVICES",
  GET_ALL_TOTAL_DEVICES: "GET_ALL_TOTAL_DEVICES",
  GETTING_TOKENS: " GETTING_TOKENS",
  GET_ALL_TOTAL_DEVICES_ONCE:"GET_ALL_TOTAL_DEVICES_ONCE",
  ALL_TOTAL_DEVICES_ONCE:"ALL_TOTAL_DEVICES_ONCE",
  GETTING_USERS: "GETTING_USERS",
  DELETING_USER: "DELETING_USER",
  RESETTING_PASSWORD: "RESETTING_PASSWORD",
  EDITING_TOKEN: "EDITING_TOKEN",
  GETTING_TRANSACTIONS: "GETTING_TRANSACTIONS",
  GETTING_REQUESTS: "GETTING_REQUESTS",
  MAKING_WITHDRAWAL: "MAKING_WITHDRAWAL",
  CREATING_VOUCHER: "CREATING_VOUCHER",
  GETTING_VOUCHERS: "GETTING_VOUCHERS",
  GETTING_ONE_AGENT: "GETTING_ONE_AGENT",
  GETTING_TRANSACTION_HISTORY: "GETTING_TRANSACTION_HISTORY",
  RESOLVING_BVN: "RESOLVING_BVN",
  UPGRADING_ACCT: "UPGRADING_ACCT",
  UPGRADING_ACCT_SUCCESS: "UPGRADING_ACCT_SUCCESS",
  GETTING_INDIVIDUAL_VOUCHERS: "GETTING_INDIVIDUAL_VOUCHERS",
  LOADING_WITHDRAWALS: "LOADING_WITHDRAWALS",
  GETTING_ALL_BUSINESSES: "GETTING_ALL_BUSINESSES",
  SETTING_TRACKER: "SETTING_TRACKER",
  GETTING_TRACKER_INFO: "GETTING_TRACKER_INFO",
  DEVICE_TRACKER_INFO: "DEVICE_TRACKER_INFO",
  GETTING_BUSINESS_DEVICES: "GETTING_BUSINESS_DEVICES",
  CREATING_BULK_VOUCHERS: "CREATING_BULK_VOUCHERS",
  GETTING_OUTSTANDING_REMITTANCE: "GETTING_OUTSTANDING_REMITTANCE",
  GETTING_VOUCHER_BALANCE: "GETTING_VOUCHER_BALANCE",
  GETTING_ONE_BUSINESS_AGENT: "GETTING_ONE_BUSINESS_AGENT",
  GETTING_DEVICE_SESION_SUMMARY: "GETTING_DEVICE_SESION_SUMMARY",

  //Funding actions
  ALL_TRANSACTIONS: "ALL_TRANSACTIONS",
  TRANSACTION_SUMMARY: "TRANSACTION_SUMMARY",
  GETTING_ADMIN_TRANSACTIONS: "GETTING_ADMIN_TRANSACTIONS",
  ADMIN_TRANSACTIONS: "ADMIN_TRANSACTIONS",
  SET_TRANSACTION_TYPE: "SET_TRANSACTION_TYPE",

  //Authentication actions
  LOGIN_USER: "LOGIN_USER",
  LOG_OUT: "LOG_OUT",
  REG_INPUT_ERROR: "REG_INPUT_ERROR",
  INITIALIZE_USER: "INITIALIZE_USER",
  REGISTERING_USER: "REGISTERING_USER",
  UPDATE_LOGIN: "UPDATE_LOGIN",
  LOGIN_FAIL: "LOGIN_FAIL",
  EMPTY_FORM: "EMPTY_FORM",
  EYE_STATUS: "EYE_STATUS",
  REGISTERING_MERCHANT: "REGISTERING_MERCHANT",
  REG_ERROR: "REG_ERROR",
  BVN_RESOLVED: "BVN_RESOLVED",
  BVN_RESOLUTION_ERROR: "BVN_RESOLUTION_ERROR",
  VERIFICATION_ERR: "VERIFICATION_ERR",
  IS_AGENT: "IS_AGENT",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  //Admin actions
  GET_ADMIN_INFO: "GET_ADMIN_INFO",
  CHANGE_LOCATION: "CHANGE_LOCATION",
  SWITCH_INFO: "SWITCH_INFO",
  BUSINESS_REPORT_SUMMARY: "BUSINESS_REPORT_SUMMARY",
  GETTING_BUSINESS_REPORT: "GETTING_BUSINESS_REPORT",
  GETTING_BUSINESS_SESSIONS: "GETTING_BUSINESS_SESSIONS",
  BUSINESS_SESSIONS_SUMMARY: "BUSINESS_SESSIONS_SUMMARY",
  GETTIND_DEVICE_SESSIONS: "GETTIND_DEVICE_SESSIONS",
  SESSION_STATISTICS: "SESSION_STATISTICS",
  GETTING_MINING_STATS: "GETTING_MINING_STATS",
  MINING_STATISTICS: "MINING_STATISTICS",
  GETTING_TOTAL_USERS: "GETTING_TOTAL_USERS",
  TOTAL_USERS: "TOTAL_USERS",
  GETTING_TOTAL_ADMINS: "GETTING_TOTAL_ADMINS",
  TOTAL_ADMINS: "TOTAL_ADMINS",
  GETTING_DEVICE_STATUS_COUNT: "GETTING_DEVICE_STATUS_COUNT",
  DEVICE_STATUS_COUNT: "DEVICE_STATUS_COUNT",
  SINGLE_TRANSACTION_DETAILS: "SINGLE_TRANSACTION_DETAILS",
  GETTING_LIVE_SESSION_HISTORY: "GETTING_LIVE_SESSION_HISTORY",
  LIVE_SESSION_HISTORY: "LIVE_SESSION_HISTORY",

  // search actions
  SEARCH_VALUE: "SEARCH_VALUE",
  CLEAR_SEARCH: "CLEAR_SEARCH",

  //Chart actions
  GET_CHARTS: "GET_CHART",
  GET_YEAR_CHARTS: "GET_YEAR_CHARTS",
  GET_MONTH_GRAPH: "GET_MONTH_GRAPH",
  GET_MONTH_BYTE: "GET_MONTH_BYTE",

  //Marketer actions
  GET_MARKETERS: "GET_MARKETERS",
  UPDATE_REFERAL_CODE: "UPDATE_REFERAL_CODE",
  GETTING_MARKETERS_FAILED: "GETTING_MARKETERS_FAILED",

  //Overview actions
  GET_DATETO: "GET_DATETO",
  GET_DATEFROM: "GET_DATEFROM",
  DATA_RANGE_DATA: "DATARANGEDATA",
  PICK_A_RANGE: "PICK_A_RANGE",

  //Verification actions
  GET_VERIFICATION_REQUESTS: "GET_VERIFICATION_REQUESTS",
  APPROVING_VERFIFICATION: "APPROVING_VERFIFICATION",
  APPROVE_VERIFICATION: "APPROVE_VERIFICATION",
  DECLINING_VERFIFICATION: "DECLINING_VERFIFICATION",
  ONE_VERIFICATION_REQUEST: "ONE_VERIFICATION_REQUEST",
  TAKE_USER_BACK: "TAKE_USER_BACK",
  VERIFICATION_TYPES: "VERIFICATION_TYPES",
  VERIFYING_ID_CARD: "VERIFYING_ID_CARD",
  ID_VERIFICATION_SUCCESS: "ID_VERIFICATION_SUCCESS",
  ID_VERIFICATION_FAIL: "ID_VERIFICATION_FAIL",
  GETTING_VERIFICATION_SUMMARY: "GETTING_VERIFICATION_SUMMARY",
  VERIFICATION_SUMMARY: "VERIFICATION_SUMMARY",
  GETTING_ALL_KYC_REQUESTS: "GETTING_ALL_KYC_REQUESTS",
  ALL_KYC_REQUESTS:"ALL_KYC_REQUESTS",

  //Manager actions
  GET_MANAGER_INFO: "GET_MANAGER_INFO",
  EDIT_USERINFO: "EDIT_USERINFO",
  FILL_OUT_FORM: "FILL_OUT_FORM",
  GET_DEVICES: "GET_DEVICES",
  GET_ALL_DEVICES: "GET_ALL_DEVICES",
  SETTING_RATE: "SETTING_RATE",
  RATE_SET: " RATE_SET",
  GET_DEVICE_DETAILS: "GET_DEVICE_DETAILS",
  EDITING_DEVICE: "EDITING_DEVICE",
  GET_TOKENS: " GET_TOKENS",
  TOKENATED_USERS: "TOKENATED_USERS",
  TOKEN_PAGE: "TOKEN_PAGE",
  CLICKED_TOKEN_ID: "CLICKED_TOKEN_ID",
  DELETED: "DELETED",
  CLEAR_FORMS: "CLEAR_FORMS",
  TOKEN_SET: "TOKEN_SET",
  GET_CURRENCY_RATES: "GET_CURRENCY_RATES",
  GETTING_EMAIL: "GETTING_EMAIL",
  FOUND_USER_INFO: "FOUND_USER_INFO",
  SET_TOKEN_ERROR: "SET_TOKEN_ERROR",
  DELETING_DEVICE: "DELETING_DEVICE",
  DELETING_TOKEN: "DELETING_TOKEN",
  CREATING_DEVICE: "CREATING_DEVICE",
  TOTAL_REVENUE: "TOTAL_REVENUE",
  CREATING_SUB_BUSINESS: "CREATING_SUB_BUSINESS",
  ALL_SUB_BUSINESS: "ALL_SUB_BUSINESS",
  CLEAR_SUB_BUSINESS: "CLEAR_SUB_BUSINESS",
  GETTING_SUB_BUSINESS: "GETTING_SUB_BUSINESS",
  BUSINESS_PROFILE: "BUSINESS_PROFILE",
  GETTING_DEVICES_BY_BUSINESS: "GETTING_DEVICES_BY_BUSINESS",
  ERROR_WHILE_SETTING_UP_TRACKER: "ERROR_WHILE_SETTING_UP_TRACKER",
  GETTING_ALL_Agent_BUSINESSES: "GETTING_ALL_Agent_BUSINESSES",
  ALL_Agent_BUSINESSES: "ALL_Agent_BUSINESSES",

  //Modal Actions
  MODAL_STATE: "MODAL_STATE",

  //Report Actions
  DEVICE_SUMMARY: "DEVICE_SUMMARY",
  GETTING_DEVICE_REPORT: "GETTING_DEVICE_REPORT",
  GETTING_AGENT_REPORT: "GETTING_AGENT_REPORT",
  AGENT_SUMMARY: "AGENT_SUMMARY",
  ONE_AGENT_REPORT: "ONE_AGENT_REPORT",
  CLEAR_TRANSACTION_HISTORY: "CLEAR_TRANSACTION_HISTORY",
  GETTING_REMITTANCE_REPORT: "GETTING_REMITTANCE_REPORT",
  REMITTANCE_REPORT: "REMITTANCE_REPORT",
  CLEAR_REMITTANCE_REPORT: "CLEAR_REMITTANCE_REPORT",
  SETTING_REMITTANCE: "SETTING_REMITTANCE",
  REMITTANCE_FAIL: "REMITTANCE_FAIL",
  REMIT_SUCCESS: "REMIT_SUCCESS",
  OUTSTANDING_REMITTANCE_REPORT: "OUTSTANDING_REMITTANCE_REPORT",
  ALL_BUSINESS_AGENTS: "ALL_BUSINESS_AGENTS",
  GETTING_AGENT_DATA_USAGE: "GETTING_AGENT_DATA_USAGE",
  BUSINESSAGENT_DATA_USAGE: "BUSINESSAGENT_DATA_USAGE",

  //Voucher Actions
  CREATE_VOUCHER_ERROR: "CREATE_VOUCHER_ERROR",
  ALL_VOUCHERS: "ALL_VOUCHERS",
  ALL_INDIVIDUAL_VOUCHERS: "ALL_INDIVIDUAL_VOUCHERS",
  BULK_VOUCHER_ERROR: "BULK_VOUCHER_ERROR",
  GETTING_USER_GENERATED_VOUCHERS: "GETTING_USER_GENERATED_VOUCHERS",
  ALL_USER_GENERATED_VOUCHERS: "ALL_USER_GENERATED_VOUCHERS",
  VOUCHER_BALANCE: "VOUCHER_BALANCE",
  VOUCHER_BALANCE_ERR: "VOUCHER_BALANCE_ERR",

  //Active Actions
  TEXT_COPIED: "TEXT_COPIED",
  UPLOAD_IMAGE_FILE: "UPLOAD_IMAGE_FILE",
  TOGGLE_NAV: "TOGGLE_NAV",
  CLEAR_DATA: "CLEAR_DATA",

  //Service acction
  GETTING_SERVICE_CHARGE: "GETTING_SERVICE_CHARGE",
  ALL_SERVICE_CHARGE: "ALL_SERVICE_CHARGE",
  DELETING_SERVICE_CHARGE: "DELETING_SERVICE_CHARGE",
  CREATING_SRVICE_CHARGE: "CREATING_SRVICE_CHARGE",
  CHARGE_FAILED: "CHARGE_FAILED",

  //Dynamic form action
  CREATING_FORM: "CREATING_FORM",
  ACTIVATING_FORM: "ACTIVATING_FORM",
  DEACTIVATING_FORM: "DEACTIVATING_FORM",
  GETTING_DYNAMIC_FORMS: "GETTING_DYNAMIC_FORMS",
  CREATING_FORM_ERR: "CREATING_FORM_ERR",
  ALL_FORMS: "ALL_FORMS",
  GET_FORM_CODE: "GET_FORM_CODE",
  CLEAR_DYNAMIC_FORMS: "CLEAR_DYNAMIC_FORMS",
  GETTING_DYNAMIC_FORMS_REPORT: "GETTING_DYNAMIC_FORMS_REPORT",
  DYNAMIC_FORM_REPORT: "DYNAMIC_FORM_REPORT",
  CLEAR_FORM_RESPONSES: "CLEAR_FORM_RESPONSES",
  FORM_CREATED: "FORM_CREATED",
  DELETING_FORM: "DELETING_FORM",

  //Countries
  GETTING_COUNTRIES: "GETTING_COUNTRIES",
  ALL_COUNTRIES: "ALL_COUNTRIES",
  CREATING_COUNTRY: "CREATING_COUNTRY",
  COUNTRY_CREATED: "COUNTRY_CREATED",
  EDITING_COUNTRY: "EDITING_COUNTRY",
  COUNTRY_EDITED: "COUNTRY_EDITED",
  GETTING_ONE_COUNTRY: "GETTING_ONE_COUNTRY",
  ONE_COUNTRY: "ONE_COUNTRY",
  ERROR_EDITING_COUNTRY: "ERROR_EDITING_COUNTRY",

  //Adverts
  GETTING_ALL_ADVERTS: "GETTING_ALL_ADVERTS",
  ALL_ADVERTS: "ALL_ADVERTS",
  CREATING_ADVERT: "CREATING_ADVERT",
  USER_ADVERT_CREATED: "USER_ADVERT_CREATED",
  APPROVING_ADVERT: "APPROVING_ADVERT",
  ADVERT_APPROVED: "ADVERT_APPROVED",
  REJECTING_ADVERT: "REJECTING_ADVERT",
  ADVERT_REJECTED: "ADVERT_REJECTED",
  GETTING_LIVE_ADVERTS: "GETTING_LIVE_ADVERTS",
  ALL_LIVE_ADVERTS: "ALL_LIVE_ADVERTS",
  ERROR_CREATING_ADVERT: "ERROR_CREATING_ADVERT",
  SELECTED_ID: "SELECTED_ID",
  GET_ADVERT_BY_ID: "GET_ADVERT_BY_ID",
  ERROR_APPROVING_ADVERT: "ERROR_APPROVING_ADVERT",
  ERROR_REJECTING_ADVERT: "ERROR_REJECTING_ADVERT",

  //Admins
  UPDATING_BILLING_TYPE: "UPDATING_BILLING_TYPE",
  ERROR_UPDATING_BILLING_TYPE: "ERROR_UPDATING_BILLING_TYPE",
  GETTING_ACTIVE_ADMINS: "GETTING_ACTIVE_ADMINS",
  GETTING_INACTIVE_ADMINS: "GETTING_INACTIVE_ADMINS",
  ACTIVE_ADMINS: "ACTIVE_ADMINS",
  INACTIVE_ADMINS: "INACTIVE_ADMINS",
  GETTING_ADMIN_DEVICES: "GETTING_ADMIN_DEVICES",
  ADMIN_DEVICES: "ADMIN_DEVICES",
  GETTING_ADMIN_LIVE_SESSIONS: "GETTING_ADMIN_LIVE_SESSIONS",
  ADMIN_LIVE_SESSIONS: "ADMIN_LIVE_SESSIONS",
  GETTING_DEVICE_TRACK_DATA: "GETTING_DEVICE_TRACK_DATA",
  DEVICE_TRACK_DATA: "DEVICE_TRACK_DATA",
  GETTING_LATEST_DEVICES: "GETTING_LATEST_DEVICES",
  LATEST_DEVICES: "LATEST_DEVICES",
  BLOCKING_DEVICE: "BLOCKING_DEVICE",

  // statke
  GETTING_STAKE_HISTORY: "GETTING_STAKE_HISTORY",
  STAKE_HISTORY: "STAKE_HISTORY",

  //unstake
  GETTING_UNSTAKE_SUMMARY: "GETTING_UNSTAKE_SUMMARY",
  UNSTAKE_SUMMARY: "UNSTAKE_SUMMARY",
  GETTING_STAKING_STATISTICS_REPORT: "GETTING_STAKING_STATISTICS_REPORT",
  STAKING_STATISTICS_REPORT: "STAKING_STATISTICS_REPORT",
  GETTING_DISTRIBUTED_REWARD: "GETTING_DISTRIBUTED_REWARD",
  DISTRIBUTED_REWARD_OVERVIEW: "DISTRIBUTED_REWARD_OVERVIEW",
  GETTING_DISTRIBUTED_REWARD_DETAILS: "GETTING_DISTRIBUTED_REWARD_DETAILS",
  DISTRIBUTED_REWARD_DETAILS: "DISTRIBUTED_REWARD_DETAILS",
  GETTING_UPCOMING_UNSTAKE_REPORT: "GETTING_UPCOMING_UNSTAKE_REPORT",
  UPCOMING_UNSTAKE_REPORT: "UPCOMING_UNSTAKE_REPORT",
  GETTING_UPCOMING_UNSTAKE_OVERVIEW: "GETTING_UPCOMING_UNSTAKE_OVERVIEW",
  UPCOMING_UNSTAKE_OVERVIEW: "UPCOMING_UNSTAKE_OVERVIEW",

  //Token
  CREATING_BUSINESS_TOKEN: "CREATING_BUSINESS_TOKEN",
  ERROR_CREATING_BUSINESS_TOKEN: "ERROR_CREATING_BUSINESS_TOKEN",
  GETTING_ALL_BUSINESS_TOKEN: "GETTING_ALL_BUSINESS_TOKEN",
  ALL_BUSINESS_TOKEN: "ALL_BUSINESS_TOKEN",
  //Subscription
  GETTING_SUBSCRIPTION_PLANS: "GETTING_SUBSCRIPTION_PLANS",
  ALL_SUBSCRIPTION_PLANS: "ALL_SUBSCRIPTION_PLANS",
  CREATING_SUBSCRIPTION_PLANS: "CREATING_SUBSCRIPTION_PLANS",
  EDITING_SUBSCRIPTION_PLANS: "EDITING_SUBSCRIPTION_PLANS",
  CREATING_SUBSCRIPTION_ERROR: "CREATING_SUBSCRIPTION_ERROR",
  GETTING_SUBSCRIPTION_BY_LOCALITY: "GETTING_SUBSCRIPTION_BY_LOCALITY",
  ALL_SUBSCRIPTION_BY_LOCALITY: "ALL_SUBSCRIPTION_BY_LOCALITY",
  GETTING_SUBSCRIPTION_HISTORY: "GETTING_SUBSCRIPTION_HISTORY",
  ALL_SUBSCRIPTION_HISTORY: "ALL_SUBSCRIPTION_HISTORY",
  UPDATING_SUBSCRIPTION: "UPDATING_SUBSCRIPTION",
  CURRENT_SUBSCRIPTION_PLAN: "CURRENT_SUBSCRIPTION_PLAN",
  PLAN_TYPE: "PLAN_TYPE",
  ALL_USER_TOKEN: "ALL_USER_TOKEN",
  GETTING_ALL_USER_TOKEN: "GETTING_ALL_USER_TOKEN",
  ALL_USER_TOKEN_ERR: "ALL_USER_TOKEN_ERR",
  GETTING_ALL_ADMIN_TOKENS: "GETTING_ALL_ADMIN_TOKENS",
  ALL_ADMIN_TOKENS: "ALL_ADMIN_TOKENS",
  ALL_ADMIN_TOKEN_ERR: "ALL_ADMIN_TOKEN_ERR",

  BUSINESS_TOKEN_CREATED: "BUSINESS_TOKEN_CREATED",

  //Transfer
  TRANSFERING_CREDIT: "TRANSFERING_CREDIT",
  CREDIT_TRANSFERRED: "CREDIT_TRANSFERRED",
  TRANSFERING_WICRYPT_ERROR: "TRANSFERING_WICRYPT_ERROR",

  TOGGLE_MODAL: "TOGGLE_MODAL",

  //Sessions
  GETTING_DEVICE_SESIONS: "GETTING_LIVE_SESIONS",
  DEVICE_SESSION_HISTORY: "DEVICE_SESSION_HISTORY",
  MERCHANT_REGISTRATION_SUCCESSFUL: "MERCHANT_REGISTRATION_SUCCESSFUL",
  DEVICE_SESSION_SUMMARY: "DEVICE_SESSION_SUMMARY",

  //Agent actions
  DELETE_AGENT_FAIL: "DELETE_AGENT_FAIL",

  //Users
  GETTING_ALL_USERS: "GETTING_ALL_USERS",
  ALL_USERS: "ALL_USERS",

  // Admin Devicess
  ALL_TOTAL_DEVICES: "ALL_TOTAL_DEVICES",
  GETTING_ALL_WICRYPT_DEVICES: "GETTING_ALL_WICRYPT_DEVICES",
  GETTING_DEVICE_DETAILS: "GETTING_DEVICE_DETAILS",
  DEVICE_DETAILS: "DEVICE_DETAILS",

  
  //Remote Commands 
  GETTING_COMMANDS: "GETTING_COMMANDS",
  ALL_COMMANDS: "ALL_COMMANDS",
  CREATING_COMMANDS: "CREATING_COMMANDS",
  CREATING_COMMANDS_SUCCESSFUL: "CREATING_COMMANDS_SUCCESSFUL",
  CREATING_COMMANDS_ERROR: "CREATING_COMMANDS_ERROR",
  GETTING_DEVICES_COMMANDS: "GETTING_DEVICES_COMMANDS",
  ALL_DEVICES_BY_COMMANDS: "ALL_DEVICES_BY_COMMANDS",
  CREATING_SINGLE_MODE_COMMAND: "CREATING_SINGLE_MODE_COMMAND",
  CREATING_SINGLE_MODE_COMMAND_SUCCESSFUL: "CREATING_SINGLE_MODE_COMMAND_SUCCESSFUL",
  CREATING_BATCH_MODE_COMMAND: "CREATING_BATCH_MODE_COMMAND",
  CREATING_BATCH_MODE_COMMAND_SUCCESSFUL: "CREATING_BATCH_MODE_COMMAND_SUCCESSFUL",
  DELETING_COMMAND: "DELETING_COMMAND",
  DELETING_COMMAND_SUCCESSFUL: "DELETING_COMMAND_SUCCESSFUL",


  //Staking
  GETTING_STAKE_LOG: "GETTING_STAKE_LOG",
  STAKE_LOG: "STAKE_LOG",
  GETTING_STAKE_SUMMARY: "GETTING_STAKE_SUMMARY",
  STAKING_SUMMARY: "STAKING_SUMMARY",

  //Spend Control
  GETTING_GLOBAL_SPEND_CONTROL: "GETTING_GLOBAL_SPEND_CONTROL",
  GLOBAL_SPEND_CONTROL: "GLOBAL_SPEND_CONTROL",
  GETTING_USERS_SPEND_CONTROL: "GETTING_USERS_SPEND_CONTROL",
  USERS_SPEND_CONTROL: "USERS_SPEND_CONTROL",
  GETTING_USER_SPEND_CONTROL: "GETTING_USER_SPEND_CONTROL",
  USER_SPEND_CONTROL: "USER_SPEND_CONTROL",
  GETTING_GLOBAL_SPEND_CONTROL_ACTIVITY: "GETTING_GLOBAL_SPEND_CONTROL_ACTIVITY",
  GLOBAL_SPEND_CONTROL_ACTIVITY: "GLOBAL_SPEND_CONTROL_ACTIVITY",
  GETTING_USER_SPEND_CONTROL_ACTIVITY: "GETTING_USER_SPEND_CONTROL_ACTIVITY",
  USER_SPEND_CONTROL_ACTIVITY: "USER_SPEND_CONTROL_ACTIVITY",
  CREATING_GLOBAL_SPEND_CONTROL: "CREATING_GLOBAL_SPEND_CONTROL",
  GLOBAL_SPEND_CONTROL_CREATED: "GLOBAL_SPEND_CONTROL_CREATED",
  CREATING_USER_SPEND_CONTROL: "CREATING_USER_SPEND_CONTROL",
  USER_SPEND_CONTROL_CREATED: "USER_SPEND_CONTROL_CREATED",
  USER_SPEND_CONTROL_ERROR: "USER_SPEND_CONTROL_ERROR",
  GLOBAL_SPEND_CONTROL_ERROR: "GLOBAL_SPEND_CONTROL_ERROR",


  //Fraud List
  GETTING_FRAUD_LIST: "GETTING_FRAUD_LIST",
  FRAUD_LIST: "FRAUD_LIST",
  GETTING_FRAUD_REPORT: "GETTING_FRAUD_REPORT",
  FRAUD_REPOPRT: "FRAUD_REPOPRT",
  REMOVING_FROM_LIST: "REMOVING_FROM_LIST",

  //Account Reconciliation
  GETTING_DAILY_REPORTS: "GETTING_DAILY_REPORTS",
  DAILY_REPORTS: "DAILY_REPORTS",
  GETTING_DAILY_USER_REPORTS: "GETTING_DAILY_USER_REPORTS",
  GETTING_DAILY_SWITCHWALLET_REPORTS: "GETTING_DAILY_SWITCHWALLET_REPORTS",
  SWITCHWALLET_REPORT: "SWITCHWALLET_REPORT",
  DAILY_REPORT: "DAILY_REPORT",
  SWITCHWALLET_DAILY_REPORT: "SWITCHWALLET_DAILY_REPORT",


  //Devices
  DEVICE_ACTIVITY_LOGS: "DEVICE_ACTIVITY_LOGS",
  GETTING_ACTIVITY_LOGS: "GETTING_ACTIVITY_LOGS",
  WHITELISTED_DEVICES: "WHITELISTED_DEVICES",
  GETTING_WHITELISTED_DEVICES: "GETTING_WHITELISTED_DEVICES",
  DEVICE_MODELS: "DEVICE_MODELS",
  GETTING_DEVICE_MODELS: "GETTING_DEVICE_MODELS",
  DEVICE_MANUFACTURERS: "DEVICE_MANUFACTURERS",
  GETTING_DEVICE_MANUFACTURERS: "GETTING_DEVICE_MANUFACTURERS",

  //Email Providers
  GET_PROVIDERS: "GET_PROVIDERS",
  GETTING_PROVIDERS: "GETTING_PROVIDERS",
  UPDATING_PROVIDER_STATUS: "UPDATING_PROVIDER_STATUS",
  ERROR_UPDATING_PROVIDER_STATUS: "ERROR_UPDATING_PROVIDER_STATUS",

  //Node Providers
  GET_NODE_PROVIDERS: "GET_NODE_PROVIDERS",
  CREATING_NODE_PROVIDER_STATUS: "CREATING_NODE_PROVIDER_STATUS",
  GETTING_NODE_PROVIDERS: "GETTING_NODE_PROVIDERS",
  UPDATING_NODE_PROVIDER_STATUS: "UPDATING_NODE_PROVIDER_STATUS",
  CREATING_NODE_PROVIDER_FAILED: "CREATING_NODE_PROVIDER_FAILED",

  //Token Remittance
  GETTING_UNREMITTED_TOKENS: "GETTING_UNREMITTED_TOKENS",
  UNREMITTED_TOKENS: "UNREMITTED_TOKENS",
  INITIATING_REMITTANCE_COMMAND: "INITIATING_REMITTANCE_COMMAND",
  INITIATING_REMITTANCE_FAILED: "INITIATING_REMITTANCE_FAILED",
  
  //Currencies 
  GETTING_CURRENCIES: "GETTING_CURRENCIES",
  GET_CURRENCIES: "GET_CURRENCIES",
  ENABLING_CURRENCY: "ENABLING_CURRENCY",
  CURRENCY_ENABLE_STATUS: "CURRENCY_ENABLE_STATUS",
  CREATING_CURRENCY: "CREATING_CURRENCY",
  CURRENCY_CREATION_STATUS: "CURRENCY_CREATION_STATUS",

  //Operations
  GETTING_OPERATIONS: "GETTING_OPERATIONS",
  GET_OPERATIONS: "GET_OPERATIONS",
  CREATING_OPERATION: "CREATING_OPERATION",

  //Mining
  ALL_MINING_WEEKS: "ALL_MINING_WEEKS",
  GETTING_WEEKLY_MINING_STATS: "GETTING_WEEKLY_MINING_STATS",
  WEEKLY_MINING_STATS: "WEEKLY_MINING_STATS",
  GETTING_PROPOSED_REWARDS: "GETTING_PROPOSED_REWARDS",
  PROPOSED_REWARDS: "PROPOSED_REWARDS",
  EXPORTING_REPORT: "EXPORTING_REPORT",
  GETTING_UPLOADS: "GETTING_UPLOADS",
  ALL_UPLOADS: "ALL_UPLOADS",
  ACCEPTING_UPLOADS: "ACCEPTING_UPLOADS",
  REJECTING_UPLOADS: "REJECTING_UPLOADS",
  APPROVE_UPLOAD: "APPROVE_UPLOAD",
  UPLOADING_REWARDS: "UPLOADING_REWARDS",
  GETTING_BOOSTER: "GETTING_BOOSTER",
  REWARD_BOOSTER: "REWARD_BOOSTER",
  UPDATING_BOOSTER: "UPDATING_BOOSTER",
  GETTING_ADMIN_ROLES: "GETTING_ADMIN_ROLES",
  ADMIN_ROLES: "ADMIN_ROLES",
  INVITING_ADMIN: "INVITING_ADMIN",
  CHANGING_ADMIN_ROLE: "CHANGING_ADMIN_ROLE",
  INVITE_ADMIN_ERROR: "INVITE_ADMIN_ERROR",
  SELECTED_ADMIN: "SELECTED_ADMIN",
  CHANGING_ADMIN_ROLE_SUCCESSFUL: "CHANGING_ADMIN_ROLE_SUCCESSFUL",
  GETTING_P2P_ORDER_CONFIRMATION: "GETTING_P2P_ORDER_CONFIRMATION",
  P2P_ORDER_CONFIRMATIONS: "P2P_ORDER_CONFIRMATIONS",
  GETTING_P2P_ORDER_CANCELLATIONS: "GETTING_P2P_ORDER_CANCELLATIONS",
  P2P_ORDER_CANCELLATIONS: "P2P_ORDER_CANCELLATIONS",
  GETTING_SWITCHWALLET_DEPOSITS: "GETTING_SWITCHWALLET_DEPOSITS",
  SWITCHWALLET_DEPOSITS: "SWITCHWALLET_DEPOSITS",
  GETTING_SWITCHWALLET_WITHDRAWALS: "GETTING_SWITCHWALLET_WITHDRAWALS",
  SWITCHWALLET_WITHDRAWALS: "SWITCHWALLET_WITHDRAWALS",

  // Topics
  GETTING_ALL_TOPICS: "GETTING_ALL_TOPICS",
  ALL_TOPICS: "ALL_TOPICS",
  GETTING_SUBSCRIBERS_PER_TOPIC: "GETTING_SUBSCRIBERS_PER_TOPIC",
  SUBSCRIBERS_PER_TOPIC: "SUBSCRIBERS_PER_TOPIC",
  GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER: "GETTING_NO_OF_MESSAGES_PER_SUBSCRIBER",
  NO_OF_MESSAGES_PER_SUBSCRIBER: "NO_OF_MESSAGES_PER_SUBSCRIBER",
  UPDATING_DEAD_LETTER_TOPIC: "UPDATING_DEAD_LETTER_TOPIC",
  DEAD_LETTER_TOPIC: "DEAD_LETTER_TOPIC",
  ERROR_UPDATING_DEAD_LETTER_TOPIC: "ERROR_UPDATING_DEAD_LETTER_TOPIC",
  DLQ_TOPICS: "DLQ_TOPICS",
  ERROR_UPDATING_DLQ: "ERROR_UPDATING_DLQ",
  CLEAR_REPLAY_ERROR: "CLEAR_REPLAY_ERROR",

  //New Wallet Transaction (Transfer, p2p)
  GETTING_NEW_TRANSACTION_DETAILS: "GETTING_NEW_TRANSACTION_DETAILS",
  NEW_TRANSACTION_DETAILS: "NEW_TRANSACTION_DETAILS",
  GETTING_NEW_P2P_DETAILS: "GETTING_NEW_P2P_DETAILS",
  NEW_P2P_DETAILS: "NEW_P2P_DETAILS",

  // Reward Booster
  GETTING_BOOSTER_CYCLES: "GETTING_BOOSTER_CYCLES",
  BOOSTER_CYCLES: "BOOSTER_CYCLES",
  GETTING_LOCKUP_REQUESTS: "GETTING_LOCKUP_REQUESTS",
  LOCKUP_REQUESTS: "LOCKUP_REQUESTS",
  GETTING_INTEREST_DISBURSEMENTS: "GETTING_INTEREST_DISBURSEMENTS",
  INTEREST_DISBURSEMENTS: "INTEREST_DISBURSEMENTS",
  GETTING_ACCRUED_INTERESTS: "GETTING_ACCRUED_INTERESTS",
  ACCRUED_INTERESTS: "ACCRUED_INTERESTS",
  GETTING_BOOSTER_CYCLES_ID: "GETTING_BOOSTER_CYCLES_ID",
  BOOSTER_CYCLES_ID: "BOOSTER_CYCLES_ID",
  GETTING_BOOSTER_CYCLES_USER_ID: "GETTING_BOOSTER_CYCLES_USER_ID",
  BOOSTER_CYCLES_USER_ID: "BOOSTER_CYCLES_USER_ID",
  GETTING_LOCKUP_REQUESTS_ID: "GETTING_LOCKUP_REQUESTS_ID",
  LOCKUP_REQUESTS_ID: "LOCKUP_REQUESTS_ID",
  GETTING_LOCKUP_REQUESTS_USER_ID: "GETTING_LOCKUP_REQUESTS_USER_ID",
  LOCKUP_REQUESTS_USER_ID: "LOCKUP_REQUEST_USER_ID",
  GETTING_ACCRUED_INTERESTS_USER_ID: "GETTING_ACCRUED_INTERESTS_USER",
  ACCRUED_INTERESTS_USER_ID: "ACCRUED_INTERESTS_USER",
  REWARD_BOOSTER_APR: "REWARD_BOOSTER_APR",
  GETTING_APR: "GETTING_APR",
  UPDATE_APR: "UPDATE_APR",
  UPDATING_APR: "UPDATING_APR",
  APR_ERROR: "APR_ERROR",
  UPDATE_BOOSTER: "UPDATE_BOOSTER",
};

export default _const;
