import React, { useEffect } from 'react';
import IconCards from "../Reusables/cards/IconCard";
import Loader from "../Reusables/loaders/HashLoader";
import TableHeader from "../Reusables/tables/TableHeader";
import { numberWithCommas, numberWithCommaswithoutdecimals } from '../../Helper';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminTransactions, getSearchDetails, handleSubNav } from '../../Redux/actions';
import { projectedUnstakeOptions } from './options';
import { TableTitle, TopCard } from './style';

const ProjectedUnstake = () => {
  const dispatch = useDispatch();
  const { duration, startTime, endTime, } = useSelector((state) => state.Search);
  const {adminTransactions, gettingAdminTransactions } = useSelector((state) => state.Transactions);

  const transactionCategory = "projected-unstake";
  const fetchAdminTransactions = () => {
    const queries ={duration, StartDate:startTime, EndDate:endTime };
    getAdminTransactions(dispatch, transactionCategory, queries);
  }

  useEffect(() => {
    fetchAdminTransactions();
  }, [ duration, startTime, endTime ]);

  return (
    <section className="main-content-container container-fluid mb-5">
      <div className="row">
        <div className="col-lg-12 w3-animate-bottom">
          <TopCard>
          <TableTitle>Projected Unstake</TableTitle>
            <TableHeader
              subHeader={projectedUnstakeOptions}
              onClick={() => handleSubNav(5)}
              onSelect={(val, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: val,
                  })
                );
              }}
              onChange={(e, props) => {
                dispatch(
                  getSearchDetails({
                    props: [props],
                    value: e.target.value,
                  })
                );
              }}
            />
          </TopCard>
          <div className="row" id={"overview-report"}>
            <IconCards
              title={"Total Amount"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingAdminTransactions ? (
                  <Loader />
                ) : adminTransactions ? (
                  numberWithCommas(adminTransactions.totalProjectedUnstakeAmount, 4) || 0
                ) : (
                  "0"
                )
              }
              id={1}
              link={"#"}
              bordered={true}
            />
            <IconCards
              title={"Total Impacted stake record"}
              image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
              value={
                gettingAdminTransactions ? (
                  <Loader />
                ) : adminTransactions ? (
                  numberWithCommaswithoutdecimals(adminTransactions.totalImpactedStakeRecords) || 0
                ) : (
                  "0"
                )
              }
              id={2}
              link={"#"}
              bordered={true}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectedUnstake