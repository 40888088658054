import React, { Component } from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import VoucherBalance from './VoucherBalance'
import { connect } from 'react-redux'
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";



class index extends Component {
  render() {

    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle="Voucher balance" />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            <VoucherBalance />
          </div>

        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {

  return {
    state
  }
}
export default connect(MapstateToProps)(index)