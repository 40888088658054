import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleModeCommand, handleModal } from "../../Redux/actions";
import { getAllDevices, getFormDetails } from "../../Redux/actions";
import CustomModal from "../Reusables/modals/CustomModal";
import {
    ModeOption,
} from "./styles";
import Loader from "../Reusables/loaders/MoonLoader";
import { SelectDevice } from "../Reusables/selects/selectDevice";


const SingleDeviceCommand = () => {
    const dispatch = useDispatch();


    const { allDevices } = useSelector((state) => state.Devices);
    const { wicryptDeviceName, commandId } = useSelector((state) => state.User);
    const { gettingAllWicryptDevices } = useSelector((state) => state.Loading);


    useEffect(() => {
        dispatch(getAllDevices("", wicryptDeviceName, "", "", "", "", "", 1, 30));
    }, [wicryptDeviceName]);

    const handleClick = (device) =>{
        dispatch(singleModeCommand({
            "deviceId": device.id,
            "commandId": commandId
        })); 
        dispatch(handleModal(0))
    }


    return (
        <div>
            <CustomModal
                id={53}
                heading="Issue Command Mode"
                close={true}
                content={
                    <div
                        style={{
                            width: "532px",
                        }}
                    >
                        <ModeOption>
                            <input type="text"
                                value={wicryptDeviceName}
                                className="input-sm form-control"
                                placeholder="Search Device" onChange={(e) =>
                                    dispatch(getFormDetails({
                                        props: ["wicryptDeviceName"],
                                        value: e.target.value,
                                    }))
                                } />
                            {gettingAllWicryptDevices ? <Loader /> :
                                <SelectDevice allDevices={allDevices} handleClick={handleClick}/>}
                               
                        </ModeOption>
                    </div>
                }
            />
        </div>
    );
};

export default SingleDeviceCommand;
