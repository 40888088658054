import React from "react";
import { InputBox, BigInputLabel, CurrencyValue, SelectErr, InputValue} from "./inputstyles"


export const BigInput = ({ title, onChange, name,type, value, disabled, err, required }) => {
    return (
        <div className="form-group mb-0">  
            <InputBox>
                <BigInputLabel htmlfor={name}>{title}{required ? <span className="text-red"> *</span> : ""}</BigInputLabel>
                <InputValue onChange={onChange} type={type} value={value}/> 
                <CurrencyValue> NGN</CurrencyValue>
            </InputBox>
            <SelectErr>{err}</SelectErr>
        </div>
    )
}

