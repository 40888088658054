import React,{Component} from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import {connect} from 'react-redux'
import AgentPage from './AgentPage'
import {getonebusinessAgent, gettingDevices} from '../../Redux/actions'
import {Link} from 'react-router-dom';
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from '../Partials/partials'


class OneAgentReport extends Component{

  componentDidMount(){
    const { businessuserId} = this.props;
    this.props.getonebusinessAgent('', businessuserId );
    this.props.gettingDevices('');
  }
    render() {
      const {agentname, gettingOneBusinessAgent, businessuserId} = this.props
      return (
         <div className="row">
         <Sidenav/>
             <MainContent className="main-content">
                 <div className="main-navbar sticky-top bg-white">
                     <Mainnav pageTitle={ <Link to="/agents"> <i className="material-icons">arrow_back</i> {gettingOneBusinessAgent ? '...' :  agentname} </Link>}/>
                 </div>  
                 <BreadCrumbs/>    
                 <div className="main-content-container container-fluid px-4 mt-5">
                 <SubSidenav />
                  <AgentPage businessuserId={businessuserId}/>
                 </div>
             </MainContent>
        </div>
      );
    }
  }

  const MapstateToProps = (state, props) =>{
    const businessuserId = props.match.params.businessuserId;
    const {agentname} = state.Manager;
    const { gettingOneBusinessAgent } = state.Loading;
    return{
      businessuserId,
      agentname,
      gettingOneBusinessAgent
    }
  }
export default connect(MapstateToProps, {getonebusinessAgent, gettingDevices})( OneAgentReport)