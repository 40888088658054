import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { handleModal, toggleModal } from "./GeneralAction";

//Get Adverts
export const getAllAdverts = (page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ALL_ADVERTS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v2/adverts?page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_ALL_ADVERTS,
            payload: false
          });
          dispatch({
            type: _const.ALL_ADVERTS,
            payload: res.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ALL_ADVERTS,
          payload: false
        });
      });
  };
};

//Get all user adverts
export const getAllUserAdverts = (page, pageSize) => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_ALL_ADVERTS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v2/adverts/me?page=${page}&pageSize=${pageSize}`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_ALL_ADVERTS,
            payload: false
          });
          dispatch({
            type: _const.ALL_ADVERTS,
            payload: res.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_ALL_ADVERTS,
          payload: false
        });
      });
  };
};

//Get all live adverts
export const getLiveAdverts = () => {
  return dispatch => {
    dispatch({
      type: _const.GETTING_LIVE_ADVERTS,
      payload: true
    });
    axios
      .get(config.apiUrl + `/v2/adverts/live`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.GETTING_LIVE_ADVERTS,
            payload: false
          });
          dispatch({
            type: _const.ALL_LIVE_ADVERTS,
            payload: res.data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: _const.GETTING_LIVE_ADVERTS,
          payload: false
        });
      });
  };
};

//Create user adverts
export const createUserAdverts = data => {
  return dispatch => {
    if (data.advertImages.length === 0) {
      dispatch({
        type: _const.ERROR_CREATING_ADVERT,
        payload: 1
      });
    } else if (data.validityInHours === "") {
      dispatch({
        type: _const.ERROR_CREATING_ADVERT,
        payload: 4
      });
    } else if (data.startDate === "Invalid date") {
      dispatch({
        type: _const.ERROR_CREATING_ADVERT,
        payload: 2
      });
    } else if (moment(data.startDate).isBefore(new Date())) {
      dispatch({
        type: _const.ERROR_CREATING_ADVERT,
        payload: 3
      });
    } else {
      dispatch({
        type: _const.CREATING_ADVERT,
        payload: true
      });
      dispatch({
        type: _const.ERROR_CREATING_ADVERT,
        payload: ""
      });
      axios
        .post(config.apiUrl + `/v2/adverts`, data)
        .then(res => {
          if (res.data.success) {
            dispatch(toggleModal(false));
            dispatch({
              type: _const.CREATING_ADVERT,
              payload: false
            });
            dispatch({
              type: _const.USER_ADVERT_CREATED,
              payload: res.data
            });
            dispatch(getAllUserAdverts(1, 30));
            Swal.fire({
              title: "Advert Created Successfully!",
              type: "success"
            });
          }
        })
        .catch(err => {
          dispatch({
            type: _const.CREATING_ADVERT,
            payload: false
          });
          dispatch({
            type: _const.ERROR_CREATING_ADVERT,
            payload: err.response.data.message
          });
        });
    }
  };
};

//Approve advert
export const approveAdverts = advertId => {
  return dispatch => {
    dispatch({
      type: _const.APPROVING_ADVERT,
      payload: true
    });
    dispatch({
      type: _const.SELECTED_ID,
      payload: advertId
    });
    axios
      .put(config.apiUrl + `/v2/adverts/${advertId}/approve`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.APPROVING_ADVERT,
            payload: false
          });
          dispatch({
            type: _const.SELECTED_ID,
            payload: ""
          });
          dispatch({
            type: _const.ADVERT_APPROVED,
            payload: res.data
          });
          dispatch(handleModal(0));
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
          dispatch(getAllAdverts(1, 30));
        }
      })
      .catch(err => {
        dispatch({
          type: _const.APPROVING_ADVERT,
          payload: false
        });
        dispatch({
          type: _const.SELECTED_ID,
          payload: ""
        });
        dispatch({
          type: _const.ERROR_APPROVING_ADVERT,
          payload: err.message,
        });
      });
  };
};

//Reject advert
export const rejectAdverts = advertId => {
  return dispatch => {
    dispatch({
      type: _const.REJECTING_ADVERT,
      payload: true
    });
    dispatch({
      type: _const.SELECTED_ID,
      payload: advertId
    });
    axios
      .put(config.apiUrl + `/v2/adverts/${advertId}/reject`)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: _const.REJECTING_ADVERT,
            payload: false
          });
          dispatch({
            type: _const.SELECTED_ID,
            payload: ""
          });
          dispatch({
            type: _const.ADVERT_REJECTED,
            payload: res.data
          });
          dispatch(handleModal(0));
          Swal.fire({
            title: "Updated!",
            type: "success",
          });
          dispatch(getAllAdverts(1, 30));
        }
      })
      .catch(err => {
        dispatch({
          type: _const.REJECTING_ADVERT,
          payload: false
        });
        dispatch({
          type: _const.SELECTED_ID,
          payload: ""
        });
        dispatch({
          type: _const.ERROR_APPROVING_ADVERT,
          payload: err.message,
        });
      });
  };
};

//Get advert by id
export const getAdvertById = advertId => {
  return dispatch => {
    axios.get(config.apiUrl + `/v2/adverts/${advertId}`).then(res => {
      dispatch({
        type: _const.GET_ADVERT_BY_ID,
        payload: res.data.data
      });
    });
  };
};
