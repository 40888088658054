import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  createVoucher,
  getvouchers,
  toggleModal,
  saveId,
  clearData,
} from "../../Redux/actions";
import moment from "moment";
import { numberWithCommas, pagination } from "../../Helper";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CreateVoucher from "./CreateVoucher";

class GenerateVoucher extends Component {
  componentWillMount() {
    const {
      voucherstartDate,
      voucherendDate,
      singlevouchercodeSearch,
    } = this.props;
    this.props.getvouchers(
      "",
      voucherstartDate,
      1,
      30,
      "",
      voucherendDate,
      singlevouchercodeSearch
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      voucherstartDate,
      voucherStatus,
      voucherendDate,
      singlevouchercodeSearch,
    } = nextProps;
    if (
      voucherstartDate !== this.props.voucherstartDate ||
      voucherStatus !== this.props.voucherStatus ||
      voucherendDate !== this.props.voucherendDate ||
      singlevouchercodeSearch !== this.props.singlevouchercodeSearch
    ) {
      this.props.getvouchers(
        "",
        voucherstartDate,
        1,
        50,
        voucherStatus,
        voucherendDate,
        singlevouchercodeSearch
      );
    }
  }

  customPagination() {
    const {
      allVouchersTotalPages,
      allVouchersCurrentPage,
      voucherstartDate,
      voucherStatus,
      voucherendDate,
      singlevouchercodeSearch,
    } = this.props;
    var pageButtons = pagination(
      allVouchersCurrentPage,
      allVouchersTotalPages,
      5
    );
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getvouchers(
              "",
              voucherstartDate,
              allVouchersCurrentPage - 1,
              30,
              voucherStatus,
              voucherendDate,
              singlevouchercodeSearch
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === allVouchersCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getvouchers(
                "",
                voucherstartDate,
                pageButtons[i],
                30,
                voucherStatus,
                voucherendDate,
                singlevouchercodeSearch
              )
            }
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getvouchers(
              "",
              voucherstartDate,
              allVouchersCurrentPage + 1,
              30,
              voucherStatus,
              voucherendDate,
              singlevouchercodeSearch
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      allVouchers,
      gettingVouchers,
      singlevouchercodeSearch,
    } = this.props;

    let allRows = [];
    allVouchers.map((voucher) => {
      return allRows.push({
        code: voucher.code,
        email: voucher.owner.username,
        value:
          numberWithCommas(voucher.voucherValue) +
          "WC | " +
          numberWithCommas(voucher.voucherValue * 360) +
          "NGN",
        balance:
          numberWithCommas(voucher.balance) +
          "WC | " +
          numberWithCommas(voucher.balance * 360) +
          "NGN",
        expiry:
          voucher.expiryDate == null
            ? "N/A"
            : moment(voucher.expiryDate).format("MMMM Do YYYY"),
        status: (
          <b
            className={
              voucher.voucherStatus === "Exhausted"
                ? "text-red"
                : voucher.voucherStatus === "Inactive"
                ? "text-orange"
                : "text-green"
            }
          >
            {" "}
            {voucher.voucherStatus}{" "}
          </b>
        ),
      });
    });

    const data = {
      columns: [
        {
          label: "Voucher Code",
          field: "code",
          sort: "asc",
          width: 170,
        },
        {
          label: "Recepient email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Voucher Value",
          field: "value",
          sort: "asc",
          width: 170,
        },
        {
          label: "Balance",
          field: "balance",
          sort: "asc",
          width: 170,
        },
        {
          label: "Expiry",
          field: "expiry",
          sort: "asc",
          width: 170,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="">
        <CreateVoucher />
        {/* <div className="row">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="d-flex">
              <h6 className="m-0">VOUCHERS</h6>
              <div className="createbtn">
                {generatingVoucher ? (
                  <Button theme="btn-primary ml-3 mt-2 actionButton wicryptColor">
                    {" "}
                    <Loader />
                  </Button>
                ) : (
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.toggleModal(true)}
                    >
                      {" "}
                    Generate Voucher
                    </i>
                  )}
              </div>
            </div>

            <div className="mt-3 d-flex">
       
              <div className="mr-3"> FROM</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherstartDate"],
                    value: e.getTime(),
                  })
                }
                selected={voucherstartDate}
                dateFormat={"dd/MM/yyyy"}
              />

              <div className="mr-3 ml-3"> TO</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherendDate"],
                    value: e.getTime(),
                  })
                }
                selected={voucherendDate}
                dateFormat={"dd/MM/yyyy"}
              />
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["voucherStatus"],
                    value: e.target.value,
                  })
                }
              >
                <option value={""}>Select Voucher Status</option>
                <option value={"Active"}>Active</option>
                <option value={"Inactive"}> Inactive</option>
                <option value={"Exhausted"}> Exhausted</option>
              </select>
            </div>
          </div>
        </div> */}
        {gettingVouchers & (allVouchers.length === 0) ? (
          <CustomLoader />
        ) : (
          <CustomDataTable
            data={data}
            pagination={this.customPagination()}
            placeholder={"Search voucher code"}
            onClick={() => this.props.toggleModal(true)}
            buttontitle={"Generate Voucher"}
            searchfunction={(e) =>
              this.props.getFormDetails({
                props: ["singlevouchercodeSearch"],
                value: e.target.value,
              })
            }
            searchvalue={singlevouchercodeSearch}
          />
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    voucherRecepientPhone,
    voucherAmt,
    voucherstartDate,
    voucherendDate,
    voucherStatus,
    voucherValidity,
    voucherMsg,
    businessProfile,
    days,
    singlevouchercodeSearch,
  } = state.User;
  const { generatingVoucher, gettingVouchers } = state.Loading;
  const {
    allVouchers,
    voucherError,
    savedBusinessId,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
  } = state.Voucher;
  return {
    allVouchers,
    voucherRecepientPhone,
    voucherAmt,
    voucherStatus,
    generatingVoucher,
    gettingVouchers,
    voucherError,
    voucherValidity,
    voucherMsg,
    savedBusinessId,
    businessProfile,
    allVouchersCurrentPage,
    allVouchersTotalPages,
    allVouchersTotalRecords,
    voucherstartDate,
    voucherendDate,
    days,
    singlevouchercodeSearch,
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  createVoucher,
  getvouchers,
  toggleModal,
  saveId,
  clearData,
})(GenerateVoucher);
