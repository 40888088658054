import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

class PieChart extends Component {
  render() {
    const {
      hoverBorderColor,
      backgroundColorArray,
      data,
      labels,
      height,
    } = this.props;
    return (
      <Doughnut
        // className= "width-100"
        data={{
          datasets: [
            {
              hoverBorderColor: hoverBorderColor ? hoverBorderColor : "#ffffff",
              data,
              backgroundColor: backgroundColorArray
                ? backgroundColorArray
                : defaultBackgroundColor,
              borderWidth: 0,
            },
          ],
          labels,
        }}
        height={height ? height : 100}
        options={options}
      />
    );
  }
}
const options = {
  responsive: true,
  legend: {
    position: "right",
    labels: {
      usePointStyle: true,
    },
  },
};

const defaultBackgroundColor = [
  "#2D4773",
  "rgba(0,123,255,0.9)",
  "rgba(0,123,255,0.5)",
  "rgba(0,123,255,0.3)",
];

export { PieChart };
