import React from "react";
import { useSelector } from "react-redux";
import { SubmitButton, SubmitButtonContainer } from "../Devices/modals/styles";
import Loader from "../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../Reusables/modals/CustomModal";
import { TopicContent, TopicSubContent } from "../Topics/topicStyles";

function AdvertModal({ acceptingRejectingAdverts, businessName, startDate, onSubmit, advertError, acceptReject, modalId }) {

    const heading = acceptReject === "accept" ? "Accept Advert" : "Reject Advert";
    const actionText = acceptReject === "accept" ? "Accept" : "Reject";
  return (
      <div>
          <CustomErrorModal
              id={modalId}
              heading={heading}
              close={true}
              content={
                  <>
                    <TopicContent className="t-center">Are you sure you want to {acceptReject}: </TopicContent>
                    <TopicSubContent className="t-center fw-bolder"> {businessName} - {startDate}</TopicSubContent>
                    {advertError ? <p className="errorMsgs mt-3 t-center mb-0">{advertError}</p> : ""} 
                    <SubmitButtonContainer onClick={onSubmit} >
                        <SubmitButton >{acceptingRejectingAdverts ? <Loader /> : actionText}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default AdvertModal;
