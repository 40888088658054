import React from "react";
import { InputDescription, Label,LayeredInputBox, SelectErr, InputContainer} from "./inputstyles"


export const LayeredInput = ({ title, onChange, description, placeholder, type, value, err, maxlength, disabled, loading }) => {
    return (
        <div className="form-group p-r mb-0">
            <InputContainer>
                <Label>{title}</Label>
                <br/>
                <LayeredInputBox 
                    placeholder ={placeholder} 
                    value={value} 
                    className="form-control"
                    onChange={onChange}
                    type={type}
                    maxLength={maxlength}
                    disabled={disabled}
                />
                <InputDescription>{description}</InputDescription>
                {loading ?
                    (
                        <i
                        className="fas fa-spinner fa-spin  mr-2 ml-2 acctNameLoader"
                        aria-hidden="true"
                        />
                    ) : (
                        ""
                        )}
            </InputContainer>
            <SelectErr>{err}</SelectErr>
        </div>
    )
}

