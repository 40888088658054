import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  assignAgents,
  gettingDevices,
  getallAgents,
  createAgents,
  toggleModal,
  editAgent,
  agentDevice,
} from "../../Redux/actions";
import { Link } from "react-router-dom";
import CreateAgents from "./CreateAgents";
import EditAgents from "./EditAgents";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import AssignDevices from "./AssignDevices";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import { pagination, numberWithCommas } from '../../Helper'

class RegAgent extends Component {
  componentWillMount() {
    this.props.gettingDevices('');
    if (this.props.allAgents.length === 0) {
      this.props.getallAgents(1, 30, this.props.agentSearch);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      agentSearch
    } = nextProps;
    if (
      agentSearch !== this.props.agentSearch
    ) {
      this.props.getallAgents(1, 30, agentSearch);
    }
  } 

  customPagination() {
    const { agentsTotalPage, agentsCurrentPage } = this.props;
    var pageButtons = pagination(agentsCurrentPage, agentsTotalPage, 5);
    var paginate = [];
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getallAgents(agentsCurrentPage - 1, 30, this.props.agentSearch)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === agentsCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.getallAgents(pageButtons[i], 30, '')}
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.this.props.getallAgents(agentsCurrentPage + 1, 30, '')
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const {
      allAgents,
      fetchingAgents,
      funct,
      currencyValue,
      agentSearch
    } = this.props;
    let allRows = [];
    allAgents.map((agent) => {
      return allRows.push({
        name: agent.user.name,
        email: agent.user.email,
        devices: agent.totalDevicesManaged,
        vouchergenerated: agent.totalVoucherGenerated,
        amount: <div> <p className="mb-0 t-12">{numberWithCommas(agent.totalAmountGenerated * currencyValue)}</p><p className="mb-0 t-8">~{agent.totalAmountGenerated}WC</p></div>,
        view: (
          <Link to={`/agentReport/${agent.businessUserId}`}>
            {" "}
            <p className="text-orange"> View details</p>
          </Link>
        ),
      });
    });
    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 170,
        },
        {
          label: "email",
          field: "email",
          sort: "asc",
          width: 170,
        },
        {
          label: "Managed Devices",
          field: "devices",
          sort: "asc",
          width: 170,
        },
        {
          label: "Total Voucher Generated",
          field: "vouchergenerated",
          sort: "asc",
          width: 170,
        },
        {
          label: "Total Amount",
          field: "amount",
          sort: "asc",
          width: 170,
        },
        {
          label: "",
          field: "view",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        {funct === "assign" ? (
          <AssignDevices />
        ) : funct === "editAgent" ? (
          <EditAgents />
        ) : (
          <CreateAgents />
        )}
        {fetchingAgents & allAgents.length === 0 ? (
          <div className="col-lg-12 mb-4">
            <CustomLoader />
          </div>) : (
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              pagination={this.customPagination()}
              placeholder={"Search agent name"}
              onClick={() => this.props.toggleModal(true)}
              buttontitle={"Create Agent"}
              searchfunction={(e) => this.props.getFormDetails({ props: ['agentSearch'], value: e.target.value })}
              searchvalue={agentSearch}
            />
          </div>
        )}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    deviceId,
    code,
    agentEmail,
    agentRole,
    clickedDeviceId,
    funct,
    currencyValue,
    agentSearch
  } = state.User;
  const {
    fetchingAgents,
    creatingAgent,
    assigningdevice,
    editingAgent,
  } = state.Loading;
  const { managerDevices } = state.Manager;
  const {
    allAgents,
    agentDevices,
    createError,
    agentsCurrentPage,
    agentsTotalPage
  } = state.Agent;
  return {
    agentDevices,
    assigningdevice,
    editingAgent,
    managerDevices,
    creatingAgent,
    funct,
    deviceId,
    code,
    fetchingAgents,
    clickedDeviceId,
    agentEmail,
    agentRole,
    allAgents,
    createError,
    agentsCurrentPage,
    agentsTotalPage,
    currencyValue,
    agentSearch
  };
};

export default connect(MapStateToProps, {
  getFormDetails,
  editAgent,
  assignAgents,
  gettingDevices,
  agentDevice,
  getallAgents,
  toggleModal,
  createAgents,
})(RegAgent);
