import React from 'react'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import Mainnav from '../Partials/Mainnav'
import DatePickerView from './DatePickerView'
import { MainContent } from '../Partials/partials'


const DataByDate = () =>{
    return (
        <div className="row">
        <Sidenav/>
            <MainContent className="main-content">
                <div className="main-navbar sticky-top bg-white">
                    <Mainnav pageTitle="Wicrypt Overview"/>
                </div>  
                <BreadCrumbs/>    
                <div className="main-content-container container-fluid px-4 mt-5">
                    <DatePickerView/>
                </div>
            </MainContent>
       </div>
    )
}

export default DataByDate