import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getSearchDetails , uploadFile} from "./index";

//Get Mining Weeks 
export const getMiningWeeks = () => {
    return (dispatch) => {
        axios
            .get(config.adminApiUrl + `/v1/mining/mining-weeks`)
            .then((res) => {
                dispatch({
                    type: _const.ALL_MINING_WEEKS,
                    payload: res.data.data,
                });
                if(res.data.data.length > 0){
                    dispatch(
                        getSearchDetails({
                            props: "selectedMiningWeek",
                            value: res.data.data[0],
                        })
                    );
                }
                
            })
            .catch((err) => {

            });
    };
};

//Get Weekly Miners Statistics
export const getWeeklyStatistics = (week, page, pageSize, stakingReq, search, hasReward) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_WEEKLY_MINING_STATS,
            payload: true,
        });
        dispatch({
            type: _const.WEEKLY_MINING_STATS,
            payload:{records: [], currentPage: 1, totalPages:1},
        });
        axios
            .get(config.adminApiUrl + `/v1/mining/weekly-miners-stats?Week=${week}&Page=${page}&PageSize=${pageSize}&MeetsMiniStaking=${stakingReq}&SearchParam=${search}&HasReward=${hasReward}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_WEEKLY_MINING_STATS,
                    payload: false,
                });
                dispatch({
                    type: _const.WEEKLY_MINING_STATS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                
                dispatch({
                    type: _const.GETTING_WEEKLY_MINING_STATS,
                    payload: false,
                });
            });
    };
};

//Proposed Rewards for this week
export const proposedRewards = (page, pageSize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_PROPOSED_REWARDS,
            payload: true,
        });
        axios
            .get(config.adminApiUrl + `/v1/mining/proposed-mining-reward?Page=${page}&PageSize=${pageSize}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_PROPOSED_REWARDS,
                    payload: false,
                });
                dispatch({
                    type: _const.PROPOSED_REWARDS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_PROPOSED_REWARDS,
                    payload: false,
                });
            });
    };
};

//Export Report
export const exportReport = () => {
    return (dispatch) => {
        dispatch({
            type: _const.EXPORTING_REPORT,
            payload: true,
        });
        axios
            .post(config.adminApiUrl + `/v1/mining/export-proposed-mining-reward`)
            .then((res) => {
                dispatch({
                    type: _const.EXPORTING_REPORT,
                    payload: false,
                });
                Swal.fire({
                    title: res.data.message,
                    type: "success",
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.EXPORTING_REPORT,
                    payload: false,
                });
            });
    };
};

//Get Uploads
export const getUploads = (week) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_UPLOADS,
            payload: true,
        });
        axios
            .get(config.apiCalc + `/ReviewedReward/preview/${week}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_UPLOADS,
                    payload: false,
                });
                dispatch({
                    type: _const.ALL_UPLOADS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_UPLOADS,
                    payload: false,
                });
            });
    };
};

//Upload reward
export const uploadReward = (week, file) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    console.log(bodyFormData, 'i am body form data')
    return (dispatch) => {
        dispatch({
            type: _const.UPLOADING_REWARDS,
            payload: true,
        });
        axios
            .post(config.apiCalc + `/ReviewedReward/${week}`, bodyFormData)
            .then((res) => {
                dispatch({
                    type: _const.UPLOADING_REWARDS,
                    payload: false,
                });
                dispatch(getUploads(week));
                Swal.fire({
                    title: res.data.message,
                    type: "success",
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.UPLOADING_REWARDS,
                    payload: false,
                });
                Swal.fire({
                    text: err.response ? err.response.data.message : "",
                    type: "error",
                  });
            });
    };
};

export const acceptUploads = (week) => {
    return (dispatch) => {
        dispatch({
            type: _const.ACCEPTING_UPLOADS,
            payload: true,
        });
        axios
            .put(config.apiCalc + `/ReviewedReward/accept/${week}`)
            .then((res) => {
                dispatch({
                    type: _const.ACCEPTING_UPLOADS,
                    payload: false,
                });
                dispatch(getUploads(week));
                Swal.fire({
                    title: res.data.message,
                    type: "success",
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.ACCEPTING_UPLOADS,
                    payload: false,
                });
                Swal.fire({
                    text: err.response ? err.response.data.message : "",
                    type: "error",
                  });
            });
    };
};

export const rejectUploads = (week) => {
    return (dispatch) => {
        dispatch({
            type: _const.REJECTING_UPLOADS,
            payload: true,
        });
        axios
            .put(config.apiCalc + `/ReviewedReward/reject/${week}`)
            .then((res) => {
                dispatch({
                    type: _const.REJECTING_UPLOADS,
                    payload: false,
                });
                dispatch(getUploads(week));
                Swal.fire({
                    title: res.data.message,
                    type: "success",
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.REJECTING_UPLOADS,
                    payload: false,
                });
                Swal.fire({
                    text: err.response ? err.response.data.message : "",
                    type: "error",
                });
            });
    };
};

export const approveUpload = (week) => {
    return (dispatch) => {
        dispatch({
            type: _const.APPROVE_UPLOAD,
            payload: true,
        });
        axios
            .put(config.apiCalc + `/Reward/approvebyweek?week=${week}&approve=true`)
            .then((res) => {
                dispatch({
                    type: _const.APPROVE_UPLOAD,
                    payload: false,
                });
                dispatch(getUploads(week));
                Swal.fire({
                    title: "Reward approved successfully" ,
                    type: "success",
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.APPROVE_UPLOAD,
                    payload: false,
                });
                Swal.fire({
                    text: err.response ? err.response.data.message : "",
                    type: "error",
                  });
            });
    };
};