import React, {Component} from "react";
import { TransactionButtons} from "./walletstyles";
import {handleModal} from "../../Redux/actions";
import {ActionButton} from "../Reusables/inputs";
import { connect } from "react-redux";


class AllTransactionButtons extends Component{
    render(){

        return(
            <TransactionButtons>
                <ActionButton title="Fund" img={'/Assets/walleticons/Fund.svg'} active={true} buttonAction={()=> this.props.handleModal(1)}/> 
                <ActionButton title="Withdraw" img={'/Assets/walleticons/Withdraw.svg'} buttonAction={()=> this.props.handleModal(2)}/> 
                <ActionButton title="Transfer" img={'/Assets/walleticons/Transfer.svg'} buttonAction={()=> this.props.handleModal(3)}/> 
            </TransactionButtons> 
        )
    }
    
}

const MapStateToProps = (state) => {
   
    return {
      state
    }
}

export default connect(MapStateToProps, { handleModal})(AllTransactionButtons);
