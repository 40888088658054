import styled from "styled-components";
import { theme } from "../../theme";

export const DataTitle = styled.p`
  color: ${theme.black};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  font-weight: bold;
`;
export const DataSubTitle = styled.p`
  color: ${theme.black};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  font-weight: bold;
`;
export const DataKey = styled.p`
  color: ${theme.grey};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  margin-right: 10px;
  text-transform: uppercase;
`;
export const DataValue = styled.p`
  color: ${theme.black};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
`;
export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: 5px;
  width: 100%;
`;
export const Icon = styled.i`
  color: ${(props) => (props.color ? theme[props.color] : theme.yellow)};
  cursor: pointer;
  font-size: ${theme.textSm} !important;
`;
export const ActionButtonContainer = styled.div`
  display: flex;
`;
