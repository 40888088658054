import _const from "../actions/Types";

const initialState = {
  dailyReports: [],
  dailReportspageSize: 1,
  dailyReportcurrentPage: 1,
  dailyReporttotalPages: 1,
  dailytotalRecords: 1,
  adminTransactions: {},
  gettingAdminTransactions: false,
  transactionType: "transfer",
  singleTransactionDetails: {},
  dailyReport: [],
  switchWalletData: [],
  switchWalletspageSize: 1,
  switchWalletcurrentPage:1,
  switchWallettotalPages:1,
  switchWalletReportData: {balances:[], dateCreated: "", anticipatedClosingBalance: [], totalDeposits: [], totalWithdrawals: []},
  orderConfirmations: {},
  orderCancellations: {},
  switchWalletDeposits: {records: [], pageSize: 1, currentPage: 1, totalPages: 1, totalRecords: 1},
  switchWalletWithdrawals: {records: [], pageSize: 1, currentPage: 1, totalPages: 1, totalRecords: 1},
  newTransactionDetails: {},
  newP2pDetails: {},
};

const TransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ADMIN_TRANSACTIONS:
      return {
        ...state,
        adminTransactions: action.payload,
      };
    case _const.GETTING_ADMIN_TRANSACTIONS:
      return {
        ...state,
        gettingAdminTransactions: action.payload,
      };
    case _const.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.payload,
      };
    case _const.SINGLE_TRANSACTION_DETAILS:
      localStorage.setItem(
        "singleTransactionDetails",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        singleTransactionDetails: action.payload,
      };

    case _const.DAILY_REPORTS:
      return {
        ...state, 
        dailyReports: action.payload.records,
        dailReportspageSize: action.payload.pageSize,
        dailyReportcurrentPage: action.payload.currentPage,
        dailyReporttotalPages: action.payload.totalPages,
        dailytotalRecords: action.payload.totalRecords
      };

    case _const.DAILY_REPORT:
      return{...state, dailyReport: action.payload }

    case _const.SWITCHWALLET_REPORT:
      return {
        ...state,
        switchWalletData: action.payload.records,
        switchWalletspageSize: action.payload.pageSize,
        switchWalletcurrentPage: action.payload.currentPage,
        switchWallettotalPages: action.payload.totalPages,
      };

    case _const.SWITCHWALLET_DAILY_REPORT:
      return{...state, switchWalletReportData: action.payload}

    case _const.P2P_ORDER_CONFIRMATIONS:
      return{...state, orderConfirmations: action.payload}

    case _const.P2P_ORDER_CANCELLATIONS:
      return{...state, orderCancellations: action.payload}

    case _const.SWITCHWALLET_DEPOSITS:
      return{...state, switchWalletDeposits: action.payload}

    case _const.SWITCHWALLET_WITHDRAWALS:
      return{...state, switchWalletWithdrawals: action.payload}

    case _const.NEW_TRANSACTION_DETAILS:
      return{...state, newTransactionDetails: action.payload}

    case _const.NEW_P2P_DETAILS:
      return{...state, newP2pDetails: action.payload}

    case _const.CLEAR_SEARCH:
      return{...state, adminTransactions: {} }

    default:
      return state;
  }
};

export default TransactionsReducer;
