import _const from "../actions/Types";

const initialState = {
    weeklyStats: [],
    weeklyStatsCurrentPage: 1,
    weeklyStatsTotalPages: 1,
    proposedReward: [],
    proposedRewardCurrentPage: 1,
    proposedRewardTotalPages: 1,
    allUploads: [],
    acceptedUpload: [],
    allMiningWeeks: []
};

const MinersReducer = (state = initialState, action) => {
    switch (action.type) {

        case _const.WEEKLY_MINING_STATS:
            return { ...state, weeklyStats: action.payload.records, weeklyStatsCurrentPage: action.payload.currentPage, weeklyStatsTotalPages: action.payload.totalPages }

        case _const.PROPOSED_REWARDS:
            return { ...state, proposedReward: action.payload.records, proposedRewardCurrentPage: action.payload.currentPage, proposedRewardTotalPages: action.payload.totalPages }

        case _const.ALL_UPLOADS:
            let accepted = [];
            for (let i = 0; i < action.payload.length; i++) {
                if (action.payload[i].isAccepted) {
                    accepted.push(action.payload[i]);
                }
            }
            return { ...state, allUploads: action.payload, acceptedUpload: accepted }

        case _const.ALL_MINING_WEEKS:
            let data = [];
            for(let i=0; i < action.payload.length; i++){
                data.push({id: i + 1, option: action.payload[i].toString()})
            }
            return { ...state, allMiningWeeks: data }

        default:
            return state;
    }
};

export default MinersReducer;