import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";

export const getCharts = (query) => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/admin/chart?duration=${query}`)
      .then((res) => {
        dispatch({
          type: _const.GET_CHARTS,
          payload: res.data.data,
        });
      })
      .catch((err) => { });
  };
};

export const getYearCharts = (query) => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/admin/chart?duration=${query}`)
      .then((res) => {
        dispatch({
          type: _const.GET_YEAR_CHARTS,
          payload: res.data.data,
        });
      })
      .catch((err) => { });
  };
};

//Charting New Graph for each Month
export const getMonthValue = (value) => {
  return (dispatch) => {
    dispatch({
      type: _const.GET_MONTH_GRAPH,
      payload: value,
    });
  };
};

//Charting New Graph for each Month
export const getMonthByte = (value) => {
  return (dispatch) => {
    dispatch({
      type: _const.GET_MONTH_BYTE,
      payload: value,
    });
  };
};

//Getting Chart for revenue per date range
export const getRevenue = (duration) => {
  return (dispatch) => {
    axios
      .get(config.apiUrl + `/v2/users/chart?duration=${duration}`)
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: _const.TOTAL_REVENUE,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: _const.TOTAL_REVENUE,
            payload: [],
          });
        }
      })
      .catch((err) => { });
  };
};
