import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyIdCard, getFormDetails } from "../../Redux/actions";
import Modal from "../Reusables/modals/Modal";
import { CustomFormContainer, NextButton, Label } from "./verificationstyles";
import Loader from "../Reusables/loaders/MoonLoader";
import {
  DataTitle,
  DataKey,
  DataValue,
  DataContainer
} from "../AcctVerification/verificationstyle";

class VerifyIdentification extends Component {
  render() {
    const {
      verificationTypes,
      idNumber,
      idType,
      verificationId,
      verifyingId,
      idverificationfail,
      idVerificationStatus,
      verificationDetails
    } = this.props;

    return (
      <React.Fragment>
        {!idVerificationStatus ? (
          <Modal heading={`Verify Identification`}>
            <CustomFormContainer>
              <div className="mb-3">
                <Label> Select ID type</Label>
                <select
                  className="loginCred form-control"
                  onChange={e =>
                    this.props.getFormDetails({
                      props: ["idType"],
                      value: e.target.value
                    })
                  }
                >
                  <option value=""> Select...</option>
                  {verificationTypes.map(each => {
                    return (
                      <option value={each.symbol} key={each.id}>
                        {each.name}
                      </option>
                    );
                  })}
                </select>
                {idverificationfail === 1 ? (
                  <p className="errorMsgs"> Please select an ID type</p>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-5">
                <Label> Identification Number</Label>
                <input
                  onChange={e =>
                    this.props.getFormDetails({
                      props: ["idNumber"],
                      value: e.target.value
                    })
                  }
                  value={idNumber}
                  className="loginCred form-control"
                />
                {idverificationfail === 2 ? (
                  <p className="errorMsgs">
                    {" "}
                    Please enter the identification number
                  </p>
                ) : (
                  ""
                )}
              </div>
              {(idverificationfail !== "") &
              (idverificationfail !== 1) &
              (idverificationfail !== 2) ? (
                <p className="errorMsgs"> {idverificationfail}</p>
              ) : (
                ""
              )}
              <hr />
              <div className="t-right">
                {verifyingId ? (
                  <NextButton>
                    <Loader />
                  </NextButton>
                ) : (
                  <NextButton
                    onClick={() =>
                      this.props.verifyIdCard(idType, verificationId, idNumber)
                    }
                  >
                    Next
                    <i className="material-icons ml-3">
                      keyboard_double_arrow_right
                    </i>
                  </NextButton>
                )}
              </div>
            </CustomFormContainer>
          </Modal>
        ) : (
          <Modal heading={`Identification Details`}>
            <CustomFormContainer>
              <DataTitle>{verificationDetails.idType}</DataTitle>

              <DataContainer>
                <DataKey>Number:</DataKey>
                <DataValue>{verificationDetails.idNumber}</DataValue>
              </DataContainer>
              <DataContainer>
                <DataKey>Name:</DataKey>
                <DataValue>
                  {verificationDetails.firstName +
                    " " +
                    verificationDetails.lastName}
                </DataValue>
              </DataContainer>
              <DataContainer>
                <DataKey>Phone Number:</DataKey>
                <DataValue>{verificationDetails.phoneNumber}</DataValue>
              </DataContainer>
              <DataContainer>
                <DataKey>Expiry Date:</DataKey>
                <DataValue>{verificationDetails.idExpiry}</DataValue>
              </DataContainer>
            </CustomFormContainer>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
const MapStateToProps = state => {
  const {
    verificationTypes,
    idverificationfail,
    idVerificationStatus,
    verificationDetails
  } = state.Verification;
  const { idType, idNumber } = state.User;
  const { verifyingId } = state.Loading;
  return {
    verificationTypes,
    idNumber,
    idType,
    verifyingId,
    idverificationfail,
    idVerificationStatus,
    verificationDetails
  };
};
export default connect(
  MapStateToProps,
  {
    verifyIdCard,
    getFormDetails
  }
)(VerifyIdentification);
