import React, {Component} from "react";
import { SectionValue, SectionTitle, Width10, Width30, BallanceSummary, Width100,Width60, SectionDescription, Activate, SpanSectionValue } from "./walletstyles"
import {Icon } from "../Reusables/cards/cards"
import { connect } from "react-redux";
import { numberWithCommas } from "../../Helper";
import {getBusinessBalance, handleSubNav, getmaxwithdrawalforpersonal} from "../../Redux/actions";
import {Link} from "react-router-dom"


class AllWallets extends Component{
    componentDidMount(){
        this.props.getBusinessBalance();
        this.props.getmaxwithdrawalforpersonal()
    }
    render(){
        const {businessBalance, balance, currencyValue, promoBalance, role} = this.props
        return (
            <div className="card card-small mb-4 w3-animate-left">
                <div className="p-20">
                    <Width100>
                        <BallanceSummary> 
                            <SectionTitle> Personal Balance</SectionTitle>
                            <SectionValue> {"NGN " + numberWithCommas(balance * currencyValue)} <SpanSectionValue> ~ = {numberWithCommas(balance) + "WC"}</SpanSectionValue></SectionValue>
                        </BallanceSummary>
                        <Width60> 
                            <SectionDescription>
                                This is your personal wallet balance
                            </SectionDescription>
                        </Width60>
                        <Width30> 
                            
                        </Width30>
                        <Width10> 
                        <Icon className="material-icons" onClick={()=>this.props.handleSubNav(2)}>chevron_right</Icon>
                        </Width10>
                    </Width100>
                    <Width100>
                        <BallanceSummary> 
                            <SectionTitle> Business Balance</SectionTitle>
                            <SectionValue> {"NGN " + numberWithCommas(businessBalance * currencyValue)} <SpanSectionValue> ~ = {numberWithCommas(businessBalance) + "WC"}</SpanSectionValue> </SectionValue>
                        </BallanceSummary>
                        <Width60> 
                            <SectionDescription>
                               This is the total amount that has been generated by your business.
                            </SectionDescription>
                        </Width60>
                        <Width30> 
                           {!role.includes("Business") ? <Link to="/registration"> <Activate> Activate</Activate></Link>: ""}
                        </Width30>
                        <Width10> 
                        <Icon className="material-icons" onClick={()=>this.props.handleSubNav(3)}>chevron_right</Icon>
                        </Width10>
                    </Width100>
                    <Width100 last>
                        <BallanceSummary> 
                            <SectionTitle>Promo Balance</SectionTitle>
                            <SectionValue> {"NGN " + numberWithCommas(promoBalance * 360)}  <SpanSectionValue> ~ = {numberWithCommas(promoBalance)} WC </SpanSectionValue></SectionValue>
                        </BallanceSummary>
                        <Width60> 
                            <SectionDescription>
                                This is the total balance you get for discounts.
                            </SectionDescription>
                        </Width60>
                        <Width30> 
                            
                        </Width30>
                        <Width10> 
                        {/* <Icon className="material-icons" >chevron_right</Icon> */}
                        </Width10>
                    </Width100>
                    
                </div>
            </div>
        )
    }
    
}

const MapstateToProps = (state) => {
    const {
      balance,
      promoBalance,
      businessBalance,
      role,
      currencyValue,
    } = state.User;
    return {
      balance,
      promoBalance,
      role,
      businessBalance,
      currencyValue,
    };
};

export default connect(MapstateToProps, {getBusinessBalance, handleSubNav, getmaxwithdrawalforpersonal})(AllWallets);