import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { StakeStatData, StakeStatValue } from "../UserDetails/styles";

const DeviceSessionDetail = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link to={`/devices`} className="pointer mb-0">
                {" "}
                <i className="material-icons">arrow_back</i> Session Details
              </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <div className="col-lg-12 ">
            <div className="card">
              <div className="card-body p-6 ">
                <div className="row mb-2">
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Host</StakeStatData>
                    <StakeStatValue>ekwealorhillary@gmail.com</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Client</StakeStatData>
                    <StakeStatValue>ekwealorhillary@gmail.com</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Agent</StakeStatData>
                    <StakeStatValue>David</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Device</StakeStatData>
                    <StakeStatValue>Office Wifi02</StakeStatValue>
                  </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Amount Received by Host</StakeStatData>
                    <StakeStatValue>NGN 5,200</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Amount Spent By Client</StakeStatData>
                    <StakeStatValue>NGN 5,700</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Voucher’s Opening Balance</StakeStatData>
                    <StakeStatValue>NGN 30,000.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>
                      Average Data Usage per minutes
                    </StakeStatData>
                    <StakeStatValue>300MB</StakeStatValue>
                  </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Host’s Opening Balance</StakeStatData>
                    <StakeStatValue>NGN 230,000.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Client’s Opening Balance</StakeStatData>
                    <StakeStatValue>NGN 12,000.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Voucher’s Closing Balance</StakeStatData>
                    <StakeStatValue>NGN 24,300.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Session Created:</StakeStatData>
                    <StakeStatValue>June 25th 2021, 8:43 am</StakeStatValue>
                  </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Host’s Closing Balance</StakeStatData>
                    <StakeStatValue>NGN 230,000.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Client’s Closing Balance</StakeStatData>
                    <StakeStatValue>NGN 3,300</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Referral Amount</StakeStatData>
                    <StakeStatValue>NGN 0.00</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Session Last Updated:</StakeStatData>
                    <StakeStatValue>June 25th 2021, 10:08 am</StakeStatValue>
                  </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Rate</StakeStatData>
                    <StakeStatValue>250NGN/GB</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Data Used</StakeStatData>
                    <StakeStatValue>23.5GB</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Service Charge</StakeStatData>
                    <StakeStatValue>NGN 0.0</StakeStatValue>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <StakeStatData>Wicrypt Commission</StakeStatData>
                    <StakeStatValue>NGN 250.5</StakeStatValue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default DeviceSessionDetail;
