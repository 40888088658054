import React from "react";
import {
  TableLabel,
  TableHead,
  TableValues,
  Table,
  TableContainer,
} from "../tables/tablestyles";

const CustomLoader = () => {
  let data = { rows: [1, 2, 3, 4, 5, 6, 7], columns: [1, 2, 3, 4, 5, 6, 7] };
  return (
    <div className="card card-small mb-4 ">
      <TableContainer>
        {/* <TableFlex>
          <Searchbox className="skeleton-name p-3" placeholder="Search..." />
        </TableFlex> */}
        <Table className="table mb-0">
          <TableHead>
            <tr>
              {data.columns.map((column, i) => {
                return (
                  <TableLabel scope="col" key={i} className="border-0">
                    {column.label}
                  </TableLabel>
                );
              })}
            </tr>
          </TableHead>
          <tbody>
            {data.rows.map((row, i) => {
              return (
                <tr key={i}>
                  {data.columns.map((column, i) => {
                    return (
                      <TableValues key={i} className="skeleton-name">
                        {row[`${column.field}`]}
                      </TableValues>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomLoader;
