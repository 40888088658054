import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Requests from "./requests";
import { MainContent } from "../Partials/partials";
import SubSidenav from '../Partials/SubSidenav';

class AllBusiness extends Component {
  render() {
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <SubSidenav />
            <Mainnav pageTitle="Crypto Withdrawal Requests"/>
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
           <Requests />
          </div>
        </MainContent>
      </div>
    );
  }
}

export default AllBusiness;
