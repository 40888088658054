import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  switchWalletTransactionReport
} from "../../Redux/actions";
import IconCard from "../Reusables/cards/IconCard";
import {
  numberWithCommas,
} from "../../Helper";
import moment from "moment";
import { StakeStatData, StakeStatValue } from "./style";
import CustomLoader from "../Reusables/loaders/CustomLoader";


const SwitchWalletReportDaily = ({ selectedDate }) => {

  const dispatch = useDispatch();

  const { switchWalletReportData } = useSelector((state) => state.Transactions);
  const { gettingDailySwitchWalletReport } = useSelector((state) => state.Loading);

  useEffect(() => {
    dispatch(switchWalletTransactionReport(selectedDate));
  }, [selectedDate]);

  const { balances, dateCreated, anticipatedClosingBalance, totalDeposits, totalWithdrawals } = switchWalletReportData


  return (
    <div className="col-lg-12">
      <div className="card w3-animate-bottom">
        <div className="card-body p-6 ">
          {gettingDailySwitchWalletReport ? null : <p className="m-0 text-bold mb-5">{moment(dateCreated).format("DD-MM-YYYY")}</p>}
          {anticipatedClosingBalance.length > 0 ? <h6 className="mb-0 text-bold mt-2 mb-2">ANTICIPATED CLOSING BALANCE</h6> : null}
          {gettingDailySwitchWalletReport ? <CustomLoader /> : <div className="row"> {anticipatedClosingBalance && (
            anticipatedClosingBalance.map(report => {
              return ( <IconCard
                title={report.currency}
                image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
                value={numberWithCommas(report.balance)}
                id={1}
                link={"#"}
              />)
            })

          ) }</div>}
          <hr/>
          {balances.length > 0 ? <h6 className="mb-0 text-bold mt-2 mb-2">ACTUAL BALANCE</h6> : null}
          {gettingDailySwitchWalletReport ? <CustomLoader /> : balances && (
            balances.map(report => {
              return (<div >
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <StakeStatData>Currency</StakeStatData>
                    <StakeStatValue>
                      {report.currency}
                    </StakeStatValue>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <StakeStatData>Opening Balance</StakeStatData>
                    <StakeStatValue>
                      {numberWithCommas(report.openingBalance)}
                    </StakeStatValue>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <StakeStatData>Closing Balance</StakeStatData>
                    <StakeStatValue>
                      {numberWithCommas(report.closingBalance)}
                    </StakeStatValue>
                  </div>
                </div>
                <hr />
              </div>)
            })

          )}
          {totalDeposits.length > 0 ? <h6 className="mb-0 text-bold mt-2 mb-2">TOTAL DEPOSIT</h6> : null}
          {gettingDailySwitchWalletReport ? <CustomLoader /> : totalDeposits && (
            totalDeposits.map(report => {
              return (
                <IconCard
                  title={report.currency}
                  image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
                  value={numberWithCommas(report.amount)}
                  id={1}
                  link={"#"}
                />
              )
            })

          )}
          <hr/>
          {totalWithdrawals.length > 0 ?<h6 className="mb-0 text-bold mt-2 mb-2">TOTAL WITHDRAWAL</h6> : null}
          {gettingDailySwitchWalletReport ? <CustomLoader /> : totalWithdrawals && (
            totalWithdrawals.map(report => {
              return (
                <IconCard
                  title={report.currency}
                  image={"/Assets/dashboardicons/Extra/SVG/devices.svg"}
                  value={numberWithCommas(report.amount)}
                  id={1}
                  link={"#"}
                />
              )
            })

          )}
        </div>
      </div>
    </div>
  );

}



export default SwitchWalletReportDaily;
