
import styled from "styled-components";



export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 493px;
  height: 50px;
  background: #25346a;
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  outline: none;
  border: none;
`;

export const Notification = styled.div`
  padding: 20px;
  width: 100%;
  height: auto;
  background: rgba(37, 52, 106, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const NotificationText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6b6b6b;
  text-align: center;
`;

export const ModeOption = styled.div`
  padding: 20px;
  width: 100%;
  height: auto;
  background: rgba(37, 52, 106, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer
`;

export const DeviceList = styled.div`
  height: 200px;
  overflow-y: scroll;
`

export const DeviceUl = styled.ul`
  margin-top: 20px;
  padding: 10px;
`
export const DeviceLi = styled.li`
  padding: 7px;
  box-shadow: 1px 1px 2px 2px #b7b7b7;
  margin-bottom: 10px;
  
`

export const SingleDevice = styled.ul`
  padding: 10px;
  border-top: 1px solid grey

`