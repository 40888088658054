import React, { useEffect, useState } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { StakeStatData, StakeStatValue } from "./styles";
// import { blockDevice } from "../../Redux/actions/AdminDevicesAction";
import moment from "moment";
import {
  formatBillingrate,
  niceBytes,
  numberWithCommas,
  numberWithCommaswithoutdecimals,
} from "../../Helper";
// import { MoreOptions, Option } from "../Devices/devicestyles";
import SelectDeviceType from "../Devices/modals/SelectDeviceType";
import AssignSubAdmin from "../Devices/modals/AssignSubAdmin";
import SetMininReward from "../Devices/modals/SetMininReward";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCurrency } from "../../Redux/actions/AuthAction";
import { changeActiveTab } from "../../Redux/actions";
import { changeTransactionType } from "../../Redux/actions/TransactionsAction";
import { clearForms } from "../../Redux/actions/GeneralAction";

const SingleDevice = () => {
  // const [showMore, setShowMore] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const userId = window.location.pathname.split("/").at(-2);
  const { currencyValue, currencyCode } = useSelector((state) => state.User);
  const currentTab = localStorage.getItem("activeTab");
  const dispatch = useDispatch();

  useEffect(() => {
    const res = JSON.parse(localStorage.getItem("selectedDevice"));
    setSelectedDevice(res);
    getCountryCurrency();
  }, []);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link
                to={`/user/${userId}`}
                className="pointer mb-0"
                onClick={() => {
                  dispatch(changeActiveTab(Number(currentTab)));
                  dispatch(
                    changeTransactionType(
                      currentTab === 1
                        ? "all"
                        : currentTab === 2
                        ? "online"
                        : currentTab === 3
                        ? "offline"
                        : "blacklisted"
                    )
                  );
                  dispatch(clearForms());
                }}
              >
                {" "}
                <i className="material-icons">arrow_back</i>{" "}
                {selectedDevice && selectedDevice.deviceName}
              </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mb-5">
          <SubSidenav />
          <SetMininReward />
          <SelectDeviceType />
          <AssignSubAdmin />
          <div className="col-lg-12 ">
            <div className="card w3-animate-bottom">
              {selectedDevice && (
                <div className="card-body p-6 ">
                  <div className="row mb-2">
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Owner</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.deviceOwner}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Email</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.ownerEmail}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Name</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.deviceName}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Assigned to Sub-Admin</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.hasSubAdmin ? "Yes" : "No"}
                      </StakeStatValue>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                      <StakeStatData>Mining Reward</StakeStatData>
                      <StakeStatValue
                        className="d-flex"
                        style={{ gap: "10px", alignItems: "center" }}
                      >
                        {selectedDevice.miningPercentage}%{" "}
                        <div>
                          <button
                            onClick={() => dispatch(handleModal(12))}
                            className="btn button accept"
                          >
                            Set
                          </button>
                        </div>
                      </StakeStatValue>
                    </div> */}
                  </div>
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Billing Rate</StakeStatData>
                      <StakeStatValue>
                        {formatBillingrate(
                          selectedDevice.billingRate,
                          currencyValue,
                          currencyCode
                        )}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      <StakeStatValue>{selectedDevice.status}</StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Total Uptime</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.uptime + " "}Hrs
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Last Activity Date</StakeStatData>
                      {selectedDevice.lastActivityDate ? 
                        <StakeStatValue>
                          {moment(selectedDevice.lastActivityDate).format(
                          "DD-MM-YYYY, h:mm:ss a"
                        )}
                       </StakeStatValue> :  <StakeStatValue> N/A </StakeStatValue>} 
                    </div>
                  </div>
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Activation Date</StakeStatData>
                      <StakeStatValue>
                        {moment(selectedDevice.deviceActivationDate).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        |{" "}
                        {moment(selectedDevice.deviceActivationDate).format(
                          "LT"
                        )}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Earnings</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.minedWNT} WNT
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Revenue Generated </StakeStatData>
                      <StakeStatValue>
                        NGN{" "}
                        {numberWithCommas(
                          selectedDevice.revenueGeneratedInNaira
                        )}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>NFT Enabled</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.isNFTDevice ? "Enabled" : "Disabled"}
                      </StakeStatValue>
                    </div>
                  </div>
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Model</StakeStatData>
                      <StakeStatValue>{selectedDevice.model}</StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Sessions</StakeStatData>
                      <StakeStatValue>
                        {" "}
                        {numberWithCommaswithoutdecimals(
                          selectedDevice.sessions
                        )}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Data Used</StakeStatData>
                      <StakeStatValue>
                        {niceBytes(selectedDevice.dataUsed)}
                      </StakeStatValue>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Type</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.deviceType}
                      </StakeStatValue>
                    </div>
                  </div>
                  {/* <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Assigned to Sub-Admin</StakeStatData>
                      <StakeStatValue>
                        {selectedDevice.hasSubAdmin ? "Yes" : "No"}
                      </StakeStatValue>
                    </div>
                  </div> */}
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    <div className="col-lg-3 col-md-6 d-flex">
                      {/* <div style={{ display: "flex", alignItems: "center" }}>
                        {selectedDevice.status === "Blocked"
                          ? // <button
                            //   onClick={() => {
                            //     unBlockDevice(dispatch, selectedDevice.deviceId);
                            //   }}
                            //   className="btn button accept"
                            // >
                            //   {blockingDevice ? (
                            //     <BeatLoading color="#e5b90a" size={10} />
                            //   ) : (
                            //     "UnBlock Device"
                            //   )}
                            // </button>
                            ""
                          : // <div
                            //   className={`${
                            //     selectedDevice.status === "Online"
                            //       ? "online"
                            //       : selectedDevice.status === "Offline"
                            //       ? "offline"
                            //       : "blocked"
                            //   }`}
                            // >
                            //   {selectedDevice.status || "N/A"}
                            // </div>
                            // <button
                            //   onClick={() => {
                            //     blockDevice(dispatch, selectedDevice.deviceId);
                            //   }}
                            //   className="btn button accept"
                            // >
                            //   {blockingDevice ? (
                            //     <BeatLoading color="#e5b90a" size={10} />
                            //   ) : (
                            //     "Block Device"
                            //   )}
                            // </button>"
                            ""}
                      </div> */}
                      <div style={{ display: "flex", position: "relative" }}>
                        {/* <button
                          onClick={() => setShowMore(!showMore)}
                          className="btn button verify"
                        >
                          More options
                        </button> */}
                        {/* {showMore && (
                          <MoreOptions active={true}>
                            <Option>
                              <Link
                                to={`/devices/singledevice/trackdata/${selectedDevice.deviceId}`}
                              >
                                Track Data
                              </Link>
                            </Option>
                            <Option
                              onClick={() => {
                                dispatch(handleModal(13));
                              }}
                            >
                              Re-assign Admin
                            </Option>
                          </MoreOptions>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default SingleDevice;
