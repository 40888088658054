import _const from "./Types";

export const getSearchDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.SEARCH_VALUE,
      payload: payload,
    });
  };
};
