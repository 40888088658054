import _const from "../actions/Types";

const initialState = {
  verificationRequests: [],
  oneRequest: {},
  noOfVerification: 0,
  onePage: false,
  verificationTotalRecords: 0,
  verificationCurrentPage: 1,
  verificationTotalPages: 1,
  verificationTypes: [],
  idverificationfail: "",
  verificationDetails: {},
  idVerificationStatus: false,
  kycRequests: [],
  noOfkycRequests: 0,
  kycRequestsTotalRecords: 0,
  kycRequestsCurrentPage: 1,
  kycRequestsTotalPages: 1,
  verificationSummary: {}
};

const OverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.GET_VERIFICATION_REQUESTS:
      return {
        ...state,
        verificationRequests: action.payload,
        noOfVerification: action.payload.length,
        verificationTotalRecords: action.totalrecords,
        verificationCurrentPage: action.currentPage,
        verificationTotalPages: action.totalPages,
      };

    case _const.ALL_KYC_REQUESTS:
      return{
        ...state,
        kycRequests: action.payload,
        noOfkycRequests: action.payload.length,
        kycRequestsTotalRecords: action.totalrecords,
        kycRequestsCurrentPage: action.currentPage,
        kycRequestsTotalPages: action.totalPages,
      }
    
    case _const.VERIFICATION_SUMMARY:
      return{...state, verificationSummary: action.payload}

    case _const.CLEAR_DATA:
      return { ...state, verificationRequests: [] };

    case _const.ONE_VERIFICATION_REQUEST:
      const request = state.verificationRequests.find(function(oneRequest) {
        return action.payload === oneRequest.id;
      });
      return {
        ...state,
        oneRequest: request,
        onePage: true,
        idVerificationStatus: false,
        verificationDetails: {},
        idverificationfail: "",
      };

    case _const.TAKE_USER_BACK:
      return { ...state, onePage: false };

    case _const.VERIFICATION_TYPES:
      return { ...state, verificationTypes: action.payload };

    case _const.ID_VERIFICATION_FAIL:
      return { ...state, idverificationfail: action.payload };

    case _const.ID_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationDetails: action.payload,
        idVerificationStatus: true,
      };

    default:
      return state;
  }
};

export default OverviewReducer;
