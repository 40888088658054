import _const from "../actions/Types";

const initialState = {
  modalId: 0,
  toggleSubnav: 1,
};

const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.TOGGLE_MODAL:
      return { ...state, modalId: action.payload };

    case _const.TOGGLE_SUBNAV:
      localStorage.setItem("currentSubnav", action.payload);
      return { ...state, toggleSubnav: action.payload };

    case _const.MERCHANT_REGISTRATION_SUCCESSFUL:
      return { ...state, modalId: 120 };

    default:
      return state;
  }
};

export default GeneralReducer;
