import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormDetails, toggleNav } from '../../Redux/actions';
import Loader from '../Reusables/loaders/HashLoader';
import DashboardCard from '../Reusables/cards/DashboardCards';
import IconCard from "../Reusables/cards/IconCard";
import DoubleIconCard from '../Reusables/cards/DoubleIconcard'
import filesize from 'filesize';
import { numberWithCommas, numberWithCommaswithoutdecimals } from '../../Helper';


class ManagerStats extends Component {


  render() {
    const { allVouchersTotalRecords, bought, sold, selectedid, managerLoading, gettingVouchers,bizsummaryReport, conversionsRate,  gettingBusinessReport, viewbalance} = this.props

    return (
      <div className="row">
        <DashboardCard title={'Devices'} closesidenav={()=>{this.props.getFormDetails({props: ['togglesubnav'], value: false }); this.props.toggleNav(false)}} link={'/devices'} value={gettingBusinessReport ? <Loader /> : bizsummaryReport.totalNumberOfDevices} />
        <DashboardCard title={'Agents'} closesidenav={()=>{this.props.getFormDetails({props: ['togglesubnav'], value: false });  this.props.toggleNav(false)}} link={'/agents'} value={gettingBusinessReport ? <Loader /> : bizsummaryReport.totalAgents} />
        <DoubleIconCard
        title={'Total Revenue (NGN)'} 
        id={5} 
        selectedid={selectedid}
        balanceview = {1}
        description={"This is the total amount in Naira that you have made from selling your data on Wicrypt"}
        togglebalance = {()=> this.props.getFormDetails({
          props: ["viewbalance"],
          value: viewbalance === 1 ? 0 : 1,
        })}
        viewbalance = {viewbalance}
        toggledescription={()=>this.props.getFormDetails({
          props: ["selectedid"],
          value: selectedid === 5 ? 0 : 5,
        })}
        value={gettingBusinessReport ? <Loader /> : numberWithCommas(bizsummaryReport.totalSalesAmount * conversionsRate)} />
        <DoubleIconCard
        title={'Business balance (NGN)'} 
        id={6} 
        selectedid={selectedid}
        togglebalance = {()=> this.props.getFormDetails({
          props: ["viewbalance"],
          value: viewbalance === 2 ? 0 : 2,
        })}
        balanceview = {2}
        viewbalance = {viewbalance}
        description={"This is the total amount in Naira that you have in your business wallet"}
        toggledescription={()=>this.props.getFormDetails({
          props: ["selectedid"],
          value: selectedid === 6 ? 0 : 6,
        })}
        value={gettingBusinessReport ? <Loader /> : numberWithCommas(bizsummaryReport.businessBalance * conversionsRate)} />
        <DashboardCard title={'Vouchers'} link={'/voucher'} value={gettingVouchers ? <Loader /> : allVouchersTotalRecords} />
        <IconCard title={'Total Sold'} 
        image={'/Assets/navicons/datasold.svg'} 
        value={managerLoading ? <Loader /> : filesize(sold)} 
        id={1}
        selectedid={selectedid}
        toggledescription={()=>this.props.getFormDetails({
          props: ["selectedid"],
          value: selectedid === 1 ? 0 : 1,
        })}
        description={"This is the total amount of data that has been sold across your Wicrypt devices"}
        />
        {/* <IconCard 
          title={'Data Bought'} 
          image={'/Assets/navicons/databought.svg'} 
          value={managerLoading ? <Loader /> : filesize(bought)} 
          id={2}
          selectedid={selectedid}
          toggledescription={()=>this.props.getFormDetails({
            props: ["selectedid"],
            value: selectedid === 2 ? 0 : 2,
          })}
          description={"This is the total amount of data that has been recorded as bought across your Wicrypt devices"}
        /> */}
        <IconCard 
          title={'Total Used'} 
          image={'/Assets/navicons/dataused.svg'} 
          value={gettingVouchers ? <Loader /> : filesize(bought)} 
          id={3}
          selectedid={selectedid}
          toggledescription={()=>this.props.getFormDetails({
            props: ["selectedid"],
            value: selectedid === 3 ? 0 : 3,
          })}
          description={"This is the total amount of data that you have used connecting to other business devices"}
        />
        <IconCard 
          title={'Sessions'} 
          image={'/Assets/navicons/sessions.svg'} 
          value={gettingBusinessReport ? <Loader /> : numberWithCommaswithoutdecimals(bizsummaryReport.totalSessionsForBusiness)} 
          id={4}
          selectedid={selectedid}
          toggledescription={()=>this.props.getFormDetails({
            props: ["selectedid"],
            value: selectedid === 4 ? 0 : 4,
          })}
          description={"This is the number of times users have connected to your Wicrypt devices"}
        />
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { managerLoading, fetchingAgents, infoLoading, gettingVouchers,  gettingBusinessReport } = state.Loading
  const { deviceCount, activeDevices } = state.Manager
  const { allAgents } = state.Agent
  const { allVouchersTotalRecords } = state.Voucher
  const { pinReveal, startDate, endDate, myBusinessId, bought, sold, selectedid, viewbalance} = state.User
  const { bizsummaryReport, conversionsRate } = state.Report
  return {
    managerLoading,
    fetchingAgents,
    infoLoading,
    deviceCount,
    activeDevices,
    allAgents,
    gettingVouchers,
    allVouchersTotalRecords,
    pinReveal,
    myBusinessId,
    startDate, endDate,
    bought, sold,
    bizsummaryReport, 
    conversionsRate,
    selectedid,
    gettingBusinessReport,
    viewbalance
  }
}
export default connect(MapStateToProps, { getFormDetails, toggleNav })(ManagerStats)