export const DataUsageHeaderOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    options: [
      {
        id: 1,
        option: "All",
      },
      {
        id: 2,
        option: "Last Week",
      },
      {
        id: 3,
        option: "Last Month",
      },
      {
        id: 4,
        option: "Last 5 Months",
      },
      {
        id: 5,
        option: "Last Year",
      },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Enter agent name",
    onChangeProp: "",
  },
];
