import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRewardBooster, updateAPR } from "../../Redux/actions";
import { SpendInput } from "../UserDetails/styles";
import Loader from "../Reusables/loaders/MoonLoader";

const RewardBooster = () => {
    const dispatch = useDispatch();
    const [APR, setAPR] = useState("");
    const [period, setPeriod] = useState("");
    const { rewardBooster } = useSelector((state) => state.Service);
    const { updatingBooster } = useSelector((state) => state.Loading);

    const handleChange = (booster) => {
        let data = {
            lockPeriod: booster.lockPeriod,
            apr: APR,
            applyToExistingLockPeriods: false
        }

        dispatch(updateAPR(data))
    }

    useEffect(() => {
        dispatch(getRewardBooster(""));
    }, []);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className=" offset-lg-3 col-lg-6 mb-4">
                        <div className="card ">

                            <div className="card-header businessCard">
                                <button type="button"
                                    className="d-block mb-2 btn btn-sm btn-pill fl-right ">
                                    Set Penalty
                                </button>
                            </div>
                            <div className="p-20">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="border-0 bold">Period</th>
                                            <th scope="col" className="border-0 bold t-center">APR</th>
                                            <th scope="col" className="border-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rewardBooster.map(booster => {
                                            return (
                                                <tr>
                                                    <td>{booster.lockPeriod}</td>
                                                    <td>
                                                        <SpendInput disabled={false} type="number"
                                                            onChange={(e) => { setAPR(e.target.value); setPeriod(booster.lockPeriod) }}
                                                            defaultValue={booster.apr}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className='details mr-3'
                                                            onClick={() => handleChange(booster)}
                                                        >
                                                            {updatingBooster && period === booster.lockPeriod ?
                                                                <Loader size={4} color={"#fff"} /> :
                                                                "Update"}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewardBooster;
