import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

export const getStakeHistory = async (dispatch, parameters, type) => {
    dispatch({
        type: _const.GETTING_STAKE_HISTORY,
        payload: true,
    });
    const params = parameters;
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            delete params[key];
        }
    });
    await axios
        .get(config.adminApiUrl + `/v1/staking/${type}-history`, {
            params,
            headers: {
                accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
            },
        })
        .then((res) => {
            dispatch({
                type: _const.GETTING_STAKE_HISTORY,
                payload: false,
            });
            dispatch({
                type: _const.STAKE_HISTORY,
                payload: res.data.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: _const.GETTING_STAKE_HISTORY,
                payload: false,
            });
        });
};


export const getStakeLog = (stakingId) => {

    return (dispatch) => {
        dispatch({
            type: _const.GETTING_STAKE_LOG,
            payload: true,
        });

        axios.get(config.adminApiUrl + `/v1/staking/staking-detail/${stakingId}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_STAKE_LOG,
                    payload: false,
                });
                dispatch({
                    type: _const.STAKE_LOG,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_STAKE_LOG,
                    payload: false,
                });
            });
    }

};

export const getStakingSummary = (duration) => {

    return (dispatch) => {
        dispatch({
            type: _const.GETTING_STAKE_SUMMARY,
            payload: true,
        });

        axios.get(config.adminApiUrl + `/v1/staking/staking-summary?Duration=${duration}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_STAKE_SUMMARY,
                    payload: false,
                });
                dispatch({
                    type: _const.STAKING_SUMMARY,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_STAKE_SUMMARY,
                    payload: false,
                });
            });
    }

};

//Get Unstake overview
export const getUnstakeOverview = () => {

    return (dispatch) => {
        dispatch({
            type: _const.GETTING_UNSTAKE_SUMMARY,
            payload: true,
        });

        axios.get(config.adminApiUrl + `/v1/stake-reports`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_UNSTAKE_SUMMARY,
                    payload: false,
                });
                dispatch({
                    type: _const.UNSTAKE_SUMMARY,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_UNSTAKE_SUMMARY,
                    payload: false,
                });
            });
    }

};

//Get Stake Report
export const getStakingStatisticReport = (duration, page, pageSize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_STAKING_STATISTICS_REPORT,
            payload: true,
        });
        axios.get(config.adminApiUrl + `/v1/stake-reports/details?stakeDurationFilter=${duration}&Page=${page}&PageSize=${pageSize}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_STAKING_STATISTICS_REPORT,
                    payload: false,
                });
                dispatch({
                    type: _const.STAKING_STATISTICS_REPORT,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_STAKING_STATISTICS_REPORT,
                    payload: false,
                });
            });
    }

};

//Get upcoming unstake Report
export const getUpcomingUnstakeReport = (duration, page, pageSize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_UPCOMING_UNSTAKE_REPORT,
            payload: true,
        });
        axios.get(config.adminApiUrl + `/v1/stake-reports/projected-unstake-activity/details?duration=${duration}&Page=${page}&PageSize=${pageSize}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_UPCOMING_UNSTAKE_REPORT,
                    payload: false,
                });
                dispatch({
                    type: _const.UPCOMING_UNSTAKE_REPORT,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_UPCOMING_UNSTAKE_REPORT,
                    payload: false,
                });
            });
    }

};

//Get upcoming unstake Overview
export const getUpcomingUnstakeOverview = (duration) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_UPCOMING_UNSTAKE_OVERVIEW,
            payload: true,
        });
        axios.get(config.adminApiUrl + `/v1/stake-reports/projected-unstake-activity/overview?duration=${duration}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_UPCOMING_UNSTAKE_OVERVIEW,
                    payload: false,
                });
                dispatch({
                    type: _const.UPCOMING_UNSTAKE_OVERVIEW,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_UPCOMING_UNSTAKE_OVERVIEW,
                    payload: false,
                });
            });
    }

};

//Get distributed reward 
export const getDistributedReward = (page, pageSize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_DISTRIBUTED_REWARD,
            payload: true,
        });
        axios.get(config.adminApiUrl + `/v1/stake-reports/earned-staking-reward/overview?Page=${page}&PageSize=${pageSize}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_DISTRIBUTED_REWARD,
                    payload: false,
                });
                dispatch({
                    type: _const.DISTRIBUTED_REWARD_OVERVIEW,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_DISTRIBUTED_REWARD,
                    payload: false,
                });
            });
    }

};

//Get distributed reward details
export const getDistributedRewarddetails = (disbursedRewardId, page, pageSize) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_DISTRIBUTED_REWARD_DETAILS,
            payload: true,
        });
        axios.get(config.adminApiUrl + `/v1/stake-reports/earned-staking-reward/${disbursedRewardId}/details?Page=${page}&PageSize=${pageSize}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_DISTRIBUTED_REWARD_DETAILS,
                    payload: false,
                });
                dispatch({
                    type: _const.DISTRIBUTED_REWARD_DETAILS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_DISTRIBUTED_REWARD_DETAILS,
                    payload: false,
                });
            });
    }

};