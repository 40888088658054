import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";

const SUCCESS = 200;

export const getDateTo = (dateTo) => {
  return (dispatch) => {
    dispatch({
      type: _const.GET_DATETO,
      payload: dateTo,
    });
  };
};

export const getDateFrom = (dateFrom) => {
  return (dispatch) => {
    dispatch({
      type: _const.GET_DATEFROM,
      payload: dateFrom,
    });
  };
};

export const dateRangeData = (startDate, stopDate) => {
  let dates = [startDate, stopDate];
  let sortedArray = dates.sort((a, b) => a - b);
  if (startDate === stopDate) {
    return (dispatch) => {
      dispatch({
        type: _const.PICK_A_RANGE,
        payload: true,
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: _const.GETTING_DATE_RANGE_DATA,
        payload: true,
      });
      axios
        .get(
          config.apiUrl +
          `/v2/admin/chart/range?startDate=${sortedArray[0]}&endDate=${sortedArray[1]}`
        )
        .then((res) => {
          dispatch({
            type: _const.GETTING_DATE_RANGE_DATA,
            payload: false,
          });
          if (res.status === SUCCESS) {
            dispatch({
              type: _const.DATA_RANGE_DATA,
              payload: res.data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _const.GETTING_DATE_RANGE_DATA,
            payload: false,
          });
        });
    };
  }
};
