import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails, handleSubNav } from "../../../Redux/actions";
import { formatString, numberWithCommas, shortenAddress } from "../../../Helper";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "../../Devices/styles";
import { MdFileCopy } from "react-icons/md";
import EmptyCard from "../../Reusables/cards/EmptyCard";

const NewSingleStakeInterestClaims = ({history}) => {

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const transactionCategory = "staking-interest-claims";
  const [transcId, setTranscId] = useState(null);

  const goBack = () => {
    history.goBack();
  };
  
  useEffect(() => {
    const newTranscId = window.location.pathname.split("/").at(-1);
    setTranscId(newTranscId);
  }, []); 
  
  useEffect(() => {
    if (transcId) {
      dispatch(getNewTransactionDetails(transactionCategory, transcId));
    }
  }, [transcId, dispatch]);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingNewTrancactionDetails ?
          <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
             {Object.keys(newTransactionDetails).length !== 0 ? 
             <div className="card-body p-6 ">
                <div className="row mb-2">
                  { newTransactionDetails.userInfo &&
                    <div className="col-lg-6 col-md-6">
                    <PersonalBox>
                      <UserDetailsAvatar
                        className="rounded-circle"
                        src="/Assets/images/avatars/user_icon.svg"
                        alt="User Avatar"
                      />
                      <PersonalInfo>
                        <span>{newTransactionDetails.userInfo && newTransactionDetails.userInfo.name ? newTransactionDetails.userInfo.name : "N/A"}</span>
                        <span>{newTransactionDetails.userInfo && newTransactionDetails.userInfo.email ? newTransactionDetails.userInfo.email : "N/A"}</span>
                      </PersonalInfo>
                      {newTransactionDetails.userInfo.id ? 
                        <Link to={`/user/${newTransactionDetails.userInfo.id}`} 
                        onClick={() => {
                          dispatch(handleSubNav(1)); 
                        }}
                        >
                          <div className="details min-width"> Details </div></Link> : <div className="details min-width"> Not Available </div>}
                    </PersonalBox>
                  </div>
                  }
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      {newTransactionDetails.transactionTimeStamp ? (
                        <StakeStatValue>
                          {moment(newTransactionDetails.transactionTimeStamp).format("DD-MM-YYYY")} |{" "}
                          {moment(newTransactionDetails.transactionTimeStamp).format("LT")}
                        </StakeStatValue>
                      ) : "N/A" }
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.status ? (
                        <StakeStatValue className={newTransactionDetails.walletTransaction.status.toUpperCase()}>{newTransactionDetails.walletTransaction.status}</StakeStatValue>
                        ) : "N/A" }
                    </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Fee</StakeStatData>
                      {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.fee ? (
                        <StakeStatValue>{numberWithCommas(newTransactionDetails.walletTransaction.fee, 4)}</StakeStatValue>
                      ) : 0 }
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Total Amount</StakeStatData>
                      {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.totalAmount && newTransactionDetails.walletTransaction.currency ? (
                        <StakeStatValue>
                          {numberWithCommas(newTransactionDetails.walletTransaction.totalAmount, 4)} {newTransactionDetails.walletTransaction.currency}
                        </StakeStatValue>
                      ) : 0 }
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Category</StakeStatData>
                      {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.category ? (
                        <StakeStatValue className={newTransactionDetails.walletTransaction.category.toLowerCase()}>{formatString(newTransactionDetails.walletTransaction.category)}</StakeStatValue>
                      ) : "N/A" }
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Transaction Type</StakeStatData>
                      {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.transactionType ? (
                        <StakeStatValue className={newTransactionDetails.walletTransaction.transactionType.toLowerCase()}>{newTransactionDetails.walletTransaction.transactionType}</StakeStatValue>
                      ) : "N/A" }
                    </div>
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-lg-12 col-md-12">
                    <StakeStatData>Description</StakeStatData>
                    {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.description ? (
                      <StakeStatValue>{newTransactionDetails.walletTransaction.description}</StakeStatValue>
                      ) : "N/A" }
                  </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                 
                    <div className="col-md-6">
                      <StakeStatData> Wallet Balance State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th colSpan={2}>Available Balance</th>
                              <th colSpan={2}>Locked Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            <tr>
                            {/* Available Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            {/* Locked Balance */}
                              <TableValues>Before</TableValues>
                              <TableValues>After</TableValues>
                            </tr>
                            {newTransactionDetails.walletBalanceStateChange && (
                              <tr>
                              <TableValues>
                                    {newTransactionDetails.walletBalanceStateChange && newTransactionDetails.walletBalanceStateChange.availableBalanceBefore ? 
                                    numberWithCommas(newTransactionDetails.walletBalanceStateChange.availableBalanceBefore, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { newTransactionDetails.walletBalanceStateChange && newTransactionDetails.walletBalanceStateChange.availableBalanceAfter ? 
                                    numberWithCommas(newTransactionDetails.walletBalanceStateChange.availableBalanceAfter, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { newTransactionDetails.walletBalanceStateChange && newTransactionDetails.walletBalanceStateChange.lockedBalanceBefore ? 
                                    numberWithCommas(newTransactionDetails.walletBalanceStateChange.lockedBalanceBefore, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { newTransactionDetails.walletBalanceStateChange && newTransactionDetails.walletBalanceStateChange.lockedBalanceAfter ? 
                                    numberWithCommas(newTransactionDetails.walletBalanceStateChange.lockedBalanceAfter, 4) : 0}
                                  </TableValues>
                              </tr>
                            )}
                        
                          </tbody>
                        </Table>
                      </div>
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8", marginBottom: "1rem"}}
                  >
                    <div className="col-md-12">
                      <StakeStatData>Wallet Transaction</StakeStatData>
                      <div style={{ marginTop: "5px" }} className="table-on-mobile">
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Date</th>
                              <th>Reference</th>
                              <th>Type</th>
                              <th>Category</th>
                              <th>Fee</th>
                              <th>Total Amount</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th>Transaction Type</th>
                              <th>Channel</th>
                            </tr>
                          </TableHead>
                          <tbody style={{marginTop: "4px"}}>
                          {newTransactionDetails.walletTransaction && (
                              <tr>
                                {newTransactionDetails.transactionTimeStamp ? <TableValues data-label="Date">
                                  {moment(newTransactionDetails.transactionTimeStamp).format("DD-MM-YYYY")} |{" "}
                                  {moment(newTransactionDetails.transactionTimeStamp).format("LT")}
                                </TableValues> : "N/A"}
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.reference ? (<TableValues data-label="Reference">
                                  <div 
                                    onClick={() => {
                                      navigator.clipboard.writeText(newTransactionDetails.walletTransaction.reference);
                                      setIsCopied(true);
                                      // Reset the "Copied" state after a short delay
                                      setTimeout(() => {
                                        setIsCopied(false);
                                      }, 1500);
                                    }}
                                    >
                                    <span className="">{shortenAddress(newTransactionDetails.walletTransaction.reference)}</span>
                                    <span className="pl-2"><MdFileCopy /></span>
                                    <span className="pl-2">{isCopied ? "Copied" : ""}</span>
                                  </div>
                                </TableValues>) : "N/A"}
                                {newTransactionDetails.type ? <TableValues data-label="Type" className={newTransactionDetails.type.toLowerCase()}>{formatString(newTransactionDetails.type)}</TableValues> : "N/A"}
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.category ? <TableValues data-label="Category" className={newTransactionDetails.walletTransaction.category.toLowerCase()}>{formatString(newTransactionDetails.walletTransaction.category)}</TableValues> : "N/A"}
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.fee ? <TableValues data-label="Fee">{newTransactionDetails.walletTransaction.fee}</TableValues> : "N/A"}
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.totalAmount && newTransactionDetails.walletTransaction.currency ?<TableValues data-label="Total Amount">{numberWithCommas(newTransactionDetails.walletTransaction.totalAmount, 2)} {newTransactionDetails.walletTransaction.currency}</TableValues> : "N/A" }
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.amount && newTransactionDetails.walletTransaction.currency ? <TableValues data-label="Amount">{numberWithCommas(newTransactionDetails.walletTransaction.amount, 2)} {newTransactionDetails.walletTransaction.currency}</TableValues> : "N/A" }
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.status ? <TableValues data-label="Status" className={newTransactionDetails.walletTransaction.status.toUpperCase()}>{newTransactionDetails.walletTransaction.status}</TableValues> : "N/A" }
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.transactionType ? <TableValues data-label="Transaction Type" className={newTransactionDetails.walletTransaction.transactionType.toLowerCase()}>{newTransactionDetails.walletTransaction.transactionType}</TableValues> : "N/A" }
                                {newTransactionDetails.walletTransaction && newTransactionDetails.walletTransaction.transactionChannel ? <TableValues data-label="Channel">{newTransactionDetails.walletTransaction.transactionChannel}</TableValues> : "N/A"}
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                </div>

                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                    <div className="col-md-6">
                      <StakeStatData>Reward Balance State Change</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Balance Before</th>
                              <th>Balance After</th>
                            </tr>
                          </TableHead>
                          {newTransactionDetails.rewardBalanceStateChange && (
                            <tbody>
                              <tr>
                               {newTransactionDetails.rewardBalanceStateChange && newTransactionDetails.rewardBalanceStateChange.balanceBefore ? 
                                <TableValues>{numberWithCommas(newTransactionDetails.rewardBalanceStateChange.balanceBefore, 1) }</TableValues> : <TableValues>0</TableValues>}
                                {newTransactionDetails.rewardBalanceStateChange && newTransactionDetails.rewardBalanceStateChange.balanceAfter ? 
                                  <TableValues>{numberWithCommas(newTransactionDetails.rewardBalanceStateChange.balanceAfter, 1) }</TableValues> : <TableValues>0</TableValues>}
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <StakeStatData>Staking info snapshot</StakeStatData>
                      <div style={{ marginTop: "5px" }}>
                        <Table>
                          <TableHead>
                            <tr>
                              <th>Staked At</th>
                              <th>Duration</th>
                              <th>Stake Balance</th>
                            </tr>
                          </TableHead>
                          <tbody>
                            {newTransactionDetails.stakingInfoSnapshot && (
                              <tr>
                                {newTransactionDetails.stakingInfoSnapshot && newTransactionDetails.stakingInfoSnapshot.stakedAt ? <TableValues>
                                  {moment(newTransactionDetails.stakingInfoSnapshot.stakedAt).format("DD-MM-YYYY")} |{" "}
                                  {moment(newTransactionDetails.stakingInfoSnapshot.stakedAt).format("LT")}
                                </TableValues> : "N/A" }
                                {newTransactionDetails.stakingInfoSnapshot && newTransactionDetails.stakingInfoSnapshot.duration ? <TableValues>{newTransactionDetails.stakingInfoSnapshot.duration}</TableValues> : "N/A"}
                                {newTransactionDetails.stakingInfoSnapshot && newTransactionDetails.stakingInfoSnapshot.stakedBalance && newTransactionDetails.currency ? <TableValues>{numberWithCommas(newTransactionDetails.stakingInfoSnapshot.stakedBalance, 4)} {newTransactionDetails.currency}</TableValues> : 0 }
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                </div>
                
              </div> :
               <EmptyCard
               img="/Assets/emptytransactions.svg"
               title={"Sorry,  you have no transaction history"}
               // descr={"No transaction history available at the moment"}
             />}
            </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default NewSingleStakeInterestClaims;
