import styled from "styled-components";

export const Body = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 39px 30px;
  margin-top: 40px;
  width: 400px;
  height: auto;
`;

export const Question = styled.p`
  text-align: center;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.grey};
  margin-bottom: 0px;
  margin-top: 30px;
`;

export const Answer = styled.p`
  text-align: center;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.blue};
  margin-bottom: 0px;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  :hover {
    opacity: 0.6;
  }
`;
