import React from 'react'

const SuccessCard = ({msg}) => {
  return (
    <div className="col-lg-4 offset-lg-4 col-md-6 col-sm-6 mb-4">
      <div className="stats-small stats-small--1 card card-small">
        <div className="card-body d-flex mb-5">
          <div className="d-flex flex-column m-auto">
            <img src="/Assets/check.svg" className="mt-5" width="100%" height="70px" alt="success" />
            <p className="text-green mt-4 mb-5"> {msg}</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default SuccessCard