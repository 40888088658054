import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import BusinessReport from "./businessReport";
import { MainContent } from "../Partials/partials";

class index extends Component {
  render() {
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle={"Business Report"} />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <BusinessReport />
          </div>
        </MainContent>
      </div>
    );
  }
}

export default index;
