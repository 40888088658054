import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDeviceSummary, getFormDetails } from "../../Redux/actions";
import moment from "moment";
import { numberWithCommas } from "../../Helper";
import filesize from "filesize";
import EmptyCard from "../Reusables/cards/EmptyCard";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import CustomLoader from "../Reusables/loaders/CustomLoader";

class DeviceFilter extends Component {
  componentWillMount() {
    this.props.getDeviceSummary(
      moment(this.props.deviceStart).format("YYYY-MM-DD"),
      moment(this.props.deviceEnd).format("YYYY-MM-DD")
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.deviceStart !== this.props.deviceStart ||
      nextProps.deviceEnd !== this.props.deviceEnd
    ) {
      this.props.getDeviceSummary(
        moment(nextProps.deviceStart).format("YYYY-MM-DD"),
        moment(nextProps.deviceEnd).format("YYYY-MM-DD")
      );
    }
  }

  render() {
    const {
      deviceStart,
      deviceEnd,
      deviceSummary,
      gettingDeviceReport,
      opendatefrom,
      opendateto
    } = this.props;

    let allRows = [];
    deviceSummary.map((device, i) => {
      return allRows.push({
        key: device.id,
        devicename: device.deviceName == null ? "Unnamed" : device.deviceName,
        users: device.usersCount,
        sessions: device.sessionsCount,
        dataused: filesize(device.totalUsage),
        revenue: "NGN" + numberWithCommas(device.totalRevenue * 360)
      });
    });
    const data = {
      columns: [
        {
          label: "Device Name",
          field: "devicename",
          sort: "asc",
          width: 170
        },
        {
          label: "Number of Users",
          field: "users",
          sort: "asc",
          width: 170
        },
        {
          label: "Sessions",
          field: "sessions",
          sort: "asc",
          width: 170
        },
        {
          label: "Data Used",
          field: "dataused",
          sort: "asc",
          width: 170
        },
        {
          label: "Revenue",
          field: "revenue",
          sort: "asc",
          width: 170
        }
      ],

      rows: allRows
    };

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card-header mb-3" style={{ width: "50%" }}>
            <div className="d-flex">
              <div className="inline-flex p-r">
                <div className="t-12 p-8"> From:</div>
                <DatePicker
                  className="dateRange"
                  selected={deviceStart}
                  open={opendatefrom}
                  onChange={date => {
                    this.props.getFormDetails({
                      props: ["deviceStart"],
                      value: date
                    });
                    this.props.getFormDetails({
                      props: ["opendatefrom"],
                      value: false
                    });
                  }}
                />
                <i
                  onClick={() =>
                    this.props.getFormDetails({
                      props: ["opendatefrom"],
                      value: !opendatefrom
                    })
                  }
                  className="material-icons text-orange carlendarIcon"
                >
                  carlendar_today
                </i>
              </div>
              <div className="inline-flex p-r">
                <div className="t-12 p-8"> To:</div>
                <DatePicker
                  className="dateRange"
                  selected={deviceEnd}
                  open={opendateto}
                  onChange={date => {
                    this.props.getFormDetails({
                      props: ["deviceEnd"],
                      value: date
                    });
                    this.props.getFormDetails({
                      props: ["opendateto"],
                      value: false
                    });
                  }}
                />
                <i
                  onClick={() =>
                    this.props.getFormDetails({
                      props: ["opendateto"],
                      value: !opendateto
                    })
                  }
                  className="material-icons text-orange carlendarIcon"
                >
                  carlendar_today
                </i>
              </div>
            </div>
          </div>
          <div className="row">
            {(deviceSummary.length > 0) & gettingDeviceReport ? (
              <CustomLoader />
            ) : (
              // deviceSummary.length > 0 ?

              <div className="col-lg-12 mb-4">
                <CustomDataTable
                  data={data}
                  title={"Device Report"}
                  placeholder={"Search device name"}
                  // customright= {<div className="d-flex">
                  // <div className="inline-flex p-r">
                  //     <div className='t-12 p-8'> From:</div>
                  //     <DatePicker
                  //         className="dateRange"
                  //         selected={deviceStart}
                  //         open={opendatefrom}
                  //         onChange={(date) => {this.props.getFormDetails({ props: ['deviceStart'], value: date }); this.props.getFormDetails({ props: ['opendatefrom'], value: false }) }}
                  //     />
                  //     <i onClick ={() => this.props.getFormDetails({ props: ['opendatefrom'], value: !opendatefrom })} className="material-icons text-orange carlendarIcon">carlendar_today</i>
                  // </div>
                  // <div className="inline-flex p-r">
                  //     <div className='t-12 p-8'> To:</div>
                  //     <DatePicker
                  //         className="dateRange"
                  //         selected={deviceEnd}
                  //         open={opendateto}
                  //         title="Device Report"
                  //         onChange={(date) => {this.props.getFormDetails({ props: ['deviceEnd'], value: date }); this.props.getFormDetails({ props: ['opendateto'], value: false })}}
                  //     />
                  //      <i onClick ={() => this.props.getFormDetails({ props: ['opendateto'], value: !opendateto })} className="material-icons text-orange carlendarIcon">carlendar_today</i>
                  // </div>
                  // </div>}
                />
              </div>
            )}
            {!gettingDeviceReport & (deviceSummary.length === 0) ? (
              // <div className="col-lg-12 mb-4" >
              <EmptyCard
                img="/Assets/emptytransactions.svg"
                title={"Sorry,  you have no device report within this period"}
                descr={
                  "You can view report when you have activity on your Wicrypt devices within the specified time frame."
                }
              />
            ) : (
              // </div>
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  const { deviceStart, deviceEnd, opendatefrom, opendateto } = state.User;
  const { deviceSummary } = state.Report;
  const { gettingDeviceReport } = state.Loading;
  return {
    deviceStart,
    deviceEnd,
    deviceSummary,
    gettingDeviceReport,
    opendatefrom,
    opendateto
  };
};

export default connect(
  MapStateToProps,
  { getDeviceSummary, getFormDetails }
)(DeviceFilter);
