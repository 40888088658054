import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import ListOfTopics from "./listOfTopics";
import DeadLetterTopics from "./deadLetterTopics";

const Topics = () => {
  
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content" style={{width: "100%"}}>
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav pageTitle="Topics" />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid">
          <SubSidenav />
          <div className="row md-px-2">
                <div className="col-12 topic-wrapper">
                  <ListOfTopics />
                  <DeadLetterTopics />
                </div>
          </div>
        
        </div>
      </MainContent>
    </div>
  );
};

export default Topics;
