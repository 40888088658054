import _const from "./Types";
import config from "../reducers/config";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toggleModal, clearData } from "./GeneralAction";
import { getBusinessBalance } from "./ManagerAction";
import { numberWithCommas } from "../../Helper";

//Create voucher for a user
export const createVoucher = (
  voucherRecepientPhone,
  voucherAmt,
  voucherValidity,
  savedBusinessId,
  voucherGenerationFee,
  startDate,
  endDate,
  voucherStatus,
  voucherGenerator,
  days
) => {
  let data = {
    phoneNumber: voucherRecepientPhone,
    email: "",
    amount: Number(voucherAmt / 360),
    validityInHours: Number(days ? voucherValidity * 24 : voucherValidity),
    businessId: savedBusinessId,
  };

  return (dispatch) => {
    if (
      voucherRecepientPhone === "" ||
      voucherAmt === 0 ||
      voucherValidity === ""
    ) {
      dispatch({
        type: _const.CREATE_VOUCHER_ERROR,
        payload: "Please fill out all fields",
      });
    } else {
      dispatch(toggleModal(false));
      Swal.fire({
        text: `You are about to generate a ${"NGN" + voucherAmt} 
                voucher at a transaction charge of NGN  ${voucherGenerationFee === 0 ? "10.00" : voucherGenerationFee
          }.
                NGN  ${Number(voucherAmt) +
          (voucherGenerationFee === 0 ||
            voucherGenerationFee === undefined
            ? 10
            : voucherGenerationFee)} will be deducted from your business wallet`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          dispatch(toggleModal(true));
          dispatch({
            type: _const.CREATING_VOUCHER,
            payload: true,
          });
          dispatch({
            type: _const.CREATE_VOUCHER_ERROR,
            payload: "",
          });
          axios
            .post(config.apiUrl + "/v2/vouchers", data)
            .then((res) => {
              dispatch({
                type: _const.CREATING_VOUCHER,
                payload: false,
              });
              Swal.fire({
                text: "Voucher generated successfully",
                type: "success",
              });
              dispatch(clearData());
              dispatch(toggleModal(false));
              if (voucherGenerator === "agent") {
                dispatch(
                  getagentvouchers(
                    savedBusinessId,
                    startDate,
                    1,
                    50,
                    voucherStatus,
                    endDate,
                    ''
                  )
                );
              } else {
                dispatch(
                  getvouchers(
                    savedBusinessId,
                    startDate,
                    1,
                    50,
                    voucherStatus,
                    endDate,
                    ''
                  )
                );
              }
              dispatch(getBusinessBalance());
            })
            .catch((err) => {
              dispatch({
                type: _const.CREATING_VOUCHER,
                payload: false,
              });
              dispatch({
                type: _const.CREATE_VOUCHER_ERROR,
                payload: err.response
                  ? err.response.data.message
                  : "Something went wrong",
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }
  };
};

//Create bulk vouchers
export const generatebulkvouchers = (
  voucherAmt,
  voucherValidity,
  quantity,
  days
) => {
  let data = {
    amount: Number(voucherAmt / 360),
    voucherValidityInHours: Number(
      days ? voucherValidity * 24 : voucherValidity
    ),
    quantity: Number(quantity),
  };
  return (dispatch) => {
    if (voucherValidity === "" || voucherAmt === 0 || quantity === 0) {
      dispatch({
        type: _const.BULK_VOUCHER_ERROR,
        payload: "Please fill out all fields",
      });
    } else {
      dispatch(toggleModal(false));
      Swal.fire({
        text: `You are about to generate ${quantity} vouchers at ${"NGN " +
          numberWithCommas(voucherAmt)} each. 
                A total of ${numberWithCommas(quantity * voucherAmt)}
                will be deducted from your personal wallet`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          dispatch(toggleModal(true));
          dispatch({
            type: _const.CREATING_BULK_VOUCHERS,
            payload: true,
          });
          dispatch({
            type: _const.BULK_VOUCHER_ERROR,
            payload: "",
          });
          axios
            .post(config.apiUrl + "/v2/vouchers/bulk", data)
            .then((res) => {
              dispatch({
                type: _const.CREATING_BULK_VOUCHERS,
                payload: false,
              });
              Swal.fire({
                text: "Bulk vouchers generated successfully",
                type: "success",
              });
              dispatch(toggleModal(false));
              dispatch(getBusinessBalance());
              dispatch({
                type: _const.CLEAR_FORMS,
                payload: true,
              });
              dispatch(clearData());
              dispatch(getusergeneratedvouchers("", "", 1, 50, "", "", "", "", ""));
            })
            .catch((err) => {
              dispatch({
                type: _const.CREATING_BULK_VOUCHERS,
                payload: false,
              });
              dispatch({
                type: _const.BULK_VOUCHER_ERROR,
                payload: err.response
                  ? err.response.data.message
                  : "Something went wrong",
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }
  };
};

//Get all vouchers created by a business
export const getvouchers = (
  businessId,
  startDateTimestamp,
  page,
  pageSize,
  voucherStatus,
  endDateTimeStamp,
  voucherCode
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_VOUCHERS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/vouchers/business?voucherStatus=${voucherStatus}&page=${page}&pageSize=${pageSize}&businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimeStamp=${endDateTimeStamp}&voucherCode=${voucherCode}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_VOUCHERS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_VOUCHERS,
          payload: res.data.data.records,
          totalrecords: res.data.data.totalRecords,
          currentPage: res.data.data.currentPage,
          totalPages: res.data.data.totalPages,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_VOUCHERS,
          payload: false,
        });
      });
  };
};

//Gets all vouchers created by an agent
export const getagentvouchers = (
  businessId,
  startDateTimestamp,
  page,
  pageSize,
  voucherStatus,
  endDateTimeStamp
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_VOUCHERS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/vouchers/agent?voucherStatus=${voucherStatus}&page=${page}&pageSize=${pageSize}&businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimeStamp=${endDateTimeStamp}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_VOUCHERS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_VOUCHERS,
          payload: res.data.data.records,
          totalrecords: res.data.data.totalRecords,
          currentPage: res.data.data.currentPage,
          totalPages: res.data.data.totalPages,
        });
        dispatch({
          type: _const.CLEAR_FORMS,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_VOUCHERS,
          payload: false,
        });
      });
  };
};

//Gets all vouchers generated by a user
export const getusergeneratedvouchers = (
  phoneNumber,
  startDateTimestamp,
  page,
  pageSize,
  voucherStatus,
  endDateTimeStamp,
  batchCode,
  voucherCode
) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_USER_GENERATED_VOUCHERS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/vouchers/userGenerated?voucherCode=${voucherCode}&phoneNumber=${phoneNumber}&voucherStatus=${voucherStatus}&page=${page}&pageSize=${pageSize}&batchCode=${batchCode}&startDateTimestamp=${startDateTimestamp}&endDateTimeStamp=${endDateTimeStamp}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_USER_GENERATED_VOUCHERS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_USER_GENERATED_VOUCHERS,
          payload: res.data.data.records,
          totalrecords: res.data.data.totalRecords,
          currentPage: res.data.data.currentPage,
          totalPages: res.data.data.totalPages,
        });
        dispatch({
          type: _const.CLEAR_FORMS,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_USER_GENERATED_VOUCHERS,
          payload: false,
        });
      });
  };
};

//Get all an individual's voucher
export const individualVoucher = (phonenumber, voucherStatus) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_INDIVIDUAL_VOUCHERS,
      payload: true,
    });
    axios
      .get(
        config.apiUrl +
        `/v2/vouchers?phoneNumber=${phonenumber}&emailAddress=${phonenumber +
        "@wicrypt.com"}&voucherStatus=${voucherStatus}&pageSize=500000`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_INDIVIDUAL_VOUCHERS,
          payload: false,
        });
        dispatch({
          type: _const.ALL_INDIVIDUAL_VOUCHERS,
          payload: res.data.data.records,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_INDIVIDUAL_VOUCHERS,
          payload: false,
        });
      });
  };
};

//Get all a voucher balance
export const voucherBalance = (phonenumber, code) => {
  return (dispatch) => { 
      dispatch({
        type: _const.GETTING_VOUCHER_BALANCE,
        payload: true,
      });
      dispatch({
        type: _const.VOUCHER_BALANCE_ERR,
        payload: "",
      });
      axios
        .get(
          config.apiUrl +
          `/v2/vouchers/balance?PhoneNumber=${phonenumber}&Code=${code}`
        )
        .then((res) => {
          dispatch({
            type: _const.GETTING_VOUCHER_BALANCE,
            payload: false,
          });
          dispatch({
            type: _const.VOUCHER_BALANCE,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: _const.GETTING_VOUCHER_BALANCE,
            payload: false,
          });
          dispatch({
            type: _const.VOUCHER_BALANCE_ERR,
            payload: err.response.data.message,
          });
        });
    };
};
