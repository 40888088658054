import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lockupCyclesColumns } from "../../../utils/TableCols/RewardBoosterCols";
import { clearSearch, getLockupRequestsId } from "../../../Redux/actions";
import { getRewardBoosterRows } from "../../../utils/TableRows/rewardBoosterRow";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import SubSidenav from "../../Partials/SubSidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import Mainnav from "../../Partials/Mainnav";
import { MainContent } from "../../Partials/partials";
import Sidenav from "../../Partials/Sidenav";

const LockupCycleId = ({history}) => {
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const { lockupRequestsId } = useSelector((state) => state.RewardBooster);
  const { gettingLockupRequestsId} = useSelector((state) => state.Loading);
  const { page, pageSize } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  const cycleId = window.location.pathname.split("/").at(-1);

  let allRows = getRewardBoosterRows( lockupRequestsId && lockupRequestsId.records ? lockupRequestsId.records.slice().reverse() : [], 
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
  );

  const data = {
    columns: lockupCyclesColumns,
    rows: allRows,
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getLockupRequestsId( cycleId, page, pageSize ));
  }, [cycleId, page, pageSize]);

  useEffect(() => {
    dispatch(clearSearch());
    return () => dispatch(clearSearch());
  }, []);

  return (
    <div className="row">
    <Sidenav />
    <MainContent className="main-content">
      <div className="main-navbar sticky-top bg-white mb-5">
        <Mainnav 
          pageTitle={
            <button
              className="pointer mb-0"
              onClick={goBack}
              style={{background: 'none', border: 'none'}}
            >
              {" "}
              <i className="material-icons">arrow_back</i> Lockup Request Cycle
            </button>
          }
        />
      </div>
      <BreadCrumbs />
      <div className="main-content-container container-fluid px-4">
        <SubSidenav />
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <CustomDataTable
                  data={data}
                  tableId={"Reward Booster"}
                  pageCount={lockupRequestsId.totalPages}
                  currentPage={lockupRequestsId.currentPage}
                  title={"Lockup Requests For Cycle"}
                  loading={gettingLockupRequestsId}
                  noData={
                    <EmptyCard
                      img="/Assets/emptytransactions.svg"
                      title={"Sorry, you have no lockup request history"}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  </div>
  );
};

export default LockupCycleId;
