import React from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import CreateCommands from "./CreateCommands";
import ListCommands from "./ListCommands";
import DeleteCommands from "./DeleteCommand";
import IssueCommandOptions from "./IssueCommandOptions";
import SingleDeviceCommand from "./SingleDeviceCommand";

const RemoteCommands = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Remote Commands"/>
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          <CreateCommands />
          <DeleteCommands />
          <IssueCommandOptions />
          <SingleDeviceCommand />
          <ListCommands />
        </div>
      </MainContent>
    </div>
  );
};

export default RemoteCommands;
