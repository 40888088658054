import React, { useEffect, useState } from "react";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { getFraudList, getSearchDetails, removeFromList } from "../../../Redux/actions";
import { getFraudListRow } from "../../../utils/TableRows/fraudListRows";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import {
  fraudColumns, fraudListOptions
} from "./options";

const FraudList = () => {

  const { gettingFraudList, removingFromList } = useSelector((state) => state.Loading)
  const { fraudList, fraudTotalPages, fraudCurrentPage } = useSelector((state) => state.Fraud);
  const [fraudListId, setFrausListId] = useState("");
  const {
    operation,
    Page,
    PageSize,
    search,
    Status,
  } = useSelector((state) => state.Search);

  const dispatch = useDispatch();

  let allRows = getFraudListRow(fraudList, removeFromList, removingFromList, fraudListId, setFrausListId, dispatch);
  const data = {
    columns: fraudColumns,
    rows: allRows,
  };

  const fetchFraudList = () => {
    dispatch(getFraudList(Page, PageSize, search, Status, operation))
  }

  useEffect(() => {
    fetchFraudList();
  }, [Page, PageSize, operation, Status]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchFraudList();
    }
  },[search])

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"fraud-list-report"}
              pageCount={fraudTotalPages}
              currentPage={fraudCurrentPage}
              header={
                <TableHeader
                  subHeader={fraudListOptions}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  // searchvalue={search}
                  onSearchClick={fetchFraudList}
                />
              }
              loading={gettingFraudList}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Fraud List Report"}
                  descr={"There is no one on this list"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FraudList;
