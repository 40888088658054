import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'
import { MoonLoader } from "react-spinners";
import { handleSubNav } from "../../Redux/actions";
import { getLatestDevices } from "../../Redux/actions/AdminAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import {
  Label,
  LeftContent,
  RightContent,
  Request,
  LinkText,
} from "./dahboardstyles";

const LatestDevices = () => {
  const { latestDevices, gettingLatestDevices } = useSelector(
    (state) => state.Admin
  );
  const { role } = useSelector((state) => state.User);
  const { Page, Name, Status, Type, Model, OrderBy } = useSelector(
    (state) => state.Search
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const queries = { Page, Name, Status, Type, Model, OrderBy, PageSize: 5 };
    getLatestDevices(dispatch, queries);
  }, []);


  return (
    <div className="col-lg-6 p-1">
      <div className="card card-small" style={{ height: "500px" }}>
        <div className="card-header border-bottom">
          <Label>Latest Devices</Label>
        </div>
        <div className="card-body pt-0">
          <div className="flex flex-col items-center justify-between">
            {gettingLatestDevices ? (
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader />
              </div>
            ) : (
              <>
                {latestDevices.length ? (
                  latestDevices.map((device, index) => (
                    <Request key={device + index} even={(index + 1) % 2 === 0}>
                      <LeftContent>
                        <div>{device.deviceName}</div>
                      </LeftContent>
                      <RightContent>
                        {device.isActive ? (
                          <div className="online">Online</div>
                        ) : (
                          <div className="offline">Offline</div>
                        )}
                      </RightContent>
                      <RightContent>
                        <Link to={`/devices/${device.deviceId}`}
                          className="t-center"

                        >
                          View More <img src="/Assets/arrowRight.svg"
                            className=" t-center"
                            height="20px"
                            alt="view more"

                          />
                        </Link>
                      </RightContent>
                    </Request>
                  ))
                ) : (
                  <EmptyCard
                    img="/Assets/emptytransactions.svg"
                    title={"No latest Devices"}
                  // descr={"No User available at the moment"}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="card-footer flex items-center justify-center"
          style={{ color: "#CFA809" }}
        >
          {(role.includes("SuperAdmin") || role.includes("Admin")) && (
            <LinkText to="/devices" onClick={() => dispatch(handleSubNav(2))}>
              See All
            </LinkText>
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestDevices;
