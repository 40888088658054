import styled from "styled-components";
import { theme } from "../../theme";
import { Link } from "react-router-dom";

export const SubTitle = styled.p`
  color: ${theme.black};
  font-weight: ${theme.textBold};
`;

export const SectionBody = styled.div``;
export const SectionValue = styled.p`
  margin-top: 1rem;
  font-size: ${theme.textSm};
  margin-bottom: 0px;
  font-weight: ${theme.textBold};
`;
export const SectionTitle = styled.p`
  margin-top: 1rem;
  font-size: ${theme.textSm};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 20px;
  border-bottom: ${(props) => (props.last ? "0px" : "1px solid #E8E8E8")};
`;
export const GraphLabel = styled.p`
    color: ${theme.black};
    font-weight: ${theme.textBold}
    font-size: ${theme.textSm};
    margin-bottom: 0px;
`;

export const Label = styled.p`
  color: ${theme.black};
  font-weight: 600;
  font-size: clamp(16px, 17px, 18px);
  margin-bottom: 0px;
`;

export const Request = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 27px;
  width: 100%;
  background-color: ${(prop) => (prop.even ? "#c4c4c41a" : null)};
  padding: 10px;
`;

export const LinkText = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #cfa809;
`;

export const LeftContent = styled.div`
  display: flex;
  flex: 0.7;
`;
export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Center = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: center;

  img {
    width: 30px;
  }
`;
