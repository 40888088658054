import React, { useEffect } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeActiveTab, handleSubNav, changeTransactionType } from "../../../Redux/actions";
import config from "../../../Redux/reducers/config";

const UserTransactionReport = () => {
  const [tranDetails, setTranDetails] = useState({});
  const currentTab = localStorage.getItem("activeInnerTab");
  const dispatch = useDispatch();

  useEffect(() => {
    const singleTransactionDetails = JSON.parse(
      localStorage.getItem("singleTransactionDetails")
    );
    setTranDetails(singleTransactionDetails);
  }, []);


  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <Link
                to={`/transcOverview`}
                className="pointer mb-0"
                onClick={() => {
                  dispatch(changeActiveTab(Number(currentTab)));
                  dispatch(
                    changeTransactionType(
                      parseInt(currentTab) === 1
                        ? "bank"
                        : parseInt(currentTab) === 2
                          ? "crypto"
                          : parseInt(currentTab) === 3
                            ? "p2p"
                            : parseInt(currentTab) === 2
                              ? "transfer"
                              : "mining-rewards"
                    )
                  );
                  dispatch(handleSubNav(2));
                }}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Transaction Report
              </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          <div className="col-lg-12 ">
            <div className="card">
              <div className="card-body p-6 ">
                <div className="row mb-2">
                  {tranDetails.name && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Name</StakeStatData>
                      <StakeStatValue>{tranDetails.name}</StakeStatValue>
                    </div>
                  )}
                  {tranDetails.email && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Email</StakeStatData>
                      <StakeStatValue>{tranDetails.email}</StakeStatValue>
                    </div>
                  )}
                  {/* {tranDetails.id && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Transation ID</StakeStatData>
                      <StakeStatValue>{tranDetails.id}</StakeStatValue>
                    </div>
                  )} */}
                  {tranDetails.walletAddress && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet Address</StakeStatData>
                      <StakeStatValue>
                        {tranDetails.walletAddress}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {tranDetails.currency && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Currency</StakeStatData>
                      <StakeStatValue>{tranDetails.currency}</StakeStatValue>
                    </div>
                  )}
                  {tranDetails.bankName && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Bank Name</StakeStatData>
                      <StakeStatValue>{tranDetails.bankName}</StakeStatValue>
                    </div>
                  )}
                  {tranDetails.accountNumber && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Account Number</StakeStatData>
                      <StakeStatValue>
                        {tranDetails.accountNumber}
                      </StakeStatValue>
                    </div>
                  )}
                  {tranDetails.network && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Network</StakeStatData>
                      <StakeStatValue>{tranDetails.network}</StakeStatValue>
                    </div>
                  )}
                  {tranDetails.amount && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Amount</StakeStatData>
                      <StakeStatValue>
                        {tranDetails.amount} {tranDetails.currency}
                      </StakeStatValue>
                    </div>
                  )}
                  {tranDetails.referenceCode && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Reference</StakeStatData>
                      <StakeStatValue>
                        {tranDetails.referenceCode}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                {tranDetails.liquidityProvider && (
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    {tranDetails.transactionType && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Type</StakeStatData>
                        <StakeStatValue>
                          {tranDetails.transactionType}
                        </StakeStatValue>
                      </div>
                    )}
                    {/* {tranDetails.buyCurrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy Currency</StakeStatData>
                        <StakeStatValue>
                          {tranDetails.buyCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {tranDetails.sellCurrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Currency</StakeStatData>
                        <StakeStatValue>
                          {tranDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )} */}
                    {tranDetails.buyAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy amount</StakeStatData>
                        <StakeStatValue>
                          {tranDetails.buyAmount} {tranDetails.buyCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                      {tranDetails.sellAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Amount</StakeStatData>
                        <StakeStatValue>
                          {tranDetails.sellAmount} {tranDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {tranDetails.liquidityProvider && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Liquidity Provider</StakeStatData>
                        <StakeStatValue>
                          Name: {tranDetails.liquidityProvider}
                        </StakeStatValue>
                        <StakeStatValue>
                          Email: {tranDetails.liquidityProviderEmail}
                        </StakeStatValue>
                        <StakeStatValue>
                          Phone Number: {tranDetails.liquidityProviderPhoneNumber}
                        </StakeStatValue>
                      </div>
                    )}
                    {tranDetails.providerPaymentData.length > 0 && (
                      <div className="col-lg-3 col-md-6">
                      <StakeStatData>{"Provider's Payment Details"}</StakeStatData>
                      {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].accountName ? <>
                        <StakeStatValue>
                          Account Name: {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].accountName}
                        </StakeStatValue>
                        <StakeStatValue>
                          Account Number: {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].accountNumber}
                        </StakeStatValue>
                        <StakeStatValue>
                          Bank: {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].bankName}
                        </StakeStatValue>
                      </> : <>
                        <StakeStatValue>
                          Wallet Address: 
                          <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/address/${tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].walletAddress}`}>  {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].walletAddress} </a>
                         
                        </StakeStatValue>
                        <StakeStatValue>
                          Network: {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].network}
                        </StakeStatValue>
                        <StakeStatValue>
                          Currency: {tranDetails.providerPaymentData[tranDetails.providerPaymentData.length - 1].currency}
                        </StakeStatValue>
                      </>}
                    </div>
                    )}
                    {tranDetails.customerEmail && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Customer Details</StakeStatData>
                        <StakeStatValue>
                          Name: {tranDetails.customerName}

                        </StakeStatValue>
                        <StakeStatValue>

                          Email: {tranDetails.customerEmail}

                        </StakeStatValue>
                        <StakeStatValue>
                          Phone Number: {tranDetails.customerPhoneNumber}
                        </StakeStatValue>
                      </div>
                    )}
                    {tranDetails.customerPaymentData && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>{ "Customer's Payment Details"}</StakeStatData>
                        {tranDetails.customerPaymentData.accountName ? <>
                          <StakeStatValue>
                            Account Name: {tranDetails.customerPaymentData.accountName}
                          </StakeStatValue>
                          <StakeStatValue>
                            Account Number: {tranDetails.customerPaymentData.accountNumber}
                          </StakeStatValue>
                          <StakeStatValue>
                            Bank: {tranDetails.customerPaymentData.bankName}
                          </StakeStatValue>
                        </> : <>
                          <StakeStatValue>
                            Wallet Address:
                            <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/address/${tranDetails.customerPaymentData.walletAddress}`}>  {tranDetails.customerPaymentData.walletAddress} </a>
                          </StakeStatValue>
                          <StakeStatValue>
                            Network: {tranDetails.customerPaymentData.network}
                          </StakeStatValue>
                          <StakeStatValue>
                            Currency: {tranDetails.customerPaymentData.currency}
                          </StakeStatValue>
                        </>}
                      </div>
                    )}
                   
                  
                  </div>
                )}
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {tranDetails.destination && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Destination</StakeStatData>
                      <StakeStatValue>{tranDetails.destination}</StakeStatValue>
                    </div>
                  )}
                  {/* {tranDetails.wntPrice && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>WNT Price</StakeStatData>
                      <StakeStatValue>$ {tranDetails.wntPrice}</StakeStatValue>
                    </div>
                  )} */}
                  {tranDetails.wallet && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet</StakeStatData>
                      <StakeStatValue>{tranDetails.wallet}</StakeStatValue>
                    </div>
                  )}
                  {tranDetails.createdAt && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      <StakeStatValue>
                        {moment(tranDetails.createdAt).format("DD-MM-YYYY")} |{" "}
                        {moment(tranDetails.createdAt).format("LT")}
                      </StakeStatValue>
                    </div>
                  )}
                  {tranDetails.status && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      <StakeStatValue className={tranDetails.status.toUpperCase()}>{tranDetails.status}</StakeStatValue>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default UserTransactionReport;
