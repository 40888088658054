import _const from "../actions/Types";

const initialState = {
  fraudList: [],
  fraudReport: [],
  fraudTotalPages: 1,
  fraudCurrentPage: 1
};

const FraudReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.FRAUD_LIST:
      return {
        ...state, fraudList: action.payload.records, fraudTotalPages: action.payload.totalPages,
        fraudCurrentPage: action.payload.currentPage
      }

    case _const.FRAUD_REPOPRT:
      return { ...state, fraudReport: action.payload }

    default:
      return state;
  }
};

export default FraudReducer;
