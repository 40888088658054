import React from "react";
import moment from "moment";

// address format
export function shortenAddress(address) {
  if (address) return `${address.slice(0, 13)}...`;
}

export async function copyHandle(value) {
  try {
    await navigator.clipboard.writeText(value);
    // toast.success("Copied");
  } catch (error) {
    // toast.error("Failed");
  }
}
//Format Number
const numberWithCommas = (n, c, d, t) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  let s = n < 0 ? "-" : "";
  let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};
function numberWithCommaswithoutdecimals(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

//Round off Numbers
function precise_round(num, dec) {
  if (typeof num !== "number" || typeof dec !== "number") return false;

  var num_sign = num >= 0 ? 1 : -1;

  return (
    Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)
  ).toFixed(dec);
}

//Format rate
const formatRate = (newRate, currencyValue) => {
  let WcGb = newRate / currencyValue;
  return WcGb / (1024 * 1024);
};

const voucherHrs = () => {
  var arr = [];
  for (let i = 1; i < 25; i++) {
    arr.push(
      <option key={i} value={i}>
        {i + " hrs"}
      </option>
    );
  }
  return arr;
};

const voucherDays = () => {
  var arr = [];
  for (let i = 1; i < 32; i++) {
    arr.push(<option value={i}>{i + " day(s)"}</option>);
  }
  arr.push(<option value={1460}> Unlimited </option>);
  return arr;
};

const customPagination = (currentpage, totalpages) => {
  var pagefrom = 1;
  var paginate = [];
  if (pagefrom !== currentpage) {
    paginate.push(<p onClick="">&laquo;</p>);
  }
  for (let i = pagefrom; i < totalpages + 1; i++) {
    if (i === currentpage) {
      paginate.push(
        <p className="active" value={i}>
          {i}
        </p>
      );
    } else {
      paginate.push(
        <p onClick="" value={i}>
          {i}
        </p>
      );
    }
  }
  if (totalpages > currentpage) {
    paginate.push(<p onClick="">&raquo;</p>);
  }
  return paginate;
};

function pagination(currentPage, maxPage, maxButtonsToDisplay) {
  var upperLimit = Math.min(
    maxPage,
    maxButtonsToDisplay +
      Math.max(
        0,
        currentPage - (maxButtonsToDisplay + (maxButtonsToDisplay % 2)) / 2
      )
  );
  var lowerLimit = upperLimit - (maxButtonsToDisplay - 1);

  var pages = [];

  if (lowerLimit > 1) pages.push(-1);
  for (var i = lowerLimit; i <= upperLimit; i++) {
    pages.push(i);
  }
  if (upperLimit < maxPage) pages.push(-1);

  return pages;
}

export const validateamount = (value) => {
  return /^[1-9]\d*$/.test(value);
};

export const validateemail = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const time_convert = (num) => {
  var hours = Math.floor(num / 60);
  var minutes = Math.floor(num % 60);
  return hours + " Hrs " + minutes + "Mins";
};

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function niceBytes(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1000 && ++l) {
    n = n / 1000;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 1) + " " + units[l];
}

function formatBillingrate(num, CurrencyValue, currencyCode) {
  return (
    numberWithCommas(num * 1024 * 1024 * CurrencyValue) + currencyCode + "  /GB"
  );
}

function humanizeDuration(input, units) {
  // units is a string with possible values of y, M, w, d, h, m, s, ms
  var duration = moment()
      .startOf("day")
      .add(units, input),
    format = "";

  if (duration.hour() > 0) {
    format += "H [Hrs] ";
  }

  if (duration.minute() > 0) {
    format += "m [Min] ";
  }

  format += " s [Secs]";

  return duration.format(format);
}

const formathash = (hash) => {
  if (hash) {
    let firsthalf = hash.slice(0, 5);
    let secondhalf = hash.slice(-4);
    let formattedhash = firsthalf + "..." + secondhalf;
    return formattedhash;
  }
};

 // function to format Topic name
 function formatTopicName(inputString) {
  const words = inputString.split('-');
  const topicIndex = words.indexOf('topic');
  const truncatedWords = topicIndex !== -1 ? words.slice(0, topicIndex) : words;
  const capitalizedWords = truncatedWords.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const result = capitalizedWords.join(' ');

  return result;
}

/**
 * Formats a camelCase or PascalCase string by inserting a space between each lowercase and uppercase letter.
 * For example, "camelCaseString" becomes "camel Case String".
 *
 * @param {string} inputString - The input string to be formatted.
 * @returns {string} The formatted string.
 */

function formatString(inputString) {
  return inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export {
  numberWithCommas,
  numberWithCommaswithoutdecimals,
  precise_round,
  formatRate,
  voucherHrs,
  voucherDays,
  customPagination,
  pagination,
  niceBytes,
  humanizeDuration,
  formatBillingrate,
  formathash,
  formatTopicName,
  formatString
};
