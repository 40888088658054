import React, { Component } from "react";
import {
  getTrackingInfo,
  getBusinessDevices,
  toggleModal,
  clearData,
  setDeviceTracker,
  getFormDetails,
  gettingDevices,
} from "../../Redux/actions";
import { connect } from "react-redux";
import Loader from "../Reusables/loaders/MoonLoader";
import DatePicker from "react-datepicker";
import moment from "moment";
import { precise_round, pagination } from "../../Helper";
import { withRouter } from "react-router-dom";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";

class Tracker extends Component {
  componentWillMount() {
    // this.props.getBusinessDevices(id);
    this.props.gettingDevices('');
  }
  componentWillReceiveProps(nextProps) {
    const { deviceToTrack, selectedAffiliatedBusiness } = nextProps;
    if (
      deviceToTrack !== this.props.deviceToTrack ||
      selectedAffiliatedBusiness !== this.props.selectedAffiliatedBusiness
    ) {
      this.props.clearData();
      this.props.getTrackingInfo(
        selectedAffiliatedBusiness,
        deviceToTrack,
        1,
        50
      );
    }
  }

  customPagination() {
    const {
      selectedAffiliatedBusiness,
      deviceToTrack,
      deviceTrackerCurrentPage,
      deviceTrackerTotalPages,
    } = this.props;
    var paginate = [];
    var pageButtons = pagination(deviceTrackerCurrentPage, deviceTrackerTotalPages, 5);
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getTrackingInfo(
              selectedAffiliatedBusiness,
              deviceToTrack,
              deviceTrackerCurrentPage - 1,
              50
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === deviceTrackerCurrentPage) {
        paginate.push(
          <p className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              this.props.getTrackingInfo(
                selectedAffiliatedBusiness,
                deviceToTrack,
                pageButtons[i],
                50
              )
            }
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            this.props.getTrackingInfo(
              selectedAffiliatedBusiness,
              deviceToTrack,
              deviceTrackerCurrentPage + 1,
              50
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  validityInDays() {
    var arr = [];
    for (let i = 1; i < 32; i++) {
      arr.push(<option value={i}>{i + " days"}</option>);
    }
    return arr;
  }

  render() {
    const {
      settingTracker,
      createTrackingDevice,
      dataAmountInBytes,
      trackerError,
      validityinDays,
      subscriptionDate,
      gettingBusinessDevices,
      userId,
      gettingTrackingInfo,
      deviceTrackerInfo,
      managerDevices,
      affiliatedBusiness,
    } = this.props;

    let allRows = [];
    deviceTrackerInfo.map((trackingInfo) => {
      return allRows.push({
        subscriptiondate: moment
          .parseZone(trackingInfo.subscriptionDate)
          .format("YYYY-MM-DD"),
        dataAmountInBytes:
          precise_round(trackingInfo.dataAmountInBytes / 1073741824, 2) + "GB",
        dataUsedInBytes:
          precise_round(trackingInfo.dataUsedInBytes / 1073741824, 2) + "GB",
        dataRemainingInBytes:
          precise_round(
            (trackingInfo.dataAmountInBytes - trackingInfo.dataUsedInBytes) /
            1073741824,
            2
          ) < 0
            ? 0 + "GB"
            : precise_round(
              (trackingInfo.dataAmountInBytes -
                trackingInfo.dataUsedInBytes) /
              1073741824,
              2
            ) + "GB",
        subscriptiontionValidity: trackingInfo.validityInDays,
        expiryDate: moment
          .parseZone(trackingInfo.expiryDate)
          .format("YYYY-MM-DD"),
      });
    });

    var date = subscriptionDate;
    var timestamp = date.getTime();

    const data = {
      columns: [
        {
          label: "Subscription Date",
          field: "subscriptiondate",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data Value",
          field: "dataAmountInBytes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data Used",
          field: "dataUsedInBytes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data Remaining",
          field: "dataRemainingInBytes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Subscription Validity",
          field: "subscriptiontionValidity",
          sort: "asc",
          width: 100,
        },
        {
          label: "Expiry Date",
          field: "expiryDate",
          sort: "asc",
          width: 100,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div>
              <p className="trackers"> Device Data Tracker </p>
            </div>
            <div className="mt-3 d-flex row">
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["deviceToTrack"],
                    value: e.target.value,
                  })
                }
              >
                <option value="">Select Device</option>
                {managerDevices.length > 0
                  ? managerDevices.map((each) => {
                    return <option value={each.id}>{each.name}</option>;
                  })
                  : ""}
              </select>
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["selectedAffiliatedBusiness"],
                    value: e.target.value,
                  })
                }
              >
                <option>Select Business</option>
                {affiliatedBusiness.map((business) => {
                  return (
                    <option value={business.business.id}>
                      {business.business.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <Modal heading={"TRACK SUBSCRIPTION DATA"}>
            <div className="col-lg-12">
              <label> Data amount in GB</label>
              <div className="mb-3">
                <input
                  type="number"
                  value={dataAmountInBytes}
                  className="form-control mr-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["dataAmountInBytes"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Subscription Date</label>

              <div className="form-control loginCred mb-3">
                <DatePicker
                  className="dateRange b-0 text-left"
                  selected={subscriptionDate}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["subscriptionDate"],
                      value: date,
                    })
                  }
                />
              </div>

              <div className="input-group mb-2">
                <select
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["createTrackingDevice"],
                      value: e.target.value,
                    })
                  }
                >
                  <option>Select Device</option>
                  {managerDevices.length > 0
                    ? managerDevices.map((each) => {
                      return <option value={each.id}>{each.name}</option>;
                    })
                    : ""}
                </select>
              </div>

              <label> Select validity period </label>
              <div className="input-group mb-2">
                <select
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["validityinDays"],
                      value: e.target.value,
                    })
                  }
                >
                  {this.validityInDays()}
                </select>
              </div>
              <p className="text-red t-12 t-center"> {trackerError}</p>
              <Button
                theme="btn-primary ml-3 mt-2 float wicryptColor"
                buttonAction={() =>
                  this.props.setDeviceTracker(
                    createTrackingDevice,
                    timestamp,
                    validityinDays,
                    dataAmountInBytes,
                    userId
                  )
                }
              >
                {settingTracker ? <Loader /> : "Track Data"}
              </Button>
            </div>
          </Modal>
          {(gettingTrackingInfo === true || gettingBusinessDevices === true) &
            (deviceTrackerInfo.length === 0) ? (
            <div className="volucherNote mt-5 t-center ">
              <CustomLoader />{" "}
            </div>
          ) : (
            <CustomDataTable data={data} pagination={this.customPagination()} />
          )}
        </div>
      </div>
    );
  }
}
const MapStateToProps = (state, props) => {
  const {
    businessDevices,
    deviceTrackerInfo,
    managerDevices,
    trackerError,
    deviceTrackerCurrentPage,
    deviceTrackerTotalPages,
    deviceTrackerTotalRecords,
    affiliatedBusiness,
  } = state.Manager;
  const {
    gettingTrackingInfo,
    gettingBusinessDevices,
    settingTracker,
  } = state.Loading;
  const {
    deviceToTrack,
    userId,
    dataAmountInBytes,
    validityinDays,
    subscriptionDate,
    businessProfile,
    funct,
    createTrackingDevice,
    selectedAffiliatedBusiness,
  } = state.User;

  return {
    businessDevices,
    selectedAffiliatedBusiness,
    gettingTrackingInfo,
    deviceTrackerCurrentPage,
    deviceTrackerTotalPages,
    deviceToTrack,
    createTrackingDevice,
    deviceTrackerInfo,
    managerDevices,
    gettingBusinessDevices,
    deviceTrackerTotalRecords,
    userId,
    trackerError,
    funct,
    settingTracker,
    dataAmountInBytes,
    validityinDays,
    subscriptionDate,
    businessProfile,
    affiliatedBusiness,
  };
};
export default withRouter(
  connect(MapStateToProps, {
    getTrackingInfo,
    clearData,
    setDeviceTracker,
    toggleModal,
    getBusinessDevices,
    gettingDevices,
    getFormDetails,
  })(Tracker)
);
