import _const from '../actions/Types';

const initialState = {
    alladverts: [],
    advertsCurrentPage: 1,
    advertsTotalPages: 1,
    advertsTotalRecords: 1,
    errorcreatingadvert: "",
    id: "",
    advert: {},
    advertError: "",
}

const AdvertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case _const.ALL_ADVERTS:
            return {
                ...state,
                alladverts: action.payload.data,
                advertsCurrentPage: action.payload.meta.page,
                advertsTotalPages: action.payload.meta.numberOfPages,
                advertsTotalRecords: action.payload.meta.total
            }

        case _const.ERROR_CREATING_ADVERT:
            return { ...state, errorcreatingadvert: action.payload }

        case _const.SELECTED_ID:
            return { ...state, id: action.payload }

        case _const.GET_ADVERT_BY_ID:
            return { ...state, advert: action.payload }

        case _const.ERROR_APPROVING_ADVERT:
            return {...state, advertError: action.payload };
            
        case _const.CLEAR_REPLAY_ERROR:
            return {...state, advertError: "" };

        default:
            return state
    }
}

export default AdvertsReducer