import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  businessSessions,
  getFormDetails,
  // getBusinessDevices,
  // gettingDevices,
  toggleModal,
} from "../../Redux/actions";
import { numberWithCommas, pagination } from "../../Helper";
import moment from "moment";
import DualDatePicker from "../Reusables/cards/DualDatePicker";
import SearchBusiness from "../Reusables/cards/SearchBusiness";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { allBusinessesForOperations } from "./../../Redux/actions/MerchantAction";
import { useEffect } from "react";

const Sessions = () => {
  const { loadingSessions } = useSelector((state) => state.Loading);
  const {
    bizsessionSummary,
    businessSessionsCurrentPage,
    businessSessionsTotalPages,
  } = useSelector((state) => state.Report);
  const {
    allsessionDateStart,
    allsessionDateEnd,
    isActive,
    sessionDeviceId,
    selectedAffiliatedBusiness,
    role,
  } = useSelector((state) => state.User);
  const { allBusiness } = useSelector((state) => state.Manager);
  const dispatch = useDispatch();

  useEffect(() => {
    businessSessions(
      selectedAffiliatedBusiness,
      isActive,
      allsessionDateStart,
      allsessionDateEnd,
      sessionDeviceId,
      1,
      50,
      role,
      dispatch
    );
    allBusinessesForOperations(1, 1000, "", dispatch);
  }, [
    isActive,
    allsessionDateStart,
    allsessionDateEnd,
    sessionDeviceId,
    selectedAffiliatedBusiness,
    role,
  ]);
  const customPagination = () => {
    var paginate = [];
    var pageButtons = pagination(
      businessSessionsCurrentPage,
      businessSessionsTotalPages,
      5
    );
    if (pageButtons[0] === -1) {
      paginate.push(
        <p
          onClick={() =>
            businessSessions(
              selectedAffiliatedBusiness,
              isActive,
              allsessionDateStart,
              allsessionDateEnd,
              sessionDeviceId,
              businessSessionsCurrentPage - 1,
              50,
              role,
              dispatch
            )
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = 0; i < pageButtons.length; i++) {
      if (pageButtons[i] < 1) continue;
      if (pageButtons[i] === businessSessionsCurrentPage) {
        paginate.push(
          <p key={pageButtons[i]} className="active" value={pageButtons[i]}>
            {pageButtons[i]}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() =>
              businessSessions(
                selectedAffiliatedBusiness,
                isActive,
                allsessionDateStart,
                allsessionDateEnd,
                sessionDeviceId,
                pageButtons[i],
                50,
                role,
                dispatch
              )
            }
            className="pointer"
            key={pageButtons[i]}
            value={pageButtons[i]}
          >
            {pageButtons[i]}
          </p>
        );
      }
    }
    if (pageButtons[pageButtons.length - 1] === -1) {
      paginate.push(
        <p
          onClick={() =>
            businessSessions(
              selectedAffiliatedBusiness,
              isActive,
              allsessionDateStart,
              allsessionDateEnd,
              sessionDeviceId,
              businessSessionsCurrentPage + 1,
              50,
              role,
              dispatch
            )
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  };

  let allRows = [];
  bizsessionSummary.map((session, i) => {
    return allRows.push({
      host: session.host.userName,
      name: session.client.userName,
      device: session.sessionDevice.deviceName,
      rate:
        numberWithCommas(session.sessionDevice.rate * 1048576 * 360) +
        " NGN/GB ",
      createdAt: moment
        .parseZone(session.sessionCreationTmeStamp)
        .format("MMMM Do YYYY, h:mm a"),
      updatedAt: moment
        .parseZone(session.sessionLastUpdateTimeStamp)
        .format("MMMM Do YYYY, h:mm a"),
      view: (
        <i
          className="material-icons t-17 text-orange pointer"
          onClick={() => dispatch(toggleModal(true, session.sessionId))}
        >
          chevron_right
        </i>
      ),
    });
  });

  const data = {
    columns: [
      {
        label: "Host",
        field: "host",
        sort: "asc",
        width: 100,
      },
      {
        label: "Client",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Device",
        field: "device",
        sort: "asc",
        width: 100,
      },
      {
        label: "Rate",
        field: "rate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Created",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Updated",
        field: "updatedAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "",
        field: "view",
        sort: "asc",
        width: 100,
      },
    ],

    rows: allRows,
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              loading={loadingSessions}
              data={data}
              pagination={customPagination()}
              customleft={
                <DualDatePicker
                  dateTo={allsessionDateEnd}
                  dateFrom={allsessionDateStart}
                  dateFromLabel="allsessionDateStart"
                  dateToLabel="allsessionDateEnd"
                />
              }
              customright={
                <div className="d-flex">
                  <SearchBusiness allBusiness={allBusiness} />
                  <select
                    className="form-control search"
                    style={{ width: "auto" }}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["isActive"],
                          value: e.target.value,
                        })
                      )
                    }
                  >
                    <option value={"True"}>Active Sessions</option>
                    <option value={"False"}>Inactive Sessions</option>
                  </select>
                </div>
              }
            />
          </div>
        </div>
      </div>
      {!loadingSessions & (bizsessionSummary.length === 0) ? (
        <EmptyCard
          img="/Assets/emptytransactions.svg"
          title={"Sorry,  you have no sessions report for the selected specs"}
          descr={
            "You can view sesssions when you have valid data for the selected specs."
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Sessions;
