import React from "react";
import moment from "moment";
import CustomModal from "../../Reusables/modals/CustomModal";
import { copyHandle, shortenAddress, numberWithCommaswithoutdecimals } from "../../../Helper";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";

export const OrderConfirmationDetail = ({ orderDetails, id }) => {
    return (
        <CustomModal
            id={id}
            close={true}
            heading={`Webhook Detail`}
            content={
                <div className="col-lg-12">
                    <div className="card w3-animate-bottom">
                        <div className="card-body p-6 ">
                            <div className="row mb-2">
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Date Processed</StakeStatData>
                                    <StakeStatValue>
                                        {moment(orderDetails.processedOn).format("DD-MM-YYYY") + " | " + moment(orderDetails.processedOn).format("LT")}
                                    </StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Customer Email</StakeStatData>
                                    <StakeStatValue>
                                        {orderDetails.webhookData.customerEmailAddress || ""}
                                    </StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Amount Paid by Customer</StakeStatData>
                                    <StakeStatValue>
                                        {numberWithCommaswithoutdecimals(orderDetails.webhookData.orderDetails.amountPaidByCustomer || 0)}
                                    </StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Amount Paid to Customer</StakeStatData>
                                    <StakeStatValue>
                                        {numberWithCommaswithoutdecimals(orderDetails.webhookData.orderDetails.amountPaidToCustomer|| 0)}
                                    </StakeStatValue>
                                </div>
                            </div>
                            <div
                                className="row mt-2 pt-2"
                                style={{ borderTop: "1px solid #E8E8E8" }}
                            >
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Order Type</StakeStatData>
                                    <StakeStatValue>
                                        <div className={` ${orderDetails.webhookData.orderDetails.orderType === 'Sell' ? 'text-red' : orderDetails.webhookData.orderDetails.orderType === 'Buy' ? 'text-green' : ''}`}>
                                            {orderDetails.webhookData.orderDetails.orderType}
                                        </div>
                                    </StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Order Reference</StakeStatData>
                                    <StakeStatValue>{orderDetails.webhookData.orderDetails.orderReference}</StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Dispute Status</StakeStatData>
                                    <StakeStatValue>{orderDetails.webhookData.orderDetails.disputeStatus}</StakeStatValue>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <StakeStatData>Order Status</StakeStatData>
                                    <StakeStatValue className={orderDetails.webhookData.orderDetails.orderStatus.toUpperCase()}>{orderDetails.webhookData.orderDetails.orderStatus}</StakeStatValue>
                                </div>
                            </div>
                        <div
                            className="row mt-2 pt-2"
                            style={{ borderTop: "1px solid #E8E8E8" }}
                        >
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Liquidity Provider</StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.liquidityProviderName}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Paid on</StakeStatData>
                            <StakeStatValue>
                                {moment(orderDetails.webhookData.paymentDate).format("DD-MM-YYYY") + " | " + moment(orderDetails.webhookData.paymentDate).format("LT")}
                            </StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Payment Method </StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.paymentMethod}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Paid by</StakeStatData>
                            <StakeStatValue>
                                {orderDetails.webhookData.paidBy}
                            </StakeStatValue>
                            </div>
                        </div>
                        <div
                            className="row mt-2 pt-2"
                            style={{ borderTop: "1px solid #E8E8E8" }}
                        >
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Recipient's account name</StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.recepientAccountDetails.accountName}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Recipient's account number</StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.recepientAccountDetails.accountNumber}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Bank </StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.recepientAccountDetails.bankName}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Currency</StakeStatData>
                            <StakeStatValue>
                                <StakeStatValue>{orderDetails.webhookData.recepientAccountDetails.currency}</StakeStatValue>
                            </StakeStatValue>
                            </div>
                        </div>
                        <div
                            className="row mt-2 pt-2"
                            style={{ borderTop: "1px solid #E8E8E8" }}
                        >
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Network</StakeStatData>
                            <StakeStatValue>{orderDetails.webhookData.recepientAccountDetails.network}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Wallet Address</StakeStatData>
                            <StakeStatValue>{shortenAddress(orderDetails.webhookData.recepientAccountDetails.walletAddress || "")}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Status </StakeStatData>
                            <StakeStatValue className={orderDetails.status.toUpperCase()}>{orderDetails.status}</StakeStatValue>
                            </div>
                            <div className="col-lg-3 col-md-6">
                            <StakeStatData>Failure Reason</StakeStatData>
                            <StakeStatValue>
                                <StakeStatValue>{orderDetails.failureReason}</StakeStatValue>
                            </StakeStatValue>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

