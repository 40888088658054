import React, { Component } from "react";
import { connect } from "react-redux";
import { copyToClipboard } from "../../Redux/actions";
import {
  ProfileTitle,
  ProfileValue,
  BigblueText,
  UserAvatar,
  WicryptPattern,
} from "./profilestyles";

class UserDetail extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
    };
  }

  render() {
    const {
      verificationStatus,

      name,
      copied,
      email,
      refCode,
      role,

      businessProfile,
    } = this.props;
    var show = role.includes("Business");

    return (
      <div className="row">
        <div className="col-lg-4 fade-in">
          <div>
            <div className="card-header text-center">
              {verificationStatus === "VERIFIED" ||
              verificationStatus === "PENDING" ? (
                ""
              ) : (
                // <Link to="/upgrade">
                //   <button
                //     type="button"
                //     className="d-block mb-2 btn btn-sm btn-pill wicryptColor m"
                //     style={{ position: "absolute", right: "5%" }}
                //   >
                //     <i className="material-icons mr-1">trending_up</i>Upgrade
                //   Account
                // </button>
                // </Link>
                <></>
              )}
              <div>
                <UserAvatar
                  className="rounded-circle"
                  src="/Assets/images/avatars/user_icon.svg"
                  alt="User Avatar"
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <ProfileTitle> NAME</ProfileTitle>
          <ProfileValue>{name}</ProfileValue>
          <ProfileTitle> EMAIL</ProfileTitle>
          <ProfileValue>{email}</ProfileValue>
          {show ? (
            <React.Fragment>
              <ProfileTitle> BUSINESSS NAME</ProfileTitle>
              <ProfileValue> {businessProfile.name}</ProfileValue>
              <ProfileTitle> BUSINESSS ADDRESS</ProfileTitle>
              <ProfileValue> {businessProfile.officeAddress}</ProfileValue>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ProfileTitle> REFERRAL CODE</ProfileTitle>
              <BigblueText>
                {" "}
                {refCode}
                <span>
                  {" "}
                  <i
                    onClick={() => this.props.copyToClipboard(refCode)}
                    className="material-icons text-orange pointer"
                  >
                    file_copy
                  </i>{" "}
                  {copied === refCode ? <p className="copied"> copied </p> : ""}
                </span>
              </BigblueText>
            </React.Fragment>
          )}
        </div>
        <WicryptPattern
          src="/Assets/cardicons/wicryptpattern.svg"
          alt="wicryptpattern"
          width=""
          height=""
        />
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const {
    editInfo,
    action,
    allBanks,
    currencyRates,
    withdrawalError,
    accountName,
    accountNameError,
    otpSent,
  } = state.Manager;
  const {
    name,
    email,
    refCode,
    role,
    businessBalance,
    accountNumber,
    amtToWithdraw,
    verificationStatus,
    bankId,
    withdrawalOtp,
    withdrawalPin,
    copied,
    narration,
    currencyValue,
    otpError,
    currencyCode,
    businessProfile,
  } = state.User;
  const { merchantAccount } = state.Agent;
  const { makingWithdrawal, sendingOtp, gettingAcctName } = state.Loading;
  return {
    editInfo,
    accountName,
    verificationStatus,
    name,
    email,
    refCode,
    role,
    merchantAccount,
    businessBalance,
    action,
    makingWithdrawal,
    allBanks,
    currencyRates,
    accountNumber,
    amtToWithdraw,
    bankId,
    withdrawalOtp,
    withdrawalPin,
    narration,
    currencyValue,
    otpError,
    currencyCode,
    withdrawalError,
    accountNameError,
    sendingOtp,
    otpSent,
    gettingAcctName,
    businessProfile,
    copied,
  };
};
export default connect(MapstateToProps, {
  copyToClipboard,
})(UserDetail);
