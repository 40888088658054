import styled from "styled-components";
import { theme } from "../../theme";

// profile styles

export const ProfileDetails = styled.div`
  margin-bottom: 30px;
`;
export const DataKey = styled.p`
  color: ${theme.grey};
  font-size: ${theme.textXs};
  margin-bottom: 5px;
  margin-right: 10px;
  text-transform: uppercase;
`;
export const DataValue = styled.p`
  color: ${theme.black};
  font-size: ${theme.textSm};
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
`;

// wallet styles

export const WalletType = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.grey};
  margin-bottom: 20px;
`;
export const WalletData = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.textSm};
  line-height: 24px;
  color: ${theme.grey};
  margin-bottom: 10px;
`;
export const WalletValue = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #25346a;
`;

// stake styles

export const StakeStatData = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.grey};
  margin-top: 10px;
`;
export const StakeStatValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29313c;
  margin-bottom: 10px;
`;

export const ChangeRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  aligm-item: center;
  gap: 15px;
  background-color: ${theme.white};
  padding: 15px;
  margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #d2cbcb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const SelectTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 10px;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  // top:100%;
  // // left: 0;
  gap: 20px;
  border-radius: 18px;
  backgroud: #ffffff;
  padding: 15px;
  width: 50%;
  z-index: 100000;
  box-shadow: 1px 1px 2px 2px #b7b7b7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: ${theme.white};
  margin-bottom: 5px;
`;

export const Value = styled.div`
  font-size: 12px;
  color: #6b6b6b;
  opacity: 0.8;
`;

export const Option = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.8;
  color: ${(props) => (props.selected ? "#E5B910" : "#6b6b6b")};
  cursor: pointer;
`;

export const AdminRole = styled.div`
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  color: #707070;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const EmailInput = styled.input`
         width: 100%;
         outline: none;
         border: none;
         border-bottom: 1px solid #d2cbcb;
         display: flex;
         padding-bottom: 5px;
         cursor: pointer;
         font-weight: 500;
         font-size: 12px;
       `;
