import styled from "styled-components";
import { theme, device } from "../../theme";

export const SidenavContainer = styled.aside`
  display: none;
  @media ${device.laptop} {
    display: block;
    background-color: ${(props) => (props.theme ? theme.white : theme.black)};
    margin: 0;
    padding: 0;
    padding-top: 20px;
    width: 20%;
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 100;
  }
`;
export const SidenavLinks = styled.div`
  display: block;
  color: ${theme.black};
  padding: 30px;
  text-decoration: none;
  margin-top: 30px;
`;
export const SidenavLink = styled.div`
         position: relative;
         font-weight: ${theme.textXBold};
         font-size: ${theme.textXs};
         line-height: 27px;
         color: ${(props) => (!props.active ? theme.grey : theme.blue)};
         background: ${(props) =>
           !props.active ? theme.white : theme.lightblue};
         padding: 10px;
         border-radius: 10px;
         cursor: pointer;
         @media ${device.laptop} {
           padding: 15px;
         }

         svg {
           font-size: 26px;
           font-weight: 800;
           color: ${theme.grey};
           margin-right: 15px;
         }
       `;

export const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 16.99px;
  right: ${(props) => (props.submainnav ? "-80%" : "30%")};
  top: ${(props) => (props.submainnav ? "-10px" : "10px")};
  background: #25346a;
  border-radius: 9.98438px;
  font-style: normal;
  font-weight: 400;
  font-size: 9.98438px;
  line-height: 12px;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
`;
export const MainContent = styled.main`
@media ${device.tablet} {
    &.detail-trans {
      width: 100%;
    } 
  }
  @media ${device.laptop} {
    width: 100%;
    margin-left: 20%;
  }
`;
export const LogoContainer = styled.div`
  text-align: center;
`;
export const MainLogo = styled.img`
  width: 130px;
`;
export const MobileLogo = styled.img`
  height: 20px;
`;
export const SideIcons = styled.img`
  // height: 20px;
  margin-right: 30px;

  @media ${device.laptop} {
    //height: 18px;
  }
`;
export const SubTitle = styled.p`
  display: none;
  font-size: ${theme.textXXs};
  position: absolute;
  top: 53%;
  color: ${theme.grey};
  left: 29%;
  @media ${device.laptop} {
    display: ${(props) => (!props.active ? "none" : "block")};
  }
`;

export const TopnavContainer = styled.nav`
  display: none;
  z-index: 1000;
  @media ${device.laptop} {
    display: block;
    width: 80%;
    background-color: ${(props) => (props.theme ? theme.white : theme.black)};
    overflow: hidden;
    position: fixed;
    top: 0;
    // height: 70px;
    padding: 25px;
    // z-index: 999;
    padding-bottom: 0px;
  }
`;
export const MobileNavContainer = styled.nav`
  display: block;
  background-color: ${(props) => (props.theme ? theme.white : theme.black)};
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  // height: 70px;
  padding: 25px;
  padding-bottom: 0;
  @media ${device.laptop} {
    display: none;
  }
`;
export const BottomnavContainer = styled.nav`
  background-color: ${(props) => (props.theme ? theme.white : theme.black)};
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 25px;
  z-index: 1000;
  @media ${device.laptop} {
    display: none;
  }
`;

export const SubTopnavContainer = styled.div`
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  overflow: auto;
`;
export const SubTopnavList = styled.ul`
  display: inline-flex;
  margin-bottom: 0px;
  margin-top: 10px;
`;
export const SubTopnavItem = styled.li`
  position: relative;
  font-size: ${theme.textXs};
  color: ${(props) => (props.active ? theme.blue : "#6B6B6B")};
  font-weight: 600;
  margin-right: 50px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: ${(props) => (props.active ? "3px solid #344684" : 0)};
`;

export const InlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: -4px;
  @media ${device.laptop} {
    margin-top: -21px;
  }
`;
export const PageTitle = styled.p`
  color: ${theme.black};
  font-weight: ${theme.textBold};
`;
export const Avatar = styled.img`
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
`;
export const HelpContainer = styled.div`
  background: ${theme.lightgrey};
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5e6366;
  padding: 0.3rem;
  //margin-right: 5rem;
  cursor: pointer;
  height: 34px;
  margin-right: 20px;
  margin-left: 20px;
`;

export const SpanIcon = styled.span`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;
export const ViewAllContainer = styled.div`
  position: absolute;
  background-color: ${(props) => (props.theme ? theme.white : theme.black)};
  box-shadow: 0px 4px 22px rgb(0 0 0 / 8%);
  border-radius: 8px;
  z-index: 10000;
  padding: 20px;
  left: 0;
  top: 10%;
  width: 80%;
  @media ${device.laptop} {
    width: 20%;
    margin-left: 20%;
  }
`;
export const AdvertButton = styled.button`
  margin-top: 60px !important;
  background: linear-gradient(
    106.95deg,
    #3d529a 38.32%,
    #304487 80.88%
  ) !important;
  border-radius: 5px !important;
  color: #fefefe !important;
  height: 80px !important;
  position: relative;
`;
export const OpaqueDiv = styled.div`
  position: absolute;
  background: linear-gradient(
    106.95deg,
    rgba(61, 82, 154, 0.9) 38.32%,
    rgba(48, 68, 135, 0.9) 80.88%
  );
  mix-blend-mode: normal;
  border-radius: 8px;
  transform: rotate(-15deg);
  top: 16px;
  width: 112px;
  left: 9px;
  height: 49px;
`;

export const UserName = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  color: #6b6b6b;
  text-transform: "uppercase";
`;

export const Email = styled.p`
  color: #b5b5b5;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const Icon = styled.i`
  color: ${(props) => (props.color ? theme[props.color] : theme.yellow)};
  cursor: pointer;
`;
export const Label = styled.p`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 4px;
  color: #cfa809;
  font-size: 10px;
  text-align: center;
  margin-bottom: 0;
`;
export const DropdownAvatar = styled.img`
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 0;
`;
export const HideonDesktop = styled.div`
  display: block;

  @media ${device.laptop} {
    display: none;
  }
`;
export const TopNavDropDown = styled.div`
  z-index: 999 !important;
  position: fixed;
  right: 25px;
  top: 7%;
  padding: 15px;
  width: 280px;
  // height: 257px;
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 8%);
  border-radius: 8px;
  @media ${device.laptop} {
    width: 250px;
  }
`;
export const SupportNavDropDown = styled.div`
  z-index: 999 !important;
  position: fixed;
  right: 10%;
  top: 7%;
  padding: 15px;
  width: 280px;
  // height: 257px;
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 8%);
  border-radius: 8px;
  @media ${device.laptop} {
    width: 250px;
  }
`;
export const SupportTitle = styled.p`
  font-size: 16px;
  font-weight: ${theme.textBold};
  color: ${theme.black};
`;
export const SupportBtn = styled.div`
  width: 100px;
  height: 30px;
  background: rgba(207, 168, 9, 0.09);
  border: 1px solid rgba(207, 168, 9, 0.35);
  box-sizing: border-box;
  border-radius: 36px;
  text-align: center;
  color: #cfa809;
  padding: 5px;
`;
