import React, { Component } from "react";
import {connect} from 'react-redux';
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import {handleSubNav} from "../../Redux/actions";
import Detail from "./Detail";


class SwitchWalletDetail extends Component {
  constructor(props) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

 

  handleGoBack() {
    this.props.history.goBack();
    this.props.handleSubNav(4);
  }

  render() {
    const { date } = this.props;
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle={
              <div className="pointer" onClick={() => this.handleGoBack()}>
                <i className="material-icons">arrow_back</i>
                SwitchWallet Daily
              </div>
            } />
          </div>
          <div className="main-content-container container-fluid px-4 mt-5">
            <BreadCrumbs />
            <SubSidenav />
            <Detail selectedDate={date} />
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state, props) =>{
    const {date} = props.match.params;
    return{
      date
    }
}

export default connect(MapstateToProps, {handleSubNav})(SwitchWalletDetail)


