import _const from '../actions/Types'

const initialState = {
    serviceBeneficiaries: [],
    clickedId: '',
    chargeErr: '',
    rewardBooster: [],
    updateBooster: {},
    aprErr: "",
}

const ReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case _const.ALL_SERVICE_CHARGE:
            return { ...state, serviceBeneficiaries: action.payload }

        case _const.DELETING_SERVICE_CHARGE:
            return { ...state, clickedId: action.id }

        case _const.CHARGE_FAILED:
            return { ...state, chargeErr: action.payload }

        case _const.REWARD_BOOSTER:
            return{...state, rewardBooster: action.payload}

        case _const.UPDATE_BOOSTER:
            return{...state, updateBooster: action.payload}
        
        case _const.APR_ERROR:
            return{...state, aprErr: action.payload};
        
        case _const.CLEAR_REPLAY_ERROR:
            return {...state, aprErr: ""};

        default:
            return state
    }
}

export default ReportReducer