import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import { MainContent } from "../Partials/partials";
import SubSidenav from "../Partials/SubSidenav";
import { getDistributedRewarddetails } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { distributedStakingdetailCol } from "../../utils/TableCols/usersCols";
import { getDistributedDetailsRows } from "../../utils/TableRows/usersRows";


const DistributedRewardDetails = () => {

    const dispatch = useDispatch();
    const [disbursedRewardId, setDisbursedRewardId] = useState("");

    useEffect(() => {
        setDisbursedRewardId(window.location.pathname.split("/").at(-1))
    }, []);

    useEffect(() => {
        if(disbursedRewardId){
         dispatch(getDistributedRewarddetails(disbursedRewardId, 1, 30));
        }
    }, [disbursedRewardId]);

    const { gettingDistributedReward } = useSelector((state) => state.Loading);

    const { distributedRewardDetails } = useSelector((state) => state.Stake);

    let allRows = getDistributedDetailsRows(distributedRewardDetails.records);

    const data = {
        columns: distributedStakingdetailCol,
        rows: allRows,
    };


  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav 
            pageTitle={
                <Link to="/admin/stake">
                  {" "}
                  <i className="material-icons">arrow_back</i>
                  Distributed Reward Detail{" "}
                </Link>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4">
          <SubSidenav />
          <div className="row">
            <div className="col-lg-12 mt-1">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <CustomDataTable
                            data={data}
                            tableId={"staking-report"}
                            title={``}
                            pageCount={distributedRewardDetails.totalPages}
                            currentPage={distributedRewardDetails.currentPage}
                            loading={gettingDistributedReward}
                            noData={
                                <EmptyCard
                                    img="/Assets/emptytransactions.svg"
                                    title={`Sorry,  you have no distributed reward history`}
                                    descr={"No distributed reward history found"}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        </div>
      </MainContent>
    </div>
  );
};

export default DistributedRewardDetails;
