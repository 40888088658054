import _const from "../actions/Types";

const initialState = {
  allusers: {},
  gettingTotalUsers: false,
  totalUsers: {},
  gettingTotalAdmins: false,
  totalAdmins: {},
  gettingActiveAdmins: false,
  activeAdmins: {},
  gettingInActiveAdmins: false,
  inActiveAdmins: {},
  gettingAdminDevices: false,
  adminDevices: {},
  gettingAdminLiveSessions: false,
  adminLiveSessions: {},
  gettingDeviceTrackData: false,
  deviceTrackData: {},
  gettingLiveSessionHistory: false,
  liveSessionsHistory: {},
  gettingStakeHistory: false,
  stakeHistory: {},
  gettingLatestDevices: false,
  latestDevices: [],
  deviceDetails: {},
  emailProviders: [],
  nodeProviders: [],
  nodeProviderFail: "",
  unremittedTokens: [],
  unremittedTotalPages: 0,
  unremittedCurrentPages: 0,
  allCurrencies: [],
  currencyTotalPages: 0,  
  currencyCurrentPage: 0,
  allOperations: [],
  operationTotalPages: 0,  
  operationCurrentPage: 0,
  adminRoles: [],
  adminError: "",
  selectedAdmin: {},
  changeAdminRoles: {},
  setActiveError: "",
  whiteListedDevices: {},
  deviceModels: {},
  deviceManufacturers: {},
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.ALL_USERS:
      return {
        ...state,
        allusers: action.payload,
      };
    case _const.GETTING_TOTAL_USERS:
      return {
        ...state,
        gettingTotalUsers: action.payload,
      };
    case _const.TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    case _const.GETTING_TOTAL_ADMINS:
      return {
        ...state,
        gettingTotalAdmins: action.payload,
      };
    case _const.TOTAL_ADMINS:
      return {
        ...state,
        totalAdmins: action.payload,
      };
    case _const.GETTING_ACTIVE_ADMINS:
      return {
        ...state,
        gettingActiveAdmins: action.payload,
      };
    case _const.ACTIVE_ADMINS:
      return {
        ...state,
        activeAdmins: action.payload,
      };
    case _const.GETTING_INACTIVE_ADMINS:
      return {
        ...state,
        gettingInActiveAdmins: action.payload,
      };
    case _const.INACTIVE_ADMINS:
      return {
        ...state,
        inActiveAdmins: action.payload,
      };
    case _const.GETTING_ADMIN_DEVICES:
      return {
        ...state,
        gettingAdminDevices: action.payload,
      };
    case _const.ADMIN_DEVICES:
      return {
        ...state,
        adminDevices: action.payload,
      };
    case _const.GETTING_ADMIN_LIVE_SESSIONS:
      return {
        ...state,
        gettingAdminLiveSessions: action.payload,
      };
    case _const.ADMIN_LIVE_SESSIONS:
      return {
        ...state,
        adminLiveSessions: action.payload,
      };
    case _const.GETTING_DEVICE_TRACK_DATA:
      return {
        ...state,
        gettingDeviceTrackData: action.payload,
      };
    case _const.DEVICE_TRACK_DATA:
      return {
        ...state,
        deviceTrackData: action.payload,
      };
    case _const.GETTING_LIVE_SESSION_HISTORY:
      return {
        ...state,
        gettingLiveSessionHistory: action.payload,
      };
    case _const.LIVE_SESSION_HISTORY:
      return {
        ...state,
        liveSessionHistory: action.payload,
      };
    case _const.GETTING_STAKE_HISTORY:
      return {
        ...state,
        gettingStakeHistory: action.payload,
      };
    case _const.STAKE_HISTORY:
      return {
        ...state,
        stakeHistory: action.payload,
      };
    case _const.GETTING_LATEST_DEVICES:
      return {
        ...state,
        gettingLatestDevices: action.payload,
      };
    case _const.LATEST_DEVICES:

      return {
        ...state,
        latestDevices: action.payload,
      };

    case _const.DEVICE_DETAILS:
      return{...state, deviceDetails: action.payload}

    case _const.GET_PROVIDERS:
      return{...state, emailProviders: action.payload}

    case _const.GET_NODE_PROVIDERS:
      return{...state, nodeProviders: action.payload}

    case _const.CREATING_NODE_PROVIDER_FAILED:
      return{...state, nodeProviderFail: action.payload}

    case _const.UNREMITTED_TOKENS:
      return{...state, unremittedTokens: action.payload.records, unremittedTotalPages: action.payload.totalPages,  unremittedCurrentPages: action.payload.currentPage}

    case _const.GET_CURRENCIES:
      return{...state, allCurrencies: action.payload.records, currencyTotalPages: action.payload.totalPages,  currencyCurrentPage: action.payload.currentPage}
  
    case _const.GET_OPERATIONS:
      return{...state, allOperations: action.payload.records, operationTotalPages: action.payload.totalPages,  operationCurrentPage: action.payload.currentPage}

    case _const.ADMIN_ROLES:
      return{...state, adminRoles: action.payload.roles}

    case _const.INVITE_ADMIN_ERROR:
      return{...state, adminError: action.payload}

    case _const.SELECTED_ADMIN:
      return{...state, selectedAdmin: action.payload}

    case _const.CHANGING_ADMIN_ROLE_SUCCESSFUL:
      return{...state, changeAdminRoles: action.payload}

    case _const.ERROR_UPDATING_PROVIDER_STATUS:
      return {...state, setActiveError: action.payload}
      
    case _const.CLEAR_REPLAY_ERROR:
      return {...state, setActiveError: ""}

    case _const.WHITELISTED_DEVICES:
      return{...state, whiteListedDevices: action.payload}

    case _const.DEVICE_MODELS:
      return{...state, deviceModels: action.payload}

    case _const.DEVICE_MANUFACTURERS:
      return{...state, deviceManufacturers: action.payload}

    default:
      return state;
  }
};

export default AdminReducer;
