import _const from "../actions/Types";

const initialState = {
  fundHistory: [],
  deviceSummary: [],
  oneAgentReport: [],
  bizsummaryReport: {},
  conversionsRate: 360,
  bizsessionSummary: [],
  navToggle: false,
  businessSessionsTotalRecords: 0,
  businessSessionsCurrentPage: 1,
  businessSessionsTotalPages: 1,
  totalBusinessTransactionPages: 1,
  currentBusinessTransactionPage: 1,
  allBusinessTransactions: 0,
  transactionOverview: {},
  businessAgent: false,
  remittanceReport: [],
  remittanceTotalRecords: 0,
  remittanceCurrentPage: 1,
  remittanceTotalPages: 1,
  outstandingremittanceReport: [],
  outstandingremittanceTotalRecords: 0,
  outstandingremittanceCurrentPage: 1,
  outstandingremittanceTotalPages: 1,
  remitErr: "",
  allBusinessAgents: [],
  businessAgentsTotalRecords: 0,
  businessAgentsCurrentPage: 1,
  businessAgentsTotalPages: 1,
  businessAgentDataUsage: []
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.FUND_HISTORY:
      return {
        ...state,
        fundHistory: action.payload.records,
        allBusinessTransactions: action.payload.totalRecords,
        currentBusinessTransactionPage: action.payload.currentPage,
        totalBusinessTransactionPages: action.payload.totalPages,
      };

    case _const.CLEAR_TRANSACTION_HISTORY:
      return { ...state, fundHistory: [] };

    case _const.DEVICE_SUMMARY:
      return { ...state, deviceSummary: action.payload };

    case _const.ONE_AGENT_REPORT:
      return { ...state, oneAgentReport: action.payload };

    case _const.BUSINESS_REPORT_SUMMARY:
      return { ...state, bizsummaryReport: action.payload };

    case _const.BUSINESS_SESSIONS_SUMMARY:
      return {
        ...state,
        bizsessionSummary: action.payload.records,
        businessSessionsTotalRecords: action.payload.totalRecords,
        businessSessionsCurrentPage: action.payload.currentPage,
        businessSessionsTotalPages: action.payload.totalPages,
      };

    case _const.ALL_BUSINESS_AGENTS:
      return {
        ...state,
        allBusinessAgents: [...state.allBusinessAgents, ...action.payload.data],
        businessAgentsTotalRecords: action.payload.meta.total,
        businessAgentsCurrentPage: action.payload.meta.page,
        businessAgentsTotalPages: action.payload.meta.numberOfPages,
      }

    case _const.REMITTANCE_REPORT:
      return {
        ...state,
        remittanceReport: action.payload.records,
        remittanceTotalRecords: action.payload.totalRecords,
        remittanceCurrentPage: action.payload.currentPage,
        remittanceTotalPages: action.payload.totalPages,
      };

    case _const.TOGGLE_NAV:
      return {
        ...state,
        navToggle: action.payload,
      };

    case _const.CLEAR_DATA:
      return { ...state, bizsessionSummary: [], allBusinessAgents: [] };

    case _const.TRANSACTIONS_OVERVIEW:
      return { ...state, transactionOverview: action.payload };

    case _const.IS_AGENT:
      return { ...state, businessAgent: action.payload };

    case _const.CLEAR_REMITTANCE_REPORT:
      return {
        ...state,
        remittanceTotalRecords: 0,
        remittanceReport: [],
        remittanceCurrentPage: 1,
        remittanceTotalPages: 1,
        outstandingremittanceTotalRecords: 0,
        outstandingremittanceReport: [],
        outstandingremittanceCurrentPage: 1,
        outstandingremittanceTotalPages: 1,
      };

    case _const.OUTSTANDING_REMITTANCE_REPORT:
      return {
        ...state, outstandingremittanceReport: action.payload.records,
        outstandingremittanceTotalRecords: action.payload.totalRecords,
        outstandingremittanceCurrentPage: action.payload.currentPage,
        outstandingremittanceTotalPages: action.payload.totalPages
      }

    case _const.REMITTANCE_FAIL:
      return { ...state, remitErr: action.payload };

    case _const.SETTING_REMITTANCE:
      return { ...state, remitErr: "" };

    case _const.BUSINESSAGENT_DATA_USAGE:
      return { ...state, businessAgentDataUsage: action.payload }

    default:
      return state;
  }
};

export default ReportReducer;