import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getsubscriptionplansbylocality, toggleModal, usersubscription, currentsubscriptionplan } from "../../Redux/actions"
import CustomLoader from "../Reusables/loaders/CustomCardLoader";
import Loader from "../Reusables/loaders/MoonLoader";
import { numberWithCommas } from "../../Helper";

class Subscriptions extends Component {

  componentDidMount() {
    this.props.getsubscriptionplansbylocality();
    this.props.currentsubscriptionplan();
  }

  render() {
    const { subscriptionplansbylocality, gettingsubscriptionbylocality, currentplan, updatingsubscription, planType, planId } = this.props;
    return (
      <div className="col-lg-12 mt-5">
        {gettingsubscriptionbylocality ? (
          <div className="col-lg-12 ">
            <div className=" t-center mt-4">
              <CustomLoader />
            </div>
          </div>
        ) : subscriptionplansbylocality.length === 0 && gettingsubscriptionbylocality === false ? (
          <div className="col-lg-12 ">
            <div className=" t-center mt-4">
              <p className="voucherText">
                {" "}
                        There are no subscription plans for your locality
                    </p>
            </div>
          </div>
        ) : (
              <div className="row">
                {subscriptionplansbylocality.map((eachPlan) => {
                  return (
                    <div className="col-lg-3">
                      <div className="card card-small mb-4">
                        <div>
                          <div className="mt-5">
                            <div className="t-center">
                              <h5 className="bold">{eachPlan.name}</h5>
                              <p className="t-12 mb-2">  Monthly Plan: <b className="bold">{eachPlan.country.isoThreeLetterCurrencyCode + " " + numberWithCommas(eachPlan.monthlyFee)} </b></p>
                              <p className="t-12 mb-2"> Annual Plan: <b className="bold"> {eachPlan.country.isoThreeLetterCurrencyCode + " " + numberWithCommas(eachPlan.yearlyFee)} </b></p>
                              <p className="t-12 mb-2"> Minimum Devices: <b className="bold"> {eachPlan.minimumDevices} </b></p>
                              <p className="t-12"> Maximum Devices: <b className="bold"> {eachPlan.maximumDevices} </b></p>
                            </div>
                          </div>
                        </div>
                        <div className="t-center mb-5">
                          <div className="mb-2 mt-2">
                            {!currentplan? 
                            <div>
                            <button
                              className="Approved btn button text-white"
                              onClick={() =>
                                this.props.usersubscription(
                                  true, eachPlan.id, "Monthly"
                                )
                              }
                            >
                              {updatingsubscription &&
                                planType === "Monthly" && planId === eachPlan.id ? (
                                  <Loader />
                                ) : (
                                  "Monthly Plan"
                                )}
                            </button>
                            <button
                              className="Approved btn button text-white"
                              onClick={() =>
                                this.props.usersubscription(
                                  true, eachPlan.id, "Annual"
                                )
                              }
                            >
                              {updatingsubscription &&
                                planType === "Annual" && planId === eachPlan.id ? (
                                  <Loader />
                                ) : (
                                  "Annual Plan"
                                )}
                            </button>
                          </div> :
                            currentplan.subscriptionPlanId === eachPlan.id ? (
                              <button
                                className="Pending btn button text-white"
                              // onClick={() => this.props.activateDynamicForm(eachPlan.formCode)
                              // }
                              >
                                Subscribed
                              </button>
                            ) : (
                                <div>
                                  <button
                                    className="Approved btn button text-white"
                                    onClick={() =>
                                      this.props.usersubscription(
                                        true, eachPlan.id, "Monthly"
                                      )
                                    }
                                  >
                                    {updatingsubscription &&
                                      planType === "Monthly" && planId === eachPlan.id ? (
                                        <Loader />
                                      ) : (
                                        "Monthly Plan"
                                      )}
                                  </button>
                                  <button
                                    className="Approved btn button text-white"
                                    onClick={() =>
                                      this.props.usersubscription(
                                        true, eachPlan.id, "Annual"
                                      )
                                    }
                                  >
                                    {updatingsubscription &&
                                      planType === "Annual" && planId === eachPlan.id ? (
                                        <Loader />
                                      ) : (
                                        "Annual Plan"
                                      )}
                                  </button>
                                </div>

                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                })}
              </div>
            )}
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const { subscriptionplansbylocality, currentplan, planType, planId } = state.Subscription;
  const { gettingsubscriptionbylocality, updatingsubscription } = state.Loading;
  return {
    subscriptionplansbylocality,
    gettingsubscriptionbylocality,
    currentplan,
    updatingsubscription,
    planType,
    planId
  }
}
export default connect(MapstateToProps, { getsubscriptionplansbylocality, toggleModal, usersubscription, currentsubscriptionplan })(Subscriptions)