import React from "react";

const Search = () => {
  return (
    <div className="col-lg-3 offset-lg-9 p-0 mt-5 mb-2 t-right">
      <input className="form-control" type="text" placeholder="Search" />
    </div>
  );
};

export default Search;
