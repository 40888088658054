
import styled from "styled-components";

export const SubmitButtonContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`;

export const SubmitButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
width: 493px;
height: 50px;
background: #25346a;
border-radius: 40px;
font-style: normal;
font-weight: 600;
font-size: 18px;
text-align: center;
color: #ffffff;
margin-top: 10px;
outline: none;
border: none;
`;