import React from "react";
import { SubmitButton, SubmitButtonContainer } from "../../Devices/modals/styles";
import Loader from "../../Reusables/loaders/MoonLoader";
import { CustomErrorModal } from "../../Reusables/modals/CustomModal";

function CurrencyControlModal({ onSubmit, setActiveError, updatingProviderStatus, enableDisable, currencyName }) {
    const formatEnableDisable = enableDisable.replace(/d$/, '');

  return (
      <div>
          <CustomErrorModal
              id={87}
              heading={`Currency Control - ${enableDisable}`}
              subHeading={`Are you sure you want to ${formatEnableDisable} this currency - ${currencyName}`}
              close={true}
              content={
                  <>
                    {setActiveError ? <p className="errorMsgs mt-3 t-center mb-0">{setActiveError}</p> : ""}
                    <SubmitButtonContainer onClick={onSubmit} >
                        <SubmitButton className={enableDisable === "Enabled" ? "bkg-green details" : "bkg-red details"}>{updatingProviderStatus ? <Loader /> : enableDisable === "Enabled" ? "Enable" : "Disable"}</SubmitButton>
                    </SubmitButtonContainer>
                  </>
              }
             
          />
      </div>
  );
}

export default CurrencyControlModal;
