/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from "react";
import { MdFileCopy } from "react-icons/md";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import _const from "../../Redux/actions/Types";
import {
  copyHandle,
  shortenAddress,
  numberWithCommaswithoutdecimals,
  numberWithCommas,
} from "../../Helper";
import { handleModal } from "../../Redux/actions";

export const getTransactionRows = (
  data,
  dispatch,
  page,
  copied,
  setCopied,
  currentRow,
  setCurrentRow,
  type,
  setType,
  activeTab,
  setTransactionDetails
) => {
  let allRows = data
    ? data.map((transc, index) => {
      return {
        name: transc.name || "N/A",
        type: transc.transactionType || "N/A",
        type: (
          <div className={` ${transc.transactionType === 'Sell' ? 'text-red' : transc.transactionType === 'Buy' ? 'Funding' : transc.transactionType === 'Swap' ? 'text-blue' : ''}`}>
            {transc.transactionType || "N/A"}
          </div>
        ),
        id: transc.id || "N/A",
        method: "",
        buyAmount: transc.buyAmount ? 
          ( <p className={` ${transc.transactionType === 'Buy' ? 'Funding' : 'text-red'}`}>
            {transc.transactionType === 'Buy' ? "+" + numberWithCommas(transc.buyAmount) + " " + transc.buyCurrency : "-" + numberWithCommas(transc.buyAmount) + " " + transc.buyCurrency}
          </p>) : "N/A",
        sellAmount: transc.sellCurrency ?
          (<p className={` ${transc.transactionType === 'Buy' ? 'text-red' : 'Funding'}`}>
            {transc.transactionType === 'Buy' ? "-" + numberWithCommas(transc.sellAmount) + " " + transc.sellCurrency : "+" + numberWithCommas(transc.sellAmount) + " " + transc.sellCurrency}
          </p>) : "N/A",
        referenceCode: transc.referenceCode ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("reference");
              setCopied(true);
              copyHandle(transc.referenceCode);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(transc.referenceCode)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "reference" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        wallet: transc.wallet || "N/A",
        address: transc.walletAddress ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setCopied(true);
              setType("address");
              copyHandle(transc.walletAddress);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(transc.walletAddress)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "address" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        provider: transc.liquidityProvider || "N/A",
        customerInfo: transc.customerName && (<p className="mb-0"> {transc.customerName}</p>),
        network: transc.network || "N/A",
        week: transc.week || "N/A",
        deviceName: transc.deviceName || "N/A",
        userId: transc.userId || "N/A",
        amount: transc.amount && (
          <b
            className={`${
              transc.transactionType === "Funding" ||
              transc.transactionType === "Weekly Reward" ||
              transc.transactionType === "Withdrawal Reversal" ||
              transc.transactionType === "Sell" ||
              transc.isCredit
                ? "Funding"
                : "Withdrawal"
            } bold t-12`}
            style={{ display: "flex", alignItems: "center" }}
          >
            {transc.transactionType === "Funding" ||
            transc.transactionType === "Weekly Reward" ||
            transc.transactionType === "Withdrawal Reversal" ||
            transc.transactionType === "Sell" ||
            transc.isCredit
              ? "+ " + numberWithCommas(transc.amount) + " " + transc.currency
              : "- " + numberWithCommas(transc.amount) + " " + transc.currency}
          </b>
        ),
        transferAmount: transc.amount && (
          <>
            <b
              className={`${
                transc.transactionType === "Credit"
                  ? "Funding"
                  : "Withdrawal"
              } bold t-12`}
              style={{ display: "flex", alignItems: "center" }}
            >
              {transc.transactionType === "Credit"
                ? "+ " + numberWithCommas(transc.amount) + " " + transc.currency
                : "- " + numberWithCommas(transc.amount) + " " + transc.currency}
            
            </b>
            <b className="text-red">Fee: {numberWithCommas(transc.fee)}</b>
          </>
        ),
        // view: (
        //   <Link
        //     to={
        //       page === "user"
        //         ? `/user/${transc.userId}/transactionDetails`
        //         : page === "admin"
        //         ? `/admin/${transc.userId}/transactionDetails`
        //         : "/transactionReport"
        //     }
        //     onClick={() => {
        //       dispatch({
        //         type: _const.SINGLE_TRANSACTION_DETAILS,
        //         payload: transc,
        //       });
        //       localStorage.setItem("activeInnerTab", activeTab);
        //     }}
        //   >
        //     <i
        //       className="material-icons mr-2 ml-2 t-14"
        //       aria-hidden="true"
        //       style={{ cursor: "pointer" }}
        //     >
        //       chevron_right
        //     </i>
        //   </Link>
        // ),
        view: (
          <div
            className="details t-center"
            onClick={() => {
              dispatch(handleModal(60));
              setTransactionDetails(transc);
            }}
          >
            More Details
          </div>
        ),
        viewMore: (
          <Link
          to={
            transc.transactionType === "Buy" || transc.transactionType === "Sell" || transc.transactionType === "Swap" 
              ? `/p2p/${transc.transactionType.toLowerCase()}/${transc.id}`
              : transc.transactionType === "Debit" || transc.transactionType === "Credit" 
              ? `/transfer/${transc.id}`
              :  transc.type === "unstake" || transc.type === "stake" || transc.type === "claim"
              ? `/staking/${transc.id}`
              : transc.type === "claimReward"
              ? `/stake-claims/${transc.id}`
              : transc.rewardWeek 
              ? `/mining-rewards/${transc.id}`
              : transc.week || transc.disbursedRewardId
              ? `/disbursed-staking/${transc.disbursedRewardId}`
              : transc.depositorWalletAddress 
              ? `/crypto-deposit/${transc.id}`
              : transc.destinationWalletAddress 
              ? `/crypto-withdrawal/${transc.id}`
              : `/disbursed-rewards/${transc.id}`
          }
          ><div className="details t-center">More Details</div>
          </Link>
        ),
        // viewMoreCrypto: (<Link to={`/crypto-deposit/${transc.id}`}><div className="details t-center">More Details</div></Link>),
        disbursedRewardId: transc.disbursedRewardId,
        date:
          moment(transc.createdAt).format("DD-MM-YYYY") +
          " | " +
          moment(transc.createdAt).format("LT"),
        status: transc.status && (
          <div className={transc.status.toUpperCase()}>
            {transc.status || "N/A"}
          </div>
        ),
        sender: transc.sender && (<div>
          <p className="mb-8">Name: <Link to={`/user/${transc.sender.id}`}>{transc.sender && transc.sender.name ? transc.sender.name : "N/A"}</Link></p>
          <p className="mb-0">Email: {transc.sender && transc.sender.email ? transc.sender.email : "N/A"}</p>
          </div>),
        receiver: transc.receiver && (<div>
          <p className="mb-8">Name: <Link to={`/user/${transc.receiver.id}`}>{transc.receiver && transc.receiver.name ? transc.receiver.name : "N/A"}</Link></p>
          <p className="mb-0">Email: {transc.receiver && transc.receiver.email ? transc.receiver.email : "N/A"}</p>
          </div>),
        fee: (transc.fee || "0"),
        totalChargeableAmount: (transc.amount ? (<span>{numberWithCommas(transc.totalChargeableAmount, 2)} {transc.currency}</span>) : "0.00"),
        isFeeRemitted: (transc.isFeeRemitted == true ? "True" : "False"),
        userName: transc.userInfo && (<p>{transc.userInfo && transc.userInfo.name ?  transc.userInfo.name : "N/A"}</p>),
        userEmail: (transc.userInfo && transc.userInfo.email ?  transc.userInfo.email : "N/A"),
        stakeType: (transc.type && <div className={transc.type.toLowerCase()}>{transc.type || "N/A"}</div>),
        lockPeriod: (transc.lockPeriod || "N/A"),
        stakeDate: moment(transc.transactionTimeStamp).format("DD-MM-YYYY") +
        " | " +
        moment(transc.transactionTimeStamp).format("LT"),
        totalDisbursedRewards: (numberWithCommas(transc.totalDisbursedRewards, 2) || "0"),
        totalImpactedUsers: (transc.totalImpactedUsers || "N/A"),
        newTrancAmount: (transc.amount ? (<span>{numberWithCommas(transc.amount, 2)} {transc.currency}</span>) : "0.00"),
        rewardWeek: (transc.rewardWeek || "N/A"),
        miningBssid: (transc.deviceInfo && transc.deviceInfo.bssid ? transc.deviceInfo.bssid : "N/A"), 
        miningDeviceName: (transc.deviceInfo && transc.deviceInfo.name ? transc.deviceInfo.name : "N/A"),
        transactionHash: transc.transactionHash ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("transactionHash");
              setCopied(true);
              copyHandle(transc.transactionHash);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(transc.transactionHash)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "transactionHash" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        depositorWalletAddress: transc.depositorWalletAddress ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("depositorWalletAddress");
              setCopied(true);
              copyHandle(transc.depositorWalletAddress);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(transc.depositorWalletAddress)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "depositorWalletAddress" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        destinationWalletAddress: transc.destinationWalletAddress ? (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCurrentRow(index + 1);
              setType("destinationWalletAddress");
              setCopied(true);
              copyHandle(transc.destinationWalletAddress);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            className="address"
          >
            {shortenAddress(transc.destinationWalletAddress)}
            <span>
              <MdFileCopy />
            </span>
            {copied && currentRow === index + 1 && type === "destinationWalletAddress" && (
              <>
                <br />
                <span>Copied</span>
              </>
            )}
          </p>
        ) : (
          "N/A"
        ),
        amountCreditedToWallet: (transc.amountCreditedToWallet ? (<>
        <b style={{display: "flex"}}>{numberWithCommas(transc.amountCreditedToWallet, 2)} {transc.currency}</b>
        <b className="text-red">Fee: {numberWithCommas(transc.fee) || 0.00}</b></>) : 0.00),

        cryptoAmount: (transc.amount ? (<>
          <b style={{display: "flex"}}>{numberWithCommas(transc.amount, 2)} {transc.currency}</b> 
          <b className="text-red">Fee: {numberWithCommas(transc.fee) || 0.00}</b></>) : 0.00),
      };
      })
    : [];
  return allRows;
};

export const getP2PWebhook = (data, setOrderDetail) => {
  let dispatch = useDispatch();
  let allRows = data
    ? data.map((transc) => {
        return {
          createdDate: transc.createdAt
            ? moment(transc.createdAt).format("DD-MM-YYYY") +
              " | " +
              moment(transc.createdAt).format("LT")
            : "",
          date: transc.processedOn
            ? moment(transc.processedOn).format("DD-MM-YYYY") +
              " | " +
              moment(transc.processedOn).format("LT")
            : "",
          status: transc.status && (
            <div className={transc.status.toUpperCase()}>
              {transc.status ? transc.status : "N/A"}
            </div>
          ),
          referenceCode: (
            <div>
              {transc.webhookData &&
              transc.webhookData.orderDetails &&
              transc.webhookData.orderDetails.orderReference
                ? transc.webhookData.orderDetails.orderReference
                : ""}
            </div>
          ),
          orderReference: (
            <div>
              {transc.webhookData ? shortenAddress(transc.webhookData.orderReference) : ""}
            </div>
          ),
          customerDetail: (
            <div>
              {transc.webhookData && transc.webhookData.customerEmailAddress
                ? transc.webhookData.customerEmailAddress
                : ""}
            </div>
          ),
          liquidityProvider: (
            <div>
              {transc.webhookData && transc.webhookData.liquidityProviderName
                ? transc.webhookData.liquidityProviderName
                : ""}
            </div>
          ),
          referenceCode:
            transc.webhookData &&
            transc.webhookData.orderDetails &&
            transc.webhookData.orderDetails.orderReference
              ? shortenAddress(transc.webhookData.orderDetails.orderReference)
              : "",
          initiatedBy:
            transc.webhookData && transc.webhookData.initiatedBy
              ? transc.webhookData.initiatedBy
              : "",
          failureReason: transc.failureReason ? transc.failureReason : "---",
          orderStatus: transc.webhookData && (
            <div
              className={
                transc.webhookData.orderStatus
                  ? transc.webhookData.orderStatus.toUpperCase()
                  : ""
              }
            >
              {transc.webhookData.orderStatus
                ? transc.webhookData.orderStatus
                : "N/A"}
            </div>
          ),
          orderStatusConfirm: transc.webhookData && transc.webhookData.orderDetails && (
            <div
              className={
                transc.webhookData.orderDetails.orderStatus
                  ? transc.webhookData.orderDetails.orderStatus.toUpperCase()
                  : ""
              }
            >
              {transc.webhookData.orderDetails.orderStatus
                ? transc.webhookData.orderDetails.orderStatus
                : "N/A"}
            </div>
          ),
          view: (
            <div
              className="details t-center"
              onClick={() => {
                dispatch(handleModal(50));
                setOrderDetail(transc);
              }}
            >
              More Details
            </div>
          ),
        };
      })
    : [];
  return allRows;
};

export const switchwalletReport = (data) => {
  const [copied, setCopied] = useState("");
  let allRows = data
    ? data.map((report) => {
        return {
          dateCreated: (
            <Link
              className="text-blue text-bold"
              to={`/switchwallet/report/${report && report.dateCreated}`}
            >
              {moment(report && report.dateCreated).format("DD-MM-YYYY")}
            </Link>
          ),
          deposits: (
            <div>
              {report && report.totalDeposits && (report.totalDeposits != 0) ?
                report.totalDeposits.map((each) => {
                  
                  return (
                    <p className="mb-0">
                      {" "}
                      {each.currency} :{" "}
                      <span className="mb-0 SUCCESS">
                        {numberWithCommas(each.amount)} 
                      </span>{" "}
                    </p>
                  );
                }): (
                  <p className="mb-0">0</p>
                )}
            </div>
          ),
          withdrawals: (
            <div>
              {report.totalWithdrawals && (report.totalWithdrawals != 0) ?
                report.totalWithdrawals.map((each) => {
                  return (
                    <p className="mb-0">
                      {" "}
                      {each.currency} :{" "}
                      <span className="mb-0 FAILED">
                        {numberWithCommas(each.amount)}
                      </span>{" "}
                    </p>
                  );
                }): (
                  <p className="mb-0">0</p>
                )}
            </div>
          ),
          createdAt: report && report.createdAt
            && (<div className="">
             { moment(report.createdAt).format("DD-MM-YYYY") +
                " | " +
                moment(report.createdAt).format("LT")}  
                
            </div>),
          processedOn: report.processedOn
            ?( <div className="CONFIRMED">
             { moment(report.processedOn).format("DD-MM-YYYY") +
                " | " +
                moment(report.processedOn).format("LT")}  
                
            </div>)
            : <div className="PENDING">Not Processed</div>,
          amount: (
            <div>
              <p className="mb-0">
                {" "}
                Amount:{" "}
                {(report &&
                  report.webhookData &&
                  numberWithCommas(report.webhookData.amount || 0)) ||
                  ""}
              </p>
              <p className="mb-0">
                {" "}
                Charge:{" "}
                {report &&
                  report.webhookData &&
                  numberWithCommas(report.webhookData.charge || 0)}
              </p>
              <p className="mb-0">
                {" "}
                Amount Deposited:{" "}
                {report &&
                  report.webhookData &&
                  numberWithCommas(
                    report.webhookData.totalAmountDeposited || 0
                  )}
              </p>
              <p className="mb-0">
                {" "}
                Amount Sent:{" "}
                {report &&
                  report.webhookData &&
                  numberWithCommas(report.webhookData.amountSent || 0)}
              </p>
              <p className="mb-0">
                {" "}
                Amount Requested:{" "}
                {report &&
                  report.webhookData &&
                  numberWithCommas(report.webhookData.amountRequested || 0)}
              </p>
              <p className="mb-0">
                {" "}
                Amount Withdrawn:{" "}
                {report &&
                  report.webhookData &&
                  numberWithCommas(
                    report.webhookData.totalAmountWithdrawn || 0
                  )}
              </p>
              <p className="mb-0">
                {" "}
                Currency:{" "}
                {report && report.webhookData && report.webhookData.currency}
              </p>
            </div>
          ),
          address: (
            <div>
              <p className="mb-0">
                Deposit Address:{" "}
                {report &&
                  report.webhookData &&
                  shortenAddress(report.webhookData.depositAddress)}
                {report &&
                  report.webhookData &&
                  report.webhookData.depositAddress && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.depositAddress
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.depositAddress
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.depositAddress) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>
             {report &&
                  report.webhookData &&
                  report.webhookData.receiveAddress &&  <p className="mb-0">
                Receiver Address:{" "}
                {report &&
                  report.webhookData &&
                  shortenAddress(report.webhookData.receiveAddress)}
                {report &&
                  report.webhookData &&
                  report.webhookData.receiveAddress && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.receiveAddress
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.receiveAddress
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.receiveAddress) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>}
              {report &&
                  report.webhookData &&
                  report.webhookData.receiverAddress && <p className="mb-0">
                Receiver Address:{" "}
                {report &&
                  report.webhookData &&
                  shortenAddress(report.webhookData.receiverAddress)}
                {report &&
                  report.webhookData &&
                  report.webhookData.receiverAddress && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.receiverAddress
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.receiverAddress
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.receiverAddress) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>}
              <p className="mb-0">
                Merchant Reference:{" "}
                {report &&
                  report.webhookData &&
                  shortenAddress(report.webhookData.merchantReference)}
                {report &&
                  report.webhookData &&
                  report.webhookData.merchantReference && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.merchantReference
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.merchantReference
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.merchantReference) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>
              <p className="mb-0">
                Transaction Hash:{" "}
                {report &&
                  report.webhookData &&
                  shortenAddress(report.webhookData.transactionHash)}
                {report &&
                  report.webhookData &&
                  report.webhookData.transactionHash && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.transactionHash
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.transactionHash
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.transactionHash) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>
              <p className="mb-0">
                Switchwallet Hash:{" "}
                {report &&
                  report.webhookData &&
                  report.webhookData.switchwalletReference}
                {report &&
                  report.webhookData &&
                  report.webhookData.switchwalletReference && (
                    <>
                      <span className="pointer">
                        <MdFileCopy
                          onClick={() => {
                            setCopied(
                              report &&
                                report.webhookData &&
                                report.webhookData.switchwalletReference
                            );
                            copyHandle(
                              report &&
                                report.webhookData &&
                                report.webhookData.switchwalletReference
                            );
                          }}
                        />
                      </span>
                      {copied ==
                      (report &&
                        report.webhookData &&
                        report.webhookData.switchwalletReference) ? (
                        <>
                          <br />
                          <span className="copied">Copied</span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </p>
              <p className="mb-0">
                {" "}
                Network:{" "}
                {report &&
                  report.webhookData &&
                  report.webhookData.networkChain}
              </p>
            </div>
          ),
          merchantClient:
            report &&
            report.webhookData &&
            report.webhookData.merchantClient &&
            report.webhookData.merchantClient.email,
         
          status: report.webhookData && (
            <div
              className={
                report &&
                report.webhookData &&
                report.webhookData.status.toUpperCase()
              }
            >
              {report && report.webhookData && report.webhookData.status
                ? report.webhookData.status
                : "N/A"} 
            </div>
          ),
           statusProcessed: report && (
            <div
              className={
                report &&
                report.status
              }
            >
              {report && report && report.status
                ? report.status
                : "N/A"} 
            </div>
          ),
          viewMore: (
            <Link
              to={`/switchwallet/report/${report && report.dateCreated}`}
              className="t-center"
            >
              View Details{" "}
              <img
                src="/Assets/arrowRight.svg"
                className=" t-center"
                height="20px"
                alt="view more"
              />
            </Link>
          ),
        };
      })
    : [];
  return allRows;
};
