import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getFormDetails,
  getSubBusiness,
  createSubBusiness,
  toggleModal,
  getByemail
} from "../../Redux/actions";
import CreateSubBusiness from "./CreateSubBusiness";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";

class AllSubBusinss extends Component {
  componentDidMount() {
    if (this.props.allSubBusiness.length === 0) {
      this.props.getSubBusiness(1, 50);
    }
  }

  customPagination() {
    const { allSubBusinessTotal, subPage } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== subPage) {
      paginate.push(
        <p
          onClick={() => this.props.getSubBusiness(subPage - 1, 50)}
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; allSubBusinessTotal >= i; i++) {
      if (i === subPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.getSubBusiness(i, 50)}
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (allSubBusinessTotal > subPage) {
      paginate.push(
        <p
          onClick={() => this.props.getSubBusiness(subPage + 1, 50)}
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }

  render() {
    const { gettingSubBusiness, allSubBusiness } = this.props;

    let allRows = [];
    allSubBusiness.map(business => {
      return allRows.push({
        name: business.name,
        // apiKey: business.apiKey,
        officeAddress: business.officeAddress,
        walletBalance: "NGN " + business.walletBalance * 360,
        canCreateSubsidiary: business.canCreateSubsidiary ? "Yes" : "No",
        viewsessions: (
          <div
            className="details t-center"
            onClick={() => {
              this.props.getFormDetails({
                props: ["subsessionbusiness"],
                value: true
              });
              this.props.getFormDetails({
                props: ["selectedAffiliatedBusiness"],
                value: business.id
              });
            }}
          >
            View
          </div>
        )
      });
    });
    const data = {
      columns: [
        {
          label: "Business Name",
          field: "name",
          sort: "asc",
          width: 170
        },
        // {
        //   label: "ApiKey",
        //   field: "apiKey",
        //   sort: "asc",
        //   width: 170,
        // },
        {
          label: "Office Address",
          field: "officeAddress",
          sort: "asc",
          width: 170
        },
        {
          label: "Wallet Balance",
          field: "walletBalance",
          sort: "asc",
          width: 170
        },
        {
          label: "CreateSubsidiary",
          field: "canCreateSubsidiary",
          sort: "asc",
          width: 170
        },
        {
          label: "View Sessions",
          field: "viewsessions",
          sort: "asc",
          width: 170
        }
      ],

      rows: allRows
    };
    return (
      <div className="row">
        <CreateSubBusiness />
        {allSubBusiness.length > 0 ? (
          <div className="col-lg-12">
            <CustomDataTable
              data={data}
              pagination={this.customPagination()}
              placeholder={"Search business"}
              onClick={() => this.props.toggleModal(true)}
              buttontitle={"Add business"}
              title="Subsidiary businesses"
            />
          </div>
        ) : (
          <div className="col-lg-12">
            {gettingSubBusiness ? (
              <CustomLoader />
            ) : (
              <EmptyCard
                img="/Assets/emptytransactions.svg"
                onClick={() => this.props.toggleModal(true)}
                buttontitle={"Add business"}
                title={"Sorry,  you have no subsidiary businesses"}
                descr={"Create subsidiary businesses to have them appear here"}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const MapStateToProps = state => {
  const { gettingSubBusiness } = state.Loading;
  const { allSubBusiness, subPage, allSubBusinessTotal } = state.Manager;
  return {
    gettingSubBusiness,
    allSubBusiness,
    subPage,
    allSubBusinessTotal
  };
};

export default connect(
  MapStateToProps,
  { getFormDetails, getSubBusiness, createSubBusiness, toggleModal, getByemail }
)(AllSubBusinss);
