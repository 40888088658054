import _const from "./Types";
import axios from "axios";
import config from "../reducers/config";
import { handleModal } from "./GeneralAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";



//Get Global Spend Control
export const getSpendControl = () => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_GLOBAL_SPEND_CONTROL,
            payload: true,
        });
        dispatch({
            type: _const.GLOBAL_SPEND_CONTROL,
            payload: [],
        });
        axios
            .get(config.adminApiUrl + `/v1/spendcontrol/global-spend-controls`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_GLOBAL_SPEND_CONTROL,
                    payload: false,
                });
                dispatch({
                    type: _const.GLOBAL_SPEND_CONTROL,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_GLOBAL_SPEND_CONTROL,
                    payload: false,
                });
            });
    };
};

//Create Global Spend Control
export const createSpendControl = (data) => {
    return (dispatch) => {
        Swal.fire({
            text: "Are you sure you want to do this?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value) {
                dispatch({
                    type: _const.CREATING_GLOBAL_SPEND_CONTROL,
                    payload: true,
                });
                axios
                    .post(config.apiUrl + `/v2/spendcontrol/add-global-spend-control`, data)
                    .then((res) => {
                        dispatch({
                            type: _const.CREATING_GLOBAL_SPEND_CONTROL,
                            payload: false,
                        });
                        if (res.data.data.isSuccessful) {
                            dispatch({
                                type: _const.GLOBAL_SPEND_CONTROL_CREATED,
                                payload: res.data.data,
                            });
                            dispatch(getSpendControl())
                            dispatch(handleModal(0))
                            Swal.fire({
                                text: `Spend Control has been successfully updated`,
                                type: "success",
                            });
                        } else {
                            dispatch({
                                type: _const.GLOBAL_SPEND_CONTROL_ERROR,
                                payload: res.data.data.message,
                            });
                        }
                    })
                    .catch((err) => {
                        dispatch({
                            type: _const.CREATING_GLOBAL_SPEND_CONTROL,
                            payload: false,
                        });
                        Swal.fire({
                            text: err.response ? err.response.data.message : "Something went wrong",
                            type: "error",
                        });
                    });
            } else {
            }
        });

    };
};

//Create Spend control for a user
export const createUserSpendControl = (data) => {
    return (dispatch) => {
        Swal.fire({
            text: "Are you sure you want to do this?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value) {
                dispatch({
                    type: _const.CREATING_USER_SPEND_CONTROL,
                    payload: true,
                });
                axios
                    .post(config.apiUrl + `/v2/spendcontrol/add-user-spend-control`, data)
                    .then((res) => {
                        dispatch({
                            type: _const.CREATING_USER_SPEND_CONTROL,
                            payload: false,
                        });
                        if (res.data.data.isSuccessful) {

                            dispatch({
                                type: _const.USER_SPEND_CONTROL_CREATED,
                                payload: res.data.data,
                            });
                            dispatch(getUserSpendControl(data[0].userId))
                            dispatch(handleModal(0))
                            Swal.fire({
                                text: `Spend Control has been successfully updated`,
                                type: "success",
                            });
                        } else {
                            dispatch({
                                type: _const.USER_SPEND_CONTROL_ERROR,
                                payload: res.data.data.message,
                            });
                        }

                    })
                    .catch((err) => {
                        dispatch({
                            type: _const.CREATING_USER_SPEND_CONTROL,
                            payload: false,
                        });
                        Swal.fire({
                            text: err.response ? err.response.data.message : "Something went wrong",
                            type: "error",
                        });
                    });

            } else {
            }
        });


    };
};


//Get Users Spend Control
export const getUsersSpendControl = () => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_USERS_SPEND_CONTROL,
            payload: true,
        });
        axios
            .get(config.adminApiUrl + `/v1/spendcontrol/user-spend-controls-grouped`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_USERS_SPEND_CONTROL,
                    payload: false,
                });
                console.log(res.data.data, 'users spend control')
                dispatch({
                    type: _const.USERS_SPEND_CONTROL,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_USERS_SPEND_CONTROL,
                    payload: false,
                });
            });
    };
};

//Get a user's Spend Control
export const getUserSpendControl = (userId) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_USER_SPEND_CONTROL,
            payload: true,
        });
        dispatch({
            type: _const.USER_SPEND_CONTROL,
            payload: [],
        });
        axios
            .get(config.adminApiUrl + `/v1/spendcontrol/user-spend-controls/${userId}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_USER_SPEND_CONTROL,
                    payload: false,
                });
                dispatch({
                    type: _const.USER_SPEND_CONTROL,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_USER_SPEND_CONTROL,
                    payload: false,
                });
            });
    };
};

//Get global spend control activity log
export const getGlobalSpendControlActivityLog = () => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_GLOBAL_SPEND_CONTROL_ACTIVITY,
            payload: true,
        });
        axios
            .get(config.adminApiUrl + `/v1/spendcontrol/global-spend-control-activity-Log`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_GLOBAL_SPEND_CONTROL_ACTIVITY,
                    payload: false,
                });
                dispatch({
                    type: _const.GLOBAL_SPEND_CONTROL_ACTIVITY,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_GLOBAL_SPEND_CONTROL_ACTIVITY,
                    payload: false,
                });
            });
    };
};


//Get a user's spend control activity log
export const getUserSpendControlActivityLog = (userId) => {
    return (dispatch) => {
        dispatch({
            type: _const.GETTING_USER_SPEND_CONTROL_ACTIVITY,
            payload: true,
        });
        axios
            .get(config.adminApiUrl + `/v1/spendcontrol/user-spend-control-activity-Log/${userId}`)
            .then((res) => {
                dispatch({
                    type: _const.GETTING_USER_SPEND_CONTROL_ACTIVITY,
                    payload: false,
                });
                dispatch({
                    type: _const.USER_SPEND_CONTROL_ACTIVITY,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: _const.GETTING_USER_SPEND_CONTROL_ACTIVITY,
                    payload: false,
                });
            });
    };
};