import React, { Component } from 'react'
import Mainnav from '../Partials/Mainnav'
import Sidenav from '../Partials/Sidenav'
import BreadCrumbs from '../Partials/Breadcrumbs'
import { connect } from 'react-redux'
import Tracker from './Tracker'
import { MainContent } from '../Partials/partials'
import SubSidenav from "../Partials/SubSidenav"


class AgentDataTracker extends Component {
  render() {

    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle="Manage Data" />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
            <Tracker />
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {


  return {
    state
  }
}

export default connect(MapstateToProps)(AgentDataTracker)