import _const from "../actions/Types";

const initialState = {
  creatingFormErr: "",
  allForms: [],
  oneFormCode: "",
  allFormsTotalRecords: 0,
  allFormsCurrentPage: 1,
  allFormsTotalPages: 1,
  allDynamicFormReport: [],
  allDynamicFormsReportTotalRecords: 0,
  allDynamicFormsReportCurrentPage: 1,
  allDynamicFormsReportTotalPages: 1,
  formCreated: false
};

const DynamicFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.CREATING_FORM_ERR:
      return { ...state, creatingFormErr: action.payload };

    case _const.ALL_FORMS:
      return {
        ...state,
        allForms: action.payload.records,
        allFormsTotalRecords: action.payload.totalrecords,
        allFormsCurrentPage: action.payload.currentPage,
        allFormsTotalPages: action.payload.totalPages,
      };

    case _const.GET_FORM_CODE:
      return { ...state, oneFormCode: action.payload };

    case _const.CLEAR_DYNAMIC_FORMS:
      return { ...state, allForms: [] };

    case _const.DYNAMIC_FORM_REPORT:
      return {
        ...state,
        allDynamicFormReport: [
          ...state.allDynamicFormReport,
          ...action.payload.records,
        ],
        allDynamicFormsReportTotalRecords: action.payload.totalRecords,
        allDynamicFormsReportCurrentPage: action.payload.currentPage,
        allDynamicFormsReportTotalPages: action.payload.totalPages,
      };

    case _const.CLEAR_FORM_RESPONSES:
      return {
        ...state,
        allDynamicFormReport: [],
        allDynamicFormsReportTotalRecords: 0,
        allDynamicFormsReportCurrentPage: 1,
        allDynamicFormsReportTotalPages: 1,
      };

    case _const.FORM_CREATED:
      return {
        ...state,
        formCreated: action.payload
      }
    default:
      return state;
  }
};

export default DynamicFormReducer;
