import React, { Component } from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import { connect } from "react-redux";
import Tracker from "./tracker";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { Link } from "react-router-dom";

class DataTracker extends Component {
  render() {
    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav
              pageTitle={
                <Link
                  to="/devices/singledevice"
                  className="pointer mb-0"
                >
                  <i className="material-icons ">arrow_back </i>
                  Track Data
                </Link>
              }
            />
          </div>
          <BreadCrumbs />
          <div className="main-content-container container-fluid px-4 mt-5">
            <SubSidenav />
            <Tracker />
          </div>
        </MainContent>
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  return {
    state,
  };
};

export default connect(MapstateToProps)(DataTracker);
