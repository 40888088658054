import React, { Component } from "react";
import BusinessCard from "../Reusables/cards/BusinessCard";
import PersonalCard from "../Reusables/cards/PersonalCard";
import { connect } from "react-redux";
import { numberWithCommas } from "../../Helper";

class Wallet extends Component {
  render() {
    const { balance, promoBalance, businessBalance, role } = this.props;
    var show = role.includes("Business");
    return (
      <div className="row fade-in">
        <PersonalCard
          title="Personal Balance"
          smallTitle={"(withdrawable from mobile app)"}
          value={numberWithCommas(balance) + "WC"}
          nairaExchange={"NGN " + numberWithCommas(balance * 360)}
        />
        {show ? (
          <BusinessCard
            title="Business Wallet"
            smallTitle={"(withdrawable from web dashboard)"}
            value={numberWithCommas(businessBalance) + "WC"}
            nairaExchange={"NGN " + numberWithCommas(businessBalance * 360)}
          />
        ) : (
          ""
        )}

        <PersonalCard
          title="Promo Balance"
          value={numberWithCommas(promoBalance) + "WC"}
          nairaExchange={"NGN " + numberWithCommas(promoBalance * 360)}
        />
      </div>
    );
  }
}

const MapstateToProps = (state) => {
  const {
    balance,

    promoBalance,
    businessBalance,
    role,
  } = state.User;
  return {
    balance,
    promoBalance,
    role,
    businessBalance,
  };
};
export default connect(MapstateToProps)(Wallet);
