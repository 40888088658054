import React, { useEffect } from "react";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import "react-datepicker/dist/react-datepicker.css";
import * as options from "./options";
import TableHeader from "../Reusables/tables/TableHeader";
import { getDeviceSessionHistoryRows } from "../../utils/TableRows/DevicesRows";
import { deviceSessionHistoryCol } from "../../utils/TableCols/DeviceCols";
import { useDispatch, useSelector } from "react-redux";
import { getLiveSessionHistory } from "../../Redux/actions/AdminDevicesAction";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { clearSearch } from "../../Redux/actions/GeneralAction";

// import { useDispatch } from "react-redux";

const DeviceSessionHistory = () => {
  const { gettingLiveSessionHistory, liveSessionHistory } = useSelector(
    (state) => state.Admin
  );
  const { Page, Name, duration } = useSelector((state) => state.Search);
  let allRows = getDeviceSessionHistoryRows(
    liveSessionHistory && liveSessionHistory.records
  );
  const dispatch = useDispatch();

  const data = {
    columns: deviceSessionHistoryCol,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);
  useEffect(() => {
    const queries = { Page, DeviceName: Name, Duration: duration };
    getLiveSessionHistory(dispatch, queries);
  }, [Page, Name, duration]);

  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              tableId={"devices-history-report"}
              currentPage={liveSessionHistory && liveSessionHistory.currentPage}
              pageCount={liveSessionHistory && liveSessionHistory.totalPages}
              data={data}
              header={
                <TableHeader
                  subHeader={options.liveSessionHistorySearch}
                  onClick={() => {}}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  searchvalue={Name}
                />
              }
              loading={gettingLiveSessionHistory}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no live sessions"}
                  // descr={"No Live Sessions available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceSessionHistory;
