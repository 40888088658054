import styled from "styled-components";
import { theme } from "../../theme";

export const StakeStatValuePre = styled.pre`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #29313c;
  margin-bottom: 10px;
`;

export const TopCard = styled.div`
  background: white;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  margin: 0 0 10px -10px;
`;

export const TableTitle = styled.p`
  color: ${theme.black};
  font-weight: ${theme.textBold};
  padding-top: 1rem;
  margin-bottom: 0;
`;