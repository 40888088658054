import _const from '../actions/Types'

const initialState = {
    referalCode: '',
    refered: [],
    refMsg: false,
    failedMsg: ''
}

const MarketerReducer = (state = initialState, action) => {
    switch (action.type) {

        case _const.UPDATE_REFERAL_CODE:
            return {
                ...state, referalCode: action.payload, refMsg: false, failedMsg: '', refered: []
            }

        case _const.GET_MARKETERS:
            return {
                ...state, refered: action.payload, refMsg: true,
            }

        case _const.GETTING_MARKETERS_FAILED:
            return {
                ...state, failedMsg: action.payload
            }

        default:
            return state
    }
}

export default MarketerReducer