export default {
  admin: {
    routes: [
      {
        component: "Dashboard",
        url: "/admin/dashboard",
      },
      {
        component: "StakeDashboard",
        url: "/admin/stake",
      },
      {
        component: "Withdrawals",
        url: "/requests",
      },
      {
        component: "User",
        url: "/manager",
      },
      {
        component: "CryptoWithdrawal",
        url: "/cryptowithdrawal",
      },
      {
        component: "UsersMap",
        url: "/transactions",
      },
      {
        component: "AllAgentDataUsage",
        url: "/dataUsage",
      },
      {
        component: "UserDetails",
        url: "/user/:userId",
      },
      {
        component: "UserTransactionDetails",
        url: "/user/:userId/transactionDetails",
      },
      {
        component: "UserSingleDeviceDetails",
        url: "/user/:userId/deviceDetails",
      },
      {
        component: "AdminTransactionDetails",
        url: "/admin/:userId/transactionDetails",
      },
      {
        component: "AdminSingleDeviceDetails",
        url: "/admin/:userId/deviceDetails",
      },
      {
        component: "AccountVerification",
        url: "/verification",
      },
      {
        component: "AccountVerificationDetail",
        url: "/oneverification/:id",
      },
      {
        component: "GetReferals",
        url: "/referals",
      },
      {
        component: "DataByDate",
        url: "/overview",
      },
      {
        component: "MerchantReg",
        url: "/registration",
      },
      {
        component: "AllBusinesses",
        url: "/businesses",
      },
      {
        component: "Upgrade",
        url: "/upgrade",
      },
      {
        component: "Transactions",
        url: "/transcOverview",
      },
      {
        component: "RemoteCommands",
        url: "/commands",
      },
      {
        component: "DeviceCommands",
        url: "/commands/:id",
      },
      {
        component: "StakingLog",
        url: "/stake/:stakingId"
      },
      {
        component: "DistributedRewardDetails",
        url: "/stake/reward/:disbursedRewardId"
      },
      {
        component: "SingleTransactionReport",
        url: "/transactionReport",
      },
      {
        component: "TrackVoucher",
        url: "/trackvoucher",
      },
      {
        component: "BusinessSummary",
        url: "/businessummary/:businessId",
      },
      {
        component: "BusinessSessions",
        url: "/sessions/:businessId",
      },
      {
        component: "AdminRemmittance",
        url: "/remittance",
      },
      {
        component: "AllSessions",
        url: "/allsessions",
      },
      {
        component: "Countries",
        url: "/countries",
      },
      {
        component: "ViewAdvert",
        url: "/viewadvert",
      },
      {
        component: "Subscriptions",
        url: "/subscriptions",
      },
      {
        component: "AdminToken",
        url: "/tokenreport",
      },
      {
        component: "AllUsers",
        url: "/wicryptusers",
      },
      {
        component: "Devices",
        url: "/devices",
      },
      {
        component: "DeviceSessionDetail",
        url: "/devices/details",
      },
      {
        component: "SingleDevice",
        url: "/devices/:deviceId",
      },
      {
        component: "DataTracker",
        url: "/devices/singledevice/trackdata/:id",
      },
      {
        component: "Admins",
        url: "/admins",
      },
      {
        component: "AdminDetails",
        url: "/admins/singleAdmin/:userId",
      },
      {
        component: "SpendControl",
        url: "/spendcontrol",
      },
      {
        component: "MinersReward",
        url: "/minersReward",
      },
      {
        component: "EmailProvider",
        url: "/settings"
      },
      {
        component: "ReconciliationDetails",
        url: "/reconcilliation/:date",
      }, 
      {
        component: "SwitchWalletReportDetail",
        url: "/switchwallet/report/:date",
      },
      {
        component: "Topics",
        url: "/topics"
      },
      {
        component: "SingleTopic",
        url: "/topics/:topicId"
      },
      {
        component: "NewTransactions",
        url: "/new-transactions",
      },
      {
        component: "NewSingleTransactionReportPage",
        url: "/transfer/:transactionId",
      },
      {
        component: "NewSingleTransactionReportP2PType",
        url: "/p2p/:transactionType/:transactionId",
      },
      {
        component: "NewSingleStakingPage",
        url: "/staking/:transactionId",
      },
      {
        component: "NewSingleStakeInterestClaimsPage",
        url: "/stake-claims/:transactionId",
      },
      {
        component: "DisbursedRewardDetailsPage",
        url: "/disbursed-rewards/:transactionId",
      },
      {
        component: "DisbursedStakingListPage",
        url: "/disbursed-staking/:transactionId",
      },
      {
        component: "NewSingleMiningReward",
        url: "/mining-rewards/:transactionId",
      },
      {
        component: "CryptoDepositDetailPage",
        url: "/crypto-deposit/:transactionId",
      },{
        component: "CryptoWithdrawalDetailPage",
        url: "/crypto-withdrawal/:transactionId",
      },
      {
        component: "RewardBooster",
        url: "/reward-booster",
      },
      {
        component: "CyclesDetailsPage",
        url: "/reward-booster/cycles/:cycleId",
      },
      {
        component: "LockupDetailsPage",
        url: "/reward-booster/lockup-requests/cycle/:cycleId",
      },
      {
        component: "Error",
        url: "*",
      },
    ],
  },
  operations: {
    routes: [
      {
        component: "UserDashboard",
        url: "/user/dashboard",
      },
      {
        component: "StakeDashboard",
        url: "/admin/stake",
      },
      {
        component: "Withdrawals",
        url: "/requests",
      },
      {
        component: "User",
        url: "/manager",
      },
      {
        component: "CryptoWithdrawal",
        url: "/cryptowithdrawal",
      },
      {
        component: "UsersMap",
        url: "/transactions",
      },
      {
        component: "AllAgentDataUsage",
        url: "/dataUsage",
      },
      {
        component: "UserDetails",
        url: "/user/:userId",
      },
      {
        component: "UserTransactionDetails",
        url: "/user/:userId/transactionDetails",
      },
      {
        component: "UserSingleDeviceDetails",
        url: "/user/:userId/deviceDetails",
      },
      {
        component: "AdminTransactionDetails",
        url: "/admin/:userId/transactionDetails",
      },
      {
        component: "AdminSingleDeviceDetails",
        url: "/admin/:userId/deviceDetails",
      },
      {
        component: "AccountVerification",
        url: "/verification",
      },
      {
        component: "AccountVerificationDetail",
        url: "/oneverification/:id",
      },
      {
        component: "GetReferals",
        url: "/referals",
      },
      {
        component: "DataByDate",
        url: "/overview",
      },
      {
        component: "MerchantReg",
        url: "/registration",
      },
      {
        component: "AllBusinesses",
        url: "/businesses",
      },
      {
        component: "Upgrade",
        url: "/upgrade",
      },
      {
        component: "Transactions",
        url: "/transcOverview",
      },
      {
        component: "RemoteCommands",
        url: "/commands",
      },
      {
        component: "DeviceCommands",
        url: "/commands/:id",
      },
      {
        component: "StakingLog",
        url: "/stake/:stakingId"
      },
      {
        component: "DistributedRewardDetails",
        url: "/stake/reward/:disbursedRewardId"
      },
      {
        component: "SingleTransactionReport",
        url: "/transactionReport",
      },
      {
        component: "TrackVoucher",
        url: "/trackvoucher",
      },
      {
        component: "BusinessSummary",
        url: "/businessummary/:businessId",
      },
      {
        component: "BusinessSessions",
        url: "/sessions/:businessId",
      },
      {
        component: "AdminRemmittance",
        url: "/remittance",
      },
      {
        component: "AllSessions",
        url: "/allsessions",
      },
      {
        component: "Countries",
        url: "/countries",
      },
      {
        component: "ViewAdvert",
        url: "/viewadvert",
      },
      {
        component: "Subscriptions",
        url: "/subscriptions",
      },
      {
        component: "AdminToken",
        url: "/tokenreport",
      },
      {
        component: "AllUsers",
        url: "/wicryptusers",
      },
      {
        component: "Devices",
        url: "/devices",
      },
      {
        component: "DeviceSessionDetail",
        url: "/devices/details",
      },
      {
        component: "SingleDevice",
        url: "/devices/:deviceId",
      },
      {
        component: "DataTracker",
        url: "/devices/singledevice/trackdata/:id",
      },
      {
        component: "SpendControl",
        url: "/spendcontrol",
      },
      {
        component: "MinersReward",
        url: "/minersReward",
      },
      {
        component: "ReconciliationDetails",
        url: "/reconcilliation/:date",
      },
      {
        component: "SwitchWalletReportDetail",
        url: "/switchwallet/report/:date",
      },
      {
        component: "Topics",
        url: "/topics"
      },
      {
        component: "Error",
        url: "*",
      },
    ],

    // routes: [
    //   {
    //     component: "UserDashboard",
    //     url: "/user/dashboard",
    //   },
    //   {
    //     component: "StakeDashboard",
    //     url: "/admin/stake",
    //   },
    //   {
    //     component: "Withdrawals",
    //     url: "/requests",
    //   },
    //   {
    //     component: "User",
    //     url: "/manager",
    //   },
    //   {
    //     component: "CryptoWithdrawal",
    //     url: "/cryptowithdrawal",
    //   },
    //   {
    //     component: "UsersMap",
    //     url: "/transactions",
    //   },
    //   {
    //     component: "AllAgentDataUsage",
    //     url: "/dataUsage",
    //   },
    //   {
    //     component: "UserDetails",
    //     url: "/user/:userId",
    //   },
    //   {
    //     component: "UserTransactionDetails",
    //     url: "/user/:userId/transactionDetails",
    //   },
    //   {
    //     component: "UserSingleDeviceDetails",
    //     url: "/user/:userId/deviceDetails",
    //   },
    //   {
    //     component: "AdminTransactionDetails",
    //     url: "/admin/:userId/transactionDetails",
    //   },
    //   {
    //     component: "AdminSingleDeviceDetails",
    //     url: "/admin/:userId/deviceDetails",
    //   },
    //   {
    //     component: "AccountVerification",
    //     url: "/verification",
    //   },
    //   {
    //     component: "AccountVerificationDetail",
    //     url: "/oneverification",
    //   },
    //   {
    //     component: "GetReferals",
    //     url: "/referals",
    //   },
    //   {
    //     component: "DataByDate",
    //     url: "/overview",
    //   },
    //   {
    //     component: "MerchantReg",
    //     url: "/registration",
    //   },
    //   {
    //     component: "AllBusinesses",
    //     url: "/businesses",
    //   },
    //   {
    //     component: "Upgrade",
    //     url: "/upgrade",
    //   },
    //   {
    //     component: "Transactions",
    //     url: "/transcOverview",
    //   },
    //   {
    //     component: "RemoteCommands",
    //     url: "/commands",
    //   },
    //   {
    //     component: "DeviceCommands",
    //     url: "/commands/:id",
    //   },
    //   {
    //     component: "StakingLog",
    //     url: "/stake/:stakingId"
    //   },
    //   {
    //     component: "SingleTransactionReport",
    //     url: "/transactionReport",
    //   },
    //   {
    //     component: "TrackVoucher",
    //     url: "/trackvoucher",
    //   },
    //   {
    //     component: "BusinessSummary",
    //     url: "/businessummary/:businessId",
    //   },
    //   {
    //     component: "BusinessSessions",
    //     url: "/sessions/:businessId",
    //   },
    //   {
    //     component: "AdminRemmittance",
    //     url: "/remittance",
    //   },
    //   {
    //     component: "AllSessions",
    //     url: "/allsessions",
    //   },
    //   {
    //     component: "Countries",
    //     url: "/countries",
    //   },
    //   {
    //     component: "ViewAdvert",
    //     url: "/viewadvert",
    //   },
    //   {
    //     component: "Subscriptions",
    //     url: "/subscriptions",
    //   },
    //   {
    //     component: "AdminToken",
    //     url: "/tokenreport",
    //   },
    //   {
    //     component: "AllUsers",
    //     url: "/wicryptusers",
    //   },
    //   {
    //     component: "Devices",
    //     url: "/devices",
    //   },
    //   {
    //     component: "DeviceSessionDetail",
    //     url: "/devices/details",
    //   },
    //   {
    //     component: "SingleDevice",
    //     url: "/devices/:deviceId",
    //   },
    //   {
    //     component: "DataTracker",
    //     url: "/devices/singledevice/trackdata/:id",
    //   },
    //   {
    //     component: "Admins",
    //     url: "/admins",
    //   },
    //   {
    //     component: "AdminDetails",
    //     url: "/admins/singleAdmin/:userId",
    //   },
    //   {
    //     component: "SpendControl",
    //     url: "/spendcontrol",
    //   },
    //   {
    //     component: "Error",
    //     url: "*",
    //   },
    // ],
    // routes: [
    //   {
    //     component: "UserDashboard",
    //     url: "/user/dashboard",
    //   },
    //   {
    //     component: "ManagerReferals",
    //     url: "/manager/referals",
    //   },
    //   {
    //     component: "AccountVerification",
    //     url: "/wicryptusers",
    //   },
    //   {
    //     component: "Devices",
    //     url: "/devices",
    //   },
    //   {
    //     component: "User",
    //     url: "/manager",
    //   },
    //   {
    //     component: "AffiliatedBusinesses",
    //     url: "/affilaited",
    //   },
    //   {
    //     component: "Upgrade",
    //     url: "/upgrade",
    //   },
    //   {
    //     component: "AgentVoucher",
    //     url: "/agent/vouchers",
    //   },
    //   {
    //     component: "AgentBusinessSessions",
    //     url: "/sessions",
    //   },
    //   {
    //     component: "AgentRemittance",
    //     url: "/agentremittance",
    //   },
    //   {
    //     component: "ManagedDevices",
    //     url: "/managedDevives",
    //   },
    //   {
    //     component: "AgentDataTracker",
    //     url: "/agentdatatracker",
    //   },
    //   {
    //     component: "BulkVouchers",
    //     url: "/bulk/vouchers",
    //   },
    //   {
    //     component: "VoucherBalance",
    //     url: "/voucher/balance",
    //   },
    //   {
    //     component: "AllSessions",
    //     url: "/allsessions",
    //   },
    //   {
    //     component: "AllBusinesses",
    //     url: "/businesses",
    //   },
    //   {
    //     component: "BusinessSummary",
    //     url: "/businessummary/:businessId",
    //   },
    //   {
    //     component: "BusinessSessions",
    //     url: "/sessions/:businessId",
    //   },
    //   {
    //     component: "AllBusinessAgents",
    //     url: "/businessagents",
    //   },
    //   {
    //     component: "OperationsBusinessReport",
    //     url: "/operation/businesssreport",
    //   },
    //   {
    //     component: "AccountVerificationDetail",
    //     url: "/oneverification",
    //   },
    //   {
    //     component: "BusinessAgentDataUsage",
    //     url: "/agent/dataUsage",
    //   },
    //   {
    //     component: "Wallets",
    //     url: "/wallets",
    //   },
    //   {
    //     component: "Error",
    //     url: "*",
    //   },
    // ],
  },
  technical: {
    routes: [
      {
        component: "UserDashboard",
        url: "/user/dashboard",
      },
      {
        component: "AdminSingleDeviceDetails",
        url: "/admin/:userId/deviceDetails",
      },
      {
        component: "AccountVerification",
        url: "/verification",
      },
      {
        component: "AccountVerificationDetail",
        url: "/oneverification/:id",
      },
      {
        component: "RemoteCommands",
        url: "/commands",
      },
      {
        component: "DeviceCommands",
        url: "/commands/:id",
      },
      {
        component: "SingleTransactionReport",
        url: "/transactionReport",
      },
      {
        component: "Devices",
        url: "/devices",
      },
      {
        component: "DeviceSessionDetail",
        url: "/devices/details",
      },
      {
        component: "SingleDevice",
        url: "/devices/:deviceId",
      },
      {
        component: "Error",
        url: "*",
      },
    ],
  },
  developer: {
    routes: [
      // {
      //   component: "UserDashboard",
      //   url: "/user/dashboard",
      // },
      {
        component: "Topics",
        url: "/topics"
      },
      {
        component: "SingleTopic",
        url: "/topics/:topicId"
      },
      {
        component: "Error",
        url: "*",
      },
    ],
  },
  redirect: {
    routes: [
      {
        component: "Error",
        url: "*",
      },
    ],
  },
};
