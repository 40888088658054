

import styled from "styled-components";
import { theme, device } from "../../theme";

export const SubTitle = styled.p`
    color: ${theme.grey};
    font-weight: ${theme.textBold};
    margin-bottom: 0;
` 
export const Note = styled.p`
    color: ${theme.yellow};
    margin-bottom: 2px;
    font-size: ${theme.textXs}
`
export const SubValue = styled.p`
    color: ${theme.blue};
    font-weight: ${theme.textXBold};
    font-size: ${theme.textMd};
`
export const SectionValue = styled.p`
    margin-top: 0.2rem;
    font-size: ${theme.textSm};
    margin-bottom: 0;
    font-weight: ${theme.textBold}

`
export const SpanSectionValue = styled.span`
    // margin-top: 0.2rem;
    font-size: ${theme.textXs};
    color: ${theme.mutedgrey}
    margin-bottom: 0;
    font-weight: ${theme.textBold}

`
export const SectionTitle = styled.p`
    margin-top: 1rem;
    font-size: ${theme.textXs};
    margin-top: 0;
    margin-bottom: 0;
    font-weight: ${theme.textBold}
`
export const SectionDescription = styled.p`
    margin-top: 1rem;
    font-size: ${theme.textXs};
    margin-top: 0;
    margin-bottom: 0;

`
export const Width30 = styled.div`
    width: 30%

`
export const Width60 = styled.div`
    width: 60%

`
export const Width10 = styled.div`
    width: 10%

`
export const BallanceSummary = styled.div`
    border-right: 1px solid #D2D2D2;
    margin-right: 20px;
    width: 30%
`
export const Width100 = styled.div`
    width: 100%;
    display: flex;
    align-items: center;  
    padding-bottom: 30px;
    border-bottom: ${props => props.last? "0px": "1px solid #DEDEDE"} ;
    margin-bottom: 20px;


`
export const OptionTitle = styled.p`
    font-weight: 600;
    font-size:  ${theme.textXs};
    color: ${theme.blue};
    margin-bottom: 0;
`
export const OptionSubTitle = styled.p`
    font-weight: 600;
    font-size:  ${theme.textXs};
    margin-bottom: 0;
    color: ${theme.grey};
`
export const FundBox = styled.div`  
    background: #FFFFFF;
    border-radius: 4px;
    height: 200px;
    font-weight: 400;
    position: relative;
    padding: 10px;
`
export const FundTitle = styled.p`
    font-size:  ${theme.textXs};
    font-weight: ${theme.textBold};
    padding: 20px;
    margin-bottom: 0px;
`
export const SpanTitle = styled.p`
    font-size:  ${theme.textXXs};
    margin-bottom: 0;
    font-weight: 600;
`
export const AccountNumber = styled.p`
    font-size: 1rem;
    color: #344684;
    font-weight: bold;    
`
export const SpanValue = styled.p`
    font-size:  ${theme.textXs};
    color: ${theme.black};
    text-transform: 'uppercase';

`
export const Err = styled.p`
    font-size: ${theme.textXs};
    color: red;
    padding: 5px;
    position: absolute;
    margin-bottom: 1rem;
`
export const Activate = styled.div`
    background: rgba(255, 255, 255, 0.08);
    border: 1.1727px solid #C8C8C8;
    box-sizing: border-box;
    border-radius: 23.454px;
    color: #979797;
    width: 150px;
    text-align: center;
    font-weight: 600;
    font-size:  ${theme.textXs};
    cursor: pointer;
    padding: 7px;
`
export const EmptyBusinessContainer = styled.div`
    padding: 20px;
    text-align: center;
    @media ${device.laptop}{
        padding: 150px;
    }
`
export const BagContainer = styled.div`
    margin-bottom: 50px;
`
export const OpenBusiness = styled.button`
    background-color: ${theme.blue} !important;
    color: ${theme.white} !important;
    padding: 10px !important;
    border-radius: 40px !important; 
`
export const WicryptPattern = styled.img`
    height: 150px;
    position: absolute;
    right: 0;
    opacity: 0.2;
    bottom: 0;
    z-index: 10;
`
export const TransactionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 0.1rem;
    @media ${device.laptop}{
        margin-right: 5rem;
    }
`
export const Funddescription = styled.p`
    margin-bottom: 0;
    font-size: 12px;
    color: #707070;
    font-weight: 200;
`
export const Wallettitle = styled.p`
    font-weight: 600;
    font-size: 14px;
    color: #000000;
`