import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  businessSessions,
  getFormDetails,
  getBusinessDevices,
  clearData,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import { numberWithCommas, precise_round } from "../../Helper";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import InfiniteScroll from "../Reusables/cards/LoadMore";
import moment from "moment";

class Summary extends Component {
  componentWillMount() {
    const {
      businessId,
      isActive,
      sessionDateStart,
      sessionDateEnd,
      sessionDeviceId,
      bizsessionSummary,
      role,
    } = this.props;
    var date = sessionDateStart;
    var starttimestamp = date.getTime();
    var date2 = sessionDateEnd;
    var endtimestamp = date2.getTime();
    if (bizsessionSummary.length === 0) {
      this.props.businessSessions(
        businessId,
        isActive,
        starttimestamp,
        endtimestamp,
        sessionDeviceId,
        1,
        50,
        role
      );
      this.props.getBusinessDevices(businessId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isActive,
      sessionDateStart,
      sessionDateEnd,
      sessionDeviceId,
      role,
    } = nextProps;
    var date = sessionDateStart;
    var starttimestamp = date.getTime();
    var date2 = sessionDateEnd;
    var endtimestamp = date2.getTime();
    if (
      isActive !== this.props.isActive ||
      sessionDateStart !== this.props.sessionDateStart ||
      sessionDateEnd !== this.props.sessionDateEnd ||
      sessionDeviceId !== this.props.sessionDeviceId
    ) {
      this.props.clearData();
      this.props.businessSessions(
        this.props.businessId,
        isActive,
        starttimestamp,
        endtimestamp,
        sessionDeviceId,
        1,
        50,
        role
      );
    }
  }

  fetchMoreData = () => {
    const {
      businessId,
      isActive,
      sessionDateStart,
      sessionDateEnd,
      sessionDeviceId,
      businessSessionsCurrentPage,
      businessSessionsTotalPages,
      role,
    } = this.props;
    var date = sessionDateStart;
    var starttimestamp = date.getTime();
    var date2 = sessionDateEnd;
    var endtimestamp = date2.getTime();
    let page = businessSessionsCurrentPage;
    if (page < businessSessionsTotalPages) {
      page++;
    }

    this.props.businessSessions(
      businessId,
      isActive,
      starttimestamp,
      endtimestamp,
      sessionDeviceId,
      page,
      50,
      role
    );
  };

  render() {
    const {
      bizsessionSummary,
      loadingSessions,
      conversionsRate,
      sessionDateStart,
      sessionDateEnd,
      businessDevices,
      businessSessionsTotalRecords,
    } = this.props;

    let allRows = [];
    bizsessionSummary.map((session) => {
      return allRows.push({
        name: session.client.userName,
        agent: session.agent.userName,
        device: session.sessionDevice.deviceName,
        rate:
          numberWithCommas(session.sessionDevice.rate * 1048576 * 360) +
          " NGN/GB ",
        dataUsedInBytes:
          precise_round(session.dataUsedInBytes / 1048576, 2) + "MB",
        receivedhost:
          "NGN " +
          numberWithCommas(session.amountReceivedByHost * conversionsRate),
        spenthost:
          "NGN " +
          numberWithCommas(session.amountSpentByClient * conversionsRate),
        clientopeningBalance:
          "NGN " +
          numberWithCommas(session.client.openingBalance * conversionsRate),
        clientclosingBalance:
          "NGN " +
          numberWithCommas(session.client.closingBalance * conversionsRate),
        voucherOpeningBalance:
          "NGN " +
          numberWithCommas(
            session.client.voucherOpeningBalance * conversionsRate
          ),
        voucherClosingBalance:
          "NGN " +
          numberWithCommas(
            session.client.voucherClosingBalance * conversionsRate
          ),
        hostopeningBalance:
          "NGN " +
          numberWithCommas(session.host.openingBalance * conversionsRate),
        hostclosingBalance:
          "NGN " +
          numberWithCommas(session.host.closingBalance * conversionsRate),
        wicryptCommision:
          "NGN " + numberWithCommas(session.wicryptCommision * conversionsRate),
        referralAmount:
          "NGN " + numberWithCommas(session.referralAmount * conversionsRate),
        serviceChargeToBeneficiary:
          "NGN " +
          numberWithCommas(
            session.serviceChargeToBeneficiary * conversionsRate
          ),
        averageDataUsagePerMinute:
          precise_round(session.averageDataUsagePerMinute / 1048576, 2) + "MB",
        sessionLastUpdateTimeStamp: moment
          .parseZone(session.sessionLastUpdateTimeStamp)
          .format("MMMM Do YYYY, h:mm a"),
      });
    });

    const data = {
      columns: [
        {
          label: "Client Name",
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Agent Username",
          field: "agent",
          sort: "asc",
          width: 100,
        },
        {
          label: "Device Name",
          field: "logo",
          sort: "asc",
          width: 100,
        },
        {
          label: "Device Rate",
          field: "rate",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data Used",
          field: "dataUsedInBytes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Amount Received by Host",
          field: "receivedhost",
          sort: "asc",
          width: 100,
        },
        {
          label: "Amount Spent by Client",
          field: "receivedhost",
          sort: "asc",
          width: 100,
        },
        {
          label: "Client Opening Balance",
          field: "clientopeningBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Client Closing Balance",
          field: "clientclosingBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Voucher Opening Balance",
          field: "voucherOpeningBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Voucher Closing Balance",
          field: "voucherClosingBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Host Opening Balance",
          field: "hostopeningBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Host Closing Balance",
          field: "hostclosingBalance",
          sort: "asc",
          width: 100,
        },
        {
          label: "Wicrypt Commission",
          field: "wicryptCommision",
          sort: "asc",
          width: 100,
        },
        {
          label: "Referral Amount",
          field: "referralAmount",
          sort: "asc",
          width: 100,
        },
        {
          label: "Service Charge",
          field: "serviceChargeToBeneficiary",
          sort: "asc",
          width: 100,
        },
        {
          label: "Average Data Usage Per Min",
          field: "averageDataUsagePerMinute",
          sort: "asc",
          width: 100,
        },
        {
          label: "Session Last UPdate",
          field: "sessionLastUpdateTimeStamp",
          sort: "asc",
          width: 100,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card-header mb-3" style={{ width: "100%" }}>
            <div className="">
              <Link to="/businesses">
                <img
                  height={30}
                  width={30}
                  src="/Assets/images/leftArrow.jpeg"
                  alt="Back arrow"
                />
              </Link>
              <br />
              <p className="trackers">
                {" "}
                {businessSessionsTotalRecords} SESSIONS{" "}
              </p>
            </div>
            <div className="mt-3 d-flex">
              <div className="dateRange">
                <div className="mr-3"> FROM</div>
                <DatePicker
                  className="dateRange"
                  selected={sessionDateStart}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["sessionDateStart"],
                      value: date,
                    })
                  }
                />
                <div className="mr-3 ml-3"> TO</div>
                <DatePicker
                  className="dateRange"
                  selected={sessionDateEnd}
                  onChange={(date) =>
                    this.props.getFormDetails({
                      props: ["sessionDateEnd"],
                      value: date,
                    })
                  }
                />
              </div>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["isActive"],
                    value: e.target.value,
                  })
                }
              >
                <option value={"True"}>Active Sessions</option>
                <option value={"False"}> Inactive Sessions</option>
              </select>
              <select
                className="input-sm form-control m-10"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["sessionDeviceId"],
                    value: e.target.value,
                  })
                }
              >
                <option value=''>Select Device</option>
                {businessDevices.map((each) => {
                  return <option value={each.id}>{each.name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="row">
            {loadingSessions & (bizsessionSummary.length === 0) ? (
              <div className="volucherNote mt-5 t-center">
                <Loader size={10} />{" "}
              </div>
            ) : (
                <div className="col-lg-12 mb-4">
                  {bizsessionSummary.length > 0 ? (
                    <div className="card card-small mb-4 dataTable">
                      <InfiniteScroll
                        dataLength={bizsessionSummary.length}
                        hasMore={
                          bizsessionSummary.length < businessSessionsTotalRecords
                            ? true
                            : false
                        }
                        items={
                          <div className="card card-small mb-4 dataTable">
                            <MDBDataTable
                              striped
                              hover
                              data={data}
                              searchLabel="Search"
                              responsive
                            />
                          </div>
                        }
                        moreData={this.fetchMoreData}
                      />
                    </div>
                  ) : (
                      <p className="voucherText t-center">
                        {" "}
                    No sessions report for the selected specs
                      </p>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { loadingSessions } = state.Loading;
  const {
    bizsessionSummary,
    conversionsRate,
    businessSessionsTotalRecords,
    businessSessionsCurrentPage,
    businessSessionsTotalPages,
  } = state.Report;
  const {
    sessionDateStart,
    sessionDateEnd,
    isActive,
    sessionDeviceId,
    myBusinessId,
    role,
  } = state.User;
  const { managerDevices, businessDevices } = state.Manager;
  return {
    loadingSessions,
    bizsessionSummary,
    conversionsRate,
    sessionDateStart,
    sessionDateEnd,
    isActive,
    role,
    businessSessionsTotalRecords,
    businessSessionsCurrentPage,
    businessSessionsTotalPages,
    sessionDeviceId,
    managerDevices,
    myBusinessId,
    businessDevices,
  };
};

export default connect(
  MapStateToProps,
  { businessSessions, getFormDetails, getBusinessDevices, clearData }
)(Summary);
