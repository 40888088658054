

export const requestHeaderOptions = [
  {
    id: 1,
    title: "Duration",
    type: "select",
    onSelectProp: "duration",
    options: [
      { id: 1, option: "All" },
      { id: 2, option: "Last Week" },
      { id: 3, option: "Last Month" },
      { id: 4, option: "Last Year" },
    ],
  },
  {
    id: 2,
    title: "Search",
    type: "text",
    placeholder: "Enter name or email",
    onChangeProp: "search",
  },
];

export const allUsersHeaderOptionTypes = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Blocked",
  },
];
