import React, { useEffect } from "react";
import CustomDataTable from "../../Reusables/tables/CustomDataTable";
import TableHeader from "../../Reusables/tables/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal, getSearchDetails, clearSearch,   changeTransactionType,
  getAdminTransactions,} from "../../../Redux/actions";
import { getTransactionRows } from "../../../utils/TableRows/transactionsTableRows";
import EmptyCard from "../../Reusables/cards/EmptyCard";
import {
  cryptoColumns,
  miningRewardColumns,
  miningRewardsOptions,
  p2pColumns,
  p2pOptions,
  transactionsCommonColumns,
  transactionsHeaderOptionTypes,
  transactionsOptions,
  transferOptions,
} from "../../UserDetails/options";
import { useState } from "react";
import { OrderConfirmationDetail } from "../P2P/orderConfirmation";
import UserTransactionReportTest from "./SingleTransactionReportTest";

const TransactionsReport = () => {
  const { activeTab } = useSelector((state) => state.User);
  const [copied, setCopied] = useState(false);
  const [currentRow, setCurrentRow] = useState(1);
  const [type, setType] = useState("address");

  const [ transactionDetails, setTransactionDetails ] = useState({});

  const {
    adminTransactions,
    transactionType,
    gettingAdminTransactions,
  } = useSelector((state) => state.Transactions);
  const {
    TransactionType,
    TransferType,
    duration,
    Status,
    Page,
    PageSize,
    WalletType,
    ReferenceCode,
    userId,
  } = useSelector((state) => state.Search);
  const dispatch = useDispatch();

  let allRows = getTransactionRows(
    adminTransactions ? adminTransactions.records : [],
    dispatch,
    "",
    copied,
    setCopied,
    currentRow,
    setCurrentRow,
    type,
    setType,
    activeTab,
    setTransactionDetails
  );
  const data = {
    columns:
      activeTab === 2
        ? cryptoColumns
        : activeTab === 3
        ? p2pColumns
        : activeTab === 5
        ? miningRewardColumns
        : transactionsCommonColumns,
    rows: allRows,
  };

  const fetchAdminTransactions = () => {
    const queries = {
      TransactionType,
      TransferType,
      Duration: duration,
      Status,
      Page,
      PageSize,
      WalletType,
      ReferenceCode,
      userId,
    };
    getAdminTransactions(dispatch, transactionType, queries);
  }

  useEffect(() => {
    fetchAdminTransactions();
  }, [
    transactionType,
    TransactionType,
    duration,
    Status,
    Page,
    PageSize,
    WalletType,
    // ReferenceCode,
    TransferType,
  ]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(ReferenceCode === ""){
      fetchAdminTransactions();
    }
  },[ReferenceCode])

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"transactions-report"}
              pageCount={adminTransactions.totalPages}
              currentPage={adminTransactions.currentPage}
              header={
                <TableHeader
                  subHeaderTypes={transactionsHeaderOptionTypes}
                  dataLength={adminTransactions.records ? adminTransactions.records.length : 0}
                  subHeader={
                    activeTab === 5
                      ? miningRewardsOptions
                      : activeTab === 3
                      ? p2pOptions
                      : activeTab === 4
                      ? transferOptions
                      : transactionsOptions
                  }
                  ontabClick={(type) => {
                    dispatch(clearSearch());
                    dispatch(changeTransactionType(type));
                  }}
                  onClick={() => {
                    dispatch(toggleModal(true));
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onSearchClick={fetchAdminTransactions}
                  // searchvalue={ReferenceCode}
                />
              }
              loading={gettingAdminTransactions}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  title={"Sorry,  you have no transaction history"}
                  // descr={"No transaction history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
      {transactionDetails && transactionDetails.id && <UserTransactionReportTest id={60} transactionDetails={transactionDetails} />}
    </div>
  );
};

export default TransactionsReport;
