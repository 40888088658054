import React, { Component } from "react";
import CustomModal from "../Reusables/modals/CustomModal";
import {
  AddSelect,
  Select,
  BigInput,
  ContinueBtn,
  LayeredInput
} from "../Reusables/inputs";
import {
  OptionTitle,
  OptionSubTitle,
  Err,
  FundBox,
  FundTitle,
  Note
} from "./walletstyles";
import {
  getFormDetails,
  getBanks,
  makeWithdrawal,
  getAcctName,
  getWithdrawalToken,
  handleModal
} from "../../Redux/actions";
import { connect } from "react-redux";
import { numberWithCommas, validateamount } from "../../Helper";
import Loader from "../Reusables/loaders/MoonLoader";

class Withdraw extends Component {
  constructor() {
    super();
    this.state = {
      count: 0
    };
  }

  componentWillMount() {
    this.props.getBanks();
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.accountNumber.length === 10) &
      (this.state.count === 0) &
      (nextProps.bankId !== 0)
    ) {
      this.setState({ count: 1 });
      this.props.getAcctName(nextProps.accountNumber, nextProps.bankId);
    }
    if ((nextProps.accountNameError !== "") & (this.state.count === 1)) {
      this.setState({ count: 0 });
    }
  }

  handleselect(value) {
    const { toggleDropdown } = this.props;
    this.props.getFormDetails({
      props: ["toggleDropdown"],
      value: !toggleDropdown
    });
    this.props.getFormDetails({
      props: ["selectedwalletoption"],
      value: value
    });
  }

  handlecontinue() {
    const {
      amtToWithdraw,
      selectedwalletoption,
      balance,
      businessBalance,
      bankId,
      currencyValue
    } = this.props;
    if (amtToWithdraw === 0 || !validateamount(amtToWithdraw)) {
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value: "please enter a valid amount"
      });
    } else if (
      (selectedwalletoption === "Personal") &
      (amtToWithdraw > balance * currencyValue)
    ) {
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value:
          "You cannot withdraw an amount greater than your personal balance"
      });
    } else if (
      (selectedwalletoption === "Business") &
      (amtToWithdraw > businessBalance * currencyValue)
    ) {
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value:
          "You cannot withdraw an amount greater than your business balance"
      });
    } else if (amtToWithdraw < 720) {
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value: "Your mimimum withdrawable amount is 720 NGN"
      });
    } else if (bankId === 0) {
      this.props.getFormDetails({
        props: ["bankAcctError"],
        value: "Please select a bank account to proceed"
      });
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value: ""
      });
    } else {
      this.props.getFormDetails({
        props: ["withdrawalAmtError"],
        value: ""
      });
      this.props.getFormDetails({
        props: ["bankAcctError"],
        value: ""
      });
      this.props.handleModal(7);
    }
  }
  handlenext() {
    const { accountNumber } = this.props;
    if (accountNumber.length < 10) {
      this.props.getFormDetails({
        props: ["accountNumberError"],
        value: "Please enter a valid account number"
      });
    } else {
      this.props.handleModal(8);
    }
  }

  handlewithdrawal() {
    const {
      accountNumber,
      amtToWithdraw,
      narration,
      bankId,
      withdrawalPin,
      withdrawalOtp,
      selectedwalletoption
    } = this.props;
    if (withdrawalPin.length !== 4) {
      this.props.getFormDetails({
        props: ["withdrawalpinError"],
        value: "Please enter your wicrypt pin."
      });
    } else if (withdrawalOtp.length < 0) {
      this.props.getFormDetails({
        props: ["withdrawalOtpError"],
        value: "Please enter the otp that was sent to you."
      });
    } else {
      this.props.makeWithdrawal(
        bankId,
        accountNumber,
        amtToWithdraw,
        narration,
        "NGN",
        withdrawalPin,
        withdrawalOtp,
        selectedwalletoption
      );
    }
  }

  handlebankselect(value) {
    const { bankdropdown } = this.props;
    this.props.getFormDetails({
      props: ["bankdropdown"],
      value: !bankdropdown
    });
    this.props.getFormDetails({
      props: ["bankId"],
      value: value.id
    });
    this.props.getFormDetails({
      props: ["selectedbank"],
      value: value.name
    });
  }

  render() {
    const {
      amtToWithdraw,
      narration,
      sendingOtp,
      selectedbank,
      bankdropdown,
      allBanks,
      gettingAcctName,
      accountName,
      walletoptions,
      toggleDropdown,
      selectedwalletoption,
      balance,
      currencyValue,
      withdrawalOtpError,
      businessBalance,
      makingWithdrawal,
      withdrawalPin,
      withdrawalOtp,
      accountNameError,
      maxWithdrable,
      otpError,
      personaloptions,
      role,
      withdrawalError,
      accountNumber,
      withdrawalAmtError,
      bankAcctError,
      withdrawalpinError,
      accountNumberError
    } = this.props;
    let options = role.includes("Business") ? walletoptions : personaloptions;
    return (
      <React.Fragment>
        <CustomModal
          id={101}
          heading=""
          close={true}
          content={
            <React.Fragment>
              <div className="stats-small stats-small--1 card card-small">
                <div className="card-body d-flex mb-5">
                  <div className="d-flex flex-column m-auto">
                    <img
                      src="https://cdn1.iconfinder.com/data/icons/color-bold-style/21/08-512.png"
                      className="mt-5"
                      width="50px"
                      height="50px"
                      alt="success"
                    />
                    <p className="text-red mt-4 mb-5"> {withdrawalError}</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        />
        <CustomModal
          id={10}
          heading=""
          close={true}
          content={
            <React.Fragment>
              <div className="stats-small stats-small--1 card card-small">
                <div className="card-body d-flex mb-5">
                  <div className="d-flex flex-column m-auto">
                    <img
                      src="/Assets/check.svg"
                      className="mt-5"
                      width="100%"
                      height="70px"
                      alt="success"
                    />
                    <p className="text-green mt-4 mb-5">
                      {" "}
                      Withdrawal initiated Successfully
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        />
        <CustomModal
          id={9}
          heading={
            <React.Fragment>
              {" "}
              <i
                onClick={() => this.props.handleModal(8)}
                className="fa fa-arrow-left pointer"
                aria-hidden="true"
              ></i>{" "}
              Continue Withdrawal{" "}
            </React.Fragment>
          }
          close={true}
          content={
            <React.Fragment>
              <LayeredInput
                placeholder=""
                title="OTP"
                description="Enter otp that was sent to your email address"
                value={withdrawalOtp}
                type="password"
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["withdrawalOtp"],
                    value: e.target.value
                  })
                }
                err={withdrawalOtpError}
              />
              <LayeredInput
                placeholder=""
                title="Pin"
                description="Enter your pin"
                value={withdrawalPin}
                type="password"
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["withdrawalPin"],
                    value: e.target.value
                  })
                }
                maxlength={4}
                err={withdrawalpinError}
              />
              <LayeredInput
                placeholder=""
                title="Narration"
                description="Leave a note to describe your withdrawal"
                value={narration}
                type="text"
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["narration"],
                    value: e.target.value
                  })
                }
                err={""}
              />

              {makingWithdrawal ? (
                <ContinueBtn title={<Loader />} />
              ) : (
                <ContinueBtn
                  title="Continue"
                  onClick={() => this.handlewithdrawal()}
                />
              )}
            </React.Fragment>
          }
        />
        <CustomModal
          id={8}
          heading={
            <React.Fragment>
              {" "}
              <i
                onClick={() => this.props.handleModal(7)}
                className="fa fa-arrow-left pointer"
                aria-hidden="true"
              ></i>{" "}
              Confirm Withdrawal{" "}
            </React.Fragment>
          }
          close={true}
          content={
            <React.Fragment>
              <FundBox>
                <FundTitle>
                  You are about to withdraw{" "}
                  {"NGN " + numberWithCommas(amtToWithdraw)} to {accountNumber}{" "}
                  with account name {accountName}
                </FundTitle>
                <div className="d-flex">
                  <ContinueBtn
                    width={"150px"}
                    color="grey"
                    title={"Close"}
                    onClick={() => this.props.handleModal(0)}
                  />
                  <ContinueBtn
                    width={"150px"}
                    title={sendingOtp ? <Loader /> : "Continue"}
                    onClick={() => this.props.getWithdrawalToken()}
                  />
                </div>
              </FundBox>
              <Err> {otpError}</Err>
            </React.Fragment>
          }
        />
        <CustomModal
          id={7}
          heading={
            <React.Fragment>
              {" "}
              <i
                onClick={() => this.props.handleModal(2)}
                className="fa fa-arrow-left pointer"
                aria-hidden="true"
              ></i>{" "}
              Add Bank Details{" "}
            </React.Fragment>
          }
          close={true}
          content={
            <React.Fragment>
              <LayeredInput
                placeholder=""
                title="Account Number"
                description="Enter your account number"
                value={accountNumber}
                type="number"
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["accountNumber"],
                    value: e.target.value
                  })
                }
                err={accountNumberError}
              />
              <LayeredInput
                placeholder=""
                title="Account Name"
                description="This is your account name"
                value={accountName}
                disabled={true}
                type="text"
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["accountName"],
                    value: e.target.value
                  })
                }
                err={accountNameError}
                loading={gettingAcctName}
              />

              <ContinueBtn title="Continue" onClick={() => this.handlenext()} />
            </React.Fragment>
          }
        />
        <CustomModal
          heading="Withdraw"
          id={2}
          close={true}
          content={
            <React.Fragment>
              {selectedwalletoption === "Personal" ? (
                <Note>
                  {" "}
                  N/B: You can only withdraw your profit: NGN{" "}
                  {numberWithCommas(maxWithdrable * currencyValue)} from your
                  personal wallet
                </Note>
              ) : (
                ""
              )}
              <Select
                title={
                  role.includes("Business")
                    ? "Choose wallet to withdraw from"
                    : "Withdraw from your personal wallet"
                }
                onOptionSelect={this.handleselect.bind(this)}
                name={"withdrawwallet"}
                value={
                  <div>
                    <OptionTitle> {selectedwalletoption}</OptionTitle>
                    <OptionSubTitle>
                      {" "}
                      {selectedwalletoption === "Personal"
                        ? "NGN " + numberWithCommas(balance * currencyValue)
                        : "NGN " +
                          numberWithCommas(businessBalance * currencyValue)}
                    </OptionSubTitle>
                  </div>
                }
                disabled={false}
                err={""}
                show={!role.includes("Business") ? true : false}
                options={options}
                toggleDropdown={
                  role.includes("Business") ? toggleDropdown : false
                }
                onDropdownReveal={() =>
                  this.props.getFormDetails({
                    props: ["toggleDropdown"],
                    value: !toggleDropdown
                  })
                }
              />
              <BigInput
                title="Click to enter amount"
                type="number"
                value={amtToWithdraw}
                onChange={e =>
                  this.props.getFormDetails({
                    props: ["amtToWithdraw"],
                    value: e.target.value
                  })
                }
                err={withdrawalAmtError}
              />
              <AddSelect
                title={"Bank account"}
                name={"bankaccount"}
                value={
                  <div>
                    <OptionSubTitle> {selectedbank}</OptionSubTitle>
                  </div>
                }
                options={allBanks}
                err={bankAcctError}
                onDropdownReveal={() =>
                  this.props.getFormDetails({
                    props: ["bankdropdown"],
                    value: !bankdropdown
                  })
                }
                toggleDropdown={bankdropdown}
                onOptionSelect={this.handlebankselect.bind(this)}
              />
              <ContinueBtn
                onClick={() => this.handlecontinue()}
                title="Continue"
              />
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const MapstateToProps = state => {
  const {
    amtToWithdraw,
    transferrecipientemail,
    walletoptions,
    toggleDropdown,
    selectedwalletoption,
    selectedbank,
    otpError,
    withdrawalAmtError,
    withdrawalpinError,
    narration,
    balance,
    currencyValue,
    businessBalance,
    withdrawalPin,
    accountNumber,
    withdrawalOtp,
    bankdropdown,
    bankId,
    bankAcctError,
    accountNumberError,
    withdrawalOtpError,
    role,
    personaloptions
  } = state.User;
  const { makingWithdrawal, gettingAcctName, sendingOtp } = state.Loading;
  const {
    allBanks,
    accountName,
    accountNameError,
    otpSent,
    withdrawalError
  } = state.Manager;
  const { maxWithdrable } = state.Wallet;
  return {
    amtToWithdraw,
    transferrecipientemail,
    walletoptions,
    toggleDropdown,
    selectedwalletoption,
    balance,
    currencyValue,
    businessBalance,
    makingWithdrawal,
    withdrawalPin,
    allBanks,
    accountNumber,
    accountName,
    accountNameError,
    otpSent,
    withdrawalError,
    withdrawalOtp,
    gettingAcctName,
    bankdropdown,
    selectedbank,
    bankId,
    narration,
    sendingOtp,
    otpError,
    bankAcctError,
    accountNumberError,
    withdrawalAmtError,
    withdrawalpinError,
    withdrawalOtpError,
    personaloptions,
    role,
    maxWithdrable
  };
};

export default connect(
  MapstateToProps,
  {
    getFormDetails,
    getBanks,
    makeWithdrawal,
    getAcctName,
    getWithdrawalToken,
    handleModal
  }
)(Withdraw);
