import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStakeLog, copyToClipboard } from "../../Redux/actions";
import { shortenAddress, numberWithCommas } from "../../Helper";
import {
    SummaryTitle,
    SubTitle,
    SummaryValue,
    SummaryCard,
    LogList,
    LogDetails,
    LogStep
} from "./style";
import moment from "moment";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import config from "../../Redux/reducers/config"


const StakeLog = () => {

    const dispatch = useDispatch();

    const { stakeLog } = useSelector((state) => state.Stake);
    const { copied } = useSelector((state) => state.User);
    const { gettingStakeLog } = useSelector((state) => state.Loading);



    const stakingId = window.location.pathname.split("/").at(-1);


    useEffect(() => {
        dispatch(getStakeLog(stakingId))
    }, [stakingId]);

    function capitalizeFirstLetter(string) {
        if(string){
            return string.charAt(0).toUpperCase() + string.substring(1);
        }
        return;
    }


    return (
        <div className=" mb-4">
            <div className="card card-small mb-4 w3-animate-bottomd">
                {gettingStakeLog ? <CustomLoader /> : 
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <SummaryCard className="card">
                            <div className="card-header">
                                <div className="col-lg-12">
                                    <SummaryTitle> {capitalizeFirstLetter(stakeLog.transactionType)} Summary</SummaryTitle>
                                </div>
                                <div className="col-lg-12 d-flex">
                                    <div classNme="col-lg-3">
                                        <SubTitle> Full Name</SubTitle>
                                        <SummaryValue >
                                            {" "}
                                            {stakeLog.fullName}
                                        </SummaryValue>
                                        <p> {stakeLog.email}</p>
                                    </div>
                                    <div classNme="col-lg-3">
                                        <SubTitle> Duration</SubTitle>
                                        <SummaryValue>
                                            {" "}
                                            {stakeLog.duration}
                                        </SummaryValue>
                                    </div>
                                    <div classNme="col-lg-3">
                                        <SubTitle> Amount</SubTitle>
                                        <SummaryValue>
                                            {" "}
                                            {numberWithCommas(stakeLog.amount)} WNT
                                        </SummaryValue>
                                    </div>
                                    <div classNme="col-lg-3">
                                        <SubTitle> Transaction</SubTitle>
                                        <SummaryValue className={stakeLog.transactionStatus}>
                                            {" "}
                                            {stakeLog.transactionStatus}
                                        </SummaryValue>
                                        <SummaryValue>
                                            {" "}
                                           <a href={`https://polygonscan.com/tx/${stakeLog.transactionHash}`} target="_blank" rel="noopener noreferrer"> {shortenAddress(stakeLog.transactionHash)}{" "}</a> 
                                           
                                        </SummaryValue>
                                    </div>
                                    <div classNme="col-lg-3">
                                        <SubTitle> Wallet Address</SubTitle>
                                        <SummaryValue>
                                            {" "}
                                            {shortenAddress(stakeLog.walletAddress)}{" "}
                                            <span>
                                                {" "}
                                                <i
                                                    onClick={() =>
                                                        dispatch(copyToClipboard(stakeLog.walletAddress))
                                                    }
                                                    className="material-icons text-orange pointer"
                                                >
                                                    file_copy
                                                </i>{" "}
                                                {copied === stakeLog.walletAddress ? <p className="copied"> copied </p> : ""}
                                            </span>
                                        </SummaryValue>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                            </div>
                        </SummaryCard>
                    </div>
                    <div className="col-lg-12">
                        <ul>
                            {stakeLog.stakingActivity && stakeLog.stakingActivity.map(each => {
                                return (<LogList>
                                    <LogStep> {each.stakingStep} | <span>{moment(each.activityTime).format('DD-MM-YY, h:mm:ss a')}</span></LogStep>
                                    <LogDetails className={each.status}> {each.status}</LogDetails>
                                    <LogDetails> {each.failureReason}</LogDetails>
                                    <LogDetails> {each.metadata ? "Amount Staked: " + numberWithCommas(stakeLog.amount) + " WNT" : ""} </LogDetails>
                                    <LogDetails> {each.metadata ? "Matic sent for Gas: " + numberWithCommas(each.metadata.maticSentForGas) + " MATIC" : ""} </LogDetails>
                                    <LogDetails> {each.metadata ? "WNT Sent: " + each.metadata.wntSent + " WNT" : ""} </LogDetails>
                                    <LogDetails> {each.metadata ? "Transaction Hash: " : "" } {each.metadata ? 
                                     <a target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="color-blue text-bold" 
                                        href ={`${config.polygonexplorerUrl}/tx/${each.metadata.transactionHash}`}> 
                                        {shortenAddress(each.metadata.transactionHash)} 
                                    </a> : ""}
                                    {/* {each.metadata &&
                                        <span>
                                            {" "}
                                            <i
                                                onClick={() =>
                                                    dispatch(copyToClipboard(each.metadata.transactionHash))
                                                }
                                                className="material-icons text-orange pointer"
                                            >
                                                file_copy
                                            </i>{" "}
                                            {copied === each.metadata.transactionHash && each.metadata.transactionHash ? <p className="copied"> copied </p> : ""}
                                        </span>} */}
                                    </LogDetails>
                                </LogList>)
                            })}


                        </ul>
                    </div>
                </div>}
            </div>


        </div>
    );
};

export default StakeLog;
