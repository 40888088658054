import React, { Component } from 'react';
import {
    getFormDetails,
    createsubscriptionplans,
    toggleModal,
    getCountries
} from "../../Redux/actions";
import { connect } from "react-redux";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";

class CreateSubscription extends Component {

    componentWillMount() {
        this.props.getCountries(1, 100, "");
    }


    render() {
        const { subscriptionname, subscriptionmonthlyfee, subscriptionyearlyfee, minimumdevices, maximumdevices, subscriptionerr, allcountries, selectedcountrycode, creatingsubscriptionplans } = this.props;
        let createdata = {
            "name": subscriptionname,
            "monthlyFee": subscriptionmonthlyfee,
            "yearlyFee": subscriptionyearlyfee,
            "countryCode": selectedcountrycode,
            "minimumDevices":minimumdevices,
            "maximumDevices": maximumdevices,
        }
        let noneservererror = ["", 1, 2, 3, 4]
        return (
            <Modal heading={"Create Subscription"}>

                <label> Subscription Name</label>
                <div className="input-group mb-2">
                    <input
                        type="text"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={subscriptionname}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionname"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                {subscriptionerr === 1 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Subscription name is required
                    </p>
                ) : (
                        ""
                    )}
                <label> Monthly Fee</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={subscriptionmonthlyfee}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionmonthlyfee"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                {subscriptionerr === 2 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Monthly Fee is required
                    </p>
                ) : (
                        ""
                    )}
                <label> Yearly Fee</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={subscriptionyearlyfee}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionyearlyfee"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                
                {subscriptionerr === 3 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Yearly fee is required
                    </p>
                ) : (
                        ""
                    )}
                    <label> Maximum Devices</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={maximumdevices}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["maximumdevices"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <label> Minimum Devices</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        value={minimumdevices}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["minimumdevices"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <label> Select Country</label>
                <select
                  className="form-control mr-2 mt-2 loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["selectedcountrycode"],
                      value: e.target.value,
                    })
                  }
                >
                  {allcountries.map((country) => {
                    return (
                      <option key={country.isoTwoLetterCode} value={country.isoTwoLetterCode}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
                {!noneservererror.includes(subscriptionerr) ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        {subscriptionerr}
                    </p>
                ) : (
                        ""
                    )}
                <Button
                    theme="wicryptColor ml-3 mt-2 float"
                    buttonAction={() =>
                        this.props.createsubscriptionplans(
                            createdata
                        )
                    }
                >
                    {creatingsubscriptionplans ? <Loader /> : "Enter"}
                </Button>
            </Modal>
        )
    }
}

const MapStateToProps = (state) => {
    const { subscriptionname, subscriptionmonthlyfee, subscriptionyearlyfee, maximumdevices, minimumdevices, selectedcountrycode } = state.User;
    const { creatingsubscriptionplans } = state.Loading;
    const { subscriptionerr } = state.Subscription;
    const {allcountries} = state.Countries;
    return {
        subscriptionname,
        subscriptionmonthlyfee,
        subscriptionyearlyfee,
        selectedcountrycode,
        creatingsubscriptionplans,
        subscriptionerr,
        allcountries,
        minimumdevices,
        maximumdevices
    };
};

export default connect(MapStateToProps, {
    getFormDetails,
    createsubscriptionplans,
    toggleModal,
    getCountries
})(CreateSubscription);