import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import StakingStatistics from "./StakingStatistics";
import ProposedReward from "./ProposedReward";
import UploadedRewards from "./UploadedRewards";

const MinersReward = () => {
  let minersDetail = [
    { id: 1, item: "Staking Statistics" },
    { id: 2, item: "Proposed Reward" },
    { id: 3, item: "Uploaded Rewards" }
  ];
  let nonTreasures = [
    { id: 1, item: "Staking Statistics" }
  ]

  const { toggleSubnav } = useSelector((state) => state.General);
  const { role } = useSelector((state) => state.User);
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Miners Reward" listItems={role.includes("Treasurer") || role.includes("Admin") ? minersDetail : nonTreasures} />
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <BreadCrumbs />
          <SubSidenav />
          {toggleSubnav === 1 ? <StakingStatistics /> : toggleSubnav === 2 ? <ProposedReward /> : <UploadedRewards />}
        </div>
      </MainContent>
    </div>
  );
};

export default MinersReward;
