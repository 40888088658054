import React, { Component } from "react";
import { connect } from "react-redux";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAgentBizSummary, getFormDetails } from "../../Redux/actions";
import moment from "moment";
import Loader from "../Reusables/loaders/MoonLoader";
import AgentCard from "../AgentReport/AgentCard";
import { numberWithCommas } from "../../Helper";
import filesize from "filesize";
import { withRouter } from "react-router-dom";

class Affiliated extends Component {
  // componentWillMount(){
  //     this.props.getAgentBizSummary( this.props.selectedAffiliatedBusiness, this.props.userId, moment(this.props.deviceStart).format('YYYY-MM-DD'),
  //     moment(this.props.deviceEnd).format('YYYY-MM-DD'))
  // }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.deviceStart !== this.props.deviceStart ||
      nextProps.deviceEnd !== this.props.deviceEnd ||
      nextProps.selectedAffiliatedBusiness !==
        this.props.selectedAffiliatedBusiness
    ) {
      this.props.getAgentBizSummary(
        nextProps.selectedAffiliatedBusiness,
        this.props.userId,
        moment(nextProps.deviceStart).format("YYYY-MM-DD"),
        moment(nextProps.deviceEnd).format("YYYY-MM-DD")
      );
    }
  }

  render() {
    const {
      deviceStart,
      deviceEnd,
      gettingAgentBiz,
      agentBizSummary,
      affiliatedBusiness,
    } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card-header p-r mb-3">
            <h6 className="m-0">DEVICE REPORT</h6>

            <div className="mt-3 d-flex">
              <div className="mr-3"> FROM</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["deviceStart"],
                    value: e,
                  })
                }
                selected={deviceStart}
                dateFormat={"dd/MM/yyyy"}
              />

              <div className="mr-3 ml-3"> TO</div>
              <Datepicker
                className="dateRange form-control"
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["deviceEnd"],
                    value: e,
                  })
                }
                selected={deviceEnd}
                dateFormat={"dd/MM/yyyy"}
              />

              {/* </div> */}
              <select
                className="input-sm form-control"
                style={{ width: "auto" }}
                onChange={(e) =>
                  this.props.getFormDetails({
                    props: ["selectedAffiliatedBusiness"],
                    value: e.target.value,
                  })
                }
              >
                <option>Select Business</option>
                {affiliatedBusiness.map((business) => {
                  return (
                    <option value={business.business.id}>
                      {business.business.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row">
            {(agentBizSummary.length > 0) & gettingAgentBiz ? (
              <div className="volucherNote">
                <Loader size={10} />{" "}
              </div>
            ) : agentBizSummary.length > 0 ? (
              agentBizSummary.map((agent) => {
                return (
                  <AgentCard
                    key={agent.id}
                    agentName={
                      agent.deviceName === null ? "Unnamed" : agent.deviceName
                    }
                    revenue={"NGN" + numberWithCommas(agent.totalRevenue * 360)}
                    usage={filesize(agent.totalUsage)}
                    sessions={agent.sessionsCount}
                  />
                );
              })
            ) : (
              <div className="volucherNote">
                {gettingAgentBiz ? (
                  <Loader size={10} />
                ) : (
                  <p className="voucherText">
                    {" "}
                    Select a business and date range to view valid data
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state, props) => {
  const {
    deviceStart,
    deviceEnd,
    userId,
    selectedAffiliatedBusiness,
  } = state.User;
  const { gettingAgentBiz } = state.Loading;
  const { agentBizSummary, affiliatedBusiness } = state.Manager;

  return {
    deviceStart,
    deviceEnd,
    selectedAffiliatedBusiness,
    userId,
    gettingAgentBiz,
    agentBizSummary,
    affiliatedBusiness,
  };
};

export default withRouter(
  connect(
    MapStateToProps,
    { getAgentBizSummary, getFormDetails }
  )(Affiliated)
);
