import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../Reusables/buttons/Button'
import {
  getFormDetails, generateToken, gettingDevices, getTokens, toggleModal,
  addUser, getAllTokenatedUsers, editToken, deleteToken, getRate
} from '../../Redux/actions'
import Loader from '../Reusables/loaders/MoonLoader'
import moment from 'moment'
import Modal from '../Reusables/modals/Modal'
import DateTimePicker from 'react-datetime-picker';
import filesize from 'filesize'
import { numberWithCommas } from '../../Helper'
import { MDBDataTable } from 'mdbreact';


class GiveToken extends Component {

  constructor() {
    super()
    this.state = {
      once: 1
    }

  }
  componentWillMount() {
    this.props.gettingDevices('');
    this.props.getTokens();
    this.props.getRate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tokenSet & this.state.once === 1) {
      this.props.getTokens()
      this.setState({
        once: 2
      })
    }

  }
  render() {
    const { recipientEmail, settingToken, managerDevices, allTokens, maxUsers, expiresAt, funct, userId,
      amountOfToken, deviceId, code, dataVolume, gettingToken, clickedDeviceId, editExpiry, editingToken,
      tokenError, deletingToken, tokenRate } = this.props

    let token = allTokens.find(token => {
      return token.id === clickedDeviceId
    })

    let allRows = []
    allTokens.map(token => {

      return allRows.push({
        name: token.code === "" ? 'A token' : token.code,
        expires: moment(token.expiresAt).format('MMMM Do YYYY'),
        maxUsers: token.maxUsers,
        volume: filesize(token.totalBytes),
        users: <button className='details mr-3' onClick={() => this.props.getAllTokenatedUsers(token.id)}> <i className="fa fa-eye" aria-hidden="true"></i>View Users </button>,
        addUser: <button disabled={token.isValid === false ? true : false} className='details mr-3' onClick={() => this.props.toggleModal(true, token.id, 'add')}>
          <i className="fa fa-plus" aria-hidden="true"></i>{settingToken ? token.id === clickedDeviceId ? <Loader /> : "Add User" : "Add User"} </button>,
        editToken: <button className='details mr-3' onClick={() => this.props.toggleModal(true, token.id, 'edit')}><i className="fa fa-edit" aria-hidden="true"></i>Edit Token</button>,
        deleteToken: <div className='text-center'> {deletingToken ?
          clickedDeviceId === token.id ?
            <i className="fas fa-spinner fa-spin " aria-hidden="true"></i>
            :
            <i style={{ cursor: 'pointer' }} className="fa fa-trash"
              onClick={() => this.props.deleteToken(token.id)} aria-hidden="true"></i>
          :
          <i style={{ cursor: 'pointer' }} className="fa fa-trash"
            onClick={() => this.props.deleteToken(token.id)} aria-hidden="true"></i>} </div>


      })

    })

    const tableData = {
      columns: [
        {
          label: 'Token Name',
          field: 'name',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Expires',
          field: 'expires',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Max Users',
          field: 'maxusers',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Volume',
          field: 'volume',
          sort: 'asc',
          width: 170
        },
        {
          label: 'View Users',
          field: 'users',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Add User',
          field: 'adduser',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Edit Token',
          field: 'editToken',
          sort: 'asc',
          width: 170
        },
        {
          label: 'Delete Token',
          field: 'delete',
          sort: 'asc',
          width: 170
        },
      ],

      rows: allRows
    }

    return (
      <div className='row'>
        {funct === 'add' ?
          <Modal heading='ADD USER'>
            <div className="input-group mb-2">
              <input type="text"
                className="form-control loginCred"
                placeholder="Email"
                aria-describedby="basic-addon1"
                value={recipientEmail}
                onChange={(e) => this.props.getFormDetails({ props: ['recipientEmail'], value: e.target.value })}
              />
            </div>
            <Button theme=" ml-3 mt-2 float wicryptColor"
              buttonAction={() => this.props.addUser(recipientEmail, clickedDeviceId)}
            >
              {settingToken ? <Loader /> : 'Enter'}
            </Button>
          </Modal> :
          funct === 'edit' ?
            <Modal heading='EDIT TOKEN'>
              <label>Set Token Expiry</label>
              <div className="input-group mb-2">
                <div className="input-group mb-2">
                  <DateTimePicker
                    value={editExpiry}
                    placeholeder='Token Expiry'
                    className="form-control loginCred"
                    onChange={(date) => this.props.getFormDetails({ props: ['editExpiry'], value: date })}
                  />
                </div>
              </div>

              <Button theme=" ml-3 mt-2 float wicryptColor"
                buttonAction={() => this.props.editToken(clickedDeviceId, editExpiry, token)}
              >
                {editingToken ? <Loader /> : 'Enter'}
              </Button>
            </Modal> :
            <Modal heading={'SET TOKEN'}>
              <p className='infoMsgs' ><i className="material-icons">info</i>You are about to generate a token at a flat rate of {numberWithCommas(tokenRate * 1024 * 1024 * 360) + ' NGN/GB. '}
            The price for every token generated will be deducted from your Business wallet balance.</p>
              <div className="input-group mb-2">
                <label> Data Amount</label>
                <div className="input-group mb-2">
                  <input type="text"
                    className="form-control loginCred"
                    aria-describedby="basic-addon1"
                    value={amountOfToken}
                    onChange={(e) => this.props.getFormDetails({ props: ['amountOfToken'], value: e.target.value })}
                  />
                  <span className="input-group-text">
                    <select className="form-control loginCred"
                      onChange={(e) => this.props.getFormDetails({ props: ['dataVolume'], value: e.target.value })}
                    >
                      <option value="GB"> GB</option>
                      <option value="MB"> MB</option>
                      <option value="KB"> KB</option>
                    </select>
                  </span>
                </div>

              </div>
              <label> Maximum Number of Users</label>
              <div className="input-group mb-2">
                <input type="text"
                  className="form-control loginCred"
                  aria-describedby="basic-addon1"
                  value={maxUsers}
                  onChange={(e) => this.props.getFormDetails({ props: ['maxUsers'], value: e.target.value })}
                />
              </div>
              <label> Set Token Expiry</label>
              <div className="input-group mb-2">
                <DateTimePicker
                  value={expiresAt}
                  className="form-control loginCred"
                  onChange={(date) => this.props.getFormDetails({ props: ['expiresAt'], value: date })}
                />
              </div>

              <label> Select Device</label>
              <select
                className="form-control loginCred"
                onChange={(e) => this.props.getFormDetails({ props: ['deviceId'], value: e.target.value })}
              >

                <option> Select Device</option>
                {managerDevices ? managerDevices.map(device => {
                  return (
                    userId !== device.user.id ? '' :
                      <option key={device.id} value={device.id}>{device.name}</option>
                  )
                }) : ''}
              </select>
              <label> Token Name</label>
              <div className="input-group mb-2">
                <input type="text"
                  className="form-control loginCred"
                  aria-describedby="basic-addon1"
                  value={code}
                  onChange={(e) => this.props.getFormDetails({ props: ['code'], value: e.target.value })}
                />
              </div>
              {tokenError !== '' ? <p className='errorMsgs' ><i className="material-icons">info</i>{tokenError}</p> : ''}
              <Button theme="wicryptColor ml-3 mt-2 float"
                buttonAction={() => this.props.generateToken(amountOfToken, deviceId, recipientEmail, code, dataVolume, maxUsers, expiresAt)}
              >
                {settingToken ? <Loader /> : 'Enter'}
              </Button>
            </Modal>
        }
        <div className='col-lg-12' style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
          <div className="card-header mb-3">

            <h6 className="m-0">TOKENS</h6>

          </div>
          <div className="card-header mb-3">
            {settingToken ? funct !== 'add' ?
              <Button theme="btn-primary ml-3 mt-2 actionButton wicryptColor"
              > <Loader /></Button> :
              <i className="fa fa-plus" aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.toggleModal(true)}> New Token</i> :
              <i className="fa fa-plus" aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.toggleModal(true)}> New Token</i>
            }
          </div>

        </div>
        {allTokens.length > 0 ?
          <div className="col-lg-12 mb-4">
            <div className="card card-small mb-4 dataTable">
              <MDBDataTable
                striped
                hover
                data={tableData}
                searchLabel='Search'
                responsive
              />
            </div>
          </div>

          :
          <div className="volucherNote" >
            {gettingToken ? <Loader size='10' /> : <p className="voucherText"> You have no tokens</p>}
          </div>
        }




      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { recipientEmail, amountOfToken, deviceId, code,
    dataVolume, expiresAt, maxUsers, clickedDeviceId, funct, editExpiry, userId } = state.User
  const { settingToken, gettingToken, editingToken, deletingToken } = state.Loading
  const { managerDevices, allTokens, tokenSet, tokenError, tokenRate } = state.Manager
  return {
    recipientEmail,
    settingToken,
    managerDevices,
    amountOfToken,
    funct,
    deviceId,
    code,
    tokenError,
    dataVolume,
    gettingToken,
    allTokens,
    maxUsers,
    expiresAt,
    clickedDeviceId,
    tokenSet,
    editExpiry,
    editingToken,
    userId,
    deletingToken,
    tokenRate

  }
}

export default connect(MapStateToProps, { getFormDetails, deleteToken, editToken, getRate, generateToken, gettingDevices, getTokens, toggleModal, addUser, getAllTokenatedUsers })(GiveToken)