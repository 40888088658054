export const allUsersCols = [
         {
           label: "Name",
           field: "name",
           sort: "asc",
           width: 170,
         },
         {
           label: "Email",
           field: "email",
           sort: "asc",
           width: 100,
         },
         {
           label: "Country",
           field: "country",
           sort: "asc",
           width: 170,
         },
         {
           label: "Type",
           field: "type",
           sort: "asc",
           width: 170,
         },

         {
           label: "Last Login Date",
           field: "lastLoginDate",
           sort: "asc",
           width: 170,
         },
         {
           label: "Status",
           field: "status",
           sort: "asc",
           width: 170,
         },
         {
           label: "",
           field: "view",
           sort: "asc",
           width: 100,
         },
       ];

export const stakeCols = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Type",
    field: "type",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Hash",
    field: "transactionHash",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "WNT Price",
  //   field: "price",
  //   sort: "asc",
  //   width: 170,
  // },
];

export const unStakeCols = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Hash",
    field: "transactionHash",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "WNT Price",
  //   field: "price",
  //   sort: "asc",
  //   width: 170,
  // },
];

export const claimsCols = [
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 170,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reward",
    field: "reward",
    sort: "asc",
    width: 170,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 170,
  },
  {
    label: "Transaction Hash",
    field: "transactionHash",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "viewMore",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "WNT Price",
  //   field: "price",
  //   sort: "asc",
  //   width: 170,
  // },
];

export const VerificationRequestCols = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 270,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 270,
  },
  {
    label: "Phone",
    field: "phone",
    sort: "asc",
    width: 270,
  },
  {
    label: "Country",
    field: "country",
    sort: "asc",
    width: 270,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 270,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 270,
  },
];

export const stakeStatCols = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 170,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    width: 170,
  },
  {
    label: "Staked Date",
    field: "stakedOn",
    sort: "asc",
    width: 170,
  },
  {
    label: "Unstake Date",
    field: "unstakeDate",
    sort: "asc",
    width: 170,
  },
  // {
  //   label: "",
  //   field: "view",
  //   sort: "asc",
  //   width: 270,
  // },
];

export const distributedStakingCol = [
  {
    label: "Week",
    field: "week",
    sort: "asc",
    width: 170,
  },
  {
    label: "WNT Distributed",
    field: "totalWNTDistributed",
    sort: "asc",
    width: 170,
  },
  {
    label: "WNT Distributed (USD)",
    field: "totalWNTDistributedInUSD",
    sort: "asc",
    width: 170,
  },
  {
    label: "Number of Stakers",
    field: "totalNumberOfStakers",
    sort: "asc",
    width: 170,
  },
  {
    label: "",
    field: "view",
    sort: "asc",
    width: 270,
  },
];

export const distributedStakingdetailCol = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 170,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 170,
  },
  {
    label: "Reward Amount",
    field: "rewardAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Staked Amount",
    field: "stakedAmount",
    sort: "asc",
    width: 170,
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    width: 270,
  },
];
