import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Subscriptions from "./Subscriptions";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";

const index = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Subscriptions"/>
        </div>
        <div className="main-content-container container-fluid px-4">
          <SubSidenav />
          <BreadCrumbs />
          <Subscriptions />
        </div>
      </MainContent>
    </div>
  );
};

export default index;

