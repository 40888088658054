import React from "react";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import moment from "moment";
import config from "../../../Redux/reducers/config";
import CustomModal from "../../Reusables/modals/CustomModal";
import { numberWithCommas } from "../../../Helper";

const UserTransactionReportTest = ({id, transactionDetails}) => {

  return (
    <CustomModal
        id={id}
        close={true}
        heading={`Transaction Detail`}
        content={
          <div className="col-lg-12 ">
            <div className="card">
              <div className="card-body p-6 ">
                <div className="row mb-2">
                  {transactionDetails.name && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Name</StakeStatData>
                      <StakeStatValue>{transactionDetails.name}</StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.email && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Email</StakeStatData>
                      <StakeStatValue>{transactionDetails.email}</StakeStatValue>
                    </div>
                  )}
                  {/* {transactionDetails.id && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Transation ID</StakeStatData>
                      <StakeStatValue>{transactionDetails.id}</StakeStatValue>
                    </div>
                  )} */}
                  {transactionDetails.walletAddress && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet Address</StakeStatData>
                      <StakeStatValue>
                        {transactionDetails.walletAddress}
                      </StakeStatValue>
                    </div>
                  )} 
                  {transactionDetails.week && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Week</StakeStatData>
                      <StakeStatValue>
                        {transactionDetails.week}
                      </StakeStatValue>
                    </div>
                  )}
                   {transactionDetails.deviceName && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Device Name</StakeStatData>
                      <StakeStatValue>
                        {transactionDetails.deviceName}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {transactionDetails.currency && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Currency</StakeStatData>
                      <StakeStatValue>{transactionDetails.currency}</StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.bankName && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Bank Name</StakeStatData>
                      <StakeStatValue>{transactionDetails.bankName}</StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.accountNumber && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Account Number</StakeStatData>
                      <StakeStatValue>
                        {transactionDetails.accountNumber}
                      </StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.network && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Network</StakeStatData>
                      <StakeStatValue>{transactionDetails.network}</StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.amount && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Amount</StakeStatData>
                      <StakeStatValue>
                        {numberWithCommas(transactionDetails.amount, 4)} {transactionDetails.currency}
                      </StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.referenceCode && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Reference</StakeStatData>
                      <StakeStatValue>
                        {transactionDetails.referenceCode}
                      </StakeStatValue>
                    </div>
                  )}
                </div>
                {transactionDetails.liquidityProvider && (
                  <div
                    className="row mt-2 pt-2"
                    style={{ borderTop: "1px solid #E8E8E8" }}
                  >
                    {transactionDetails.transactionType && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Type</StakeStatData>
                        <StakeStatValue>
                          <div className={` ${transactionDetails.transactionType === 'Sell' ? 'text-red' : transactionDetails.transactionType === 'Buy' ? 'text-green' : ''}`}>
                            {transactionDetails.transactionType}
                          </div>
                        </StakeStatValue>
                      </div>
                    )}
                    {/* {transactionDetails.buyCurrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy Currency</StakeStatData>
                        <StakeStatValue>
                          {transactionDetails.buyCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {transactionDetails.sellCurrency && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Currency</StakeStatData>
                        <StakeStatValue>
                          {transactionDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )} */}
                    {transactionDetails.buyAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Buy amount</StakeStatData>
                        <StakeStatValue>
                          {numberWithCommas(transactionDetails.buyAmount, 4)} {transactionDetails.buyCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                      {transactionDetails.sellAmount && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Sell Amount</StakeStatData>
                        <StakeStatValue>
                          {numberWithCommas(transactionDetails.sellAmount, 4)} {transactionDetails.sellCurrency}
                        </StakeStatValue>
                      </div>
                    )}
                    {transactionDetails.liquidityProvider && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Liquidity Provider</StakeStatData>
                        <StakeStatValue>
                          Name: {transactionDetails.liquidityProvider}
                        </StakeStatValue>
                        <StakeStatValue>
                          Email: {transactionDetails.liquidityProviderEmail}
                        </StakeStatValue>
                        <StakeStatValue>
                          Phone Number: {transactionDetails.liquidityProviderPhoneNumber}
                        </StakeStatValue>
                      </div>
                    )}
                    {transactionDetails.providerPaymentData.length > 0 && (
                      <div className="col-lg-3 col-md-6">
                      <StakeStatData>{"Provider's Payment Details"}</StakeStatData>
                      {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].accountName ? <>
                        <StakeStatValue>
                          Account Name: {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].accountName}
                        </StakeStatValue>
                        <StakeStatValue>
                          Account Number: {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].accountNumber}
                        </StakeStatValue>
                        <StakeStatValue>
                          Bank: {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].bankName}
                        </StakeStatValue>
                      </> : <>
                        <StakeStatValue>
                          Wallet Address: 
                          <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/address/${transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].walletAddress}`}>  {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].walletAddress} </a>
                        
                        </StakeStatValue>
                        <StakeStatValue>
                          Network: {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].network}
                        </StakeStatValue>
                        <StakeStatValue>
                          Currency: {transactionDetails.providerPaymentData[transactionDetails.providerPaymentData.length - 1].currency}
                        </StakeStatValue>
                      </>}
                    </div>
                    )}
                    {transactionDetails.customerEmail && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>Customer Details</StakeStatData>
                        <StakeStatValue>
                          Name: {transactionDetails.customerName}

                        </StakeStatValue>
                        <StakeStatValue>

                          Email: {transactionDetails.customerEmail}

                        </StakeStatValue>
                        <StakeStatValue>
                          Phone Number: {transactionDetails.customerPhoneNumber}
                        </StakeStatValue>
                      </div>
                    )}
                    {transactionDetails.customerPaymentData && (
                      <div className="col-lg-3 col-md-6">
                        <StakeStatData>{ "Customer's Payment Details"}</StakeStatData>
                        {transactionDetails.customerPaymentData.accountName ? <>
                          <StakeStatValue>
                            Account Name: {transactionDetails.customerPaymentData.accountName}
                          </StakeStatValue>
                          <StakeStatValue>
                            Account Number: {transactionDetails.customerPaymentData.accountNumber}
                          </StakeStatValue>
                          <StakeStatValue>
                            Bank: {transactionDetails.customerPaymentData.bankName}
                          </StakeStatValue>
                        </> : <>
                          <StakeStatValue>
                            Wallet Address:
                            <a target="_blank" rel="noopener noreferrer" className="color-blue" href ={`${config.polygonexplorerUrl}/address/${transactionDetails.customerPaymentData.walletAddress}`}>  {transactionDetails.customerPaymentData.walletAddress} </a>
                          </StakeStatValue>
                          <StakeStatValue>
                            Network: {transactionDetails.customerPaymentData.network}
                          </StakeStatValue>
                          <StakeStatValue>
                            Currency: {transactionDetails.customerPaymentData.currency}
                          </StakeStatValue>
                        </>}
                      </div>
                    )}
                  
                  
                  </div>
                )}
                <div
                  className="row mt-2 pt-2"
                  style={{ borderTop: "1px solid #E8E8E8" }}
                >
                  {transactionDetails.destination && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Destination</StakeStatData>
                      <StakeStatValue>{transactionDetails.destination}</StakeStatValue>
                    </div>
                  )}
                  {/* {transactionDetails.wntPrice && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>WNT Price</StakeStatData>
                      <StakeStatValue>$ {transactionDetails.wntPrice}</StakeStatValue>
                    </div>
                  )} */}
                  {transactionDetails.wallet && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Wallet</StakeStatData>
                      <StakeStatValue>{transactionDetails.wallet}</StakeStatValue>
                    </div>
                  )}
                  {transactionDetails.createdAt && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Date</StakeStatData>
                      <StakeStatValue>
                        {moment(transactionDetails.createdAt).format("DD-MM-YYYY")} |{" "}
                        {moment(transactionDetails.createdAt).format("LT")}
                      </StakeStatValue>
                    </div>
                  )}
                  {/* In transaction report we have 5 subtabs: bank, crypto, p2p, transfer and  mining reward. This page shows the details of any of the pages.
                  This extra check here is to make sure that the status for 'mining reward' doesn't show in the details page because it's reduntant but will show
                  in the rest of the page. I used 'reference code' because the data retured in 'mining reward' does not have reference code */}
                  {transactionDetails.referenceCode && transactionDetails.status && (
                    <div className="col-lg-3 col-md-6">
                      <StakeStatData>Status</StakeStatData>
                      <StakeStatValue className={transactionDetails.status.toUpperCase()}>{transactionDetails.status}</StakeStatValue>
                    </div>
                  )}
                </div>
              </div>
            </div>
        </div>
        }
    />
  );
};

export default UserTransactionReportTest;
