import React, { Component } from "react";
import { getFormDetails, resetForms } from "../../Redux/actions";
import { connect } from "react-redux";
import Button from "../Reusables/buttons/Button";

class PreviewForm extends Component {
  remove = (id) => {
    const { allformControl } = this.props;
    allformControl.splice(id, 1);
    this.props.getFormDetails({
      props: ["allformControl"],
      value: allformControl,
    });
  };

  removefromtab = (id, tabarray, tabindex) => {
    let tabinputs = tabarray;
    for (let i = 0; i < tabarray.length; i++) {
      if (i === tabindex) {
        tabinputs[i].formControls.splice(id, 1);
      }
    }
    this.props.getFormDetails({
      props: ["tabarray"],
      value: tabinputs,
    });

  };

  render() {
    const {
      requireRouterCodeLogin,
      enableVoucherLogin,
      allformControl,
      allocationType,
      alltabs,
      currenttab
    } = this.props;

    return (
      <div className="col-lg-6 mb-4">
        {/* Sliders & Progress Bars */}
        <div className="card card-small mb-4">
          <div className="card-header border-bottom d-flex">
            <h6 className="m-0 bold">PREVIEW</h6>
            {alltabs.length > 0 || allformControl.length > 0 ? 
            <h6 className="pointer" onClick={()=> this.props.resetForms()}> Reset</h6>
            : ""}
          </div>
          <div className="preview">
            <div className="mt-5">
              <div className="t-center">
                <h5 className="bold"> Welcome!</h5>
                <p className="t-12">
                  {" "}
                  Please fill out the form and Enjoy free Internet
                </p>
              </div>
            </div>
            {alltabs.length > 0 ?
              <div className="col-lg-12">
                <div className="t-center">
                  <div className="uploads">
                    {alltabs.map((eachtab, i) => {
                      return (
                        <p className={i === currenttab ? "activetab pointer mr-3" : "pointer mr-3"}
                          onClick={() => this.props.getFormDetails({
                            props: ["currenttab"],
                            value: i,
                          })}>
                          {" "}
                          {eachtab.name}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-content">
                  {alltabs.map((eachtab, i) => {
                    return (
                      currenttab === i ?
                        <div id={eachtab.name} className="">
                          <p className="t-12 formlook mb-3"> {eachtab.description}</p>
                          {eachtab.formControls.map((eachForm, i) => {
                            return eachForm.fieldType === "DropDown" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  <select
                                    type="number"
                                    className="form-control"
                                    placeholder={eachForm.placeholder}
                                  >
                                    {eachForm.formControlOptions.map(
                                      (eachoption) => {
                                        return <option> {eachoption.value}</option>;
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            ) : eachForm.fieldType === "CheckBox" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  {eachForm.formControlOptions.map((eachoption) => {
                                    return (
                                      <div
                                        className={
                                          "custom-control  mb-1 custom-checkbox"
                                        }
                                      >
                                        <input
                                          type={"checkbox"}
                                          className="custom-control-input"
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="formsCheckboxChecked"
                                        >
                                          {eachoption.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : eachForm.fieldType === "RadioButton" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => { this.remove(i); this.removefromtab(i, alltabs, currenttab); }}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  {eachForm.formControlOptions.map((eachoption) => {
                                    return (
                                      <div
                                        className={
                                          "custom-control  mb-1 custom-radio"
                                        }
                                      >
                                        <input
                                          type={"radio"}
                                          className="custom-control-input"
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="formsCheckboxChecked"
                                        >
                                          {eachoption.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : eachForm.fieldType === "TextArea" ? (
                              <div className="formlook">
                                <label> {eachForm.label}</label>
                                <div className="mb-3 p-r">
                                  <textarea
                                    rows={4}
                                    cols={50}
                                    disabled={true}
                                    className="form-control"
                                    placeholder={eachForm.placeholder}
                                  />
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            ) : (
                                      <div className="formlook">
                                        <label> {eachForm.label}</label>
                                        <div className="mb-3 p-r">
                                          <input
                                            type="number"
                                            disabled={true}
                                            className="form-control"
                                            placeholder={eachForm.placeholder}
                                          />
                                          <i
                                            className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                            onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    );
                          })}
                        </div> : ""
                    );
                  })}
                  {allocationType !== 1 ? (
                    <div className="formlook">
                      <label> Phone Number</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>
                  ) : (
                      ""
                    )}
                  {currenttab === 0 & requireRouterCodeLogin ? (
                    <div>
                      <div className="formlook">
                        <label>Email</label>
                        <div className="mb-3">
                          <input
                            type="number"
                            disabled={true}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="formlook">
                        <label> Enter Router Code</label>
                        <div className="mb-3">
                          <input
                            type="number"
                            disabled={true}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
              :
              <div className="col-lg-12">
                {allformControl.map((eachForm, i) => {
                  return eachForm.fieldType === "DropDown" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        <select
                          type="number"
                          className="form-control"
                          placeholder={eachForm.placeholder}
                        >
                          {eachForm.formControlOptions.map((eachoption) => {
                            return <option> {eachoption.value}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  ) : eachForm.fieldType === "CheckBox" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        {eachForm.formControlOptions.map((eachoption) => {
                          return (
                            <div
                              className={"custom-control  mb-1 custom-checkbox"}
                            >
                              <input
                                type={"checkbox"}
                                className="custom-control-input"
                                disabled={true}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="formsCheckboxChecked"
                              >
                                {eachoption.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : eachForm.fieldType === "RadioButton" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        {eachForm.formControlOptions.map((eachoption) => {
                          return (
                            <div className={"custom-control  mb-1 custom-radio"}>
                              <input
                                type={"radio"}
                                className="custom-control-input"
                                disabled={true}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="formsCheckboxChecked"
                              >
                                {eachoption.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : eachForm.fieldType === "TextArea" ? (
                    <div className="formlook">
                      <label> {eachForm.label}</label>
                      <div className="mb-3 p-r">
                        <textarea
                          rows={4}
                          cols={50}
                          disabled={true}
                          className="form-control"
                          placeholder={eachForm.placeholder}
                        />
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  ) : (
                            <div className="formlook">
                              <label> {eachForm.label}</label>
                              <div className="mb-3 p-r">
                                <input
                                  type="number"
                                  disabled={true}
                                  className="form-control"
                                  placeholder={eachForm.placeholder}
                                />
                                <i
                                  className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                  onClick={() => this.remove(i)}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          );
                })}
                {allocationType !== 1 ? (
                  <div className="formlook">
                    <label> Phone Number</label>
                    <div className="mb-3">
                      <input
                        type="number"
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                ) : (
                    ""
                  )}
                {requireRouterCodeLogin ? (
                  <div>
                    <div className="formlook">
                      <label>Email</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="formlook">
                      <label> Enter Router Code</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                    ""
                  )}
              </div>}

            <div className="t-center mt-5 mb-5">
              <div className="mb-2 mt-2">
                <Button
                  theme="btn-primary w-50 wicryptColor"
                // disabled = "true"
                >
                  Submit
                </Button>
              </div>
              {enableVoucherLogin ? (
                <div className="">
                  <p className="mb-0 t-12 mt-2">
                    {" "}
                    Already have a valid Voucher?{" "}
                  </p>
                  <Button theme=" w-50 greybtn" disabled="true">
                    Login With Voucher
                  </Button>
                </div>
              ) : (
                  ""
                )}
            </div>
          </div>
          {enableVoucherLogin ? 
          <div>
          <div className="card-header border-bottom d-flex mt-5">
            <h6 className="m-0 bold">Dynamic Voucher Page</h6>
          </div>
          <div className="preview">
            <div className="mt-5">
              <div className="t-center">
                <h5 className="bold p-5"> Voucher Login</h5>
              </div>
            </div>
            <div className="row">
                          {alltabs.length > 0 ?
              <div className="col-lg-12">
                <div className="t-center">
                  <div className="uploads">
                    {alltabs.map((eachtab, i) => {
                      return (
                        <p className={i === currenttab ? "activetab pointer mr-3" : "pointer mr-3"}
                          onClick={() => this.props.getFormDetails({
                            props: ["currenttab"],
                            value: i,
                          })}>
                          {" "}
                          {eachtab.name}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-content">
                  {alltabs.map((eachtab, i) => {
                    return (
                      currenttab === i ?
                        <div id={eachtab.name} className="">
                          <p className="t-12 formlook mb-3"> {eachtab.description}</p>
                          {eachtab.formControls.map((eachForm, i) => {
                            return eachForm.fieldType === "DropDown" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  <select
                                    type="number"
                                    className="form-control"
                                    placeholder={eachForm.placeholder}
                                  >
                                    {eachForm.formControlOptions.map(
                                      (eachoption) => {
                                        return <option> {eachoption.value}</option>;
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            ) : eachForm.fieldType === "CheckBox" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  {eachForm.formControlOptions.map((eachoption) => {
                                    return (
                                      <div
                                        className={
                                          "custom-control  mb-1 custom-checkbox"
                                        }
                                      >
                                        <input
                                          type={"checkbox"}
                                          className="custom-control-input"
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="formsCheckboxChecked"
                                        >
                                          {eachoption.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : eachForm.fieldType === "RadioButton" ? (
                              <div className="formlook">
                                <label>
                                  {" "}
                                  {eachForm.label}
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer"
                                    onClick={() => { this.remove(i); this.removefromtab(i, alltabs, currenttab); }}
                                    aria-hidden="true"
                                  />
                                </label>
                                <div className="mb-3 p-r">
                                  {eachForm.formControlOptions.map((eachoption) => {
                                    return (
                                      <div
                                        className={
                                          "custom-control  mb-1 custom-radio"
                                        }
                                      >
                                        <input
                                          type={"radio"}
                                          className="custom-control-input"
                                          disabled={true}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="formsCheckboxChecked"
                                        >
                                          {eachoption.value}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : eachForm.fieldType === "TextArea" ? (
                              <div className="formlook">
                                <label> {eachForm.label}</label>
                                <div className="mb-3 p-r">
                                  <textarea
                                    rows={4}
                                    cols={50}
                                    disabled={true}
                                    className="form-control"
                                    placeholder={eachForm.placeholder}
                                  />
                                  <i
                                    className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                    onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            ) : (
                                      <div className="formlook">
                                        <label> {eachForm.label}</label>
                                        <div className="mb-3 p-r">
                                          <input
                                            type="number"
                                            disabled={true}
                                            className="form-control"
                                            placeholder={eachForm.placeholder}
                                          />
                                          <i
                                            className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                            onClick={() => this.removefromtab(i, alltabs, currenttab)}
                                            aria-hidden="true"
                                          />
                                        </div>
                                      </div>
                                    );
                          })}
                        </div> : ""
                    );
                  })}
                  {/* {allocationType !== 1 ? (
                    <div className="formlook">
                      <label> Phone Number</label>
                      <div className="mb-3">
                        <input
                          type="number"
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>
                  ) : (
                      ""
                    )} */}
                  {/* {currenttab === 0 & requireRouterCodeLogin ? (
                    <div>
                      <div className="formlook">
                        <label>Email</label>
                        <div className="mb-3">
                          <input
                            type="number"
                            disabled={true}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="formlook">
                        <label> Enter Router Code</label>
                        <div className="mb-3">
                          <input
                            type="number"
                            disabled={true}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                      ""
                    )} */}
                </div>
              </div>
              :
              <div className="col-lg-12">
                {allformControl.map((eachForm, i) => {
                  return eachForm.fieldType === "DropDown" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        <select
                          type="number"
                          className="form-control"
                          placeholder={eachForm.placeholder}
                        >
                          {eachForm.formControlOptions.map((eachoption) => {
                            return <option> {eachoption.value}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  ) : eachForm.fieldType === "CheckBox" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        {eachForm.formControlOptions.map((eachoption) => {
                          return (
                            <div
                              className={"custom-control  mb-1 custom-checkbox"}
                            >
                              <input
                                type={"checkbox"}
                                className="custom-control-input"
                                disabled={true}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="formsCheckboxChecked"
                              >
                                {eachoption.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : eachForm.fieldType === "RadioButton" ? (
                    <div className="formlook">
                      <label>
                        {" "}
                        {eachForm.label}
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </label>
                      <div className="mb-3 p-r">
                        {eachForm.formControlOptions.map((eachoption) => {
                          return (
                            <div className={"custom-control  mb-1 custom-radio"}>
                              <input
                                type={"radio"}
                                className="custom-control-input"
                                disabled={true}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="formsCheckboxChecked"
                              >
                                {eachoption.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : eachForm.fieldType === "TextArea" ? (
                    <div className="formlook">
                      <label> {eachForm.label}</label>
                      <div className="mb-3 p-r">
                        <textarea
                          rows={4}
                          cols={50}
                          disabled={true}
                          className="form-control"
                          placeholder={eachForm.placeholder}
                        />
                        <i
                          className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                          onClick={() => this.remove(i)}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  ) : (
                            <div className="formlook">
                              <label> {eachForm.label}</label>
                              <div className="mb-3 p-r">
                                <input
                                  type="number"
                                  disabled={true}
                                  className="form-control"
                                  placeholder={eachForm.placeholder}
                                />
                                <i
                                  className="fas fa fa-times mr-2 ml-2 pointer acctNameLoader"
                                  onClick={() => this.remove(i)}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          );
                })}
              </div>}
          </div>
            {currenttab !== 0  & alltabs.length > 0 ? "" : enableVoucherLogin ? 
                <div> 
                  <div className="formlook">
                    <label> Phone Number</label>
                    <div className="mb-3">
                      <input
                        type="number"
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="formlook">
                    <label> Voucher Code</label>
                    <div className="mb-3">
                      <input
                        type="number"
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>

                </div> : "" 
              //   : enableVoucherLogin & requireRouterCodeLogin?
              //   <div className="formlook">
              //   <label> Phone Number</label>
              //   <div className="mb-3">
              //     <input
              //       type="number"
              //       disabled={true}
              //       className="form-control"
              //     />
              //   </div>
              // </div>
              //   : ""
              }
            
            <div className="t-center mt-5 mb-5">
              <div className="p-5">
                <Button
                  theme="btn-primary w-50 wicryptColor"
                // disabled = "true"
                >
                  Submit
                </Button>
              </div>
              
            </div>
          </div>
          </div>: ""}
        </div>
        {/* / Sliders & Progress Bars */}
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const {
    requireRouterCodeLogin,
    enableVoucherLogin,
    allformControl,
    allocationType,
    alltabs,
    currenttab
  } = state.User;

  return {
    requireRouterCodeLogin,
    enableVoucherLogin,
    allformControl,
    allocationType,
    alltabs,
    currenttab
  };
};

export default connect(
  MapStateToProps,
  { getFormDetails, resetForms }
)(PreviewForm);
