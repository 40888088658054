import React, { Component } from "react";
import LongCards from "../Reusables/cards/LongCards";
import { connect } from "react-redux";
import {
  logInUser,
  changeLocation,
  getFormDetails,
  emptyForm,
} from "../../Redux/actions";
import { withRouter } from "react-router-dom";
import BreadCrumbs from "../Partials/Breadcrumbs";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      notEmail: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth & (nextProps.googleLogin === 0)) {
      if (
        nextProps.role.includes("SuperAdmin") ||
        nextProps.role.includes("Admin")
      ) {
        this.props.history.push("/admin/dashboard");
      } else if(nextProps.role.includes("Developer")) {
        this.props.history.push("/topics");
      } else{
        this.props.history.push("/user/dashboard");
      }
    } else if (nextProps.auth & (nextProps.googleLogin === 1)) {
      this.props.history.push("/register");
    }
    if (nextProps.regError !== "") {
      setTimeout(function() {
        nextProps.getFormDetails({ props: ["regError"], value: "" });
      }, 5000);
    }
  }
  handleLogin() {
    const { loginEmail, password } = this.props;
    let checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (password === "" || loginEmail === "") {
      this.props.emptyForm({ props: ["error"], value: true });
    } else if (!checkEmail.test(loginEmail)) {
      this.props.emptyForm({ props: ["notEmail"], value: true });
    } else {
      let data = {
        email: this.props.loginEmail,
        password: this.props.password,
        macAddress: "00:00",
      };
      this.props.logInUser(data);
    }
  }

  render() {
    const { regError } = this.props;
    return (
      <div className="row background">
        <main className="main-content col-lg-12 col-md-12 col-sm-12">
          <div className="main-content-container container-fluid px-4">
            <BreadCrumbs />
            <div className="row">
              {regError !== "" ? (
                <div id="toast" className="show">
                  <div id="img">
                    <i class="fa fa-bell" aria-hidden="true"></i>
                  </div>
                  <div id="desc">{regError}</div>
                </div>
              ) : (
                ""
              )}
              <LongCards
                error={this.props.error}
                err={this.props.loginErrorMsg}
                loggingIn={this.props.loggingIn}
                notEmail={this.props.notEmail}
                heading="Log In"
                logIn={this.handleLogin}
                pinReveal={this.props.pinReveal}
              />
            </div>
          </div>
          <div className="privacy mt-4">
            <div>
              <p className="policies">
                {" "}
                Copyright © 2018-2021. All rights reserved. Wicrypt Limited
              </p>
            </div>
            <div>
              <a href="https://wicrypt.com/help/" className="tanda policies">
                {" "}
                Help Center
              </a>
              <a
                href="https://wicrypt.com/privacy-policy.html"
                className="tanda policies"
              >
                {" "}
                Privacy Policy
              </a>
              <a
                href="https://wicrypt.com/terms-condition.html"
                className="tanda policies"
              >
                {" "}
                Terms and Condition
              </a>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { loggingIn } = state.Loading;
  const {
    pinReveal,
    auth,
    location,
    role,
    loginEmail,
    password,
    loginErrorMsg,
    error,
    notEmail,
    regError,
    googleLogin,
  } = state.User;
  return {
    loggingIn,
    auth,
    location,
    role,
    loginEmail,
    password,
    loginErrorMsg,
    error,
    notEmail,
    pinReveal,
    googleLogin,
    regError,
  };
};
export default withRouter(
  connect(MapStateToProps, {
    logInUser,
    getFormDetails,
    changeLocation,
    emptyForm,
  })(Login)
);
