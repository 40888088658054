import React from "react";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import AllSubscriptions from "./AllSubscriptions"
import SubSidenav from "../Partials/SubSidenav"
import { MainContent } from "../Partials/partials";

const index = () => {
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white">
          <Mainnav pageTitle="Subscriptions"/>
        </div>
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          <BreadCrumbs />
          <AllSubscriptions />
        </div>
      </MainContent>
    </div>
  );
};

export default index;

