import React, { Component } from "react";
import {
  SubTitle,
  SubValue,
  SectionValue,
  SectionTitle,
  WicryptPattern
} from "./walletstyles";
import { handlePinReveal } from "../../Redux/actions";
import { connect } from "react-redux";
import { numberWithCommas } from "../../Helper";
import Alltransactionbuttons from "./alltransactionbuttons";

class BusinessWallet extends Component {
  render() {
    const { businessBalance, currencyValue, pinReveal } = this.props;
    return (
      <div className="card card-small mb-4 w3-animate-right">
        <div className="p-20">
          <div>
            <SubTitle>
              {" "}
              Business Balance
              {pinReveal === 0 ? (
                <span onClick={() => this.props.handlePinReveal(1)}>
                  <i className="material-icons pointer ml-3">visibility_off</i>
                </span>
              ) : (
                <span onClick={() => this.props.handlePinReveal(0)}>
                  <i className="material-icons pointer ml-3">visibility</i>
                </span>
              )}
            </SubTitle>

            <SubValue>
              {pinReveal
                ? "NGN " + numberWithCommas(businessBalance * currencyValue)
                : "NGN ******"}
            </SubValue>
          </div>
          <div>
            <SectionTitle> Approx.</SectionTitle>
            <SectionValue>
              {pinReveal
                ? numberWithCommas(businessBalance) + " WC"
                : "***** WC"}
            </SectionValue>
          </div>
          <Alltransactionbuttons />
        </div>
        <WicryptPattern
          src="/Assets/cardicons/wicryptpattern.svg"
          alt="wicryptpattern"
        />
      </div>
    );
  }
}

const MapStateToProps = state => {
  const { businessBalance, currencyValue, role, pinReveal } = state.User;
  return {
    businessBalance,
    currencyValue,
    role,
    pinReveal
  };
};

export default connect(
  MapStateToProps,
  { handlePinReveal }
)(BusinessWallet);
