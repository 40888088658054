import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallVerificationRequests, toggleModal } from "../../Redux/actions";
import { clearSearch } from "../../Redux/actions/GeneralAction.js";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import TableHeader from "../Reusables/tables/TableHeader";
import { requestHeaderOptions } from "../Users/options";
import { VerificationRequestCols } from "../../utils/TableCols/usersCols";
import { getAllVerificationRequestRows } from "../../utils/TableRows/usersRows";
import { getSearchDetails } from "../../Redux/actions/SearchAction";

const RequestTable = () => {
  const {
    verificationRequests,
    verificationCurrentPage,
    verificationTotalPages,
  } = useSelector((state) => state.Verification);
  const { gettingRequests } = useSelector((state) => state.Loading);
  const { search, duration, page, pageSize } = useSelector(
    (state) => state.Search
  );
  const dispatch = useDispatch();

  let allRows = getAllVerificationRequestRows(verificationRequests, dispatch);

  const data = {
    columns: VerificationRequestCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);

  const fetchKYCRequest = () => {
    const queries = {
      Search: search,
      Duration: duration,
      Page: page,
      PageSize: pageSize,
    };
    getallVerificationRequests(dispatch, queries);
  }

  useEffect(() => {
    fetchKYCRequest();
  }, [duration, page]);

  // This is to refresh the page when the search input is empty
  useEffect(()=>{
    if(search === ""){
      fetchKYCRequest();
    }
  },[search])

  return (
    <div className="col-lg-12 mb-4">
      <CustomDataTable
        loading={gettingRequests}
        currentPage={verificationCurrentPage}
        pageCount={verificationTotalPages}
        header={
          <TableHeader
            subHeader={requestHeaderOptions}
            onClick={() => dispatch(toggleModal(true))}
            onChange={(e, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: e.target.value,
                })
              );
            }}
            onSelect={(val, props) => {
              dispatch(
                getSearchDetails({
                  props: [props],
                  value: val,
                })
              );
            }}
            onSearchClick={fetchKYCRequest}
          />
        }
        data={data}
        noData={
          <EmptyCard
            img="/Assets/emptytransactions.svg"
            title={"Sorry,  you have no verification requests"}
            // descr={"No Request available at the moment"}
          />
        }
      />
    </div>
  );
};

export default RequestTable;
