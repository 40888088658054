import React, { Component } from 'react';
import Mainnav from '../Partials/Mainnav';
import Sidenav from '../Partials/Sidenav';
import BreadCrumbs from '../Partials/Breadcrumbs';
import WalletSummary from './walletsummary';
import AllWallets from "./allwallets";
import SubSidenav from "../Partials/SubSidenav";
import PersonalWallet from "./personalwallet";
import PersonalHistory from "./personalhistory";
import FundWallet from "./fundwallet";
import Withdraw from "./withdraw";
import Transfer from "./transfer";
import BusinessWallet from "./businesswallet";
import Businesshistory from './businesshistory';
import NoBusiness from './nobusiness';
import { MainContent } from "../Partials/partials";


import { connect } from "react-redux";


class index extends Component {

    render() {
        const { toggleSubnav, role } = this.props
        return (
            <div className="row">
                <FundWallet />
                <Withdraw />
                <Transfer />
                <Sidenav />
                <MainContent className="main-content">
                    <div className="main-navbar sticky-top bg-white mb-5">
                        <Mainnav pageTitle="Wallets" listItems={[{ id: 1, item: "Overview" }, { id: 2, item: "Personal" }, { id: 3, item: "Business" },]} />
                    </div>
                    <BreadCrumbs />
                    <div className="main-content-container container-fluid px-4 mt-5">
                        <SubSidenav />
                        {toggleSubnav === 1 ?
                            <React.Fragment>
                                <WalletSummary />
                                <AllWallets />
                            </React.Fragment>
                            : toggleSubnav === 2 ?
                                <React.Fragment>
                                    <PersonalWallet />
                                    <PersonalHistory />
                                </React.Fragment> :
                                <React.Fragment>
                                    {!role.includes("Business") ?
                                        <NoBusiness />
                                        :
                                        <React.Fragment>
                                            <BusinessWallet />
                                            <Businesshistory />
                                        </React.Fragment>}
                                </React.Fragment>
                        }
                    </div>
                </MainContent>
            </div>
        );
    }

}

const MapstateToProps = (state) => {
    const { toggleSubnav } = state.General
    const { role } = state.User;
    return {
        toggleSubnav,
        role
    }
}


export default connect(MapstateToProps)(index)