import React from "react";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../../Helper";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../Redux/actions";
export const getBusinessAgentRows = (data) => {
  const allRows = data
    ? data.map((agent, i) => {
        return {
          agent: agent.agentName,
          manageddevices: agent.managedDevices,
          currency: agent.currency,
          voucherremittanceallowance: agent.voucherAllowance,
          status: (
            <p
              className={
                agent.verificationStatus === "Unverified"
                  ? "unverified"
                  : "verified"
              }
            >
              {agent.verificationStatus === "Unverified"
                ? "Not Verified"
                : "Verified"}{" "}
            </p>
          ),
        };
      })
    : [];
  return allRows;
};
export const getBusinessRows = (data) => {
  const dispatch = useDispatch();
  let allRows = data
    ? data.map((business) => {
        return {
          name: business.businessName || "N/A",
          address: business.officeAddress || "N/A",
          voucherfee: (
            <b>
              <div>
                {" "}
                <p className="mb-0">
                  {business.currency + " " +
                    numberWithCommas(business.voucherGeneratedFee)}
                </p>
                {/* <p className="mb-0 t-8 littleamt">
                  ~{numberWithCommas(business.voucherGeneratedFee)}
                  WC
                </p> */}
              </div>
            </b>
          ),
          billing: (
            <div
              className="pointer billingbox"
              onClick={() => dispatch(toggleModal(true, business.businessId))}
            >
              <p className="billingtype"> {business.billingType} </p>
              <p className="billingicon">
                {" "}
                <img src="/Assets/Edit.svg" alt="edit" />
              </p>
            </div>
          ),
          report: (
            <Link to={`/businessummary/${business.businessId}`}>
              <div className="d-flex">
                <p className="mb-0"> View Report </p>
                <span>
                  {" "}
                  <i className="material-icons t-17 text-orange">
                    chevron_right
                  </i>
                </span>
              </div>
            </Link>
          ),
        };
      })
    : [];
  return allRows;
};
