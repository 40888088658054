import React, { useEffect, useState } from "react";
import axios from "axios";
import Mainnav from "../Partials/Mainnav";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import UserTransactions from "./UserTransactions";
import { Link } from "react-router-dom";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import { useSelector, useDispatch } from "react-redux";
// import ActivityLogs from "./ActivityLogs";
import Wallets from "./Wallets";
import Stake from "./Stake";
import Devices from "./Devices";
import Profile from "./Profile";
import { handleSubNav } from "../../Redux/actions";
import config from "../../Redux/reducers/config";
import { changeActiveTab } from "./../../Redux/actions/UserAction";
import { getSearchDetails } from "../../Redux/actions/SearchAction";
import UserSpendCOntrol from "./UserSpendControl";
import CustomControl from "../SpendControl/CustomControl";
import FraudReport from "./FraudRepot";
import SpendControlActivity from "./SpendControlActivity";
import AccountReconciliation from "./AccountReconciliation";
import BoosterCyclesUserPage from "../RewardBooster/userPage/BoosterCyclesUserPage";
import LockupUserPage from "../RewardBooster/userPage/LockupUserPage";
import AccruedUserPage from "../RewardBooster/userPage/AccruedUserPage";
import NewUserTransactionsReport from "./NewUserTransactions";


const index = ({history}) => {
  const { toggleSubnav } = useSelector((state) => state.General);
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState({});
  const userId = window.location.pathname.split("/").at(-1);
  const currentTab = localStorage.getItem("activeTab");
  const route = localStorage.getItem("backBtn");

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const getUserProfile = async () => {
      await axios
        .get(config.adminApiUrl + `/v1/users/profile/${userId}`)
        .then((res) => {
          setUserProfile(res.data.data);
        });
    };
    getUserProfile();
  }, []);



  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              // <Link
              //   to={`/wicryptusers`}
              //   className="pointer mb-0"
              //   onClick={() => {
              //     dispatch(handleSubNav(1));
              //     dispatch(changeActiveTab(Number(currentTab)));
              //     dispatch(
              //       getSearchDetails({
              //         props: ["usersType"],
              //         value: Number(currentTab) === 1 ? "active" : "blocked",
              //       })
              //     );
              //   }}
              // >
                 <button
                className="pointer mb-0"
                onClick={()=> {
                  goBack();
                  dispatch(handleSubNav(1));
                  dispatch(changeActiveTab(Number(currentTab)));
                  dispatch(
                    getSearchDetails({
                      props: ["usersType"],
                      value: Number(currentTab) === 1 ? "active" : "blocked",
                    })
                  );
                }}
                style={{background: 'none', border: 'none'}}
              > 
                {" "}
                <i className="material-icons">arrow_back</i> {userProfile.name}
              </button>
              // </Link>
            }
            listItems={[
              { id: 1, item: "Profile" },
              { id: 3, item: "Wallets" },
              { id: 4, item: "Transactions" },
              { id: 9, item: "Account Reconciliation" },
              { id: 5, item: "Stake" },
              { id: 6, item: "Devices" },
              { id: 7, item: "Spend Control" },
              { id: 10, item: "Booster Cycles" },
              { id: 11, item: "Lockup Request" },
              { id: 12, item: "Accrued Interest" },
             // { id: 2, item: "Activity Log" },
              { id: 8, item: "Fraud Report" },
            ]}
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? (
            <Profile userProfile={userProfile} />

          // ) : toggleSubnav === 2 ? (
          //   <ActivityLogs />
          ) : toggleSubnav === 3 ? (
            <Wallets />
          ) : toggleSubnav === 4 ? (
            <NewUserTransactionsReport userId={userId} />
          ) : toggleSubnav === 5 ? (
            <Stake userId={userId} />
          ) : toggleSubnav === 6 ? (
            <Devices userId={userId} />
          ) : toggleSubnav === 9 ? (
            <AccountReconciliation userId={userId} />
          ) : toggleSubnav === 10 ? (
            <BoosterCyclesUserPage userId={userId} />
          ) : toggleSubnav === 11 ? (
            <LockupUserPage userId={userId} />
          ) : toggleSubnav === 12 ? (
            <AccruedUserPage userId={userId} />
          )  : toggleSubnav === 7 ? (
            <>
              <UserSpendCOntrol userProfile={userProfile} userId={userId} />
              <CustomControl userId={userId} />
              <SpendControlActivity />
            </>
          ) : (
            <FraudReport userId={userId} />
          )}
        </div>

      </MainContent>
    </div>
  );
};

export default index;
