import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ModalTitle, ModalContainer, ModalHeader, ModalCotent } from "../Reusables/modals/modalstyles"

class AgentDeleteSuccess extends Component {
    componentDidMount() {
        this.timeoutHandle = setTimeout(() => {
            this.props.history.push("/agents");
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutHandle);
    }

    render() {

        return (
            <React.Fragment>

                <ModalContainer>
                    <ModalCotent className="w3-animate-bottom">
                        <ModalHeader>
                            <ModalTitle>{''}</ModalTitle>
                        </ModalHeader>
                        <div className="row">
                            <div className="d-flex flex-column m-auto">
                                <img src="/Assets/check.svg" className="mt-5" width="100%" height="70px" alt="success" />
                                <p className="text-green mt-4 mb-5"> Agent Deleted Successfully!</p>
                            </div>
                        </div>
                    </ModalCotent>

                </ModalContainer>
            </React.Fragment>
        );
    }
}

const MapStateToProps = (state) => {
    return {
        state
    };
};
export default withRouter(
    connect(
        MapStateToProps,
        {}
    )(AgentDeleteSuccess)
);
