import React, { Component } from 'react';
import {
    getFormDetails,
    editsubscriptionplans,
    toggleModal,
    getCountries
} from "../../Redux/actions";
import { connect } from "react-redux";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";

class EditSubscription extends Component {

    componentWillMount() {
        this.props.getCountries(1, 100);
    }


    render() {
        const { subscriptionname, subscriptionmonthlyfee, subscriptionyearlyfee, subscriptionplans,
            clickedDeviceId, subscriptionerr, allcountries, selectedcountrycode, minimumdevices, maximumdevices, editingsubscriptionplans } = this.props;

        var selectedsubscription =
        clickedDeviceId === ""
            ? {}
            : subscriptionplans.find((subscription) => {
                return subscription.id === clickedDeviceId;
            });

        let createdata = {
            "name": subscriptionname === "" ? selectedsubscription.name : subscriptionname,
            "monthlyFee": subscriptionmonthlyfee === "" ? selectedsubscription.monthlyFee : subscriptionname,
            "yearlyFee": subscriptionyearlyfee === "" ? selectedsubscription.yearlyFee : subscriptionyearlyfee,
            "countryCode": selectedcountrycode === "" ? selectedsubscription.countryCode : selectedcountrycode,
            "minimumDevices": minimumdevices === 0 ? selectedsubscription.minimumDevices : parseInt(minimumdevices),
            "maximumDevices": maximumdevices === 0 ? selectedsubscription.maximumDevices : parseInt(maximumdevices),

        }
        let noneservererror = ["", 1, 2, 3, 4];

        return (
            <Modal heading={"Edit Subscription"}>

                <label> Subscription Name</label>
                <div className="input-group mb-2">
                    <input
                        type="text"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        defaultValue={selectedsubscription.name}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionname"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                {subscriptionerr === 1 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Subscription name is required
                    </p>
                ) : (
                        ""
                    )}
                <label> Monthly Fee</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        defaultValue={selectedsubscription.monthlyFee}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionmonthlyfee"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                {subscriptionerr === 2 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Monthly Fee is required
                    </p>
                ) : (
                        ""
                    )}
                <label> Yearly Fee</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        defaultValue={selectedsubscription.yearlyFee}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["subscriptionyearlyfee"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                {subscriptionerr === 3 ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        Yearly fee is required
                    </p>
                ) : (
                        ""
                    )}
                    <label> Maximum Devices</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        defaultValue={selectedsubscription.maximumdevices}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["maximumdevices"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <label> Minimum Devices</label>
                <div className="input-group mb-2">
                    <input
                        type="number"
                        className="form-control loginCred"
                        aria-describedby="basic-addon1"
                        defaultValue={selectedsubscription.minimumdevices}
                        onChange={(e) =>
                            this.props.getFormDetails({
                                props: ["minimumdevices"],
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <label> Select Country</label>
                <select
                    className="form-control mr-2 mt-2 loginCred"
                    onChange={(e) =>
                        this.props.getFormDetails({
                            props: ["selectedcountrycode"],
                            value: e.target.value,
                        })
                    }
                >
                    <option key={selectedsubscription.country.isoTwoLetterCode} value={selectedsubscription.country.isoTwoLetterCode}>
                        {selectedsubscription.country.name}
                    </option>
                    {allcountries.map((country) => {
                        return (
                            <option key={country.isoTwoLetterCode} value={country.isoTwoLetterCode}>
                                {country.name}
                            </option>
                        );
                    })}
                </select>
                {!noneservererror.includes(subscriptionerr) ? (
                    <p className="errorMsgs">
                        <i className="material-icons">info</i>
                        {subscriptionerr}
                    </p>
                ) : (
                        ""
                    )}
                <Button
                    theme="wicryptColor ml-3 mt-2 float"
                    buttonAction={() =>
                        this.props.editsubscriptionplans(
                            clickedDeviceId,
                            createdata
                        )
                    }
                >
                    {editingsubscriptionplans ? <Loader /> : "Enter"}
                </Button>
            </Modal>
        )
    }
}

const MapStateToProps = (state) => {
    const { subscriptionname, subscriptionmonthlyfee, subscriptionyearlyfee, minimumdevices, maximumdevices, selectedcountrycode, clickedDeviceId } = state.User;
    const { editingsubscriptionplans } = state.Loading;
    const { subscriptionerr, subscriptionplans } = state.Subscription;
    const { allcountries } = state.Countries;
    return {
        subscriptionname,
        subscriptionmonthlyfee,
        subscriptionyearlyfee,
        selectedcountrycode,
        editingsubscriptionplans,
        subscriptionerr,
        allcountries,
        subscriptionplans,
        clickedDeviceId,
        minimumdevices,
        maximumdevices,
    };
};

export default connect(MapStateToProps, {
    getFormDetails,
    editsubscriptionplans,
    toggleModal,
    getCountries
})(EditSubscription);