import React, { Component } from "react";
import { connect } from "react-redux";
import {
  approveVerification,
  getFormDetails,
  toggleModal,
} from "../../Redux/actions";
import Modal from "../Reusables/modals/Modal";
import Button from "../Reusables/buttons/Button";
import Loader from "../Reusables/loaders/MoonLoader";

class ApproveVerification extends Component {
  render() {
    const {
      oneRequest,
      status,
      startDate,
      endDate,
      approvingVerification,
    } = this.props;
    return (
      <Modal heading={`Approve  requests`}>
        <div className="text-center">
          <p> Are you sure you want to approve this request?</p>
        </div>
        <div className="d-flex">
          <Button
            theme=" ml-3 mt-2 float Cancelled"
            buttonAction={() => this.props.toggleModal(false)}
          >
            Cancel
          </Button>
          {approvingVerification ? (
            <Button theme=" ml-3 mt-2 float wicryptColor">
              {" "}
              <Loader />
            </Button>
          ) : (
            <Button
              theme=" ml-3 mt-2 float wicryptColor"
              buttonAction={() =>
                this.props.approveVerification(
                  {
                    reasonForRejection: "",
                    requestId: oneRequest.id,
                  },
                  status,
                  startDate,
                  endDate
                )
              }
            >
              Continue
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}
const MapStateToProps = (state) => {
  const { oneRequest } = state.Verification;
  const { approvingVerification } = state.Loading;
  const { status, startDate, endDate } = state.User;
  return {
    oneRequest,
    status,
    startDate,
    endDate,
    approvingVerification,
  };
};
export default connect(MapStateToProps, {
  approveVerification,
  getFormDetails,
  toggleModal,
})(ApproveVerification);
