import React, { Component } from "react";
import { numberWithCommas } from "../../Helper";
import {
  outstandingRemittance,
  getFormDetails,
  getallAgents,
  clearRemittance,
} from "../../Redux/actions";
import { connect } from "react-redux";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import Datepicker from "react-datepicker";
import moment from "moment";
import CustomDataTable from "../Reusables/tables/CustomDataTable";

class Outstanding extends Component {
  componentWillMount() {
    const {
      outstandingselectedAgent,
      outstandingremitStart,
      outstandingremitEnd,
      outstandingremittanceReport,
      allAgents
    } = this.props;
    if (allAgents.length === 0) {
      this.props.getallAgents(1, 30, '');
    }
    if (outstandingremittanceReport.length === 0) {
      this.props.outstandingRemittance(
        outstandingselectedAgent,
        outstandingremitStart,
        outstandingremitEnd,
        1,
        50
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { outstandingselectedAgent, outstandingremitStart, outstandingremitEnd } = nextProps;
    if (
      outstandingselectedAgent !== this.props.outstandingselectedAgent ||
      outstandingremitStart !== this.props.outstandingremitStart ||
      outstandingremitEnd !== this.props.outstandingremitEnd
    ) {
      this.props.clearRemittance();
      this.props.outstandingRemittance(
        outstandingselectedAgent,
        outstandingremitStart,
        outstandingremitEnd,
        1,
        50
      );
    }
  }

  customPagination() {
    const { outstandingremittanceTotalPages, outstandingremittanceCurrentPage, outstandingremitStart, outstandingremitEnd, outstandingselectedAgent } = this.props;
    var pagefrom = 1;
    var paginate = [];
    if (pagefrom !== outstandingremittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.outstandingRemittance(outstandingselectedAgent, outstandingremitStart, outstandingremitEnd, outstandingremittanceCurrentPage - 1, 50)
          }
          className="pointer"
        >
          &laquo;
        </p>
      );
    }
    for (let i = pagefrom; outstandingremittanceTotalPages >= i; i++) {
      if (i === outstandingremittanceCurrentPage) {
        paginate.push(
          <p key={i} className="active" value={i}>
            {i}
          </p>
        );
      } else {
        paginate.push(
          <p
            onClick={() => this.props.outstandingRemittance(outstandingselectedAgent, outstandingremitStart, outstandingremitEnd, i, 50)}
            className="pointer"
            key={i}
            value={i}
          >
            {i}
          </p>
        );
      }
    }
    if (outstandingremittanceTotalPages > outstandingremittanceCurrentPage) {
      paginate.push(
        <p
          onClick={() =>
            this.props.outstandingRemittance(outstandingselectedAgent, outstandingremitStart, outstandingremitEnd, outstandingremittanceCurrentPage + 1, 50)
          }
          className="pointer"
        >
          &raquo;
        </p>
      );
    }
    return paginate;
  }


  render() {
    const {
      outstandingremittanceReport,
      allAgents,
      outstandingremitStart,
      outstandingremitEnd,
      gettingOutstandingRemits,
      conversionsRate,
    } = this.props;

    let allRows = [];
    outstandingremittanceReport.map((remit) => {
      return allRows.push({
        username: remit.agent.user.userName,
        amount:
          remit.amount +
          " WC | NGN " +
          numberWithCommas(remit.amount * conversionsRate),
        voucherRemittanceAllowance:
          remit.agent.voucherRemittanceAllowance +
          " WC | NGN " +
          numberWithCommas(
            remit.agent.voucherRemittanceAllowance * conversionsRate
          ),
        defaultRemittanceAllowance:
          remit.agent.business.defaultRemittanceAllowance +
          " WC | NGN " +
          numberWithCommas(
            remit.agent.business.defaultRemittanceAllowance * conversionsRate
          ),

        date:
          remit.lastRemittanceDate == null
            ? "N/A"
            : moment.parseZone(remit.lastRemittanceDate).format("YYYY-MM-DD"),
      });
    });

    const data = {
      columns: [
        {
          label: "Agent Username",
          field: "username",
          sort: "asc",
          width: 170,
        },
        {
          label: "Amount to Remit",
          field: "amount",
          sort: "asc",
          width: 270,
        },
        {
          label: "Voucher Remittance Allowance",
          field: "voucherRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Default Remittance Allowance",
          field: "defaultRemittanceAllowance",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date of Last Remittance",
          field: "date",
          sort: "asc",
          width: 170,
        },
      ],

      rows: allRows,
    };

    return (
      <div className="col-lg-12 mb-4">
        <div className="card-header mb-3">
          <div className="d-flex">
            <h6 className="m-0">OUTSTANDING REMITTANCE REPORT</h6>
          </div>

          <div className="mt-3 d-flex">
            <div className="mr-3"> FROM</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["outstandingremitStart"],
                  value: e.getTime(),
                })
              }
              selected={outstandingremitStart}
              dateFormat={"dd/MM/yyyy"}
            />

            <div className="mr-3 ml-3"> TO</div>
            <Datepicker
              className="dateRange form-control"
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["outstandingremitEnd"],
                  value: e.getTime(),
                })
              }
              selected={outstandingremitEnd}
              dateFormat={"dd/MM/yyyy"}
            />
            <select
              className="input-sm form-control"
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.getFormDetails({
                  props: ["outstandingselectedAgent"],
                  value: e.target.value,
                })
              }
            >
              <option>Select Agent</option>
              {allAgents.map((agent, i) => {
                return <option key={i} value={agent.user.id}>{agent.user.name}</option>;
              })}
            </select>
          </div>
        </div>
        {gettingOutstandingRemits &
          (outstandingremittanceReport.length === 0) ? (
            <div className="volucherNote mt-5 t-center ">
              <CustomLoader />{" "}
            </div>
          ) : outstandingremittanceReport.length > 0 ? (
            <CustomDataTable
              data={data}
              pagination={this.customPagination()}
            />
          ) : (
              <p className="voucherText t-center">
                {" "}
            Select an agent and a date range to view valid data
              </p>
            )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  const {
    outstandingremittanceReport,
    outstandingremittanceTotalRecords,
    outstandingremittanceCurrentPage,
    outstandingremittanceTotalPages,
    conversionsRate,
  } = state.Report;
  const { allAgents } = state.Agent;
  const { outstandingselectedAgent, outstandingremitStart, outstandingremitEnd } = state.User;
  const { gettingOutstandingRemits } = state.Loading;
  return {
    outstandingremittanceReport,
    allAgents,
    outstandingselectedAgent,
    outstandingremitStart,
    outstandingremitEnd,
    gettingOutstandingRemits,
    outstandingremittanceTotalRecords,
    outstandingremittanceCurrentPage,
    outstandingremittanceTotalPages,
    conversionsRate,
  };
};
export default connect(
  MapStateToProps,
  { outstandingRemittance, getFormDetails, clearRemittance, getallAgents }
)(Outstanding);
