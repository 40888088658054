import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Reusables/loaders/HashLoader";
import IconCard from "../Reusables/cards/IconCard";
import {
  numberWithCommaswithoutdecimals, precise_round,
} from "../../Helper";
import { getUnstakeOverview, getStakingStatisticReport, clearSearch, getSearchDetails } from "../../Redux/actions";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { stakeStatCols } from "../../utils/TableCols/usersCols";
import { getStaketatisticsRows } from "../../utils/TableRows/usersRows";
import TableHeader from "../Reusables/tables/TableHeader";
import { statOptionsStake } from "../UserDetails/options";


const UnstakeActivity = () => {
  const dispatch = useDispatch();

  const { gettingUnstakeSummary, gettingStakingStatisticsReport } = useSelector((state) => state.Loading);

  const { unstakeSummary, stakingStatisticsSummary } = useSelector((state) => state.Stake);

  const { stakeDurationFilter } = useSelector((state) => state.Search);

  useEffect(() => {
    dispatch(getUnstakeOverview());
    dispatch(getStakingStatisticReport(stakeDurationFilter, 1, 30));
  }, [stakeDurationFilter]);


  let allRows = getStaketatisticsRows(stakingStatisticsSummary.records);

  const data = {
    columns: stakeStatCols,
    rows: allRows,
  };

  useEffect(() => {
    dispatch(clearSearch());
  }, []);


  return (
    <div>
      {/* <div
        className="row flex items-right justify-end"
        style={{ paddingRight: "30px" }}
      >
        <SimpleSelect
          options={statOptions}
          selectProp="duration"
          onSelect={(val, props) => {
            dispatch(
              getSearchDetails({
                props: [props],
                value: val,
              })
            );
          }}
        />
      </div> */}

      <div className="row  p-2">
        <IconCard
          title={"Total WNT"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUnstakeSummary ? (
              <Loader />
            ) : unstakeSummary ? (
              (numberWithCommaswithoutdecimals(precise_round(unstakeSummary.totalWNT, 3)) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Total WNT (USD)"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUnstakeSummary ? (
              <Loader />
            ) : unstakeSummary ? (
              (numberWithCommaswithoutdecimals(precise_round(unstakeSummary.totalWNTInUsd, 3)) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
        <IconCard
          title={"Number of Accounts"}
          image={"/Assets/dashboardicons/Extra/SVG/wntmined.svg"}
          value={
            gettingUnstakeSummary ? (
              <Loader />
            ) : unstakeSummary ? (
              (numberWithCommaswithoutdecimals(unstakeSummary.numberOfAccount) || 0)
            ) : (
              "0"
            )
          }
          id={1}
          link={"#"}
        />
      </div>
      <div className="row">
      <div className="col-lg-12 mt-1">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <CustomDataTable
              data={data}
              tableId={"staking-report"}
              title={`Staking Statistics`}
              pageCount={stakingStatisticsSummary.totalPages}
              currentPage={stakingStatisticsSummary.currentPage}
              header={
                <TableHeader
                  // subHeaderTypes={StakeHeaderOptionTypes}
                  subHeader={statOptionsStake}
                  // onClick={() => dispatch(toggleModal(true))}
                  // ontabClick={(val, props) => {
                  //   dispatch(
                  //     getSearchDetails({
                  //       props: ["stakeType"],
                  //       value: val,
                  //     })
                  //   );
                  // }}
                  onSelect={(val, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: val,
                      })
                    );
                  }}
                  onChange={(e, props) => {
                    dispatch(
                      getSearchDetails({
                        props: [props],
                        value: e.target.value,
                      })
                    );
                  }}
                  // searchvalue={duration}
                />
              }
              loading={gettingStakingStatisticsReport}
              noData={
                <EmptyCard
                  img="/Assets/emptytransactions.svg"
                  //title={`Sorry,  you have no ${stakeType} history`}
                  // descr={"No stake history available at the moment"}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default UnstakeActivity;
