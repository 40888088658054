import React from "react";
import CustomModal from "../../Reusables/modals/CustomModal";
import { DeviceTypeContainer, Type } from "./styles";
import { useDispatch } from "react-redux";
import { handleModal } from "../../../Redux/actions";

function SelectDeviceType() {
  const dispatch = useDispatch();
  return (
    <div>
      <CustomModal
        id={13}
        heading="Select Device Type"
        close={true}
        content={
          <>
            <DeviceTypeContainer
              onClick={() => {
                dispatch(handleModal(14));
              }}
            >
              <Type>Jump Start Device</Type>
              <img
                src="/Assets/dashboardicons/Extra/SVG/righticon.svg"
                alt=""
              />
            </DeviceTypeContainer>
            <DeviceTypeContainer
              onClick={() => {
                dispatch(handleModal(14));
              }}
            >
              <Type>Original Device</Type>
              <img
                src="/Assets/dashboardicons/Extra/SVG/righticon.svg"
                alt=""
              />
            </DeviceTypeContainer>
          </>
        }
      />
    </div>
  );
}

export default SelectDeviceType;
