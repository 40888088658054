import React from 'react';
import { Link } from 'react-router-dom'
import Loader from '../Reusables/loaders/MoonLoader'
import { connect } from 'react-redux'
import { getFormDetails, registerMerch, getByemail } from '../../Redux/actions'

const RegForm = (props) => {


    const { merchantAddress, merchantName, merchantregistering, merchantLogo, merchantEmail,
        fetchingname, logginInUser, emailFail, merchantError, imagePreview } = props


    let data = {
        "userId": logginInUser.id,
        "name": merchantName,
        "logoUrl": null,
        "officeAddress": merchantAddress,
        "logoFile": merchantLogo
    }

    return (
        <div className=" col-lg-5 offset-lg-3">
            <div className="card card-small card-post card-post--1">
                <div className="card-body">
                    <h5 className="card-title mb-3">
                        <Link to='' className="text-fiord-blue cardHeading ">Register</Link>
                    </h5>
                    {logginInUser.id === undefined ?
                        <div>
                            <p className='wicryptmsg'> Own a business account</p>
                            <label> Business Email</label>
                            <div className="input-group mb-3 ">
                                <input type="email"
                                    className="form-control loginCred"
                                    aria-describedby="basic-addon1"
                                    value={merchantEmail}
                                    onChange={(e) => props.getFormDetails({ props: ['merchantEmail'], value: e.target.value })}
                                />

                            </div>
                            {emailFail !== '' ? <p className='errorMsgs' ><i className="material-icons">info</i>{emailFail}</p> : ''}
                            <div className='loginBtn'>
                                <button onClick={() => props.getByemail(merchantEmail)}
                                    className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin">
                                    {fetchingname ? <Loader /> : 'Register'}
                                </button>
                            </div>
                        </div> :
                        <div>
                            <p className='wicryptmsg'> Create a Business account for {logginInUser.name}</p>
                            <div className="p-r">
                                <div className="avatar__preview mx-auto mb-4">
                                    <img
                                        className="avatar__upload"
                                        id="Img1"
                                        src={
                                            imagePreview !== ""
                                                ? imagePreview
                                                : "https://assets.hongkiat.com/uploads/psd-text-svg/logo-example.jpg"
                                        }
                                        alt="advert"
                                    />

                                </div>
                                <input type="file" name="file" id="file"
                                    onChange={(e) => props.getFormDetails({ props: ['merchantLogo'], value: e.target.files[0] })}
                                    className="uglyduckling" />
                                <label for="file" className="uglylabel"><i className="fa fa-edit fa-2x" aria-hidden="true" /></label>
                            </div>

                            <label> Business Name</label>
                            <div className="input-group ">

                                <input type="text"
                                    className="form-control loginCred"
                                    aria-label="MerchantName"
                                    aria-describedby="basic-addon1"
                                    value={merchantName}
                                    onChange={(e) => props.getFormDetails({ props: ['merchantName'], value: e.target.value })}
                                />

                            </div>
                            <label> Merchant Address</label>
                            <div className="input-group ">

                                <input type="email"
                                    className="form-control loginCred"
                                    aria-label="MerchantAddress"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => props.getFormDetails({ props: ['merchantAddress'], value: e.target.value })}
                                />

                            </div>
                            {merchantError !== '' ? <p className='errorMsgs' ><i className="material-icons">info</i>{merchantError}</p> : ''}

                            <div className='loginBtn mt-3'>
                                <button onClick={() => props.registerMerch(data)}
                                    className="mb-2 btn mr-1 customBtn btn-primary wicryptColor clickLogin">
                                    {merchantregistering ? <Loader /> : 'Register'}
                                </button>
                            </div>
                        </div>
                    }

                </div>

            </div>
        </div>
    )

}

const MapStateToProps = (state) => {
    const { merchantName, merchantAddress, merchantLogo, merchantEmail, logginInUser, emailFail, merchantError, imagePreview } = state.User
    const { merchantregistering, fetchingname } = state.Loading
    return {

        merchantName,
        merchantAddress,
        merchantregistering,
        merchantLogo,
        merchantEmail,
        fetchingname,
        logginInUser,
        emailFail,
        merchantError,
        imagePreview

    }
}
export default connect(MapStateToProps, { getFormDetails, registerMerch, getByemail })(RegForm)