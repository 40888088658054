import React from "react";
import { ContinueBtn } from "../../Reusables/inputs/ContinueButton";
import { ButtonContainer } from "../../Reusables/inputs/inputstyles";
import CustomModal from "../../Reusables/modals/CustomModal";
import { Answer, Body, Question } from "./styles";
import { theme } from "../../../theme";
import { getFormDetails, handleModal } from "../../../Redux/actions";
import { useDispatch } from "react-redux";
import PinBoxes from "../../Reusables/inputs/PinBoxes";

function ForgotPin() {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value, name } = e.target;

    getFormDetails({
      props: name,
      value: value,
    });
  };

  return (
    <div>
      <CustomModal
        id={18}
        heading="Verify Email"
        subHeading="Please  enter the four digit pin we sent to : johns@wicrypt.com"
        close={true}
        content={
          <>
            <Body>
              <div className="mb-3">
                <div className="d-flex" style={{ gap: "15px" }}>
                  <PinBoxes name="pin91" keyUp={handleChange} />
                  <PinBoxes name="pin92" keyUp={handleChange} />
                  <PinBoxes name="pin93" keyUp={handleChange} />
                  <PinBoxes name="pin94" keyUp={handleChange} />
                </div>
              </div>
              <Question>Didn’t receive an OTP?</Question>
              <Answer /*onClick={resend}*/>Resend OTP?</Answer>
            </Body>
            {/* {withdrawalotpgeneration || err ? (
              <p className="errorMsgs mt-3 t-center">
                {" "}
                {withdrawalotpgeneration + err}{" "}
              </p>
            ) : (
              <div />
            )}
            {cryptoWithdrawalFailedErrMsg ? (
              <p className="errorMsgs mt-3 t-center">
                {cryptoWithdrawalFailedErrMsg}
              </p>
            ) : (
              ""
            )} */}
            <ButtonContainer>
              <ContinueBtn
                onClick={() => dispatch(handleModal(18))}
                title={false ? "Loading..." : "Submit"}
                darktheme={theme}
              />
            </ButtonContainer>
          </>
        }
      />
    </div>
  );
}

export default ForgotPin;
