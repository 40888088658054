import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribersPerTopic, getNoOfMessagesPerSubscriber, updateDeadLetterTopic, handleModal } from "../../Redux/actions";
import { SummaryTitle, SummaryCard } from "../Staking/style";
import CustomLoader from "../Reusables/loaders/CustomLoader";
import CustomDataTable from "../Reusables/tables/CustomDataTable";
import EmptyCard from "../Reusables/cards/EmptyCard";
import { formatTopicName } from "../../Helper";
import ReplayDeadLetterModal from "./ReplayModal";
import Loader from "../Reusables/loaders/MoonLoader";

const SingleSubscriberTopic = () => {

    const dispatch = useDispatch();
    const [replaySubscriberName, setReplaySubscriberName] = useState("");

    const handleReplayButtonClick = (subscriberName) => {
        setReplaySubscriberName(subscriberName);
        dispatch(handleModal(80));
    };

    // fetching subscribers per topic
    const { subscribersPerTopic, noOfMessagesPerSubscriber, replayError } = useSelector((state) => state.Topics);
    const { gettingSubscribersPerTopic, gettingNoOfMessagesPerSubscriber } = useSelector((state) => state.Loading);

    let allRows = [];

    const topicId = window.location.pathname.split("/").at(-1);

    // This variable returns the topic without the dead letter symbol (dlq).
    const modifiedTopic = topicId.replace(/-dlq/, '');

    useEffect(() => {
        dispatch(getSubscribersPerTopic(topicId));
    }, [topicId]);

    // useEffect(()=>{
    //     console.log({subscribersPerTopic, noOfMessagesPerSubscriber})
    // }, [subscribersPerTopic, noOfMessagesPerSubscriber])

    useEffect(()=>{
        if(subscribersPerTopic.length){
            dispatch(getNoOfMessagesPerSubscriber(subscribersPerTopic.map(item => item.split('/').at(-1))))
        }
    }, [subscribersPerTopic])


    // if (noOfMessagesPerSubscriber.length && noOfMessagesPerSubscriber.length === subscribersPerTopic.length) {
    //     subscribersPerTopic.map((each, index) => {
    //         const subscriberName = each.split("/").at(-1);
    //         const showViewDetail = subscriberName.toLowerCase().includes('dlq');
      
    //         if (  noOfMessagesPerSubscriber[index] && noOfMessagesPerSubscriber[index].numberOfMessage !== undefined){

    //             const numberOfMessages = noOfMessagesPerSubscriber[index].numberOfMessage;
    //             const isDisabled = () => !numberOfMessages ? true : false;
                
    //             return allRows.push({
    //                 noOfMessages: <p key={index}>{numberOfMessages}</p>,
    //                 viewDetail: showViewDetail ? (
    //                     <button
    //                         key={index}
    //                         className={`details t-center ${isDisabled() ? 'disabledButton' : ''}`}
    //                         onClick={() => handleReplayButtonClick(subscriberName)}
    //                         disabled={isDisabled()}
    //                     >
    //                         Replay
    //                     </button>
    //                 ) : null,
    //             });
    //         } 
    //       });
    //   }

    if (subscribersPerTopic.length) {
        subscribersPerTopic.map((each, index) => {
            const subscriberName = each.split("/").at(-1);
          
            const subscriberInfo = {
                subscribers: <p key={index} className="mb-0">{subscriberName}</p>,
                noOfMessages: <p key={index}>{gettingNoOfMessagesPerSubscriber ? <Loader />: ""}</p>,
            };
    
            allRows.push(subscriberInfo);
    
            if (noOfMessagesPerSubscriber.length && noOfMessagesPerSubscriber.length === subscribersPerTopic.length) {
                const showViewDetail = subscriberName.toLowerCase().includes('dlq');
    
                if (noOfMessagesPerSubscriber[index] && noOfMessagesPerSubscriber[index].numberOfMessage !== undefined) {
                    const numberOfMessages = noOfMessagesPerSubscriber[index].numberOfMessage;
                    const isDisabled = () => !numberOfMessages ? true : false;
    
                    subscriberInfo.noOfMessages = <p key={index}>{numberOfMessages}</p>;
                    subscriberInfo.viewDetail = showViewDetail ? (
                        <button
                            key={index}
                            className={`details t-center ${isDisabled() ? 'disabledButton' : ''}`}
                            onClick={() => handleReplayButtonClick(subscriberName)}
                            disabled={isDisabled()}
                        >
                            Replay
                        </button>
                    ) : null;
                }
            }
        });
    }
 
    const data = {
        columns: [
            {
                label: "Names of subscribers",
                field: "subscribers",
                sort: "asc",
                width: 170,
            },
            {
                label: "Number of messages",
                field: "noOfMessages",
                sort: "asc",
                width: 170,
            },
            {
                label: "",
                field: "viewDetail",
                sort: "asc",
                width: 170,
            },
           
            
        ],

        rows: allRows,
    };



    return (
        <div className=" mb-4">
            <div className="card card-small mb-4 w3-animate-bottomd">
               
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <SummaryCard className="card">
                            <div className="card-header">
                                <div className="col-lg-12">
                                    <SummaryTitle> {formatTopicName(topicId)} Topic</SummaryTitle>
                                </div>
                                <div className="col-lg-12">
                                    <CustomDataTable
                                        data={data}
                                        title="List of all subscribers under this topic"
                                        loading={gettingSubscribersPerTopic}
                                        noData={
                                            <EmptyCard
                                                img="/Assets/emptytransactions.svg"
                                                title={"No subscriber under this topic"}
                                            // descr={"No Request available at the moment"}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                           
                        </SummaryCard>
                    </div>
                   
                </div>
            </div>
           
                <ReplayDeadLetterModal
                    subscriberName={replaySubscriberName}
                    replayError={replayError}
                    onSubmit={() => dispatch(updateDeadLetterTopic(modifiedTopic, replaySubscriberName))}
                />
           
        </div>
    );
};

export default SingleSubscriberTopic;
