import React, { Component } from "react";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from '../Partials/SubSidenav';
import { MainContent } from "../Partials/partials";
import StakeLog from "./StakeLog";

class Staking extends Component {
  constructor(props) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  handleGoBack() {
    this.props.history.goBack();
  }

  render() {

    return (
      <div className="row">
        <Sidenav />
        <MainContent className="main-content">
          <div className="main-navbar sticky-top bg-white">
            <Mainnav pageTitle={
              <div className="pointer" onClick={() => this.handleGoBack()}>
                <i className="material-icons">arrow_back</i> Staking Log
              </div>
            } />
          </div>
          <div className="main-content-container container-fluid px-4 mt-5">
            <BreadCrumbs />
            <SubSidenav />
            <StakeLog />
          </div>
        </MainContent>
      </div>
    );
  }
}

export default Staking;

