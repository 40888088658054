import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setRemittance,
  getFormDetails,
  toggleModal,
  setAgentRemittance,
  getallAgents,
  recordAgentRemittance,
} from "../../Redux/actions";
import Loader from "../Reusables/loaders/MoonLoader";
import Modal from "../Reusables/modals/Modal";
import Datepicker from "react-datepicker";

class Remit extends Component {
  componentWillMount() {
    if (this.props.allAgents.length === 0) {
      this.props.getallAgents(1, 30, '');
    }
  }

  render() {
    const {
      referalCode,
      settingRemittance,
      remitRate,
      conversionsRate,
      remitErr,
      businessProfile,
      funct,
      allAgents,
      agentRemit,
      remitReceivedDate,
    } = this.props;
    const noError = {
      display: "block",
      color: "red",
      fontSize: "12px",
    };

    return (
      <div className="row">
        <Modal
          heading={
            funct === "setdefaultremit"
              ? "SET REMIT RATE IN NAIRA"
              : funct === "setAgentRate"
                ? "SET AGENT REMIT RATE IN NAIRA"
                : funct === "recordRemit"
                  ? "RECORD REMIT RATE"
                  : ""
          }
        >
          {funct === "setdefaultremit" ? (
            <form>
              <label> Enter Remit Rate</label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control loginCred"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={remitRate}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["remitRate"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <p style={noError}> {remitErr}</p>

              <div className=" mb-4 mt-3 authbtns">
                <button
                  type="button"
                  className="mb-4 btn wicryptColor customBtn float button"
                  onClick={() =>
                    referalCode !== ""
                      ? this.props.setRemittance(
                        businessProfile.id,
                        remitRate,
                        conversionsRate
                      )
                      : ""
                  }
                >
                  {settingRemittance === true ? <Loader /> : "Enter"}
                </button>
              </div>
              <div className="clearfix" />
            </form>
          ) : funct === "setAgentRate" ? (
            <form>
              <label> Select an Agent to Set Custom Remit Rate</label>
              <div className="input-group mb-3">
                <select
                  className="loginCred form-control"
                  style={{ width: "auto" }}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["agentRemit"],
                      value: e.target.value,
                    })
                  }
                >
                  <option>Select Agent</option>
                  {allAgents.map((agent) => {
                    return (
                      <option value={agent.user.id}>{agent.user.name}</option>
                    );
                  })}
                </select>
              </div>
              <label> Enter Remit Rate</label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control loginCred"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={remitRate}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["remitRate"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <p style={noError}> {remitErr}</p>

              <div className=" mb-4 mt-3 authbtns">
                <button
                  type="button"
                  className="mb-4 btn wicryptColor customBtn float button"
                  onClick={() =>
                    referalCode !== ""
                      ? this.props.setAgentRemittance(
                        businessProfile.id,
                        remitRate,
                        conversionsRate,
                        agentRemit
                      )
                      : ""
                  }
                >
                  {settingRemittance === true ? <Loader /> : "Enter"}
                </button>
              </div>
              <div className="clearfix" />
            </form>
          ) : funct === "recordRemit" ? (
            <form>
              <label> Select an Agent to Set Custom Remit Rate</label>
              <div className="input-group mb-3">
                <select
                  className="loginCred form-control"
                  style={{ width: "auto" }}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["agentRemit"],
                      value: e.target.value,
                    })
                  }
                >
                  <option>Select Agent</option>
                  {allAgents.map((agent) => {
                    return (
                      <option value={agent.user.id}>{agent.user.name}</option>
                    );
                  })}
                </select>
              </div>
              <label> Enter Amount Received</label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  className="form-control loginCred"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={remitRate}
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["remitRate"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <label> Enter Date Received</label>
              <div className="input-group mb-3">
                <Datepicker
                  className="form-control loginCred"
                  onChange={(e) =>
                    this.props.getFormDetails({
                      props: ["remitReceivedDate"],
                      value: e.getTime(),
                    })
                  }
                  selected={remitReceivedDate}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>

              <p style={noError}> {remitErr}</p>

              <div className=" mb-4 mt-3 authbtns">
                <button
                  type="button"
                  className="mb-4 btn wicryptColor customBtn float button"
                  onClick={() =>
                    referalCode !== ""
                      ? this.props.recordAgentRemittance(
                        remitReceivedDate,
                        remitRate,
                        conversionsRate,
                        agentRemit
                      )
                      : ""
                  }
                >
                  {settingRemittance === true ? <Loader /> : "Enter"}
                </button>
              </div>
              <div className="clearfix" />
            </form>
          ) : (
                  ""
                )}
        </Modal>
        <div className="offset-lg-1 col-lg-10 mb-4">
          <div className="card card-small mb-4">
            <div className="card-header border-bottom">
              <h6 className="m-0"> SETTINGS</h6>
            </div>
            <div className="p-20">
              <div
                className="d-flex"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.toggleModal(true, "", "setdefaultremit")
                }
              >
                <div>
                  <p className="mb-0 w-600"> Set Default Rate</p>
                  <p className="t-12"> Set the default amount to be remitted by your agents</p>
                </div>
                <div>
                  <i className="fa fa-chevron-right t-25" aria-hidden="true">
                    {" "}
                  </i>
                </div>
              </div>
              <hr />
              <div
                className="d-flex"
                onClick={() => this.props.toggleModal(true, "", "setAgentRate")}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p className="mb-0 w-600"> Set Agent Remit Rate</p>
                  <p className="t-12"> Set the amount you expect from a particuar agent</p>
                </div>
                <div>
                  <i
                    className="fa fa-chevron-right t-25"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                  </i>
                </div>
              </div>
              <hr />
              <div
                className="d-flex"
                onClick={() => this.props.toggleModal(true, "", "recordRemit")}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p className="mb-0 w-600"> Record Agent Remit Rate</p>
                  <p className="t-12">
                    {" "}
                    Record the amount received from an agent
                  </p>
                </div>
                <div>
                  <i
                    className="fa fa-chevron-right t-25"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state) => {
  const { settingRemittance } = state.Loading;
  const {
    remitRate,
    businessProfile,
    funct,
    agentRemit,
    remitReceivedDate,
  } = state.User;
  const { conversionsRate, remitErr } = state.Report;
  const { allAgents } = state.Agent;
  return {
    settingRemittance,
    remitRate,
    conversionsRate,
    remitErr,
    businessProfile,
    funct,
    allAgents,
    agentRemit,
    remitReceivedDate,
  };
};

export default connect(
  MapStateToProps,
  {
    setRemittance,
    getFormDetails,
    setAgentRemittance,
    toggleModal,
    getallAgents,
    recordAgentRemittance,
  }
)(Remit);
